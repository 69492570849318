import { FC, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import AnimateLabel from 'components/AnimateLabel/AnimateLabel';
import CheckBox from 'components/CheckboxGroup/CheckboxGroup';
import { FormError } from 'components/FormError/FormError';
import { FormLabel } from 'components/FormLabel/FormLabel';
import { If } from 'components/Generics';
import {
  leftBottomTeethOptions,
  leftTopTeethOptions,
  rigthBottomTeethOptions,
  rigthTopTeethOptions
} from 'components/TreatmentPlanningInstructions/TreatmentPlanningInstructions.config';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { Option } from 'types/select';
import { translate } from 'utils';

type TeethToExtractProp = {
  onChange: (value: typeof initialValue) => void;
  value: typeof initialValue | null;
  error?: string;
  name: string;
  title?: string;
  showError?: boolean;
  disabled?: boolean;
  animateLabel?: string;
  source?: string;
};
const initialValue = {
  rightTop: [] as Option[],
  rightBottom: [] as Option[],
  leftTop: [] as Option[],
  leftBottom: [] as Option[]
};
const TeethToExtract: FC<TeethToExtractProp> = ({
  onChange,
  value,
  error,
  name,
  title,
  showError = true,
  disabled = false,
  animateLabel = 'treatmentSpecifications.teethToExtract',
  source
}) => {
  const isPrescriptionSummary = source === 'PrescriptionSummary';
  const checkBoxContainerClassName = `${
    isPrescriptionSummary ? 'md:flex-wrap md:justify-between' : ''
  } md:flex  grid grid-cols-4 gap-x-12 md:gap-0`;
  const { animatedField, callback } = useFieldRefContext();
  const [rigthTopTeeth, setRightTopTeeth] = useState<Option[]>(
    value?.rightTop || []
  );
  const [rigthBottomTeeth, setRigthBottomTeeth] = useState<Option[]>(
    value?.rightBottom || []
  );
  const [leftTopTeeth, setLeftTopTeeth] = useState<Option[]>(
    value?.leftTop || []
  );
  const [leftBottomTeeth, setLeftBottomTeeth] = useState<Option[]>(
    value?.leftBottom || []
  );
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else if (value) {
      setRightTopTeeth(value.rightTop || []);
      setRigthBottomTeeth(value.rightBottom || []);
      setLeftTopTeeth(value.leftTop || []);
      setLeftBottomTeeth(value.leftBottom || []);
    }
  }, [value]);

  return (
    <div>
      <If condition={!!title}>
        <div
          className={classNames('text-17px mb-6', {
            'text-ERROR': error,
            'text-GRAY3': disabled
          })}
        >
          <label>{title}</label>
        </div>
      </If>
      <div
        className={classNames(
          'flex md:flex-row flex-col flex-wrap  md:rounded-lg border-1 md:border-solid md:border-LIGHT_GRAY_2 overflow-x-auto rounded-lg px-4 py-6 md:w-fit w-auto',
          {
            'bg-LIGHT_ERROR_RED': error,
            'bg-white': !error,
            'md:p-10': !isPrescriptionSummary
          }
        )}
        ref={callback?.(name)}
      >
        <div
          className={classNames('relative mb-10 md:mb-0', {
            'md:w-full': isPrescriptionSummary
          })}
        >
          <h3>
            <FormLabel
              className='text-sm'
              label={translate('prescriptions.right')}
              error={!!error}
              disabled={disabled}
            />
            <AnimateLabel
              animate={animatedField === animateLabel}
              className='text-ERROR md:text-17px text-base font-normal'
            >
              {translate('prescriptions.right')}
            </AnimateLabel>
          </h3>
          <div
            className={classNames({
              'md:w-full': isPrescriptionSummary,
              'md:flex': !isPrescriptionSummary
            })}
          >
            <CheckBox
              onChangehandle={(value) => {
                onChange({
                  leftBottom: leftBottomTeeth,
                  leftTop: leftTopTeeth,
                  rightTop: value,
                  rightBottom: rigthBottomTeeth
                });
              }}
              options={rigthTopTeethOptions}
              name='Right Top Teeth'
              selectedItems={rigthTopTeeth}
              checkBoxClassName='border-RADIO_BORDER'
              checkBoxContainerClassName={checkBoxContainerClassName}
              labelClassName='flex flex-col-reverse md:items-center md:mr-6'
              checkBoxInnerContainerClassName='w-5'
              disabled={disabled}
            />
          </div>
          <div
            className={classNames({
              'md:w-full': isPrescriptionSummary
            })}
          >
            <CheckBox
              onChangehandle={(value) => {
                onChange({
                  leftBottom: leftBottomTeeth,
                  leftTop: leftTopTeeth,
                  rightTop: rigthTopTeeth,
                  rightBottom: value
                });
              }}
              options={rigthBottomTeethOptions}
              name='Right Bottom Teeth'
              selectedItems={rigthBottomTeeth}
              checkBoxClassName='border-RADIO_BORDER'
              checkBoxContainerClassName={checkBoxContainerClassName}
              labelClassName='flex flex-col-reverse md:items-center md:mr-6'
              checkBoxInnerContainerClassName='w-5'
              disabled={disabled}
            />
          </div>
        </div>
        <div
          className={classNames('relative', {
            'md:w-full': isPrescriptionSummary
          })}
        >
          <h3>
            <FormLabel
              className='text-sm'
              label={translate('prescriptions.left')}
              error={!!error}
              disabled={disabled}
            />
            <AnimateLabel
              animate={animatedField === animateLabel}
              className='text-ERROR md:text-17px text-base font-normal'
            >
              {translate('prescriptions.left')}
            </AnimateLabel>
          </h3>
          <div
            className={classNames({
              'md:w-full': isPrescriptionSummary,
              'md:flex': !isPrescriptionSummary
            })}
          >
            <CheckBox
              onChangehandle={(value) => {
                onChange({
                  leftBottom: leftBottomTeeth,
                  leftTop: value,
                  rightTop: rigthTopTeeth,
                  rightBottom: rigthBottomTeeth
                });
              }}
              options={leftTopTeethOptions}
              name='Left Top Teeth'
              selectedItems={leftTopTeeth}
              checkBoxClassName='border-RADIO_BORDER'
              checkBoxContainerClassName={checkBoxContainerClassName}
              labelClassName='flex flex-col-reverse md:items-center md:mr-6'
              checkBoxInnerContainerClassName='w-5'
              disabled={disabled}
            />
          </div>
          <div
            className={classNames({
              'md:w-full': isPrescriptionSummary
            })}
          >
            <CheckBox
              onChangehandle={(value) => {
                onChange({
                  leftBottom: value,
                  leftTop: leftTopTeeth,
                  rightTop: rigthTopTeeth,
                  rightBottom: rigthBottomTeeth
                });
              }}
              options={leftBottomTeethOptions}
              name='Left Bottom Teeth'
              selectedItems={leftBottomTeeth}
              checkBoxClassName='border-RADIO_BORDER'
              checkBoxContainerClassName={checkBoxContainerClassName}
              labelClassName='flex flex-col-reverse md:items-center md:mr-6'
              checkBoxInnerContainerClassName='w-5'
              disabled={disabled}
            />
          </div>
        </div>
      </div>
      <If condition={showError}>
        <FormError errorMessage={error} />
      </If>
    </div>
  );
};

export default TeethToExtract;
