import { FC } from 'react';

import { translate } from 'utils';

interface ActionOptionProps {
  title: string;
  subtitle: string;
  onClick(): void;
  count?: number;
}

const ActionOption: FC<ActionOptionProps> = ({
  title,
  subtitle,
  onClick,
  count
}) => {
  const isOptionDisabled = count === 0;
  return (
    <button
      disabled={isOptionDisabled}
      onClick={onClick}
      className='rounded-md py-2 px-4 w-full text-left hover:bg-LIGHT_BLUE_8 transition-colors ease-in-out delay-150'
    >
      <div className='flex w-full justify-between items-center'>
        <div>
          <p className='text-14px'>{translate(title)}</p>
          <p className='text-DISABLED text-xs'>{translate(subtitle)}</p>
        </div>
        <div className='text-14px text-DISABLED_2 ml-2'>
          <p>{count ?? ''}</p>
        </div>
      </div>
    </button>
  );
};

export default ActionOption;
