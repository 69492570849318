import { useRecoilValue } from 'recoil';

import { INBOX_GROUP_NAME } from 'constants/inbox';
import { loginedUserDetails, selectedTicket } from 'store/atoms';

interface SuperAdminAccess {
  isSuperUser: boolean;
}

export function useSuperUserAccess(): SuperAdminAccess {
  const loginedUser = useRecoilValue(loginedUserDetails);
  const { ticketUsers } = useRecoilValue(selectedTicket);

  let isSuperUser = false;
  if (loginedUser.id && ticketUsers) {
    const superUsers = ticketUsers.filter(
      (user) => user.groupName === INBOX_GROUP_NAME.CHAT_SUPER_USER
    );
    const superUserIds = superUsers.map((user) => user.userId);
    isSuperUser = superUserIds.includes(loginedUser.id);
  }
  return { isSuperUser };
}
