import { FC } from 'react';

import { customTwMerge } from 'utils';

interface GalleryContainerProps {
  children: React.ReactNode;
  onClick(): void;
  className?: string;
}

const GalleryContainer: FC<GalleryContainerProps> = ({
  children,
  onClick,
  className = ''
}) => {
  return (
    <div
      role='presentation'
      onClick={onClick}
      className={customTwMerge(
        'absolute inset-0 bg-[#282829de] md:bg-[#282829a6] py-10 md:py-8 px-1.5 md:pl-19 z-[31] md:z-[25] flex flex-col justify-between items-center',
        className
      )}
    >
      {children}
    </div>
  );
};

export default GalleryContainer;
