import { FC, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { Select } from 'components';
import { loginedUserDetails } from 'store/atoms';
import { Option } from 'types/select';
import { translate } from 'utils';

interface Props {
  selectedClinic?: Option;
  onChange: (selected: Option) => void;
  styles?: any;
  isDisabled?: boolean;
}

export const HeadClinicSelector: FC<Props> = ({
  selectedClinic,
  onChange,
  styles,
  isDisabled = false
}) => {
  const { headClinics } = useRecoilValue(loginedUserDetails);
  const clinicList = useMemo(() => {
    if (selectedClinic?.value) {
      return headClinics?.filter(
        (clinic) => clinic?.value !== selectedClinic?.value
      );
    }
    return headClinics;
  }, [headClinics, selectedClinic?.value]);

  return (
    <Select
      controlShouldRenderValue={true}
      placeholder={translate('multiclinic.dropdown')}
      isMulti={false}
      options={clinicList || []}
      name='clinicSelector'
      isFormSelect
      selectedValue={selectedClinic}
      onChange={onChange}
      styles={styles}
      menuListMaxHeight='336px'
      isDisabled={isDisabled}
    />
  );
};
