import { FC, useEffect, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { SalesforceIcon, ZenyumLogo } from 'assets/icons';
import { Tooltip } from 'components';
import { If } from 'components/Generics';
import { EXTERNAL_LINKS_TRACK_EVENT } from 'constants/segment';
import { useGetTpvInfo } from 'services/hooks/Ticket.hooks';
import { selectedTicket } from 'store/atoms';
import { useIsExternalUser } from 'store/selectors/auth';
import { KeyLinks } from 'types/Inbox.types';
import { translate } from 'utils';
import { getTicketList } from 'utils/customHooks/inbox';

interface TpvInfoCardProps {
  keyLinks: KeyLinks;
  handleLinkClick: () => void;
  handleSegmentEvent: (linkType: string, viewType: string) => void;
}
const TpvInfoCard: FC<TpvInfoCardProps> = ({
  keyLinks,
  handleLinkClick,
  handleSegmentEvent
}) => {
  const [getTpvInfo] = useGetTpvInfo({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });
  const selectedTicketDetails = useRecoilValue(selectedTicket);
  const isExternalUser = useRecoilValue(useIsExternalUser);

  const formatedTcketInfo = useMemo(
    () => getTicketList([selectedTicketDetails])[0],
    [selectedTicketDetails]
  );

  useEffect(() => {
    if (formatedTcketInfo.metaData.tpvDetails?.contextValueId) {
      const params = {
        id: '',
        treatmentPlanId: ''
      };
      if (formatedTcketInfo.metaData.tpDetails?.contextValueId) {
        params.treatmentPlanId =
          formatedTcketInfo.metaData.tpDetails?.contextValueId;
      }
      if (formatedTcketInfo.metaData.tpvDetails?.contextValueId) {
        params.id = formatedTcketInfo.metaData.tpvDetails?.contextValueId;
      }
      getTpvInfo({ variables: { params } });
    }
  }, [selectedTicketDetails.id]);

  return (
    <div>
      {isExternalUser && (
        <div className='mt-6'>
          {formatedTcketInfo?.metaData.tpDetails?.contextValue && (
            <div className='px-4 py-3 border border-BORDER_COLOR'>
              {formatedTcketInfo?.metaData.tpDetails?.contextValue}
            </div>
          )}
          {formatedTcketInfo?.metaData.tpvDetails?.contextValue && (
            <div className='px-4 py-3 mt-6  border border-BORDER_COLOR'>
              {formatedTcketInfo?.metaData.tpvDetails?.contextValue}
            </div>
          )}
        </div>
      )}
      <If
        condition={
          (!!keyLinks?.salesforceLink || !!keyLinks?.adminPortalLink) &&
          !isExternalUser &&
          !!keyLinks?.isTreatmentActive
        }
      >
        <div className='bg-GRAY5 mx-4 my-6 p-3 rounded-xl'>
          <span className='mb-5 pr-5' style={{ verticalAlign: 'super' }}>
            {translate('inbox.key_links')}
          </span>
          {!!keyLinks?.salesforceLink && !!keyLinks?.isTreatmentActive && (
            <button onClick={handleLinkClick}>
              <div className='inline-block pr-4'>
                <Tooltip messageText={translate('salesforce')}>
                  <SalesforceIcon />
                </Tooltip>
              </div>
            </button>
          )}
          {!!keyLinks?.adminPortalLink && (
            <button
              onClick={() => {
                handleSegmentEvent(
                  EXTERNAL_LINKS_TRACK_EVENT.ADMIN_PORTAL_CLICK,
                  EXTERNAL_LINKS_TRACK_EVENT.INBOX
                );
                window.open(keyLinks?.adminPortalLink);
              }}
            >
              <div className='inline-block '>
                <Tooltip messageText={translate('adminPortal')}>
                  <ZenyumLogo />
                </Tooltip>
              </div>
            </button>
          )}
        </div>
      </If>
    </div>
  );
};

export default TpvInfoCard;
