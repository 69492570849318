import { FC } from 'react';

import { Modal } from 'components';
import Button from 'components/Button/Button';
import { translate } from 'utils';

interface Props {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  handleSubmit: () => void;
}
const BulkAddConfirmationBox: FC<Props> = ({
  isOpen,
  setIsOpen,
  handleSubmit
}) => {
  const handleClose = () => {
    setIsOpen(false);
  };
  const handleConfirm = () => {
    handleSubmit();
    handleClose();
  };
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className='w-full p-6 min-w-524px'>
        <p className='text-xl text-DEFAULT_TEXT'>
          {translate('chat.join_all')}
        </p>
        <p className='mt-6 text-base'>{translate('chat.want_join')}</p>
        <p className='text-base'>{translate('chat.bulk_add.description')}</p>
        <div className='flex justify-end mt-10 md:mt-5'>
          <Button
            onClick={handleClose}
            className={
              'border border-white text-DEFAULT_TEXT px-30px py-4 text-14px md:text-base md:px-8 md:py-3 xxs:w-full md:w-max'
            }
          >
            {translate('cancel.cancel')}
          </Button>
          <Button
            onClick={handleConfirm}
            className={
              'bg-PRIMARY text-WHITE px-30px py-4 text-14px md:text-base md:px-8 md:py-3 xxs:w-full md:w-max  ml-4'
            }
          >
            {translate('confirmNoShow.buttons.confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BulkAddConfirmationBox;
