import { FC, useState, ReactNode } from 'react';

import cx from 'classnames';
import moment from 'moment';
import { CalendarProps, CalendarTileProperties } from 'react-calendar';
import OutsideClickHandler from 'react-outside-click-handler';

import { CalendarIcon } from 'assets/icons';
import { ReactCalendar } from 'components';
import { If } from 'components/Generics';
import { DATE_FORMAT_CALENDAR } from 'constants/settings';
import { DateRange, DropdownPosition } from 'types/Appointment.types';
import { translate } from 'utils';

export interface DatePickerProps {
  value?: Date;
  onChange?: (date: Date) => void;
  placeholder?: string;
  calendarProps?: CalendarProps;
  background?: string;
  disabled?: boolean;
  control?: ReactNode;
  buttonClass?: string;
  inputHeight?: string;
  showCalendarIcon?: boolean;
  dateFormat?: string;
  dropdownPosition?: DropdownPosition;
  minDate?: Date;
  disabledDateRange?: DateRange[];
}
export const DatePicker: FC<DatePickerProps> = ({
  value,
  onChange,
  placeholder = translate('date_picker.select_date'),
  calendarProps,
  background,
  disabled = false,
  control,
  buttonClass,
  inputHeight,
  dateFormat = DATE_FORMAT_CALENDAR,
  showCalendarIcon = false,
  dropdownPosition = 'bottom',
  disabledDateRange = []
}) => {
  const [showCalendar, toggleCalendar] = useState(false);

  const isDateBetween = (date: Date, { startDate, endDate }: DateRange) => {
    return moment(date).isBetween(startDate, endDate, undefined, '[]');
  };

  const handleDisabledDates = ({ date }: CalendarTileProperties) => {
    return disabledDateRange.some((dateRange) =>
      isDateBetween(date, dateRange)
    );
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        toggleCalendar(false);
      }}
    >
      <div className={`relative ${inputHeight}`}>
        <button
          style={{ background }}
          type='button'
          data-testid='calendarButton'
          onClick={() => toggleCalendar(!showCalendar)}
          className={
            buttonClass ||
            'px-2 py-1 bg-GRAY rounded-4px w-full h-full truncate flex items-center justify-between'
          }
          disabled={disabled}
        >
          <span>
            {control ||
              (value ? moment(value).format(dateFormat) : placeholder)}
          </span>
          <If condition={showCalendarIcon}>
            <CalendarIcon />
          </If>
        </button>
        {showCalendar && (
          <div
            className={cx(
              'shadow-calendar rounded-4px z-30 bg-WHITE absolute',
              {
                'top-8px': dropdownPosition == 'bottom',
                'bottom-12': dropdownPosition == 'top'
              }
            )}
          >
            <ReactCalendar
              showNeighboringMonth={false}
              minDetail='month'
              value={value}
              onChange={(date: Date) => {
                toggleCalendar(false);
                if (onChange) onChange(date);
              }}
              tileDisabled={handleDisabledDates}
              {...calendarProps}
            />
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};
