import i18n from 'i18next';

import translationEn from './public/locales/en.json';
import translationEnHk from './public/locales/en_HK.json';
import translationEnMy from './public/locales/en_MY.json';
import translationId from './public/locales/id_ID.json';
import translationJp from './public/locales/ja_JP.json';
import translationMs from './public/locales/ms_MY.json';
import translationTh from './public/locales/th.json';
import translationVi from './public/locales/vi_VN.json';
import translationHk from './public/locales/zh_HK.json';
import translationZh from './public/locales/zh_TW.json';

// the translations ref: https://react.i18next.com/legacy-v9/step-by-step-guide
const resources = {
  en: {
    translation: translationEn
  },
  vi: {
    translation: translationVi
  },
  zh: {
    translation: translationZh
  },
  id: {
    translation: translationId
  },
  th: {
    translation: translationTh
  },
  mn: {
    translation: translationEn
  },
  ms: {
    translation: translationMs
  },
  ko: {
    translation: translationEn
  },
  ja: {
    translation: translationJp
  },
  hk: {
    translation: translationHk
  },
  'en-MY': {
    translation: translationEnMy
  },
  'en-HK': {
    translation: translationEnHk
  }
};

i18n.init({
  resources,
  fallbackLng: 'en',

  keySeparator: '.',
  nsSeparator: '|',

  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;
