import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { Button } from 'components';
import RhfInput from 'components/Generics/Rhf/RhfInput';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import { RHFDateRangePicker } from 'components/RHFDateRhangePicker/RHFDateRangePicker';
import UserInfoInput from 'components/UserInfoInput/UserInfoInput';
import { DATE_FORMAT_HYPHEN } from 'constants/settings';
import { LEAVE_TYPE_OPTIONS } from 'constants/userDetails';
import { AddLeaveInput, LeaveForm } from 'types/leave';
import { translate } from 'utils';
import { useLeaveHooks } from 'utils/customHooks/leave.customHooks';
import { formatDate } from 'utils/dateUtils';
import { AddLeaveValidation } from 'validations/AddLeaveValidation';

interface Props {
  userId?: string;
  isLoginedUser?: boolean;
  toggleAddLeave?: () => void;
  onCalendarOpen?: () => void;
}
const AddLeave: FC<Props> = ({
  userId,
  toggleAddLeave,
  isLoginedUser,
  onCalendarOpen
}) => {
  const { addLeave } = useLeaveHooks({ toggleAddLeave, isLoginedUser });
  const formMethods = useForm<LeaveForm>({
    mode: 'onChange',
    resolver: yupResolver(AddLeaveValidation)
  });

  const { handleSubmit } = formMethods;
  /**
   * formating date to UTC date with 00:00:00 and 23:59:59
   */
  const formatDateUtc = (startDate?: Date, endDate?: Date) => {
    const leaveDateRange = {
      startDate: '<DATE>T00:00:00Z',
      endDate: '<DATE>T23:59:59Z'
    };
    leaveDateRange.startDate = leaveDateRange.startDate.replace(
      '<DATE>',
      formatDate(startDate, DATE_FORMAT_HYPHEN)
    );
    leaveDateRange.endDate = leaveDateRange.endDate.replace(
      '<DATE>',
      formatDate(endDate, DATE_FORMAT_HYPHEN)
    );
    return leaveDateRange;
  };

  const submitHandler = (formValues: LeaveForm) => {
    const { startDate, endDate } = formatDateUtc(
      formValues.date.startDate,
      formValues.date.endDate
    );
    const addLeaveInput: AddLeaveInput = {
      type: formValues.type.value,
      endDate,
      startDate,
      description: formValues.description
    };
    addLeave(addLeaveInput, userId);
  };
  return (
    <div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className='flex justify-between '>
            <UserInfoInput
              label={translate('leave.type_of_leave')}
              isEditable
              className='w-full'
            >
              <RhfSelect
                name='type'
                options={LEAVE_TYPE_OPTIONS}
                inputProps={{
                  controlShouldRenderValue: true,
                  placeholder: translate('leave.select_leave_type')
                }}
              ></RhfSelect>
            </UserInfoInput>
            <UserInfoInput
              label={translate('leave.leave_date')}
              isEditable
              className='w-full ml-6'
            >
              <RHFDateRangePicker
                name='date'
                hideErrorMessage={false}
                inputProps={{
                  buttonClass:
                    'flex w-full h-full p-4 border border-LIGHT_GRAY_2 bg-WHITE',
                  minDate: new Date(),
                  onOpen: onCalendarOpen,
                  showYearSelection: false
                }}
              />
            </UserInfoInput>
            <UserInfoInput
              label={translate('leave.descriptions')}
              isEditable
              className='w-full ml-6'
            >
              <RhfInput
                name='description'
                showAnimated={false}
                className='bg-WHITE'
                inputProps={{
                  placeholder: translate('leave.type_away')
                }}
              />
            </UserInfoInput>
          </div>
          <div className='flex justify-end'>
            <Button
              className='text-PRIMARY border-PRIMARY border-1  px-4 py-2 mt-5 disabled:border-none'
              type='button'
              onClick={toggleAddLeave}
            >
              {translate('cancel.cancel')}
            </Button>
            <Button
              className='bg-PRIMARY text-WHITE px-4 py-2 mt-5 ml-2'
              type='submit'
            >
              {translate('leave.add_leaves')}
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default AddLeave;
