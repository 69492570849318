import { FC } from 'react';

import { RichTextInput } from 'types/Inbox.types';
import { formatDate, translate } from 'utils';

interface Props {
  richText: RichTextInput;
  message: any;
}

const UNSNOOZE_REASON_TRANSLATION_KEY: Record<string, string> = {
  USER_CANCEL: 'snooze.reason.user_cancel',
  NEW_MESSAGE: 'snooze.reason.new_message',
  SNOOZE_TIME_UP: 'snooze.reason.time_up'
};

const GroupSnoozeStatusMessageBlock: FC<Props> = ({ richText, message }) => {
  const { rich_text_details = [] } = richText;
  const [{ details }] = rich_text_details;
  const {
    is_snooze: isSnooze,
    name,
    snooze_until: snoozeUntil,
    unsnooze_reason: unsnoozeReason
  } = details;

  const getMessageText = () => {
    const createdDate = formatDate(message?.createdAt, 'h:mm A');

    if (isSnooze && snoozeUntil) {
      const formattedSnoozeUntil = formatDate(snoozeUntil, 'M/D/YYYY, h:mm A');
      return translate('snooze.message.notification', {
        snoozeUntil: formattedSnoozeUntil,
        createdUser: name,
        createdDate
      });
    } else if (unsnoozeReason) {
      const unsnoozeTranslation =
        UNSNOOZE_REASON_TRANSLATION_KEY[unsnoozeReason];
      return translate(unsnoozeTranslation, {
        createdUser: name,
        createdDate
      });
    }
  };

  const messageText = getMessageText();

  return (
    <div className='flex items-center justify-center text-xs md:text-sm break-words bg-GRAY5 text-DISABLED_2 rounded-md px-3 py-1 mx-10 md:mx-24'>
      <p className='table-cell text-center'>{messageText}</p>
    </div>
  );
};

export default GroupSnoozeStatusMessageBlock;
