import { FC, useEffect, useMemo, useState } from 'react';

import { ArrowRight } from 'assets/icons';
import { TreatmentProgressChips } from 'components';
import { If } from 'components/Generics';
import { CanAccess } from 'hoc/can-access/CanAccess';
import { translate } from 'utils';
import { TREATMENT_STAGES } from 'utils/common';

import MenuTour from './MenuTour/MenuTour';
import {
  AllStages,
  getProgressMenuOptions,
  ProgressMenuOptions
} from './TreatmentMenuConfig';

type TreatmentProgressMenuProp = {
  showFeatureWalkthrough?: boolean;
  currentStage?: AllStages;
  onClick: (stage: AllStages) => void;
  showGuide?: boolean;
  stage?: AllStages;
  treatmentType: string;
};

const TreatmentProgressMenu: FC<TreatmentProgressMenuProp> = ({
  showFeatureWalkthrough,
  currentStage,
  onClick,
  showGuide = false,
  stage,
  treatmentType
}) => {
  const [activeStage, setActiveStage] = useState(currentStage);
  const [runGuide, setRunGuide] = useState(false);
  const progressMenuOptions: ProgressMenuOptions[] = useMemo(
    () => getProgressMenuOptions(stage, treatmentType),
    [stage, treatmentType]
  );

  useEffect(() => {
    setActiveStage(currentStage);
  }, [currentStage]);

  const indexOfCurrentStage = progressMenuOptions.findIndex(
    ({ menuKey }) => menuKey === stage
  );

  const getMenuState = (menuKey: string, index: number) => {
    const isCurrentKeyEOT =
      menuKey === TREATMENT_STAGES.END_OF_TREATMENT_REVIEW;
    const isActiveStageInTreatment = stage === TREATMENT_STAGES.IN_TREATMENT;
    const enableEOTonIntreatment = isCurrentKeyEOT && isActiveStageInTreatment;
    if (menuKey === activeStage) return 'ACTIVE';
    else if (enableEOTonIntreatment) return '';
    else if (index > indexOfCurrentStage) return 'DISABLED';
    return '';
  };

  const handleMenuClick = (menuKey: AllStages) => {
    onClick(menuKey);
    setActiveStage(menuKey);
  };

  useEffect(() => {
    showGuide && setRunGuide(true);
  }, [showGuide]);

  return (
    <>
      <If condition={showGuide || !!showFeatureWalkthrough}>
        <MenuTour
          run={runGuide && showGuide}
          showFeatureWalkthrough={!!showFeatureWalkthrough}
        />
      </If>
      <div className='flex items-center space-x-3.5 w-full overflow-auto pb-2 lg:pb-0 stepper-class relative'>
        {progressMenuOptions.map(({ menuKey, menuName, permission }, index) => {
          return (
            <CanAccess
              key={menuKey}
              permission={permission}
              yes={
                <>
                  <TreatmentProgressChips
                    onClick={() => handleMenuClick(menuKey)}
                    id={menuKey}
                    state={getMenuState(menuKey, index)}
                    preventAutoScroll={showGuide}
                  >
                    {translate(menuName)}
                  </TreatmentProgressChips>
                  <ArrowRight className='h-4 w-4 flex-shrink-0 last:hidden' />
                </>
              }
            />
          );
        })}
      </div>
    </>
  );
};

export default TreatmentProgressMenu;
