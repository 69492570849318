import StackTrace from 'stacktrace-js';

import { ERROR } from 'constants/segment';
import { segmentTrackEvent } from 'utils/common';
const useLogError = () => {
  const logError = (error: Error) => {
    StackTrace.fromError(error).then((err) => {
      const errorLog = {
        errorStack: err,
        errorMessage: error.toString()
      };
      segmentTrackEvent(ERROR.FE_404_ERROR, errorLog);
    });
  };
  return { logError };
};

export default useLogError;
