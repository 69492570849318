import classNames from 'classnames';
import { extendTailwindMerge } from 'tailwind-merge';

import * as customTheme from '../theme/customThemes';

const customTwMerge = extendTailwindMerge({
  classGroups: {
    text: [{ text: Object.keys(customTheme.fontSize) }]
  }
});

export const twCx = (...args: classNames.ArgumentArray) =>
  customTwMerge(classNames(args));

export default customTwMerge;
