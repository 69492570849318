import { FC, useState } from 'react';

import classNames from 'classnames';

import { Button } from 'components';
import ConfirmationModal from 'components/TreatmentVersionCard/ConfirmationModal/ConfirmationModal';
import { KEEP_PLAN_OPENED } from 'constants/common';
import { TREATMENT_PLAN_VERSION_ACTIONS } from 'constants/options';

interface Props {
  buttonType?: string;
  planVersionId?: string;
  treatmentPlanName?: string;
  treatmentPlanId?: string;
  planVersionName: string;
  patientId: string;
  disabled: boolean;
}

export const ReOpenPLan: FC<Props> = ({
  buttonType,
  planVersionId,
  treatmentPlanName,
  planVersionName,
  treatmentPlanId,
  patientId,
  disabled = false
}) => {
  const [reopenPlanPopup, setReopenPlanPopup] = useState(false);
  const onClickReOpen = () => {
    setReopenPlanPopup(true);
  };
  return (
    <>
      <ConfirmationModal
        isOpen={reopenPlanPopup}
        onClose={() => setReopenPlanPopup(false)}
        planVersionId={planVersionId}
        treatmentPlanName={treatmentPlanName}
        planVersionName={planVersionName}
        treatmentPlanId={treatmentPlanId}
        title='Re-opening Rejected TPV'
        mainText={`Please confirm that you like to re-open the rejected TPV -`}
        textBoxTitle='To raise a ZenChat and inform Doctor, please enter your comment here'
        acceptanceStatus={TREATMENT_PLAN_VERSION_ACTIONS.PENDING_DOCTOR_REVIEW}
        isReopen={true}
        patientId={patientId}
      />
      {buttonType === KEEP_PLAN_OPENED ? (
        <button
          disabled={disabled}
          onClick={onClickReOpen}
          className='text-PRIMARY text-sm text-left ml-6 underline disabled:text-DISABLED_2'
        >
          Keep this plan open
        </button>
      ) : (
        <Button
          disabled={disabled}
          onClick={onClickReOpen}
          className={classNames(
            'rounded-full px-2 py-4 text-sm w-full border border-DEFAULT_TEXT disabled:border-none h-12'
          )}
        >
          Reopen TPV
        </Button>
      )}
    </>
  );
};
