import { Option } from 'types/select';

import { translate } from './../../utils/locale';

export const leftBottomTeethOptions: Option[] = [
  { value: '3.1' },
  { value: '3.2' },
  { value: '3.3' },
  { value: '3.4' },
  { value: '3.5' },
  { value: '3.6' },
  { value: '3.7' },
  { value: '3.8' }
].map(({ value }) => ({ label: value, value }));

export const leftTopTeethOptions: Option[] = [
  { value: '2.1' },
  { value: '2.2' },
  { value: '2.3' },
  { value: '2.4' },
  { value: '2.5' },
  { value: '2.6' },
  { value: '2.7' },
  { value: '2.8' }
].map(({ value }) => ({ label: value, value }));

export const priorTreatmentNeededExtraOptions: Option[] = [
  { value: 'NONE', label: translate('prescriptions.none') },
  { value: 'OTHERS', label: translate('prescriptions.others') }
];

export const rigthBottomTeethOptions: Option[] = [
  { value: '4.8' },
  { value: '4.7' },
  { value: '4.6' },
  { value: '4.5' },
  { value: '4.4' },
  { value: '4.3' },
  { value: '4.2' },
  { value: '4.1' }
].map(({ value }) => ({ label: value, value }));

export const rigthTopTeethOptions: Option[] = [
  { value: '1.8' },
  { value: '1.7' },
  { value: '1.6' },
  { value: '1.5' },
  { value: '1.4' },
  { value: '1.3' },
  { value: '1.2' },
  { value: '1.1' }
].map(({ value }) => ({ label: value, value }));

export const teethExtractionInstructions: Option[] = [
  {
    value: 'NO_TEETH_TO_EXTRACT',
    label: translate('prescriptions.no_teeth_to_extract')
  },
  {
    value: 'TEETH_TO_EXTRACT',
    label: translate('prescriptions.teeth_to_extract')
  }
];

export const treatmentPlanType: Option[] = [
  {
    label: translate('prescriptions.zenyumclear'),
    value: 'ZENCLEAR'
  },
  {
    label: translate('prescriptions.zenyumclear_plus'),
    value: 'ZENCLEAR_PLUS'
  },
  {
    label: translate('prescriptions.both'),
    value: 'both'
  }
];

export const yesOrNoOptions: Option[] = [
  { value: 'YES', label: translate('yes.yes') },
  { value: 'NO', label: translate('no.no') }
];

export const helpNeeded: Option[] = [{ value: 'YES' }, { value: 'NO' }].map(
  ({ value }) => ({
    label: translate(`TREATMENT_PLANNING.HELP_NEEDED.OPTIONS.${value}`),
    value
  })
);

export const errorListMapper = [
  {
    field: 'priorTreatment',
    label: 'prescriptions.1._prior_treatment_needed',
    innerFields: [
      {
        field: 'treatments',
        label: ''
      },
      {
        field: 'canPlanningProceed',
        label: 'prescriptions.can_treatment_planning_proceed?'
      },
      {
        field: 'completed',
        label: 'button_rounded_(short).prior_treatment_completed'
      }
    ]
  },
  {
    field: 'treatmentSpecifications',
    label: 'prescriptions.2._treatment_specifications',
    innerFields: [
      {
        field: 'treatmentSpecAttachments',
        label: 'prescriptions.attachments'
      },
      {
        field: 'iprLocation',
        label: 'prescriptions.ipr_location'
      },
      {
        field: 'crowdingResolvement',
        label: 'prescriptions.resolve_crowding'
      },
      {
        field: 'teethExtractionInstructions',
        label: 'prescriptions.teeth_extraction_instructions'
      },
      {
        field: 'teethToExtract',
        label: 'prescriptions.teeth_to_extract'
      },
      {
        field: 'treatmentPlanTypes',
        label: 'prescriptions.treatment_plan_type'
      },
      {
        field: 'treatmentPlanInstructions',
        label: 'prescriptions.ZenClear_treatment_plan_instructions'
      },
      {
        field: 'zenplusInstructions',
        label: 'prescriptions.ZenPlus_treatment_plan_instructions'
      }
    ]
  },
  {
    field: 'treatmentFiles',
    label: 'prescriptions.3._patient_files',
    innerFields: [
      {
        field: 'photo',
        label: 'photos.photos'
      },
      {
        field: 'consent',
        label: 'consent_form.consent_form'
      },
      {
        field: 'xray',
        label: 'x-rays.x-rays'
      },
      {
        field: 'scan',
        label: 'scans.scans'
      }
    ]
  }
];
export const PrescriptionExternalErrorListMapper: {
  field: string;
  label: string;
  innerFields: {
    field: string;
    label: string;
    animateLabel?: string;
  }[];
  animateLabel?: string;
}[] = [
  {
    field: 'priorTreatment',
    label: 'prescriptions.1._prior_treatment_needed',
    innerFields: [
      {
        field: 'treatments',
        label: ''
      },
      {
        field: 'canPlanningProceed',
        label: 'prescriptions.can_treatment_planning_proceed?'
      },
      {
        field: 'completed',
        label: 'button_rounded_(short).prior_treatment_completed'
      }
    ]
  },
  {
    field: 'treatmentSpecifications',
    label: 'prescriptions.2._treatment_specifications',
    innerFields: [
      {
        field: 'treatmentType',
        label: 'aftersales_appointment.treatment_type'
      },
      {
        field: 'ZenClear.treatmentGoals.treatmentGoals',
        label: '1.Treatment.goals'
      },
      {
        field: 'ZenClear.archesToTreat',
        label: '2.Arches.to.treat'
      },
      {
        field: 'ZenClear.toothMovementRestrictions.toothMovementRestrictions',
        label: '3.Tooth.movement.restrictions'
      },
      {
        field: 'ZenClear.toothMovementRestrictions.teethNotToMove',
        label: '3.Tooth.movement.restrictions'
      },
      {
        field: 'ZenClear.prosthesis.prosthesis',
        label: '4.Prosthesis.Restorations',
        animateLabel: 'ZenClear.prosthesis.prosthesis'
      },
      {
        field: 'ZenClear.prosthesis.iprOrAttachment',
        label: '4.Prosthesis.Restorations',
        animateLabel: 'ZenClear.prosthesis.prosthesis'
      },
      {
        field: 'ZenClear.prosthesis.prosthesisIprToAvoid',
        label: '4.Prosthesis.Restorations.IPR.to.avoid',
        animateLabel: 'ZenClear.prosthesis.prosthesis'
      },
      {
        field: 'ZenClear.prosthesis.prosthesisAttachmentToAvoid',
        label: '4.Prosthesis.Restorations .Attachment.to.avoid',
        animateLabel: 'ZenClear.prosthesis.prosthesis'
      },

      {
        field: 'ZenClear.extractTeeth.extractTeeth',
        label: '5.Teeth.Extractions'
      },
      {
        field: 'ZenClear.extractTeeth.teethToExtract',
        label: '5.Teeth.Extractions'
      },

      {
        field: 'ZenClear.specialNotes',
        label: '6.Special.Notes'
      },

      {
        field: 'ZenPlus.treatmentGoals.treatmentGoals',
        label: '1.Treatment.goals',
        animateLabel: 'ZenPlus.treatmentGoals.treatmentGoals'
      },
      {
        field: 'ZenPlus.treatmentGoals.treatmentGoalsProcedures',
        label: '1.Treatment.goals',
        animateLabel: 'ZenPlus.treatmentGoals.treatmentGoals'
      },
      {
        field: 'ZenPlus.treatmentGoals.proceduresInnerFields',
        label: '1.Treatment.goals',
        animateLabel: 'ZenPlus.treatmentGoals.treatmentGoals'
      },
      {
        field: 'ZenPlus.treatmentGoals.upperLowerProcedures',
        label: '1.Treatment.goals',
        animateLabel: 'ZenPlus.treatmentGoals.treatmentGoals'
      },
      {
        field: 'ZenPlus.archesToTreat',
        label: '2.Arches.to.treat'
      },
      {
        field: 'ZenPlus.toothMovementRestrictions.toothMovementRestrictions',
        label: '3.Tooth.movement.restrictions'
      },
      {
        field: 'ZenPlus.toothMovementRestrictions.teethNotToMove',
        label: '3.Tooth.movement.restrictions'
      },

      {
        field: 'ZenPlus.overbite',
        label: '4.Overjet'
      },
      {
        field: 'ZenPlus.midlines.midlines',
        label: '5.Midlines'
      },
      {
        field: 'ZenPlus.midlines.shiftUpperPosition',
        label: '5.Midlines.Shift.Upper',
        animateLabel: 'ZenPlus.midlines.midlines'
      },
      {
        field: 'ZenPlus.midlines.shiftLowerPosition',
        label: '5.Midlines.Shift.Lower',
        animateLabel: 'ZenPlus.midlines.midlines'
      },
      {
        field: 'ZenPlus.midlines.shiftUpper',
        label: '5.Midlines',
        animateLabel: 'ZenPlus.midlines.midlines'
      },
      {
        field: 'ZenPlus.midlines.shiftLower',
        label: '5.Midlines',
        animateLabel: 'ZenPlus.midlines.midlines'
      },

      {
        field: 'ZenPlus.extractTeeth.extractTeeth',
        label: '6.Teeth.Extractions'
      },
      {
        field: 'ZenPlus.extractTeeth.teethToExtract',
        label: '6.Teeth.Extractions'
      },

      {
        field: 'ZenPlus.prosthesis.prosthesis',
        label: '7.Prosthesis.Restorations',
        animateLabel: 'ZenPlus.prosthesis.prosthesis'
      },
      {
        field: 'ZenPlus.prosthesis.iprOrAttachment',
        label: '4.Prosthesis.Restorations',
        animateLabel: 'ZenPlus.prosthesis.prosthesis'
      },
      {
        field: 'ZenPlus.prosthesis.prosthesisIprToAvoid',
        label: '7.Prosthesis.Restorations.IPR.to.avoid',
        animateLabel: 'ZenPlus.prosthesis.prosthesis'
      },
      {
        field: 'ZenPlus.prosthesis.prosthesisAttachmentToAvoid',
        label: '7.Prosthesis.Restorations.Attachment.to.avoid',
        animateLabel: 'ZenPlus.prosthesis.prosthesis'
      },
      {
        field: 'ZenPlus.specialNotes',
        label: '8.Special Notes'
      }
    ]
  },
  {
    field: 'clinicalPreferences',
    label: '3.clinical.preferences',
    innerFields: [
      {
        field: 'ZenClear.iprSize',
        label: 'ipr.size'
      },
      {
        field: 'ZenClear.iprLocation',
        label: 'ipr.location'
      },
      {
        field: 'ZenPlus.iprSize',
        label: 'ipr.size'
      },
      {
        field: 'ZenPlus.iprLocation',
        label: 'ipr.location'
      },
      {
        field: 'ZenPlus.biteRamps',
        label: 'prescription.bite.ramps'
      },
      {
        field: 'ZenPlus.premolarCrossbite',
        label: 'prescription.premolar.crossbite'
      },
      {
        field: 'ZenPlus.extrusionElastics',
        label: 'prescription.extrusion.elastics'
      }
    ]
  },
  {
    field: 'treatmentFiles',
    label: '4. Patient files',
    innerFields: [
      {
        field: 'photo',
        label: 'photos.photos'
      },
      {
        field: 'consent',
        label: 'consent_form.consent_form'
      },
      {
        field: 'xray',
        label: 'x-rays.x-rays'
      },
      {
        field: 'scan',
        label: 'scans.scans'
      }
    ]
  }
];
export const PrescriptionExternalRefinementErrorListMapper: {
  field: string;
  label: string;
  innerFields: {
    field: string;
    label: string;
    animateLabel?: string;
  }[];
  animateLabel?: string;
}[] = [
  {
    field: 'treatmentSpecifications',
    label: 'refinement_prescriptions.1._treatment_specifications',
    innerFields: [
      {
        field: 'ZenClear.reasonForSubmission',
        label: '1.Reason.for.submission*'
      },
      {
        field: 'ZenClear.alignerInputUpperZenstage',
        label: '2.what.aligner.upper',
        animateLabel: 'ZenClear.AlignerInput'
      },
      {
        field: 'ZenClear.alignerInputUpperSet',
        label: '2.what.aligner.upper',
        animateLabel: 'ZenClear.AlignerInput'
      },
      {
        field: 'ZenClear.alignerInputLowerSet',
        label: '2.what.aligner.lower',
        animateLabel: 'ZenClear.AlignerInput'
      },
      {
        field: 'ZenClear.alignerInputLowerZenstage',
        label: '2.what.aligner.lower',
        animateLabel: 'ZenClear.AlignerInput'
      },
      {
        field: 'ZenClear.radioUpper',
        label: '2.what.aligner.upper',
        animateLabel: 'ZenClear.AlignerInput'
      },
      {
        field: 'ZenClear.radioLower',
        label: '2.what.aligner.lower',
        animateLabel: 'ZenClear.AlignerInput'
      },

      {
        field: 'ZenClear.archesToTreat',
        label: '3.Arches.to.treat'
      },
      {
        field: 'ZenClear.toothChart2',
        label: '4.Refinement.goals',
        animateLabel: 'ZenClear.Refinement_Goals'
      },
      {
        field: 'ZenClear.attachmentsOnly',
        label: '4.Refinement.goals',
        animateLabel: 'ZenClear.Refinement_Goals'
      },
      {
        field: 'ZenClear.refinementGoals',
        label: '4.Refinement.goals',
        animateLabel: 'ZenClear.Refinement_Goals'
      },
      {
        field: 'ZenClear.attachments',
        label: '5. Attachments'
      },
      {
        field: 'ZenClear.attachmentsOnlyCheck',
        label: '4.Refinement.goals',
        animateLabel: 'ZenClear.Refinement_Goals'
      },
      {
        field: 'ZenClear.IprPerformedDropDown',
        label: '6. IPR performed for previous treatment plan',
        animateLabel: 'ZenClear.IprPerformedDropDown'
      },
      {
        field: 'ZenClear.iprPerformed',
        label: '6. IPR performed for previous treatment plan',
        animateLabel: 'ZenClear.IprPerformedDropDown'
      },
      {
        field: 'ZenClear.toothChart3',
        label: '6. IPR performed for previous treatment plan',
        animateLabel: 'ZenClear.IprPerformedDropDown'
      },
      {
        field: 'ZenClear.specialNotes',
        label: '9.special_notes'
      },
      {
        field: 'ZenPlus.reasonForSubmission',
        label: '1.Reason.for.submission*'
      },
      {
        field: 'ZenPlus.alignerInputUpperZenstage',
        label: '2.what.aligner.upper',
        animateLabel: 'ZenPlus.AlignerInput'
      },
      {
        field: 'ZenPlus.alignerInputUpperSet',
        label: '2.what.aligner.upper',
        animateLabel: 'ZenPlus.AlignerInput'
      },
      {
        field: 'ZenPlus.alignerInputLowerSet',
        label: '2.what.aligner.lower',
        animateLabel: 'ZenPlus.AlignerInput'
      },
      {
        field: 'ZenPlus.alignerInputLowerZenstage',
        label: '2.what.aligner.lower',
        animateLabel: 'ZenPlus.AlignerInput'
      },
      {
        field: 'ZenPlus.radioUpper',
        label: '2.what.aligner.upper',
        animateLabel: 'ZenPlus.AlignerInput'
      },
      {
        field: 'ZenPlus.radioLower',
        label: '2.what.aligner.lower',
        animateLabel: 'ZenPlus.AlignerInput'
      },

      {
        field: 'ZenPlus.archesToTreat',
        label: '3.Arches.to.treat'
      },
      {
        field: 'ZenPlus.toothChart2',
        label: '4.Refinement.goals',
        animateLabel: 'ZenPlus.Refinement_Goals'
      },
      {
        field: 'ZenPlus.attachmentsOnly',
        label: '4.Refinement.goals',
        animateLabel: 'ZenPlus.Refinement_Goals'
      },
      {
        field: 'ZenPlus.attachmentsOnlyCheck',
        label: '4.Refinement.goals',
        animateLabel: 'ZenPlus.Refinement_Goals'
      },
      {
        field: 'ZenPlus.addButtonsForElasticsCheck',
        label: '4.Refinement.goals',
        animateLabel: 'ZenPlus.Refinement_Goals'
      },
      {
        field: 'ZenPlus.buttonsForElastics',
        label: '4.Refinement.goals',
        animateLabel: 'ZenPlus.Refinement_Goals'
      },
      {
        field: 'ZenPlus.refinementGoals',
        label: '4.Refinement.goals',
        animateLabel: 'ZenPlus.Refinement_Goals'
      },
      {
        field: 'ZenPlus.attachments',
        label: '5. Attachments'
      },
      {
        field: 'ZenPlus.IprPerformedDropDown',
        label: '6. IPR performed for previous treatment plan',
        animateLabel: 'ZenPlus.IprPerformedDropDown'
      },
      {
        field: 'ZenPlus.iprPerformed',
        label: '6. IPR performed for previous treatment plan',
        animateLabel: 'ZenPlus.IprPerformedDropDown'
      },
      {
        field: 'ZenPlus.toothChart3',
        label: '6. IPR performed for previous treatment plan',
        animateLabel: 'ZenPlus.IprPerformedDropDown'
      },
      {
        field: 'ZenPlus.specialNotes',
        label: '9.special_notes'
      }
    ]
  },
  {
    field: 'clinicalPreferences',
    label: '2.clinical.preferences',
    innerFields: [
      {
        field: 'Refinement.iprSize',
        label: 'ipr.size'
      },
      {
        field: 'Refinement.iprLocation',
        label: 'ipr.location'
      }
    ]
  },
  {
    field: 'treatmentFiles',
    label: 'prescriptions.3._patient_files',
    innerFields: [
      {
        field: 'photo',
        label: 'photos.photos'
      },
      {
        field: 'consent',
        label: 'consent_form.consent_form'
      },
      {
        field: 'xray',
        label: 'x-rays.x-rays'
      },
      {
        field: 'scan',
        label: 'scans.scans'
      }
    ]
  }
];
export const zenclearErrorListMapper = [
  {
    field: 'treatmentGoals',
    label: '1.Treatment.goals',
    innerFields: []
  },
  {
    field: 'archesToTreat',
    label: '2.Arches.to.treat',
    innerFields: []
  },
  {
    field: 'toothMovementRestrictions',
    label: '3.Tooth.movement.restrictions',
    innerFields: []
  },
  {
    field: 'teethNotToMove',
    label: '3.Tooth.movement.restrictions',
    innerFields: []
  },
  {
    field: 'prosthesis',
    label: '4.Prosthesis.Restorations',
    innerFields: []
  },
  {
    field: 'ipr',
    label: '4.Prosthesis.Restorations',
    innerFields: [],
    animateLabel: 'prosthesis'
  },
  {
    field: 'prosthesisIprToAvoid',
    label: '4.Prosthesis.Restorations.IPR.to.avoid',
    innerFields: []
  },
  {
    field: 'prosthesisAttachmentToAvoid',
    label: '4.Prosthesis.Restorations .Attachment.to.avoid',
    innerFields: []
  },
  {
    field: 'extractTeeth',
    label: '5.Teeth.Extractions',
    innerFields: []
  },
  {
    field: 'teethToExtract',
    label: '5.Teeth.Extractions',
    innerFields: []
  },
  {
    field: 'treatmentPlanningLab',
    label: '6. Treatment Planning Lab',
    innerFields: []
  },
  {
    field: 'manufacturer',
    label: '7. Manufacturer',
    innerFields: []
  },
  {
    field: 'specialNotes',
    label: '8. Special Notes',
    innerFields: []
  }
];

export const zenplusErrorListMapper = [
  {
    field: 'treatmentGoals',
    label: '1.Treatment.goals',
    innerFields: []
  },
  {
    field: 'treatmentGoalsProcedures',
    label: '1.Treatment.goals',
    innerFields: [],
    animateLabel: 'treatmentGoals'
  },
  {
    field: 'proceduresInnerFields',
    label: '1.Treatment.goals',
    innerFields: [],
    animateLabel: 'treatmentGoals'
  },
  {
    field: 'upperLowerProcedures',
    label: '1.Treatment.goals',
    innerFields: [],
    animateLabel: 'treatmentGoals'
  },
  {
    field: 'archesToTreat',
    label: '2.Arches.to.treat',
    innerFields: []
  },
  {
    field: 'toothMovementRestrictions',
    label: '3.Tooth.movement.restrictions',
    innerFields: []
  },
  {
    field: 'teethNotToMove',
    label: '3.Tooth.movement.restrictions',
    innerFields: []
  },
  {
    field: 'overbite',
    label: '4.Overjet',
    innerFields: []
  },
  {
    field: 'midlines',
    label: '5.Midlines',
    innerFields: []
  },
  {
    field: 'shiftUpperPosition',
    label: '5.Midlines.Shift.Upper',
    innerFields: [],
    animateLabel: 'midlines'
  },
  {
    field: 'shiftLowerPosition',
    label: '5.Midlines.Shift.Lower',
    innerFields: [],
    animateLabel: 'midlines'
  },
  {
    field: 'shiftUpper',
    label: '5.Midlines',
    innerFields: [],
    animateLabel: 'midlines'
  },
  {
    field: 'extractTeeth',
    label: '6.Teeth.Extractions',
    innerFields: []
  },
  {
    field: 'teethToExtract',
    label: '6.Teeth.Extractions',
    innerFields: []
  },
  {
    field: 'prosthesis',
    label: '7.Prosthesis.Restorations',
    innerFields: []
  },
  {
    field: 'ipr',
    label: '7.Prosthesis.Restorations',
    innerFields: [],
    animateLabel: 'prosthesis'
  },
  {
    field: 'prosthesisIprToAvoid',
    label: '7.Prosthesis.Restorations.IPR.to.avoid',
    innerFields: []
  },
  {
    field: 'prosthesisAttachmentToAvoid',
    label: '7.Prosthesis.Restorations.Attachment.to.avoid',
    innerFields: []
  },
  {
    field: 'treatmentPlanningLab',
    label: '8. Treatment Planning Lab',
    innerFields: []
  },
  {
    field: 'manufacturer',
    label: '9. Manufacturer',
    innerFields: []
  },
  {
    field: 'specialNotes',
    label: '10.special_notes',
    innerFields: []
  }
];
export const zenClearRefinementFormErrorListMapper = [
  {
    field: 'reasonForSubmission',
    label: '1.Reason.for.submission*',
    innerFields: []
  },
  {
    field: 'AlignerInput',
    label: '2.What.aligner',
    innerFields: [
      {
        field: 'alignerInputUpperZenstage',
        label: 'Upper',
        innerFields: [],
        animateLabel: 'AlignerInput'
      },
      {
        field: 'alignerInputUpperSet',
        label: 'Upper',
        innerFields: [],
        animateLabel: 'AlignerInput'
      },
      {
        field: 'alignerInputLowerSet',
        label: 'refinementForm.Lower',
        innerFields: [],
        animateLabel: 'AlignerInput'
      },
      {
        field: 'alignerInputLowerZenstage',
        label: 'refinementForm.Lower',
        innerFields: [],
        animateLabel: 'AlignerInput'
      },
      {
        field: 'radioUpper',
        label: 'Upper',
        innerFields: [],
        animateLabel: 'AlignerInput'
      },
      {
        field: 'radioLower',
        label: 'refinementForm.Lower',
        innerFields: [],
        animateLabel: 'AlignerInput'
      }
    ]
  },

  {
    field: 'archesToTreat',
    label: '3.Arches.to.treat',
    innerFields: []
  },
  {
    field: 'toothChart2',
    label: '4.Refinement.goals',
    innerFields: [],
    animateLabel: 'Refinement_Goals'
  },
  {
    field: 'attachmentsOnly',
    label: '4.Refinement.goals',
    innerFields: [],
    animateLabel: 'Refinement_Goals'
  },
  {
    field: 'attachmentsOnlyCheck',
    label: '4.Refinement.goals',
    innerFields: [],
    animateLabel: 'Refinement_Goals'
  },
  {
    field: 'buttonsForElastics',
    label: '4.Refinement.goals',
    innerFields: [],
    animateLabel: 'Refinement_Goals'
  },
  {
    field: 'refinementGoals',
    label: '4.Refinement.goals',
    innerFields: [],
    animateLabel: 'Refinement_Goals'
  },
  {
    field: 'attachments',
    label: '5. Attachments',
    innerFields: []
  },
  {
    field: 'IprPerformedDropDown',
    label: '6. IPR performed for previous treatment plan',
    innerFields: []
  },
  {
    field: 'iprPerformed',
    label: '6. IPR performed for previous treatment plan',
    innerFields: [],
    animateLabel: 'IprPerformedDropDown'
  },
  {
    field: 'toothChart3',
    label: '6. IPR performed for previous treatment plan',
    innerFields: [],
    animateLabel: 'IprPerformedDropDown'
  },
  {
    field: 'treatmentPlanningLab',
    label: '7. Treatment Planning Lab',
    innerFields: []
  },
  {
    field: 'manufacturer',
    label: '8. Manufacturer',
    innerFields: []
  },
  {
    field: 'specialNotes',
    label: '9.special_notes',
    innerFields: []
  }
];

export const refinementErrorListMapper = [
  {
    field: 'treatmentPlanInstructions',
    label: 'prescriptions.ZenClear_treatment_plan_instructions',
    innerFields: []
  },
  {
    field: 'zenplusInstructions',
    label: 'prescriptions.ZenPlus_treatment_plan_instructions',
    innerFields: []
  },
  {
    field: 'photo',
    label: 'photos.photos',
    innerFields: []
  },
  {
    field: 'xray',
    label: 'x-rays.x-rays',
    innerFields: []
  },
  {
    field: 'scan',
    label: 'scans.scans',
    innerFields: []
  }
];
