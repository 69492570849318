import { FC, useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { RhfRadioGroup, RHFTextArea, AnimateLabel } from 'components';
import If from 'components/Generics/If/If';
import { pagePaths } from 'config/pages';
import { BothTreatmentPlans, ZenClear, ZenPlus } from 'constants/common';
import { TEETH_EXTRACTION_INSTRUCTIONS } from 'constants/options';
import { PERMISSIONS } from 'constants/Permissions';
import { TRACK_EVENTS } from 'constants/segment';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { CanAccess } from 'hoc/can-access/CanAccess';
import { useAppApolloClient } from 'services';
import { useGetTreatmentSpecficationOptions } from 'services/hooks/TreatmentPlanning.hooks';
import { GET_TREATMENT_PLANS_OPTIONS } from 'services/query/treatmentPlanning';
import { Option } from 'types/select';
import { TreatmentPlanOptions } from 'types/TreatmentPlanning.types';
import { customTwMerge, translate } from 'utils';

import RhfTeethToExtract from './TeethToExtract/RhfTeethToExtract/RhfTeethToExtract';

type Props = {
  isReferral?: boolean;
  doctorComplexity?: number;
  save?: () => void;
  className?: string;
};

const TreatmentSpecifications: FC<Props> = ({
  isReferral = false,
  doctorComplexity,
  className = 'bg-white',
  save
}) => {
  const { animatedField, callback } = useFieldRefContext();
  const client = useAppApolloClient();
  const [treatmentPlanOptions, setTreatmentPlanOptions] = useState<Option[]>(
    []
  );
  const {
    watch,
    setValue,
    formState: { errors, isSubmitted },
    trigger
  } = useFormContext();
  const watchTeethExtractionInstructions: string = watch(
    'treatmentSpecifications.teethExtractionInstructions'
  );
  const watchDoctorInReferrals = watch('referringDoctor');
  const planType: string = watch('treatmentSpecifications.treatmentPlanTypes');
  const isZenplus = planType === ZenPlus;
  const isBoth = planType === BothTreatmentPlans;
  const isZenClear = planType === ZenClear;
  const {
    data: {
      getAttachementOptions,
      getCrowdingTreatmentOptions,
      getIPRLocations
    } = {
      getAttachementOptions: [],
      getCrowdingTreatmentOptions: [],
      getIPRLocations: []
    }
  } = useGetTreatmentSpecficationOptions();

  useEffect(() => {
    if (isReferral ? watchDoctorInReferrals?.id : true) {
      const getTreatmentPlans = async () => {
        try {
          const { data } = await client.query<TreatmentPlanOptions, any>({
            query: GET_TREATMENT_PLANS_OPTIONS,
            variables: {
              input: {
                maxComplexity: isReferral
                  ? watchDoctorInReferrals?.maxComplexity
                  : doctorComplexity
              }
            },
            fetchPolicy: 'no-cache'
          });
          setTreatmentPlanOptions(data?.getAllTreatmentTypes || []);
        } catch (error) {
          console.log(error);
        }
      };
      getTreatmentPlans();
    }
  }, [
    watchDoctorInReferrals?.id,
    watchDoctorInReferrals?.maxComplexity,
    doctorComplexity,
    isReferral
  ]);

  useEffect(() => {
    isSubmitted && trigger('treatmentSpecifications.teethToExtract');
  }, [watchTeethExtractionInstructions, trigger, isSubmitted]);

  useEffect(() => {
    isSubmitted &&
      trigger([
        'treatmentSpecifications.treatmentPlanInstructions',
        'treatmentSpecifications.zenplusInstructions'
      ]);
  }, [planType, trigger, isSubmitted]);

  const handleEditTemplateClick = () => {
    if (!isReferral)
      window.analytics.track(TRACK_EVENTS.TPPT_EDITED, {
        source_type: 'Pending prescription page'
      });
  };

  const formError = (errors?.treatmentSpecifications as any) || {};
  return (
    <div className={customTwMerge('bg-white', className)}>
      <div className='mt-6'>
        <RhfRadioGroup
          title={translate('prescriptions.attachments')}
          options={getAttachementOptions}
          name='treatmentSpecifications.treatmentSpecAttachments'
          className='mt-6'
          radioClassName='border-RADIO_BORDER'
          radioItemFont='md:text-17px text-sm'
        />
      </div>
      <div className='mt-6'>
        <RhfRadioGroup
          options={getIPRLocations}
          title={translate('prescriptions.ipr_location')}
          className='mt-4'
          name='treatmentSpecifications.iprLocation'
          radioClassName='border-RADIO_BORDER'
          radioItemFont='md:text-17px text-sm'
        />
      </div>
      <div className='mt-6'>
        <RhfRadioGroup
          title={translate('prescriptions.resolve_crowding')}
          options={getCrowdingTreatmentOptions}
          name='treatmentSpecifications.crowdingResolvement'
          className='mt-6'
          radioClassName='border-RADIO_BORDER'
          radioItemFont='md:text-17px text-sm'
        />
      </div>
      <div className='mt-6'>
        <RhfRadioGroup
          infoToolTipMessage={translate(
            'prescriptions.all_teeth_will_be_covered_by_aligner_unless_indicated_for_extraction_above__or_specified_to_be_excluded_in_consultation_notes._over-erupted_wisdom_teeth_may_be_excluded_from_aligner_as_it_may_cause_fi'
          )}
          options={TEETH_EXTRACTION_INSTRUCTIONS}
          title={translate('prescriptions.teeth_extraction_instructions')}
          className='mt-4'
          name='treatmentSpecifications.teethExtractionInstructions'
          radioClassName='border-RADIO_BORDER'
          radioItemFont='md:text-17px text-sm'
          showErrorTitle={formError?.teethToExtract}
        />
      </div>
      <If condition={watchTeethExtractionInstructions === 'TEETH_TO_EXTRACT'}>
        <div
          className='mt-6'
          ref={callback?.('treatmentSpecifications.teethToExtract')}
        >
          <RhfTeethToExtract name='treatmentSpecifications.teethToExtract' />
        </div>
      </If>
      <If condition={!isReferral || !!watchDoctorInReferrals}>
        <div className='mt-6 flex'>
          <RhfRadioGroup
            title={translate('prescriptions.treatment_plan_type')}
            options={treatmentPlanOptions}
            name='treatmentSpecifications.treatmentPlanTypes'
            className='mt-4'
            radioClassName='border-RADIO_BORDER'
            radioItemFont='md:text-17px text-sm'
            showErrorTitle={formError?.treatmentPlanInstructions}
          />
        </div>
        <If condition={isZenClear || isBoth}>
          <div
            ref={callback?.(
              'treatmentSpecifications.treatmentPlanInstructions'
            )}
            className='mt-6'
          >
            <div
              className={`md:text-17px xxs:text-base relative ${
                formError?.treatmentPlanInstructions && 'text-ERROR'
              }`}
            >
              <div className='flex justify-between'>
                <span>
                  {translate(
                    'prescriptions.ZenClear_treatment_plan_instructions'
                  )}
                </span>
                <AnimateLabel
                  animate={
                    animatedField ===
                    'treatmentSpecifications.treatmentPlanInstructions'
                  }
                >
                  {translate(
                    'prescriptions.ZenClear_treatment_plan_instructions'
                  )}
                </AnimateLabel>
                <div className='flex'>
                  <div className='space-x-4 text-sm md:block xxs:hidden'>
                    <CanAccess
                      permission={PERMISSIONS.TREATMENT_PLANNING_PREFERENCE}
                      yes={
                        <button
                          onClick={handleEditTemplateClick}
                          type='button'
                          className='cursor-default'
                        >
                          <Link
                            to={pagePaths.treatmentPlanningPreference}
                            className='underline'
                          >
                            {translate('Page_1.Edit_template')}
                          </Link>
                        </button>
                      }
                    />

                    <button
                      type='button'
                      onClick={() => {
                        setValue(
                          'treatmentSpecifications.treatmentPlanInstructions',
                          '',
                          { shouldDirty: true }
                        );
                        save?.();
                      }}
                    >
                      {translate('Page_1.Black_ghost_button')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <RHFTextArea
              name='treatmentSpecifications.treatmentPlanInstructions'
              autogrow
              inputProps={{
                placeholder: translate('settings.please_type_here')
              }}
              fontClass='text-sm'
              maxgrowHeight={260}
            />
            <If condition={!isBoth}>
              <CanAccess
                permission={PERMISSIONS.TREATMENT_PLANNING_PREFERENCE}
                yes={
                  <div className='md:text-sm xxs:text-xs text-DARK_GRAY md:mt-4'>
                    {translate(
                      'Page_1.This_is_a_templatised_text._It_will_automatically_be_added_into_any_future_treatment_plan_instructions._This_instructions_could_still_be_edited_via_the_setting_page_'
                    )}
                  </div>
                }
              />
            </If>
            <div className='space-x-4 text-sm xxs:block md:hidden mt-1'>
              <CanAccess
                permission={PERMISSIONS.TREATMENT_PLANNING_PREFERENCE}
                yes={
                  <button
                    onClick={handleEditTemplateClick}
                    type='button'
                    className='cursor-default'
                  >
                    <Link
                      to={pagePaths.treatmentPlanningPreference}
                      className='underline'
                    >
                      {translate('Page_1.Edit_template')}
                    </Link>
                  </button>
                }
              />
              <button
                onClick={() => {
                  setValue(
                    'treatmentSpecifications.treatmentPlanInstructions',
                    '',
                    { shouldDirty: true }
                  );
                  save?.();
                }}
              >
                {translate('Page_1.Black_ghost_button')}
              </button>
            </div>
          </div>
        </If>
      </If>
      <If condition={isZenplus || isBoth}>
        <div
          ref={callback?.('treatmentSpecifications.zenplusInstructions')}
          className='mt-6'
        >
          <div
            className={`md:text-17px xxs:text-base relative ${
              formError?.zenplusInstructions && 'text-ERROR'
            }`}
          >
            <div className='flex justify-between'>
              <span>
                {translate('prescriptions.ZenPlus_treatment_plan_instructions')}
              </span>
              <AnimateLabel
                animate={
                  animatedField ===
                  'treatmentSpecifications.zenplusInstructions'
                }
              >
                {translate('prescriptions.ZenPlus_treatment_plan_instructions')}
              </AnimateLabel>
              <div className='flex'>
                <div className='space-x-4 text-sm xxs:hidden md:block'>
                  <CanAccess
                    permission={PERMISSIONS.TREATMENT_PLANNING_PREFERENCE}
                    yes={
                      <button
                        onClick={handleEditTemplateClick}
                        type='button'
                        className='cursor-default'
                      >
                        <Link
                          to={pagePaths.treatmentPlanningPreference}
                          className='underline'
                        >
                          {translate('Page_1.Edit_template')}
                        </Link>
                      </button>
                    }
                  />
                  <button
                    type='button'
                    onClick={() => {
                      setValue(
                        'treatmentSpecifications.zenplusInstructions',
                        '',
                        { shouldDirty: true }
                      );
                      save?.();
                    }}
                  >
                    {translate('Page_1.Black_ghost_button')}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <RHFTextArea
            name='treatmentSpecifications.zenplusInstructions'
            autogrow
            inputProps={{
              placeholder: translate('settings.please_type_here')
            }}
            fontClass='text-sm'
            maxgrowHeight={260}
          />
          <CanAccess
            permission={PERMISSIONS.TREATMENT_PLANNING_PREFERENCE}
            yes={
              <div className='md:text-sm xxs:text-xs text-DARK_GRAY md:mt-4'>
                {translate(
                  'Page_1.This_is_a_templatised_text._It_will_automatically_be_added_into_any_future_treatment_plan_instructions._This_instructions_could_still_be_edited_via_the_setting_page_'
                )}
              </div>
            }
          />
          <div className='space-x-4 text-sm xxs:block md:hidden mt-1'>
            <CanAccess
              permission={PERMISSIONS.TREATMENT_PLANNING_PREFERENCE}
              yes={
                <button
                  onClick={handleEditTemplateClick}
                  type='button'
                  className='cursor-default'
                >
                  <Link
                    to={pagePaths.treatmentPlanningPreference}
                    className='underline'
                  >
                    {translate('Page_1.Edit_template')}
                  </Link>
                </button>
              }
            />
            <button
              onClick={() => {
                setValue('treatmentSpecifications.zenplusInstructions', '', {
                  shouldDirty: true
                });
                save?.();
              }}
            >
              {translate('Page_1.Black_ghost_button')}
            </button>
          </div>
        </div>
      </If>
    </div>
  );
};

export default TreatmentSpecifications;
