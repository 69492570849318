import { FC, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { EditIcon } from 'assets/icons';
import Button from 'components/Button/Button';
import { If } from 'components/Generics';
import RhfInput from 'components/Generics/Rhf/RhfInput';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import { RHFPhoneNumberInput } from 'components/PhoneNumberInput/RHFPhoneNumberInput';
import { useGetBranches, useUpdateUser } from 'services/hooks/UsersList.hooks';
import { loginedUserDetails } from 'store/atoms';
import { Option } from 'types/select';
import {
  Clinic,
  StaffDetails,
  UserInfoFormType,
  UserPermissionList
} from 'types/UsersList';
import { isDataExist, translate } from 'utils';
import { useHasAccess } from 'utils/hasAccess';
import { useRoleOption } from 'utils/role';
import { showToast } from 'utils/toast/toast.util';
import { EditUserInfoValidation } from 'validations/EditUserInfoValidation';

import UserInfoInput from '../UserInfoInput/UserInfoInput';

interface Props {
  userDetails: StaffDetails;
  permissionList: UserPermissionList;
  userRoleGroup: string;
  isInternalUsersFiltered?: boolean;
}

const UserInfoForm: FC<Props> = ({
  userDetails,
  permissionList,
  userRoleGroup,
  isInternalUsersFiltered
}) => {
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const loggedInUserDetails = useRecoilValue(loginedUserDetails);
  const { getSelectedRoleOptions, allRoleOption } =
    useRoleOption(userRoleGroup);

  const [updateUser, { loading: isFormSubmitting, data: updatedUserDetails }] =
    useUpdateUser({
      onError: () => {
        resetForm();
      }
    });

  const defaultValues: any = {
    username: userDetails.username || '',
    email: userDetails.email || '',
    phone: userDetails.phone || ''
  };
  if (Number.isInteger(userDetails.capacity?.capacity)) {
    defaultValues.dailyCapacity = Number(userDetails.capacity?.capacity);
  }

  const { data: branchDetails } = useGetBranches({
    variables: {
      headClinicId: loggedInUserDetails?.headClinicId
    }
  });

  const branchOptions = useMemo(
    () =>
      branchDetails?.getBranchesOfHeadClinic.map((branch: any) => ({
        value: branch.id,
        label: branch.name
      })) || [],
    [branchDetails]
  );

  const getSelectedBranches = (clinic?: Clinic[]) => {
    return branchOptions.filter(({ label }) =>
      clinic?.some((clinic) => clinic?.name === label)
    );
  };

  const formMethods = useForm<UserInfoFormType>({
    mode: 'onChange',
    resolver: yupResolver(EditUserInfoValidation),
    defaultValues: defaultValues
  });
  const {
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { dirtyFields }
  } = formMethods;

  const watchBranch = watch('branches');
  const watchRole = watch('role');
  const getBranchPlaceholder = () => {
    if (isDataExist(watchBranch)) {
      return (
        <div className='truncate max-w-[25rem]'>
          {watchBranch?.map((branch: any) => branch.label).join(', ')}
        </div>
      );
    }
    return translate('settings.add_new_branches');
  };

  const getRolePlaceholder = () => {
    if (isDataExist(watchRole)) {
      return (
        <div className='truncate max-w-[12.5rem]'>
          {watchRole?.map((role: any) => role.label).join(',')}
        </div>
      );
    }
    return translate('settings.add_role');
  };

  useEffect(() => {
    const defaultBranches = getSelectedBranches(userDetails?.clinic);
    setValue('branches', defaultBranches);
  }, [branchOptions, setValue, userDetails]);

  useEffect(() => {
    const defaultRoles = getSelectedRoleOptions(userDetails.groups);
    setValue('role', defaultRoles);
  }, [allRoleOption, setValue, userDetails]);

  const toggleIsEditable = () => {
    setIsEditable((prevState) => !prevState);
  };

  const resetForm = () => {
    if (updatedUserDetails?.updateUser) {
      const {
        username = '',
        email = '',
        phone = '',
        clinic = [],
        groups = [],
        capacity
      } = updatedUserDetails.updateUser;
      const values: any = {
        username,
        email,
        phone,
        branches: getSelectedBranches(clinic),
        role: getSelectedRoleOptions(groups)
      };
      const showDailyCapacity = Number.isInteger(capacity?.capacity);
      if (showDailyCapacity) {
        values.dailyCapacity = Number(capacity.capacity);
      }
      reset(values);
    } else {
      reset({
        ...defaultValues,
        branches: getSelectedBranches(userDetails.clinic),
        role: getSelectedRoleOptions(userDetails.groups)
      });
    }
  };

  const handleCancelEdit = () => {
    resetForm();
    setIsEditable(false);
  };

  const hasUserEditAccess = useHasAccess(permissionList.userEdit);
  const hasCapacityEditAccess = useHasAccess(permissionList.dailyCapacityEdit);
  const showDailyCapacity = Number.isInteger(userDetails.capacity?.capacity);
  const showEditButton =
    !isInternalUsersFiltered &&
    !isEditable &&
    (hasUserEditAccess || (hasCapacityEditAccess && showDailyCapacity));

  const submitHandler = async (values: any) => {
    const modifiedValues: Record<string, any> = {};
    Object.keys(dirtyFields).forEach((field) => {
      modifiedValues[field] = values[field];
    });

    const { username, email, phone, branches, role, dailyCapacity } =
      modifiedValues;

    const input: any = {
      username: username,
      email: email,
      phone: phone,
      branches: branches?.map((branch: Option) => branch.value),
      groups: role?.map((option: Option) => option.value),
      dailyCapacity: dailyCapacity
    };

    const userInfo = await updateUser({
      variables: {
        id: userDetails?.id,
        input
      }
    });

    const updatedUserDetails = userInfo?.data?.updateUser;
    if (updatedUserDetails) {
      showToast(translate('updated.user'), true);
    }
    setIsEditable(false);
  };

  return (
    <div>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className='flex justify-between w-full gap-5'>
            <UserInfoInput
              label={translate('username.username')}
              value={userDetails.username || ''}
              isEditable={isEditable}
              permission={permissionList.userEdit}
            >
              <RhfInput
                name='username'
                className='bg-BG_COLOR text-DISABLED_2'
                showAnimated={false}
                inputProps={{
                  placeholder: translate('username.username')
                }}
              />
            </UserInfoInput>
            <UserInfoInput
              label={translate('common.email')}
              value={userDetails.email || ''}
              isEditable={isEditable}
              permission={permissionList.userEdit}
            >
              <RhfInput
                name='email'
                className='bg-BG_COLOR text-DISABLED_2'
                showAnimated={false}
                type='email'
                inputProps={{
                  placeholder: translate('common.email')
                }}
              />
            </UserInfoInput>
            <UserInfoInput
              label={translate('settings.role')}
              value={watchRole?.map((role: any) => role.label).join(',') || '-'}
              isEditable={isEditable}
              className='min-w-44'
              permission={permissionList.userEdit}
            >
              <RhfSelect
                name='role'
                options={allRoleOption}
                inputProps={{
                  controlShouldRenderValue: false,
                  placeholder: getRolePlaceholder(),
                  isMulti: true
                }}
              ></RhfSelect>
            </UserInfoInput>
            <UserInfoInput
              label={translate('phone_number.phone_number')}
              value={userDetails.phone || '-'}
              isEditable={isEditable}
              permission={permissionList.userEdit}
              className='min-w-44'
            >
              <RHFPhoneNumberInput
                name='phone'
                textInputclassName='bg-WHITE'
                selectControlStyles={{
                  height: '3.5rem'
                }}
              />
            </UserInfoInput>
            {userRoleGroup == 'EXTERNAL' && (
              <UserInfoInput
                label={translate('common.branches')}
                value={
                  watchBranch?.map((branch: any) => branch.label).join(',') ||
                  '-'
                }
                isEditable={isEditable}
                className='min-w-90'
                permission={permissionList.userEdit}
              >
                <RhfSelect
                  name='branches'
                  options={branchOptions}
                  isSearchable={true}
                  searchStyle='bg-white !p-4 !text-DISABLED'
                  inputProps={{
                    controlShouldRenderValue: false,
                    isMulti: true,
                    placeholder: getBranchPlaceholder()
                  }}
                ></RhfSelect>
              </UserInfoInput>
            )}

            <If condition={showEditButton}>
              <button onClick={toggleIsEditable} type='button'>
                <EditIcon className='text-PRIMARY' />
              </button>
            </If>
          </div>
          <If condition={showDailyCapacity}>
            <div className='mt-11'>
              <UserInfoInput
                label={translate('common.daily_capacity')}
                value={String(userDetails?.capacity?.capacity)}
                isEditable={false}
                permission={permissionList.dailyCapacityEdit}
                className='w-60'
              >
                <RhfInput
                  name='dailyCapacity'
                  className='bg-BG_COLOR text-DISABLED_2'
                  showAnimated={false}
                  inputProps={{
                    placeholder: translate('settings_user.insert_number')
                  }}
                />
              </UserInfoInput>
            </div>
          </If>
          <div className='flex justify-end mt-6'>
            {isEditable && (
              <div className='flex'>
                <Button
                  className='text-PRIMARY border-PRIMARY border-1 px-4 py-1 disabled:border-none'
                  type='button'
                  onClick={handleCancelEdit}
                  disabled={isFormSubmitting}
                >
                  {translate('cancel.cancel')}
                </Button>
                <Button
                  className='bg-PRIMARY text-WHITE px-4 py-1 ml-2'
                  type='submit'
                  disabled={isFormSubmitting}
                >
                  {translate('common.save_apply')}
                </Button>
              </div>
            )}
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default UserInfoForm;
