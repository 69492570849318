import { FC } from 'react';

import { DocumentNode } from 'graphql';

import { TreatmentSourceType } from 'components/TreatmentProgressMenu/TreatmentMenuConfig';
import { ALLOWED_PHOTO_MIME_TYPE } from 'constants/common';
import ReferralFileUploader from 'containers/ReferralFileUploader/ReferralFileUploader';
import TreatmentFileUploader from 'containers/TreatmentFileUploader/TreatmentFileUploader';
import { FilesData } from 'types/Files.types';
import { TreatmentDocumentType } from 'types/TreatmentPlanning.types';

interface Props {
  treatmentId: string;
  fileDropAreaTitle?: string;
  documentType: TreatmentDocumentType;
  className: string;
  sourceType: TreatmentSourceType;
  files: FilesData[];
  refetchQueries: DocumentNode[];
  name: string;
  thumbnailHeight: string;
  referralId?: string;
  isResubmission?: boolean;
  isReferral: boolean;
  uploadContainerStyle?: string;
}
const OtherPhotoCard: FC<Props> = ({
  treatmentId,
  fileDropAreaTitle,
  documentType,
  isResubmission = false,
  className,
  isReferral,
  sourceType,
  referralId,
  files,
  refetchQueries,
  name,
  thumbnailHeight,
  uploadContainerStyle
}) => {
  const allowedFileMimeType =
    documentType === TreatmentDocumentType.PHOTO ||
    documentType === TreatmentDocumentType.X_RAY
      ? ALLOWED_PHOTO_MIME_TYPE
      : '';
  return isReferral ? (
    <ReferralFileUploader
      title={''}
      name=''
      value={files}
      referralId={referralId || ''}
      fileDropAreaTitle={fileDropAreaTitle || ''}
      referralDocumentType={documentType}
      className={className}
      hideUploadModal={true}
      allFiles={files as FilesData[]}
      refetchQueries={refetchQueries}
      containerStyle='SECONDARY'
      allowedFileMimeType={allowedFileMimeType}
    />
  ) : (
    <TreatmentFileUploader
      uploadContainerStyle={uploadContainerStyle}
      value={files}
      treatmentId={treatmentId}
      fileDropAreaTitle={fileDropAreaTitle}
      hideUploadModal={true}
      isResubmission={isResubmission}
      documentType={documentType}
      className={className}
      sourceType={sourceType}
      allowedFileMimeType={allowedFileMimeType}
      refetchQueries={refetchQueries}
      allFiles={files as FilesData[]}
      name={name}
      thumbnailHeight={thumbnailHeight}
    />
  );
};

export default OtherPhotoCard;
