import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import TeethToExtract from '../TeethToExtract';

type RhfTeethToExtractProp = {
  name: string;
  title?: string;
  showError?: boolean;
  disabled?: boolean;
  animateLabel?: string;
  source?: string;
};
const RhfTeethToExtract: FC<RhfTeethToExtractProp> = ({
  name,
  title,
  showError = true,
  disabled = false,
  animateLabel,
  source
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TeethToExtract
          source={source}
          title={title}
          value={field.value}
          onChange={field.onChange}
          name={name}
          error={error?.message}
          showError={showError}
          disabled={disabled}
          animateLabel={animateLabel}
        />
      )}
    />
  );
};

export default RhfTeethToExtract;
