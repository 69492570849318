import { FC, lazy } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { pagePaths } from 'config/pages';

import PrivateRoutes from './privateRoute';

const Login = lazy(() => import('pages/LoginPage/LoginPage'));
const ForgotPage = lazy(() => import('pages/ForgotPage/ForgotPage'));
const RetainerPurchase = lazy(
  () => import('pages/RetainerPurchase/RetainerPurchase')
);
const RetainerThankyou = lazy(
  () => import('pages/RetainerPurchase/components/Success/RetainerThankyou')
);
const SetPassword = lazy(() => import('pages/SetPassword/SetPassword'));
const CreateNewAccountPage = lazy(
  () => import('pages/CreateNewAccountPage/CreateNewAccountPage')
);
const ConsentFormPage = lazy(
  () => import('pages/ConsentFormPage/ConsentFormPage')
);
const WebLinkPage = lazy(() => import('pages/WebLinkPage/WebLinkPage'));
const ClinicPage = lazy(() => import('pages/ClinicPage/ClinicPage'));

const rootLayout: FC = () => {
  return (
    <Router>
      <Routes>
        <Route path={pagePaths.login} element={<Login />} />
        <Route path={pagePaths.forgotPassword} element={<ForgotPage />} />
        <Route path={pagePaths.setPassword} element={<SetPassword />} />
        <Route path={pagePaths.consentForm} element={<ConsentFormPage />} />
        <Route path={pagePaths.webLink} element={<WebLinkPage />} />
        <Route path={pagePaths.clinicPage} element={<ClinicPage />} />
        <Route
          path={pagePaths.retainerPurchase}
          element={<RetainerPurchase />}
        />
        <Route
          path={pagePaths.retainerThankyou}
          element={<RetainerThankyou />}
        />
        <Route
          path={pagePaths.createNewAccount}
          element={<CreateNewAccountPage />}
        />
        <Route path='*' element={<PrivateRoutes />} />
      </Routes>
    </Router>
  );
};

export default rootLayout;
