import {
  ClassificationFrontal,
  ClassificationFrontalSmiling,
  ClassificationIntaOralLeft,
  ClassificationIntraOral,
  ClassificationIntraOralRight,
  ClassificationLower,
  ClassificationSideProfile,
  ClassificationUpper
} from 'assets/icons';
import { Classification, FilesData } from 'types/Files.types';
import { VideoConstraints } from 'types/prescriptionCamera.types';
import { Option } from 'types/select';
import { TreatmentDocumentType } from 'types/TreatmentPlanning.types';

export const VIDEO_CONSTRAINTS = {
  facingMode: 'user',
  width: 1280,
  height: 720
};

export const CLASSIFICATION_LABEL: Record<string, string> = {
  [Classification.FRONTAL]: 'frontal.image',
  [Classification.FRONTAL_SMILING]: 'frontal.smiling.image',
  [Classification.INTRAORAL_LEFT]: 'intraoral.left',
  [Classification.INTRAORAL_RIGHT]: 'intraoral.right',
  [Classification.UPPER]: 'upper.image',
  [Classification.LOWER]: 'lower.image',
  [Classification.SIDE_PROFILE]: 'side.profile',
  [Classification.INTRAORAL_FRONTAL]: 'intraoral.frontal',
  [Classification.PANORAMIC_RADIOGRAPHY]: 'panoramic.radiography',
  [Classification.LATERAL_CEPHALOGRAM]: 'lateral.cephalogram',
  [Classification.OTHER]: 'prescriptions.others'
};

export interface ImageClassification {
  title: string;
  imageUrl: string;
  status: string;
  fileId: string;
  fileType: string;
  skeltonImage: JSX.Element | string;
  classification: Classification;
}
export interface PatientFilesMobileCamera {
  currentStage: number;
  isCameraReady: boolean;
  videoConstraints: VideoConstraints;
  files: ImageClassification[];
}
const patientFileMobileCameraDefault: PatientFilesMobileCamera = Object.freeze({
  currentStage: 0,
  isCameraReady: false,
  videoConstraints: VIDEO_CONSTRAINTS,
  files: [
    {
      title: CLASSIFICATION_LABEL[Classification.FRONTAL],
      imageUrl: '',
      status: '',
      fileId: '',
      fileType: '',
      skeltonImage: <ClassificationFrontal />,
      classification: Classification.FRONTAL
    },
    {
      title: CLASSIFICATION_LABEL[Classification.FRONTAL_SMILING],
      imageUrl: '',
      fileId: '',
      status: '',
      fileType: '',
      skeltonImage: <ClassificationFrontalSmiling />,
      classification: Classification.FRONTAL_SMILING
    },
    {
      title: CLASSIFICATION_LABEL[Classification.SIDE_PROFILE],
      imageUrl: '',
      status: '',
      fileId: '',
      fileType: '',
      skeltonImage: <img src={ClassificationSideProfile} alt='' />,
      classification: Classification.SIDE_PROFILE
    },
    {
      title: CLASSIFICATION_LABEL[Classification.UPPER],
      imageUrl: '',
      fileId: '',
      status: '',
      fileType: '',
      skeltonImage: <ClassificationUpper />,
      classification: Classification.UPPER
    },
    {
      title: CLASSIFICATION_LABEL[Classification.LOWER],
      imageUrl: '',
      status: '',
      fileId: '',
      fileType: '',
      skeltonImage: <ClassificationLower />,
      classification: Classification.LOWER
    },
    {
      title: CLASSIFICATION_LABEL[Classification.INTRAORAL_FRONTAL],
      imageUrl: '',
      status: '',
      fileId: '',
      fileType: '',
      skeltonImage: <ClassificationIntraOral />,
      classification: Classification.INTRAORAL_FRONTAL
    },
    {
      title: CLASSIFICATION_LABEL[Classification.INTRAORAL_LEFT],
      imageUrl: '',
      status: '',
      fileId: '',
      fileType: '',
      skeltonImage: <ClassificationIntaOralLeft />,
      classification: Classification.INTRAORAL_LEFT
    },
    {
      title: CLASSIFICATION_LABEL[Classification.INTRAORAL_RIGHT],
      imageUrl: '',
      fileId: '',
      status: '',
      fileType: '',
      skeltonImage: <ClassificationIntraOralRight />,
      classification: Classification.INTRAORAL_RIGHT
    }
  ]
});
const patientXrayDefault: PatientFilesMobileCamera = Object.freeze({
  currentStage: 0,
  isCameraReady: false,
  videoConstraints: VIDEO_CONSTRAINTS,
  files: [
    {
      title: CLASSIFICATION_LABEL[Classification.PANORAMIC_RADIOGRAPHY],
      imageUrl: '',
      status: '',
      fileId: '',
      fileType: '',
      skeltonImage: '',
      classification: Classification.PANORAMIC_RADIOGRAPHY
    },
    {
      title: CLASSIFICATION_LABEL[Classification.LATERAL_CEPHALOGRAM],
      imageUrl: '',
      fileId: '',
      status: '',
      fileType: '',
      skeltonImage: '',
      classification: Classification.LATERAL_CEPHALOGRAM
    }
  ]
});

export interface PatientFilesMobile {
  showCameraOrUploadModal: boolean;
  showMobilePhotoListModal: boolean;
  showCamera: boolean;
  files: FilesData[];
  duplicateClassificationList: Classification[];
  missingClassificationList: Classification[];
}
const treatmentFileMobileDefault: PatientFilesMobile = {
  showCameraOrUploadModal: false,
  showMobilePhotoListModal: false,
  showCamera: false,
  files: [],
  duplicateClassificationList: [],
  missingClassificationList: []
};

const otherFileDefault: ImageClassification = {
  title: 'Other',
  status: '',
  skeltonImage: '',
  classification: Classification.OTHER,
  fileId: '',
  fileType: 'img',
  imageUrl: ''
};

const PRIMARY_REQUIRED_PHOTOS = [
  Classification.UPPER,
  Classification.LOWER,
  Classification.INTRAORAL_RIGHT,
  Classification.INTRAORAL_FRONTAL,
  Classification.INTRAORAL_LEFT,
  Classification.FRONTAL,
  Classification.FRONTAL_SMILING,
  Classification.SIDE_PROFILE
];

const REFINEMENT_REQUIRED_PHOTOS = [
  Classification.UPPER,
  Classification.LOWER,
  Classification.INTRAORAL_RIGHT,
  Classification.INTRAORAL_FRONTAL,
  Classification.INTRAORAL_LEFT
];

const PRIMARY_REQUIRED_XRAY = [Classification.PANORAMIC_RADIOGRAPHY];
const REFINEMENT_REQUIRED_XRAY: Classification[] = [];

const xRayClassificationCategories: Option[] = [
  {
    label: CLASSIFICATION_LABEL[Classification.PANORAMIC_RADIOGRAPHY],
    value: Classification.PANORAMIC_RADIOGRAPHY
  },
  {
    label: CLASSIFICATION_LABEL[Classification.LATERAL_CEPHALOGRAM],
    value: Classification.LATERAL_CEPHALOGRAM
  }
];
const photoClassificationCategories: Option[] = [
  {
    label: CLASSIFICATION_LABEL[Classification.FRONTAL],
    value: Classification.FRONTAL
  },
  {
    label: CLASSIFICATION_LABEL[Classification.FRONTAL_SMILING],
    value: Classification.FRONTAL_SMILING
  },
  {
    label: CLASSIFICATION_LABEL[Classification.SIDE_PROFILE],
    value: Classification.SIDE_PROFILE
  },
  {
    label: CLASSIFICATION_LABEL[Classification.UPPER],
    value: Classification.UPPER
  },
  {
    label: CLASSIFICATION_LABEL[Classification.LOWER],
    value: Classification.LOWER
  },

  {
    label: CLASSIFICATION_LABEL[Classification.INTRAORAL_FRONTAL],
    value: Classification.INTRAORAL_FRONTAL
  },
  {
    label: CLASSIFICATION_LABEL[Classification.INTRAORAL_LEFT],
    value: Classification.INTRAORAL_LEFT
  },
  {
    label: CLASSIFICATION_LABEL[Classification.INTRAORAL_RIGHT],
    value: Classification.INTRAORAL_RIGHT
  },
  {
    label: CLASSIFICATION_LABEL[Classification.OTHER],
    value: Classification.OTHER
  }
];

const TREATMENT_DOCUMENT_FILE_DROP_TYPE_MAP = {
  [TreatmentDocumentType.SCAN]: 'scans',
  [TreatmentDocumentType.X_RAY]: 'X-rays',
  [TreatmentDocumentType.PHOTO]: 'image',
  [TreatmentDocumentType.PRE_ASSESSMENT_PHOTO]: 'photo',
  [TreatmentDocumentType.CONSENT_FORM]: 'consent'
};

enum TREATMENT_FILE_MOBILE_ACTIONS {
  SHOW_CAMERA_OR_UPLOAD_MODAL = 'showCameraOrUpload',
  HIDE_OR_UPLOAD_MODAL = 'hideCameraOrUpload',
  SHOW_MOBILE_PHOTO_LIST_MODAL = 'showMobilePhotoListModal',
  HIDE_MOBILE_PHOTO_LIST_MODAL = 'hideMobilePhotoListModal',
  UPDATE_PATIENT_FILES = 'updatePatientFiles',
  SET_DUPLICATE_CLASSIFICATION_LIST = 'setDuplicateClassificationList',
  SET_MISSING_REQUIRED_CLASSIFICATION = 'setMissingRequiredClassification',
  SHOW_CAMERA = 'showCamera',
  RESET = 'reset'
}

export {
  patientFileMobileCameraDefault,
  treatmentFileMobileDefault,
  xRayClassificationCategories,
  photoClassificationCategories,
  otherFileDefault,
  patientXrayDefault,
  PRIMARY_REQUIRED_PHOTOS,
  REFINEMENT_REQUIRED_PHOTOS,
  PRIMARY_REQUIRED_XRAY,
  REFINEMENT_REQUIRED_XRAY,
  TREATMENT_DOCUMENT_FILE_DROP_TYPE_MAP,
  TREATMENT_FILE_MOBILE_ACTIONS
};
