import { FC } from 'react';

import { Loader } from 'components/Loader/Loader';

interface Props {
  colSpan: number;
  hasMore: boolean;
}

const TableFooterContent: FC<Props> = ({ colSpan, hasMore }) => {
  const loadedAllRows = !hasMore;
  if (loadedAllRows) return null;
  return (
    <tr>
      <td colSpan={colSpan}>
        <Loader />
      </td>
    </tr>
  );
};

export default TableFooterContent;
