import {
  CalendarNumberIcon,
  CalendarNumberOutlineIcon,
  FrontalImage,
  FrontalSmiling,
  InboxIcon,
  InboxSelectedIcon,
  IntraOralFrontal,
  IntraOralLeft,
  IntraOralRight,
  Lower,
  NotesIcon,
  NotesSelectedIcon,
  SideProfile,
  Upper
} from 'assets/icons';
import { Classification } from 'types/Files.types';
import { SidePanelItem, SidePanelItemConfig } from 'types/PatientCard.types';
import { TreatmentDocumentType } from 'types/TreatmentPlanning.types';

import { EVENT_DETAILS_ACTIONS } from './options';
import { PERMISSIONS } from './Permissions';
import { CLASSIFICATION_LABEL } from './treatmentFiles';

const PATIENT_CARD_SIDE_PANEL_CONFIG: Record<string, SidePanelItemConfig> = {
  INBOX: {
    name: 'inbox_button.inbox',
    key: 'INBOX'
  },
  APPOINTMENTS: {
    name: 'button.appointments',
    key: 'APPOINTMENTS'
  },
  NOTES: {
    name: 'notes.notes',
    key: 'NOTES'
  }
};

const RESUBMISSION_PENDING = 'RESUBMISSION_PENDING';
const PATIENT_CARD_SIDE_PANEL_ITEMS: SidePanelItem[] = [
  {
    element: <InboxIcon className='m-auto h-4 w-5' />,
    name: PATIENT_CARD_SIDE_PANEL_CONFIG.INBOX.name,
    key: PATIENT_CARD_SIDE_PANEL_CONFIG.INBOX.key,
    permission: PERMISSIONS?.INBOX,
    selectedElement: <InboxSelectedIcon />
  },
  {
    element: <CalendarNumberOutlineIcon className='m-auto' />,
    name: PATIENT_CARD_SIDE_PANEL_CONFIG.APPOINTMENTS.name,
    key: PATIENT_CARD_SIDE_PANEL_CONFIG.APPOINTMENTS.key,
    permission: PERMISSIONS?.APPOINTMENTS_PATIENT_CARD,
    selectedElement: (
      <CalendarNumberIcon className='m-auto fill-current text-PRIMARY' />
    )
  },
  {
    element: <NotesIcon className='m-auto' />,
    name: PATIENT_CARD_SIDE_PANEL_CONFIG.NOTES.name,
    key: PATIENT_CARD_SIDE_PANEL_CONFIG.NOTES.key,
    permission: PERMISSIONS?.VIEW_NOTE,
    selectedElement: <NotesSelectedIcon />
  }
];

const APPOINTMENTS_OPTIONS = [
  { label: 'filters.all', value: '' },
  { label: 'sidepanel_appointmentlist.past', value: 'past' },
  { label: 'sidepanel_appointmentlist.upcoming', value: 'upcoming' }
];

const RETAINER_REFINEMENT_TABS: Record<string, string> = {
  REFINEMENT: 'REFINEMENT',
  RETAINER: 'RETAINER'
};

export enum OPPORTUNITY_TYPE {
  BRACES = 'BRACES',
  REFINEMENT = 'REFINEMENT'
}

const PRESCRIPTION_SUMMARY_TAB = 'PRESCRIPTION_SUMMARY';

const FILE_UPLOAD_FIELDS = [
  'treatmentFiles.photo',
  'treatmentFiles.scan',
  'treatmentFiles.xray',
  'treatmentFiles.consentForm'
];

const TREATMENT_SPECIFICATION_FIELDS = [
  'treatmentSpecifications.treatmentSpecAttachments',
  'treatmentSpecifications.iprLocation',
  'treatmentSpecifications.crowdingResolvement',
  'treatmentSpecifications.teethExtractionInstructions',
  'treatmentSpecifications.teethToExtract',
  'treatmentSpecifications.treatmentPlanTypes'
];

export interface ClassificationItem {
  label: string;
  icon: JSX.Element;
  key: Classification;
}

export const PATIENT_FILE_CLASSIFICATIONS: Record<
  TreatmentDocumentType.PHOTO | TreatmentDocumentType.X_RAY,
  ClassificationItem[]
> = {
  [TreatmentDocumentType.PHOTO]: [
    {
      label: CLASSIFICATION_LABEL[Classification.FRONTAL],
      icon: <FrontalImage />,
      key: Classification.FRONTAL
    },
    {
      label: CLASSIFICATION_LABEL[Classification.FRONTAL_SMILING],
      icon: <FrontalSmiling />,
      key: Classification.FRONTAL_SMILING
    },
    {
      label: CLASSIFICATION_LABEL[Classification.SIDE_PROFILE],
      icon: <SideProfile className='lg:w-[7.364rem] lg:h-[9.8rem]' />,
      key: Classification.SIDE_PROFILE
    },
    {
      label: CLASSIFICATION_LABEL[Classification.UPPER],
      icon: <Upper className='lg:w-[7.636rem] lg:h-[6.076rem]' />,
      key: Classification.UPPER
    },
    {
      label: CLASSIFICATION_LABEL[Classification.LOWER],
      icon: <Lower className='lg:w-[7.901rem] lg:h-[5.779rem]' />,
      key: Classification.LOWER
    },
    {
      label: CLASSIFICATION_LABEL[Classification.INTRAORAL_FRONTAL],
      icon: <IntraOralFrontal className='lg:w-[7.943rem] lg:h-[5.738rem]' />,
      key: Classification.INTRAORAL_FRONTAL
    },
    {
      label: CLASSIFICATION_LABEL[Classification.INTRAORAL_LEFT],
      icon: <IntraOralLeft className='lg:w-[7.651rem] lg:h-[5.738rem]' />,
      key: Classification.INTRAORAL_LEFT
    },
    {
      label: CLASSIFICATION_LABEL[Classification.INTRAORAL_RIGHT],
      icon: <IntraOralRight className='lg:w-[7.274rem] lg:h-[5.444rem]' />,
      key: Classification.INTRAORAL_RIGHT
    }
  ],
  [TreatmentDocumentType.X_RAY]: [
    {
      label: CLASSIFICATION_LABEL[Classification.PANORAMIC_RADIOGRAPHY],
      icon: <IntraOralFrontal className='lg:w-[7.943rem] lg:h-[5.738rem]' />,
      key: Classification.PANORAMIC_RADIOGRAPHY
    },
    {
      label: CLASSIFICATION_LABEL[Classification.LATERAL_CEPHALOGRAM],
      icon: <IntraOralLeft className='lg:w-[7.651rem] lg:h-[5.738rem]' />,
      key: Classification.LATERAL_CEPHALOGRAM
    }
  ]
};
export const SHOW_STATUS = [
  EVENT_DETAILS_ACTIONS.NO_SHOW,
  EVENT_DETAILS_ACTIONS.SHOWED_UP,
  EVENT_DETAILS_ACTIONS.SCHEDULED
];

export {
  RESUBMISSION_PENDING,
  PATIENT_CARD_SIDE_PANEL_ITEMS,
  PATIENT_CARD_SIDE_PANEL_CONFIG,
  APPOINTMENTS_OPTIONS,
  RETAINER_REFINEMENT_TABS,
  PRESCRIPTION_SUMMARY_TAB,
  FILE_UPLOAD_FIELDS,
  TREATMENT_SPECIFICATION_FIELDS
};
