import { GET_TICKET_LIST } from 'services/query/inbox';
import {
  GET_TREATMENT_PLANS,
  UPDATE_TREATMENT_PLAN,
  UPDATE_TREATMENT_PLAN_VERSION,
  REVIEW_TPV_UPDATE,
  REASSIGN_COUNCIL,
  RAISE_CASEOPS_ISSUE
} from 'services/query/TreatmentPlanReview.queries';
import { TicketData } from 'types/Tickets.types';
import {
  GetTreatmentPlan,
  TicketByTopicAndContextInput,
  UpdateTreatmentPlan,
  UpdateTreatmentPlanVersion,
  UseGetTicketId,
  RequestTPVUpdate,
  ReassignCouncil,
  raiseCaseOpsIssueType
} from 'types/TreatmentPlanReview.types';

import {
  UseGraphQlMutation,
  UseGraphQlMutationWithTpes,
  UseGraphQlQuery,
  UseGraphQlQueryWithTypes
} from './hooks';

export const useGetTreatmentPlans: GetTreatmentPlan = (config) => {
  return UseGraphQlQuery(GET_TREATMENT_PLANS, config);
};

export const useUpdateTreatmentPlan: UpdateTreatmentPlan = (config) => {
  return UseGraphQlMutationWithTpes(UPDATE_TREATMENT_PLAN, config);
};

export const useUpdateTreatmentPlanVersion: UpdateTreatmentPlanVersion = (
  config
) => {
  return UseGraphQlMutation(UPDATE_TREATMENT_PLAN_VERSION, config);
};
export const useRaiseCaseOpsIssue: raiseCaseOpsIssueType = (config) => {
  return UseGraphQlMutation(RAISE_CASEOPS_ISSUE, config);
};

export const useGetTicketId: UseGetTicketId = (config) => {
  return UseGraphQlQueryWithTypes<TicketData, TicketByTopicAndContextInput>(
    GET_TICKET_LIST,
    config
  );
};

export const useReviewTPVUpdate: RequestTPVUpdate = (config) => {
  return UseGraphQlMutation(REVIEW_TPV_UPDATE, {
    ...config
  });
};

export const useReassignCouncil: ReassignCouncil = (config) => {
  return UseGraphQlMutationWithTpes(REASSIGN_COUNCIL, config);
};
