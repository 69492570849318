import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { WarningIcon } from 'assets/icons';
import { isWarningClosed } from 'store/atoms';
import { translate } from 'utils/locale';

interface InternalWarningProps {
  isInternal: boolean;
}

const InternalWarning: FC<InternalWarningProps> = ({ isInternal }) => {
  const setClosed = useSetRecoilState(isWarningClosed);
  const handleWarningClose = () => {
    setClosed(true);
  };

  const key = isInternal ? 'INTERNAL' : 'EXTERNAL';
  return (
    <div className='flex items-center bg-BLUE_200 px-4 pt-2 pb-3 text-14px w-full mb-2 rounded-xl'>
      <div className='w-5'>
        <WarningIcon className='text-BLUE_800' />
      </div>
      <div className='ml-5 text-BLUE_800'>
        <p className='underline text-xs md:text-14px'>
          {translate(`INBOX.CHAT_WARNING.${key}.TITLE`)}
        </p>
        <p className='text-xs md:text-14px'>
          {translate(`INBOX.CHAT_WARNING.${key}.MESSAGE`)}
        </p>
      </div>
      <button
        className='ml-auto pl-2 underline text-BLUE_800 text-14px'
        onClick={handleWarningClose}
        type='button'
      >
        {translate('COMMON.BUTTON.CLOSE')}
      </button>
    </div>
  );
};

export default InternalWarning;
