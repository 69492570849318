import { ChangeEvent, FC } from 'react';

import { InputProps } from 'types/RadioInput';
import { customTwMerge as twMerge } from 'utils';

interface Props {
  id?: string;
  name?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  className?: string;
  inputProps?: InputProps;
  label?: string;
}

const Radio: FC<Props> = ({
  id,
  name,
  value,
  onChange,
  checked,
  className = '',
  inputProps,
  label
}) => {
  return (
    <label>
      <input
        id={id}
        type='radio'
        name={name}
        data-testid='radio'
        value={value}
        checked={checked}
        onChange={onChange}
        className={twMerge(
          'form-radio text-PRIMARY focus:ring-0 w-5 h-5 border-RADIO_BORDER px-2 mr-2',
          className
        )}
        {...inputProps}
      />
      <span>{label}</span>
    </label>
  );
};

export default Radio;
