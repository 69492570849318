import { FC } from 'react';

import cx from 'classnames';

import { DownArrowIcon } from 'assets/icons';
import { If } from 'components/Generics';
import { AccordionItem } from 'types/Accordion';
interface Props {
  accordionItems: AccordionItem[];
  onClickHandler: (key: string) => void;
  selectedItem: string[] | string;
}
const Accordion: FC<Props> = ({
  accordionItems,
  selectedItem,
  onClickHandler
}) => {
  return (
    <div className='accordion' id='accordionExample'>
      {accordionItems.map((item) => (
        <div className='accordion-item' key={item.key}>
          <div className='accordion-header ' id='headingOne'>
            <button
              className='mt-4 mb-2 w-full px-4 py-2 border-b-1 border-CORE_GRAY'
              type='button'
              onClick={() => onClickHandler(item.key)}
            >
              <div className='flex justify-between w-full items-center'>
                <h2 className='text-DEFAULT_TEXT font-medium text-sm'>
                  {item.title}
                </h2>
                <DownArrowIcon
                  className={cx(
                    'text-DEFAULT_TEXT',
                    {
                      'transform duration-500  rotate-180':
                        selectedItem.includes(item.key)
                    },
                    {
                      'transform duration-500  rotate-0':
                        !selectedItem.includes(item.key)
                    }
                  )}
                />
              </div>
            </button>
          </div>
          <If condition={selectedItem.includes(item.key)}>
            <div>{item.component}</div>
          </If>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
