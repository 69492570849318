import { gql } from '@apollo/client';

export const GET_PRIOR_TREATMENT_OPTIONS = gql`
  query GetPriorTreatmentsList {
    getPriorTreatmentsList {
      value: key
      label: value
    }
  }
`;

export const GET_TREATMENT_SPECIFICATION_OPTIONS = gql`
  query GetTreatmentSpecificationOptions {
    getIPRLocations {
      value: key
      label: value
    }
    getCrowdingTreatmentOptions {
      value: key
      label: value
    }
    getAttachementOptions {
      value: key
      label: value
    }
  }
`;

export const GET_TREATMENT_PLANS_OPTIONS = gql`
  query GetAllTreatmentTypes($input: FilterTreatmentPlanTypeInput) {
    getAllTreatmentTypes(input: $input) {
      value: key
      label: value
    }
  }
`;

export const GET_TREATMENT_FILE_UPLOAD_URL = gql`
  query GetTreatmentDocumentsUploadUrl(
    $treatmentId: String!
    $documents: [TreatmentDocumentInput]!
  ) {
    getTreatmentDocumentsUploadUrl(
      input: { treatmentId: $treatmentId, documents: $documents }
    ) {
      url
      name
      key
      extension
    }
  }
`;

export const GET_TREATMENT_PLAN_FILE_UPLOAD_URL = gql`
  query GetTreatmentPlanDocumentsUploadUrl(
    $treatmentId: String!
    $documents: [TreatmentPlanDocumentInput]!
  ) {
    getTreatmentPlanDocumentsUploadUrl(
      input: { treatmentPlanId: $treatmentId, documents: $documents }
    ) {
      url
      name
      key
      extension
    }
  }
`;

export const ATTACH_TREATMENT_DOCUMENTS = gql`
  mutation AttachTreatmentDocuments(
    $treatmentId: String!
    $documents: [TreatmentDocumentInput]!
  ) {
    attachTreatmentDocuments(
      input: { treatmentId: $treatmentId, documents: $documents }
    ) {
      id: documentId
      url: presignedUrl
      name: documentName
    }
  }
`;

export const ATTACH_TREATMENT_PLAN_DOCUMENTS = gql`
  mutation AttachTreatmentPlanDocuments(
    $treatmentId: String!
    $documents: [TreatmentPlanDocumentInput]!
  ) {
    attachTreatmentPlanDocuments(
      input: { treatmentPlanId: $treatmentId, documents: $documents }
    ) {
      id: documentId
      url: presignedUrl
      name: documentName
    }
  }
`;

export const DELETE_TREATMENT_DOCUMENTS = gql`
  mutation RemoveTreatmentDocument($treatmentId: ID!, $documentId: ID!) {
    removeTreatmentDocument(
      treatmentId: $treatmentId
      documentId: $documentId
    ) {
      id
    }
  }
`;

export const DELETE_TREATMENT_PLAN_DOCUMENTS = gql`
  mutation RemoveTreatmentDocument($treatmentId: ID!, $documentId: ID!) {
    removeTreatmentPlanDocument(
      treatmentPlanId: $treatmentId
      documentId: $documentId
    ) {
      id
    }
  }
`;

export const UPDATE_TREATMENT_DOCUMENTS = gql`
  mutation updateTreatmentDocuments(
    $input: [UpdateTreatmentDocumentInput!]!
    $treatmentId: String!
  ) {
    updateTreatmentDocuments(input: $input, treatmentId: $treatmentId) {
      documentId
      documentName
      classification
      documentType
      sourceType
      presignedUrl
      extension
      origin
    }
  }
`;
