import { MutableRefObject } from 'react';

import {
  ApolloQueryResult,
  LazyQueryExecFunction,
  OperationVariables
} from '@apollo/client';
import { Resetter, SetterOrUpdater } from 'recoil';

import {
  INBOX_GROUP_NOTIFICATION_UPDATES,
  INBOX_NOTIFICATION
} from 'constants/inbox';
import {
  GroupStatusNotification,
  MapToObj,
  TicketByIdOptionalArg
} from 'types/Inbox.types';
import { LoginedUser } from 'types/Login.types';
import { InboxFilters, Ticket, TicketById } from 'types/Tickets.types';
import { isDataExist } from 'utils';
import {
  checkPatientCardAndTicketPatientsSame,
  isForDifferentHeadClinic
} from 'utils/inbox';

/**
 * @description
 * Function to handle the group updates notifications
 * @param updates New updates notification
 * @param headClinicIdRef reference of the head clinicId
 * @param ticketListRef reference for the ticket list
 * @param selectedTicketRef reference for the selected ticket
 * @param userDetails logined user details
 * @param searchedTicketIdRef searched ticket Id reference
 * @param ticketPaginationInfo pagination info of the ticket list
 * @param isFromPatientDetails check for is in patientList or not
 * @param defaultSelectedTicket default ticket for resetting
 * @param patientId patient id
 * @param setSelectedTicket function for setting selected ticket
 * @param setTicketsList function for setting
 * @param getTicketById function for getting ticket by id
 * @param resetSelectedSearchedMessage function for resetting selected message
 * @param setShowChat function for showing new chat
 * @param updateSelectedTicketDetails function for updating selected ticket details
 * @param isChatFilterApplied check for is filter applied
 * @param setNotification function for set notification
 * @param updateRecentMessage function for updating recent message
 */
export const handleGroupStatusListenerSuccess = (
  updates: any,
  headClinicIdRef: MutableRefObject<string | undefined>,
  ticketListRef: MutableRefObject<Ticket[]>,
  selectedTicketRef: MutableRefObject<Ticket | undefined>,
  ticketFilterRef: MutableRefObject<InboxFilters | undefined>,
  userDetails: LoginedUser,
  searchedTicketIdRef: MutableRefObject<number | undefined>,
  ticketPaginationInfo: MutableRefObject<{
    offset: number;
    previousFetchDirection: string;
    previousScrollHeight: number;
    totalCount: number;
  }>,
  isFromPatientDetails: boolean,
  defaultSelectedTicket: Ticket,
  patientId: string | undefined,
  setSelectedTicket: (
    valOrUpdater: Ticket | ((currVal: Ticket) => Ticket)
  ) => void,
  setTicketsList: (
    valOrUpdater: Ticket[] | ((currVal: Ticket[]) => Ticket[])
  ) => void,
  getTicketById: (
    id: number,
    optionalArg?: TicketByIdOptionalArg
  ) => Promise<ApolloQueryResult<TicketById>>,
  resetSelectedSearchedMessage: Resetter,
  setShowChat: SetterOrUpdater<boolean>,
  updateSelectedTicketDetails: (optionalArg?: TicketByIdOptionalArg) => void,
  checkNoFilterApplied: () => boolean,
  setNotification: (
    valOrUpdater: MapToObj | ((currVal: MapToObj) => MapToObj)
  ) => void,
  updateRecentMessage: () => void,
  getTicketByFilter: (
    ticketId: number,
    onCompleted: (ticket: Ticket) => void
  ) => void,
  setUnReadMessageCount?: React.Dispatch<React.SetStateAction<number>>,
  getUnReadMessageCount?: LazyQueryExecFunction<any, OperationVariables>
): void => {
  if (isDataExist(updates)) {
    updates.forEach((notification: GroupStatusNotification) => {
      const { group_details: { custom_data } = {} } = notification;

      if (isForDifferentHeadClinic(custom_data, headClinicIdRef.current)) {
        return;
      }

      const tickets = [...ticketListRef.current];
      const updatedTicketIndex = tickets.findIndex(
        (ticket) => ticket.id === Number(notification.group_details.entity_id)
      );
      if (
        INBOX_GROUP_NOTIFICATION_UPDATES.includes(notification.message_category)
      ) {
        getUnReadMessageCount?.({
          variables: { input: { patientId: patientId } },
          onCompleted: (data) => {
            setUnReadMessageCount?.(data?.getUnReadMessageCount);
          }
        });
      }
      switch (notification.message_category) {
        case INBOX_NOTIFICATION.GROUP_PROPERTY_UPADTED: {
          if (notification?.property_name === INBOX_NOTIFICATION.IS_CLOSED) {
            const status = notification?.property_value
              ? INBOX_NOTIFICATION.CLOSE
              : INBOX_NOTIFICATION.OPEN;
            if (
              selectedTicketRef.current?.id ===
              Number(notification.group_details.entity_id)
            ) {
              const selectedTicketInfo = { ...selectedTicketRef.current };
              selectedTicketInfo.status = status;
              setSelectedTicket(selectedTicketInfo);
            }
            if (updatedTicketIndex !== -1) {
              const updatedTicket = { ...tickets[updatedTicketIndex] };
              updatedTicket.status = status;
              tickets[updatedTicketIndex] = updatedTicket;
              setTicketsList(tickets);
            }
          } else if (
            notification?.property_name === INBOX_NOTIFICATION.IS_URGENT
          ) {
            const isUrgent = !!notification?.property_value;
            if (
              selectedTicketRef.current?.id ===
              Number(notification.group_details.entity_id)
            ) {
              const selectedTicketInfo = { ...selectedTicketRef.current };
              selectedTicketInfo.isUrgent = isUrgent;
              setSelectedTicket(selectedTicketInfo);
            }
            if (updatedTicketIndex !== -1) {
              const updatedTicket = { ...tickets[updatedTicketIndex] };
              updatedTicket.isUrgent = isUrgent;
              tickets[updatedTicketIndex] = updatedTicket;
            }
            setTicketsList(tickets);
          } else if (
            notification?.property_name ===
              INBOX_NOTIFICATION.OWNERSHIP_UPDATED &&
            updatedTicketIndex !== -1
          ) {
            getTicketById(Number(notification.group_details.entity_id)).then(
              ({ data }) => {
                if (data.getTicketById.id) {
                  if (updatedTicketIndex >= 0) {
                    tickets[updatedTicketIndex] = data.getTicketById;
                    setTicketsList(tickets);
                  }
                  if (
                    selectedTicketRef.current?.id ===
                    Number(notification.group_details.entity_id)
                  ) {
                    const selectedTicketInfo = data.getTicketById;
                    setSelectedTicket(selectedTicketInfo);
                  }
                }
              }
            );
          } else if (
            notification?.property_name ===
              INBOX_NOTIFICATION.MENTIONED_MESSAGE_ID &&
            updatedTicketIndex !== -1
          ) {
            tickets[updatedTicketIndex] = {
              ...tickets[updatedTicketIndex],
              isMentionedMessageRead: false,
              mentionedMessageId: notification?.property_value || null
            };
            setTicketsList(tickets);
          } else if (
            notification?.property_name === INBOX_NOTIFICATION.IS_SNOOZED &&
            updatedTicketIndex !== -1
          ) {
            const isSnoozed = !!notification?.property_value;
            const updatedTicket = { ...tickets[updatedTicketIndex] };
            let snoozeInfo;
            if (isSnoozed) {
              const { rich_text_info } = notification || {};
              const { rich_text_details } = rich_text_info || {};
              const [{ details }] = rich_text_details || [];
              const { id, snooze_until } = details || {};
              snoozeInfo = {
                id: Number(id),
                snoozeUntil: snooze_until || new Date()
              };
            }
            updatedTicket.snoozeInfo = snoozeInfo;
            tickets[updatedTicketIndex] = updatedTicket;
            setTicketsList(tickets);
          }
          break;
        }
        case INBOX_NOTIFICATION.GROUP_USERS_REMOVED: {
          if (userDetails.id === notification?.user_list?.[0]) {
            /* resolves issue ZCCSQ3-993 */
            if (
              searchedTicketIdRef.current ===
                Number(notification.group_details.entity_id) &&
              !ticketFilterRef.current?.viewTicketsWithoutMe
            ) {
              resetSelectedSearchedMessage();
            }

            ticketPaginationInfo.current.totalCount -= 1;
            if (updatedTicketIndex !== -1) {
              if (ticketFilterRef.current?.viewTicketsWithoutMe) {
                updateSelectedTicketDetails({ viewTicketsWithoutMe: true });
              } else {
                tickets.splice(updatedTicketIndex, 1);
              }
            }
            setTicketsList(tickets);
            if (
              selectedTicketRef.current?.id ===
                Number(notification.group_details.entity_id) &&
              !ticketFilterRef.current?.viewTicketsWithoutMe &&
              !isFromPatientDetails
            ) {
              isDataExist(tickets)
                ? setSelectedTicket(tickets[0])
                : setSelectedTicket(defaultSelectedTicket);
            } else if (
              selectedTicketRef.current?.id ===
              Number(notification.group_details.entity_id)
            ) {
              setShowChat(false);
            }
          } else if (
            selectedTicketRef.current?.id ===
            Number(notification.group_details.entity_id)
          ) {
            updateSelectedTicketDetails();
          }
          break;
        }
        case INBOX_NOTIFICATION.GROUP_USERS_ADDED:
          {
            if (
              selectedTicketRef.current?.id ===
              Number(notification.group_details.entity_id)
            ) {
              updateSelectedTicketDetails();
            }
          }
          break;
        case INBOX_NOTIFICATION.GROUP_CREATED:
          {
            if (updatedTicketIndex === -1) {
              getTicketByFilter(
                Number(notification.group_details.entity_id),
                (ticket) => {
                  if (
                    ticket.id &&
                    (!isFromPatientDetails ||
                      (isFromPatientDetails &&
                        checkPatientCardAndTicketPatientsSame(
                          patientId,
                          ticket
                        )))
                  ) {
                    tickets.unshift(ticket);
                    setNotification((prevState) => {
                      const newState = { ...prevState };
                      newState[ticket.id] = ticket.unReadMessageCount;
                      return newState;
                    });
                    setTicketsList(tickets);
                  }
                }
              );
            }
          }
          break;
        case INBOX_NOTIFICATION.EDIT_MESSAGE:
          {
            if (
              notification.isMessageUnRead &&
              !notification.isMentionedInMessage &&
              updatedTicketIndex !== -1 &&
              tickets[updatedTicketIndex].mentionedMessageId ===
                notification.group_message_id
            ) {
              tickets[updatedTicketIndex] = {
                ...tickets[updatedTicketIndex],
                mentionedMessageId: null,
                isMentionedMessageRead: true
              };
              setTicketsList(tickets);
            } else if (
              notification.isMessageUnRead &&
              notification.isMentionedInMessage &&
              updatedTicketIndex !== -1 &&
              !tickets[updatedTicketIndex].mentionedMessageId &&
              notification.group_message_id
            ) {
              tickets[updatedTicketIndex] = {
                ...tickets[updatedTicketIndex],
                mentionedMessageId: notification.group_message_id,
                isMentionedMessageRead: false
              };
              setTicketsList(tickets);
            }
          }
          break;
      }
    });
    updateRecentMessage();
  }
};
