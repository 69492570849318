import { FC } from 'react';

import { Tab } from 'components';

export interface TabType {
  label: string;
  count?: string;
  id: string;
  testId?: string;
  endIcon?: JSX.Element;
}

interface TabsProps {
  tabs: Array<TabType>;
  selectedTab: string;
  onChange: (id: string) => void;
  className?: string;
  tabClassName?: string;
  countColour?: string;
  padding?: string;
}

export const TabContainer: FC<TabsProps> = ({
  tabs,
  selectedTab = tabs[0]?.id,
  onChange,
  className,
  tabClassName,
  countColour,
  padding
}) => {
  return (
    <div className={`min-w-max flex space-x-8 ${className}`}>
      {tabs?.map((tab) => (
        <Tab
          endIcon={tab?.endIcon}
          key={tab?.id}
          selected={selectedTab === tab?.id}
          label={tab?.label}
          count={tab?.count}
          handleClick={(e) => {
            e.stopPropagation();
            onChange(tab?.id);
          }}
          tabClassName={tabClassName}
          countColour={countColour}
          padding={padding}
        />
      ))}
    </div>
  );
};
