import React, { ReactNode } from 'react';

import { ErrorCrossIcon, ErrorIcon, More, SuccessIcon } from 'assets/icons';
import { translate, customTwMerge as twMerge } from 'utils';

interface Props {
  status: string;
  statusClassName?: string;
}

export const statusIcons: {
  [index: string]: { icon?: ReactNode; color?: string; value?: string };
} = {
  PENDING_DOCTOR_REVIEW: {
    icon: <ErrorIcon className='w-4 h-4' />,
    value: 'status.pending_doctor_review'
  },
  PENDING_PATIENT_REVIEW: {
    icon: (
      <More className='bg-YELLOW_2 text-WHITE rounded-full w-4 h-4 px-0.5' />
    ),
    color: 'YELLOW_2',
    value: 'status.pending_patient_review'
  },
  PATIENT_ACCEPTED: {
    icon: <SuccessIcon className='w-4 h-4' />,
    color: 'SUCCESS_TEXT',
    value: 'status.patient_accepted'
  },
  PATIENT_REJECTED: {
    icon: <ErrorCrossIcon className='w-4 h-4 text-ERROR' />,
    color: 'ERROR',
    value: 'tpv.patient_rejected'
  },
  REJECTED: {
    icon: <ErrorCrossIcon className='w-4 h-4 text-ERROR' />,
    color: 'ERROR',
    value: 'rejected.rejected'
  },
  PENDING_ZENYUM_COUNCIL_REVIEW: {
    icon: (
      <More className='bg-YELLOW_2 text-WHITE rounded-full w-4 h-4 px-0.5' />
    ),
    color: 'YELLOW_2'
  },
  DISCUSSION_REQUIRED: {
    icon: <ErrorIcon className='w-4 h-4' />,
    value: 'tpv.discussion_required'
  },
  CASE_IN_PLANNING: {
    icon: <ErrorIcon className='w-4 h-4' />,
    value: 'status.in_planning'
  },
  CASE_WITH_ZENYUM: {
    icon: <ErrorIcon className='w-4 h-4' />,
    value: 'status.in_planning'
  },
  CASE_OPS_TPV_CHECK: {
    icon: <ErrorIcon className='w-4 h-4' />,
    value: 'status.in_planning'
  },
  INT_DOC_TPV_CHECK: {
    icon: <ErrorIcon className='w-4 h-4' />,
    value: 'status.in_planning'
  },
  CASE_WITH_INT_DOC: {
    icon: <ErrorIcon className='w-4 h-4' />,
    value: 'status.case_with_int_doc',
    color: 'YELLOW_2'
  },
  IN_PLANNING: { icon: <ErrorIcon className='w-4 h-4' /> },
  DORMANT: { color: 'DEFAULT_TEXT', value: 'dormant.dormant' },
  'In Production': {
    icon: <ErrorIcon className='w-4 h-4' />,
    value: 'retainer.status.in_production'
  },
  'Last Mile Delivery': {
    icon: (
      <More className='bg-YELLOW_2 text-WHITE rounded-full w-4 h-4 px-0.5' />
    ),
    value: 'retainer.status.last_mile_delivery',
    color: 'YELLOW_2'
  },
  Delivered: {
    icon: <SuccessIcon className='w-4 h-4' />,
    value: 'retainer.status.delivered',
    color: 'SUCCESS_TEXT'
  },
  PAID: {
    icon: <SuccessIcon className='w-4 h-4' />,
    value: 'retainer.status.completed',
    color: 'SUCCESS_TEXT'
  },
  PAYMENT_DUE: {
    icon: <ErrorIcon className='w-4 h-4' />,
    value: 'retainer.status.payment_due',
    color: 'ERROR'
  },
  FAILED: {
    icon: <ErrorIcon className='w-4 h-4' />,
    value: 'retainer.status.failed',
    color: 'ERROR'
  },
  PENDING_PICK_UP: {
    icon: (
      <More className='bg-YELLOW_2 text-WHITE rounded-full w-4 h-4 px-0.5' />
    ),
    value: 'retainer.status.pending_pickup',
    color: 'YELLOW_2'
  },
  ILL_FITTING: {
    value: 'retainer.status.ill_fitting',
    color: 'BLACK'
  },
  SUCCESS: {
    icon: <SuccessIcon className='w-4 h-4' />,
    value: 'retainer.status.success',
    color: 'SUCCESS_TEXT'
  },
  PICK_UP_SUCCESS: {
    icon: <SuccessIcon className='w-4 h-4' />,
    value: 'retainer.status.pickup_success',
    color: 'SUCCESS_TEXT'
  },
  RETAINER_ILL_FITTING: {
    icon: <ErrorCrossIcon className='w-4 h-4' />,
    value: 'retainer.status.ill_fitting',
    color: 'ERROR'
  },
  PENDING_APPROVAL: {
    icon: (
      <More className='bg-YELLOW_2 text-WHITE rounded-full w-4 h-4 px-0.5' />
    ),
    color: 'YELLOW_2',
    value: 'status.pending_approval'
  },
  PENDING_CONFIRMATION: {
    icon: (
      <More className='bg-YELLOW_2 text-WHITE rounded-full w-4 h-4 px-0.5' />
    ),
    color: 'YELLOW_2',
    value: 'status.pending_confirmation'
  },
  PENDING_PROPOSAL_SYNC: {
    icon: (
      <More className='bg-YELLOW_2 text-WHITE rounded-full w-4 h-4 px-0.5' />
    ),
    color: 'YELLOW_2',
    value: 'status.pending_approval'
  },
  APPROVED: {
    icon: <SuccessIcon className='w-4 h-4' />,
    value: 'status.soa_approved',
    color: 'SUCCESS_TEXT'
  },
  INVOICE_COMPLETED: {
    icon: <SuccessIcon className='w-4 h-4' />,
    value: 'status.invoice_completed',
    color: 'SUCCESS_TEXT'
  },
  'Waiting for Delivery': {
    icon: <ErrorIcon className='w-4 h-4' />,
    value: 'status.waiting_for_delivery',
    color: 'PRIMARY_DARK'
  }
};

export const VersionStatusComponent: React.FC<Props> = ({
  status,
  statusClassName
}) => {
  return (
    <div
      className={`md:w-full text-${
        statusIcons[status]?.color || 'PRIMARY_DARK'
      }  flex items-center text-17px `}
    >
      {statusIcons[status]?.icon && (
        <div className='mr-2'>{statusIcons[status]?.icon}</div>
      )}

      <p
        className={twMerge(
          'flex flex-wrap xxs:text-sm md:text-17px ',
          statusClassName
        )}
      >
        {translate(`${statusIcons[status]?.value || status}`)}
      </p>
    </div>
  );
};
