import { FC } from 'react';

import { CloseIcon } from 'assets/icons';
import NewChatForm from 'components/NewChatForm/NewChatForm';
import { translate } from 'utils';

interface Props {
  onClose(): void;
}

const NewChatPopup: FC<Props> = ({ onClose }) => {
  return (
    <div
      role='dialog'
      id='modal-container-new-chat'
      data-testid='modal-container-new-chat'
      className='xxs:hidden overflow-auto md:block absolute w-full top-0 left-0 h-full bg-WHITE z-10 px-10 py-8'
    >
      <button onClick={onClose} className='fixed p-2 right-12 z-2'>
        <CloseIcon />
      </button>
      <section className='relative mb-6'>
        <header>
          <h1 className='text-28px'>{translate('actions.newChat')}</h1>
        </header>
        <section className='flex flex-col h-full md:w-3/4'>
          <NewChatForm onClose={onClose} />
        </section>
      </section>
    </div>
  );
};

export default NewChatPopup;
