import { FC } from 'react';

import cx from 'classnames';

import { RejectIcon } from 'assets/icons';
import { Skeleton, Button } from 'components';
import { BULK_REASSIGN_LIMIT } from 'constants/inbox';
import { OwnerInfo } from 'types/Inbox.types';
import { translate } from 'utils';

interface Props {
  ownerFrom?: OwnerInfo;
  ownerTo?: OwnerInfo;
  ticketCount: number;
  disableConfirm: boolean;
  isLoading?: boolean;
  handleBackButtonClick: () => void;
  handleConfirmBtn: () => void;
}

const BulkAssignConfirmation: FC<Props> = ({
  handleBackButtonClick,
  handleConfirmBtn,
  ownerFrom,
  ownerTo,
  disableConfirm,
  ticketCount,
  isLoading = false
}) => {
  const ticketCountData =
    ticketCount > BULK_REASSIGN_LIMIT ? BULK_REASSIGN_LIMIT : ticketCount;

  const ownerDisplayValue = (owner?: OwnerInfo) => {
    return `${owner?.userFirstName} ${owner?.userLastName} (${owner?.emailId})`;
  };
  return (
    <div className='p-8 overflow-hidden bg-white flex flex-col justify-center gap-8 w-776px  px-8 rounded-lg text-DEFAULT_TEXT'>
      <div className="font-['Sofia_Pro'] leading-6  self-start">
        {translate('chat.confirm_reassign')}
      </div>
      <div className='flex flex-col justify-between mr-16 gap-3 items-start'>
        <Skeleton loading={isLoading} width='18.75rem' height='1.25rem'>
          <div className='leading-6'>{`${translate(
            'chat.total_tickets'
          )} = ${ticketCount}`}</div>
        </Skeleton>
        <Skeleton loading={isLoading} width='20.75rem' height='1.25rem'>
          <div className='leading-6 flex'>
            <p>{`${translate('chat.tickets_to_reassign')} =`}</p>
            <p
              className={cx('ml-6px', {
                ' text-DARK_GREEN_2 bg-LIGHT_GREEN_2 px-3 rounded-4xl':
                  ticketCount <= BULK_REASSIGN_LIMIT
              })}
            >
              {ticketCountData}
            </p>
          </div>
        </Skeleton>
        {ticketCount > BULK_REASSIGN_LIMIT && (
          <div className='self-stretch flex flex-row gap-2 items-center'>
            <RejectIcon className='w-4 h-4  items-center ' />
            <div
              className='text-sm  text-ERROR self-start'
              id='bulk-limit-exceed'
            >
              {translate('chat.bulk_assign_validation_message')}
            </div>
          </div>
        )}
      </div>
      <div className='flex flex-row items-center'>
        <div className='flex flex-col gap-3 items-start mr-5 '>
          <div>{translate('chat.original_owner')}</div>
          <div className='bg-LIGHT_BLUE_8 flex flex-col justify-center p-3 shrink-0 items-center rounded-xl'>
            <div className=' text-LIGHT_BLUE_BG_3'>
              {ownerDisplayValue(ownerFrom)}
            </div>
          </div>
        </div>
        <div className='relative flex items-center mt-8'>
          <div className='w-4 z-1 h-0.5 bg-DEFAULT_TEXT'></div>
          <div className='absolute ml-2 w-2 h-2 border-t-2 border-r-2 border-DEFAULT_TEXT transform rotate-45 '></div>
        </div>
        <div className='flex flex-col gap-3 items-start ml-5'>
          <div>{translate('chat.new_owner')}</div>
          <div
            className='border-solid border-LIGHT_BLUE_BG_3 self-stretch p-3 flex flex-col justify-center shrink-0 items-center border rounded-xl'
            id='reassigned-owner'
          >
            <div className=' text-LIGHT_BLUE_BG_3'>
              {ownerDisplayValue(ownerTo)}
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-row gap-5 items-start'>
        <Button
          className='text-center text-sm bg-LIGHT_GRAY flex flex-col justify-center w-1/2 h-12 items-center rounded-4xl'
          onClick={handleBackButtonClick}
        >
          {translate('COMMON.BUTTON.BACK')}
        </Button>
        <Button
          disabled={ticketCount == 0 || isLoading || disableConfirm}
          className='text-center text-sm  text-white bg-PRIMARY flex flex-col justify-center w-1/2 h-12 items-center rounded-4xl'
          onClick={handleConfirmBtn}
        >
          {translate('confirm.confirm')}
        </Button>
      </div>
    </div>
  );
};

export default BulkAssignConfirmation;
