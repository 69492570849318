import { FC, ReactNode } from 'react';

interface IfProps {
  children?: ReactNode;
  condition: boolean;
}

const If: FC<IfProps> = ({ condition, children }) => {
  if (!condition) return null;
  return <> {children} </>;
};

export default If;
