import { FC, ReactNode } from 'react';

import { customTwMerge as twMerge } from 'utils';
interface Props {
  icon: ReactNode;
  value: ReactNode;
  className?: string;
  iconClassName?: string;
  valueClassname?: string;
}
export const IconCard: FC<Props> = ({
  icon,
  value,
  className,
  iconClassName,
  valueClassname
}) => {
  return (
    <div
      className={twMerge(
        'w-full flex text-sm space-x-2 md:space-x-3',
        className
      )}
    >
      <div className={iconClassName}>{icon}</div>
      <div className={valueClassname}>{value}</div>
    </div>
  );
};
