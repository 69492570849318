import { FC } from 'react';

import classNames from 'classnames';

import Tooltip, { TooltipProps } from 'components/Tooltip/Tooltip';

const ChatHeaderTooltip: FC<TooltipProps> = ({
  messageText,
  tooltipStyle,
  children
}) => {
  return (
    <Tooltip
      positions={['top']}
      messageText={messageText}
      arrowClassName='hidden'
      tooltipStyle={classNames(
        'z-50 text-10px bg-LIGHT_GRAY rounded-md py-1 px-2 text-DISABLED',
        tooltipStyle
      )}
    >
      {children}
    </Tooltip>
  );
};

export default ChatHeaderTooltip;
