import { FC } from 'react';

import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { RHFTextArea, SpecialNoteTranslation } from 'components';
import ArchesToTreatInput from 'components/ArchesToTreatInput/ArchesToTreatInput';
import { ProsthesisRestorationInput } from 'components/ProsthesisRestorationInput/ProsthesisRestorationInput';
import { TeethSelectRadioInput } from 'components/TeethSelectRadioInput/TeethSelectRadioInput';
import { TreatmentGoalsInput } from 'components/TreatmentGoalsInput/TreatmentGoalsInput';
import {
  TEETH_TO_EXTRACT_OPTIONS,
  TOOTH_MOVEMENT_RESTRICTIONS_OPTIONS
} from 'constants/TPVFormOptions';
import { hideLiveTranslation } from 'store/atoms/authAtom';
import { translate } from 'utils';

type TreatmentSpecificationsExternalZenclearProps = {
  formType: string;
  isDisabled?: boolean;
  isReferral?: boolean;
  setSpecialNotes: (fieldName: string, result?: string) => void;
};
const TreatmentSpecificationsExternalZenclear: FC<
  TreatmentSpecificationsExternalZenclearProps
> = ({ formType, isDisabled = false, isReferral, setSpecialNotes }) => {
  const hideLiveTranslationBox = useRecoilValue(hideLiveTranslation);
  const { watch } = useFormContext();
  const watchTranslatedNotes = watch(
    `treatmentSpecifications.${formType}.translatedSpecialNotes`
  );
  return (
    <div
      className={classNames('flex flex-col rounded-md  gap-y-10', {
        'bg-GRAY5': !isReferral
      })}
    >
      <TreatmentGoalsInput
        disabled={isDisabled}
        applyDisableStyles={false}
        isZenplus={false}
        classname='flex space-x-4'
        fieldName={`treatmentSpecifications.${formType}.treatmentGoals`}
      />
      <ArchesToTreatInput
        isNewTemplatizedPrescription={true}
        disabled={isDisabled}
        inputName={`treatmentSpecifications.${formType}.archesToTreat`}
        label={translate('2.Arches.to.treat')}
      />
      <TeethSelectRadioInput
        title={translate('3.Tooth.movement.restrictions')}
        radioGroupName={`treatmentSpecifications.${formType}.toothMovementRestrictions.toothMovementRestrictions`}
        radioGroupOptions={TOOTH_MOVEMENT_RESTRICTIONS_OPTIONS}
        teethSelectionName={`treatmentSpecifications.${formType}.toothMovementRestrictions.teethNotToMove`}
        disabled={isDisabled}
        animateLabel={`treatmentSpecifications.${formType}.toothMovementRestrictions.teethNotToMove`}
      />
      <ProsthesisRestorationInput
        isNewTemplatizedPrescription={true}
        title={translate('4.Prosthesis.Restorations')}
        disabled={isDisabled}
        fieldName={`treatmentSpecifications.${formType}.prosthesis`}
      />
      <TeethSelectRadioInput
        title='5.Teeth.Extractions'
        radioGroupName={`treatmentSpecifications.${formType}.extractTeeth.extractTeeth`}
        radioGroupOptions={TEETH_TO_EXTRACT_OPTIONS}
        teethSelectionName={`treatmentSpecifications.${formType}.extractTeeth.teethToExtract`}
        message={translate('teethExtraction.message.zenclear')}
        disabled={isDisabled}
        animateLabel={`treatmentSpecifications.${formType}.extractTeeth.teethToExtract`}
      />

      {hideLiveTranslationBox ? (
        <RHFTextArea
          inputProps={{
            placeholder: translate('settings.please_type_here'),
            disabled: isDisabled
          }}
          title={translate('6.Special.Notes')}
          name={`treatmentSpecifications.${formType}.specialNotes`}
          autogrow
        />
      ) : (
        <SpecialNoteTranslation
          isTranslateFieldDefined={!!watchTranslatedNotes}
          setValue={setSpecialNotes}
          isDisabled={isDisabled}
          title={translate('6.Special.Notes')}
          InputTextFieldName={`treatmentSpecifications.${formType}.specialNotes`}
          translatedTextFieldName={`treatmentSpecifications.${formType}.translatedSpecialNotes`}
        />
      )}
    </div>
  );
};

export default TreatmentSpecificationsExternalZenclear;
