import { GET_RECENT_TICKET_LIST } from 'services/query/Dashboard';
import { GetRecentTicketsList } from 'types/Dashboard.types';

import { UseGraphQlQuery } from './hooks';

const useRecentTicketList: GetRecentTicketsList = (config) =>
  UseGraphQlQuery(GET_RECENT_TICKET_LIST, {
    ...config
  });

export { useRecentTicketList };
