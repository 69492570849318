import { gql } from '@apollo/client';

export const SUBMIT_PRESCRIPTION = gql`
  mutation SubmitPrescription($id: ID!, $input: PrescriptionInput!) {
    submitPrescription(treatmentId: $id, input: $input) {
      id
      patientId
      status
      stage
      type
    }
  }
`;

export const SAVE_PRESCRIPTION = gql`
  mutation SavePrescription($id: ID!, $input: PrescriptionInput!) {
    savePrescription(treatmentId: $id, input: $input) {
      id
      patientId
      status
      stage
      type
      details {
        metadata
      }
    }
  }
`;

export const DELETE_TREATMENT = gql`
  mutation DeleteTreatment($treatmentId: String!) {
    deleteTreatment(treatmentId: $treatmentId) {
      id
    }
  }
`;

export const GET_SUBMISSION_REASON = gql`
  query {
    getRefinementSubmissionReasons {
      key
      value
    }
  }
`;
