import { useEffect, useMemo, useRef } from 'react';

import { useRecoilValue } from 'recoil';

import { pagePaths } from 'config/pages';
import { CLINIC_CLOUD, ZENBASE } from 'constants/common';
import {
  INBOX_GROUP_NAME,
  SEGMENT_STATUS,
  TICKET_STATUS
} from 'constants/inbox';
import { DEVICE_TYPE } from 'constants/segment';
import {
  loginedUserDetails,
  selectedTicket,
  selectedTicketActiveTreatement
} from 'store/atoms';
import { treatmentAppointment } from 'store/atoms/appointment';
import { globalSelectedClinic } from 'store/atoms/authAtom';
import { getCurrentBranches } from 'store/selectors/auth';
import { PageDetails } from 'types/Segment.types';
import { isMobileBrowser, checkIfAppIsAdminClinic } from 'utils';
import { getFullName } from 'utils/common';
import { getTicketList } from 'utils/customHooks/inbox';
import { useQuery } from 'utils/customHooks/useQuery';
import { useIsmobile } from 'utils/customHooks/useViewport';
import { formatSegmentTimeDiff } from 'utils/dateUtils';

interface TrackData {
  zenchat_ID: number;
  zenchat_topic: string;
  chat_created_ts: string;
  zenchat_status: string;
  zenchat_urgent: string;
  user_permission: string;
}

interface TrackContextData {
  zenchat_context_type: string;
  treatment_plan_id: string;
  treatment_plan_version_id: string;
  treatment_plan_type: string;
  treatment_plan_version: string;
}

interface TrackPatientData {
  patient_name: string;
  patient_id: string;
  patient_country: string;
  opportunity_name: string;
  opportunity_id: string;
}

interface SegmentTrackData {
  trackData: TrackData;
  trackContextData: TrackContextData;
  trackPatientData: TrackPatientData;
}

export const getDeviceType = (): string => {
  const { userAgent } = navigator;
  const DEVICES = [
    'Android',
    'iPad',
    'iPod',
    'iPhone',
    'Linux',
    'Windows',
    'Mac'
  ];

  for (const device of DEVICES) {
    if (userAgent.match(device)) {
      return device;
    }
  }
  return 'other';
};

export const useTrackData = (): SegmentTrackData => {
  const selectedTicketDetails = useRecoilValue(selectedTicket);
  const { id } = useRecoilValue(loginedUserDetails);
  const activeTreatment = useRecoilValue(selectedTicketActiveTreatement);

  const getUserPermission = () => {
    const ownerIndex = selectedTicketDetails.ticketUsers.findIndex(
      (user) => user.groupName === INBOX_GROUP_NAME.CHAT_OWNER
    );
    const ownerId = selectedTicketDetails.ticketUsers[ownerIndex]?.userId;
    const initiatorIndex = selectedTicketDetails.ticketUsers.findIndex(
      (user) => user.groupName === INBOX_GROUP_NAME.CHAT_INITIATOR
    );
    const initiatorId =
      selectedTicketDetails.ticketUsers[initiatorIndex]?.userId;

    if (ownerId === id) return 'Owner';
    if (initiatorId === id) return 'Initiator';
    return 'Member';
  };

  const getZenchatStatus = (status: string) => {
    if (status === TICKET_STATUS.OPEN) {
      return SEGMENT_STATUS.OPENED;
    } else if (status === TICKET_STATUS.CLOSE) {
      return SEGMENT_STATUS.CLOSED;
    }
    return status;
  };

  const trackData = {
    zenchat_ID: selectedTicketDetails.id,
    zenchat_topic: selectedTicketDetails?.topicInfo?.topicName,
    zenchat_status: getZenchatStatus(selectedTicketDetails.status),
    chat_created_ts: selectedTicketDetails.createdAt,
    zenchat_urgent: selectedTicketDetails.isUrgent ? 'Yes' : 'No',
    user_permission: getUserPermission()
  };

  const { metaData, ticketDetails } = useMemo(
    () => getTicketList([selectedTicketDetails])[0],
    [selectedTicketDetails]
  );

  const getZenchatContextType = () => {
    const { tpvDetails, tpDetails } = metaData;
    if (tpvDetails) return 'TPV';
    if (tpDetails) return 'TP';
    return 'None';
  };

  const trackContextData = {
    zenchat_context_type: getZenchatContextType(),
    treatment_plan_id: metaData.tpDetails?.contextValueId || 'none',
    treatment_plan_version_id: metaData.tpvDetails?.contextValueId || 'none',
    treatment_plan_version: metaData.tpvDetails?.contextValue || 'none',
    treatment_plan_type: metaData.tpDetails?.contextValue || 'none'
  };

  const treatmentType = selectedTicketDetails?.treatmentInfo?.type;
  const trackPatientData = {
    patient_name: metaData.name,
    patient_id: metaData.patientId,
    patient_country: ticketDetails.treatmentInfo?.patient?.country || '',
    opportunity_name:
      treatmentType === 'REFINEMENT' &&
      ticketDetails.treatmentInfo?.retainerOpportunityId
        ? 'RETAINER'
        : treatmentType,
    opportunity_id:
      ticketDetails.treatmentInfo?.retainerOpportunityId ||
      activeTreatment[0].details.opportunityId
  };

  return { trackData, trackContextData, trackPatientData };
};

export const useSegmentPage = (details: PageDetails): void => {
  const host = window.location.host;

  useEffect(() => {
    const startTime = new Date();
    return () => {
      const endTime = new Date();
      const duration = endTime.getTime() - startTime.getTime();
      window.analytics?.page(details.title, {
        view_type: isMobileBrowser() ? 'mobile' : details.view_type,
        duration: formatSegmentTimeDiff(duration),
        device_type: getDeviceType(),
        url: `${host}${details.path}`,
        ...details
      });
    };
  }, []);
};

const getViewType = () => {
  const { pathname } = window.location;
  const basePath = pathname.split('/')[1];
  if (basePath === 'patient') {
    return 'side panel';
  } else {
    return 'inbox';
  }
};

export const useSegmentTrackChat = (): ((
  title: string,
  details?: any,
  isScreen?: boolean
) => void) => {
  const trackCallback = (title: string, details: any) => {
    window?.analytics?.track(title, {
      ...details,
      view_type: isMobileBrowser() ? 'mobile' : getViewType(),
      device_type: getDeviceType()
    });
  };
  return trackCallback;
};

interface Trackcallback {
  title: string;
  details?: any;
  isPage?: boolean;
}

export const useSegmentTrackReferral = (branchName?: string) => {
  const currentBranches = useRecoilValue(getCurrentBranches);
  const currentBranchNames = currentBranches
    .map((branch) => branch.name)
    .join(',');
  const isMobile = useIsmobile();
  const device = isMobile ? DEVICE_TYPE.MOBILE : DEVICE_TYPE.DESKTOP;
  const query = useQuery();
  const { pathname } = window.location;
  const isReferPatientPage = pathname.includes(pagePaths.referPatient);
  const referral_id = isReferPatientPage ? query?.get('id') ?? '' : undefined;
  const { id: user_id } = useRecoilValue(loginedUserDetails);
  const branch = branchName ?? currentBranchNames;
  const clinic = useRecoilValue(globalSelectedClinic)?.label || '';
  const trackCallback = ({
    title,
    details = {},
    isPage = false
  }: Trackcallback) => {
    const trackFn = isPage ? window?.analytics?.page : window?.analytics?.track;
    trackFn?.(title, {
      branch,
      clinic,
      user_id,
      referral_id,
      device,
      ...details
    });
  };
  return trackCallback;
};

export const useSegmentAppointmentDetails = (): ((
  title: string,
  details?: any,
  keys?: any
) => void) => {
  const treatmentAppointmentDetails = useRecoilValue(treatmentAppointment);
  const { id: treatmentAppointmentId, patientTreatmentInfo } =
    treatmentAppointmentDetails;
  const { patient, caseComplexity } = patientTreatmentInfo || {};
  const { id: patientId, firstName, lastName } = patient || {};

  const isAdminClinic = checkIfAppIsAdminClinic();
  const trackCallback = (title: string, details: any, keys: any) => {
    let values = {};
    if (keys) {
      values = Object.keys(keys).reduce(
        (acc, item) => ({
          ...acc,
          [item]: keys[item].reduce(
            (value: any, el: string) => value?.[el],
            treatmentAppointmentDetails
          )
        }),
        {}
      );
    }
    window?.analytics?.track(title, {
      page: 'Appointments Internal',
      ...details,
      ...values,
      view: isAdminClinic ? ZENBASE : CLINIC_CLOUD,
      appt_ID: treatmentAppointmentId,
      patient_id: patientId,
      patient_name: getFullName({ firstName, lastName }),
      case_complexity: caseComplexity || ''
    });
  };
  return trackCallback;
};

export const useSegmentAppointmentPage = (
  details: PageDetails
): ((details: any) => void) => {
  const treatmentAppointmentDetails = useRecoilValue(treatmentAppointment);
  const isAdminClinic = checkIfAppIsAdminClinic();
  const commonDetailsRef = useRef({
    view: isAdminClinic ? ZENBASE : CLINIC_CLOUD,
    appt_ID: '',
    patient_id: '',
    patient_name: '',
    case_complexity: ''
  });

  const setExtraDetails = (details: any) => {
    commonDetailsRef.current = {
      ...commonDetailsRef.current,
      ...details
    };
  };

  const { id: treatmentAppointmentId, patientTreatmentInfo } =
    treatmentAppointmentDetails;
  const { patient, caseComplexity } = patientTreatmentInfo || {};
  const { id: patientId, firstName, lastName } = patient || {};
  const getPatientName = () => {
    return getFullName({ firstName, lastName });
  };

  useEffect(() => {
    commonDetailsRef.current = {
      ...commonDetailsRef.current,
      appt_ID: treatmentAppointmentId,
      patient_id: patientId || '',
      patient_name: getPatientName(),
      case_complexity: caseComplexity?.toString() || ''
    };
  }, [treatmentAppointmentDetails]);

  useEffect(() => {
    const startTime = new Date();
    return () => {
      const endTime = new Date();
      const duration = endTime.getTime() - startTime.getTime();
      window.analytics.page(details.title, {
        ...details,
        ...commonDetailsRef.current,
        duration: formatSegmentTimeDiff(duration)
      });
    };
  }, []);
  return setExtraDetails;
};
