import { FC, Fragment } from 'react';

import { Link } from 'react-router-dom';

import { translate } from 'utils';

export interface Breadcrumb {
  label: string;
  pathname?: string;
}

interface Props {
  links: Breadcrumb[];
}

export const Breadcrumbs: FC<Props> = ({ links }) => {
  return (
    <div className='flex items-center space-x-2 text-sm w-full'>
      {links?.map((link, index) => (
        <Fragment key={link?.label}>
          {link?.pathname ? (
            <Link className='truncate shrink-0' to={link?.pathname}>
              {translate(link?.label)}
            </Link>
          ) : (
            <div className='truncate'>{translate(link?.label)}</div>
          )}
          <div>{index + 1 !== links?.length ? '/' : ''}</div>
        </Fragment>
      ))}
    </div>
  );
};
