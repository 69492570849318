import { FC, useMemo, useState } from 'react';

import { sortBy } from 'lodash';
import { useRecoilValue } from 'recoil';

import { Button, Modal, NameCard, Radio } from 'components';
import { INBOX_GROUP_NAME } from 'constants/inbox';
import { CHAT_TRACK_EVENTS } from 'constants/segment';
import { useUpdateTicketOwner } from 'services/hooks/Inbox.hooks';
import {
  useSegmentTrackChat,
  useTrackData
} from 'services/hooks/Segment.hooks';
import { loginedUserDetails } from 'store/atoms';
import { OwnerDetails, Participant } from 'types/Inbox.types';
import { formatParticipantName, translate } from 'utils';
import { getParticipantCount } from 'utils/common';
import { useSetError } from 'utils/customHooks/useSetError';
import { getSegmentUserDetails } from 'utils/inbox';

interface Props {
  isOpen: boolean;
  ticketId: number;
  participants: Participant[];
  setIsOpen: (isOpen: boolean) => void;
}

interface ParticipantMap {
  [key: string]: Participant;
}

const ReassignOwner: FC<Props> = ({
  isOpen,
  setIsOpen,
  participants,
  ticketId
}) => {
  const [selectedNewOwner, setSelectedNewOwner] = useState<string>('');

  const userDetails = useRecoilValue(loginedUserDetails);

  const { trackData, trackPatientData } = useTrackData();
  const handleSegmentTrack = useSegmentTrackChat();

  const { setError } = useSetError();

  const [updateOwner, { loading }] = useUpdateTicketOwner({
    onCompleted: (data: OwnerDetails) => {
      const zenchatUpdateUserBqData = {
        zenchat_ID: trackData.zenchat_ID,
        opportunity_name: trackPatientData.opportunity_name,
        patient_name: trackPatientData.patient_name,
        patient_country: trackPatientData.patient_country,
        zenchat_status: trackData.zenchat_status,
        zenchat_urgent: trackData.zenchat_urgent,
        zenchat_topic: trackData.zenchat_topic,
        chat_created_ts: trackData.chat_created_ts,
        no_of_participants: getParticipantCount(data.updateTicketOwnership),
        ...getSegmentUserDetails(data.updateTicketOwnership)
      };
      handleSegmentTrack(
        CHAT_TRACK_EVENTS.ZENCHAT_USER_UPDATE_BQ,
        zenchatUpdateUserBqData
      );
      setIsOpen(false);
      const message = 'Owner updated successfully';
      setError(message, true);
    }
  });

  const handleSubmit = () => {
    const param = {
      variables: {
        userId: selectedNewOwner,
        ticketId
      }
    };
    updateOwner(param);
  };

  const formattedParticipantDetails = useMemo(() => {
    const ownerList: string[] = [];
    const participantMap: ParticipantMap = {};
    participants.forEach((participant) => {
      if (participant.groupName === INBOX_GROUP_NAME.CHAT_OWNER) {
        ownerList.push(participant.userId);
      } else if (!participant.isExternal && !participant.deactivatedAt) {
        participantMap[participant.userId] = participant;
      }
    });

    //To remove all the owner fields
    ownerList.forEach((owner) => {
      delete participantMap[owner];
    });
    let formattedList = Object.values(participantMap);
    formattedList = sortBy(formattedList, ['userFirstName', 'userLastName']);
    return formattedList;
  }, [participants, userDetails.id]);

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <section className='text-ONYX p-6 max-w-lg'>
        <p className='text-xl mb-3'>{translate('inbox.reassign_owner')}</p>
        <div className='text-sm'>
          <p>{translate('inbox.reassign.message_1')}</p>
          <p className='mt-4'>{translate('inbox.reassign.message_2')}</p>
        </div>
        <div className='max-h-60 overflow-auto mt-6'>
          {formattedParticipantDetails.map((participant) => (
            <div key={participant.userId} className='flex items-center'>
              <Radio
                name='owner'
                value={participant.userId}
                onChange={(e) => setSelectedNewOwner(e.target.value)}
              />
              <NameCard
                name={formatParticipantName(participant, userDetails)}
                className='text-sm min-w-min ml-3 '
                image={participant.userProfilePicture}
              />
            </div>
          ))}
        </div>
        <div className='w-full flex justify-end mt-2'>
          <Button
            className='bg-PRIMARY text-WHITE text-sm  px-8 py-3'
            onClick={handleSubmit}
            disabled={!selectedNewOwner || loading}
          >
            Confirm
          </Button>
        </div>
      </section>
    </Modal>
  );
};

export default ReassignOwner;
