import { FC } from 'react';

import { translate } from 'utils';

const ParticipantDescription: FC = () => {
  return (
    <section className='text-DEFAULT_TEXT text-sm mb-3 mt-5 ml-4'>
      <p>{translate('INBOX.PARTICIPANT_DESC.SELECT_TOPIC_WILL')}</p>
      <ul className='list-disc pl-5'>
        <li>{translate('INBOX.PARTICIPANT_DESC.AUTOMATICALLY_ADD')}</li>
        <li>{translate('INBOX.PARTICIPANT_DESC.RELEVANT_INFO')}</li>
      </ul>
    </section>
  );
};

export default ParticipantDescription;
