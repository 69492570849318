import { atom } from 'recoil';

import { TreatmentAppointment } from 'types/Appointment.types';

import {
  ATOMS,
  defaultAfterSalesAppointmentSidePanelFilters,
  defaultTreatmentAppointment
} from './constants';

const treatmentAppointment = atom<TreatmentAppointment>({
  key: ATOMS.TREATMENT_APPOINTMENT,
  default: defaultTreatmentAppointment
});

const selectedDoctorsClinicId = atom<string>({
  key: ATOMS.SELECTED_DOCTORS_CLINIC_ID,
  default: ''
});

const afterSalesAppointmentSidePanelFilters = atom<any>({
  key: ATOMS.AFTER_SALES_APPOINTMENT_SIDEPANEL_FILTERS,
  default: defaultAfterSalesAppointmentSidePanelFilters
});

export {
  treatmentAppointment,
  selectedDoctorsClinicId,
  afterSalesAppointmentSidePanelFilters
};
