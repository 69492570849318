import { FC, LegacyRef, ReactNode, useState } from 'react';

// eslint-disable-next-line import/order
import FullCalendar from '@fullcalendar/react';
import {
  CalendarOptions,
  DateMarker,
  DayCellContentArg,
  DayHeaderContentArg,
  EventContentArg,
  MoreLinkArg,
  ViewApi
} from '@fullcalendar/common';
import dayGridPlugin from '@fullcalendar/daygrid';
import momentPlugin from '@fullcalendar/moment';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGrid from '@fullcalendar/scrollgrid';
import timegrid from '@fullcalendar/timegrid';
import classNames from 'classnames';
import moment from 'moment';

import './calendar.css';

import { FULL_CALENDAR_LICENSE_KEY } from 'config';
import { CALENDAR_VIEWS } from 'constants/options';
import i18n from 'i18n';
import { Event } from 'types/Calendar.types';
import { translate, useViewport } from 'utils';
import { formatTimeRange } from 'utils/dateUtils';

import CalendarPopover from './components/CalendarPopover';
import monthViewMobile from './customViews/MonthViewMobile';
import ScheduleView from './customViews/ScheduleView';

type PopoverStyle = {
  x: number;
  y: number;
  date: Date;
};

interface AdditionalProps {
  onEventSelect?: (event: Event) => void;
}

export const FullPageCalendar: FC<
  CalendarOptions & {
    calendarRef: LegacyRef<FullCalendar>;
  } & AdditionalProps
> = ({ calendarRef, onEventSelect, ...props }) => {
  const [popoverConfig, setPopoverConfig] = useState<null | PopoverStyle>(null);
  const { width } = useViewport();
  const isMobile = width < 768;

  const renderDay: (
    DayCellContentArg: DayCellContentArg
  ) => ReactNode | string = ({
    date,
    view
  }: {
    date: DateMarker;
    view: ViewApi;
  }) => {
    if (view?.type === CALENDAR_VIEWS.MONTH && date.getDate() === 1) {
      return moment(date).format('D MMM');
    }
  };

  const renderMoreLinkContent = ({ num }: { num: number }) => `${num} more`;

  const renderEventContent = ({ event, view }: EventContentArg) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    const { patient, expireAt } = event?._def?.extendedProps;
    const timeDiff = moment(event?._def?.extendedProps?.appointmentEnd).diff(
      event?._def?.extendedProps?.appointmentStart,
      'minutes'
    );
    const timeText = `${formatTimeRange(
      event?._def?.extendedProps?.appointmentStart
    )} - ${formatTimeRange(event?._def?.extendedProps?.appointmentEnd)}`;
    if (isMobile) {
      if (view?.type !== CALENDAR_VIEWS.WEEK) {
        return (
          <div
            className={classNames('rounded overflow-hidden text-DEFAULT_TEXT', {
              'p-2': timeDiff > 45,
              'p-1': timeDiff >= 30,
              'p-0.5': timeDiff < 30
            })}
          >
            <div className='flex justify-between items-center'>
              <span
                className={classNames('text-sm font-bold truncate', {
                  'text-10px': timeDiff < 30
                })}
              >
                {patient?.name}
              </span>
              {view?.type !== CALENDAR_VIEWS.RESOURCE_DAY && (
                <span className='text-10px truncate'>{`${moment(
                  event?.start
                ).format('h A')} - ${moment(event?.end).format('h A')}`}</span>
              )}
            </div>
            {timeDiff > 30 && (
              <p className='text-10px truncate mt-1'>{event?._def?.title}</p>
            )}
          </div>
        );
      } else {
        return (
          <span className='text-10px font-bold p-0.5 flex items-center h-full truncate text-DEFAULT_TEXT'>
            {patient?.name}
          </span>
        );
      }
    } else {
      if (view?.type === CALENDAR_VIEWS.MONTH) {
        return (
          <div className='h-4 w-full flex  justify-between items-center  text-DEFAULT_TEXT'>
            <div
              className='rounded-full h-2 w-2'
              style={{ background: event._def.ui.backgroundColor }}
            />
            <div className='mx-1 flex-1 h-full truncate'>
              {event._def.title}
            </div>
            <div className='h-full'>{timeText}</div>
          </div>
        );
      } else {
        return (
          <div
            className={classNames(
              'max-h-full max-w-full flex flex-col text-left overflow-hidden h-20 text-DEFAULT_TEXT',
              {
                'p-2': timeDiff > 45,
                'p-1': timeDiff >= 30,
                'p-0.5': timeDiff < 30
              }
            )}
          >
            {timeDiff > 15 && (
              <p className='text-xs'>
                {timeText} {expireAt && `[PENDING]`}
              </p>
            )}
            <p
              className={classNames('text-sm font-bold truncate', {
                'text-10px': timeDiff < 30
              })}
            >
              {patient?.name}
            </p>
            {timeDiff > 30 && (
              <p className='text-xs truncate mt-1'>{event._def.title}</p>
            )}
          </div>
        );
      }
    }
  };

  const renderDayHeader = ({
    date,
    isToday,
    view
  }: DayHeaderContentArg): ReactNode | string => {
    if (view?.type === CALENDAR_VIEWS.WEEK) {
      const day = moment(date)?.format('ddd');
      return (
        <div className='md:text-sm xxs:text-xs leading-4'>
          {isMobile ? day[0] : day}
          <p
            className={classNames(
              'md:text-17px xxs:text-sm mt-2 md:h-8 md:w-8 xxs:h-6 xxs:w-6 flex flex-col items-center justify-center',
              {
                'bg-PRIMARY rounded-full flex justify-center items-center text-WHITE':
                  isToday
              }
            )}
          >
            {date?.getDate()}
          </p>
        </div>
      );
    }
  };

  const getDefaultTime = () => {
    const currentDate = new Date();
    return `${currentDate?.getHours() - 1}:${currentDate?.getMinutes()}`;
  };

  const handleMonthMoreClick = (args: MoreLinkArg) => {
    // note: default popover is hidden with css
    const { date, jsEvent } = args;
    const { clientX: x, clientY: y } = jsEvent as any;
    setPopoverConfig({
      x,
      y,
      date
    });
  };

  return (
    <>
      <FullCalendar
        ref={calendarRef}
        plugins={[
          dayGridPlugin,
          timegrid,
          momentPlugin,
          monthViewMobile,
          ScheduleView,
          resourceTimeGridPlugin,
          scrollGrid
        ]}
        schedulerLicenseKey={FULL_CALENDAR_LICENSE_KEY}
        height='100%'
        nowIndicator={true}
        dayCellContent={renderDay}
        dayMaxEventRows={3}
        moreLinkContent={renderMoreLinkContent}
        eventContent={renderEventContent}
        dayHeaderContent={renderDayHeader}
        slotDuration='01:00:00'
        expandRows={true}
        fixedWeekCount={false}
        slotLabelFormat={{
          hour: 'numeric',
          omitZeroMinute: true
        }}
        allDaySlot={!isMobile}
        allDayContent={() => translate('calendar.all_day')}
        allDayClassNames='allDayClassName'
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: 'short'
        }}
        moreLinkClassNames='moreLinkClassName'
        headerToolbar={
          isMobile
            ? false
            : {
                start: 'title prev,next',
                center: '',
                end: ''
              }
        }
        views={{
          [CALENDAR_VIEWS.WEEK]: {
            titleFormat: ({ start, end }) =>
              `${moment(start).format('D')} - ${moment(end).format(
                'D MMM YYYY'
              )}`
          },
          [CALENDAR_VIEWS.RESOURCE_DAY]: {
            dayMinWidth: 200
          },
          [CALENDAR_VIEWS.MONTH]: {
            eventMaxStack: 3,
            moreLinkContent: translate('calendar.viewmore'),
            moreLinkClick: handleMonthMoreClick
          }
        }}
        locale={i18n?.language}
        scrollTime={getDefaultTime()}
        {...props}
      />
      {popoverConfig ? (
        <CalendarPopover
          x={popoverConfig.x}
          y={popoverConfig.y}
          date={popoverConfig.date}
          onClose={() => setPopoverConfig(null)}
          onEventSelect={onEventSelect}
        />
      ) : null}
    </>
  );
};
