import { FC } from 'react';

import cx from 'classnames';

import loaderImg from 'assets/gif/loader.gif';

interface Props {
  fullPage?: boolean;
  className?: string;
  containerClassName?: string;
}

export const Loader: FC<Props> = ({
  fullPage,
  className,
  containerClassName
}) => {
  return (
    <div
      className={cx('flex items-center justify-center', containerClassName, {
        'fixed inset-0 bg-LOADER_BG z-30 md:z-42': fullPage
      })}
      data-testid='loader'
    >
      <img
        src={loaderImg}
        className={className || 'w-20 h-20'}
        data-testid='loaderIcon'
        alt='loading...'
      />
    </div>
  );
};
