import { FC } from 'react';

import { Button, Modal } from 'components';
import { LeaveInfo } from 'types/leave';
import { translate } from 'utils';
import { useLeaveHooks } from 'utils/customHooks/leave.customHooks';

interface Props {
  onClose: () => void;
  isLoginedUser?: boolean;
  isOpen: boolean;
  leaveInfo?: LeaveInfo | null;
  setSelectedLeave: React.Dispatch<React.SetStateAction<LeaveInfo | null>>;
}

const CancelLeave: FC<Props> = ({
  isOpen,
  onClose,
  leaveInfo,
  isLoginedUser,
  setSelectedLeave
}) => {
  const { cancelLeave } = useLeaveHooks({ setSelectedLeave, isLoginedUser });
  const onSubmit = () => {
    if (leaveInfo?.id) {
      cancelLeave(leaveInfo.id);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      classNames={{
        modal: 'mx-4'
      }}
    >
      <section className='p-6 md:w-656px'>
        <h4 className='text-DEFAULT_TEXT text-xl font-semibold'>
          {translate('leave.cancel_leave')}
        </h4>
        <p className='mt-8 text-sm text-ONYX'>
          {translate('leave.cancel_desc', {
            start: leaveInfo?.startDate || '-',
            end: leaveInfo?.endDate || '-',
            leavetype: leaveInfo?.type || translate('leave.leave')
          })}
        </p>
        <div className='flex justify-end mt-6'>
          <Button className='py-3 px-4 text-DEFAULT_TEXT' onClick={onClose}>
            {translate('cancel.cancel')}
          </Button>
          <Button
            className='bg-PRIMARY text-WHITE px-5 py-3 ml-2'
            onClick={onSubmit}
          >
            {translate('confirm.confirm')}
          </Button>
        </div>
      </section>
    </Modal>
  );
};

export default CancelLeave;
