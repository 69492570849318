import { useApolloClient, useLazyQuery } from '@apollo/client';
import {
  VerifyChatAuthKeyOutput
  // eslint-disable-next-line import/no-unresolved
} from '@chat/chat-sdk/dist/types';

import chatClient from 'chatSDK';
import { CHAT_AUTH_RESPONSE } from 'constants/auth';
import { TRACK_EVENTS } from 'constants/segment';
import {
  CHANGE_PASSWORD,
  GET_CHAT_TOKEN,
  GET_LOGINED_USER_DETAILS,
  LOG_OUT,
  PASSWORD_LOGIN,
  RESET_PASSWORD,
  VERIFY_PASSWORD_LINK,
  USER_HEAD_CLINICS,
  SELECT_USER_CLINIC
} from 'services/query/auth';
import {
  GENERATE_RESET_LINK,
  CHECK_IF_EXISTING_ACCOUNT
} from 'services/query/login';
import {
  AuthenticateToken,
  AuthMutation,
  ChangePassword,
  ChatTokenDetails,
  GetChatTokenInfo,
  GetLoginedUserDetails,
  LoginMutation,
  PasswordLoginDetails,
  UserHeadClinic,
  VerifyEmail
} from 'types/Login.types';
import { showToast } from 'utils/toast/toast.util';

import {
  UseGraphQlMutation,
  UseGraphQlMutationWithTpes,
  UseGraphQlQuery
} from './hooks';
import { useIsChatAuthenticated, useIsLoggedIn } from '../auth/authToken';

const defaultConfig = {};

export const PasswordLogin: LoginMutation = (config = defaultConfig) => {
  const [, setIsLoggedIn] = useIsLoggedIn();

  return UseGraphQlMutation(PASSWORD_LOGIN, {
    ...config,
    onCompleted: (data: PasswordLoginDetails) => {
      setIsLoggedIn(!!data.passwordLogin.accessToken);
      window.analytics.track(TRACK_EVENTS?.LOGIN);
    }
  });
};

export const useChatAuthentication = () => {
  const client = useApolloClient();
  const chat = chatClient.getChatClient();
  const { setIsChatAuthenticated } = useIsChatAuthenticated();
  const chatAuthentication = async () => {
    const { data: { getChatAccessToken } = {} } =
      await client.query<ChatTokenDetails>({
        query: GET_CHAT_TOKEN,
        fetchPolicy: 'no-cache'
      });
    if (!getChatAccessToken?.key) return;

    const key = getChatAccessToken.key;
    chat.verifyChatAuthKey(
      { key },
      ({ message }: VerifyChatAuthKeyOutput) => {
        const isSuccess = message === CHAT_AUTH_RESPONSE.SUCCESS;
        setIsChatAuthenticated(isSuccess);
      },
      (error: any) => {
        const message = error.response?.errors?.[0].message;
        showToast(message, false);
      }
    );
  };

  return { chatAuthentication };
};

export const logOut: LoginMutation = (config = defaultConfig) => {
  return UseGraphQlMutation(LOG_OUT, {
    ...config
  });
};

export const generatePasswordResetLink: AuthMutation = (
  config = defaultConfig
) => {
  return UseGraphQlMutation(GENERATE_RESET_LINK, config);
};

export const ForgotPassword: AuthMutation = (config = defaultConfig) =>
  UseGraphQlMutation(RESET_PASSWORD, config);

export const VerifyToken: AuthenticateToken = (config = defaultConfig) =>
  UseGraphQlQuery(VERIFY_PASSWORD_LINK, config);

export const GetLoggedInUserDetails: GetLoginedUserDetails = (
  config = defaultConfig
) => UseGraphQlQuery(GET_LOGINED_USER_DETAILS, config);

export const useGetChatToken: GetChatTokenInfo = (config = defaultConfig) => {
  return useLazyQuery<ChatTokenDetails>(GET_CHAT_TOKEN, config);
};

export const useChangePassword: ChangePassword = (config) => {
  return UseGraphQlMutationWithTpes(CHANGE_PASSWORD, config);
};

export const useUserHeadClinics: UserHeadClinic = (config = defaultConfig) =>
  UseGraphQlQuery(USER_HEAD_CLINICS, config);

export const useSelectUserClinic: AuthMutation = (config = defaultConfig) =>
  UseGraphQlMutation(SELECT_USER_CLINIC, config);

export const useVerifyIfExistingAccount: VerifyEmail = (
  config = defaultConfig
) => UseGraphQlMutation(CHECK_IF_EXISTING_ACCOUNT, config);
