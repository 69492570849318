import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { ErrorIcon } from 'assets/icons';
import RadioGroup from 'components/RadioGroup/RadioGroup';
import { InputProps } from 'types/input';
import { Option } from 'types/select';
import { translate } from 'utils';

type RhfRadioGroupProp = {
  name: string;
  options: Option[];
  title?: string;
  showErrorTitle?: boolean;
  className?: string;
  radioClassName?: string;
  inputProps?: InputProps;
  radioItemFont?: string;
  message?: string;
  titleClassName?: string;
  optionClassName?: string;
  onClick?: () => void;
  showError?: boolean;
  infoToolTipMessage?: string;
};

const RhfRadioGroup: FC<RhfRadioGroupProp> = ({
  name,
  options,
  title,
  showErrorTitle = false,
  showError = false,
  className = '',
  radioClassName = '',
  inputProps,
  radioItemFont,
  message = '',
  optionClassName,
  titleClassName,
  onClick,
  infoToolTipMessage
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <RadioGroup
            onChangehandle={field.onChange}
            options={options}
            title={title}
            className={className}
            name={name}
            showErrorTitle={showErrorTitle || !!error?.message}
            selectedItem={field.value}
            radioClassName={radioClassName}
            inputProps={inputProps}
            radioItemFont={radioItemFont}
            message={message}
            titleClassName={titleClassName}
            optionClassName={optionClassName}
            onClick={onClick}
            infoToolTipMessage={infoToolTipMessage}
          />
          <div>
            {error?.message && showError && (
              <p className='text-ERROR text-xs mt-2 flex '>
                <ErrorIcon />
                <span className='ml-2'>{translate(error?.message)}</span>
              </p>
            )}
          </div>
        </>
      )}
    />
  );
};

export default RhfRadioGroup;
