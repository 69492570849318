import { atom } from 'recoil';

import { SuccessErrorAlertType } from 'types/successErrorAlert';

import { ATOMS, defaultAlertValue } from './constants';

export const successErrorAlert = atom<SuccessErrorAlertType>({
  key: ATOMS.SUCCESS_ERROR_ALERT_ATOM,
  default: defaultAlertValue
});
