import { FC } from 'react';

import cx from 'classnames';
import { twMerge } from 'tailwind-merge';

import { FilterIcon } from 'assets/icons';
import { useToggle } from 'utils/customHooks/useToggle';

import { ActionFilter, TicketSearch } from './index';

interface TicketFiltersProp {
  isFilterOpen: boolean;
  isFilterSelected: boolean;
  onFilterClick: () => void;
}

const TicketFilters: FC<TicketFiltersProp> = ({
  isFilterOpen,
  isFilterSelected,
  onFilterClick
}) => {
  const [isSearchExpanded, toggleIsSearchExpanded] = useToggle();

  return (
    <section className='flex justify-between items-center py-2 md:pr-4 md:pl-3 h-[3.125rem]'>
      <ActionFilter hide={isSearchExpanded} />
      <div
        className={cx('flex justify-center items-center gap-x-2', {
          'flex-1': isSearchExpanded
        })}
      >
        <TicketSearch
          isExpanded={isSearchExpanded}
          toggleExpansion={toggleIsSearchExpanded}
        />
        <button onClick={onFilterClick} id='ZenChatFilter'>
          <FilterIcon
            className={twMerge(
              cx('h-5 w-6', {
                'bg-black fill-current text-white rounded-full py-1 h-[1.5rem] w-[1.5rem]':
                  isFilterOpen,
                'bg-PRIMARY fill-current text-white rounded-full py-1 h-[1.5rem] w-[1.5rem]':
                  isFilterSelected
              })
            )}
          />
        </button>
      </div>
    </section>
  );
};

export default TicketFilters;
