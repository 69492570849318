import {
  GET_DESIGN_LAB,
  GET_OPPORTUNITY_ID,
  GET_SUPPORTED_DESIGN_LAB,
  GET_TPV_DATA,
  GET_TREATMENT_PLAN_VERSION_IPR_DATA,
  SAVE_TPV,
  SUBMIT_TPV
} from 'services/query/TPVForm.queries';
import { UseQueryType } from 'types/hooks';
import {
  GetDesignLabs,
  GetOpportunityData,
  GetPatientDetails,
  MutationType
} from 'types/PatientList.types';
import {
  GetSupportedDesignLabsList,
  GetTreatmentPlanVersions
} from 'types/TPVForm.types';

import { UseGraphQlMutation, UseGraphQlQuery } from './hooks';

export const useGetTPVData: GetPatientDetails = (config) => {
  return UseGraphQlQuery(GET_TPV_DATA, config);
};

export const useGetOpportunityId: UseQueryType<GetOpportunityData> = (
  config
) => {
  return UseGraphQlQuery(GET_OPPORTUNITY_ID, config);
};
export const useGetDesignLabs: UseQueryType<GetDesignLabs> = (config) => {
  return UseGraphQlQuery(GET_DESIGN_LAB, config);
};

export const useSaveTPV: MutationType<any> = (config) => {
  return UseGraphQlMutation(SAVE_TPV, config);
};

export const useSubmitTPV: MutationType<any> = (config) => {
  return UseGraphQlMutation(SUBMIT_TPV, config);
};

export const useGetTreatmentplanVersionIPRData: UseQueryType<
  GetTreatmentPlanVersions
> = (config) => {
  return UseGraphQlQuery(GET_TREATMENT_PLAN_VERSION_IPR_DATA, config);
};

export const useGetSupportedDesignLabs: UseQueryType<
  GetSupportedDesignLabsList
> = (config) => UseGraphQlQuery(GET_SUPPORTED_DESIGN_LAB, config);
