import { FC } from 'react';

import cx from 'classnames';

import { translate } from 'utils';
import { formatDateWithTimeZone } from 'utils/dateUtils';

interface Props {
  isAvailable?: boolean;
  showDate?: boolean;
  startDate?: Date;
  timeZone?: string;
  endDate?: Date;
}

const LeaveStatus: FC<Props> = ({
  isAvailable,
  startDate,
  endDate,
  timeZone = '',
  showDate = true
}) => {
  const showDateText = !isAvailable && showDate && !!startDate && !!endDate;

  return (
    <div
      className={cx(' flex items-center ', {
        'text-SUCCESS_ICON_DEFAULT': isAvailable,
        'text-YELLOW_4': !isAvailable
      })}
    >
      <div
        className={cx('rounded-full  w-3 h-3', {
          'bg-SUCCESS_ICON_DEFAULT': isAvailable,
          'bg-YELLOW_4': !isAvailable
        })}
      />
      <div className='ml-2 font-bold text-sm'>
        {isAvailable
          ? translate('leave.available')
          : translate('leave.on_leave')}
      </div>
      {showDateText && (
        <h5 className='text-sm text-DEFAULT_TEXT ml-3'>
          {`${formatDateWithTimeZone(
            startDate,
            timeZone
          )} - ${formatDateWithTimeZone(endDate, timeZone)}`}
        </h5>
      )}
    </div>
  );
};

export default LeaveStatus;
