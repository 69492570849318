import { FC, useMemo, useState } from 'react';

import { Loader } from 'components';
import { Divider } from 'components/Divider/Divider';
import { If } from 'components/Generics';
import ZoomPhotoPopup from 'components/ZoomPhotoPopup/ZoomPhotoPopup';
import { OUTCOME_BACKEND } from 'constants/calendarConstants';
import {
  useGetPriorTreatmentOptions,
  useGetTreatmentPlanTypes,
  useGetTreatmentSpecficationOptions
} from 'services/hooks/TreatmentPlanning.hooks';
import { FilesData } from 'types/Files.types';
import { PendingPrescriptionFiles } from 'types/PendingPrescription.types';
import { ALIGNER_INFO_TYPE } from 'types/TPVForm.types';
import {
  PriorTreatment,
  TreatmentSpecifications
} from 'types/TreatmentPlanning.types';
import { translate } from 'utils';
import {
  getPriorTreatment,
  getTreatmentSpecificationDetails,
  planTypeCode
} from 'utils/TreatmentPlanningInstructions.utils';

import PrescriptionDetails from './PrescriptionDetails/PrescriptionDetails';
import PrescriptionFiles from './PrescriptionFiles/PrescriptionFiles';
import PrescriptionHeader from './PrescriptionHeader/PrescriptionHeader';

type Props = {
  maxLimit?: number;
  priorTreatment: PriorTreatment | undefined;
  treatmentSpecifications: TreatmentSpecifications | undefined;
  documents?: FilesData[];
  isCompleted?: boolean;
  allFiles?: PendingPrescriptionFiles;
  isRefinement?: boolean;
};

const getCanPlanningProceed = (priorTreatment: PriorTreatment | undefined) => {
  if (priorTreatment?.canPlanningProceed === undefined) {
    return '-';
  }

  return priorTreatment?.canPlanningProceed
    ? translate('yes.yes')
    : translate('no.no');
};

const showLoader = (specLoading: boolean, priorLoading: boolean) =>
  specLoading || priorLoading;

const PendingPrescriptionDetails: FC<Props> = ({
  documents,
  priorTreatment,
  treatmentSpecifications,
  isCompleted = true,
  allFiles,
  maxLimit,
  isRefinement = false
}) => {
  const isIllFitting =
    treatmentSpecifications?.version === 'V2' &&
    treatmentSpecifications?.tpv?.submissionReason ===
      OUTCOME_BACKEND.ILL_FITTING_AT_FITTING;
  const isRefinementIllFitting = isRefinement || isIllFitting;

  const [zoomPhoto, toggleZoomPhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<string | number>(0);
  const {
    data = {
      getAttachementOptions: [],
      getCrowdingTreatmentOptions: [],
      getIPRLocations: []
    },
    loading: specLoading
  } = useGetTreatmentSpecficationOptions();
  const {
    data: { getAllTreatmentTypes } = {
      getAllTreatmentTypes: []
    }
  } = useGetTreatmentPlanTypes();
  const { data: priorTreatmentOptionsReponse, loading: priorLoading } =
    useGetPriorTreatmentOptions();

  const {
    attachmentReuired,
    crowdingResolvement,
    iprLocation,
    planType,
    planInstructionEnabled
  } = useMemo(
    () =>
      getTreatmentSpecificationDetails(
        data,
        getAllTreatmentTypes,
        treatmentSpecifications
      ),
    [data, treatmentSpecifications, getAllTreatmentTypes]
  );
  const treatments = useMemo(
    () =>
      getPriorTreatment(
        priorTreatmentOptionsReponse?.getPriorTreatmentsList,
        priorTreatment?.treatments
      ),
    [priorTreatmentOptionsReponse?.getPriorTreatmentsList, priorTreatment]
  );
  const getAlignerInfo = () => {
    return treatmentSpecifications?.tpv?.alignerInfo?.map(
      (item: { name: string; value: { name: string; value: string }[] }) => {
        return (
          <div className='flex flex-col mb-4 space-y-1' key={item?.name}>
            <p
              className={'md:text-17px text-base text-DEFAULT_TEXT'}
            >{`${item?.name} :`}</p>
            <div className='flex'>
              {item?.value?.map(
                (innerItem: { name: string; value: string }) => {
                  return (
                    <div key={innerItem?.name} className='flex'>
                      <p className={'md:text-base text-sm text-DEFAULT_TEXT'}>
                        {`${innerItem?.name} :`}
                      </p>
                      {innerItem?.name === ALIGNER_INFO_TYPE.ZEN_STAGE ? (
                        <p
                          className={
                            'md:text-base text-sm text-DEFAULT_TEXT ml-1'
                          }
                        >{`${innerItem?.value}`}</p>
                      ) : (
                        <p
                          className={
                            'md:text-base text-sm text-DEFAULT_TEXT ml-1'
                          }
                        >{`${innerItem?.value}/${maxLimit}`}</p>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        );
      }
    );
  };

  if (showLoader(specLoading, priorLoading)) return <Loader fullPage />;
  return (
    <div className='text-DEFAULT_TEXT'>
      <h2 className='text-17px font-normal text-DEFAULT_TEXT md:mb-8 mb-4'>
        {translate('treating_doctor_prescription')}
      </h2>
      {!isRefinementIllFitting && (
        <>
          <div className='flex'>
            <div className='flex flex-col md:space-y-6 space-y-4'>
              <PrescriptionHeader
                label={translate('prescriptions.1._prior_treatment_needed')}
                status={
                  isCompleted
                    ? translate('chips_label_(non_interactive).completed')
                    : ''
                }
              />
              <PrescriptionDetails
                label={`${translate('prescriptions.prior_treatment_needed')}?`}
                value={treatments}
              />
              <If condition={priorTreatment?.canPlanningProceed === true}>
                <PrescriptionDetails
                  label={translate(
                    'prescriptions.can_treatment_planning_proceed?'
                  )}
                  value={getCanPlanningProceed(priorTreatment)}
                />
              </If>
            </div>
          </div>
          <div className='my-6'>
            <Divider />
          </div>
        </>
      )}
      <div className='flex'>
        <div className='md:space-y-6 space-y-4'>
          <PrescriptionHeader
            label={
              !isRefinementIllFitting
                ? translate('prescriptions.2._treatment_specifications')
                : translate('prescriptions.1._treatment_specifications')
            }
            status={
              isCompleted
                ? translate('chips_label_(non_interactive).completed')
                : ''
            }
          />
          <If condition={!isRefinementIllFitting}>
            <>
              <PrescriptionDetails
                label={translate('prescriptions.attachments')}
                value={attachmentReuired}
              />
              <PrescriptionDetails
                label={translate('prescriptions.ipr_location')}
                value={iprLocation}
              />
              <PrescriptionDetails
                label={translate('prescriptions.resolve_crowding')}
                value={crowdingResolvement}
              />
              <PrescriptionDetails
                label={translate('prescriptions.teeth_extraction_instructions')}
                value={
                  treatmentSpecifications?.teethToExtract ||
                  translate('prescriptions.none')
                }
              />
            </>
          </If>
          <If condition={isIllFitting}>
            <>
              <PrescriptionDetails
                label={translate('submission.reason')}
                value={translate('Ill.fitting.at.fitting')}
              />
              <div className='font-normal'>
                <p className='text-GRAY3 text-sm mb-2'>
                  {translate('aligner.Information')}
                </p>

                {getAlignerInfo()}
              </div>
            </>
          </If>

          <PrescriptionDetails
            label={translate('prescriptions.treatment_plan_type')}
            value={planType}
          />
          <If condition={planInstructionEnabled[planTypeCode.ZENCLEAR]}>
            <PrescriptionDetails
              label={translate(
                'prescriptions.ZenClear_treatment_plan_instructions'
              )}
              value={treatmentSpecifications?.treatmentPlanInstructions}
              valueClassName='whitespace-pre'
            />
          </If>
          <If condition={planInstructionEnabled[planTypeCode.ZEN_PLUS]}>
            <PrescriptionDetails
              label={translate(
                'prescriptions.ZenPlus_treatment_plan_instructions'
              )}
              value={treatmentSpecifications?.zenplusInstructions}
              valueClassName='whitespace-pre'
            />
          </If>
        </div>
      </div>
      <div className='my-6'>
        <Divider />
      </div>
      <div className='flex'>
        <div className='md:space-y-6 space-y-4 w-full'>
          <PrescriptionHeader
            label={
              !isRefinementIllFitting
                ? translate('prescriptions.3._patient_files')
                : translate('prescriptions.2._patient_files')
            }
            status={
              isCompleted
                ? translate('chips_label_(non_interactive).completed')
                : ''
            }
          />
          {!documents ? (
            <div>-</div>
          ) : (
            <>
              <PrescriptionFiles
                label={translate('referrals.photos')}
                setSelectedPhoto={setSelectedPhoto}
                files={allFiles?.PHOTO}
                toggleZoomPhoto={toggleZoomPhoto}
              />
              <PrescriptionFiles
                label={translate('consent_form.consent_form')}
                files={allFiles?.CONSENT_FORM}
                setSelectedPhoto={setSelectedPhoto}
                toggleZoomPhoto={toggleZoomPhoto}
              />
              <PrescriptionFiles
                label={translate('x-rays.x-rays')}
                files={allFiles?.X_RAY}
                setSelectedPhoto={setSelectedPhoto}
                toggleZoomPhoto={toggleZoomPhoto}
              />
              <PrescriptionFiles
                label={translate('scans.scans')}
                files={allFiles?.SCAN}
                setSelectedPhoto={setSelectedPhoto}
                toggleZoomPhoto={toggleZoomPhoto}
              />
            </>
          )}
        </div>
      </div>
      <ZoomPhotoPopup
        allFiles={documents}
        zoomPhoto={zoomPhoto}
        toggleZoomPhoto={toggleZoomPhoto}
        selectedPhoto={selectedPhoto as string}
        setSelectedPhoto={setSelectedPhoto}
      />
    </div>
  );
};

export default PendingPrescriptionDetails;
