import { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import Button from 'components/Button/Button';
import { If } from 'components/Generics';
import RhfInput from 'components/Generics/Rhf/RhfInput';
import { useUpdateUser } from 'services/hooks/UsersList.hooks';
import { StaffDetails, UserDetailsForm } from 'types/UsersList';
import { translate } from 'utils';
import { showToast } from 'utils/toast/toast.util';
import { ResetPasswordSchema } from 'validations/LoginValidation';

interface Props {
  userDetails: StaffDetails;
}

const UpdateLogin: FC<Props> = ({ userDetails }) => {
  const [showEditLogin, setShowEditLogin] = useState(false);
  const [updateUser] = useUpdateUser();
  const formMethods = useForm<UserDetailsForm>({
    mode: 'onChange',
    resolver: yupResolver(ResetPasswordSchema)
  });
  const {
    reset,
    handleSubmit,
    formState: { isValid }
  } = formMethods;

  const toggleEditLogin = () => {
    setShowEditLogin((prevState) => !prevState);
  };
  const onClickCancel = () => {
    reset();
    toggleEditLogin();
  };
  const onSubmit = async (data: UserDetailsForm) => {
    const userInfo = await updateUser({
      variables: {
        id: userDetails?.id,
        input: {
          username: data?.username || undefined,
          phone: data?.phone,
          email: data?.email,
          password: data?.password
        }
      }
    });

    const updatedUserDetails = userInfo?.data?.updateUser;
    if (updatedUserDetails) {
      toggleEditLogin();
      showToast(translate('updated.user'), true);
    }
  };

  return (
    <div>
      <h4 className='text-sm font-bold text-DEFAULT_TEXT'>
        {translate('settings.login')}
      </h4>
      {!showEditLogin && (
        <Button
          className='text-white bg-PRIMARY px-4 py-1 mt-3'
          type='button'
          onClick={toggleEditLogin}
        >
          {translate('settings.edit_login')}
        </Button>
      )}

      <If condition={showEditLogin}>
        <FormProvider {...formMethods}>
          <form>
            <div>
              <div className='w-1/3'>
                <div className='mt-8'>
                  <RhfInput
                    label={translate('new_password.new_password')}
                    inputProps={{
                      type: 'text',
                      placeholder: translate('settings_password.password_error')
                    }}
                    name='password'
                    className='bg-BG_COLOR text-DISABLED_2'
                    showAnimated={false}
                  />
                </div>
                <div className='mt-4'>
                  <RhfInput
                    label={translate('password.confirm_password')}
                    className='bg-BG_COLOR text-DISABLED_2'
                    inputProps={{
                      type: 'password',
                      placeholder: translate('settings_password.retype')
                    }}
                    name='passwordConfirmation'
                    showAnimated={false}
                  />
                </div>
              </div>
              <div className='flex my-4'>
                <Button
                  className='text-PRIMARY border-PRIMARY border-1 px-4 py-1'
                  type='button'
                  onClick={onClickCancel}
                >
                  {translate('cancel.cancel')}
                </Button>
                <Button
                  className='bg-PRIMARY text-WHITE px-4 py-1 ml-2'
                  type='submit'
                  disabled={!isValid}
                  onClick={handleSubmit(onSubmit)}
                >
                  {translate('button_rounded_(short).save')}
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>
      </If>
    </div>
  );
};

export default UpdateLogin;
