import React, { useCallback, useEffect, useState } from 'react';

import cx from 'classnames';
import { debounce } from 'lodash';

import { FilterIcon, CloseIcon, SearchIcon } from 'assets/icons';
import { If } from 'components/Generics';

interface Props {
  className?: string;
  placeholder?: string;
  debounceTime?: number;
  highlightAdvanceSearch?: boolean;
  onSearch: (text: string) => void;
  handleAdvanceSearchClick?: () => void;
  searchWord?: string;
  inputClassName?: string;
  isClose?: boolean;
  showAdvanceSearch?: boolean;
  bgColor?: string;
}

const Search: React.FC<Props> = ({
  className = 'h-full rounded-full w-full',
  placeholder = 'Search',
  onSearch,
  searchWord = '',
  showAdvanceSearch = false,
  highlightAdvanceSearch = false,
  handleAdvanceSearchClick,
  debounceTime = 1000,
  inputClassName,
  isClose = false,
  bgColor = 'bg-GRAY'
}) => {
  const [text, setText] = useState('');
  const showCloseIcon = isClose && !!text;
  const debouncedSearch = useCallback(
    debounce((searchText: string) => {
      onSearch(searchText);
    }, debounceTime),
    []
  );

  const onChange = useCallback((searchText: string) => {
    setText(searchText);
    debouncedSearch(searchText);
  }, []);

  useEffect(() => {
    if (searchWord !== text) {
      setText(searchWord);
    }
  }, [searchWord]);

  const clearText = () => {
    setText('');
    onSearch('');
  };

  return (
    <div
      className={`flex items-center  space-x-2 border-none ${bgColor} h-full ${className} relative`}
    >
      <div className='pl-4'>
        <SearchIcon className='w-3 h-3 md:w-4 md:h-4' />
      </div>
      <input
        type='text'
        maxLength={500}
        className={`border-none bg-transparent focus:outline-none w-full flex-1 ${inputClassName} `}
        placeholder={placeholder}
        data-testid='search-bar'
        onChange={(e) => onChange(e.target.value)}
        value={text}
      />
      <If condition={showCloseIcon}>
        <div
          className={cx('xxs:hidden md:block', {
            'pr-8': !showAdvanceSearch,
            'pr-2': showAdvanceSearch
          })}
        >
          <CloseIcon onClick={clearText} className='cursor-pointer' />
        </div>
      </If>
      <If condition={showAdvanceSearch}>
        <div
          className={cx('xxs:hidden md:block', {
            'pr-8': !showCloseIcon,
            'pr-2': showCloseIcon
          })}
        >
          <FilterIcon
            onClick={handleAdvanceSearchClick}
            className={cx('cursor-pointer', {
              'text-PRIMARY': highlightAdvanceSearch
            })}
            data-testid='advance-search-icon'
          />
        </div>
      </If>
    </div>
  );
};

export default Search;
