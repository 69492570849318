import { ChangeEvent } from 'react';

import { ControllerRenderProps, FieldValues } from 'react-hook-form';

import { ZENSTAGE_OPTIONS } from 'constants/options';
import {
  ARCHES_TO_TREAT,
  MIDLINES,
  MIDLINES_SHIFT_SUB,
  OVERBITES,
  TEETH_SELECT_OPTIONS,
  TRUTH_VALUE,
  TREATMENT_GOALS_PROCEDURES,
  ZENCLEAR_TPV_REFINEMENT_FORM_ARCHES_TO_TREAT,
  ZENCLEAR_TPV_REFINEMENT_FORM_ATTACHMENTS,
  IPR_REFINEMENT_FORM_OPTIONS,
  REFINEMENT_GOALS_OPTIONS,
  REFINEMENT_GOALS,
  REFINEMENT_IPR,
  ATTACHMENTS_OPTIONS,
  RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS,
  ZENCLEAR_TPV_REFINEMENT_FORM_REASON_BACKEND,
  ZENCLEAR_TPV_REFINEMENT_FORM_REASON_WITH_ILL_FITTING
} from 'constants/TPVFormOptions';
import {
  TPVPrescription,
  TREATMENT_PLAN_TYPE,
  TreatmentGoalsObject,
  TreatmentPlanType,
  VersionDetails,
  VersionGroup
} from 'types/PatientList.types';
import { ZenClearType, ZenPlusType } from 'types/PendingPrescription.types';
import { Option } from 'types/select';
import { TreatmentPLanPreferenceFormType } from 'types/Settings.types';
import {
  ALIGNER_INFO_TYPE,
  AlignerIPRInfo,
  IprInfo,
  TPVFormField,
  TPVFormTypes,
  ZenclearApiInput,
  ZenclearFormType,
  RefinementTPVApiInput,
  RefinementFormType,
  ZenPlusApiInput,
  ZenPlusFormType
} from 'types/TPVForm.types';
import { TeethExtraction } from 'types/TreatmentPlanning.types';
import {
  formatTeethsForController,
  formatTeethToExtract,
  formatTeethSelection,
  formatClinicalPreferences
} from 'utils/TreatmentPlanningInstructions.utils';

export const getAlignerData = (alignerData: TPVFormField[], type: string) => {
  const alignerInfo = alignerData?.find((option) => option?.name === type);
  if (alignerInfo) {
    return {
      radioValue: alignerInfo?.value?.[0]?.name,
      inputValue: alignerInfo?.value?.[0]?.value
    };
  }
};
export const getSetAndZenStageDefaultValues = (
  currentAligner?: number,
  setAlignerCount?: number
): { set: number; zenstage: number } => {
  let setValue, zenstageValue;
  if (!!currentAligner && !!setAlignerCount) {
    if (currentAligner >= setAlignerCount) {
      setValue = setAlignerCount;
      zenstageValue = currentAligner - setAlignerCount;
    } else {
      setValue = currentAligner;
      zenstageValue = 0;
    }
  }
  return {
    set: setValue || 0,
    zenstage: zenstageValue || 0
  };
};
export const isTpvExisting = (
  treatmentPlans: TreatmentPlanType[],
  setShowConsentSendButton: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const isTpv = treatmentPlans?.some((item) => {
    return item?.versions?.length > 0;
  });
  setShowConsentSendButton(!isTpv);
  return;
};
export const formatZenclearRefinementFormData = (
  isZenPlus: boolean,
  data?: TPVPrescription,
  alignerAndIPRInfo?: AlignerIPRInfo,
  treatmentPlanningLab?: string,
  manufacturer?: string,
  hideLiveTranslation?: boolean
): RefinementFormType | undefined => {
  const refinementGoals: Option[] = [];
  data?.refinementGoals?.forEach((element) => {
    const option = REFINEMENT_GOALS_OPTIONS?.find(
      (item) => item?.value === element?.name
    );
    if (option) refinementGoals?.push(option);
  });
  const toothChart2: Record<string, string> = {};
  data?.refinementGoals
    ?.find((option) => option?.name === REFINEMENT_GOALS.CLOSE_RESIDUAL_SPACES)
    ?.value?.forEach((item) => {
      toothChart2[item?.position] = item?.value.slice(0, -2);
    });

  const alignerUpperData = getAlignerData(data?.alignerInfo || [], 'upper');
  const alignerLowerData = getAlignerData(data?.alignerInfo || [], 'lower');

  const toothChart3 =
    data?.iprInfo?.name === REFINEMENT_IPR.NOT_AS_PRESCRIBED
      ? data?.iprInfo?.value?.map((item) => item?.position) || []
      : [];

  const attachmentsData = data?.refinementGoals
    ?.find(
      (option) => option?.name === REFINEMENT_GOALS.RESOLVE_POSTERIOR_OPEN_BITE
    )
    ?.value?.find(
      (item) =>
        item?.name === RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.ATTACHMENTS_ONLY ||
        item?.name === RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.SEE_RESULTING_PLAN
    );
  const buttonsForElasticsData = data?.refinementGoals
    ?.find(
      (option) => option?.name === REFINEMENT_GOALS.RESOLVE_POSTERIOR_OPEN_BITE
    )
    ?.value?.find(
      (item) =>
        item?.name ===
        RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.ADD_BUTTON_FOR_ELASTICS
    );
  const newdata = {
    reasonForSubmission:
      ZENCLEAR_TPV_REFINEMENT_FORM_REASON_WITH_ILL_FITTING?.find(
        (reason) =>
          ZENCLEAR_TPV_REFINEMENT_FORM_REASON_BACKEND[reason?.value] ===
          data?.submissionReason
      ) || ZENCLEAR_TPV_REFINEMENT_FORM_REASON_WITH_ILL_FITTING[0],
    archesToTreat:
      Number(data?.archesToTreat?.length) > 1
        ? ZENCLEAR_TPV_REFINEMENT_FORM_ARCHES_TO_TREAT[2]
        : ZENCLEAR_TPV_REFINEMENT_FORM_ARCHES_TO_TREAT?.find(
            (option) => option?.value === data?.archesToTreat[0]
          ) || ZENCLEAR_TPV_REFINEMENT_FORM_ARCHES_TO_TREAT[2],
    attachments:
      ZENCLEAR_TPV_REFINEMENT_FORM_ATTACHMENTS?.find(
        (option) => option?.value === data?.attachments
      ) || ZENCLEAR_TPV_REFINEMENT_FORM_ATTACHMENTS[0],
    iprPerformed:
      alignerAndIPRInfo?.iprInfo?.length === 0
        ? IPR_REFINEMENT_FORM_OPTIONS[0]
        : IPR_REFINEMENT_FORM_OPTIONS?.find(
            (option) => option?.value === data?.iprInfo?.name
          ) || IPR_REFINEMENT_FORM_OPTIONS[0],
    refinementGoals,
    specialNotes: data?.specialNotes || '',
    translatedSpecialNotes: hideLiveTranslation
      ? ''
      : data?.translatedSpecialNotes || '',
    AlignerInput: {
      radioUpper: alignerUpperData?.radioValue || ALIGNER_INFO_TYPE.SET,
      radioLower: alignerLowerData?.radioValue || ALIGNER_INFO_TYPE.SET,
      alignerInputUpperSet:
        alignerUpperData?.radioValue === ALIGNER_INFO_TYPE.SET &&
        alignerUpperData?.inputValue
          ? alignerUpperData?.inputValue?.split('/')?.[0]
          : getSetAndZenStageDefaultValues(
              alignerAndIPRInfo?.alignerInfo?.currentAligner,
              alignerAndIPRInfo?.alignerInfo?.setAlignerCount
            ).set,
      alignerInputLowerSet:
        alignerLowerData?.radioValue === ALIGNER_INFO_TYPE.SET &&
        alignerLowerData?.inputValue
          ? alignerLowerData?.inputValue?.split('/')?.[0]
          : getSetAndZenStageDefaultValues(
              alignerAndIPRInfo?.alignerInfo?.currentAligner,
              alignerAndIPRInfo?.alignerInfo?.setAlignerCount
            ).set,
      alignerInputUpperZenstage: ZENSTAGE_OPTIONS?.find(
        (option) =>
          option?.value ===
          (alignerUpperData?.radioValue === ALIGNER_INFO_TYPE.ZEN_STAGE
            ? alignerUpperData?.inputValue
            : `${
                getSetAndZenStageDefaultValues(
                  alignerAndIPRInfo?.alignerInfo?.currentAligner,
                  alignerAndIPRInfo?.alignerInfo?.setAlignerCount
                ).zenstage
              }`)
      ),
      alignerInputLowerZenstage: ZENSTAGE_OPTIONS?.find(
        (option) =>
          option?.value ===
          (alignerLowerData?.radioValue === ALIGNER_INFO_TYPE.ZEN_STAGE
            ? alignerLowerData?.inputValue
            : `${
                getSetAndZenStageDefaultValues(
                  alignerAndIPRInfo?.alignerInfo?.currentAligner,
                  alignerAndIPRInfo?.alignerInfo?.setAlignerCount
                ).zenstage
              }`)
      )
    },
    toothChart2,
    toothChart3,
    ...(!!isZenPlus && {
      buttonsForElastics:
        buttonsForElasticsData?.name ===
        RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.ADD_BUTTON_FOR_ELASTICS
          ? formatTeethsForController(buttonsForElasticsData?.value)
          : undefined
    }),
    attachmentsOnly:
      attachmentsData?.name ===
      RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.ATTACHMENTS_ONLY
        ? formatTeethsForController(attachmentsData?.value)
        : undefined,
    attachmentsSelect:
      ATTACHMENTS_OPTIONS?.find(
        (option) => option?.value === attachmentsData?.name
      ) || ATTACHMENTS_OPTIONS[0],
    designId: data?.designId,
    iprInfoText: data?.iprInfoText || '',
    formType: isZenPlus ? TPVFormTypes.ZENPLUS : TPVFormTypes.ZENCLEAR,
    ...(!!data &&
      treatmentPlanningLab !== null && {
        treatmentPlanningLab: {
          label: treatmentPlanningLab,
          value: treatmentPlanningLab
        }
      }),
    ...(!!data &&
      manufacturer !== null && {
        manufacturer: {
          label: manufacturer,
          value: manufacturer
        }
      }),
    attachmentsOnlyCheck: !!attachmentsData,
    ...(!!isZenPlus && { addButtonsForElasticsCheck: !!buttonsForElasticsData })
  };
  return newdata;
};

export const formatZenclearFormData = (
  treatmentPlanningLab?: string,
  manufacturer?: string,
  data?: TPVPrescription,
  hideLiveTranslation?: boolean
): ZenclearFormType | undefined => {
  if (data) {
    const newdata = {
      ...(!!data?.treatmentGoals &&
        data?.treatmentGoals?.length > 0 && {
          treatmentGoals: data.treatmentGoals?.map((item: any) => item.name)
        }),
      archesToTreat: getArchesToTreat(data?.archesToTreat),
      teethNotToMove: formatTeethsForController(
        data?.toothMovementRestrictions
      ),
      toothMovementRestrictions: data?.toothMovementRestrictions
        ? formatTeethSelection(data?.toothMovementRestrictions)
        : TEETH_SELECT_OPTIONS.NONE,
      prosthesisIprToAvoid: formatTeethsForController(
        data?.prosthesisIprToAvoid
      ),
      prosthesisAttachmentToAvoid: formatTeethsForController(
        data?.prosthesisAttachmentToAvoid
      ),
      teethToExtract: formatTeethsForController(data?.teethToExtract),
      extractTeeth: data?.teethToExtract
        ? formatTeethSelection(data?.teethToExtract)
        : TEETH_SELECT_OPTIONS.NONE,
      prosthesis: data?.prosthesis ? data.prosthesis : TRUTH_VALUE.NO,
      specialNotes: data?.specialNotes,
      translatedSpecialNotes: hideLiveTranslation
        ? ''
        : data?.translatedSpecialNotes || '',
      ...(treatmentPlanningLab !== null && {
        treatmentPlanningLab: {
          label: treatmentPlanningLab,
          value: treatmentPlanningLab
        }
      }),
      ...(manufacturer !== null && {
        manufacturer: {
          label: manufacturer,
          value: manufacturer
        }
      })
    };
    return newdata;
  }
};

export const getArchesToTreat = (arches?: string[]) => {
  if (arches?.length)
    return arches?.length > 1 ? ARCHES_TO_TREAT.BOTH : arches[0];
  return ARCHES_TO_TREAT.BOTH;
};
export const TREATMENT_GOALS_ARRAY = [
  'ALIGNMENT',
  'RETRACTION',
  'SPACE_CLOSURE'
];
export const treatmentGoalsOptionsFromAPI = (goal: TreatmentGoalsObject[]) => {
  const activeGoals = goal?.map((item) => item.name);
  const newGoals: string[] = [];
  activeGoals?.map(
    (g: string) => !TREATMENT_GOALS_ARRAY.includes(g) && newGoals.push(g)
  );
  return newGoals;
};

export const getProcedures = (procedures: string[]): Option[] => {
  return procedures.map((item) => ({ label: item, value: item }));
};
export const formatZenPlusFormData = (
  treatmentPlanningLab?: string,
  manufacturer?: string,
  data?: TPVPrescription,
  hideLiveTranslation?: boolean
): ZenPlusFormType | undefined => {
  if (data) {
    const { shiftLower, shiftUpper } = formatMidlinesShiftForForm(
      data?.midlinesShifts
    );
    const goalsAndProcedures = getTreatmentGoalsAndProcedures(
      data?.treatmentGoals
    );
    const {
      overbite = OVERBITES.SHOW_RESULTING,
      midlines = MIDLINES.SHOW_RESULTING,
      prosthesis = TRUTH_VALUE.NO
    } = data || {};

    const newdata: ZenPlusFormType = {
      ...(!!goalsAndProcedures.goals &&
        goalsAndProcedures.goals?.length > 0 && {
          treatmentGoals: goalsAndProcedures.goals
        }),
      treatmentGoalsProcedures: goalsAndProcedures.treatmentGoalsProcedure,
      proceduresInnerFields: goalsAndProcedures.proceduresInnerFields,
      upperLowerProcedures: goalsAndProcedures.upperLowerProcedures,
      archesToTreat: getArchesToTreat(data?.archesToTreat),
      teethNotToMove: formatTeethsForController(
        data?.toothMovementRestrictions
      ),
      toothMovementRestrictions: data?.toothMovementRestrictions
        ? formatTeethSelection(data?.toothMovementRestrictions)
        : TEETH_SELECT_OPTIONS.NONE,
      prosthesisIprToAvoid: formatTeethsForController(
        data?.prosthesisIprToAvoid
      ),
      prosthesisAttachmentToAvoid: formatTeethsForController(
        data?.prosthesisAttachmentToAvoid
      ),
      teethToExtract: formatTeethsForController(data?.teethToExtract),
      extractTeeth: data?.teethToExtract
        ? formatTeethSelection(data?.teethToExtract)
        : TEETH_SELECT_OPTIONS.NONE,
      prosthesis,
      ipr: data?.prosthesis === TRUTH_VALUE.YES && !!data?.prosthesisIprToAvoid,
      attachment:
        data?.prosthesis === TRUTH_VALUE.YES &&
        !!data?.prosthesisAttachmentToAvoid,
      specialNotes: data?.specialNotes,
      translatedSpecialNotes: hideLiveTranslation
        ? ''
        : data?.translatedSpecialNotes || '',
      overbite,
      midlines,
      shiftUpperPosition: shiftUpper as string,
      shiftLowerPosition: shiftLower as string,
      shiftLower: !!shiftLower,
      shiftUpper: !!shiftUpper,
      ...(treatmentPlanningLab !== null && {
        treatmentPlanningLab: {
          label: treatmentPlanningLab,
          value: treatmentPlanningLab
        }
      }),
      ...(manufacturer !== null && {
        manufacturer: {
          label: manufacturer,
          value: manufacturer
        }
      })
    };
    return newdata;
  }
};
export const formatInnerProcedures = (
  innerPro?: [{ name: string; value: string }]
) => {
  const inner: { key: string; label: string; subOptions?: Option[] }[] = [];
  innerPro?.map((item) =>
    inner.push({
      key: item.name,
      label: item.name,
      subOptions: [{ value: item.value, label: item.value }]
    })
  );
  return inner;
};

export const formatProcedurestoForm = (
  procedure: [{ name: string; value?: [{ name: string; value: string }] }]
) => {
  const pro: {
    key: string;
    label: string;
    subOptions?: { key: string; label: string }[];
  }[] = [];
  procedure.forEach((item) =>
    pro.push({
      key: item.name,
      label: item.name,
      subOptions: formatInnerProcedures(item?.value) || []
    })
  );
  return pro;
};

export const formatProcedureInnerFieldToForm = (
  procedures: {
    key: string;
    label: string;
    subOptions?: { name: string; value: string }[];
  }[]
) => {
  const innerArr: { key: string; label: string; subOptions?: any }[] = [];
  procedures.forEach((item) =>
    innerArr.push({
      key: item.key,
      label: item.label,
      subOptions: [
        { value: item.subOptions?.[0].value, label: item.subOptions?.[0].value }
      ]
    })
  );
  return innerArr;
};

export const getTreatmentGoalsAndProcedures = (
  treatmentGoals?: {
    name: string;
    procedures: [{ name: string; value?: [{ name: string; value: string }] }];
  }[]
) => {
  const goals: string[] = [];
  const treatmentGoalsProcedure: {
    [x: string]: { key: string; label: string; subOptions?: any }[];
  } = {};
  const proceduresInnerFields: any = {};
  const upperLowerProcedures: {
    upper: string | undefined;
    lower: string | undefined;
  } = { upper: '', lower: '' };
  treatmentGoals?.forEach((goal) => {
    goals.push(goal.name);
    treatmentGoalsProcedure[goal.name] = formatProcedurestoForm(
      goal.procedures
    );
    treatmentGoalsProcedure[goal.name].forEach((item) => {
      item.subOptions
        ? (proceduresInnerFields[item.key] = formatProcedureInnerFieldToForm(
            item?.subOptions
          ))
        : proceduresInnerFields[item.key]?.push({});
      proceduresInnerFields[item.key].map(
        (innerItem: any) =>
          (upperLowerProcedures[innerItem.key as 'upper' | 'lower'] =
            innerItem.subOptions[0]?.value)
      );
    });
  });
  return {
    goals,
    treatmentGoalsProcedure,
    proceduresInnerFields,
    upperLowerProcedures
  };
};

export const formatToothChart3Values = (
  iprData: IprInfo[],
  selected: string[]
) => {
  const toothData: IprInfo[] = [];
  selected?.map((ipr) => {
    const selectedData = iprData?.find((item) => ipr === item?.position);
    if (selectedData)
      toothData?.push({
        value: selectedData?.value,
        position: selectedData?.position,
        setNumber: selectedData?.setNumber
      });
  });
  return toothData;
};

export const getRefinementFormData = (
  data?: RefinementFormType,
  isZenPlus = false,
  alignerAndIPRInfo?: AlignerIPRInfo,
  hasAlignerData = false
): RefinementTPVApiInput => {
  const alignerData = hasAlignerData ? data?.AlignerInput : data;
  return {
    type: isZenPlus ? TPVFormTypes.ZENPLUS : TPVFormTypes.ZENCLEAR,
    submissionReason:
      ZENCLEAR_TPV_REFINEMENT_FORM_REASON_BACKEND[
        data?.reasonForSubmission?.value
      ] || data?.reasonForSubmission?.value,
    refinementGoals: getRefinementGoals(data, isZenPlus),
    attachments: data?.attachments?.value,
    iprInfo: {
      name: data?.iprPerformed?.value,
      value:
        data?.iprPerformed?.value === REFINEMENT_IPR.NOT_AS_PRESCRIBED
          ? formatToothChart3Values(
              alignerAndIPRInfo?.iprInfo || [],
              data?.toothChart3 || []
            )
          : undefined
    },
    specialNotes: data?.specialNotes || '',
    translatedSpecialNotes: data?.translatedSpecialNotes || '',
    archesToTreat:
      data?.archesToTreat?.value === ARCHES_TO_TREAT.BOTH
        ? [ARCHES_TO_TREAT.LOWER, ARCHES_TO_TREAT.UPPER]
        : [data?.archesToTreat?.value],
    alignerInfo: [
      {
        name: 'upper',
        value: [
          {
            name: alignerData?.radioUpper,
            value:
              alignerData?.radioUpper === ALIGNER_INFO_TYPE.SET
                ? alignerData?.alignerInputUpperSet?.toString()
                : alignerData?.alignerInputUpperZenstage?.value
          }
        ]
      },
      {
        name: 'lower',
        value: [
          {
            name: alignerData?.radioLower,
            value:
              alignerData?.radioLower === ALIGNER_INFO_TYPE.SET
                ? alignerData?.alignerInputLowerSet?.toString()
                : alignerData?.alignerInputLowerZenstage?.value
          }
        ]
      }
    ],
    designId: data?.designId || undefined,
    iprInfoText: alignerAndIPRInfo?.iprInfo ? undefined : data?.iprInfoText,
    totalAlignerSet: `${alignerAndIPRInfo?.alignerInfo?.setAlignerCount}`,
    attachmentsOnlyCheck: !!data?.attachmentsOnlyCheck,
    ...(!!isZenPlus && {
      addButtonsForElasticsCheck: data?.addButtonsForElasticsCheck
    })
  };
};

export const getRefinementGoals = (
  data?: RefinementFormType,
  isZenPlus?: boolean
) => {
  const refinementGoals: any = [];
  data?.refinementGoals?.map((goal) => {
    switch (goal?.value) {
      case REFINEMENT_GOALS.ALIGNMENT:
        refinementGoals?.push({ name: REFINEMENT_GOALS.ALIGNMENT });
        break;
      case REFINEMENT_GOALS.CLOSE_RESIDUAL_SPACES:
        refinementGoals?.push({
          name: REFINEMENT_GOALS.CLOSE_RESIDUAL_SPACES,
          value: Object.keys(data?.toothChart2)?.map((item) => ({
            position: item,
            value: `${data?.toothChart2?.[item]}mm`
          }))
        });
        break;
      case REFINEMENT_GOALS.RESOLVE_ANTERIOR_CONTACTS:
        refinementGoals?.push({
          name: REFINEMENT_GOALS.RESOLVE_ANTERIOR_CONTACTS
        });
        break;
      case REFINEMENT_GOALS.RESOLVE_POSTERIOR_OPEN_BITE: {
        const value = [];
        if (data?.attachmentsOnlyCheck)
          value?.push({
            name: data?.attachmentsSelect?.value,
            value:
              data?.attachmentsSelect?.value ===
              RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.ATTACHMENTS_ONLY
                ? formatTeethToExtract(
                    TEETH_SELECT_OPTIONS.SELECT_TEETH,
                    data?.attachmentsOnly
                  )
                : undefined
          });
        if (data?.addButtonsForElasticsCheck && isZenPlus)
          value?.push({
            name: RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.ADD_BUTTON_FOR_ELASTICS,
            value: formatTeethToExtract(
              TEETH_SELECT_OPTIONS.SELECT_TEETH,
              data?.buttonsForElastics
            )
          });
        refinementGoals?.push({
          name: REFINEMENT_GOALS.RESOLVE_POSTERIOR_OPEN_BITE,
          value
        });
      }
    }
  });
  return refinementGoals;
};

export const getZenClearData = (data: ZenclearFormType): ZenclearApiInput => ({
  type: TPVFormTypes.ZENCLEAR,
  ...(!!data?.treatmentGoals && {
    treatmentGoals: formatTreatmentGoalsToApi(data?.treatmentGoals)
  }),
  archesToTreat:
    data?.archesToTreat === ARCHES_TO_TREAT.BOTH
      ? [ARCHES_TO_TREAT.LOWER, ARCHES_TO_TREAT.UPPER]
      : [data?.archesToTreat],
  toothMovementRestrictions: formatTeethToExtract(
    data?.toothMovementRestrictions,
    data?.teethNotToMove
  ),
  prosthesis: data?.prosthesis,
  prosthesisIprToAvoid:
    data?.prosthesis === TRUTH_VALUE.YES
      ? formatTeeth(data?.prosthesisIprToAvoid)
      : undefined,
  prosthesisAttachmentToAvoid:
    data?.prosthesis === TRUTH_VALUE.YES
      ? formatTeeth(data?.prosthesisAttachmentToAvoid)
      : undefined,
  teethToExtract: formatTeethToExtract(
    data?.extractTeeth,
    data?.teethToExtract
  ),
  specialNotes: data?.specialNotes || '',
  translatedSpecialNotes: data?.translatedSpecialNotes || ''
});
export const formatSubOptions = (
  innerFields?: { key: string; label: string; subOptions: any }[],
  upperLowerProcedures?: { [x: string]: string }
) => {
  const innerArray: { name: string; value: string | undefined }[] = [];
  innerFields?.map((item) =>
    innerArray.push({ name: item.key, value: upperLowerProcedures?.[item.key] })
  );
  return innerArray;
};
export const formatProcedures = (
  goal: string,
  procedures?: {
    [x: string]: { key: string; label: string; subOptions?: any }[];
  },
  proceduresInnerFields?: {
    [x: string]: { key: string; label: string; subOptions: any }[];
  },
  upperLowerProcedures?: { [x: string]: string }
) => {
  const proArray: any = [];
  procedures?.[goal].map(
    (proc: { key: string; label: string; subOptions?: any[] }) =>
      proArray.push({
        name: proc.key,
        ...(proc.key === TREATMENT_GOALS_PROCEDURES.DISTALIZATION && {
          value: formatSubOptions(
            proceduresInnerFields?.[proc.key],
            upperLowerProcedures
          )
        })
      })
  );
  return proArray;
};

export const formatTreatmentGoalsToApi = (
  goals?: string[] | string,
  procedures?: {
    [x: string]: { key: string; label: string; subOptions: any }[];
  },
  proceduresInnerFields?: {
    [x: string]: { key: string; label: string; subOptions: any }[];
  },
  upperLowerProcedures?: { [x: string]: string }
) => {
  const finalGoals: {
    name: string;
    procedures: { name: string; value: { name: string; value: string }[] };
  }[] = [];
  if (typeof goals !== 'string')
    goals?.map((item) =>
      finalGoals.push({
        name: item,
        procedures: formatProcedures(
          item,
          procedures,
          proceduresInnerFields,
          upperLowerProcedures
        )
      })
    );
  return finalGoals;
};
export const getZenPlusData = (data: ZenPlusFormType): ZenPlusApiInput => {
  return {
    type: TPVFormTypes.ZENPLUS,
    ...(!!data?.treatmentGoals && {
      treatmentGoals: formatTreatmentGoalsToApi(
        data?.treatmentGoals,
        data.treatmentGoalsProcedures,
        data.proceduresInnerFields,
        data.upperLowerProcedures
      )
    }),
    archesToTreat:
      data?.archesToTreat === ARCHES_TO_TREAT.BOTH
        ? [ARCHES_TO_TREAT.LOWER, ARCHES_TO_TREAT.UPPER]
        : [data?.archesToTreat],
    toothMovementRestrictions: formatTeethToExtract(
      data?.toothMovementRestrictions,
      data?.teethNotToMove
    ),
    prosthesis: data?.prosthesis,
    prosthesisIprToAvoid:
      data?.prosthesis === TRUTH_VALUE.YES
        ? formatTeeth(data?.prosthesisIprToAvoid)
        : undefined,
    prosthesisAttachmentToAvoid:
      data?.prosthesis === TRUTH_VALUE.YES
        ? formatTeeth(data?.prosthesisAttachmentToAvoid)
        : undefined,
    overbite: data?.overbite,
    midlines: data?.midlines,
    midlinesShifts:
      data?.midlines == MIDLINES.IMPROVE
        ? getMidlineShifts({
            shiftUpper: data?.shiftUpper,
            shiftLower: data?.shiftLower,
            shiftUpperPosition: data?.shiftUpperPosition,
            shiftLowerPosition: data?.shiftLowerPosition
          })
        : undefined,
    teethToExtract: formatTeethToExtract(
      data?.extractTeeth,
      data?.teethToExtract
    ),
    specialNotes: data?.specialNotes || '',
    translatedSpecialNotes: data?.translatedSpecialNotes || ''
  };
};

const getMidlineShifts = ({
  shiftUpper,
  shiftLower,
  shiftUpperPosition,
  shiftLowerPosition
}: {
  shiftUpper?: boolean;
  shiftLower?: boolean;
  shiftUpperPosition?: string;
  shiftLowerPosition?: string;
}): string[] => {
  const midlinesShifts = [];
  if (shiftUpper && shiftUpperPosition) midlinesShifts.push(shiftUpperPosition);
  if (shiftLower && shiftLowerPosition) midlinesShifts.push(shiftLowerPosition);
  return midlinesShifts;
};

export const formatMidlinesShiftForForm = (shifts?: string[]) => {
  const shiftLower = shifts?.find(
    (shift) =>
      shift?.includes(MIDLINES_SHIFT_SUB.LOWER_LEFT) ||
      shift?.includes(MIDLINES_SHIFT_SUB.LOWER_RIGHT)
  );
  const shiftUpper = shifts?.find(
    (shift) =>
      shift?.includes(MIDLINES_SHIFT_SUB.UPPER_LEFT) ||
      shift?.includes(MIDLINES_SHIFT_SUB.UPPER_RIGHT)
  );
  return { shiftLower, shiftUpper };
};

export const formatTeeth = (teeths?: TeethExtraction): string => {
  if (teeths) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const teethsArray = Object.entries(teeths).reduce((prev, [_, value]) => {
      value?.length && prev.push(value?.map(({ value }) => value).toString());
      return prev;
    }, [] as string[]);
    return teethsArray.toString();
  }
  return '';
};

export const getTreatmentPlansInitialTPV = (plans: TreatmentPlanType[]) => {
  const treatmentPlan = plans[0];
  if (treatmentPlan) {
    const versionGroup: VersionGroup = {
      ZENCLEAR: [],
      ZENCLEAR_PLUS: []
    };
    const { versions } = treatmentPlan;
    versions?.forEach((version) => {
      if (version.type === TREATMENT_PLAN_TYPE.ZENCLEAR) {
        versionGroup.ZENCLEAR.push(version);
      } else if (version.type === TREATMENT_PLAN_TYPE.ZENCLEAR_PLUS) {
        versionGroup.ZENCLEAR_PLUS.push(version);
      }
    });
    return {
      zenplusTPV: getInitialTPV(versionGroup.ZENCLEAR_PLUS || []),
      zenclearTPV: getInitialTPV(versionGroup.ZENCLEAR || []),
      zenPlusTPId: treatmentPlan?.id,
      zenClearTPId: treatmentPlan?.id
    };
  }
};

const getInitialTPV = (versions: VersionDetails[]) =>
  versions?.find((version) => version?.acceptanceStatus !== 'DRAFT') ||
  versions?.find((version) => version?.acceptanceStatus === 'DRAFT');

export const onchangeCheckboxFields = (
  e: ChangeEvent<HTMLInputElement>,
  selectedOptions: any,
  field: ControllerRenderProps<FieldValues, any>
) => {
  const values = field.value;
  const checked = e.target.checked;
  const newValues = checked
    ? [...(values || []), selectedOptions]
    : values.filter((v: any) => v.key !== selectedOptions.key);
  return field.onChange(newValues);
};

export const getSelectedOption = (
  selectedValue: string,
  optionArray: Option[]
) => {
  return optionArray.find(({ value }) => value == selectedValue);
};

export const getTabLabels = (isRefinement: boolean) => {
  return isRefinement
    ? {
        zenclear: 'ZenClear.Refinement.Prescription',
        zenplus: 'ZenPlus.Refinement.Prescription'
      }
    : {
        zenclear: 'ZenClear.Prescription',
        zenplus: 'ZenPlus.Prescription'
      };
};
const getArchesToTreatForTreatmentSpec = (archesToTreatInput?: string) => {
  if (archesToTreatInput) {
    return archesToTreatInput === ARCHES_TO_TREAT.BOTH
      ? [ARCHES_TO_TREAT.LOWER, ARCHES_TO_TREAT.UPPER]
      : [archesToTreatInput];
  }
  return undefined;
};
const isAnyToothSelected = (
  prosthesis: string,
  tooths?: TeethExtraction,
  iprOrAttachment?: Option[]
) => {
  const selectedProsthesisOption = iprOrAttachment?.map((item: Option) => {
    return item?.value;
  });
  const isAnyToothsSelected =
    tooths?.leftBottom.length ||
    tooths?.rightBottom.length ||
    tooths?.leftTop.length ||
    tooths?.rightTop.length ||
    0;

  return selectedProsthesisOption?.includes(prosthesis) && isAnyToothsSelected;
};
export const getZenClearDataForTreatmentSpec = (
  isTreatmentTypeChanged: boolean,
  ClinicalPreferencesData: TreatmentPLanPreferenceFormType,
  data?: ZenClearType
): ZenclearApiInput => ({
  type: TPVFormTypes.ZENCLEAR,
  treatmentGoals: formatTreatmentGoalsToApi(
    data?.treatmentGoals?.treatmentGoals
  ),
  archesToTreat: getArchesToTreatForTreatmentSpec(data?.archesToTreat),
  toothMovementRestrictions: formatTeethToExtract(
    data?.toothMovementRestrictions?.toothMovementRestrictions,
    data?.toothMovementRestrictions?.teethNotToMove
  ),
  prosthesisOptions: data?.prosthesis?.iprOrAttachment?.map((item: Option) => {
    return item?.value;
  }),
  prosthesis: data?.prosthesis?.prosthesis,
  prosthesisIprToAvoid:
    data?.prosthesis?.prosthesis === TRUTH_VALUE.YES &&
    isAnyToothSelected(
      'IPR to avoid',
      data?.prosthesis?.prosthesisIprToAvoid,
      data?.prosthesis?.iprOrAttachment
    )
      ? formatTeeth(data?.prosthesis?.prosthesisIprToAvoid)
      : undefined,
  prosthesisAttachmentToAvoid:
    data?.prosthesis?.prosthesis === TRUTH_VALUE.YES &&
    isAnyToothSelected(
      'Attachment to avoid',
      data?.prosthesis?.prosthesisAttachmentToAvoid,
      data?.prosthesis?.iprOrAttachment
    )
      ? formatTeeth(data?.prosthesis?.prosthesisAttachmentToAvoid)
      : undefined,
  teethToExtract: formatTeethToExtract(
    data?.extractTeeth?.extractTeeth,
    data?.extractTeeth?.teethToExtract
  ),
  specialNotes: data?.specialNotes || '',
  translatedSpecialNotes: data?.translatedSpecialNotes || '',
  //here clinical preference is sent an empty object when the treatmentType is changed.This is only for SavePrescription.
  clinicalPreferences: isTreatmentTypeChanged
    ? {}
    : formatClinicalPreferences(
        ClinicalPreferencesData,
        true,
        false,
        true,
        false,
        false
      )
});
export const getZenPlusDataForTreatmentSpec = (
  isTreatmentTypeChanged: boolean,
  ClinicalPreferencesData: TreatmentPLanPreferenceFormType,
  data?: ZenPlusType
): ZenPlusApiInput => {
  return {
    type: TPVFormTypes.ZENPLUS,
    ...(!!data?.treatmentGoals && {
      treatmentGoals: formatTreatmentGoalsToApi(
        data?.treatmentGoals?.treatmentGoals,
        data?.treatmentGoals?.treatmentGoalsProcedures,
        data?.treatmentGoals?.proceduresInnerFields,
        data?.treatmentGoals?.upperLowerProcedures
      )
    }),
    archesToTreat: getArchesToTreatForTreatmentSpec(data?.archesToTreat),
    toothMovementRestrictions: formatTeethToExtract(
      data?.toothMovementRestrictions?.toothMovementRestrictions,
      data?.toothMovementRestrictions?.teethNotToMove
    ),
    prosthesis: data?.prosthesis?.prosthesis,
    prosthesisOptions: data?.prosthesis?.iprOrAttachment?.map(
      (item: Option) => {
        return item?.value;
      }
    ),
    prosthesisIprToAvoid:
      data?.prosthesis?.prosthesis === TRUTH_VALUE.YES &&
      isAnyToothSelected(
        'IPR to avoid',
        data?.prosthesis?.prosthesisIprToAvoid,
        data?.prosthesis?.iprOrAttachment
      )
        ? formatTeeth(data?.prosthesis?.prosthesisIprToAvoid)
        : undefined,
    prosthesisAttachmentToAvoid:
      data?.prosthesis?.prosthesis === TRUTH_VALUE.YES &&
      isAnyToothSelected(
        'Attachment to avoid',
        data?.prosthesis?.prosthesisAttachmentToAvoid,
        data?.prosthesis?.iprOrAttachment
      )
        ? formatTeeth(data?.prosthesis?.prosthesisAttachmentToAvoid)
        : undefined,
    overbite: data?.overbite,
    midlines: data?.midlines?.midlines,
    midlinesShifts:
      data?.midlines?.midlines == MIDLINES.IMPROVE
        ? getMidlineShifts({
            shiftUpper: data?.midlines?.shiftUpper,
            shiftLower: data?.midlines?.shiftLower,
            shiftUpperPosition: data?.midlines?.shiftUpperPosition,
            shiftLowerPosition: data?.midlines?.shiftLowerPosition
          })
        : undefined,
    teethToExtract: formatTeethToExtract(
      data?.extractTeeth?.extractTeeth,
      data?.extractTeeth?.teethToExtract
    ),
    specialNotes: data?.specialNotes || '',
    translatedSpecialNotes: data?.translatedSpecialNotes || '',
    clinicalPreferences: isTreatmentTypeChanged
      ? {}
      : formatClinicalPreferences(
          ClinicalPreferencesData,
          true,
          true,
          false,
          false,
          false
        )
  };
};
export const isValuePresentInOptions = (options: Option[], value: string) => {
  return !!options?.some((obj: Option) => obj.value === value);
};

export const getAlignerInfo = (
  alignerInfo: TPVFormField[],
  alignerAndIPRInfo?: AlignerIPRInfo
) => {
  const alignerUpperData = getAlignerData(alignerInfo || [], 'upper');
  const alignerLowerData = getAlignerData(alignerInfo || [], 'lower');
  return {
    radioUpper: alignerUpperData?.radioValue || ALIGNER_INFO_TYPE.SET,
    radioLower: alignerLowerData?.radioValue || ALIGNER_INFO_TYPE.SET,
    alignerInputUpperSet:
      alignerUpperData?.radioValue === ALIGNER_INFO_TYPE.SET &&
      alignerUpperData?.inputValue
        ? alignerUpperData?.inputValue?.split('/')?.[0]
        : getSetAndZenStageDefaultValues(
            alignerAndIPRInfo?.alignerInfo?.currentAligner,
            alignerAndIPRInfo?.alignerInfo?.setAlignerCount
          ).set,
    alignerInputLowerSet:
      alignerLowerData?.radioValue === ALIGNER_INFO_TYPE.SET &&
      alignerLowerData?.inputValue
        ? alignerLowerData?.inputValue?.split('/')?.[0]
        : getSetAndZenStageDefaultValues(
            alignerAndIPRInfo?.alignerInfo?.currentAligner,
            alignerAndIPRInfo?.alignerInfo?.setAlignerCount
          ).set,
    alignerInputUpperZenstage: ZENSTAGE_OPTIONS?.find(
      (option) =>
        option?.value ===
        (alignerUpperData?.radioValue === ALIGNER_INFO_TYPE.ZEN_STAGE
          ? alignerUpperData?.inputValue
          : `${
              getSetAndZenStageDefaultValues(
                alignerAndIPRInfo?.alignerInfo?.currentAligner,
                alignerAndIPRInfo?.alignerInfo?.setAlignerCount
              ).zenstage
            }`)
    ),
    alignerInputLowerZenstage: ZENSTAGE_OPTIONS?.find(
      (option) =>
        option?.value ===
        (alignerLowerData?.radioValue === ALIGNER_INFO_TYPE.ZEN_STAGE
          ? alignerLowerData?.inputValue
          : `${
              getSetAndZenStageDefaultValues(
                alignerAndIPRInfo?.alignerInfo?.currentAligner,
                alignerAndIPRInfo?.alignerInfo?.setAlignerCount
              ).zenstage
            }`)
    )
  };
};
