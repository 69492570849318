import { useRef, FC } from 'react';

import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';

import FileCard from 'components/Files/FileCard/FileCard';
import { If } from 'components/Generics';
import Slider from 'components/Slider/Slider';
import { ALLOWED_EXTENSIONS } from 'constants/common';
import { FilesData } from 'types/Files.types';
import { translate } from 'utils';

import AddFileButton from './AddFileButton/AddFileButton';

type FilesProp = {
  files: FilesData[];
  onDelete?: (id: string | number, localId?: string | number) => void;
  deleting?: boolean;
  className?: string;
  viewOnly?: boolean;
  width?: string;
  height?: string;
  noName?: boolean;
  enableSlider?: boolean;
  setSelectedPhoto?: React.Dispatch<React.SetStateAction<string | number>>;
  toggleZoomPhoto?: React.Dispatch<React.SetStateAction<boolean>>;
  isFromNotesUpload?: boolean;
  showOnlyAddButton?: boolean;
  onDropAccepted?: <T extends File>(files: T[], event?: DropEvent) => void;
  onDropRejected?: (fileRejections: FileRejection[], event?: DropEvent) => void;
};

const Files: FC<FilesProp> = ({
  files = [],
  onDelete,
  deleting,
  className,
  viewOnly,
  width = 'w-28.5 md:w-44.5',
  height,
  noName = false,
  enableSlider = false,
  setSelectedPhoto,
  toggleZoomPhoto,
  isFromNotesUpload = false,
  showOnlyAddButton = false,
  onDropAccepted,
  onDropRejected
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const fileExtensionValidator = (file: File) => {
    const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
    if (ALLOWED_EXTENSIONS.includes(fileExtension)) return null;
    return {
      code: 'invalid-file',
      message: translate('file.upload.not_a_valid_file', {
        X: file.name
      })
    };
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noKeyboard: true,
    noClick: true,
    accept: '',
    maxFiles: 0,
    onDropAccepted,
    onDropRejected,
    minSize: 1,
    validator: fileExtensionValidator
  });

  return (
    <Slider childRef={containerRef} disable={!enableSlider}>
      <div
        className={`flex  overflow-auto items-center ${className} ${
          viewOnly
            ? 'xxs:flex-wrap md:flex-nowrap xxs:mt-2 md:gap-x-0 md:gap-y-0 gap-x-4 gap-y-4'
            : ''
        }`}
        ref={containerRef}
      >
        {files?.map((details) => (
          <button
            type='button'
            key={details.id}
            onClick={() => {
              if (
                details.fileType === 'img' &&
                (details.uploadProgress ? details.uploadProgress === 100 : true)
              ) {
                toggleZoomPhoto?.(true);
                setSelectedPhoto?.(details.id || details.fileId || '');
              }
            }}
          >
            <FileCard
              name={details.file?.name || details.fileName || ''}
              {...details}
              key={details.id}
              className='flex-shrink-0  xxs:mr-2 md:mr-3 md:p-3 md:bg-CHINESE_WHITE md:rounded-xl my-2'
              width={width}
              onDelete={onDelete}
              deleting={deleting}
              viewOnly={viewOnly}
              classification={details.classification}
              height={height}
              noName={noName}
            />
          </button>
        ))}
        {/* for aligning the add button with the files in notes */}
        <If condition={isFromNotesUpload && showOnlyAddButton}>
          <div
            {...getRootProps()}
            className='self-start md:w-44.5 h-28.5 md:h-56 w-full rounded'
          >
            <input data-testid='file-upload-input' {...getInputProps()} />
            <AddFileButton onClick={open} height='md:min-h-[14rem]' />
          </div>
        </If>
      </div>
    </Slider>
  );
};

export default Files;
