import { createContext, useContext } from 'react';

import { PatientFilesMobile } from 'constants/treatmentFiles';
import { TreatmentFileMobileAction } from 'types/treatmentFiles.types';

export const MobileFileContext = createContext<contextType | null>(null);

export type contextType = {
  state: PatientFilesMobile;
  dispatch: React.Dispatch<TreatmentFileMobileAction>;
};

export const useMobileFileContext = () => {
  const data = useContext(MobileFileContext);
  if (!data) {
    throw new Error(
      'useMobileFileContext has to be used within <MobileFileContext.Provider>'
    );
  }
  return data;
};
