import { FC } from 'react';

import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

import { FormLabel } from 'components/FormLabel/FormLabel';
import { If } from 'components/Generics';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import { InfoCardView } from 'components/InfoCardView/InfoCardView';
import InfoToolTip from 'components/InfoToolTip';
import { OVERBITES, OVERBITE_OPTIONS } from 'constants/TPVFormOptions';
import { translate } from 'utils';
import { getSelectedOption } from 'utils/tpvUtils';

type Props = {
  disabled?: boolean;
  applyDisableStyles?: boolean;
  isNewTemplatizedPrescription?: boolean;
  name?: string;
  source?: string;
};

export const OverbiteInput: FC<Props> = ({
  disabled = false,
  applyDisableStyles = false,
  isNewTemplatizedPrescription = false,
  name = 'overbite',
  source
}) => {
  const { control, watch } = useFormContext();
  const selectedOverbite = watch(name);
  const isPrescriptionSummary = source === 'PrescriptionSummary';

  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState: { error } }) => (
          <>
            <div className={classNames('flex flex-wrap gap-3 mb-3')}>
              <FormLabel
                label={translate('4.Overjet')}
                disabled={applyDisableStyles}
                error={!!error}
                name={name}
                className='md:text-17px'
              />
              <InfoToolTip
                message={translate('overbite.message')}
                className='w-5 h-5 text-PRIMARY'
                toolTipStyle='w-45 md:w-full'
              />
            </div>
            <div
              className={classNames('md:min-w-[325px] md:w-max h-48px', {
                'md:pr-10': isPrescriptionSummary
              })}
            >
              <RhfSelect
                name={name}
                options={OVERBITE_OPTIONS}
                onChange={(data) => {
                  field?.onChange(data.value);
                }}
                isDisabled={disabled}
                inputProps={{
                  controlShouldRenderValue: true,
                  selectedValue: getSelectedOption(
                    selectedOverbite,
                    OVERBITE_OPTIONS
                  )
                }}
              />
            </div>
            <If condition={!isNewTemplatizedPrescription && selectedOverbite}>
              <If condition={selectedOverbite === OVERBITES.CORRECT_DEEP_BITE}>
                <div className='mt-4'>
                  <InfoCardView
                    message={translate('Maximum.2.0mm.intrusion')}
                  />
                </div>
              </If>
              <If condition={selectedOverbite === OVERBITES.CORRECT_OPEN_BITE}>
                <div className='mt-4'>
                  <InfoCardView
                    message={translate('Maximum.2.0mm.extrusion')}
                  />
                </div>
              </If>
            </If>
          </>
        )}
      />
    </div>
  );
};
