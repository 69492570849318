import { ERROR_CODE_MAP } from 'constants/translationMaps';
import {
  useAddLeave,
  useAddLeaveForMyself,
  useCancelLeave,
  useGetLeaves
} from 'services/hooks/leave.hook';
import { GET_LOGINED_USER_DETAILS } from 'services/query/auth';
import { GET_LEAVES } from 'services/query/leave';
import { GET_STAFF_DETAILS } from 'services/query/userslist';
import { AddLeaveInput, LeaveInfo } from 'types/leave';
import { translate } from 'utils/locale';
import { showToast } from 'utils/toast/toast.util';

interface Props {
  isLoginedUser?: boolean;
  toggleAddLeave?: () => void;
  setSelectedLeave?: (leaveinfo: LeaveInfo | null) => void;
}

const useLeaveHooks = ({
  isLoginedUser = false,
  toggleAddLeave,
  setSelectedLeave
}: Props) => {
  const [addLeaveForOthers] = useAddLeave({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      showToast(translate('leave.leave_added'), true);
      toggleAddLeave?.();
    },
    refetchQueries: [GET_LEAVES, GET_STAFF_DETAILS]
  });

  const [addLeaveForMyself] = useAddLeaveForMyself({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      toggleAddLeave?.();
      showToast(translate('leave.leave_added'), true);
    },
    refetchQueries: [GET_LEAVES, GET_LOGINED_USER_DETAILS]
  });

  const [deleteLeave] = useCancelLeave({
    fetchPolicy: 'no-cache',
    onCompleted: () => {
      showToast(translate('leave.leave_cancelled'), true);
      setSelectedLeave?.(null);
    },
    onError: (error) => {
      const code = error?.graphQLErrors?.[0]?.extensions?.code as string;
      let errorMessage = error.message;
      if (code && ERROR_CODE_MAP[code]) {
        errorMessage = translate(ERROR_CODE_MAP[code]);
      }
      showToast(errorMessage, false);
      setSelectedLeave?.(null);
    },
    refetchQueries: isLoginedUser
      ? [GET_LEAVES, GET_STAFF_DETAILS, GET_LOGINED_USER_DETAILS]
      : [GET_LEAVES, GET_STAFF_DETAILS]
  });

  const addLeave = (input: AddLeaveInput, userId?: string) => {
    if (isLoginedUser) {
      addLeaveForMyself({
        variables: {
          input
        }
      });
    } else {
      addLeaveForOthers({
        variables: {
          input,
          userId
        }
      });
    }
  };

  const cancelLeave = (id: string) => {
    deleteLeave({
      variables: {
        id
      }
    });
  };
  return { addLeave, cancelLeave };
};

const useGetAllLeaves = (userId: string) => {
  const { data: leaveData, loading: isLoading } = useGetLeaves({
    variables: { filter: { userId } },
    fetchPolicy: 'network-only'
  });

  return { leaveData, isLoading };
};

export { useLeaveHooks, useGetAllLeaves };
