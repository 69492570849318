import { FC, Fragment, ReactElement } from 'react';

import cx from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { HelpIcon, LogoIcon, LogoutIcon, ReportIssueIcon } from 'assets/icons';
import { If } from 'components/Generics';
import {
  REACT_APP_HELP_CENTER_CLINIC_URL,
  REACT_APP_HELP_CENTER_URL
} from 'config';
import { pagePaths } from 'config/pages';
import { CLINIC_CLOUD, ZENBASE } from 'constants/common';
import {
  TRACK_EVENTS,
  APPOINTMENT_TRACK_EVENTS,
  SEGMENT_REFERRAL_TRACK
} from 'constants/segment';
import { GlobalClinicSelector } from 'containers/GlobalClinicSelector/GlobalClinicSelector';
import { CanAccess } from 'hoc/can-access/CanAccess';
import { useSegmentTrackReferral } from 'services/hooks/Segment.hooks';
import { globalSelectedClinic, loginedUserDetails } from 'store/atoms/authAtom';
import { NavItem } from 'types/Navbar';
import {
  useLogout,
  translate,
  checkIfAppIsAdminClinic,
  segmentTrackEvent
} from 'utils';
import { checkIsExternal } from 'utils/common';
import useNavBarConfig from 'utils/customHooks/navbarConfig.customHooks';

import { settingsNav } from './Navbar.config';
import packageJson from '../../../package.json';

import './Navbar.css';

interface NavbarProps {
  setIsOpen(type: boolean): void;
  isOpen: boolean;
}

const Navbar: FC<NavbarProps> = ({ setIsOpen, isOpen }) => {
  const location = useLocation();
  const logout = useLogout();
  const { getNavBarItems } = useNavBarConfig();
  const selectedClinic = useRecoilValue(globalSelectedClinic);
  const userDetails = useRecoilValue(loginedUserDetails);
  const trackReferralSegment = useSegmentTrackReferral();

  const handleOnClick = (navItem: NavItem) => {
    const view = checkIfAppIsAdminClinic() ? ZENBASE : CLINIC_CLOUD;
    switch (navItem.path) {
      case pagePaths.appointmentList:
        segmentTrackEvent(APPOINTMENT_TRACK_EVENTS.APPOINTMENT_TAB_CLICKED, {
          view
        });
        break;
      case pagePaths.caseLibrary:
        window.analytics.track(TRACK_EVENTS.CASE_LIBRARY_CLICKED);
        break;
      case pagePaths.referrals:
        trackReferralSegment({ title: SEGMENT_REFERRAL_TRACK.TAB_CLICKED });
        break;
      default:
        break;
    }
  };

  const onClickHelpCenterTab = () => {
    window.analytics.track(TRACK_EVENTS.HELP_CENTER_CLICKED);
  };

  const renderNavItem = (
    navItem: NavItem,
    key: string | number
  ): ReactElement => (
    <CanAccess
      key={key}
      permission={navItem?.permission}
      yes={
        <Fragment>
          <Link
            to={navItem.path}
            className={cx(
              `flex justify-between p-2 mb-2 rounded-lg text-lg hover:bg-PRIMARY hover:text-white cursor-pointer items-center break-normal navBar ${
                navItem.customClass ? navItem.customClass : ''
              }`,
              {
                'bg-PRIMARY text-white navBar-selected':
                  location.pathname.includes(navItem.path)
              }
            )}
          >
            <button
              id={navItem?.testId}
              className='w-full'
              onClick={() => handleOnClick(navItem)}
            >
              {typeof navItem.title === 'string' ? (
                <div className='flex items-center'>
                  {navItem.icon && <navItem.icon className='mr-4 w-5 h-5' />}
                  <span className='md:text-17px xxs:text-sm text-left'>
                    {translate(navItem.title)}
                  </span>
                </div>
              ) : (
                <>{navItem.title}</>
              )}
            </button>
          </Link>
        </Fragment>
      }
    />
  );

  /**
   * @description
   * Function to generate help center URL based on the login
   * @returns string help center url
   */
  const getHelpCenterUrl = () => {
    if (checkIfAppIsAdminClinic()) {
      return REACT_APP_HELP_CENTER_URL;
    }
    return REACT_APP_HELP_CENTER_CLINIC_URL;
  };

  return (
    <>
      <div
        className={cx(
          'absolute md:static md:z-5 inset-0 transform ease-in-out h-screen overflow-hidden z-50 md:border-r-1 md:border-CORE_GRAY',
          {
            'duration-1000 translate-x-0 h-full w-60 ': isOpen,
            'transition-all duration-1000 -translate-x-full  md:w-0  ': !isOpen
          }
        )}
      >
        <div className='flex h-full '>
          <div className='flex flex-col justify-between bg-GRAY5 text-DEFAULT_TEXT w-264px md:min-w-60 md:max-w-max md:mt-14'>
            <div className='h-full flex flex-col'>
              <div>
                <div className='ml-6 mt-18 md:hidden  '>
                  <LogoIcon className='h-6 w-144px ' data-testid='logo' />
                </div>
                <If
                  condition={
                    !!userDetails?.id &&
                    !!selectedClinic &&
                    checkIsExternal(userDetails?.groups) &&
                    userDetails?.headClinics?.length > 1
                  }
                >
                  <div className='md:hidden px-6 mt-6'>
                    <GlobalClinicSelector />
                  </div>
                </If>
                <div className='mt-12 px-4 xxs:hidden md:block'>
                  <Link className='text-2xl font-bold' to={pagePaths.dashboard}>
                    {checkIfAppIsAdminClinic()
                      ? translate('zenbase')
                      : translate('navigation.clinic_cloud')}
                  </Link>
                </div>
              </div>
              <div className='mt-4 md:px-4 xxs:px-6 h-full flex flex-col'>
                {getNavBarItems().map((navItem, index) => {
                  if (!navItem.hidden) return renderNavItem(navItem, index);
                })}
                <div className='flex-1 border-b my-4 border-CORE_GRAY' />
                {renderNavItem(settingsNav, settingsNav.title)}
                <div>
                  <a
                    role='button'
                    onClick={onClickHelpCenterTab}
                    href={getHelpCenterUrl()}
                    className={cx(
                      `flex justify-between mb-2 p-2 rounded-lg text-lg hover:bg-PRIMARY hover:text-white cursor-pointer items-center break-normal `
                    )}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='flex items-center'>
                      <HelpIcon className='mr-4 w-5 h-5' />
                      <span className='md:text-17px xxs:text-sm whitespace-nowrap'>
                        {translate('help_centre_button.help_centre')}
                      </span>
                    </div>
                  </a>
                  <a
                    href='https://forms.clickup.com/25534194/f/rb7qj-66105/GM7E4T33R0ZNN7M5GZ'
                    className={cx(
                      `flex justify-between md:hidden mb-2 p-2 rounded-lg text-lg hover:bg-PRIMARY hover:text-white cursor-pointer items-center break-normal `
                    )}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <div className='flex items-center'>
                      <ReportIssueIcon className='mr-4 w-5 h-5' />
                      <span className='md:text-17px xxs:text-sm whitespace-nowrap'>
                        {translate('button.report_an_issue')}
                      </span>
                    </div>
                  </a>
                  <button
                    className={cx(
                      `flex justify-between mb-2 p-2 rounded-lg text-lg hover:bg-PRIMARY hover:text-white cursor-pointer items-center break-normal w-full`
                    )}
                    onClick={logout}
                  >
                    <div className='flex items-center' id='log-out'>
                      <LogoutIcon className='mr-4 w-5 h-5' />
                      <span className='md:text-17px xxs:text-sm whitespace-nowrap'>
                        {translate('settings_button.log_out')}
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </div>
            <div className=' text-center mb-8 mt-12 '>
              <span className='inline-block align-middle text-DISABLED text-sm'>
                {translate('navigation.zenyum_pte_ltd_version_1.0', {
                  X: packageJson.version
                })}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        className={cx('flex-1 bg-LOADER_BG fixed inset-0 z-31 md:hidden', {
          hidden: !isOpen
        })}
        data-testid='overlay-button'
        role='button'
        tabIndex={-1}
        onKeyDown={() => setIsOpen(false)}
        onClick={() => {
          setIsOpen(false);
        }}
      ></div>
    </>
  );
};

export default Navbar;
