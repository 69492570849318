import { ChangeEvent, FC } from 'react';

import classnames from 'classnames';

import { customTwMerge } from 'utils';

interface Props {
  id?: string;
  name?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  readOnly?: boolean;
  className?: string;
  containerClassName?: string;
  disabled?: boolean;
}

const Checkbox: FC<Props> = ({
  id,
  name,
  value,
  onChange,
  readOnly,
  checked,
  className = '',
  containerClassName = '',
  disabled = false
}) => {
  return (
    <div className={containerClassName}>
      <input
        id={id}
        type='checkbox'
        name={name}
        data-testid='checkbox'
        value={value}
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        className={classnames(
          customTwMerge(
            'form-checkbox text-PRIMARY focus:ring-transparent w-5 h-5 rounded border-LIGHT_GRAY_3 px-2 mr-2 ',
            className
          ),
          {
            'bg-LIGHT_GRAY': disabled
          }
        )}
        disabled={disabled}
      />
    </div>
  );
};

export default Checkbox;
