import { FC, ReactNode } from 'react';

import TicketCardSkeleton from './TicketCardSkeleton';

interface Props {
  loading: boolean;
  children?: ReactNode;
}

const TicketCardListSkeleton: FC<Props> = ({ loading, children }) => {
  if (!loading) return <>{children}</>;

  return (
    <div className='w-full'>
      <TicketCardSkeleton />
      <TicketCardSkeleton />
      <TicketCardSkeleton />
    </div>
  );
};

export default TicketCardListSkeleton;
