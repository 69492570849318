import { ChangeEvent, FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { Option } from 'types/select';

import CheckboxGroup from '../CheckboxGroup';

export type RhfCheckboxProp = {
  name: string;
  options: Option[];
  title?: string;
  showErrorTitle?: boolean;
  className?: string;
  checkBoxClassName?: string;
  checkBoxItemFont?: string;
  labelClassName?: string;
  checkBoxContainerClassName?: string;
  disabled?: boolean;
  onChange?: (
    selected: Array<Option>,
    name?: string,
    event?: ChangeEvent<HTMLInputElement>
  ) => void;
  gridTemplateColumnsWidth?: string;
};

const RhfCheckboxGroup: FC<RhfCheckboxProp> = ({
  options,
  name,
  title,
  showErrorTitle = true,
  className = '',
  checkBoxClassName = '',
  checkBoxItemFont,
  labelClassName,
  checkBoxContainerClassName = '',
  disabled,
  onChange,
  gridTemplateColumnsWidth
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <CheckboxGroup
          gridTemplateColumnsWidth={gridTemplateColumnsWidth}
          title={title}
          onChangehandle={onChange || field.onChange}
          options={options}
          showErrorTitle={showErrorTitle && !!error?.message}
          name={name}
          selectedItems={field.value}
          className={className}
          checkBoxClassName={checkBoxClassName}
          checkBoxContainerClassName={checkBoxContainerClassName}
          disabled={disabled}
          checkBoxItemFont={checkBoxItemFont}
          labelClassName={labelClassName}
        />
      )}
    />
  );
};

export default RhfCheckboxGroup;
