import React, { FC, ReactNode, useEffect, useState } from 'react';

import { SliderArrow } from 'assets/icons';

interface Props {
  children: ReactNode;
  childRef?: React.RefObject<HTMLDivElement>;
  disable?: boolean;
  rightOffset?: number;
}

const SCROLL_OFFSET = 150;

const Slider: FC<Props> = ({
  children,
  childRef,
  disable = false,
  rightOffset = 0
}) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollRight, setScrollRight] = useState(0);

  const updateScrollPosition = () => {
    if (childRef?.current) {
      const { scrollWidth, clientWidth, scrollLeft } = childRef?.current || {};
      if (scrollLeft >= 0) {
        setScrollLeft(childRef?.current?.scrollLeft);
      }

      if (scrollWidth >= 0 && clientWidth >= 0 && scrollLeft >= 0) {
        setScrollRight(scrollWidth - clientWidth - scrollLeft - rightOffset);
      }
    }
  };

  useEffect(() => {
    const element = childRef?.current;
    if (childRef?.current?.scrollWidth) {
      updateScrollPosition();
    }
    if (element && !disable) {
      element.addEventListener('scroll', updateScrollPosition, false);
    }
    return () => {
      if (element && !disable) {
        element.removeEventListener('scroll', updateScrollPosition, false);
      }
    };
  }, [childRef]);

  const handleClick = (offset: number) => {
    if (childRef?.current) {
      childRef.current.scrollLeft += offset;
    }
  };

  const handleLeftClick = () => {
    handleClick(-SCROLL_OFFSET);
  };

  const handleRightClick = () => {
    handleClick(SCROLL_OFFSET);
  };

  return disable ? (
    <> {children} </>
  ) : (
    <div className='relative z-0'>
      {scrollLeft > 0 && (
        <div className='hidden absolute top-0 left-11 z-1 md:flex items-center justify-center h-full transform rotate-180'>
          <button onClick={handleLeftClick} type='button'>
            <SliderArrow />
          </button>
        </div>
      )}
      {children}
      {scrollRight > 0 && (
        <div className='absolute top-0 right-11 z-1 hidden md:flex items-center justify-center h-full'>
          <button onClick={handleRightClick} type='button'>
            <SliderArrow />
          </button>
        </div>
      )}
    </div>
  );
};

export default Slider;
