import { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import RhfCheckboxGroup from 'components/CheckboxGroup/RhfCheckboxGroup/RhfCheckboxGroup';
import { FormLabel } from 'components/FormLabel/FormLabel';
import { If } from 'components/Generics';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import RhfTeethToExtract from 'components/TreatmentPlanningInstructions/TreatmentSpecifications/TeethToExtract/RhfTeethToExtract/RhfTeethToExtract';
import {
  IPR_OR_ATTACHMENT_OPTIONS,
  PROSTHESIS_OPTIONS,
  TRUTH_VALUE
} from 'constants/TPVFormOptions';
import { getSelectedOption, isValuePresentInOptions } from 'utils/tpvUtils';

type Props = {
  title: string;
  disabled?: boolean;
  fieldName?: string;
  isNewTemplatizedPrescription?: boolean;
};

export const ProsthesisRestorationInput: FC<Props> = ({
  title,
  disabled,
  fieldName,
  isNewTemplatizedPrescription = false
}) => {
  const {
    control,
    watch,
    trigger,
    formState: { isSubmitted, errors }
  } = useFormContext();
  const FieldName = fieldName ? `${fieldName}.` : '';
  const [name] = useState(`${FieldName}prosthesis`);
  const watchFields = [
    'ipr',
    'attachment',
    `${FieldName}prosthesisAttachmentToAvoid`,
    `${FieldName}prosthesisIprToAvoid`,
    name,
    `${FieldName}iprOrAttachment`
  ];
  const [
    ipr,
    attachment,
    prosthesisAttachmentToAvoid,
    prosthesisIprToAvoid,
    prosthesis,
    iprOrAttachment
  ] = watch(watchFields);

  useEffect(() => {
    if (isSubmitted) trigger(watchFields);
  }, [
    ipr,
    attachment,
    prosthesisAttachmentToAvoid,
    prosthesisIprToAvoid,
    isSubmitted,
    prosthesis,
    trigger
  ]);
  const internalFieldError =
    _.get(errors, `${FieldName}prosthesisAttachmentToAvoid`) ||
    _.get(errors, `${FieldName}prosthesisIprToAvoid`) ||
    _.get(errors, `${FieldName}prosthesis`) ||
    errors?.ipr ||
    errors?.attachment;
  const externalFieldError =
    _.get(errors, `${FieldName}prosthesisAttachmentToAvoid`) ||
    _.get(errors, `${FieldName}prosthesisIprToAvoid`) ||
    _.get(errors, `${FieldName}prosthesis`) ||
    _.get(errors, `${FieldName}iprOrAttachment`) ||
    errors?.ipr ||
    errors?.attachment;

  const fieldError = isNewTemplatizedPrescription
    ? !!externalFieldError
    : !!internalFieldError;
  return (
    <div className='relative'>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <>
            <FormLabel
              label={title}
              className='mb-1'
              error={!!fieldError}
              name={name}
            />
            <div className='md:w-325px mt-5'>
              <RhfSelect
                name={name}
                options={PROSTHESIS_OPTIONS}
                onChange={(data) => {
                  field.onChange(data?.value);
                }}
                isDisabled={disabled}
                inputProps={{
                  controlShouldRenderValue: true,
                  selectedValue: getSelectedOption(
                    prosthesis,
                    PROSTHESIS_OPTIONS
                  )
                }}
              />
            </div>
            <If condition={prosthesis === TRUTH_VALUE.YES}>
              {isNewTemplatizedPrescription && (
                <RhfCheckboxGroup
                  disabled={disabled}
                  options={IPR_OR_ATTACHMENT_OPTIONS}
                  name={`${FieldName}iprOrAttachment`}
                  className='mt-1'
                  checkBoxItemFont='text-sm flex flex-col md:flex-row'
                  labelClassName='mr-14'
                />
              )}
              <div>
                <If
                  condition={
                    !isNewTemplatizedPrescription ||
                    isValuePresentInOptions(iprOrAttachment, 'IPR to avoid')
                  }
                >
                  <div className='mb-4 mt-4'>
                    <FormLabel
                      label='IPR to avoid'
                      className={classNames('mb-1', {
                        'text-BLUE_4': isNewTemplatizedPrescription
                      })}
                      error={
                        !!_.get(errors, `${FieldName}prosthesisIprToAvoid`)
                      }
                      name='ipr'
                    />
                    <div className='mt-4'>
                      <RhfTeethToExtract
                        name={`${FieldName}prosthesisIprToAvoid`}
                        showError={false}
                        disabled={disabled}
                        animateLabel={`${FieldName}prosthesisIprToAvoid`}
                      />
                    </div>
                  </div>
                </If>
                <If
                  condition={
                    !isNewTemplatizedPrescription ||
                    isValuePresentInOptions(
                      iprOrAttachment,
                      'Attachment to avoid'
                    )
                  }
                >
                  <div>
                    <FormLabel
                      label='Attachment to avoid'
                      className={classNames('mb-1', {
                        'text-BLUE_4': isNewTemplatizedPrescription
                      })}
                      error={
                        !!_.get(
                          errors,
                          `${FieldName}prosthesisAttachmentToAvoid`
                        )
                      }
                      name='attachment'
                    />
                    <div className='mt-4'>
                      <RhfTeethToExtract
                        name={`${FieldName}prosthesisAttachmentToAvoid`}
                        showError={false}
                        disabled={disabled}
                        animateLabel={`${FieldName}prosthesisAttachmentToAvoid`}
                      />
                    </div>
                  </div>
                </If>
              </div>
            </If>
          </>
        )}
      />
    </div>
  );
};
