import {
  DELETE_TREATMENT,
  GET_SUBMISSION_REASON,
  SAVE_PRESCRIPTION,
  SUBMIT_PRESCRIPTION
} from 'services/query/Prescription.query';
import {
  DeleteTreatment,
  GetRefinementSubmissionReason,
  SubmitPrescription,
  UpdatePrescription
} from 'types/PendingPrescription.types';

import { UseGraphQlMutationWithTpes, UseGraphQlQuery } from './hooks';

export const useSavePerscripiton: UpdatePrescription = (config) => {
  return UseGraphQlMutationWithTpes(SAVE_PRESCRIPTION, config);
};

export const useSubmitPerscripiton: SubmitPrescription = (config) => {
  return UseGraphQlMutationWithTpes(SUBMIT_PRESCRIPTION, config);
};

export const useDeleteTreatment: DeleteTreatment = (config) =>
  UseGraphQlMutationWithTpes(DELETE_TREATMENT, config);

/**
 * Hook to get useGetAllSubmissionReason
 * @param {QueryHookOptions=} config query options
 * @returns {QueryResult}
 */

export const useGetAllSubmissionReason: GetRefinementSubmissionReason = (
  config
) => {
  return UseGraphQlQuery(GET_SUBMISSION_REASON, config);
};
