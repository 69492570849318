import { FC, useEffect } from 'react';

import classNames from 'classnames';

import { Divider } from 'components/Divider/Divider';
import { If } from 'components/Generics';
import { LOWER_TOOTH_LIST, UPPER_TOOTH_LIST } from 'constants/ToothChart';
import { customTwMerge, translate } from 'utils';
type Props = {
  min?: number;
  max?: number;
  toothData: Record<string, string>;
  setToothData(toothData: Record<string, string>): void;
  disabled?: boolean;
  toothChartRef?: React.RefObject<HTMLDivElement>;
};

export const ToothChart2: FC<Props> = ({
  min,
  max,
  toothData,
  setToothData,
  disabled,
  toothChartRef
}) => {
  const onChangeToothData = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    const { value } = event.target;
    if (
      (!max || Number(value) < max) &&
      (min === undefined || Number(value) >= min)
    )
      setToothData({
        ...toothData,
        [fieldName]: event.target.value
      });
  };

  useEffect(() => {
    if (toothChartRef?.current) {
      const container = toothChartRef.current;
      const middleScroll = container.scrollWidth / 4;
      container.scrollLeft = middleScroll;
    }
  }, []);

  return (
    <div
      id='tooth-chart'
      className='flex flex-row bg-BG_COLOR px-7 w-full mt-2 overflow-x-scroll scrollbar-visible-mobile max-w-screen-xl'
      ref={toothChartRef}
    >
      <div className='flex items-center'>
        {translate('prescriptions.right')}
      </div>
      <div className='space-y-8 p-6 flex flex-col'>
        <div className='flex flex-1 md:pt-[6.25rem] pt-20'>
          {UPPER_TOOTH_LIST.map((teeth, index) => {
            const fieldName = `${UPPER_TOOTH_LIST?.[index - 1]?.name}-${
              teeth?.name
            }`;
            return (
              <div
                className='flex flex-1 items-end md:w-[6.25%]'
                key={teeth.name}
              >
                <div
                  className={classNames(
                    'w-10px  h-full relative mx-1 top-0',
                    {
                      'bg-LINE_COLOR': index > 0
                    },
                    index % 2 === 1 ? 'h-toothChartHeight' : ''
                  )}
                >
                  <If condition={index > 0}>
                    <div className='text-xs top-0  -translate-x-1/2 -translate-y-full absolute'>
                      <input
                        type='number'
                        className={customTwMerge(
                          'border-1 rounded-md text-center bg-white md:w-27px md:h-6 h-5 w-5 ',
                          classNames({
                            'bg-LIGHT_GRAY': disabled
                          })
                        )}
                        step={0.1}
                        max={max}
                        min={min}
                        value={toothData?.[fieldName]}
                        onChange={(e) => onChangeToothData(e, fieldName)}
                        disabled={disabled}
                      />
                    </div>
                  </If>
                </div>
                <div className='flex flex-col justify-end'>
                  <div className='text-WHITE'>{teeth?.image}</div>
                  <p className='text-xs text-center'>{teeth.name}</p>
                </div>
              </div>
            );
          })}
        </div>
        <Divider />
        <div className='flex flex-1 md:pb-[6.25rem] pb-20'>
          {LOWER_TOOTH_LIST.map((teeth, index) => {
            const fieldName = `${LOWER_TOOTH_LIST?.[index - 1]?.name}-${
              teeth?.name
            }`;
            return (
              <div
                className='flex flex-1 items-stretch md:w-[6.25%]'
                key={'lower' + teeth.name}
              >
                <div
                  className={classNames(
                    'w-1px h-full relative  mx-1 bottom-0',
                    {
                      'bg-LINE_COLOR': index > 0
                    },
                    index % 2 === 1 ? 'h-toothChartHeight' : ''
                  )}
                >
                  <If condition={index > 0}>
                    <div className='text-xs absolute bottom-0 -translate-x-1/2 translate-y-full'>
                      <input
                        type='number'
                        className={customTwMerge(
                          'text-xs  md:w-27px md:h-6 h-5 w-5 border-1 rounded-md text-center bg-white',
                          classNames({
                            'bg-LIGHT_GRAY': disabled
                          })
                        )}
                        step={0.1}
                        max={max}
                        min={min}
                        value={toothData?.[fieldName]}
                        onChange={(e) => {
                          setToothData({
                            ...toothData,
                            [fieldName]: e.target.value
                          });
                        }}
                        disabled={disabled}
                      />
                    </div>
                  </If>
                </div>
                <div className='flex flex-col'>
                  <p className='text-xs text-center'>{teeth.name}</p>
                  <div className='text-WHITE'>{teeth?.image}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className='flex items-center'>{translate('prescriptions.left')}</div>
    </div>
  );
};
