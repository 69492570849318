import {
  Tooth1,
  Tooth2,
  Tooth3,
  Tooth4,
  Tooth5,
  Tooth6,
  Tooth7,
  Tooth8,
  Tooth9,
  Tooth10,
  Tooth11,
  Tooth12,
  Tooth13,
  Tooth14,
  Tooth15,
  Tooth16,
  Tooth17,
  Tooth18,
  Tooth19,
  Tooth20,
  Tooth21,
  Tooth22,
  Tooth23,
  Tooth24,
  Tooth25,
  Tooth26,
  Tooth27,
  Tooth28,
  Tooth29,
  Tooth30,
  Tooth31
} from 'assets/images/tooth';

export const TOOTH_ORDER_LIST = [
  '1.8',
  '1.7',
  '1.6',
  '1.5',
  '1.4',
  '1.3',
  '1.2',
  '1.1',
  '2.1',
  '2.2',
  '2.3',
  '2.4',
  '2.5',
  '2.6',
  '2.7',
  '2.8',
  '4.8',
  '4.7',
  '4.6',
  '4.5',
  '4.4',
  '4.3',
  '4.2',
  '4.1',
  '3.1',
  '3.2',
  '3.3',
  '3.4',
  '3.5',
  '3.6',
  '3.7',
  '3.8'
];

export const UPPER_TOOTH_LIST = [
  {
    image: <Tooth1 className='w-full' />,
    name: TOOTH_ORDER_LIST[0]
  },
  {
    image: <Tooth2 className='w-full' />,
    name: TOOTH_ORDER_LIST[1]
  },
  {
    image: <Tooth3 className='w-full' />,
    name: TOOTH_ORDER_LIST[2]
  },
  {
    image: <Tooth4 className='w-full' />,
    name: TOOTH_ORDER_LIST[3]
  },
  {
    image: <Tooth5 className='w-full' />,
    name: TOOTH_ORDER_LIST[4]
  },
  {
    image: <Tooth6 className='w-full' />,
    name: TOOTH_ORDER_LIST[5]
  },
  {
    image: <Tooth7 className='w-full' />,
    name: TOOTH_ORDER_LIST[6]
  },
  {
    image: <Tooth8 className='w-full' />,
    name: TOOTH_ORDER_LIST[7]
  },
  {
    image: <Tooth9 className='w-full' />,
    name: TOOTH_ORDER_LIST[8]
  },
  {
    image: <Tooth10 className='w-full' />,
    name: TOOTH_ORDER_LIST[9]
  },
  {
    image: <Tooth11 className='w-full' />,
    name: TOOTH_ORDER_LIST[10]
  },
  {
    image: <Tooth12 className='w-full' />,
    name: TOOTH_ORDER_LIST[11]
  },
  {
    image: <Tooth13 className='w-full' />,
    name: TOOTH_ORDER_LIST[12]
  },
  {
    image: <Tooth14 className='w-full' />,
    name: TOOTH_ORDER_LIST[13]
  },
  {
    image: <Tooth15 className='w-full' />,
    name: TOOTH_ORDER_LIST[14]
  },
  {
    image: <Tooth16 className='w-full' />,
    name: TOOTH_ORDER_LIST[15]
  }
];
export const LOWER_TOOTH_LIST = [
  {
    image: <Tooth17 className='w-full' />,
    name: TOOTH_ORDER_LIST[16]
  },
  {
    image: <Tooth18 className='w-full' />,
    name: TOOTH_ORDER_LIST[17]
  },
  {
    image: <Tooth19 className='w-full' />,
    name: TOOTH_ORDER_LIST[18]
  },
  {
    image: <Tooth20 className='w-full' />,
    name: TOOTH_ORDER_LIST[19]
  },
  {
    image: <Tooth21 className='w-full' />,
    name: TOOTH_ORDER_LIST[20]
  },
  {
    image: <Tooth22 className='w-full' />,
    name: TOOTH_ORDER_LIST[21]
  },
  {
    image: <Tooth23 className='w-full' />,
    name: TOOTH_ORDER_LIST[22]
  },
  {
    image: <Tooth24 className='w-full' />,
    name: TOOTH_ORDER_LIST[23]
  },
  {
    image: <Tooth25 className='w-full' />,
    name: TOOTH_ORDER_LIST[24]
  },
  {
    image: <Tooth26 className='w-full' />,
    name: TOOTH_ORDER_LIST[25]
  },
  {
    image: <Tooth27 className='w-full' />,
    name: TOOTH_ORDER_LIST[26]
  },
  {
    image: <Tooth28 className='w-full' />,
    name: TOOTH_ORDER_LIST[27]
  },
  {
    image: <Tooth29 className='w-full' />,
    name: TOOTH_ORDER_LIST[28]
  },
  {
    image: <Tooth30 className='w-full' />,
    name: TOOTH_ORDER_LIST[29]
  },
  {
    image: <Tooth31 className='w-full' />,
    name: TOOTH_ORDER_LIST[30]
  },
  {
    image: <Tooth31 className='w-full' />,
    name: TOOTH_ORDER_LIST[31]
  }
];
