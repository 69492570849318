import {
  ADD_LEAVE_FOR_MYSELF,
  ADD_LEAVE_FOR_OTHERS,
  CANCEL_LEAVE,
  GET_LEAVES
} from 'services/query/leave';
import { AddLeave, CancelLeave, GetLeaves } from 'types/leave';

import { UseGraphQlMutation, UseGraphQlQuery } from './hooks';

export const useAddLeave: AddLeave = (config) => {
  return UseGraphQlMutation(ADD_LEAVE_FOR_OTHERS, config);
};

export const useAddLeaveForMyself: AddLeave = (config) => {
  return UseGraphQlMutation(ADD_LEAVE_FOR_MYSELF, config);
};

export const useCancelLeave: CancelLeave = (config) => {
  return UseGraphQlMutation(CANCEL_LEAVE, config);
};

export const useGetLeaves: GetLeaves = (config) => {
  return UseGraphQlQuery(GET_LEAVES, config);
};
