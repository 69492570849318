import { FC } from 'react';

import { cloneDeep } from 'lodash';
import { useRecoilState } from 'recoil';

import { Button, Modal } from 'components';
import {
  INBOX_CONTEXT_TYPE,
  SEGMENT_STATUS,
  TICKET_STATUS
} from 'constants/inbox';
import { CHAT_TRACK_EVENTS, VIEW_TYPE } from 'constants/segment';
import {
  useSegmentTrackChat,
  useTrackData
} from 'services/hooks/Segment.hooks';
import { useUpdateTicketStatus } from 'services/hooks/Ticket.hooks';
import { selectedTicket, ticketList } from 'store/atoms';
import { Ticket, TicketStatus } from 'types/Tickets.types';
import { translate } from 'utils';
import { getParticipantCount, getTopicName } from 'utils/common';
import { formatSegmentTimeHours } from 'utils/dateUtils';

import ClosedTicketModal from './ClosedTicketModal';
import ReopenTicketModal from './ReopenTicketModal';

interface ClosePopupProps {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
}

const ClosePopup: FC<ClosePopupProps> = ({ isOpen, setIsOpen }) => {
  const [ticketsList, setTicketsList] = useRecoilState(ticketList);
  const handleSegmentTrack = useSegmentTrackChat();
  const { trackData, trackContextData } = useTrackData();
  const [selectedTicketDetails, setSelectedTicketDetails] =
    useRecoilState(selectedTicket);
  const [updateTicketStatus] = useUpdateTicketStatus({
    onCompleted: (data: TicketStatus) => {
      const tickets = cloneDeep(ticketsList);
      const updatedTicketIndex = tickets.findIndex(
        (ticket) => ticket.id === data.updateTicketStatus.id
      );

      if (updatedTicketIndex !== -1) {
        if (data.updateTicketStatus.status === TICKET_STATUS.CLOSE) {
          const createdAt = new Date(tickets[updatedTicketIndex].createdAt);
          const timeNow = new Date();
          const segmentData = {
            ...trackData,
            ...trackContextData,
            zenchat_status: 'closed',
            time_diff_to_created_date: formatSegmentTimeHours(
              timeNow.getTime() - createdAt.getTime()
            )
          };

          handleSegmentTrack(CHAT_TRACK_EVENTS.CHAT_CLOSE, segmentData);
        } else {
          const lastClosed = new Date(data.updateTicketStatus.lastClosedDate);
          const timeNow = new Date();
          handleSegmentTrack(CHAT_TRACK_EVENTS.CHAT_REOPEN, {
            ...trackData,
            ...trackContextData,
            view_type: VIEW_TYPE.INBOX,
            zenchat_status: SEGMENT_STATUS.OPENED,
            timediff_to_closed_date: formatSegmentTimeHours(
              timeNow.getTime() - lastClosed.getTime()
            )
          });
        }
        tickets[updatedTicketIndex].status = data.updateTicketStatus.status;
        setTicketsList(tickets);
        setSelectedTicketDetails((oldDetails: Ticket) => {
          const updatedTicketDetails = cloneDeep(oldDetails);
          updatedTicketDetails.status = data.updateTicketStatus.status;
          return updatedTicketDetails;
        });
      }

      const participantCount = getParticipantCount(
        selectedTicketDetails.ticketUsers
      );
      const zenchatUpdateBqData = {
        zenchat_ID: trackData.zenchat_ID,
        chat_created_ts: trackData.chat_created_ts,
        no_of_participants: participantCount
      };
      handleSegmentTrack(
        CHAT_TRACK_EVENTS.ZENCHAT_UPDATE_BQ,
        zenchatUpdateBqData
      );
    }
  });

  const handlePopupConfirm = () => {
    const param = {
      variables: {
        ticketId: selectedTicketDetails?.id,
        status:
          selectedTicketDetails?.status === TICKET_STATUS.OPEN
            ? TICKET_STATUS.CLOSE
            : TICKET_STATUS.OPEN
      }
    };
    updateTicketStatus(param);
    setIsOpen(false);
  };

  const getPatientName = (): string =>
    selectedTicketDetails.ticketTopicContextInfo?.find(
      (context) => context.contextType === INBOX_CONTEXT_TYPE.PATIENT
    )?.contextValue || '';

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className='text-ONYX p-6'>
        {selectedTicketDetails?.status === TICKET_STATUS.OPEN ? (
          <ClosedTicketModal
            patientName={getPatientName()}
            topicName={getTopicName(selectedTicketDetails.topicInfo.topicCode)}
          />
        ) : (
          <ReopenTicketModal
            patientName={getPatientName()}
            topicName={getTopicName(selectedTicketDetails.topicInfo.topicCode)}
          />
        )}
        <div className='mt-6 flex justify-end gap-2'>
          <Button
            type='reset'
            onClick={() => setIsOpen(false)}
            className='text-14px leading-6 py-3 px-4'
          >
            {translate('cancel.cancel')}
          </Button>
          <Button
            type='submit'
            onClick={handlePopupConfirm}
            className='text-14px leading-6 py-3 px-5 bg-PRIMARY text-white'
          >
            {translate('confirm.confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ClosePopup;
