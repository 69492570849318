import { FC } from 'react';

import AppointmentTime from 'components/AppointmentTime/AppointmentTime';
import CalendarDate from 'components/CalendarDate/CalendarDate';
import { DATE_FORMAT, TIME_FORMAT_12 } from 'constants/settings';
import { TimeSlot } from 'types/AfterSalesAppointment.types';
import { formatDate } from 'utils';

interface Props {
  appointmentSlotIndex: number;
  slot?: TimeSlot;
}
const AppointmentPreferredSlot: FC<Props> = ({
  appointmentSlotIndex,
  slot
}) => {
  const getTime = () =>
    slot?.from && slot?.to
      ? formatDate(slot.from, TIME_FORMAT_12) +
        ' - ' +
        formatDate(slot.to, TIME_FORMAT_12)
      : '-';

  return (
    <div className='flex flex-col mb-14px'>
      <h3 className='text-GRAY3 font-normal mb-6px text-sm'>
        PREFERRED SLOT {appointmentSlotIndex + 1}
      </h3>
      <div className='flex flex-row'>
        <CalendarDate
          date={slot?.from ? formatDate(slot.from, DATE_FORMAT) : '-'}
        />
        <AppointmentTime time={getTime()} />
      </div>
    </div>
  );
};

export default AppointmentPreferredSlot;
