import { FC } from 'react';

import CustomModal from 'components/CustomModal/CustomModal';
import { If } from 'components/Generics';
import { ResponsiveModalProps } from 'types/Modals.types';
import { useViewport, customTwMerge as twMerge } from 'utils';

import ModalHeader from '../ModalHeader/ModalHeader';

import './ResponsiveModal.css';

const ResponsiveModal: FC<ResponsiveModalProps> = ({
  title,
  children,
  isOpen,
  onClose,
  className,
  showHeader = true,
  titleClassName,
  customModalClassNames
}) => {
  const { width } = useViewport();
  const isMobile = width < 768;
  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      showCloseIcon={false}
      noOverlay={isMobile}
      classNames={customModalClassNames}
    >
      <div
        className={twMerge(
          'w-full md:w-164  md:h-auto customResponsiveModalHeight p-6',
          className
        )}
      >
        <If condition={showHeader}>
          <ModalHeader
            titleClassName={titleClassName}
            title={title}
            onClose={onClose}
          />
        </If>
        {children}
      </div>
    </CustomModal>
  );
};

export default ResponsiveModal;
