import { FC } from 'react';

import { Line, Circle, ProgressProps } from 'rc-progress';

type ProgressBarProps = {
  percentage: ProgressProps['percent'];
  strokeWidth?: ProgressProps['strokeWidth'];
  strokeColor?: ProgressProps['strokeColor'];
  type?: 'line' | 'circle';
  trailColor?: ProgressProps['trailColor'];
  className?: ProgressProps['className'];
};

const ProgressBar: FC<ProgressBarProps> = ({
  percentage,
  type = 'line',
  strokeWidth = 3,
  strokeColor = '#2CAAE2',
  trailColor = '#EEF7FF',
  className = ''
}) =>
  type === 'line' ? (
    <Line
      percent={percentage}
      strokeWidth={strokeWidth}
      trailWidth={strokeWidth}
      strokeColor={strokeColor}
      trailColor={trailColor}
      className={className}
    />
  ) : (
    <Circle
      percent={percentage}
      strokeWidth={strokeWidth}
      strokeColor={strokeColor}
      trailColor={trailColor}
      className={className}
    />
  );

export default ProgressBar;
