import { FC, useState } from 'react';

import { InputActionMeta, createFilter } from 'react-select';

import Select from 'components/Select/Select';
import { SelectProps } from 'types/select';
import { translate } from 'utils';

import Dropdown from './DropDown';
import { controlStyles, menuStyles, RhfControlStyle } from './styles';

const SelectSearch: FC<SelectProps> = ({
  className,
  placeholder,
  arrowStyle,
  isFilter,
  isDeactivated,
  field,
  searchStyle,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');

  const onClose = () => {
    setSearchInput('');
  };

  const onSearch = (value: string, actionType: InputActionMeta) => {
    if (actionType.action === 'input-change') setSearchInput(value);
  };

  const handleClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleToggle = () => {
    if (isOpen) {
      onClose();
    }
    setIsOpen(!isOpen);
  };

  const filterConfig = {
    stringify: (option: any) => `${translate(option.label)}`
  };
  const placeholderValue = props?.selectedValue
    ? props?.selectedValue?.label
    : placeholder;

  return (
    <Dropdown
      testId={props.testId}
      isOpen={isOpen}
      onClose={handleClose}
      onToggle={handleToggle}
      placeholder={
        props?.controlShouldRenderValue ? placeholderValue : placeholder
      }
      searchStyle={searchStyle}
      className={className}
      arrowStyle={arrowStyle}
      isFilter={isFilter}
      isDeactivated={isDeactivated}
    >
      <Select
        {...props}
        isMulti={props.isMulti}
        placeholder=''
        isSearchable={true}
        isFilter={isFilter}
        menuIsOpen={true}
        controlShouldRenderValue={false}
        inputValue={searchInput}
        onInputChange={onSearch}
        menuStyles={menuStyles}
        searchBoxHeight={props.searchBoxHeight}
        controlStyles={isFilter ? controlStyles : RhfControlStyle}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={true}
        showSearchIcon={true}
        filterOption={createFilter(filterConfig)}
        onChange={
          props.isMulti
            ? props?.onChange ||
              ((selected) => {
                field?.onChange({
                  target: { name: field.name, value: selected }
                });
              })
            : props?.onChange ||
              ((selected) => {
                field?.onChange({
                  target: { name: field.name, value: selected }
                });
                handleClose();
              })
        }
      />
    </Dropdown>
  );
};

export default SelectSearch;
