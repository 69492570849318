import { FC } from 'react';

import CustomModal from 'components/CustomModal/CustomModal';
import { ChatCustomData } from 'types/Inbox.types';
import { SuccessorType } from 'types/InTreatmetn.types';
import { VersionDetails } from 'types/PatientList.types';

import ApprovalFeedback from '../ApprovalFeedback/ApprovalFeedback';
import RejectionFeedback from '../RejectionFeedback/RejectionFeedback';

type ApprovalFeeedbackModalProp = {
  approvalProps?: {
    showApprovePopup: boolean;
    onApproved: (isApproved: boolean) => void;
    treatmentId?: string;
    patientId?: string;
    name?: string;
    opportunityName?: string;
    opportunityId?: string;
    onRetainer?: () => void;
  };
};

type ModalProps = {
  open: boolean;
  onClose: () => void;
};

type RejectionFeeedbackModalProp = {
  rejectionProps?: {
    showRejectPopup: boolean;
    treatmentId?: string;
    patientId?: string;
    name?: string;
    opportunityName?: string;
    opportunityId?: string;
    clinicId: string;
    acceptedTreatmentPlanVersion?: VersionDetails;
    chatCustomData: ChatCustomData;
    onRefinement: () => void;
    treatmentPlanTypes: string[];
    additionalTreatment?: SuccessorType;
  };
};
const FeedbackModal: FC<
  ApprovalFeeedbackModalProp & RejectionFeeedbackModalProp & ModalProps
> = ({ approvalProps, rejectionProps, ...modalProps }) => {
  return (
    <CustomModal
      {...modalProps}
      showCloseIcon={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      {approvalProps?.showApprovePopup && (
        <ApprovalFeedback {...approvalProps} onClose={modalProps.onClose} />
      )}
      {rejectionProps?.showRejectPopup && (
        <RejectionFeedback {...rejectionProps} onClose={modalProps.onClose} />
      )}
    </CustomModal>
  );
};

export default FeedbackModal;
