import { FC } from 'react';

import { CountrySelector } from 'components/CountrySelector/CountrySelector';
import RhfInput from 'components/Generics/Rhf/RhfInput';
import { translate } from 'utils';

const AddAddress: FC = () => {
  return (
    <div className='space-y-6'>
      <div className='w-full'>
        <RhfInput
          label={translate('street.street')}
          name='street'
          showAnimated={false}
          inputProps={{
            placeholder: translate('street.street')
          }}
        />
      </div>
      <div className='flex w-full'>
        <div className='w-full md:mr-6 mr-4'>
          <RhfInput
            label={translate('postcode.postcode')}
            name='postalCode'
            showAnimated={false}
            inputProps={{ placeholder: translate('postcode.postcode') }}
          />
        </div>
        <div className='flex w-full'>
          <CountrySelector />
        </div>
      </div>
      <div className='flex w-full'>
        <div className='w-full md:mr-6 mr-4'>
          <RhfInput
            label={translate(`city.city`)}
            name='city'
            showAnimated={false}
            inputProps={{ placeholder: translate(`city.city`) }}
          />
        </div>
        <div className='w-full'>
          <RhfInput
            label={translate(`state.state`)}
            name='state'
            showAnimated={false}
            inputProps={{ placeholder: translate(`state.state`) }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddAddress;
