import { gql } from '@apollo/client';

export const REFERRAL_FILE_UPLOAD = gql`
  query ($referralId: String!, $documents: [ReferralDocumentInput]!) {
    getReferralDocumentsUploadUrl(
      input: { referralId: $referralId, documents: $documents }
    ) {
      url
      name
      key
      extension
    }
  }
`;

export const SAVE_REFERRAL_DOCUMENTS = gql`
  mutation SaveReferralDocuments(
    $referralId: String!
    $documents: [ReferralDocumentInput]!
  ) {
    saveReferralDocuments(
      input: { referralId: $referralId, documents: $documents }
    ) {
      id
      url
      name
      type
      extension
    }
  }
`;

export const DELETE_REFERRAL_DOCUMENTS = gql`
  mutation DeleteReferralDocument($id: String!, $referralId: String!) {
    deleteReferralDocument(input: { id: $id, referralId: $referralId })
  }
`;

export const UPDATE_REFERRAL_DOCUMENTS = gql`
  mutation updateReferralDocuments(
    $referralId: String!
    $input: [UpdateReferralDocumentsInput!]!
  ) {
    updateReferralDocuments(input: $input, referralId: $referralId) {
      documentId
    }
  }
`;
