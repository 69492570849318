import { FC } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import {
  Controller,
  FieldValues,
  UseFormSetValue,
  UseFormTrigger,
  useFormContext
} from 'react-hook-form';

import { Checkbox, RHFTextArea } from 'components';
import { FormLabel } from 'components/FormLabel/FormLabel';
import If from 'components/Generics/If/If';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import { RHFToothChart3 } from 'components/ToothChart3/RHFToothChart3';
import {
  IPR_REFINEMENT_FORM_OPTIONS,
  REFINEMENT_IPR
} from 'constants/TPVFormOptions';
import { IprInfo } from 'types/TPVForm.types';
import { translate } from 'utils';

type props = {
  name?: string;
  iprInfo?: IprInfo[];
  disabled?: boolean;
  missingTooth?: string[];
  source?: string;
  smileSummaryUrl?: string;
  showSmileSummary: boolean;
};

const onChangeIprNotAsPrescribed = (
  checked: boolean,
  setValue: UseFormSetValue<FieldValues>,
  FieldName: string,
  isSubmitted: boolean,
  trigger: UseFormTrigger<FieldValues>,
  iprInfo?: IprInfo[]
) => {
  if (checked) {
    setValue(
      `${FieldName}toothChart3`,
      iprInfo?.map((item) => item?.position) || []
    );
  } else {
    setValue(`${FieldName}toothChart3`, []);
  }
  if (isSubmitted) trigger();
};

export const IprPerformedDropDown: FC<props> = ({
  name,
  iprInfo,
  disabled,
  smileSummaryUrl,
  missingTooth,
  showSmileSummary,
  source
}) => {
  const {
    watch,
    setValue,
    control,
    trigger,
    formState: { isSubmitted }
  } = useFormContext();
  const isPrescriptionSummary = source === 'PrescriptionSummary';
  const FieldName = name ? `${name}.` : '';
  const [watchIpr, toothChart3] = watch([
    `${FieldName}iprPerformed`,
    `${FieldName}toothChart3`
  ]);

  const iprOptions =
    iprInfo?.length === 0
      ? [IPR_REFINEMENT_FORM_OPTIONS[0]]
      : IPR_REFINEMENT_FORM_OPTIONS;

  const allSelected = iprInfo?.every((item) =>
    toothChart3?.includes(item?.position)
  );

  return (
    <div>
      <Controller
        control={control}
        name={`${FieldName}IprPerformedDropDown`}
        render={({ formState: { errors } }) => {
          const fieldError =
            _.get(errors, `${FieldName}iprPerformed`) ||
            _.get(errors, `${FieldName}toothChart3`);

          return (
            <>
              <FormLabel
                label={translate(
                  '6. IPR performed for previous treatment plan'
                )}
                className='mb-4 text-sm'
                error={!!fieldError}
                name={`${FieldName}IprPerformedDropDown`}
              />

              <div>
                <div
                  className={classNames(
                    'flex w-full justify-between flex-col md:flex-row max-w-screen-xl',
                    {
                      'md:flex-wrap': isPrescriptionSummary
                    }
                  )}
                >
                  <div
                    className={classNames({
                      'md:w-327px w-full': !isPrescriptionSummary
                    })}
                  >
                    <RhfSelect
                      inputProps={{
                        showErrorTitle: false,
                        controlShouldRenderValue: true,
                        placeholder: translate('IPR performed treatment plan'),
                        placeHolderStyle: {
                          fontSize: '0.75rem',
                          fontColor: '#B8B8B5'
                        },
                        selectHeight: '3rem',
                        isDisabled: disabled || iprInfo?.length === 0
                      }}
                      options={iprOptions}
                      name={`${FieldName}iprPerformed`}
                      className={{ label: 'text-black text-sm' }}
                      showError={false}
                    />
                  </div>

                  <div>
                    <If
                      condition={
                        !disabled &&
                        watchIpr?.value === REFINEMENT_IPR.NOT_AS_PRESCRIBED &&
                        !!iprInfo
                      }
                    >
                      <div className='flex mr-7 mt-4'>
                        <Checkbox
                          onChange={(e) =>
                            onChangeIprNotAsPrescribed(
                              e.target.checked,
                              setValue,
                              FieldName,
                              isSubmitted,
                              trigger,
                              iprInfo
                            )
                          }
                          checked={allSelected}
                          disabled={disabled}
                        />
                        <div className='ml-2'>
                          {allSelected
                            ? translate('Unselect.all')
                            : translate('Select.all')}
                        </div>
                      </div>
                    </If>
                  </div>
                </div>
                <If
                  condition={
                    watchIpr?.value === REFINEMENT_IPR.NOT_AS_PRESCRIBED
                  }
                >
                  {iprInfo ? (
                    <div className='pt-4 flex flex-col'>
                      {toothChart3?.length === 0 && (
                        <div className='text-10px text-ERROR pb-4'>
                          {translate('ipr.not.performed.as.prescribed')}
                        </div>
                      )}
                      <RHFToothChart3
                        name={`${FieldName}toothChart3`}
                        toothData={iprInfo}
                        missingTooth={missingTooth}
                        disabled={disabled}
                      />
                    </div>
                  ) : (
                    <div className='mt-6 '>
                      <RHFTextArea
                        inputProps={{
                          placeholder: translate('IPR.instructions'),
                          disabled
                        }}
                        fontClass='text-sm'
                        name={`${FieldName}iprInfoText`}
                        autogrow
                        initialHeight='56px'
                        labelclassName='text-sm mb-4'
                      />
                    </div>
                  )}
                </If>
                <If condition={showSmileSummary}>
                  <div className='md:text-sm xxs:text-xs mb-2 flex whitespace-pre-wrap break-words items-center space-x-2 mt-2'>
                    <div className='md:text-sm xxs:text-xs flex whitespace-pre-wrap'>
                      {translate('tpv.smile_summary')}:
                    </div>
                    {!smileSummaryUrl ? (
                      '  -'
                    ) : (
                      <a
                        target='_blank'
                        href={smileSummaryUrl}
                        className='underline whitespace-nowrap mr-4 text-PRIMARY'
                        rel='noreferrer'
                      >
                        {translate('smilesummary.pdf')}
                      </a>
                    )}
                  </div>
                </If>
              </div>
            </>
          );
        }}
      />
    </div>
  );
};
