import { FC, Fragment } from 'react';

import cx from 'classnames';
import moment from 'moment';

import { EventComponent } from 'components';
import { Event } from 'types/Calendar.types';
import { checkDate, checkTime, DATE_TYPES } from 'utils/dateUtils';

interface Props {
  events: Event[];
  showNowIndicator?: boolean;
}

export const EventGroup: FC<Props> = ({ events, showNowIndicator }) => {
  const date = events[0]?.start;
  const dateType = checkDate(date);
  let isNowIndicatorRendered = false;

  const renderNowIndicator = () => {
    isNowIndicatorRendered = true;
    return (
      <div className='flex items-center'>
        <div className='bg-PRIMARY w-2 h-2 rounded-full' />
        <div className='h-1px bg-PRIMARY w-full' />
      </div>
    );
  };

  return (
    <div className='flex md:mt-7 md:mb-8'>
      <div
        className={cx(
          'flex items-left flex-col text-left text-sm xxs:w-5 md:w-29px xxs:mr-18px md:mr-35px',
          {
            'text-LIGHT_GREY_5': dateType === DATE_TYPES.PAST
          }
        )}
      >
        <p className='md:mt-23px xxs:mt-2 mb-1 xxs:text-10px md:text-14px'>
          {moment(date)?.format('MMM')?.toLocaleUpperCase()}
        </p>
        <p
          className={cx('xxs:text-14px md:text-17px text-center', {
            'bg-PRIMARY rounded-full md:w-6 md:h-6 leading-6 xxs:w-5 xxs:h-5':
              dateType === DATE_TYPES.TODAY,
            'text-WHITE': dateType === DATE_TYPES.TODAY
          })}
        >
          {moment(date)?.format('DD')}
        </p>
      </div>
      <div className='w-full'>
        {events?.map((event, index) => {
          const shouldRenderNowIndicator =
            showNowIndicator &&
            dateType === DATE_TYPES.TODAY &&
            checkTime(event?.start) === DATE_TYPES.FUTURE &&
            !isNowIndicatorRendered;
          return (
            <Fragment key={event?.id}>
              {(shouldRenderNowIndicator || index > 0) && (
                <div className='h-2'>
                  {shouldRenderNowIndicator && renderNowIndicator()}
                </div>
              )}
              <div className=''>
                <EventComponent event={event} />
              </div>
              {!isNowIndicatorRendered &&
                dateType === DATE_TYPES.TODAY &&
                index === events?.length - 1 && (
                  <div className='h-2'>{renderNowIndicator()}</div>
                )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
