import { FC } from 'react';

import cx from 'classnames';

import { ChipLabel } from 'components';
import { useGetTreatmentActions } from 'services/hooks/PatientList.hooks';
import { PatientListActionRequiredProps } from 'types/PatientListActionRequired.types';
import { translate } from 'utils';

export const PatientListActionRequired: FC<PatientListActionRequiredProps> = ({
  action,
  onClick
}) => {
  const { data: actionData } = useGetTreatmentActions({
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first'
  });

  const treatmentActions = new Map(
    actionData?.getTreatmentActions?.map((item) => [item.key, item.value])
  );

  const getActionRequired: (action?: string) => string = (treatmentAction) => {
    return treatmentAction
      ? treatmentActions.get(treatmentAction) ||
          'action_required.no_actions_required'
      : 'action_required.no_actions_required';
  };

  const actionsRequired = translate(getActionRequired(action));
  const noActionRequired =
    actionsRequired === translate('action_required.no_actions_required');

  return (
    <button
      onClick={onClick}
      className={cx({
        'hover:opacity-70 hover:scale-110 transform duration-300':
          !noActionRequired
      })}
    >
      <ChipLabel
        className={
          actionsRequired === translate('action_required.no_actions_required')
            ? 'bg-LIGHT_GRAY text-DISABLED'
            : 'bg-LIGHT_BLUE text-DARK_BLUE'
        }
      >
        {actionsRequired}
      </ChipLabel>
    </button>
  );
};
