import { FC, useState } from 'react';

import { Add, LightBulbIcon } from 'assets/icons';
import { If } from 'components/Generics';
import { Loader } from 'components/Loader/Loader';
import MobilePhotoCard from 'components/MobilePhotoCard';
import useReuploadHook from 'components/ReUploadModal/useReuploadHook';
import TabDrawer from 'components/TabDrawer/TabDrawer';
import { useMobileFileContext } from 'components/TreatmentPlanningInstructions/TreatmentFiles/MobilePhotoUpload/context';
import ZoomPhotoPopup from 'components/ZoomPhotoPopup/ZoomPhotoPopup';
import {
  CLASSIFICATION_LABEL,
  PRIMARY_REQUIRED_PHOTOS,
  PRIMARY_REQUIRED_XRAY,
  REFINEMENT_REQUIRED_PHOTOS,
  REFINEMENT_REQUIRED_XRAY,
  TREATMENT_FILE_MOBILE_ACTIONS
} from 'constants/treatmentFiles';
import { Classification, FilesData } from 'types/Files.types';
import { Option } from 'types/select';
import {
  TreatmentDocument,
  TreatmentDocumentType
} from 'types/TreatmentPlanning.types';
import { isDataExist, translate } from 'utils';
import { showToast } from 'utils/toast/toast.util';

export type MobileFileListViewProps = {
  isReUpload?: boolean;
  treatmentId?: string;
  title: string;
  files: FilesData[];
  onCancel: () => void;
  isPrimary?: boolean;
  selectedDocumentType?: TreatmentDocumentType;
  onDelete: (
    id: string | number | undefined,
    localId?: string | number,
    successHandler?: ((id: string | number) => void) | undefined
  ) => void;
  updateTreatmentFile: (
    input: {
      documentId: string | number;
      classification: string | null;
    }[]
  ) => void;
  uploadButtonHandlerForMobileView: () => void;
  toggleMobilePhotoUploadModal: () => void;
  missingFilesList?: Option[];
};

const MobileFileListView: FC<MobileFileListViewProps> = ({
  isReUpload,
  treatmentId,
  title,
  onCancel,
  onDelete,
  uploadButtonHandlerForMobileView,
  toggleMobilePhotoUploadModal,
  files,
  selectedDocumentType,
  isPrimary,
  updateTreatmentFile,
  missingFilesList
}) => {
  const { getMissingClassification } = useReuploadHook({
    treatmentId
  });

  const [zoomPhoto, toggleZoomPhoto] = useState(false);

  const [selectedPhoto, setSelectedPhoto] = useState<string | number>(0);
  const { state, dispatch } = useMobileFileContext();

  const getRequiredClassification = () => {
    if (selectedDocumentType === TreatmentDocumentType.PHOTO) {
      if (isReUpload)
        return getMissingClassification(TreatmentDocumentType.PHOTO)
          ?.classificationList;
      else
        return isPrimary ? PRIMARY_REQUIRED_PHOTOS : REFINEMENT_REQUIRED_PHOTOS;
    }
    if (selectedDocumentType === TreatmentDocumentType.X_RAY) {
      if (isReUpload)
        return getMissingClassification(TreatmentDocumentType.X_RAY)
          ?.classificationList;
      else return isPrimary ? PRIMARY_REQUIRED_XRAY : REFINEMENT_REQUIRED_XRAY;
    }
    return [];
  };

  const checkingRequiredClassificationExists = () => {
    const requiredClassifications = getRequiredClassification();
    return requiredClassifications.reduce(
      (accumulator: Classification[], classification) => {
        const index = state.files.findIndex(
          (file: FilesData) => classification === file.classification
        );
        const notAvailable = index == -1;
        if (notAvailable) {
          accumulator.push(classification);
        }
        return accumulator;
      },
      []
    );
  };

  const getDuplicateClassification = (classificationFiles = state.files) => {
    const classificationsList: Classification[] = [];
    const duplicateClassificationList: Classification[] = [];
    classificationFiles.forEach((classificationFile: FilesData) => {
      if (
        classificationFile.classification &&
        classificationFile.classification !== Classification.OTHER &&
        classificationsList.includes(classificationFile.classification)
      ) {
        duplicateClassificationList.push(classificationFile?.classification);
      } else if (classificationFile.classification) {
        classificationsList.push(classificationFile.classification);
      }
    });
    dispatch({
      type: TREATMENT_FILE_MOBILE_ACTIONS.SET_DUPLICATE_CLASSIFICATION_LIST,
      data: duplicateClassificationList
    });
    return duplicateClassificationList;
  };
  const getClassificationValue = (classification?: Classification | null) => {
    if (classification === Classification.OTHER) return null;
    return classification;
  };
  const updateClassification = () => {
    const duplicateClassificationList = getDuplicateClassification();
    if (duplicateClassificationList.length === 0) {
      const classificationList: TreatmentDocument[] =
        state.files.map((item: FilesData) => {
          return {
            documentId: item.id,
            classification: getClassificationValue(item.classification) || null
          };
        }) || [];
      updateTreatmentFile(classificationList);
    }
  };

  const handleChange = (option: Option, index: number) => {
    const changedFiles = [...state.files];
    changedFiles[index].classification = option.value;
    dispatch({
      type: TREATMENT_FILE_MOBILE_ACTIONS.UPDATE_PATIENT_FILES,
      data: changedFiles
    });
    updateClassification();
  };

  const handleSave = () => {
    const errorFields = checkingRequiredClassificationExists();
    dispatch({
      type: TREATMENT_FILE_MOBILE_ACTIONS.SET_MISSING_REQUIRED_CLASSIFICATION,
      data: errorFields
    });
    if (
      errorFields.length == 0 &&
      state.duplicateClassificationList.length === 0
    ) {
      showToast(translate('successfully.saved'), true);
      onCancel();
    }
  };

  const handleOnDelete = (fileData: FilesData[], id?: string | number) => {
    const successHandler = () => {
      const updatedFileList = fileData.filter(({ fileId }) => fileId !== id);
      dispatch({
        type: TREATMENT_FILE_MOBILE_ACTIONS.UPDATE_PATIENT_FILES,
        data: updatedFileList
      });
      if (updatedFileList.length === 0) {
        dispatch({
          type: TREATMENT_FILE_MOBILE_ACTIONS.HIDE_MOBILE_PHOTO_LIST_MODAL
        });
        toggleMobilePhotoUploadModal();
      }
      getDuplicateClassification(updatedFileList);
    };
    onDelete(id, undefined, successHandler);
  };

  return (
    <>
      <TabDrawer
        outerContainerClassName='z-[22] bg-DEFAULT_TEXT bg-opacity-65'
        innerContainerClassName='mx-3.5 my-9 bg-white p-4 rounded-xl'
      >
        <div className='flex flex-col h-full overflow-scroll'>
          <div className='flex flex-row justify-between'>
            <div className='text-20px '>{title}</div>
            <div className='flex flex-row space-x-4'>
              <button
                onClick={onCancel}
                className='text-14px px-4'
                type='button'
                title='saveButton'
              >
                {translate('button_rounded_(short).save')}
              </button>
              <button
                onClick={handleSave}
                type='button'
                title='saveButton'
                className='px-6 py-1  text-white bg-PRIMARY rounded-32px'
              >
                {translate('btn.finish')}
              </button>
            </div>
          </div>

          <div className='w-full my-5 bg-YELLOW_LIGHT_50 flex  p-4 rounded-lg h-28 justify-center items-center '>
            <LightBulbIcon className='text-DARK_YELLOW h-8 w-8 mr-6 ml-3 mt-2 ' />
            <div className='text-BROWN text-sm'>
              {translate('upload.high.resolution.image')}
            </div>
          </div>

          {(isDataExist(state.missingClassificationList) ||
            isDataExist(state.duplicateClassificationList)) && (
            <div className='text-ERROR DARK_BORDER_RED-1 border-DARK_BORDER_RED bg-LIGHT_ERROR_RED rounded-md w-full p-3 mb-4 text-sm'>
              <If condition={isDataExist(state.missingClassificationList)}>
                <p className='mb-2'>{translate('upload.categories')}</p>
                {state.missingClassificationList.map((error) => (
                  <p key={error} className='text-xs'>
                    - {translate(CLASSIFICATION_LABEL[error])}
                  </p>
                ))}
              </If>
              <If condition={isDataExist(state.duplicateClassificationList)}>
                <p className='mb-2 mt-4'>
                  {translate('duplicate.classification')}
                </p>
                {Array.from(new Set(state.duplicateClassificationList)).map(
                  (error) => (
                    <p key={error} className='text-xs'>
                      - {translate(CLASSIFICATION_LABEL[error])}
                    </p>
                  )
                )}
              </If>
            </div>
          )}
          {state.files.length === 0 ? (
            <Loader className='justify-center items-center h-20 w-20' />
          ) : (
            <>
              <div className='pb-5 flex flex-col items-center justify-center'>
                {state.files.map((item: FilesData, index: number) => (
                  <div key={`${item.id}-${index}`} className='mb-10'>
                    <MobilePhotoCard
                      missingFilesList={missingFilesList}
                      fileType={selectedDocumentType}
                      toggleZoomPhoto={toggleZoomPhoto}
                      setSelectedPhoto={setSelectedPhoto}
                      fileData={item}
                      onChange={(option: Option) => handleChange(option, index)}
                      fileId={item.fileId || ''}
                      files={state.files}
                      onDelete={handleOnDelete}
                      isDuplicateClassificationSelected={
                        item.classification &&
                        state.duplicateClassificationList.includes(
                          item.classification
                        )
                      }
                    />
                  </div>
                ))}
                {isDataExist(state.duplicateClassificationList) && (
                  <div className='w-full my-5 bg-YELLOW_LIGHT_50 flex  p-4 rounded-lg h-28 justify-center items-center '>
                    <LightBulbIcon className='text-DARK_YELLOW h-8 w-8 mr-6 ml-3 mt-2 ' />
                    <div className='text-BROWN text-sm'>
                      A duplicate classification has been selected. Please
                      reclassify before adding any new photographs.
                    </div>
                  </div>
                )}
                <button
                  onClick={uploadButtonHandlerForMobileView}
                  type='button'
                  disabled={isDataExist(state.duplicateClassificationList)}
                  title='add-icon'
                >
                  <div className='bg-CHINESE_WHITE w-251px h-251px rounded-lg flex justify-center items-center '>
                    <Add className='h-9 b-9' />
                  </div>
                </button>
              </div>
            </>
          )}
        </div>
        <If condition={zoomPhoto}>
          <div className='flex flex-col'>
            <ZoomPhotoPopup
              allFiles={files || []}
              zoomPhoto={zoomPhoto}
              toggleZoomPhoto={toggleZoomPhoto}
              selectedPhoto={selectedPhoto as string}
              setSelectedPhoto={setSelectedPhoto}
            />
          </div>
        </If>
      </TabDrawer>
    </>
  );
};

export default MobileFileListView;
