import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { If } from 'components/Generics';
import { customTwMerge } from 'utils';

type AnimateLabelProp = {
  animate: boolean;
  className?: string;
  children: ReactNode;
};

const AnimateLabel: FC<AnimateLabelProp> = ({
  animate = false,
  className = '',
  children
}) => {
  return (
    <If condition={animate}>
      <span
        className={classNames('absolute top-0', {
          [customTwMerge(`scale-up-center block h-6`, className)]: animate,
          hidden: !animate
        })}
      >
        {children}
      </span>
    </If>
  );
};

export default AnimateLabel;
