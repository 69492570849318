import { FC } from 'react';

import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { RETAINER_STATUSES, TREATMENT_STAGES } from 'constants/patientList';
import { TRACK_EVENTS } from 'constants/segment';
import { patientListFilters } from 'store/atoms';
import { Task } from 'types/Dashboard.types';
import { Option } from 'types/select';

import 'react-loading-skeleton/dist/skeleton.css';

interface Props {
  task: Task;
  loading: boolean;
  isRetainerTask?: boolean;
  showLastCardExpaanded?: boolean;
  retainerStatuses?: Option[];
}

export const TaskCountCard: FC<Props> = ({
  task,
  loading,
  isRetainerTask = false,
  showLastCardExpaanded = false,
  retainerStatuses
}) => {
  const navigate = useNavigate();

  const setFilters = useSetRecoilState(patientListFilters);

  const filterLabel = retainerStatuses?.find(
    (option) => option.key === task.id
  )?.value;

  const handleClick = () => {
    if (isRetainerTask) {
      setFilters((currentFilter) => ({
        ...currentFilter,
        retainerStatus: [{ value: task.id, label: filterLabel }]
      }));
    }
    navigate(task?.target);
    window?.analytics.track(TRACK_EVENTS?.TASK_CARD_CLICKED, {
      task_name: task?.label
    });
  };

  return (
    <button
      type='button'
      onClick={handleClick}
      className={classNames(
        'flex md:flex-col flex-row-reverse md:justify-start justify-between w-full md:h-28.5 h-fit px-6 pb-2 pt-2 md:pt-4 rounded-8px items-center md:items-start text-center',
        {
          'bg-GRAY5': loading,
          'bg-LIGHT_BLUE_HEADER':
            (task?.id === TREATMENT_STAGES.PENDING_PRESCRIPTION ||
              task?.id === RETAINER_STATUSES.PENDING_PRESCRIPTION) &&
            !loading,
          'bg-PURPLE':
            (task?.id === TREATMENT_STAGES.TREATMENT_PLAN_REVIEW ||
              task?.id === RETAINER_STATUSES.DELIVERED) &&
            !loading,
          'bg-YELLOW_LIGHT':
            (task?.id === TREATMENT_STAGES.PENDING_RESUBMISSION ||
              task?.id === RETAINER_STATUSES.RESUBMIT_SCAN) &&
            !loading,
          'bg-LIGHT_GREEN_2':
            task?.id === TREATMENT_STAGES.END_OF_TREATMENT_REVIEW && !loading,
          'last:col-span-2': showLastCardExpaanded
        }
      )}
      key={task?.id}
    >
      <p
        className={classNames('md:text-32px text-base md:mb-2 font-semibold', {
          'w-1/4': loading
        })}
      >
        {loading ? <Skeleton /> : task?.count}
      </p>
      <p
        className={classNames(
          'md:text-xs  xxs:text-xs text-left md:min-w-fit min-w-44 w-3/4'
        )}
      >
        {loading ? <Skeleton /> : task?.label}
      </p>
    </button>
  );
};
