import { useLazyQuery } from '@apollo/client';

import {
  CREATE_CHAT,
  CREATE_TREATMENT_NOTE,
  DELETE_NOTE,
  DELETE_NOTE_DOCUMENT,
  GET_ALL_APPOINTMENT_FOR_OPPORTUNITY,
  GET_APPOINTENT_TYPES,
  GET_DOCTORS_NOTES,
  GET_MIA_INFO,
  GET_PATIENT_PHOTOS,
  GET_TOTAL_ALIGNER_SET,
  SUBMIT_OUTCOME_FOR_APPOINTMENT,
  UPDATE_EXTERNAL_DOCTOR_REVIEW,
  UPDATE_TREATMENT_NOTE
} from 'services/query/InTreatment.queries';
import {
  CreateTicketFromTopicCode,
  DeleteNotesDocument,
  GetDoctorsNotesInput,
  GetDoctorsNotesRes,
  GetPatientPhotosInput,
  GetPatientPhotosRes,
  UpdateExternalDoctorReview,
  UseGetAppointmentTypes,
  UseGetDoctorsNotes,
  UseGetPatientPhotos,
  DeleteNote,
  UseGetMiaInfo,
  GetPatientMiaInfoRes,
  SubmitOutcomeForAppointment,
  UseGetAppointments,
  GetTotalAlignerSetRes,
  UseGetTotalAligners
} from 'types/InTreatmetn.types';
import { AuthMutation } from 'types/PatientList.types';

import {
  UseGraphQlMutation,
  UseGraphQlMutationWithTpes,
  UseGraphQlQuery,
  UseGraphQlQueryWithTypes
} from './hooks';

const defaultConfig = {};

export const useGetDoctorsNotes: UseGetDoctorsNotes = (config) => {
  return UseGraphQlQueryWithTypes<GetDoctorsNotesRes, GetDoctorsNotesInput>(
    GET_DOCTORS_NOTES,
    config
  );
};

export const useGetPatientPhotos: UseGetPatientPhotos = (config) => {
  return UseGraphQlQueryWithTypes<GetPatientPhotosRes, GetPatientPhotosInput>(
    GET_PATIENT_PHOTOS,
    config
  );
};

export const useGetMiaInfo: UseGetMiaInfo = (config) => {
  return useLazyQuery<GetPatientMiaInfoRes, GetPatientPhotosInput>(
    GET_MIA_INFO,
    config
  );
};

export const useGetTotalAligners: UseGetTotalAligners = (config) => {
  return UseGraphQlQueryWithTypes<GetTotalAlignerSetRes, GetPatientPhotosInput>(
    GET_TOTAL_ALIGNER_SET,
    config
  );
};

export const useGetAppointmentTypes: UseGetAppointmentTypes = (config) => {
  return UseGraphQlQuery(GET_APPOINTENT_TYPES, config);
};

export const useGetAppointments: UseGetAppointments = (config) => {
  return UseGraphQlQuery(GET_ALL_APPOINTMENT_FOR_OPPORTUNITY, config);
};

export const createTreatmentNote: AuthMutation = (config = defaultConfig) => {
  return UseGraphQlMutation(CREATE_TREATMENT_NOTE, config);
};

export const useDeleteNotesDocument: DeleteNotesDocument = (config) => {
  return UseGraphQlMutationWithTpes(DELETE_NOTE_DOCUMENT, config);
};

export const updateTreatmentNote: AuthMutation = (config = defaultConfig) => {
  return UseGraphQlMutation(UPDATE_TREATMENT_NOTE, config);
};

export const useDeleteTreatmentNote: DeleteNote = (config) =>
  UseGraphQlMutationWithTpes(DELETE_NOTE, config);

export const useUpdateExternalDoctorReview: UpdateExternalDoctorReview = (
  config
) => {
  return UseGraphQlMutationWithTpes(UPDATE_EXTERNAL_DOCTOR_REVIEW, config);
};

export const submitOutcomeForAppointment: SubmitOutcomeForAppointment = (
  config: any
) => UseGraphQlMutation(SUBMIT_OUTCOME_FOR_APPOINTMENT, config);

export const useCreateTicketFromTopicCode: CreateTicketFromTopicCode = (
  config
) => {
  return UseGraphQlMutation(CREATE_CHAT, {
    ...config
  });
};
