import { gql } from '@apollo/client';

export const ADD_LEAVE_FOR_OTHERS = gql`
  mutation submitLeaveForOthers($userId: String!, $input: SubmitLeaveInput!) {
    submitLeaveForOthers(userId: $userId, input: $input) {
      id
    }
  }
`;

export const ADD_LEAVE_FOR_MYSELF = gql`
  mutation submitLeaveForMyself($input: SubmitLeaveInput!) {
    submitLeaveForMyself(input: $input) {
      id
    }
  }
`;

export const CANCEL_LEAVE = gql`
  mutation cancelLeave($id: ID!) {
    cancelLeave(id: $id) {
      id
    }
  }
`;

export const GET_LEAVES = gql`
  query viewLeaves($filter: LeaveFilter) {
    viewLeaves(filter: $filter) {
      id
      type
      startDate
      endDate
      description
      timezone
    }
  }
`;
