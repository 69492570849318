import { FC } from 'react';

import { PopoverPosition } from 'react-tiny-popover';

import { QuestionIcon } from 'assets/icons';
import { customTwMerge } from 'utils';
import { useIsmobile } from 'utils/customHooks/useViewport';

import Tooltip from './Tooltip/Tooltip';

type InfoToolTipProps = {
  message?: string;
  className?: string;
  toolTipStyle?: string;
};
const InfoToolTip: FC<InfoToolTipProps> = ({
  message,
  className,
  toolTipStyle
}) => {
  const isMobile = useIsmobile();
  const getInfoBoxPosition = (): PopoverPosition[] => {
    return isMobile ? ['bottom'] : ['right'];
  };
  return (
    <Tooltip
      arrowColor='#C6E8F8'
      messageText={message || ''}
      positions={getInfoBoxPosition()}
      popOverClassName={'z-1'}
      tooltipStyle={customTwMerge(
        'bg-BLUE_200 text-BLUE_600 text-10px p-6px rounded-md z-1',
        toolTipStyle
      )}
    >
      <QuestionIcon className={className} />
    </Tooltip>
  );
};
export default InfoToolTip;
