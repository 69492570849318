import { EventRenderRange } from '@fullcalendar/common';
import _ from 'lodash';
import moment from 'moment';
import { PopoverPosition } from 'react-tiny-popover';

import { pagePaths } from 'config/pages';
import {
  ALLOWED_EXTENSIONS,
  ALLOWED_IMAGE_EXTENSIONS,
  DAYS,
  MULTI_TAB_SYNC_STORAGE_KEY,
  ROLES_TO_EXCLUDE,
  USER_ROLES
} from 'constants/common';
import { INBOX_GROUP_NAME, TEAM_NAME } from 'constants/inbox';
import { TREATMENT_PLAN_VERSION_STATUS } from 'constants/options';
import { TREATMENT_TYPE } from 'constants/patientList';
import { TITLE } from 'constants/segment';
import { REFINEMENT_GOALS } from 'constants/TPVFormOptions';
import { ROLES_TRANSLATION_MAPPER } from 'constants/translationMaps';
import { Doctor } from 'types/Appointment.types';
import {
  Branches,
  DayTime,
  WorkDayInput,
  WorkDayTimingDataInput,
  WorkDayTimingInput
} from 'types/Branches.types';
import { Event } from 'types/Calendar.types';
import { ErrorList, ErrorListMapper } from 'types/ErrorListMapper.types';
import { FilesData, FileType } from 'types/Files.types';
import { TreatmentStaff } from 'types/GiveInstructions.types';
import { Participant } from 'types/Inbox.types';
import {
  Doctors,
  Documents,
  Employee,
  Family,
  SubmittedBy,
  TreatmentFamilies,
  TreatmentPlanType,
  VersionDetails
} from 'types/PatientList.types';
import { Option } from 'types/select';
import { TreatmentDocumentType } from 'types/TreatmentPlanning.types';
import { User, UserRoles } from 'types/UsersList';
import { translate } from 'utils';

import { getActualTime, getFormattedTime } from './dateUtils';
import { showToast } from './toast/toast.util';
import {
  AllStages,
  SourceStageMap,
  TreatmentSourceType
} from '../components/TreatmentProgressMenu/TreatmentMenuConfig';

const formatFile = (files: File[]): FilesData[] =>
  files.map((file) => ({
    id: Math.floor(Date.now() * Math.random()),
    dateTime: moment().format('DD MMM YYYY, hh:mm A'),
    fileType: file.type.includes('image') ? FileType.IMAGE : FileType.FILE,
    fileSrc: URL.createObjectURL(file),
    uploadProgress: 0,
    file
  }));

const isDataExist = (data: any, num = 0): boolean =>
  data && Array.isArray(data) && data.length > num;

interface Name {
  firstName?: string;
  lastName?: string;
  salutation?: string;
}
export const getCurrentTpvStatus = (selectedVersion?: VersionDetails) => {
  const currentTpvStatus =
    selectedVersion?.acceptanceStatus ===
    TREATMENT_PLAN_VERSION_STATUS?.DISCUSSION_REQUIRED
      ? selectedVersion?.details?.metadata?.previousStatus
      : selectedVersion?.acceptanceStatus;
  return currentTpvStatus;
};

export const getNumberOption = (number: number) => {
  return {
    value: number,
    label: number.toString()
  };
};

const getFullName: (name: Name) => string = ({
  firstName,
  lastName,
  salutation
}) =>
  `${salutation || ''}${salutation ? ' ' : ''}${
    firstName ? firstName?.trim() + ' ' : ''
  }${lastName || ''}`;

const getInitials: (name: Name) => string = ({ firstName, lastName }) =>
  `${firstName ? firstName?.[0] : ''}${lastName ? lastName?.[0] : ''}`;

const formatEventRenderRange: (eventDef: EventRenderRange) => Event = (
  eventDef: EventRenderRange
) => {
  const { ui, def } = eventDef;
  return {
    id: def?.extendedProps?.id,
    title: def?.title,
    patient: def?.extendedProps?.patient,
    start: def?.extendedProps?.appointmentStart,
    end: def?.extendedProps?.appointmentEnd,
    color: ui?.backgroundColor,
    textColor: ui?.textColor,
    doctor: def?.extendedProps?.doctor,
    onSelectEvent: def.extendedProps.onSelectEvent,
    type: def.extendedProps.type,
    clinic: def.extendedProps.clinic,
    description: def.extendedProps.description,
    participants: def.extendedProps.participants,
    treatmentId: def?.extendedProps?.treatmentId,
    appointment_outcome: def?.extendedProps?.appointment_outcome
  };
};

const formatEventRenderRangeArray: (events: EventRenderRange[]) => Event[] = (
  events
) => {
  return events?.map?.((event: EventRenderRange) =>
    formatEventRenderRange(event)
  );
};

const setToLocalStorage = (key: string, value: string): void => {
  localStorage.setItem(key, value);
};

const getFromLocalStorage = (key: string): string | null => {
  return localStorage.getItem(key);
};

export const setObjectToLocalStorage = (
  key: string,
  data: Record<any, any>
): void => {
  const stringObject = JSON.stringify(data);
  setToLocalStorage(key, stringObject);
};

export const getObjectFromLocalStorage = (key: string) => {
  const stringObject = getFromLocalStorage(key) || '{}';
  return JSON.parse(stringObject);
};

const getAttatchmentIPRValues: (
  ipr?: boolean,
  attachments?: boolean
) => string = (ipr = false, attachments = false) =>
  `${
    ipr
      ? translate('tpv.chips_label_(non_interactive).submitted')
      : translate('tpv.no_ipr')
  } | ${
    attachments
      ? translate('prescriptions.attachments')
      : translate('tpv.no_attachments')
  }`;

const getTeamName = (teamName: string): string => {
  switch (teamName) {
    case TEAM_NAME.CASE_OPERATIONS.key:
      return translate('caseops.caseops');
    case TEAM_NAME.CUSTOMER_CARE.key:
      return translate('CC.CC');
    case TEAM_NAME.CUSTOMER_SUCCESS.key:
      return translate('CS.CS');
    case TEAM_NAME.EXTERNAL_DOCTOR.key:
      return translate('INBOX.EXTERNAL_DOCTOR');
    case TEAM_NAME.PARTNERSHIP_MANAGER.key:
      return translate('partnership_manager.partnership_manager');
    case TEAM_NAME.PRE_ASSESSMENT_DOCTOR.key:
      return translate('zenyum_council.pre_assessment_doctor');
    case TEAM_NAME.TREATMENT_PLANNING_DOCTOR.key:
      return translate('zenyum_council.treatment_planning_doctor');
    case TEAM_NAME.NURSE.key:
      return translate('nurse.nurse');
    case TEAM_NAME.TREATMENT_PLANNING_LEAD.key:
      return translate('zenyum_council.treatment_planning_lead');
    case TEAM_NAME.PRE_ASSESSMENT_LEAD.key:
      return translate('zenyum_council.pre_assessment_lead');
    case TEAM_NAME.CLINICAL_TRAINER_HQ.key:
      return translate('zenyum_council.clinicTrainerHq');
    case TEAM_NAME.CLINICAL_TRAINER.key:
      return translate('role.clinical_trainer');
    case TEAM_NAME.CS_ADMIN.key:
      return translate('user.cs_admin');
    case TEAM_NAME.CC_ADMIN.key:
      return translate('user.cc_admin');
    default:
      return teamName
        .split('_')
        .map((word) => {
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        })
        .join(' ');
  }
};
const checkIsExternal = (groups: string[]): boolean =>
  isDataExist(groups) &&
  (groups.includes(USER_ROLES.EXTERNAL_DOCTOR) ||
    groups.includes(USER_ROLES.NURSE) ||
    groups.includes(USER_ROLES.CLINIC_ADMIN) ||
    groups.includes(USER_ROLES.OTHERS));

export const checkIsTreatmentPlanningDoctor = (groups: string[]): boolean =>
  isDataExist(groups) && groups.includes(USER_ROLES.TREATMENT_PLANNING_DOCTOR);

const getSourceType = (stage: string) => {
  return SourceStageMap[stage];
};
const getPatientImageDocuments = (documents: Documents[]): FilesData[] => {
  return documents
    .filter((doc) => {
      const extension = doc?.extension?.toLowerCase();
      return extension === 'png' || extension === 'jpeg' || extension === 'jpg';
    })
    .map(({ documentId, documentName, presignedUrl, documentType }) => ({
      id: documentId,
      fileId: documentId,
      fileName: documentName,
      fileType: ALLOWED_IMAGE_EXTENSIONS.includes(
        documentName?.split('?')?.[0]?.split('.')?.pop()?.toLowerCase() || ''
      )
        ? ('img' as const)
        : ('file' as const),
      fileSrc: presignedUrl,
      key: documentType
    }));
};

const getDocumentsForSelectedStage = (
  documents: Documents[],
  stage: AllStages | TreatmentSourceType.RETAINERS
): FilesData[] => {
  const source = getSourceType(stage);
  return documents
    .filter(({ sourceType }) => sourceType === source)
    .map(
      ({
        documentId,
        documentName,
        presignedUrl,
        thumbnail,
        documentType,
        origin,
        classification,
        createdAt,
        submittedBy,
        resubmissionStatus
      }) => ({
        id: documentId,
        fileId: documentId,
        createdAt,
        fileName: documentName,
        resubmissionStatus,
        classification,
        fileType: ALLOWED_IMAGE_EXTENSIONS.includes(
          documentName?.split('?')?.[0]?.split('.')?.pop()?.toLowerCase() || ''
        )
          ? ('img' as const)
          : ('file' as const),
        fileSrc: presignedUrl,
        thumbnail,
        key: documentType,
        origin,
        submittedBy: submittedBy ?? SubmittedBy.Patient
      })
    );
};

export const getConsentFile = (documents?: Documents[]): string => {
  const consentFormFiles = documents?.filter(
    (document) => document?.documentType === TreatmentDocumentType.CONSENT_FORM
  );

  return consentFormFiles?.[consentFormFiles?.length - 1]?.presignedUrl || '';
};
export const getReferralConsentFile = (
  documents?: {
    id: string;
    key?: string;
    name: string;
    url: string;
    type: string;
    extension: string;
  }[]
): string => {
  const consentFormFiles = documents?.filter(
    (document) => document?.type === TreatmentDocumentType.CONSENT_FORM
  );

  return consentFormFiles?.[consentFormFiles?.length - 1]?.url || '';
};

const formatBranch = (branch: Branches): Omit<Branches, 'id'> => ({
  city: branch?.city,
  country: branch?.country,
  name: branch?.name,
  postalCode: branch?.postalCode,
  state: branch?.state,
  street: branch?.street,
  isAppointmentEnabled: branch?.isAppointmentEnabled,
  availability: {
    weekday: {
      startTime: branch?.availability?.weekday?.startTime,
      endTime: branch?.availability?.weekday?.endTime
    },
    weekend: {
      startTime: branch?.availability?.weekend?.startTime,
      endTime: branch?.availability?.weekend?.endTime
    }
  }
});

type Address = {
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
};

const formatAddressField = (field?: string, divider = ',') =>
  `${field ? field + divider : ''}`;

const formatAddress = (address: Address): string => {
  return `${formatAddressField(address?.street)} ${formatAddressField(
    address?.city
  )} ${formatAddressField(address?.state)} ${formatAddressField(
    address?.country
  )} ${formatAddressField(address?.postalCode)}`
    .trim()
    .slice(0, -1);
};

const getFileExtension = (fileName: string) =>
  fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2).toLowerCase();

const filterFilesUpload = (
  files: FileList,
  allowedExtensions = ALLOWED_EXTENSIONS
) => {
  const filteredFiles: FilesData[] = [];
  const fileArray = Array.from(files);

  fileArray.forEach((fileItem: File) => {
    const fileExtension = getFileExtension(fileItem.name);

    if (allowedExtensions.includes(fileExtension)) {
      const objectUrl = URL.createObjectURL(fileItem);
      const file: FilesData = {
        id: new Date().toISOString(),
        fileType: getFileType(fileExtension),
        fileSrc: objectUrl,
        file: fileItem
      };

      filteredFiles.push(file);
    } else {
      showToast(
        translate('error.validFileType', {
          X: fileItem.name
        }),
        false
      );
    }
  }, []);
  return filteredFiles;
};

const getNursesAndDoctorsCount = (users: User[] = []): string => {
  const count = { DOCTOR: 0, NURSE: 0 };
  users.forEach(({ groups }) => {
    groups.includes(UserRoles.EXTERNAL_DOCTOR) ? count.DOCTOR++ : count.NURSE++;
  });
  const getGroupName = (length: number, type: 'DOC' | 'NURSE') => {
    if (length) {
      if (length > 1) {
        return type === 'DOC'
          ? `${length} ${translate('chips_label.doctors')}`
          : `${length} ${translate('nurses.nurses')}`;
      } else {
        return type === 'DOC'
          ? `${length} ${translate('doctor.doctor')}`
          : `${length} ${translate('nurse.nurse')}`;
      }
    } else {
      return '';
    }
  };
  return `${getGroupName(count.DOCTOR, 'DOC')} ${getGroupName(
    count.NURSE,
    'NURSE'
  )}`.trim();
};
const getFileType = (extension: string): 'img' | 'file' =>
  ALLOWED_IMAGE_EXTENSIONS.includes(extension.toLowerCase()) ? 'img' : 'file';

const fixedEncodeURIComponent = (str: string): string => {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16).toUpperCase();
  });
};

const getFileKey = (fileUrl: string, fileName: string): string => {
  const regexToFindKey = new RegExp(
    `(?<=amazonaws.com/)(.*)${fixedEncodeURIComponent(
      fileName as string
    )}(?=\\?)`
  );
  return decodeURIComponent(fileUrl.match(regexToFindKey)?.[0] || '');
};

const referralsTabsTranslateMapper: Record<string, string> = {
  INCOMPLETE: 'incomplete_referrals.incomplete_referrals',
  REVIEW_PENDING: 'pending_zenyum_review.pending_zenyum_review',
  REJECTED: 'referrals.rejected_referrals'
};

const findIndex = (list?: Option[], value?: string): number => {
  const index = list?.findIndex((item) => item?.value === value) || 0;
  if (index < 0) return 0;
  return index;
};
const isMobileBrowser = (): boolean => {
  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i
  ];
  return toMatch.some((toMatchItem) => navigator.userAgent.match(toMatchItem));
};

const getDoctorName = (doctors: Doctors[] | undefined): string => {
  const primaryDoctor =
    doctors?.find(
      ({ primary, role }) => primary && role === USER_ROLES.EXTERNAL_DOCTOR
    )?.details ||
    doctors?.find(({ role }) => role === USER_ROLES.EXTERNAL_DOCTOR)?.details;
  if (primaryDoctor)
    return getFullName({
      firstName: primaryDoctor?.firstName,
      lastName: primaryDoctor?.lastName,
      salutation:
        primaryDoctor?.firstName?.includes('Dr') ||
        primaryDoctor?.lastName?.includes('Dr')
          ? ''
          : 'Dr'
    });

  return '-';
};
const getPrimaryDoctor = (
  doctors: Doctors[] | undefined
): Employee | undefined => {
  const primaryDoctor =
    doctors?.find(
      ({ primary, role }) => primary && role === USER_ROLES.EXTERNAL_DOCTOR
    )?.details ||
    doctors?.find(({ role }) => role === USER_ROLES.EXTERNAL_DOCTOR)?.details;
  return primaryDoctor;
};

const getAllOpportunityIds = (FamilyData?: TreatmentFamilies) => {
  const combinedFamilies = getCombinedFamilies(FamilyData);
  const opportunityIds = combinedFamilies
    ?.filter(({ opportunityId }) => opportunityId)
    .map((family: Family) => family.opportunityId);
  return opportunityIds;
};

const getCombinedFamilies = (FamilyData?: TreatmentFamilies) => {
  if (!FamilyData) return [];
  const combinedFamilies = Object.values(FamilyData)
    .filter((value) => Array.isArray(value))
    .flat();
  return combinedFamilies as Family[];
};

const getTreatmentOptionLabel = (
  index: number,
  treatmentLength: number,
  type: string
) => {
  let tabName = '';
  const isMultiple = treatmentLength > 2;
  if (!index && type === 'AFTERSALES') {
    tabName = translate('sidepanel_appointmentlist.primary');
  } else if (type === TREATMENT_TYPE.ZENYUM_RETAINER) {
    tabName =
      treatmentLength > 1
        ? translate('retainer.retainer') + ' ' + (index + 1)
        : translate('retainer.retainer');
  } else {
    tabName =
      index > 0 && isMultiple
        ? translate('refinement.refinement') + ' ' + index
        : translate('refinement.refinement');
  }
  return tabName;
};

const mapTreatmentOptions = (treatments: Family[], totalCount: number) =>
  treatments.map(({ opportunityId, cpTreatmentId, type }, index) => ({
    value: opportunityId || '',
    label: getTreatmentOptionLabel(index, totalCount, type),
    cpTreatmentId,
    type
  }));

const getTreatmentOptions = (familyData?: TreatmentFamilies) => {
  const alignerTreatmentsCount = familyData?.aligner?.length || 0;
  const alignerTreatmentOptions = familyData?.aligner
    ? mapTreatmentOptions(familyData.aligner, alignerTreatmentsCount)
    : [];

  const retainerTreatments =
    familyData?.retainer?.filter(
      (family) => family.type === 'ZENYUM_RETAINER'
    ) || [];

  const refinementTreatmentCount = retainerTreatments?.length;

  const retainerTreatmentsOptions = mapTreatmentOptions(
    retainerTreatments,
    refinementTreatmentCount
  );
  return [...alignerTreatmentOptions, ...retainerTreatmentsOptions];
};

export const TREATMENT_STAGES = {
  PRE_ASSESSMENT: 'PRE_ASSESSMENT',
  PENDING_PRESCRIPTION: 'PENDING_PRESCRIPTION',
  TREATMENT_PLAN_REVIEW: 'TREATMENT_PLAN_REVIEW',
  IN_TREATMENT: 'IN_TREATMENT',
  END_OF_TREATMENT_REVIEW: 'END_OF_TREATMENT_REVIEW'
};
const getTopicName = (topicId: string): string => {
  switch (topicId) {
    //Topic stages
    case 'PENDING_PRESCRIPTION': {
      return translate('chips_label.pending_prescription');
    }
    case 'GENERAL': {
      return translate('TopicList');
    }
    case 'TREATMENT_PLAN_REVIEW': {
      return translate('chips_label.treatment_plan_review');
    }
    case 'PRE_ASSESSMENT': {
      return translate('chips_label.pre-assessment');
    }
    case 'END_OF_TREATMENT_REVIEW': {
      return translate('chips_label.end-of-treatment_review');
    }
    case 'IN_TREATMENT': {
      return translate('status.in_treatment');
    }
    case 'RETAINERS': {
      return translate('inbox.retainers');
    }
    //Topics
    case 'CONSULTATION_ENQUIRY': {
      return translate('TopicList.3');
    }
    case 'PRIOR_TREATMENT_CLARIFICATION': {
      return translate('TopicList4');
    }
    case 'MISSING_FILE': {
      return translate('TopicList5');
    }
    case 'MISSING_CLINICAL_INFORMATION': {
      return translate('TopicList6');
    }
    case 'RE_SCAN_REQUIRED': {
      return translate('TopicList7');
    }
    case 'CASE_REJECTION': {
      return translate('TopicList9');
    }
    case 'ZENYUMCLEARTM_PLUS_OR_ZENYUMCLEARTM_CONFIRMATION': {
      return translate('TopicList12');
    }
    case 'TREATMENT_PLAN_DISCUSSION': {
      return translate('TopicList32');
    }
    case 'RE_SIMULATION_REQUIRED': {
      return translate('TopicList14');
    }
    case 'CASE_REFERRALS': {
      return translate('TopicList.1');
    }
    case 'NEW_NOTE_ADDED': {
      return translate('topic.appointment.notes');
    }
    case 'ILL_FITTING_ALIGNERS': {
      return translate('TopicList20');
    }
    case 'URGENT_ENQUIRY': {
      return translate('TopicList21');
    }
    case 'TRIMMING_ALIGNERS': {
      return translate('TopicList22');
    }
    case 'URGENT_END_OF_TREATMENT_SUPPORT': {
      return translate('TopicList29');
    }
    case 'END_OF_TREATMENT_SUPPORT': {
      return translate('TopicList30');
    }
    case 'GENERAL_ENQUIRY': {
      return translate('INBOX.GENERAL_ENQUIRY');
    }
    case 'REFINEMENT_REQUIRED': {
      return translate('INBOX.REFINEMENT_REQUIRED');
    }
    case 'IN_TREATMENT_DISCUSSION': {
      return translate('zenchat_topic_in_treatment_discussion');
    }
    case 'SCAN_UPLOAD': {
      return translate('inbox.scan_upload');
    }
    case 'RETAINER_SUPPORT': {
      return translate('inbox.retainer_support');
    }
    case 'ALIGNER_FIT_ISSUES': {
      return translate('inbox.aligner_fit_issues');
    }
    case 'PRE_TREATMENT_DISCUSSION': {
      return translate('inbox.pre_treatment_discussion');
    }
    case 'CLINIC_REFERRAL_CASES': {
      return translate('inbox.clinic_referral_cases');
    }
    case 'CASE_OPS_ISSUE': {
      return translate('case.ops.issue');
    }
    case 'DISCUSS_TP_INTERNALLY': {
      return translate('discuss.tp.internally');
    }
    default:
      return topicId;
  }
};

export const tpvStatus = {
  pendingZenyumReviewTPV: 'PENDING_ZENYUM_COUNCIL_REVIEW',
  patientAcceptedTPV: 'PATIENT_ACCEPTED',
  draft: 'DRAFT',
  rejected: 'REJECTED'
};
export const isAnyPlanOpen = (
  treatmentPlans: TreatmentPlanType[],
  selectedVersion?: VersionDetails
): boolean => {
  return treatmentPlans.some((plan) => {
    return plan?.versions?.some((version) => {
      if (version?.id === selectedVersion?.id) return false; //condition to check if any plan open, other than the selected version
      if (
        version?.acceptanceStatus === TREATMENT_PLAN_VERSION_STATUS.REJECTED
      ) {
        return false;
      }
      if (
        version?.acceptanceStatus ===
          TREATMENT_PLAN_VERSION_STATUS.DISCUSSION_REQUIRED &&
        version?.details?.metadata?.previousStatus ===
          TREATMENT_PLAN_VERSION_STATUS.REJECTED
      ) {
        return false;
      }
      return true;
    });
  });
};

const getFilterdTPVs = (
  versions: VersionDetails[] | null
): VersionDetails[] => {
  const acceptedTPVs =
    versions?.filter((version) => {
      return getCurrentTpvStatus(version) === tpvStatus.patientAcceptedTPV;
    }) || [];
  const tpvs = acceptedTPVs?.length ? acceptedTPVs : versions;
  return (
    tpvs?.filter((tpv) => {
      const currentTpvStatus = getCurrentTpvStatus(tpv);
      return (
        currentTpvStatus !== tpvStatus.pendingZenyumReviewTPV &&
        currentTpvStatus !== tpvStatus.draft
      );
    }) || []
  );
};
export const getAllTpvStatus = (
  treatmentPlans: TreatmentPlanType[],
  selectedVersionId?: string
) => {
  const tpv: string[] = [];
  if (treatmentPlans && selectedVersionId) {
    treatmentPlans.forEach((plan) =>
      plan?.versions
        ?.filter((version) => version.id !== selectedVersionId)
        ?.forEach((item) => {
          if (
            item.acceptanceStatus ===
            TREATMENT_PLAN_VERSION_STATUS.DISCUSSION_REQUIRED
          )
            tpv.push(item?.details?.metadata?.previousStatus as string);
          else tpv.push(item.acceptanceStatus);
        })
    );
  } else {
    treatmentPlans.forEach((plan) =>
      plan?.versions?.forEach((item) => {
        if (
          item.acceptanceStatus ===
          TREATMENT_PLAN_VERSION_STATUS.DISCUSSION_REQUIRED
        )
          tpv.push(item?.details?.metadata?.previousStatus as string);
        else tpv.push(item.acceptanceStatus);
      })
    );
  }
  return tpv;
};
export const isStatusPresent = (
  treatmentPlans: TreatmentPlanType[],
  status: TREATMENT_PLAN_VERSION_STATUS
) => {
  if (treatmentPlans) {
    return treatmentPlans.some((plan) => {
      return plan?.versions?.some((item) => {
        if (
          item?.acceptanceStatus !==
            TREATMENT_PLAN_VERSION_STATUS?.DISCUSSION_REQUIRED &&
          item?.acceptanceStatus === status
        )
          return true;
        else if (
          item?.acceptanceStatus ===
            TREATMENT_PLAN_VERSION_STATUS?.DISCUSSION_REQUIRED &&
          item?.details?.metadata?.previousStatus === status
        )
          return true;

        return false;
      });
    });
  }
  return false;
};

const getFilteredTps = (
  treatmentPlans: TreatmentPlanType[]
): TreatmentPlanType[] => {
  const acceptedTps = treatmentPlans.filter((tp) =>
    tp.versions?.find((version) => {
      return getCurrentTpvStatus(version) === 'PATIENT_ACCEPTED';
    })
  );
  const tps = acceptedTps.length ? acceptedTps : treatmentPlans;
  return tps;
};

const copyToClipboard = (copyText: string): void => {
  navigator?.clipboard?.writeText?.(copyText);
};

const formatRole = (role: string) => {
  if (role === USER_ROLES.CLINICAL_TRAINER_HQ) {
    return translate('zenyum_council.clinicTrainerHq');
  }
  if (role === USER_ROLES.CC_ADMIN) {
    return translate('user.cc_admin');
  }
  if (role === USER_ROLES.CS_ADMIN) {
    return translate('user.cs_admin');
  }
  return role
    .split('_')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(' ');
};

const displayName = (firstName?: string, lastName?: string, role?: string) =>
  `${getFullName({ firstName, lastName })}${
    role ? ` (${formatRole(role)})` : ''
  }`;

const hasMinimumFilesWithExtension = (
  filesArray: FilesData[] | undefined,
  targetExtension: string,
  minFileCount: number
) => {
  let matchingFileCount = 0;
  return !!filesArray?.some((fileItem) => {
    if ((fileItem.fileName || fileItem.file?.name)?.endsWith(targetExtension))
      matchingFileCount += 1;
    return matchingFileCount >= minFileCount;
  });
};

const checkName = (firstName?: string, lastName?: string) =>
  firstName || lastName;

const getRoleName = (
  role: string,
  firstName?: string,
  lastName?: string
): string => {
  switch (role) {
    case USER_ROLES.CASE_OPERATIONS:
      return translate(ROLES_TRANSLATION_MAPPER.CASE_OPERATIONS);
    case USER_ROLES.CASE_OPERATIONS_ADMIN:
      return translate(ROLES_TRANSLATION_MAPPER.CASE_OPERATIONS_ADMIN);
    case USER_ROLES.CUSTOMER_SUCCESS:
    case USER_ROLES.CS_ADMIN:
      return translate(ROLES_TRANSLATION_MAPPER.CUSTOMER_SUCCESS);
    case USER_ROLES.CUSTOMER_CARE:
    case USER_ROLES.CC_ADMIN:
      return translate(ROLES_TRANSLATION_MAPPER.CUSTOMER_CARE);
    case USER_ROLES.PARTNERSHIP_MANAGER: {
      if (checkName(firstName, lastName)) {
        return displayName(firstName, lastName);
      }
      return translate(ROLES_TRANSLATION_MAPPER.PARTNERSHIP_MANAGER);
    }
    case USER_ROLES.EXTERNAL_DOCTOR: {
      if (checkName(firstName, lastName)) {
        return displayName(firstName, lastName);
      }
      return translate(ROLES_TRANSLATION_MAPPER.EXTERNAL_DOCTOR);
    }
    case USER_ROLES.NURSE: {
      if (checkName(firstName, lastName)) {
        return displayName(firstName, lastName);
      }
      return translate(ROLES_TRANSLATION_MAPPER.NURSE);
    }
    case USER_ROLES.CLINIC_ADMIN: {
      if (checkName(firstName, lastName)) {
        return displayName(firstName, lastName);
      }
      return translate(ROLES_TRANSLATION_MAPPER.CLINIC_ADMIN);
    }
    case USER_ROLES.OTHERS: {
      if (checkName(firstName, lastName)) {
        return displayName(firstName, lastName);
      }
      return translate(ROLES_TRANSLATION_MAPPER.OTHERS);
    }
    case USER_ROLES.INTERNAL_DOCTOR:
    case USER_ROLES.PRE_ASSESSMENT_DOCTOR:
    case USER_ROLES.PRE_ASSESSMENT_LEAD:
    case USER_ROLES.TREATMENT_PLANNING_DOCTOR:
    case USER_ROLES.TREATMENT_PLANNING_LEAD:
    case USER_ROLES.AFTERSALES_DOCTOR:
    case USER_ROLES.OPERATIONS_DENTISTRY:
    case USER_ROLES.CLINICAL_TRAINER:
    case USER_ROLES.ZENYUM_COUNCIL_ADMIN:
    case USER_ROLES.PATIENT_CARE_LEAD:
    case USER_ROLES.CLINICAL_TRAINER_HQ:
      return translate('zenyum_council.zenyum_council');
    case USER_ROLES.SUPER_ADMIN:
      return translate('user.super_admin');
    case USER_ROLES.SALES_OPERATIONS:
      return translate(ROLES_TRANSLATION_MAPPER.SALES_OPERATIONS);
    default: {
      if (firstName || lastName) {
        return displayName(firstName, lastName, role);
      }
      return '';
    }
  }
};

//gets the type of browser
const detectBrowser = (): string => {
  if (
    (navigator.userAgent.indexOf('Opera') ||
      navigator.userAgent.indexOf('OPR')) != -1
  ) {
    return 'Opera';
  } else if (navigator.userAgent.indexOf('Chrome') != -1) {
    return 'Chrome';
  } else if (navigator.userAgent.indexOf('Safari') != -1) {
    return 'Safari';
  } else if (navigator.userAgent.indexOf('Firefox') != -1) {
    return 'Firefox';
  } else if (
    navigator.userAgent.indexOf('MSIE') != -1 ||
    !!document.DOCUMENT_NODE == true
  ) {
    return 'IE'; //crap
  } else {
    return 'other';
  }
};

const formatMessage = (searchedMessage: string, searchedTerm: string) => {
  const regexToSpecialChar = new RegExp(/[&\\/\\#,+$~%'":*?<>{}]/g);
  const message = searchedMessage.replace(regexToSpecialChar, '');
  if (message && searchedTerm) {
    const searchTerm = searchedTerm.replace(regexToSpecialChar, '');
    const index = message.toLocaleLowerCase().indexOf(searchTerm);
    let searchedString = '';
    let searchedStringBefore = '';
    let searchedStringAfter = '';
    if (index >= 0) {
      searchedString = message.substring(index, index + searchTerm.length);
      searchedStringBefore = message.substring(index, 0);
      searchedStringAfter = message.substring(
        index + searchTerm.length,
        message.length
      );

      if (searchedStringBefore.split(' ').length > 3) {
        searchedStringBefore =
          '...' + searchedStringBefore.split(' ').slice(-3).join(' ');
      }

      if (searchedStringAfter.split(' ').length > 3) {
        searchedStringAfter =
          searchedStringAfter.split(' ').slice(0, 3).join(' ') + '...';
      }
    } else {
      searchedStringBefore =
        message.length > 30 ? message.substring(0, 30) + '...' : message;
    }
    return (
      <div>
        {searchedStringBefore}
        <span className='font-bold'>{searchedString}</span>
        {searchedStringAfter}
      </div>
    );
  }
};

const formatDoctorsForSelect = (doctors: Doctor[]): Option[] =>
  doctors.map((doctor) => ({
    value: doctor.id,
    label: getFullName({
      firstName: doctor.firstName,
      lastName: doctor.lastName,
      salutation: ''
    })
  })) || [];

const capitalizeFirstLetterOnly = (word: string) =>
  _.startCase(_.toLower(word));

const getEntityHours = (timings: DayTime[], isDoctor: boolean) => {
  let formatDay: WorkDayTimingDataInput = {};
  timings?.forEach((time) => {
    formatDay = {
      ...formatDay,
      [time?.day]: {
        hours: time?.availableHours?.map((hour) => ({
          end: {
            label: getFormattedTime(hour.endTime),
            value: getFormattedTime(hour.endTime, 'HH:mm')
          },
          start: {
            label: getFormattedTime(hour.startTime),
            value: getFormattedTime(hour.startTime, 'HH:mm')
          }
        })),

        ...(isDoctor && {
          workingFullTime:
            time?.isWorkingFullTime && !time?.isWorking
              ? false
              : time?.isWorkingFullTime
        }),
        ...(isDoctor && {
          notWorking: !time?.isWorking
        }),
        ...(!isDoctor && { closed: !time?.isWorking })
      }
    };
  });
  return formatDay;
};
function getParticipantCount(ticketUsers: Participant[]): number {
  let userCount = 0;
  let ownerId;
  let initiatorId;
  ticketUsers.forEach((user) => {
    if (user.groupName === INBOX_GROUP_NAME.CHAT_INITIATOR) {
      initiatorId = user.userId;
    } else if (user.groupName === INBOX_GROUP_NAME.CHAT_OWNER) {
      ownerId = user.userId;
    } else if (user.groupName !== INBOX_GROUP_NAME.CHAT_SUPER_USER) {
      userCount += 1;
    }
  });

  if (ownerId && initiatorId) {
    userCount += initiatorId === ownerId ? 1 : 2;
  }
  return userCount;
}

const updateSaveData = (
  data: WorkDayTimingDataInput,
  isDoctor: boolean,
  clinicId?: string,
  doctorId?: string
): WorkDayInput[] => {
  let inputArray: WorkDayInput = {
    entityType: '',
    clinicId: '',
    doctorId: '',
    day: '',
    timing: [],
    isWorking: true,
    isWorkingFullTime: false
  };
  const finalArray: WorkDayInput[] = [];
  DAYS.forEach((eachDay) => {
    inputArray = {
      ...inputArray,
      entityType: isDoctor ? 'DOCTOR' : 'CLINIC',
      clinicId: clinicId || '',
      ...(isDoctor && {
        doctorId: doctorId
      }),
      day: eachDay,
      isWorking: !isDoctor
        ? (!data[eachDay]?.closed as boolean)
        : (!data[eachDay]?.notWorking as boolean),
      isWorkingFullTime: data[eachDay]?.workingFullTime as boolean,
      timing:
        data[eachDay]?.notWorking ||
        data[eachDay]?.workingFullTime ||
        data[eachDay]?.closed
          ? []
          : (data[eachDay]?.hours?.map((item) => ({
              startTime: getActualTime(item.start?.label as string),
              endTime: getActualTime(item.end?.label as string),
              isZenyumTiming: false
            })) as WorkDayTimingInput[])
    };

    finalArray.push(inputArray);
  });
  return finalArray;
};
const checkIfAppIsAdminClinic = (): boolean =>
  window.location.host?.includes('adminclinic') ||
  window.location.host?.includes('zenbase') ||
  false;

const segmentTrackEvent = (event: string, data: any) => {
  window.analytics?.track(event, data);
};

const segmentPageEvent = (event: string, data: any) => {
  window.analytics.page(event, data);
};

export const callSegmentPage = (view: string, startTime: Date) => {
  const endTime = new Date();
  const duration = endTime.getTime() - startTime.getTime();
  window.analytics.page(TITLE.CALENDAR, {
    path: pagePaths.calendar,
    title: TITLE.CALENDAR,
    url: `${window.location.host}${pagePaths.calendar}`,
    view_type: view,
    duration
  });
};
const subStringText = (message = '', length: number) => {
  if (message.length > length) {
    return `${message.substring(0, length)}...`;
  }
  return message;
};
const isValidHttpUrl = (string: string): boolean => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

const syncTabs = (): void => {
  if (!localStorage.getItem(MULTI_TAB_SYNC_STORAGE_KEY))
    localStorage.setItem(MULTI_TAB_SYNC_STORAGE_KEY, 'true');
  else localStorage?.removeItem(MULTI_TAB_SYNC_STORAGE_KEY);
};
const getFileFormat = (files: FilesData[]) => {
  const extensions: string[] = [];
  files?.map(
    (item) =>
      !extensions.includes(item?.file?.name.split('.')[1] as string) &&
      extensions.push(item?.file?.name?.split('.')[1] as string)
  );
  return extensions;
};
const removeItemAtIndex = (
  files: FilesData[] = [],
  index: number
): FilesData[] => files.filter((_, fileIndex) => fileIndex !== index);
const canvasToBlob = (canvas: any) => {
  const blobBin = atob(canvas.split(',')[1]);
  const array = [];
  for (let i = 0; i < blobBin.length; i++) {
    array.push(blobBin.charCodeAt(i));
  }
  const file = new Blob([new Uint8Array(array)], { type: 'image/png' });
  return file;
};

export const formatErrorList = (
  error: Record<string, any>,
  errorListMapper: ErrorListMapper
): ErrorList[] => {
  const errorList: ErrorList[] = errorListMapper.reduce(
    (acc: ErrorList[], { field, innerFields, label, slNo }) => {
      if (error[field]) {
        if (innerFields?.length) {
          const errorFields: ErrorList[] = [];
          innerFields.forEach(
            ({ field: innerFieled, label: innerLabel, slNo }) =>
              error[field][innerFieled] &&
              errorFields.push({
                id: `${field}.${innerFieled}`,
                field,
                slNo,
                label: `${translate(label)} ${
                  translate(innerLabel) ? '>' : ''
                } ${translate(innerLabel)}`
              })
          );
          return [...acc, ...errorFields];
        }
        return [...acc, { id: field, label: translate(label), field, slNo }];
      }
      return acc;
    },
    []
  );
  return errorList;
};

export const sortByReference = (
  mainArray: FilesData[],
  sortOrder: string[]
) => {
  const sorter = (a: FilesData, b: FilesData) => {
    const firstIndex = sortOrder.indexOf(a.key as string);
    const secondIndex = sortOrder.indexOf(b.key as string);
    return firstIndex - secondIndex;
  };
  mainArray.sort(sorter);
};

export const filterOutRoles = (roles: string[]) => {
  return roles?.filter((role) => !ROLES_TO_EXCLUDE.includes(role))?.join(', ');
};

export const RESIMULATION_INSTRUCTION_LABEL =
  'Resimulation instructions (optional)';

export const NotInPlanning = (tpv: VersionDetails) => {
  const currentTpvStatus = getCurrentTpvStatus(tpv);
  if (
    currentTpvStatus !== TREATMENT_PLAN_VERSION_STATUS.CASE_IN_PLANNING &&
    currentTpvStatus !== TREATMENT_PLAN_VERSION_STATUS.CASE_WITH_ZENYUM &&
    currentTpvStatus !== TREATMENT_PLAN_VERSION_STATUS.CASE_OPS_TPV_CHECK &&
    currentTpvStatus !== TREATMENT_PLAN_VERSION_STATUS.IN_PLANNING
  )
    return true;
};

export const formatPhoneDataForForm: (
  phone?: string,
  defaultCountryCode?: string
) => string = (phone, defaultCountryCode): string => {
  if (phone) {
    if (phone?.includes(' ')) return phone;
    return (defaultCountryCode ? `+${defaultCountryCode}` : '') + ' ' + phone;
  }
  return '';
};

export const getTpsNotInPlanning = (treatmentPlan: TreatmentPlanType[]) => {
  const acceptedTps = treatmentPlan.filter((tp) =>
    tp?.versions?.find((tpv) => NotInPlanning(tpv))
  );
  const tps = acceptedTps.length ? acceptedTps : treatmentPlan;
  return tps;
};

export const getVersionsNotInPlanning = (versions: VersionDetails[]) => {
  return versions?.filter((tpv) => NotInPlanning(tpv));
};

export const splitTextAtIndex = (str: string, index: number): string[] => {
  const result = [str.slice(0, index), str.slice(index)];

  return result;
};

export const getCouncilData = (doctors: TreatmentStaff[]) => {
  const details = doctors?.find(
    (doctor) => doctor.role === USER_ROLES.TREATMENT_PLANNING_DOCTOR
  )?.details;
  return `${details?.firstName || ''} ${details?.lastName || ''}`;
};
export const checkIsSuperAdmin = (groups: string[]) => {
  return isDataExist(groups) && groups.includes(USER_ROLES.SUPER_ADMIN);
};
export const scrollToToothChart = (
  isChecked: boolean,
  toothChartCloseResidualRef: React.RefObject<HTMLDivElement>,
  toothChartResolvePosteriorRef: React.RefObject<HTMLDivElement>,
  selectedOption: string
) => {
  if (selectedOption === REFINEMENT_GOALS.CLOSE_RESIDUAL_SPACES && isChecked) {
    setTimeout(() =>
      toothChartCloseResidualRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center'
      })
    );
  } else if (
    selectedOption === REFINEMENT_GOALS.RESOLVE_POSTERIOR_OPEN_BITE &&
    isChecked
  ) {
    setTimeout(() =>
      toothChartResolvePosteriorRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      })
    );
  }
};

export const getLanguageKey = (languageKey: string) => {
  switch (languageKey) {
    case 'zh-TW':
    case 'mn':
      return 'zh';
    default:
      return languageKey;
  }
};

export const getLanguageLabel = (languageKey: string) => {
  switch (languageKey) {
    case 'zh-TW':
    case 'zh':
      return '中文';
    case 'en':
      return 'EN';
    case 'ja':
      return '日本語';
    case 'th':
      return 'ไทย';
    case 'vi':
      return 'Tiếng Việt';
    case 'id':
      return 'Bahasa Indonesia';
    case 'my':
      return 'Melayu';
    default:
      return languageKey;
  }
};

export const callSegementPage = (title: string, startTime: Date) => {
  const endTime = new Date();
  const duration = endTime.getTime() - startTime.getTime();
  window.analytics?.page(title, {
    path: pagePaths.referrals,
    title: title,
    url: `${window.location.host}${pagePaths.referrals}`,
    duration
  });
};

const getTooltipPosition = (isSmallScreen: boolean): PopoverPosition[] => {
  return isSmallScreen ? ['bottom'] : ['right'];
};

const getFileName = (name: string | undefined) => {
  const fullFileName = name?.split('/').pop();
  const fileName = fullFileName?.replace(/^\d+_/, '');
  if (fileName && fileName.length > 30) {
    const dots = '.....';
    const start = fileName?.slice(0, 6);
    const end = fileName?.slice(-12);
    return `${start}${dots}${end}`;
  }
  return fileName;
};

type StringTransform = (val: string) => string;
const stringPipe =
  (...functions: StringTransform[]) =>
  (arg: string) =>
    functions.reduce((acc, func) => func(acc), arg);

export {
  canvasToBlob,
  findIndex,
  formatRole,
  formatFile,
  getFileKey,
  getRoleName,
  displayName,
  hasMinimumFilesWithExtension,
  isDataExist,
  getTeamName,
  getFileType,
  getFullName,
  getInitials,
  getTopicName,
  formatBranch,
  formatMessage,
  subStringText,
  getDoctorName,
  formatAddress,
  detectBrowser,
  isValidHttpUrl,
  stringPipe,
  getFilterdTPVs,
  updateSaveData,
  getFilteredTps,
  getEntityHours,
  checkIsExternal,
  copyToClipboard,
  isMobileBrowser,
  getPrimaryDoctor,
  segmentPageEvent,
  segmentTrackEvent,
  setToLocalStorage,
  getFromLocalStorage,
  getParticipantCount,
  formatDoctorsForSelect,
  formatEventRenderRange,
  checkIfAppIsAdminClinic,
  getAttatchmentIPRValues,
  filterFilesUpload,
  getNursesAndDoctorsCount,
  capitalizeFirstLetterOnly,
  formatEventRenderRangeArray,
  getDocumentsForSelectedStage,
  referralsTabsTranslateMapper,
  getPatientImageDocuments,
  syncTabs,
  removeItemAtIndex,
  getFileFormat,
  getFileExtension,
  getTooltipPosition,
  getAllOpportunityIds,
  getCombinedFamilies,
  getTreatmentOptions,
  getFileName
};
