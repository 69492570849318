import { FC } from 'react';

import Button from 'components/Button/Button';
import { translate } from 'utils';

interface Props {
  handleResetButtonClick?: () => void;
  handleApplyButtonClick?: () => void;
}

export const MobileFilterFooter: FC<Props> = ({
  handleResetButtonClick,
  handleApplyButtonClick
}) => {
  return (
    <div className='flex space-x-4  w-full justify-between'>
      <Button
        onClick={handleResetButtonClick}
        className='w-1/2 h-12 bg-WHITE text-PRIMARY text-center border-1 border-PRIMARY whitespace-nowrap text-14px'
      >
        {translate('reset.reset')}
      </Button>
      <Button
        onClick={handleApplyButtonClick}
        className='w-1/2 h-12 bg-PRIMARY text-white text-center whitespace-nowrap text-14px'
      >
        {translate('apply.apply')}
      </Button>
    </div>
  );
};
