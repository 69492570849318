import { FC } from 'react';

import { DropdownIcon } from 'assets/icons';
import { If } from 'components/Generics';

interface Props {
  isSorted: boolean;
  isSortedDesc?: boolean;
}
const SortArrow: FC<Props> = ({ isSorted, isSortedDesc }) => {
  return (
    <span className='h-6 w-6 flex items-center justify-center'>
      <If condition={!isSorted}>
        <DropdownIcon className='inline-block h-2 text-CORE_GRAY' />
      </If>
      <If condition={isSorted && !!isSortedDesc}>
        <DropdownIcon className='inline-block h-2 text-DARK_GRAY' />
      </If>
      <If condition={isSorted && !isSortedDesc}>
        <DropdownIcon className='transform rotate-180 inline-block h-2 text-DARK_GRAY' />
      </If>
    </span>
  );
};

export default SortArrow;
