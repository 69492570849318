import { atom } from 'recoil';

import { LoginedUser } from 'types/Login.types';
import { Option } from 'types/select';

import { ATOMS, defaultLoginedDetails } from './constants';

export const loginedUserDetails = atom<LoginedUser>({
  key: ATOMS.LOGINED_USER_DETAILS_ATOM,
  default: defaultLoginedDetails
});

export const checkFireBaseConnection = atom<boolean>({
  key: ATOMS.CHECK_FIREBASE_CONNECTION,
  default: false
});
export const showUnauthChatError = atom<boolean>({
  key: ATOMS.UNAUTHORIZED_ACCESS_TO_CHATSERVICE,
  default: false
});

export const globalSelectedClinic = atom<Option | undefined>({
  key: ATOMS.SELECTED_CLINIC,
  default: undefined
});
export const hideLiveTranslation = atom<boolean>({
  key: ATOMS.HIDE_LIVE_TRANSLATION,
  default: true
});
