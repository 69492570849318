const colors = {
  DARK_BLUE_2: '#2388B5',
  PRIMARY: '#2CAAE2',
  ERROR: '#D91E36',
  SUCCESS: '#136753',
  YELLOW: '#FEF2D8',
  YELLOW_2: '#F0B90F',
  YELLOW_3: '#FFFBF3',
  YELLOW_4: '#FFD73B',
  BROWN: '#775404',
  WHITE: '#FFFFFF',
  DISABLED: '#999999',
  LIGHT_BLUE_9: '#C4FDFD',
  DISABLED_2: '#86868B',
  CHINESE_WHITE: '#E0E0E1',
  PRIMARY_LIGHT: '#E9F3FD',
  LIGHT_GRAY: '#EBEBEB',
  GRAY: '#FAFBFC',
  GRAY3: '#949494',
  GRAY7: '#6F797B',
  GRAY8: '#f5f4f7',
  LIGHT_GRAY_2: '#EEEEEE',
  DEFAULT_TEXT: '#282829',
  DARK_GREEN_2: '#00695B',
  LIGHT_GREEN_2: '#E1F5E4',
  LIGHT_GREEN_3: '#09A490',
  YELLOW_WARNING: '#fffff2',
  LIGHT_BLUE: '#D5EAFF',
  DARK_BLUE: '#0063CC',
  DARK_BLUE_1: '#0D3344',
  DARK_GRAY: '#6E6E73',
  LIGHT_GRAY_3: '#C2C2C2',
  LIGHT_GRAY_4: '#B8B8B5',
  LIGHT_GREY_5: '#CBCBCB',
  LIGHT_GREY_6: '#E5E5E5',
  LIGHT_GREY_7: '#6F6F6F',
  LIGHT_RED: '#F7D2D7',
  LIGHT_RED_1: '#FFE5E5',
  LIGHT_BORDER_RED: '#E60225',
  DARK_BORDER_RED: '#DC334B',
  LIGHT_GREEN: '#CDEDE5',
  LIGHT_BORDER_GREEN: '#A3D9CC',
  PRIMARY_MEDIUM: '#BDE4FA',
  PRIMARY_DARK: '#0D4576',
  ONYX: '#393838',
  BLACK: '#000000',
  APP_BG: 'rgba(250, 251, 252, 0.65)',
  CORE_GRAY: '#D2D2D7',
  LINE_GRAY: '#E6E6E6',
  GHOST_WHITE: '#F8FBFF',
  UPSDELL_RED: '#B3192D',
  BORDER_COLOR: '#EEEEEF',
  FILE_DELETE: 'rgba(40, 40, 41, 0.5)',
  RADIO_BORDER: '#D8D9DB',
  LIGHT_ERROR_RED: '#FFF5F5',
  LIGHT_RED_2: '#FFE3EF',
  LIGHT_BLUE_2: '#E8F0FD',
  LIGHT_BLUE_3: '#CEE4FC',
  LIGHT_BLUE_4: '#F8FCFF',
  LIGHT_BLUE_5: '#F5FAFF',
  LIGHT_BLUE_6: '#EFF4FC',
  BLUE_2: '#2877E2',
  BLUE_600: '#345BB5',
  BLUE_200: '#C6E8F8',
  BLUE_100: '#D4E3F9',
  BORDER_GRAY: '#919293',
  LOADER_BG: 'rgba(40, 40, 41, 0.65)',
  DATE_PICKER_BG: '#F2F3F4',
  SUCCESS_TEXT: '#207E68',
  SUCCESS_ICON_DEFAULT: '#23BF9A',
  BG_COLOR: '#FAFAFA',
  LIGHT_BLUE_BG: '#E9F4FA',
  YELLOW_BG: '#FBEED6',
  LIGHT_YELLOW_1: '#FFF9EC',
  LIGHT_YELLOW_2: '#FEF2D8',
  LIGHT_YELLOW_BG: '#FFFAEE',
  CYAN_BG: '#D8EFF3',
  HOVER_BLUE: '#F1F8FF',
  LIGHT_BLUE_7: '#F4FBFD',
  GRAY4: '#777777',
  LIGHT_BLUE_HEADER: '#E9F6FC',
  LIGHT_YELLOW: '#FFF3C4',
  LIGHT_ORANGE: '#FFE7CF',
  DARK_ORANGE: '#FF890D',
  LIGHT_ROSE: '#FDD2E0',
  GRAY5: '#F5F5F7',
  LIGHT_GRAY8: '#F9F9F9',
  LIGHT_GRAY_DISCLAIMER: '#F8F8F9',
  LIGHT_BLUE_66: '#E6F4FF',
  YELLOW_LIGHT: '#FFFBEB',
  YELLOW_LIGHT_50: '#FFFFF2',
  BLUE_CARD_BORDER: '#5990B1',
  YELLOW_CARD_BORDER: '#CBB48A',
  DARKBLUE_CARD_BORDER: '#71A9B2',
  LIGHT_RED_4: '#82103D',
  DARK_GREEN: '#0E595B',
  BORDER_HALF_WIDTH: 'rgba(153, 153, 153, 0.5)',
  DARK_YELLOW_2: '#FFF1D5',
  DEEP_bLUE: '#EFF4FC',
  LIGHT_RED_3: '#F7D2D7',
  SECONDARY_TEXT: '#6E6E73',
  LINE_COLOR: '#D2D2D7',
  COOL_GREY: '#B1B1B4',
  CONSENT_FORM_APPBAR_BG: 'rgba(250, 250, 250, 0.75)',
  LIGHT_BLUE_1: '#E3EFF3',
  GREY_3: '#9A9A9B',
  DARK_YELLOW: '#FFC048',
  LIGHT_GRAY_FORM_BG: '#F8F8F9',
  SWITCH_BLUE: '#5AC8FA',
  DARKER_BLUE: '#78ADD9',
  GRAY_6: '#F3F3F3',
  LIGHT_BLUE_8: '#CCE8FF',
  BLUE_4: '#1A6688',
  LIGHT_BLUE_BG_2: '#E9F6FC',
  BLUE_800: '#12445A',
  PRIMARY_LIGHT_BLUE: '#008CFF',
  ROYAL_PURPLE: '#4B1C92',
  LAVENDER_MIST: '#EFE5FF',
  LIGHT_BLUE_BG_3: '#005ae2',
  DARK_BLUE_3: '#005AE2',
  SUNBEAM_YELLOW: '#FFD60A',
  LIGHT_BLUE_900: '#003866',
  PINK_RED: '#FFF5F5',
  DARK_YELLOW_3: '#FF7E27',
  PURPLE: '#D3DEF8'
};

const fontSize = {
  '12px': '0.75rem',
  '8px': '0.5rem',
  '10px': ['0.625rem', '1rem'],
  '20px': '1.25rem',
  '24px': '2.4rem',
  '17px': ['1.0625rem', '1.5rem'],
  '16px': '1.6rem',
  '34px': '2.125rem',
  '11px': '0.688rem',
  '14px': '0.875rem',
  '40px': '2.5rem',
  '32px': '2rem',
  '28px': ['1.75rem', '2rem'],
  '148px': '9.25rem',
  4.5: ['4.5rem', '5rem'],
  2: '2rem',
  xl: ['1.25rem', '1.5rem'],
  collapsible: ['1.25rem', '2rem'],
  '2xl': ['1.5rem', '2.5rem'],
  '3xl': ['1.75rem', '2rem'],
  sm: ['0.875rem', '1.5rem']
};

const lineHeight = {
  unset: 'unset',
  '14px': '0.875rem',
  '22px': '1.375rem',
  '56px': '3.7333rem'
};

const opacity = {
  65: '.65'
};

const spacing = {
  60.5: '3.781rem',
  520: '32.5rem',
  19: '4.875rem',
  3.5: '0.875rem',
  '30px': '1.875rem',
  35: '8.75rem',
  36: '9rem',
  '45px': '15rem',
  95: '21.4rem',
  85: '22.25rem',
  100: '32rem',
  '118px': '7.375rem',
  '34px': '2.125rem',
  '35px': '2.188rem',
  '79px': '4.938rem',
  '111px': '6.938rem',
  '6px': '0.375rem',
  '10px': '0.625rem',
  '696px': '43.5rem',
  '335px': '20.9375rem',
  '42px': '2.625rem',
  '299px': '18.688rem',
  '294px': '18.375rem'
};

const borderWidth = {
  0.5: '0.5px',
  1: '1px',
  '12px': '0.75rem'
};

const maxWidth = {
  '312px': '19.5rem',
  '90vw': '90vw',
  '896px': '56rem',
  '335px': '20.938rem',
  '416px': '26rem',
  '496px': '31rem',
  '376px': '23.5rem',
  '600px': '37.5rem',
  '343px': '21.438rem',
  '228px': '14.25rem',
  '280px': '17.5rem'
};

const padding = {
  '1px': '1px',
  '5px': '0.313rem',
  '6px': '0.375rem',
  13: '3.25rem',
  79: '19.75rem',
  '13px': '0.813rem',
  '26px': '1.625rem',
  '18px': '1.125rem',
  '440px': '27.5rem',
  '104px': '6.5rem',
  '181px': '11.313rem',
  '125px': '7.813rem',
  '14px': '0.875rem',
  '230px': '14.375rem',
  '46px': '2.875rem',
  '5.5px': '0.344rem',
  '41px': '2.563',
  '120px': '7.5rem',
  '42px': '2.625rem',
  '17px': '1.063rem',
  '2.5px': '0.156rem',
  '2px': '0.125rem'
};

const maxHeight = {
  '3/4': '75%',
  '60vh': '60vh',
  '25vh': '25vh',
  '50vh': '50vh',
  '352px': '22rem',
  '576px': '576px',
  '430px': '26.875rem',
  '450px': '28.125rem',
  '1024px': '64rem',
  '750px': '46.875rem',
  '428px': '26.75rem',
  '928px': '58rem'
};

const backgroundImage = {
  //'map-placeholder': "url('./assets/images/MapPlaceholderBackground.png')",
};

const minWidth = {
  44: '11rem',
  85: '22.25rem',
  '80px': '5rem',
  60: '15rem',
  90: '25rem',
  '656px': '41rem',
  '135px': '8.438rem',
  '524px': '32.75rem',
  '1/4': '25%',
  '416px': '26rem',
  '372px': '23.25rem',
  '600px': '37.5rem',
  '91px': '5.688rem',
  '350px': '21.875rem',
  '440px': '27.5rem',
  '112px': '7rem',
  '160px': '10rem',
  '199px': '12.438rem'
};

const zIndex = {
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  9: 9,
  21: 21,
  31: 31,
  99: 99,
  1000: 1000,
  55: 55,
  60: 60,
  41: 41,
  42: 42
};

const minHeight = {
  '501px': '31rem',
  '360px': '22.5rem',
  '56px': '3.5rem',
  '1/2': '50%',
  '5/6': '83%',
  '3/4': '75%',
  '40px': '2.5rem',
  '35vh': '35vh',
  '50vh': '50vh',
  '130px': '8.125rem',
  '160px': '10rem',
  screenHeight: 'calc(100vh - 20rem)',
  6: '1.5rem',
  70: '17.5rem',
  '210px': '13.125rem',
  2: '0.125rem'
};

const flex = {
  '48%': '0 0 48%',
  '100%': '0 0 100%',
  '31%': '0 0 31%'
};

const height = {
  '22px': '1.375rem',
  '93px': '5.813rem',
  '251px': '15.688rem',
  '217px': '13.563rem',
  '1px': '1px',
  1.5: '0.375rem',
  44.5: '11.5rem',
  28.5: '7.5rem',
  7.5: '1.875rem',
  47: '11.75rem',
  66: '16.5rem',
  19: '4.75rem',
  '28vh': '28vh',
  '236px': '14.75rem',
  '110px': '6.875rem',
  '155.5px': '9.71875rem',
  '104px': '6.5rem',
  8.5: '8.5rem',
  11.5: '11.5rem',
  fit: 'fit-content',
  4.5: '1.125rem',
  '44px': '2.75rem',
  '144px': '9rem',
  '166px': '10.375rem',
  '48px': '3rem',
  '264px': '16.5rem',
  '160px': '10rem',
  '170px': '10.625rem',
  '5.65px': '0.353125rem',
  '56px': '3.7333rem',
  '248px': '15.5rem',
  '72px': '4.5rem',
  '16.8px': '1.05rem',
  screenHeight: 'calc(100vh - 20rem)',
  collapsibleDrawerHeight: 'calc(100vh - 130px)',
  toothChartHeight: 'calc(100% + 2.25rem)',
  '15px': '0.9375rem',
  '960px': '60rem',
  '750px': '46.875rem',
  '256px': '16rem',
  '428px': '26.75rem',
  '360px': '22.5rem',
  '330px': '20.625rem',
  '164px': '10.25rem',
  '128px': '8rem',
  '1090px': '68.125rem',
  '310px': '19.375rem',
  '130px': '8.125rem',
  70: '17.5rem',
  '47.33px': '2.958rem',
  '120px': '7.5rem',
  86: '21.5rem',
  appBarHeight: '3rem',
  '270px': '16.875rem',
  '152px': '9.5rem',
  '216px': '13.5rem',
  '500px': '31.25rem',
  '367px': '22.938rem',
  '370px': '23.125rem',
  '75vh': '75vh',
  '376px': '23.5rem',
  tpvDetailsCardheight: 'calc(100% - 1rem)',
  '168px': '10.5rem',
  '50px': '3.125rem',
  '95p': '95%',
  '754px': '47.125rem',
  '312px': '19.5rem',
  notesSidePanelHeight: 'calc(100vh - 12rem)',
  mobileDrawerHeight: 'calc(var(--vh, 1vh) * 100);'
};

const width = {
  '6.79px': '0.424rem',
  '126px': '7.875rem',
  '100px': '6.25rem',
  '61px': '3.813rem',
  '93px': '5.813rem',
  '251px': '15.688rem',
  '776px': '48.5rem',
  '406px': '25.375rem',
  '230px': '14.375rem',
  '60px': '3.75rem',
  '658px': '41.125rem',
  '1px': '1px',
  1.5: '0.375rem',
  2.5: '0.625rem',
  44.5: '11.5rem',
  28.5: '7.5rem',
  48.75: '12.75rem',
  53: '13.25rem',
  '155.5px': '9.71875rem',
  7.5: '1.875rem',
  222: '55.5rem',
  '48%': '48%',
  '120px': '7.5rem',
  '160px': '10rem',
  '512px': '32rem',
  164: '41rem',
  '656px': '41rem',
  19: '4.75rem',
  '127.5px': '7.96875rem',
  45: '11.25rem',
  '91.5px': '5.71875rem',
  22: '5.5rem',
  '104px': '6.5rem',
  '370px': '26rem',
  '30%': '30%',
  4.5: '1.125rem',
  '44px': '44px',
  '296px': '18.5rem',
  '327px': '20.438rem',
  '48px': '3rem',
  '184.67px': '11.542rem',
  '264px': '16.5rem',
  81: '21rem',
  '36%': '36%',
  '64%': '64%',
  '47%': '47%',
  '175px': '10.938rem',
  '72px': '4.5rem',
  '356px': '22.25rem',
  '33px': '2.063rem',
  '1200px': '1200px',
  '429px': '429px',
  '475px': '475px',
  '16.8px': '1.05rem',
  '283px': '17.68rem',
  34: '8.5rem',
  '13px': '0.8125rem',
  '29px': '29px',
  '382px': '23.875rem',
  '89px': '5.563rem',
  '785px': '49.063rem',
  '360px': '22.5rem',
  '330px': '20.625rem',
  '719px': '44.938rem',
  '710px': '44.375',
  '737px': '46.063rem',
  '800px': '50rem',
  '199px': '199px',
  '85px': '85px',
  '335px': '20.938rem',
  '600px': '37.5rem',
  '325px': '20.313rem',
  '372px': '23.25rem',
  '703px': '43.938rem',
  '163.5px': '10.219rem',
  '128px': '8rem',
  86: '21.5rem',
  416: '26rem',
  78: '19.5rem',
  '18px': '1.125rem',
  '824px': '51.5rem',
  '343px': '21.438rem',
  '675px': '42.188rem',
  '165px': '10.313rem',
  '39px': '2.43rem',
  '52px': '3.25rem',
  '1058px': '66.125rem',
  '544px': '34rem',
  '248px': '15.5rem',
  '450px': '28.125rem',
  '70px': '4.375rem',
  '149px': '9.3125rem',
  '498px': '31.125rem',
  '750px': '46.875rem',
  '92px': '5.75rem',
  '184px': '11.5rem',
  '652px': '40.75rem',
  '30.2%': '30.2%',
  '69.8%': '69.8%',
  '768px': '48rem',
  '178px': '11.125rem',
  '90px': '5.625rem',
  '54.4%': '54.4%',
  '45.6%': '45.6%',
  '504px': '31.5rem',
  '262px': '16.375rem',
  '404px': '25.25rem',
  '27px': '1.6875rem',
  '85%': '85%',
  '70%': '70%',
  '574px': '574px'
};

const boxShadow = {
  appBar: '0px 1px 4px rgba(107, 119, 140, 0.15)',
  suggestion: '4px 8px 16px rgba(142, 153, 168, 0.25)',
  calendar: '2px 4px 8px rgba(142, 153, 168, 0.25)',
  'calendar-popover': '0 2px 6px rgba(0,0,0,.15)',
  'appointment-details': '2px 4px 8px 0px rgba(142, 153, 168, 0.25)'
};

const borderRadius = {
  '4px': '4px',
  '4xl': '3rem',
  '8px': '0.5rem',
  '12px': '0.75rem',
  '32px': '2rem',
  '48px': '3rem',
  '100px': '6.25rem',
  '10px': '0.625rem',
  '35px': '2.188rem',
  '30px': '1.875rem'
};

const margin = {
  '60px': '3.75rem',
  0.75: '0.1875rem',
  1.5: '0.625rem',
  '0.1rem': '0.1rem',
  '12.67px': '0.791875rem',
  '18px': '1.125rem',
  '26px': '1.635rem',
  '30px': '1.875rem',
  '1px': '1px',
  '17px': '1.0625rem',
  n48: '-3rem',
  n40: '-2.5rem',
  n24: '-1.5rem',
  n30: '-1.875rem',
  18: '4.5rem',
  5.5: '1.375rem',
  7.5: '1.875rem',
  2.5: '0.625rem',
  '18.5px': '1.15625rem',
  '20.5px': '1.28125rem',
  '35px': '35px',
  '23px': '23px',
  '19px': '1.188rem',
  '15.67px': '0.979rem',
  '41px': '2.563rem',
  '25px': '1.563rem',
  '10px': '0.625rem',
  '14px': '0.875rem',
  '6px': '0.375rem',
  appBarHeight: 'var(--appbar-height)',
  '42px': '2.625rem',
  '16px': '1rem',
  '120px': '7.5rem',
  '188px': '11.75rem',
  21.25: '5.3125rem',
  65: '16.25rem',
  13.25: '3.3125rem',
  '22px': '1.375rem',
  n16: '-1rem',
  n32: '-2rem',
  '10.8px': '0.675rem'
};

const topValues = {
  '8px': '0.5rem',
  47: '47%',
  34: '8.1rem'
};

module.exports = {
  fontFamily: {
    body: ['Sofia Pro', 'sans-serif'],
    signature: ['adobe-handwriting-ernie', 'cursive']
  },
  translate: {
    '1.5px': '0.094rem'
  },
  gridColumn: ['last'],
  colors,
  fontSize,
  spacing,
  borderWidth,
  borderRadius,
  maxWidth,
  maxHeight,
  backgroundImage,
  minWidth,
  zIndex,
  margin,
  opacity,
  minHeight,
  flex,
  height,
  boxShadow,
  padding,
  width,
  top: topValues,
  lineHeight
};
