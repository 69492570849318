import { FC, useState } from 'react';

import cx from 'classnames';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil';

import { Add } from 'assets/icons';
import AdvanceSearchModal from 'pages/Inbox/components/Filters/Component/AdvanceSearch/AdvanceSearchModal';
import { loginedUserDetails } from 'store/atoms';
import { inboxFilters, showAddMe, ticketList } from 'store/atoms/inbox';
import { InboxAdvanceSearch, InboxFilters } from 'types/Tickets.types';
import { translate } from 'utils';

const AdvanceSearchButton: FC = () => {
  // global states
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const userDetails = useRecoilValue(loginedUserDetails);
  const resetTicketList = useResetRecoilState(ticketList);
  const setShowAddMe = useSetRecoilState(showAddMe);

  // local states
  const [inboxFilter, setInboxFilter] = useRecoilState(inboxFilters);

  const isFilterApplied = !!inboxFilter.advancedSearch;

  const handleAdvanceSearch = (value: any) => {
    const advanceSearchParam: InboxAdvanceSearch = {
      includeParticipantEmail: value.email
    };
    if (value.includeMe === 'false') {
      advanceSearchParam.excludeParticipantEmail = userDetails.email;
    }
    resetTicketList();
    setInboxFilter((prevFilters: InboxFilters) => {
      return {
        ...prevFilters,
        advancedSearch: advanceSearchParam,
        viewTicketsWithoutMe: value.includeMe === 'false'
      };
    });
    setShowAdvanceSearch(false);
    setShowAddMe(true);
  };

  const handleAdvanceSearchClear = () => {
    const { advancedSearch, viewTicketsWithoutMe } = inboxFilter;
    if (advancedSearch || viewTicketsWithoutMe) {
      setInboxFilter((prevFilters: InboxFilters) => {
        const selectedFilters = { ...prevFilters };
        advancedSearch && delete selectedFilters.advancedSearch;
        viewTicketsWithoutMe && delete selectedFilters.viewTicketsWithoutMe;
        return selectedFilters;
      });
      setShowAdvanceSearch(false);
    }
  };

  const openPopup = () => {
    setShowAdvanceSearch(true);
  };

  return (
    <>
      <button className='w-full' onClick={openPopup}>
        <div
          className={cx(
            'text-sm px-4 py-2 flex justify-between items-center w-full',
            {
              'bg-LIGHT_BLUE_900 text-white rounded-md': isFilterApplied,
              'text-DEFAULT_TEXT': !isFilterApplied
            }
          )}
        >
          <p>{translate('advance.search')}</p>
          <span>
            <Add
              className={cx('w-4 h-4', {
                'text-white': isFilterApplied,
                'text-DEFAULT_TEXT': !isFilterApplied
              })}
            />
          </span>
        </div>
      </button>
      {showAdvanceSearch && (
        <AdvanceSearchModal
          isOpen={showAdvanceSearch}
          selectedFilters={inboxFilter}
          handleAdvanceSearchClear={handleAdvanceSearchClear}
          setIsOpen={(status) => setShowAdvanceSearch(status)}
          handleAdvanceSearch={handleAdvanceSearch}
        />
      )}
    </>
  );
};

export default AdvanceSearchButton;
