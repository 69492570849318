import { FC, ReactNode } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FormError } from 'components/FormError/FormError';
import { If } from 'components/Generics';
import { customTwMerge as twMerge } from 'utils';

import Checkbox from './Checkbox';

interface RhfInputProps {
  name: string;
  defaultValue?: boolean;
  className?: string;
  label?: string | ReactNode;
  disabled?: boolean;
  labelClassName?: string;
  hideError?: boolean;
  isRequired?: boolean;
}

const RhfCheckbox: FC<RhfInputProps> = ({
  name = '',
  defaultValue = false,
  className = '',
  label,
  disabled = false,
  labelClassName = '',
  hideError = false,
  isRequired = false
}) => {
  const { control } = useFormContext();
  return (
    <div className={`${className} `}>
      <Controller
        name={name}
        render={({ field, fieldState: { error } }) => (
          <>
            <label className='py-2 flex' role='presentation'>
              <Checkbox
                data-testid='checkbox'
                id={name}
                name={name}
                onChange={(event) => {
                  const checked = event.target.checked;
                  field.onChange({
                    target: { name: field.name, value: checked }
                  });
                }}
                checked={field.value}
                disabled={disabled}
              />
              <span className={twMerge('pt-0.5', labelClassName)}>
                {label}
                <If condition={isRequired}>
                  <span className='text-DARK_BORDER_RED'>*</span>
                </If>
              </span>
            </label>
            <If condition={!!error?.message && !hideError}>
              <FormError errorMessage={error?.message} />
            </If>
          </>
        )}
        control={control}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default RhfCheckbox;
