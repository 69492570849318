import { FC } from 'react';

interface ActionOptionContainerProps {
  isExpanded: boolean;
  children: React.ReactNode;
  toggleExpansion(): void;
}

const ActionOptionContainer: FC<ActionOptionContainerProps> = ({
  children,
  isExpanded,
  toggleExpansion
}) => {
  if (!isExpanded) return null;
  return (
    <>
      <div className='absolute bottom-0 translate-y-full z-20'>
        <div className='rounded-2xl md:rounded-md bg-BG_COLOR md:bg-white mt-3 w-[19.625rem] shadow-calendar'>
          {children}
        </div>
      </div>

      <div
        className='fixed inset-0 z-1'
        role='button'
        tabIndex={0}
        onKeyDown={toggleExpansion}
        onClick={toggleExpansion}
      />
    </>
  );
};

export default ActionOptionContainer;
