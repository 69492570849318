import { FC, Suspense, useState, useEffect } from 'react';

import { useApolloClient } from '@apollo/client';
import { changeLanguage } from 'i18next';
import moment from 'moment';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState, useResetRecoilState } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import chatClient from 'chatSDK';
import {
  AppBar,
  Loader,
  Navbar,
  UnauthorizedError,
  IntercomProvider
} from 'components';
import { ClinicSelectorPopup } from 'components/ClinicSelectorPopup/ClinicSelectorPopup';
import { pages, pagePaths } from 'config/pages';
import { LOGIN_ERRORS } from 'constants/auth';
import {
  CLINIC_SWAP_CONSTANTS,
  LIVE_TRANSLATION_HIDDEN_COUNTRIES,
  MOBILE_SCREEN_WIDTH,
  MULTI_TAB_SYNC_STORAGE_KEY,
  USER_ROLES
} from 'constants/common';
import { SUPPORTED_LANG } from 'constants/userSettings';
import { CanAccess } from 'hoc/can-access/CanAccess';
import { useIsChatAuthenticated, useIsLoggedIn } from 'services/auth/authToken';
import { GetLoggedInUserDetails } from 'services/hooks/Auth.hooks';
import { getDeviceType } from 'services/hooks/Segment.hooks';
import { useGetUserPreference } from 'services/hooks/TemplateSettings.hooks';
import { loginedUserDetails, patientListFilters } from 'store/atoms';
import {
  checkFireBaseConnection,
  globalSelectedClinic,
  showUnauthChatError,
  hideLiveTranslation
} from 'store/atoms/authAtom';
import { defaultLoginedDetails } from 'store/atoms/constants';
import {
  checkNewBracesTemplatizedPrescriptionEnabled,
  checkNewRefinementTemplatizedPrescriptionEnabled
} from 'store/atoms/patientListAtom';
import { cachedRoute } from 'store/atoms/routeAtom';
import { translate, useLogout, useViewport } from 'utils';
import { checkIsExternal, detectBrowser, getFullName } from 'utils/common';
import { useChangeClinic } from 'utils/customHooks/useChangeClinic';
import { useQueryParams } from 'utils/customHooks/useQueryParams';
import { getCurrentDateIso } from 'utils/dateUtils';

const PrivateRoute: FC = () => {
  const [selectClinic] = useChangeClinic();
  const [showSideNav, setshowSideNav] = useState(false);
  const [showClinicSelectorPopup, setClinicSelectorPopup] = useState(false);
  const location = useLocation();
  const { width } = useViewport();
  const [isLoggedIn] = useIsLoggedIn();
  const { isChatAuthenticated } = useIsChatAuthenticated();
  const [userDetails, setLoginedUserDetails] =
    useRecoilState(loginedUserDetails);
  const setHideLiveTranslation = useSetRecoilState(hideLiveTranslation);
  const setNewBracesTemplatizedPrescriptionEnabled = useSetRecoilState(
    checkNewBracesTemplatizedPrescriptionEnabled
  );
  const setNewRefinementTemplatizedPrescriptionEnabled = useSetRecoilState(
    checkNewRefinementTemplatizedPrescriptionEnabled
  );
  const setFirebaseConfig = useSetRecoilState(checkFireBaseConnection);
  const setShowUnAuthChatModal = useSetRecoilState(showUnauthChatError);
  const resetFirebaseConfig = useResetRecoilState(checkFireBaseConnection);
  const chat = chatClient.getChatClient();
  const setCachedRoute = useSetRecoilState(cachedRoute);
  const onError = () => logout();
  const { data, loading } = GetLoggedInUserDetails({
    onError,
    notifyOnNetworkStatusChange: true,
    skip: !isLoggedIn,
    variables: {
      date: getCurrentDateIso()
    }
  });
  const logout = useLogout();
  const [selectedClinic, setClinic] = useRecoilState(globalSelectedClinic);
  const queryParams = useQueryParams();
  const clickSource = queryParams.get('clickSource');
  const clinicId = queryParams.get('cid');

  useGetUserPreference({
    variables: { name: 'TEMPLATIZED_PRESCRIPTION' },
    fetchPolicy: 'network-only',
    skip: !isLoggedIn,
    onCompleted: (data) => {
      setNewRefinementTemplatizedPrescriptionEnabled(
        data?.getUserPreference?.preference
          ?.refinementTemplatizedPrescriptionEnabled
      );
      setNewBracesTemplatizedPrescriptionEnabled(
        data?.getUserPreference?.preference
          ?.bracesTemplatizedPrescriptionEnabled
      );
    }
  });
  const checkChatAuth = (error: any) => {
    if (
      error.response?.errors?.[0].message === LOGIN_ERRORS.UNAUTHORIZED_ACCESS
    ) {
      setShowUnAuthChatModal(true);
    }
  };

  const [selectedPatientListFilters, setPatientListFilters] =
    useRecoilState(patientListFilters);

  useEffect(() => {
    if (data?.getLoggedInUserDetails) {
      if (
        !selectedPatientListFilters?.council?.length &&
        !data?.getLoggedInUserDetails.groups.includes(USER_ROLES.SUPER_ADMIN)
      ) {
        // Initializing council filter to logged in user if no other filter is selected for the patient list.

        setPatientListFilters((filters) => ({
          ...filters,
          council: [
            {
              value: data?.getLoggedInUserDetails?.id,
              label: getFullName({
                firstName: data?.getLoggedInUserDetails?.firstName,
                lastName: data?.getLoggedInUserDetails?.lastName
              })
            }
          ]
        }));
      }
      setLoginedUserDetails(data?.getLoggedInUserDetails);
      setHideLiveTranslation(
        LIVE_TRANSLATION_HIDDEN_COUNTRIES.includes(
          data?.getLoggedInUserDetails?.country || ''
        )
      );
      if (data?.getLoggedInUserDetails?.headClinicId) {
        const clinic = data?.getLoggedInUserDetails?.headClinics?.find(
          (clinic) =>
            clinic?.value === data?.getLoggedInUserDetails?.headClinicId
        );
        setClinic({ value: clinic?.value, label: clinic?.label });
      } else if (
        checkIsExternal(data?.getLoggedInUserDetails?.groups) &&
        data?.getLoggedInUserDetails?.headClinics?.length > 1
      ) {
        if (
          clickSource == CLINIC_SWAP_CONSTANTS.EMAIL &&
          clinicId &&
          window.location.pathname.substring(0, 5) ===
            CLINIC_SWAP_CONSTANTS.PATH
        ) {
          selectClinic({
            variables: {
              clinicId: clinicId
            }
          });
        } else {
          setClinicSelectorPopup(!!data?.getLoggedInUserDetails?.id);
        }
      }

      const {
        firstName,
        lastName,
        email,
        clinic,
        country,
        groups,
        phone,
        id,
        username
      } = data?.getLoggedInUserDetails || {};
      const name = getFullName({ firstName, lastName });
      window.analytics.identify(id, {
        name,
        email,
        clinic: clinic?.filter((item) => !item?.headClinicId)?.[0]?.name || '',
        branch:
          clinic
            ?.filter((item) => item?.headClinicId)
            ?.map(({ name }) => name)
            ?.join(', ') || '',
        country,
        role: groups?.join(', '),
        device_type: getDeviceType(),
        browser: detectBrowser(),
        phone_no: phone,
        user_id: id,
        username,
        user_type: checkIsExternal(groups) ? 'external' : 'internal'
      });
      const lang = SUPPORTED_LANG.includes(
        data?.getLoggedInUserDetails?.language
      )
        ? data?.getLoggedInUserDetails?.language
        : 'en';
      changeLanguage(lang);
      moment?.locale(lang);
    }
    return () => {
      setLoginedUserDetails(defaultLoginedDetails);
    };
  }, [data, setLoginedUserDetails]);

  useEffect(() => {
    if (width < 768 && showSideNav) {
      setshowSideNav(false);
    } else if (width > 768 && !showSideNav) {
      setshowSideNav(true);
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn && isChatAuthenticated) {
      chat.connectToSocket(
        () => {
          setFirebaseConfig(true);
        },
        (error: any) => {
          checkChatAuth(error);
          setFirebaseConfig(false);
        }
      );
      const streamId = localStorage.getItem('streamId');
      if (!streamId) {
        localStorage.setItem('streamId', uuidv4());
      }
    }
    return () => {
      resetFirebaseConfig();
    };
  }, [isChatAuthenticated]);

  /**
   * @description
   * useeffect used to close the navbar on selection in the mobile view
   */
  useEffect(() => {
    if (width < 768 && showSideNav) {
      setshowSideNav(false);
    }
  }, [location]);

  const client = useApolloClient();

  const onStorageUpdate = (e: any) => {
    const { key } = e;
    if (key === MULTI_TAB_SYNC_STORAGE_KEY) {
      client?.refetchQueries({ include: 'active' });
    }
  };

  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);

  useEffect(() => {
    if (selectedClinic?.value) setClinicSelectorPopup(false);
  }, [selectedClinic?.value]);

  if (!isLoggedIn) {
    setCachedRoute(
      location.pathname.includes(pagePaths.logout)
        ? undefined
        : location?.pathname + location?.search
    ); // Cache the initial  route incase the user is not logged in
    return <Navigate to={pagePaths.login} />;
  }

  return (
    <IntercomProvider>
      <div className='max-h-screen w-screen' id='mobile-modal-container'>
        <AppBar handleMenuClick={() => setshowSideNav(!showSideNav)} />
        <div className='h-screen min-w-full flex'>
          <Navbar setIsOpen={setshowSideNav} isOpen={showSideNav} />
          <div
            className='flex-1 mt-14 relative  overflow-auto'
            id='modal-container'
          >
            <div className='private-route overflow-auto h-full w-full'>
              <UnauthorizedError />
              {!userDetails?.id || showClinicSelectorPopup || loading ? (
                <Loader fullPage={width >= MOBILE_SCREEN_WIDTH} />
              ) : (
                <Suspense fallback={<div>{translate('loading')}...</div>}>
                  <Routes>
                    {pages.map((page) => {
                      return (
                        <Route
                          key={page?.title}
                          path={page.path}
                          element={
                            <CanAccess
                              permission={page?.permission}
                              yes={page?.Component}
                              no={<Navigate to={page?.redirectTo || '/'} />}
                            />
                          }
                        />
                      );
                    })}
                  </Routes>
                </Suspense>
              )}
            </div>
          </div>
        </div>
        <ClinicSelectorPopup
          open={showClinicSelectorPopup}
          onClose={() => setClinicSelectorPopup(false)}
        />
      </div>
    </IntercomProvider>
  );
};

export default PrivateRoute;
