import { FC } from 'react';

import { useFormContext } from 'react-hook-form';

import {
  ALLOWED_FILE_MIME_TYPE,
  ALLOWED_PHOTO_MIME_TYPE
} from 'constants/common';
import RhfReferralFileUploader from 'containers/ReferralFileUploader/RhfReferralFileUploader/RhfReferralFileUploader';
import { customTwMerge, translate } from 'utils';

type PatientFilesType = {
  referralId: string;
  className?: string;
  disableEdit?: boolean;
};
const PatientFiles: FC<PatientFilesType> = ({
  referralId,
  className,
  disableEdit
}) => {
  const { watch } = useFormContext();
  const images = watch('image') || [];
  const consentForm = watch('consentForm') || [];
  const xrays = watch('xray') || [];
  const scans = watch('scan') || [];
  const allFiles = [...images, ...consentForm, ...xrays, ...scans];
  return (
    <div className={customTwMerge('bg-white', className)}>
      <div>
        <RhfReferralFileUploader
          title={translate('photos.photos')}
          referralId={referralId}
          name='image'
          fileDropAreaTitle='image'
          referralDocumentType='PHOTO'
          className='mb-6 w-full'
          allowedFileMimeType={ALLOWED_PHOTO_MIME_TYPE}
          allFiles={allFiles}
          disableEdit={disableEdit}
        />
      </div>
      <div>
        <RhfReferralFileUploader
          title={translate('consent_form.consent_form')}
          referralId={referralId}
          name='consentForm'
          allowedFileMimeType={ALLOWED_FILE_MIME_TYPE}
          fileDropAreaTitle='consent'
          referralDocumentType='CONSENT_FORM'
          className='mb-6 w-full'
          allFiles={allFiles}
          disableEdit={disableEdit}
        />
      </div>
      <div>
        <RhfReferralFileUploader
          title={translate('x-rays.x-rays')}
          referralId={referralId}
          name='xray'
          fileDropAreaTitle='X-rays'
          referralDocumentType='X_RAY'
          className='mb-6 w-full'
          allFiles={allFiles}
          disableEdit={disableEdit}
        />
      </div>
      <div>
        <RhfReferralFileUploader
          title={translate('title.scan')}
          name='scan'
          referralId={referralId}
          fileDropAreaTitle='scans'
          referralDocumentType='SCAN'
          className='w-full'
          allFiles={allFiles}
          disableEdit={disableEdit}
        />
      </div>
    </div>
  );
};

export default PatientFiles;
