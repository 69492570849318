import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { MenuPlacement } from 'react-select';

import { PhoneNumberInput } from './PhoneNumberInput';

type Props = {
  name: string;
  placeholder?: string;
  defaultCountryCode?: string;
  label?: string;
  textInputclassName?: string;
  selectheight?: string;
  selectControlStyles?: any;
  disabled?: boolean;
  selectMenuPlacement?: MenuPlacement;
  menuListMaxHeight?: string;
};

export const RHFPhoneNumberInput: FC<Props> = ({
  name,
  placeholder,
  defaultCountryCode,
  label,
  selectControlStyles,
  selectheight,
  textInputclassName,
  disabled = false,
  selectMenuPlacement,
  menuListMaxHeight
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <div>
          <label>
            <span className='mb-2 text-xs md:text-sm'>{label}</span>
            <PhoneNumberInput
              value={field?.value}
              onChange={(newValue) =>
                field?.onChange({ target: { value: newValue } })
              }
              errorMessage={error?.message}
              placeHolder={placeholder}
              defaultCountryCode={defaultCountryCode}
              selectControlStyles={selectControlStyles}
              selectheight={selectheight}
              textInputclassName={textInputclassName}
              disabled={disabled}
              selectMenuPlacement={selectMenuPlacement}
              menuListMaxHeight={menuListMaxHeight}
            />
          </label>
        </div>
      )}
    />
  );
};
