import { EventClickArg } from '@fullcalendar/common';
import moment from 'moment';

import {
  ALL_APPOINTMENT_COLORS,
  APPOINTMENT_TYPES
} from 'constants/calendarConstants';
import { USER_ROLES } from 'constants/common';
import { EVENT_DETAILS_ACTIONS } from 'constants/options';
import { SlotData } from 'types/Appointment.types';
import {
  Appointment,
  AppointmentDetails,
  Event,
  ParticipantData
} from 'types/Calendar.types';

import { checkIfAppIsAdminClinic, getFullName } from './common';
import { formatTimeRange } from './dateUtils';

const shouldShowAppointment = (statusCode?: string) => {
  const isClinicCloud = !checkIfAppIsAdminClinic();
  return !(
    statusCode === EVENT_DETAILS_ACTIONS.RESCHEDULE ||
    statusCode === EVENT_DETAILS_ACTIONS.TO_BE_SCHEDULED ||
    statusCode === EVENT_DETAILS_ACTIONS.CANCELLED ||
    (isClinicCloud && statusCode === EVENT_DETAILS_ACTIONS.CLOSED_LOST)
  );
};

const getApptColor = (appointment: Appointment) => {
  const { statusInfo } = appointment;
  return statusInfo?.statusCode === EVENT_DETAILS_ACTIONS.NO_SHOW
    ? ALL_APPOINTMENT_COLORS.NO_SHOW_BG
    : ALL_APPOINTMENT_COLORS[appointment?.type?.name] ||
        ALL_APPOINTMENT_COLORS.DEFAULT;
};

export const formatAppointments = (
  appointments?: Appointment[],
  customViewSelectEvent?: (event: Event) => void,
  showBorderColor?: boolean,
  isFromAppointments?: boolean
): any => {
  const doctorColors: { [id: string]: string } = {};
  const getDoctorColors = (id: string) => {
    if (doctorColors[id]) return doctorColors[id];
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    doctorColors[id] = randomColor;
    return randomColor;
  };
  const formattedAppointments: any = [];
  appointments?.forEach((appointment: Appointment) => {
    const { statusInfo } = appointment;
    if (shouldShowAppointment(statusInfo?.statusCode)) {
      const patient = appointment?.participants?.find(
        (participant) => participant?.role === USER_ROLES?.PATIENT
      );
      const doctor = appointment?.participants?.find(
        (participant) => participant?.role === USER_ROLES.EXTERNAL_DOCTOR
      );
      const startDate = new Date(appointment?.startTime);
      const endDate = new Date(appointment?.endTime);
      const allDay =
        startDate.getHours() === 0 &&
        startDate.getMinutes() === 0 &&
        endDate.getHours() === 23 &&
        endDate.getMinutes() === 59; // Check if an event is an all day event
      let borderColor = '';
      let className = '';
      if (showBorderColor)
        borderColor = getDoctorColors(doctor?.user?.id || '');
      if (isFromAppointments) {
        className = appointment?.expireAt
          ? 'appointment pending-appointment'
          : 'appointment';
      } else if (appointment?.expireAt) {
        className = 'pending-appointment';
      }
      if (statusInfo?.statusCode === EVENT_DETAILS_ACTIONS.NO_SHOW)
        borderColor = ALL_APPOINTMENT_COLORS.NO_SHOW_TEXT;
      formattedAppointments?.push({
        title: appointment?.title,
        id: appointment?.id,
        patient: {
          ...patient?.user,
          name: getFullName({
            firstName: patient?.user?.firstName,
            lastName: patient?.user?.lastName
          })
        },
        status: statusInfo?.statusCode,
        start: appointment?.startTime,
        end: appointment?.endTime,
        appointmentStart: appointment?.startTime,
        appointmentEnd: appointment?.endTime,
        doctor: {
          ...doctor?.user,
          name: getFullName({
            firstName: doctor?.user?.firstName,
            lastName: doctor?.user?.lastName
          })
        },
        participants: appointment?.participants?.map((participant) => ({
          id: participant?.user?.id,
          name: getFullName({
            firstName: participant?.user?.firstName,
            lastName: participant?.user?.lastName
          })
        })),
        color: getApptColor(appointment),
        textColor:
          statusInfo?.statusCode === EVENT_DETAILS_ACTIONS.NO_SHOW
            ? ALL_APPOINTMENT_COLORS.NO_SHOW_TEXT
            : '#282829',
        resourceId: doctor?.user?.id,
        borderColor,
        onSelectEvent: customViewSelectEvent,
        type: appointment?.type,
        clinic: { name: appointment?.location, id: '' },
        description: appointment?.description,
        allDay,
        expireAt: appointment?.expireAt,
        isClosed: appointment?.isClosed,
        className,
        treatmentId: appointment?.treatment?.id,
        opportunityId: appointment?.treatment?.details?.opportunityId,
        opportunityName: appointment?.treatment?.type,
        treatmentStage: appointment?.treatment?.stage,
        treatmentStatus: statusInfo?.statusCode,
        wowEnabled: appointment?.wowEnabled,
        treatmentAppointmentId: appointment?.treatmentAppointmentId,
        cpApptId: appointment?.cpAppointmentId
      });
    }
  });
  return formattedAppointments;
};

const getTitleForAfterSalesAppt = (
  appointment: AppointmentDetails,
  patient: ParticipantData | undefined
) => {
  return (
    patient?.name +
    ' - ' +
    APPOINTMENT_TYPES[appointment?.appointmentTypeLabel] +
    ' - ' +
    moment(appointment?.startTime).format('YYYY-MM-DD') +
    ' - ' +
    appointment?.doctorClinic
  );
};

const getColors = (
  appointment: AppointmentDetails,
  appointmentStatus: string
) => {
  return {
    color:
      appointmentStatus === EVENT_DETAILS_ACTIONS.NO_SHOW
        ? ALL_APPOINTMENT_COLORS.NO_SHOW_BG
        : ALL_APPOINTMENT_COLORS[appointment?.appointmentTypeLabel] ||
          ALL_APPOINTMENT_COLORS.DEFAULT,
    textColor:
      appointmentStatus === EVENT_DETAILS_ACTIONS.NO_SHOW
        ? ALL_APPOINTMENT_COLORS.NO_SHOW_TEXT
        : '#282829'
  };
};

export const formatAllAppointments = (
  appointments?: AppointmentDetails[],
  customViewSelectEvent?: (event: Event) => void,
  showBorderColor?: boolean,
  isFromAppointments?: boolean
): any => {
  const doctorColors: { [id: string]: string } = {};
  const getDoctorColors = (id: string) => {
    if (doctorColors[id]) return doctorColors[id];
    const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
    doctorColors[id] = randomColor;
    return randomColor;
  };
  const formattedAppointments: any = [];
  appointments?.forEach((appointment: AppointmentDetails) => {
    const { appointmentStatus } = appointment;
    if (shouldShowAppointment(appointmentStatus)) {
      const patient = appointment?.participants?.find(
        (participant) => participant?.role === USER_ROLES?.PATIENT
      );
      const doctor = appointment?.participants?.find(
        (participant) => participant?.role === USER_ROLES.EXTERNAL_DOCTOR
      );
      const startDate = new Date(appointment?.startTime);
      const endDate = new Date(appointment?.endTime);
      const allDay =
        startDate.getHours() === 0 &&
        startDate.getMinutes() === 0 &&
        endDate.getHours() === 23 &&
        endDate.getMinutes() === 59; // Check if an event is an all day event
      let borderColor = '';
      let className = '';
      if (showBorderColor) borderColor = getDoctorColors(doctor?.id || '');
      if (isFromAppointments) {
        className = appointment?.expireAt
          ? 'appointment pending-appointment'
          : 'appointment';
      } else if (appointment?.expireAt) {
        className = 'pending-appointment';
      }
      if (appointmentStatus === EVENT_DETAILS_ACTIONS.NO_SHOW)
        borderColor = ALL_APPOINTMENT_COLORS.NO_SHOW_TEXT;
      const { color, textColor } = getColors(appointment, appointmentStatus);
      formattedAppointments?.push({
        title:
          appointment?.title || getTitleForAfterSalesAppt(appointment, patient),
        id: appointment?.appointmentId,
        patient,
        status: appointmentStatus,
        start: appointment?.startTime,
        end: appointment?.endTime,
        appointmentStart: appointment?.startTime,
        appointmentEnd: appointment?.endTime,
        appointment_outcome: appointment?.appointment_outcome,
        doctor,
        participants: appointment?.participants?.map((participant) => ({
          id: participant?.id,
          name: participant?.name
        })),
        color,
        textColor,
        resourceId: doctor?.salesforceId,
        borderColor,
        onSelectEvent: customViewSelectEvent,
        type: { name: appointment?.appointmentTypeLabel, id: '' },
        clinic: {
          name: appointment?.doctorClinic,
          id: appointment?.clinicSfId
        },
        description: appointment?.description,
        allDay,
        expireAt: appointment?.expireAt,
        isClosed: appointment?.isClosed,
        className,
        treatmentId: appointment?.treatmentId,
        opportunityId: appointment?.opportunityId,
        opportunityName: appointment?.treatmentType,
        treatmentStatus: appointmentStatus,
        wowEnabled: appointment?.wowEnabled,
        treatmentAppointmentId: appointment?.treatmentAppointmentId,
        cpApptId: appointment?.appointmentId,
        isRescheduled: appointment?.isRescheduled,
        appointmentAction: appointment?.appointmentAction,
        expectedApptDate: appointment?.expectedDate,
        actualApptDate: appointment?.actualDate
      });
    }
  });
  return formattedAppointments;
};

export const formatAppt = (appt: AppointmentDetails) => {
  const timeText = `${formatTimeRange(appt?.startTime)} - ${formatTimeRange(
    appt?.endTime
  )}`;
  const { appointmentStatus } = appt;
  const patient = appt?.participants?.find(
    (participant) => participant?.role === USER_ROLES?.PATIENT
  );
  const color =
    appointmentStatus === EVENT_DETAILS_ACTIONS.NO_SHOW
      ? ALL_APPOINTMENT_COLORS.NO_SHOW_BG
      : ALL_APPOINTMENT_COLORS[appt?.appointmentTypeLabel] ||
        ALL_APPOINTMENT_COLORS.DEFAULT;
  return {
    color,
    timeText,
    title: appt?.title || getTitleForAfterSalesAppt(appt, patient)
  };
};

export const getDefaultRange = () => {
  const start = new Date();
  start.setHours(0, 0, 0, 0);
  const end = new Date();
  end.setHours(23, 59, 59, 999);
  return { start: start.toISOString(), end: end.toISOString() };
};

export const formatSelectedEvent: (event: EventClickArg) => Event = ({
  event: { _def }
}) => ({
  id: _def?.publicId,
  title: _def.title,
  patient: _def.extendedProps?.patient,
  doctor: _def.extendedProps?.doctor,
  start: _def.extendedProps?.appointmentStart,
  end: _def.extendedProps?.appointmentEnd,
  color: _def.ui.backgroundColor,
  textColor: _def.ui.textColor,
  type: _def?.extendedProps?.type,
  clinic: _def.extendedProps?.clinic,
  description: _def?.extendedProps?.description,
  participants: _def?.extendedProps?.participants,
  status: _def?.extendedProps?.status,
  allDay: _def?.allDay,
  treatmentId: _def?.extendedProps?.treatmentId,
  opportunityName: _def.extendedProps?.opportunityName,
  opportunityId: _def.extendedProps?.opportunityId,
  treatmentStage: _def.extendedProps?.treatmentStage,
  treatmentStatus: _def.extendedProps?.treatmentStatus,
  expireAt: _def.extendedProps?.expireAt,
  isBackground: _def.extendedProps?.isBackground,
  isClosed: _def.extendedProps?.isClosed,
  wowEnabled: _def.extendedProps?.wowEnabled,
  treatmentAppointmentId: _def.extendedProps?.treatmentAppointmentId,
  isRescheduled: _def.extendedProps?.isRescheduled,
  appointmentAction: _def.extendedProps?.appointmentAction,
  expectedApptDate: _def.extendedProps?.expectedApptDate,
  actualApptDate: _def.extendedProps?.actualApptDate,
  appointment_outcome: _def.extendedProps?.appointment_outcome
});

export const formatHighlightedSlot = (slots: SlotData[]) => {
  return slots.map((slot) => {
    return {
      start: slot.startTime,
      end: slot.endTime,
      display: 'background',
      className: 'preferred-slot',
      backgroundColor: '#282829',
      isBackground: true
    };
  });
};

export const getTimeDiff = ({
  start,
  end
}: {
  start: Date;
  end: Date;
}): string => {
  const diff = moment(end).diff(start, 'hours', true);
  const hourDiff = Math.floor(diff);
  const minsDiff = Math.floor((diff % 1) * 60);
  let timeDiff = '';
  if (hourDiff >= 1)
    timeDiff = `${hourDiff} ${hourDiff > 1 ? 'hours' : 'hour'}`;
  if (minsDiff) {
    timeDiff = `${timeDiff} ${timeDiff ? ' ' : ''}${minsDiff} ${
      minsDiff > 1 ? 'minutes' : 'minute'
    }`;
  }
  return timeDiff;
};
