import { useState } from 'react';

import {
  GetChatGalleryOutput,
  ChatGallery as ChatGalleryPhotos
  // eslint-disable-next-line import/no-unresolved
} from '@chat/chat-sdk/dist/types';

import chatClient from 'chatSDK';
import { ENTITY_TYPE, MEDIA_TYPE } from 'constants/inbox';
import { translate } from 'utils';
import { showToast } from 'utils/toast/toast.util';

export const useChatGallery = (chatId: number) => {
  const [photos, setPhotos] = useState<ChatGalleryPhotos[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const chat = chatClient.getChatClient();

  const getParams = () => {
    const offset = photos.length;

    return {
      entity_id: chatId.toString(),
      entity_type: ENTITY_TYPE.TICKET,
      paginationInfo: {
        offset,
        limit: 20
      },
      media_types: MEDIA_TYPE.IMAGE
    };
  };

  const onSuccess = (data: GetChatGalleryOutput) => {
    setIsLoading(false);
    const { chatGallery = [], paginationInfo = {} } = data;
    setTotalCount(paginationInfo?.totalCount || 0);
    setPhotos((prev) => [...prev, ...chatGallery]);
  };

  const onError = () => {
    setIsLoading(false);
    showToast(translate('ERROR.DEFAULT_ERROR'), false);
  };

  const next = () => {
    const variables = getParams();
    setIsLoading(true);
    chat.getChatGallery(variables, onSuccess, onError);
  };

  const onClose = () => {
    setPhotos([]);
  };

  return { photos, isLoading, next, onClose, totalCount };
};
