import { FC } from 'react';

interface DropdownOptionProps {
  label: string;
  timeText: string;
  onClick: () => void;
}

const DropdownOption: FC<DropdownOptionProps> = ({
  label,
  timeText,
  onClick
}) => (
  <button
    onClick={onClick}
    className='text-base px-4 py-3 flex rounded-lg justify-between hover:bg-PRIMARY hover:text-white'
  >
    <p>{label}</p>
    <p>{timeText}</p>
  </button>
);

export default DropdownOption;
