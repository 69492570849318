import { pagePaths } from 'config/pages';
import { ReferralSteps, REFERRALS_STATUS } from 'types/Referrals.types';
import { Option } from 'types/select';

const REFERRALS_STEPS = {
  PERSONAL_INFO: 'PERSONAL_DETAILS',
  TREATMENT_SPEC: 'TREATMENT_SPECIFICATIONS',
  PATIENT_FILE: 'PATIENT_FILES',
  REVIEW_SUMMARY: 'REVIEW_SUMMARY'
};
const REFERRAL_STATUS = {
  INCOMPLETE: 'INCOMPLETE'
};
const REFERRALS_PROGRESS_STEPS: Record<string, ReferralSteps> = {
  [REFERRALS_STEPS.PERSONAL_INFO]: {
    label: 'personal.information',
    state: REFERRALS_STATUS.ACTIVE,
    key: REFERRALS_STEPS.PERSONAL_INFO
  },
  [REFERRALS_STEPS.TREATMENT_SPEC]: {
    label: 'treatment.specification',
    state: REFERRALS_STATUS.DISABLED,
    key: REFERRALS_STEPS.TREATMENT_SPEC
  },
  [REFERRALS_STEPS.PATIENT_FILE]: {
    label: 'patient.files',
    state: REFERRALS_STATUS.DISABLED,
    key: REFERRALS_STEPS.PATIENT_FILE
  },
  [REFERRALS_STEPS.REVIEW_SUMMARY]: {
    label: 'review.summary',
    state: REFERRALS_STATUS.DISABLED,
    key: REFERRALS_STEPS.REVIEW_SUMMARY
  }
};
const TEETH_EXTRACTION_INSTRUCTION: Option[] = [
  {
    value: 'NO_TEETH_TO_EXTRACT',
    label: 'prescriptions.no_teeth_to_extract'
  },
  {
    value: 'TEETH_TO_EXTRACT',
    label: 'prescriptions.teeth_to_extract'
  }
];

const STATUS: Record<string, REFERRALS_STATUS> = {
  COMPLETED: REFERRALS_STATUS.COMPLETED,
  ACTIVE: REFERRALS_STATUS.ACTIVE,
  DISABLED: REFERRALS_STATUS.DISABLED,
  ERROR: REFERRALS_STATUS.ERROR,
  COMPLETED_DISABLED: REFERRALS_STATUS.COMPLETED_DISABLED,
  DEFAULT_STATUS: REFERRALS_STATUS.DEFAULT_STATUS,
  ACTIVE_DISABLED: REFERRALS_STATUS.ACTIVE_DISABLED
};

const REQUIRED_FIELD: Record<string, string[]> = {
  [REFERRALS_STEPS.PERSONAL_INFO]: [
    'firstName',
    'lastName',
    'phone',
    'email',
    'age',
    'clinic',
    'referringDoctor',
    'guardianEmail',
    'guardianPhone'
  ],
  [REFERRALS_STEPS.PATIENT_FILE]: ['image', 'xray', 'scan'],
  [REFERRALS_STEPS.TREATMENT_SPEC]: [
    'treatmentSpecifications',
    'clinicalPreferences'
  ]
};

const MIN_AGE = 12;
export {
  REFERRALS_STEPS,
  MIN_AGE,
  REFERRALS_PROGRESS_STEPS,
  STATUS,
  REFERRAL_STATUS,
  TEETH_EXTRACTION_INSTRUCTION,
  REQUIRED_FIELD
};

export const TREATMENT_INSTRUCTIONS_BREADCRUMBS = [
  {
    label: 'dashboard_button.dashboard',
    pathname: pagePaths.dashboard
  },
  {
    label: 'referrals.referrals',
    pathname: pagePaths.referrals
  },
  {
    label: 'referrals.refer_a_patient'
  }
];

export const ClinicalPreferenceFields = [
  'treatmentSpecifications.treatmentType',
  'clinicalPreferences.ZenClear.iprSize',
  'clinicalPreferences.ZenClear.iprLocation',
  'clinicalPreferences.ZenPlus.iprSize',
  'clinicalPreferences.ZenPlus.iprLocation',
  'clinicalPreferences.ZenPlus.biteRamps',
  'clinicalPreferences.ZenPlus.premolarCrossbite',
  'clinicalPreferences.ZenPlus.extrusionElastics'
];

export const valueKeyMapper: Record<string, string> = {
  AS_REQUIRED: 'prescriptions.as_required',
  ANTERIOR:
    'prescriptions.anterior_ipr_preferred_(roundtripping_or_staging_ipr_might_be_necessary)',
  POSTERIOR: 'prescriptions.posterior_ipr_preferred',
  IPR: 'prescriptions.ipr_primarily',
  EXPANSION_WITH_IPR: 'prescriptions.expansion_w/ipr',
  EXPANSION: 'prescriptions.expansion_primarily',
  IF_NECESSARY: 'prescriptions.as_required',
  NONE: 'prescriptions.none'
};
