import { useLazyQuery } from '@apollo/client';

import {
  GET_APPOINTMENTS,
  GET_APPOINTMENT_TYPES,
  CREATE_APPOINTMENT,
  DELETE_APPOINTMENT,
  UPDATE_APPOINTMENT,
  GET_ALL_APPOINTMENTS,
  GET_TREATMENT_BY_CP_ID,
  GET_TREATMENT_BY_ID
} from 'services/query/Calendar.queries';
import {
  CreateAppointment,
  DeleteAppointment,
  GetAllAppointments,
  GetAppointments,
  GetAppointmentType,
  GetTreatmentByCPID,
  GetTreatmentByID,
  TreatmentByCpId,
  TreatmentById,
  UpdateAppointment
} from 'types/Calendar.types';

import { UseGraphQlMutation, UseGraphQlQuery } from './hooks';

// const defaultConfig = {
//   variables: { offset: 0, sortBy: 'status', sortOrder: 'ASC' },
//   notifyOnNetworkStatusChange: true
// };

/**
 * Function to get sample data
 * @param {QueryHookOptions=} config query options
 * @returns {QueryResult}
 */
export const useGetAppointments: GetAppointments = (config) => {
  return UseGraphQlQuery(GET_APPOINTMENTS, config);
};

export const useCreateAppointment: CreateAppointment = (config) => {
  return UseGraphQlMutation(CREATE_APPOINTMENT, config);
};

export const useUpdateAppointment: UpdateAppointment = (config) => {
  return UseGraphQlMutation(UPDATE_APPOINTMENT, config);
};

export const useDeleteAppointment: DeleteAppointment = (config) => {
  return UseGraphQlMutation(DELETE_APPOINTMENT, config);
};

export const useGetAppointmentTypes: GetAppointmentType = (config) => {
  return useLazyQuery(GET_APPOINTMENT_TYPES, config);
};

export const useGetAllAppointments: GetAllAppointments = (config) => {
  return UseGraphQlQuery(GET_ALL_APPOINTMENTS, config);
};

export const useGetTreatmentByCPID: GetTreatmentByCPID = (config) =>
  useLazyQuery<TreatmentByCpId>(GET_TREATMENT_BY_CP_ID, config);

export const useGetTreatmentByID: GetTreatmentByID = (config) =>
  useLazyQuery<TreatmentById>(GET_TREATMENT_BY_ID, config);
