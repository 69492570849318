import { FC, ReactNode, useEffect, useMemo } from 'react';

import cx from 'classnames';
import { useDropzone, DropEvent, FileRejection } from 'react-dropzone';

import { PhotoUploadIcon, UploadSmallIcon } from 'assets/icons';
import Button from 'components/Button/Button';
import AddFileButton from 'components/Files/AddFileButton/AddFileButton';
import { If } from 'components/Generics';
import { ALLOWED_EXTENSIONS } from 'constants/common';
import { translate, customTwMerge as twMerge } from 'utils';
import { useIsmobile } from 'utils/customHooks/useViewport';

const fileExtensionValidator = (file: File) => {
  const fileExtension = file.name.split('.').pop()?.toLowerCase() || '';
  if (ALLOWED_EXTENSIONS.includes(fileExtension)) return null;
  return {
    code: 'invalid-file',
    message: translate('file.upload.not_a_valid_file', {
      X: file.name
    })
  };
};

type FileDropZoneProps = {
  onDropAccepted:
    | (<T extends File>(files: T[], event?: DropEvent) => void)
    | undefined;
  onDropRejected?: (fileRejections: FileRejection[], event?: DropEvent) => void;
  allowedFileMimeType: string | string[] | undefined;
  fileTitleType: string;
  showOnlyAddButton?: boolean;
  showUploadImagesButton?: boolean;
  noKeyboard?: boolean;
  children?: ReactNode;
  noClick?: boolean;
  maxFiles?: number;
  uploadButtonName?: string;
  containerStyle?: string;
  supportedFilesText?: string;
  fileDialogOpen?: boolean;
  onFileDialogClosed?: (value: boolean) => void;
  className?: string;
  isFromNotesUpload?: boolean;
  onUploadButtonClick?: () => void;
  uploadButtonHandler?: () => void;
  disabled?: boolean;
};

const FileDropZone: FC<FileDropZoneProps> = ({
  onDropAccepted,
  onDropRejected,
  allowedFileMimeType,
  fileTitleType,
  noKeyboard = true,
  noClick = true,
  showOnlyAddButton = false,
  showUploadImagesButton = false,
  maxFiles = 0,
  children,
  uploadButtonName,
  fileDialogOpen,
  onFileDialogClosed,
  supportedFilesText,
  containerStyle = 'PRIMARY',
  className = '',
  uploadButtonHandler,
  isFromNotesUpload = false,
  onUploadButtonClick,
  disabled = false
}) => {
  const isMobile = useIsmobile();
  const fileTranslationMapper: Record<string, string> = {
    scans: translate('files.drop_your_scans_here__or_browse'),
    photo: translate('files.drop_your_photos') + ' ',
    image: translate('files.drop_your_image_here__or_browse'),
    'X-rays': translate('files.drop_your_x_rays'),
    consent: translate('files.drop_your_consent_form')
  };

  const mobileFileTranslationMapper: Record<string, string> = {
    scans: translate('files.upload_your_scans'),
    photo: translate('files.upload_your_photos'),
    image: translate('files.upload_your_image'),
    'X-rays': translate('files.upload_your_xrays'),
    consent: translate('files.upload_your_consent')
  };

  const buttonTextTranslationMapper: Record<string, string> = {
    'X-rays': translate('upload_x-rays.upload_x-rays'),
    consent: translate('upload_consent_form.upload_consent_form'),
    scans: translate('action_required.upload_scans')
  };
  const { getRootProps, getInputProps, isDragActive, isDragReject, open } =
    useDropzone({
      noKeyboard,
      noClick,
      accept: allowedFileMimeType || '',
      onDropAccepted,
      onDropRejected,
      maxFiles,
      minSize: 1,
      validator: !allowedFileMimeType ? fileExtensionValidator : () => null,
      disabled: disabled
    });

  const handleUploadButtonClick = () => {
    if (uploadButtonHandler) {
      uploadButtonHandler?.();
      return;
    }
    onUploadButtonClick?.();
    open?.();
  };

  useEffect(() => {
    if (fileDialogOpen) {
      open();
      onFileDialogClosed?.(false);
    }
  }, [fileDialogOpen]);

  const style = useMemo(() => {
    return {
      ...(!showOnlyAddButton ? { height: '100%', with: '100%' } : {}),
      ...(isDragActive ? { opacity: '50%' } : {}),
      ...(isDragReject ? { opacity: '50%' } : {})
    };
  }, [isDragActive, isDragReject, showOnlyAddButton]);

  const getSupportedFileText = () => {
    if (supportedFilesText) {
      return supportedFilesText;
    } else if (
      fileTitleType == 'image' ||
      fileTitleType == 'photo' ||
      fileTitleType === 'X-rays'
    ) {
      return translate('files.supports:_jpg__jpeg_2000_png');
    } else if (fileTitleType != 'consent') {
      return translate('files.supported');
    }
    return translate('files.supports:_pdf__jpeg_png');
  };

  const fileTitleTranslationMapper = isMobile
    ? mobileFileTranslationMapper
    : fileTranslationMapper;

  const getBrowsDescriptionLabel = () => {
    if (fileTitleType === 'photo' && !isMobile) {
      return translate('files.or_browse_your_computer');
    }
    return translate('files.or_browse');
  };

  const renderContent = () => {
    if (children) {
      return (
        <div
          role='button'
          tabIndex={-1}
          onKeyDown={open}
          onClick={() => open()}
        >
          {children}
        </div>
      );
    }
    return (
      <div
        className={twMerge(
          cx(
            `flex flex-col md:border-none border-LIGHT_GRAY_2 items-center justify-center w-full h-full xxs:py-6 `,
            {
              'md:bg-gray border rounded-xl': containerStyle === 'PRIMARY',
              'bg-white rounded-xl': containerStyle === 'SECONDARY',
              'bg-GRAY5 rounded-lg': containerStyle === 'TERTIARY'
            }
          ),
          className
        )}
      >
        <PhotoUploadIcon className='mb-4' />
        <h2 className='text-17px text-DEFAULT_TEXT mb-2 block text-center'>
          <span className=''>{fileTitleTranslationMapper[fileTitleType]}</span>
          <button
            type='button'
            className='text-PRIMARY text-17px disabled:text-DISABLED_2'
            onClick={handleUploadButtonClick}
            disabled={disabled}
          >
            {getBrowsDescriptionLabel()}
          </button>
        </h2>
        <p className='text-xs text-DISABLED_2 mb-6'>{getSupportedFileText()}</p>
        <Button
          onClick={handleUploadButtonClick}
          className='md:flex bg-DEFAULT_TEXT text-white text-center h-12 pl-4 pr-6 py-3 disabled:bg-DISABLED_2 disabled:text-white'
          type='button'
          disabled={disabled}
        >
          <>
            <UploadSmallIcon className='mt-1' />
            <span className='pt-0.5 ml-3 text-sm leading-6'>
              {`${
                uploadButtonName ||
                buttonTextTranslationMapper[fileTitleType] ||
                translate('referrals.upload_photos')
              }`}
            </span>
          </>
        </Button>
      </div>
    );
  };
  return (
    <div {...getRootProps({ style })}>
      <input data-testid='file-upload-input' {...getInputProps()} />
      {!showUploadImagesButton ? (
        <>
          {!showOnlyAddButton && renderContent()}
          <If condition={!isFromNotesUpload && showOnlyAddButton}>
            <AddFileButton
              onClick={handleUploadButtonClick}
              className='flex w-full h-full cursor-default md:pb-3.5 md:pt-2px md:mr-2 xxs:mr-1 '
            />
          </If>
        </>
      ) : (
        <Button
          onClick={open}
          disabled={disabled}
          className='md:flex bg-WHITE text-PRIMARY text-center h-12 px-6 py-4 disabled:bg-DISABLED_2 disabled:text-white'
          type='button'
        >
          <>
            <UploadSmallIcon className='mt-1' />
            <span className='pt-0.5 ml-3 text-sm leading-6'>
              {translate('upload_images.upload_images')}
            </span>
          </>
        </Button>
      )}
    </div>
  );
};

export default FileDropZone;
