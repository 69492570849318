import React, { ChangeEvent, FC } from 'react';

import cx from 'classnames';

import AnimateLabel from 'components/AnimateLabel/AnimateLabel';
import { If } from 'components/Generics';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { customTwMerge as twMerge } from 'utils';

interface InputProps {
  name?: string;
  type?: string;
  label?: string;
  endIcon?: React.ReactNode;
  isMandatory?: boolean;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  customDisabled?: string;
  isError?: boolean;
  showErrorTitle?: boolean;
  showBorderError?: boolean;
  labelClassName?: string;
  width?: string;
}

const OutlinedInput: FC<InputProps> = ({
  placeholder,
  label,
  isMandatory = false,
  disabled = false,
  name = '',
  endIcon,
  customDisabled,
  showErrorTitle = false,
  isError = false,
  showBorderError = false,
  labelClassName = '',
  width,
  ...inputProps
}) => {
  const { animatedField, callback } = useFieldRefContext();
  const erroredInput = showErrorTitle && isError;

  return (
    <div className={twMerge('flex w-full', width)} ref={callback?.(name)}>
      <label
        htmlFor={name}
        className='text-DEFAULT_TEXT flex flex-col xxs:text-xs md:text-sm w-full relative'
      >
        {label && (
          <>
            <span
              className={twMerge(
                `pb-2 ${erroredInput ? 'text-ERROR' : ''}`,
                labelClassName
              )}
            >
              {label}
              {isMandatory && <span className='text-ERROR'>*</span>}
            </span>
            <AnimateLabel
              className='text-ERROR md:text-17px text-base font-normal pb-1'
              animate={erroredInput && animatedField === name}
            >
              {label}
            </AnimateLabel>
          </>
        )}
        <div className='flex items-center justify-between w-full'>
          <input
            data-testid='input'
            {...inputProps}
            placeholder={placeholder}
            disabled={disabled}
            name={name}
            className={twMerge(
              cx(
                `border border-LIGHT_GRAY_2 focus:outline-none rounded-8px md:h-14 xxs:h-12 p-4 md:text-base xxs:text-14px w-full placeholder-LIGHT_GRAY_4
             ${
               disabled
                 ? 'disabled:bg-LIGHT_GRAY disabled:text-DISABLED'
                 : customDisabled
             }`,
                inputProps?.className,
                {
                  'border-ERROR': showBorderError && isError
                }
              )
            )}
          />
          <If condition={!!endIcon}>
            <div className='absolute right-4'>{endIcon}</div>
          </If>
        </div>
      </label>
    </div>
  );
};

export default OutlinedInput;
