import { FC, useEffect, useRef, useState } from 'react';

import { DocumentNode, useApolloClient } from '@apollo/client';
import { isEqual } from 'lodash';

import { ErrorIcon } from 'assets/icons';
import { AnimateLabel } from 'components';
import FilesUpload from 'components/FilesUpload/FilesUpload';
import If from 'components/Generics/If/If';
import ZoomPhotoPopup from 'components/ZoomPhotoPopup/ZoomPhotoPopup';
import { REQUEST_METHODS } from 'constants/index';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { callApi } from 'services/api/request';
import { deleteReferralDocument } from 'services/hooks/Referrals.hooks';
import {
  REFERRAL_FILE_UPLOAD,
  SAVE_REFERRAL_DOCUMENTS
} from 'services/query/referralFileUpload';
import {
  FilesData,
  ReferralDocumentInput,
  ReferralDocumentListInput,
  ReferralDocumentResponse,
  ReferralDocumentType,
  ReferralDocumentUploadUrlResponse
} from 'types/Files.types';
import { translate } from 'utils';
import useCustomCompareEffect from 'utils/customHooks/useCustomCompareEffect';
import { showToast } from 'utils/toast/toast.util';

type ReferralFileUploaderProps = {
  referralId: string;
  value?: FilesData[];
  onChange?: (files: FilesData[]) => void;
  referralDocumentType: ReferralDocumentType;
  allowedFileMimeType?: string;
  fileDropAreaTitle?: string;
  title?: string;
  className?: string;
  disabled?: boolean;
  isMandatory?: boolean;
  hideUploadModal?: boolean;
  errorMessage?: string;
  showUploadImagesButton?: boolean;
  name: string;
  allFiles?: FilesData[];
  containerStyle?: string;
  refetchQueries?: Array<DocumentNode>;
  onUploadButtonClick?: () => void;
  uploadButtonHandler?: () => void;
  onFileuploadSuccess?: (data: FilesData[]) => void;
};

const ReferralFileUploader: FC<ReferralFileUploaderProps> = ({
  referralId,
  onChange,
  value = [],
  allowedFileMimeType = '',
  referralDocumentType,
  fileDropAreaTitle = '',
  title = '',
  className = '',
  disabled,
  refetchQueries,
  isMandatory = false,
  errorMessage = '',
  onFileuploadSuccess,
  hideUploadModal,
  showUploadImagesButton,
  uploadButtonHandler,
  name,
  allFiles,
  containerStyle,
  onUploadButtonClick
}) => {
  const client = useApolloClient();
  const [files, setFiles] = useState<FilesData[]>(value);
  const [errorFileState, setErrorFileState] = useState<FilesData | undefined>(
    undefined
  );
  const [deleteDocument, { loading }] = deleteReferralDocument();
  const isFirstRender = useRef(true);
  const { animatedField, callback } = useFieldRefContext();
  const [zoomPhoto, toggleZoomPhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<string | number>(0);
  const [uploadingFilesCount, setUploadingFilesCount] = useState<number>(0); // Number of files being uploaded.

  const uploadFile = async (
    fileUploadInput: ReferralDocumentInput,
    file: FilesData
  ) => {
    try {
      const { data: documentUrl } = await getFileUploadUrl(fileUploadInput);
      const { url, key } = documentUrl?.getReferralDocumentsUploadUrl[0] || {};
      await uploadFileToS3(url, file);
      fileUploadInput.key = key;
      file.key = key;
      const { data } = await saveUploadedFile(fileUploadInput);
      if (data) updateIdOfUploadedFile(data, file);
    } catch (error) {
      setErrorFileState(file);
      throw error;
    }
  };

  /**
   * to remove errored file from file state
   */
  useEffect(() => {
    if (errorFileState)
      setFiles((curretFiles) => {
        return curretFiles.filter(({ id }) => id !== errorFileState.id);
      });
  }, [errorFileState]);

  useCustomCompareEffect(() => {
    if (!isEqual(files, value)) {
      setFiles(value);
    }
  }, [value]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      const uploadedFiles = files.filter(({ fileId }) => fileId);
      if (
        !isEqual(value, uploadedFiles) &&
        files.length === uploadedFiles.length
      ) {
        onChange?.(uploadedFiles);
      }
    }
  }, [files]);

  const updateIdOfUploadedFile = (
    uploadedFile: ReferralDocumentResponse,
    file: FilesData
  ) => {
    setFiles((files) => {
      const updatedFile = files.find(({ id }) => id === file.id);
      if (updatedFile) {
        updatedFile.fileId = uploadedFile.saveReferralDocuments[0].id;
        return [...files];
      }
      return files;
    });
  };

  const onFilesChange = (files: FilesData[]) => {
    onFileuploadSuccess?.(files);
    setFiles((currentFiles) => currentFiles.concat(files));
    const numberOfFiles = files?.length;
    setUploadingFilesCount(numberOfFiles);
    const uploadingFiles = files?.map((file) => {
      const fileUploadInput: ReferralDocumentInput = {
        name: file.file?.name as string, // For handling apollo client issue for multiple REFERRAL_FILE_UPLOAD query with same files.
        type: referralDocumentType
      };
      return uploadFile(fileUploadInput, file);
    });
    Promise.all(uploadingFiles)
      .then(() => {
        showToast(
          translate('success.multiplePhotosUploaded', { x: numberOfFiles }),
          true
        );
        setUploadingFilesCount(0);
      })
      .catch(() => {
        showToast(translate('uploadingStatus.failedToast'), false);
        setUploadingFilesCount(0);
      });
  };

  const getFileUploadUrl = async (fileUploadInput: ReferralDocumentInput) => {
    const date = new Date();
    return client.query<
      ReferralDocumentUploadUrlResponse,
      ReferralDocumentListInput
    >({
      query: REFERRAL_FILE_UPLOAD,
      variables: {
        documents: [
          {
            ...fileUploadInput,
            name: `${date.getTime()} ${fileUploadInput.name}`
          }
        ],
        referralId: referralId
      },
      fetchPolicy: 'network-only'
    });
  };

  const uploadFileToS3 = async (url: string, file: FilesData) => {
    const onUploadProgress = (progressEvent: ProgressEvent) => {
      const progress = (progressEvent.loaded * 100) / progressEvent.total;
      setFiles((files) => {
        const updatedFile = files.find(({ id }) => id === file.id);
        if (updatedFile) {
          updatedFile.uploadProgress = progress;
          return [...files];
        }
        file.uploadProgress = progress;
        return files.concat(file);
      });
    };
    const imgBody = new Blob([file.file as File], { type: file.file?.type });
    return callApi({
      payload: { method: REQUEST_METHODS.PUT, url, data: imgBody },
      headers: { 'Content-Type': `${file.file?.type}` },
      onUploadProgress
    });
  };

  const saveUploadedFile = async (fileUploadInput: ReferralDocumentInput) => {
    return client.mutate<ReferralDocumentResponse, ReferralDocumentListInput>({
      mutation: SAVE_REFERRAL_DOCUMENTS,
      variables: {
        documents: [fileUploadInput],
        referralId: referralId,
        files
      },
      refetchQueries,
      fetchPolicy: 'network-only'
    });
  };

  const onDelete = (id: string | number) => {
    const documentId = String(id);
    deleteDocument({
      variables: {
        id: documentId,
        referralId: referralId
      }
    })
      .then(({ data }) => {
        if (data?.deleteReferralDocument) {
          setFiles((files) => {
            return files.filter(({ fileId }) => fileId !== id);
          });
          toggleZoomPhoto(false);
        }
      })
      .catch(console.log);
  };

  return (
    <div className='w-full h-full' ref={callback?.(name)}>
      <div className='md:mb-4'>
        <h3
          className={`leading-6 mb-2 text-17px relative ${
            errorMessage ? 'text-ERROR' : 'text-DEFAULT_TEXT'
          }`}
        >
          {title}
          <If condition={isMandatory}>
            <span className='text-ERROR'>*</span>
          </If>
          {errorMessage && (
            <AnimateLabel animate={!!errorMessage && animatedField === name}>
              {title}
            </AnimateLabel>
          )}
        </h3>
        <p className='text-sm text-DISABLED_2 mt-1'>
          {uploadingFilesCount
            ? translate('uploadingStatus.uploading', { x: uploadingFilesCount })
            : value?.length > 0 &&
              translate('uploadingStatus.uploaded', { x: value?.length })}
        </p>
      </div>
      <FilesUpload
        onFilesChange={onFilesChange}
        files={files}
        hideUploadModal={hideUploadModal}
        onDelete={onDelete}
        uploadButtonHandler={uploadButtonHandler}
        deleting={loading}
        allowedFileMimeType={allowedFileMimeType}
        fileDropAreaTitle={fileDropAreaTitle}
        className={`${
          !files?.length &&
          !showUploadImagesButton &&
          'md:h-66 rounded-lg bg-GRAY_6 md:bg-white'
        } ${className}`}
        showUploadImagesButton={showUploadImagesButton}
        setSelectedPhoto={setSelectedPhoto}
        toggleZoomPhoto={toggleZoomPhoto}
        uploadContainerStyle={containerStyle}
        onUploadButtonClick={onUploadButtonClick}
        dropContainerClassName={'p-4 md:p-0'}
        disabled={disabled}
      />
      {errorMessage && (
        <p className='text-ERROR text-xs mt-2 flex '>
          <ErrorIcon />
          <span className='ml-2'>{errorMessage}</span>
        </p>
      )}
      <ZoomPhotoPopup
        allFiles={allFiles ? allFiles || [] : files}
        zoomPhoto={zoomPhoto}
        toggleZoomPhoto={toggleZoomPhoto}
        selectedPhoto={selectedPhoto as string}
        setSelectedPhoto={setSelectedPhoto}
      />
    </div>
  );
};

export default ReferralFileUploader;
