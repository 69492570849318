import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers($filter: FilterUsersInput) {
    getUsers(filter: $filter) {
      id
      salutation
      username
      groups
      firstName
      lastName
      email
      mobile
      phone
      country: primaryCountry
      profilePicture
      clinic {
        id
        name
        headClinicId
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation UpdateUserStatus($id: ID!, $isActive: Boolean!) {
    updateUserStatus(id: $id, isActive: $isActive) {
      userId
      deactivatedAt
    }
  }
`;

export const ADD_USER = gql`
  mutation AddUser($userDetails: AddUserInput!, $roles: [ID], $branches: [ID]) {
    addUser(userDetails: $userDetails, roles: $roles, branches: $branches) {
      id
      username
    }
  }
`;

export const GET_HEAD_CLINIC_BRANCHES = gql`
  query GetBranchesOfHeadClinic($headClinicId: String) {
    getBranchesOfHeadClinic(headClinicId: $headClinicId) {
      id
      name
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $input: UpdateUserInput!) {
    updateUser(id: $id, input: $input) {
      id
      firstName
      lastName
      phone
      email
      username
      groups
      clinic {
        id
        name
        headClinicId
      }
      capacity {
        capacity
      }
    }
  }
`;

export const UPDATE_USER_GROUPS = gql`
  mutation UpdateUserGroups($id: ID!, $input: UpdateUserGroupInput!) {
    updateUserGroups(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_USER_CLINICS = gql`
  mutation UpdateUserClinics($id: ID!, $branches: [ID]) {
    updateUserClinics(id: $id, branches: $branches) {
      id
    }
  }
`;

export const GET_STAFFS = gql`
  query GetAllStaff($filter: StaffFilter, $paginationInfo: PaginationInput) {
    getAllStaff(filter: $filter, paginationInfo: $paginationInfo) {
      staff {
        id
        salutation
        username
        groups
        firstName
        lastName
        email
        mobile
        phone
        country: primaryCountry
        profilePicture
        clinic {
          id
          name
          headClinicId
        }
        deactivatedAt
        maxComplexity
        capacity {
          capacity
        }
      }
    }
  }
`;

export const GET_STAFF_BASIC = gql`
  query getBasicStaffInfo(
    $filter: StaffFilter
    $paginationInfo: PaginationInput
    $date: Date!
  ) {
    getAllStaff(filter: $filter, paginationInfo: $paginationInfo) {
      staff {
        id
        firstName
        lastName
        groups
        profilePicture
        available(date: $date)
      }
      totalCount
      paginationInfo {
        limit
        offset
      }
    }
  }
`;

export const GET_STAFF_DETAILS = gql`
  query GetStaffDetails($id: ID!, $date: Date!) {
    getStaff(id: $id) {
      id
      salutation
      username
      groups
      firstName
      lastName
      email
      mobile
      phone
      country: primaryCountry
      profilePicture
      clinic {
        id
        name
        headClinicId
      }
      deactivatedAt
      maxComplexity
      available(date: $date)
      activeLeave(date: $date) {
        startDate
        endDate
        timezone
      }
      capacity {
        capacity
      }
    }
  }
`;

export const GET_COUNTRIES = gql`
  query GetSupportedCountries {
    getSupportedCountries {
      value: key
      label: value
    }
  }
`;

export const GET_ROLES = gql`
  query GetSupportedRoles($type: UserType) {
    getSupportedRoles(type: $type) {
      value: key
      label: value
    }
  }
`;

export const GET_LANGUAGES = gql`
  query GetSupportedLanguages {
    getSupportedLanguages {
      value: key
      label: value
    }
  }
`;

export const GET_DOCTORS = gql`
  query GetAllDoctors($branchId: ID) {
    getAllDoctors(branchId: $branchId) {
      firstName
      lastName
      id
      details {
        maxComplexity
      }
    }
  }
`;
