import Skeleton from 'react-loading-skeleton';

const CalendarPopoverCardSkeleton = () => {
  return (
    <div className='w-full flex  justify-between items-center  text-DEFAULT_TEXT'>
      <div className='flex items-center'>
        <div className='rounded-full h-2 w-2 bg-gray-200' />
        <div className='mx-1 h-full text-sm'>
          <Skeleton className='w-[15rem]' />
        </div>
      </div>
      <div className='h-full text-sm'>
        <Skeleton className='w-[7rem]' />
      </div>
    </div>
  );
};

export default CalendarPopoverCardSkeleton;
