import {
  ChangeEvent,
  FC,
  useState,
  useEffect,
  useMemo,
  useCallback,
  KeyboardEvent
} from 'react';

import { debounce } from 'lodash';

import { Button, AnimateLabel } from 'components';
import Checkbox from 'components/CheckboxGroup/CheckboxGroup';
import { If } from 'components/Generics';
import Input from 'components/Input/Input';
import Radio from 'components/RadioGroup/RadioGroup';
import { STAGES } from 'constants/common';
import { TRACK_EVENTS } from 'constants/segment';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { useGetPriorTreatmentOptions } from 'services/hooks/TreatmentPlanning.hooks';
import { Option } from 'types/select';
import { PriorTreatmentNeededType } from 'types/TreatmentPlanning.types';
import { customTwMerge, translate } from 'utils';

type PriorTreatmentNeededProps = {
  onChange: (priorTreatment: PriorTreatmentNeededType | null) => void;
  value: PriorTreatmentNeededType | undefined;
  type?: 'REFERRALS' | 'PENDING_PRESCRIPTION';
  error: any;
  patientId?: string;
  isDisabled?: boolean;
  className?: string;
};
const PriorTreatmentNeeded: FC<PriorTreatmentNeededProps> = ({
  onChange,
  value,
  error,
  type = 'REFERRALS',
  patientId,
  isDisabled = false,
  className
}) => {
  const { animatedField, callback } = useFieldRefContext();
  const priorTreatmentNeededExtraOptions: Option[] = [
    { value: 'NONE', label: translate('prescriptions.none') },
    { value: 'OTHERS', label: translate('prescriptions.others') }
  ];
  const yesOrNoOptions: Option[] = [
    { value: 'YES', label: translate('yes.yes') },
    { value: 'NO', label: translate('no.no') }
  ];

  const [priorTreatment, setPriorTreatment] = useState<Option[] | undefined>(
    value?.treatments?.map((value) => ({ value }))
  );
  const [completed, setCompleted] = useState<boolean | undefined>(undefined);
  const getProceedTreatmentPlanningValueForSetting = (
    canPlanningProceed?: boolean
  ) => {
    if (canPlanningProceed) return 'YES';
    else if (canPlanningProceed === false) return 'NO';
    else return undefined;
  };
  const [proceedTreatmentPlanning, setProceedTreatmentPlanning] = useState<
    string | undefined
  >(getProceedTreatmentPlanningValueForSetting(value?.canPlanningProceed));
  const [otherTreatment, setOtherTreatment] = useState('');

  const { data: priorTreatmentOptionsReponse } = useGetPriorTreatmentOptions();
  const priorTreatmentNeededOptions: Option[] = useMemo(
    () =>
      [priorTreatmentNeededExtraOptions[0]]
        .concat(priorTreatmentOptionsReponse?.getPriorTreatmentsList || [])
        .concat(priorTreatmentNeededExtraOptions[1]) || [],
    [priorTreatmentOptionsReponse?.getPriorTreatmentsList]
  );
  const disablePriorTreatmentOtherInput = !priorTreatment?.find(
    ({ value }) => value === 'OTHERS'
  );
  if (disablePriorTreatmentOtherInput && otherTreatment) setOtherTreatment('');

  useEffect(() => {
    const getOtherTreatmentValue = (treatments: string[] | undefined) =>
      treatments?.find((treatment) =>
        priorTreatmentNeededOptions.every(({ value }) => value !== treatment)
      );
    const hanldePriorTreatmentChange = (treatments: string[] | undefined) => {
      const otherTreatment = getOtherTreatmentValue(treatments);
      let updatedTreatments =
        treatments?.length === 0 ? treatments.concat('NONE') : treatments;
      updatedTreatments =
        otherTreatment !== undefined
          ? treatments?.map((value) =>
              value === otherTreatment ? 'OTHERS' : value
            )
          : updatedTreatments?.filter((value) => value !== 'OTHERS');
      otherTreatment !== undefined && setOtherTreatment(otherTreatment);
      const value = updatedTreatments?.map((value) => ({ value }));
      if (value?.length && value[value.length - 1]?.value === 'NONE') {
        setPriorTreatment([{ value: 'NONE' }]);
      } else if (Number(value?.length) > 1 && value?.[0]?.value === 'NONE') {
        const selectedPriorTreatment = value.filter(
          ({ value }) => value !== 'NONE'
        );
        setPriorTreatment(selectedPriorTreatment);
      } else {
        setPriorTreatment(value);
      }
    };

    hanldePriorTreatmentChange(value?.treatments);
  }, [value?.treatments, priorTreatmentNeededOptions]);

  const debouncedFilterTrack = useCallback(
    debounce((data: any) => {
      window?.analytics.track(TRACK_EVENTS?.FILLED_PRIOR_TREATMENT, data);
    }, 5000),
    []
  );

  useEffect(() => {
    setProceedTreatmentPlanning(
      getProceedTreatmentPlanningValueForSetting(value?.canPlanningProceed)
    );
  }, [value?.canPlanningProceed]);

  useEffect(() => {
    setCompleted(value?.completed);
  }, [value?.completed]);

  const getProceedTreatmentPlanningValueForSubmitting = (
    canPlanningProceed?: string
  ) => {
    return canPlanningProceed === 'YES'
      ? true
      : canPlanningProceed === 'NO'
      ? false
      : undefined;
  };

  const getPriorTreatmentValue = (
    treatment: Option[] | undefined,
    otherTreatmentChange?: string
  ) => {
    const otherTreatmentText =
      otherTreatmentChange === undefined
        ? otherTreatment
        : otherTreatmentChange;
    if (treatment?.length === 0) return undefined;
    if (treatment?.[treatment.length - 1]?.value === 'NONE') return [];
    return treatment
      ?.map(({ value }) => (value === 'OTHERS' ? otherTreatmentText : value))
      .filter((value) => value !== 'NONE');
  };

  const onPriorTreatmentChange = (value: Option[]) => {
    const priorTreatmentValue = getPriorTreatmentValue(value);
    onChange({
      treatments: priorTreatmentValue,
      completed: !priorTreatmentValue?.length ? undefined : completed,
      canPlanningProceed: !priorTreatmentValue?.length
        ? undefined
        : getProceedTreatmentPlanningValueForSubmitting(
            proceedTreatmentPlanning
          )
    });
    window?.analytics.track(TRACK_EVENTS?.FILLED_PRIOR_TREATMENT, {
      patient_id: patientId,
      section_of_filled_field: 'Prior treatment needed',
      value_selected: getPriorTreatmentValue(value, 'OTHERS')
    });
  };

  const onProceedTreatmentPlanning = (value: string) => {
    onChange({
      canPlanningProceed: getProceedTreatmentPlanningValueForSubmitting(value),
      completed: value === 'YES' ? completed : undefined,
      treatments: getPriorTreatmentValue(priorTreatment)
    });
    if (type === STAGES.PENDING_PRESCRIPTION)
      window?.analytics.track(TRACK_EVENTS?.FILLED_TREATMENT_PLANNING_PROCEED, {
        patient_id: patientId,
        section_of_filled_field: 'Prior treatment needed',
        value_selected: value === 'YES' ? true : false
      });
  };

  const onOtherTreatmentChange = ({
    target
  }: ChangeEvent<HTMLInputElement>) => {
    const filledValues = getPriorTreatmentValue(priorTreatment, target.value);
    debouncedFilterTrack({
      patient_id: patientId,
      section_of_filled_field: 'Prior treatment needed',
      value_selected: filledValues
    });
    onChange({
      treatments: getPriorTreatmentValue(priorTreatment, target.value),
      canPlanningProceed: getProceedTreatmentPlanningValueForSubmitting(
        proceedTreatmentPlanning
      ),
      completed: proceedTreatmentPlanning === 'YES' ? completed : undefined
    });
  };

  const onCompletedChange = () => {
    onChange({
      canPlanningProceed: getProceedTreatmentPlanningValueForSubmitting(
        proceedTreatmentPlanning
      ),
      treatments: getPriorTreatmentValue(priorTreatment),
      completed: true
    });
    if (type === STAGES.PENDING_PRESCRIPTION)
      window?.analytics.track(TRACK_EVENTS?.FILLED_PRIOR_TREATMENT_COMPLETED, {
        patient_id: patientId,
        section_of_filled_field: 'Prior treatment needed',
        value_selected: 'prior treatment completed'
      });
  };

  const getPriorTreamentCompletionIndicationMsg = () => {
    let msg = '';
    if (value?.canPlanningProceed === false)
      msg = translate('prescriptionSubmission.successToast.priorTreatment');
    if (value?.completed)
      msg = translate(
        'prescriptionSubmission.successMessage.priorTreatmentDone'
      );
    return msg;
  };
  const checkKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') e.preventDefault();
  };

  return (
    <div className={customTwMerge('bg-white', className)}>
      <Checkbox
        onChangehandle={onPriorTreatmentChange}
        options={priorTreatmentNeededOptions}
        name='treatments'
        selectedItems={priorTreatment}
        className='mt-6'
        checkBoxClassName='border-RADIO_BORDER'
        checkBoxItemFont='md:text-17px text-sm'
        disabled={isDisabled}
      />
      <div className='flex'>
        <Input
          onKeyDown={checkKeyDown}
          value={otherTreatment}
          onChange={onOtherTreatmentChange}
          type='text'
          placeholder={`${translate('please_specify.please_specify')}:`}
          name='Specify Treatment'
          disabled={disablePriorTreatmentOtherInput || isDisabled}
          className={`w-full ml-7 appearance-none pr-12 focus:outline-none bg-transparent pl-4 border-1 md:h-10 rounded text-sm h-12`}
        />
      </div>
      <If
        condition={
          priorTreatment !== undefined && priorTreatment[0]?.value !== 'NONE'
        }
      >
        <div className='mt-6'>
          <Radio
            onChangehandle={onProceedTreatmentPlanning}
            options={yesOrNoOptions}
            title={translate('prescriptions.can_treatment_planning_proceed?')}
            className='mt-4'
            name='priorTreatment.canPlanningProceed'
            selectedItem={proceedTreatmentPlanning}
            showErrorTitle={!!error?.canPlanningProceed}
            radioClassName='border-RADIO_BORDER'
            radioItemFont='md:text-17px text-sm'
            inputProps={{ disabled: isDisabled }}
          />
        </div>
        <If
          condition={
            type === STAGES.PENDING_PRESCRIPTION &&
            proceedTreatmentPlanning === 'NO'
          }
        >
          <div
            className={` w-full p-4 mt-6 ${
              error?.completed ? 'bg-LIGHT_ERROR_RED' : 'bg-GRAY'
            }`}
            ref={callback?.('priorTreatment.completed')}
          >
            <h2
              className={`text-17px font-bold mb-2 relative ${
                error?.completed ? 'text-ERROR' : 'text-DEFAULT_TEXT'
              }`}
            >
              <span>
                {translate('prescriptions.is_prior_treatment_completed?')}
              </span>
              <AnimateLabel
                animate={
                  error?.completed &&
                  animatedField === 'priorTreatment.completed'
                }
              >
                {translate('prescriptions.is_prior_treatment_completed?')}
              </AnimateLabel>
            </h2>
            <div className='mb-6'>
              <If condition={!!getPriorTreamentCompletionIndicationMsg()}>
                <p
                  className={`text-sm test-DISABLED mt-2 ${
                    error?.completed ? 'text-ERROR' : 'text-DISABLED'
                  }`}
                >
                  {getPriorTreamentCompletionIndicationMsg()}
                </p>
              </If>
            </div>
            <Button
              className={`px-8 py-3 text-white rounded-32px text-sm w-full md:w-auto ${
                error?.completed ? 'bg-ERROR' : 'bg-PRIMARY'
              }`}
              onClick={onCompletedChange}
              disabled={value?.completed || isDisabled}
            >
              {translate('button_rounded_(short).prior_treatment_completed')}
            </Button>
          </div>
        </If>
      </If>
    </div>
  );
};

export default PriorTreatmentNeeded;
