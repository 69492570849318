import React, { ChangeEvent, FC } from 'react';

import { InputProps } from 'types/input';
import { customTwMerge as twMerge } from 'utils';

import './styles.css';

interface Props {
  name: string;
  endIcon?: React.ReactNode;
  className?: string;
  placeholder?: string;
  errorMessage?: boolean;
  inputProps?: InputProps;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
}

const AnimatedOutlinedInput: FC<Props> = ({
  name = '',
  endIcon,
  className,
  errorMessage = false,
  ...inputProps
}) => {
  return (
    <>
      <div className='flex'>
        <input
          data-testid='input'
          {...inputProps}
          name={name}
          className={twMerge(
            `placeholder-transparent block w-full relative appearance-none pr-12 z-2 focus:outline-none bg-transparent pl-5 border-2 border-opacity-50 h-10 rounded ${
              errorMessage ? 'border-ERROR' : ''
            }`,
            className
          )}
        />
        <div className='w-0 absolute right-0'>{endIcon}</div>
        <label
          htmlFor={name}
          className={`absolute top-0 z-0 duration-300 transform-origin mt-4 ml-4 ${
            errorMessage ? 'text-ERROR' : 'text-LIGHT_GRAY_4 '
          }`}
        >
          {inputProps?.placeholder}
        </label>
      </div>
    </>
  );
};

export default AnimatedOutlinedInput;
