import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState
} from 'react';

import { FieldRef, FieldRefCallback } from '../types/FieldRef.types';

type FieldRefContextType = {
  callback: FieldRefCallback | null;
  animatedField: string;
};

const FieldRefContext = createContext<FieldRefContextType>({
  callback: null,
  animatedField: ''
});

const useFieldRefContext = () => {
  const context = useContext(FieldRefContext);
  if (!context) {
    console.error('useFieldRefContext must be used within an FieldRefProvider');
  }
  return context;
};

const FieldRefProvider = (props: any) => {
  const fieldRefs = useRef<FieldRef>(new Map());
  const fieldRefCallBack = useCallback(
    (fieldName: string) => (element: HTMLElement) => {
      fieldRefs.current.set(fieldName, element);
    },
    []
  );
  return <FieldRefContext.Provider value={fieldRefCallBack} {...props} />;
};

const useFeldRefCallback = () => {
  const [fieldRefs, setFieldRefs] = useState(new Map<string, HTMLElement>());
  const fieldRefCallBack = useCallback(
    (fieldName: string) => (element: HTMLElement) => {
      setFieldRefs((refs) => refs.set(fieldName, element));
    },
    []
  );
  return { fieldRefs, fieldRefCallBack };
};

export {
  FieldRefContext,
  useFieldRefContext,
  FieldRefProvider,
  useFeldRefCallback
};
