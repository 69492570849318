import { FC } from 'react';

import IprSizeAndLocationDropdowns from './IprSizeAndLocationDropdowns';
type Props = {
  iprSizeName: string;
  iprLocationName: string;
  isPrescription?: boolean;
  isVieworEdit?: boolean;
  isDisabled?: boolean;
  isReferral?: boolean;
};
const ZenClearPreferenceTemplate: FC<Props> = ({
  iprSizeName,
  iprLocationName,
  isPrescription = false,
  isVieworEdit = false,
  isDisabled = false,
  isReferral = false
}) => {
  return (
    <IprSizeAndLocationDropdowns
      isDisabled={isDisabled}
      isVieworEdit={isVieworEdit}
      isPrescription={isPrescription}
      iprLocationName={iprLocationName}
      iprSizeName={iprSizeName}
      isReferral={isReferral}
    />
  );
};

export default ZenClearPreferenceTemplate;
