import { FC, ReactNode, useEffect, useRef, MutableRefObject } from 'react';

import { Loader } from 'components/Loader/Loader';

interface Props {
  children: ReactNode;
  next: () => void;
  hasMore: boolean;
  scrollableTarget: MutableRefObject<HTMLDivElement | null>;
  dataLength: number;
  threshold?: number;
}

const HorizontalInfiniteScroll: FC<Props> = ({
  children,
  next,
  hasMore,
  scrollableTarget,
  dataLength,
  threshold = 100
}) => {
  const dataCount = useRef({
    prevCount: 0,
    currentCount: 0
  });
  dataCount.current.currentCount = dataLength;
  const isListEmpty = dataLength === 0;
  if (isListEmpty) {
    dataCount.current.prevCount = 0;
  }

  const updateNext = (didScrollCrossedThreshold: boolean) => {
    const { currentCount } = dataCount.current;
    if (hasMore && didScrollCrossedThreshold) {
      next();
      dataCount.current.prevCount = currentCount;
    }
  };

  useEffect(() => {
    const updateScrollPosition: EventListenerOrEventListenerObject = (
      event
    ) => {
      const { scrollLeft, scrollWidth, clientWidth } =
        event.currentTarget as HTMLElement;
      const { currentCount, prevCount } = dataCount.current;
      if (prevCount < currentCount) {
        const didScrollCrossedThreshold =
          scrollLeft + clientWidth + threshold >= scrollWidth;
        updateNext(didScrollCrossedThreshold);
      }
    };

    if (scrollableTarget && scrollableTarget.current) {
      scrollableTarget.current.addEventListener(
        'scroll',
        updateScrollPosition,
        false
      );
    }

    const scrollableEl = scrollableTarget.current;
    return () => {
      if (scrollableEl) {
        scrollableEl.removeEventListener('scroll', updateScrollPosition, false);
      }
    };
  });

  return (
    <>
      {children}
      {hasMore && (
        <div className='h-20 w-20'>
          <Loader />
        </div>
      )}
    </>
  );
};

export default HorizontalInfiniteScroll;
