import React, { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { ErrorIcon } from 'assets/icons';
import OutlinedInput from 'components/OutlinedInput/OutlinedInput';
import { InputProps } from 'types/input';
import { translate } from 'utils';

import AnimatedOutlinedInput from '../../AnimatedOutlinedInput/AnimatedOutlinedInput';

interface RhfInputProps {
  name: string;
  className?: string;
  showAnimated?: boolean;
  label?: string;
  type?: string;
  inputProps?: InputProps;
}

const RhfInput: FC<RhfInputProps> = ({
  name,
  label,
  showAnimated = true,
  className,
  inputProps
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className='w-full'>
            {!showAnimated ? (
              <>
                <OutlinedInput
                  label={label}
                  className={className}
                  {...inputProps}
                  value={field.value}
                  name={name}
                  onChange={field.onChange}
                  isError={!!error?.message}
                />
              </>
            ) : (
              <>
                <AnimatedOutlinedInput
                  className={className}
                  errorMessage={!!error?.message}
                  {...inputProps}
                  value={field.value}
                  name={name}
                  onChange={field.onChange}
                />
              </>
            )}
            <div>
              {error?.message && (
                <p className='text-ERROR text-xs mt-2 flex '>
                  <ErrorIcon />
                  <span className='ml-2'>{translate(error?.message)}</span>
                </p>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default RhfInput;
