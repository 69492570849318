import { FC, ReactNode } from 'react';

import { Modal } from 'react-responsive-modal';

import { If } from 'components/Generics';
import { useViewport } from 'utils';

const modalStyles = {
  modal: {
    margin: 0,
    width: '100%',
    height: '100%',
    padding: 0
  },
  root: {
    marginTop: 'var(--appbar-height)',
    zIndex: 5
  },
  overlay: {
    background: 'transparent'
  }
};

interface Props {
  header?: ReactNode;
  footer?: ReactNode;
  children?: ReactNode;
  className?: string;
}

export const MobileFullScreenView: FC<Props> = ({
  header,
  footer,
  children,
  className = 'px-6'
}) => {
  const { width } = useViewport();
  const isMobile = width < 768;
  return (
    <Modal
      styles={modalStyles}
      center={false}
      open={isMobile}
      showCloseIcon={false}
      onClose={() => null}
      animationDuration={0}
      blockScroll={false}
    >
      <div
        data-testid='mobile-dialog'
        className={`relative min-h-full h-max w-full ${className}`}
      >
        <If condition={!!header}>
          <div className='sticky top-0 left-0 bg-WHITE w-full z-3 py-6'>
            {header}
          </div>
        </If>
        <div>{children}</div>
      </div>
      <If condition={!!footer}>
        <div className='sticky w-full p-6 bottom-0 z-3 bg-WHITE'>{footer}</div>
      </If>
    </Modal>
  );
};
