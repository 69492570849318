import { SuccessorType } from 'types/InTreatmetn.types';

export const TOPIC_CODE = {
  illFitting: 'ILL_FITTING_ALIGNERS',
  trimming: 'TRIMMING_ALIGNERS',
  refinement: 'REFINEMENT_REQUIRED',
  intreatmentDiscussion: 'IN_TREATMENT_DISCUSSION'
};

export const CONFIRM_TITLE = {
  illFitting: 'ill-fitting_aligners',
  trimming: 'trimming'
};

export const POPUP_REFINEMENT = 'REFINEMENT';

type PopupKeys = 'chatWithZenyum' | 'refinement';

export const POPUP: Record<PopupKeys, SuccessorType> = {
  chatWithZenyum: 'CHAT_WITH_ZENYUM',
  refinement: 'REFINEMENT'
};

export const PATIENT_PHOTOS_SOURCE = {
  offboardingReview: 'OFFBOARDING_REVIEW'
};
