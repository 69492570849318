import { atom } from 'recoil';

import { Treatment } from 'types/Inbox.types';
import { SidepanelPreference } from 'types/PatientList.types';
import { SelectedSearchedTicket } from 'types/select';
import { InboxFilters, Ticket } from 'types/Tickets.types';

import {
  ATOMS,
  defaultInboxFilters,
  defaultSelectedSearchedTicket,
  defaultSelectedTicket,
  defaultSelectedTicketTreatment,
  defaultUnreadNotification
} from './constants';

const inboxFilters = atom<InboxFilters>({
  key: ATOMS.INBOX_FILTER_ATOM,
  default: defaultInboxFilters
});

const selectedTicket = atom<Ticket>({
  key: ATOMS.SELECTED_TICKET,
  default: defaultSelectedTicket
});

const ticketListLoaded = atom<boolean>({
  key: ATOMS.INBOX_TICKET_LIST_LOADED,
  default: false
});

const unreadNotification = atom({
  key: ATOMS.UNREAD_NOTIFICATION,
  default: defaultUnreadNotification
});

const selectedSerchedMessage = atom<SelectedSearchedTicket>({
  key: ATOMS.SELECTED_SEARCHED_MESSAGE,
  default: defaultSelectedSearchedTicket
});

const isWarningClosed = atom({
  key: ATOMS.IS_WARNING_CLOSED,
  default: false
});

const selectedTicketActiveTreatement = atom<Treatment[]>({
  key: ATOMS.SELECTED_TICKET_ACTIVE_TREATMENT,
  default: defaultSelectedTicketTreatment
});

const ticketList = atom<Ticket[]>({
  key: ATOMS.TICKET_LIST,
  default: []
});

const showChatMessage = atom({
  key: ATOMS.SHOW_CHAT,
  default: false
});

const showAddMe = atom({
  key: ATOMS.SHOW_ADD_ME,
  default: false
});

const selectedChatAccordion = atom({
  key: ATOMS.SELECTED_CHAT_ACCORDION,
  default: ['']
});

const showNewChat = atom({
  key: ATOMS.SHOW_NEW_CHAT,
  default: false
});

const showMobileViewInbox = atom({
  key: ATOMS.SHOW_MOBILE_VIEW_INBOX,
  default: false
});

const showMobileViewAppointment = atom({
  key: ATOMS.SHOW_MOBILE_VIEW_APPOINTMENTS,
  default: false
});

const showMobileViewNotes = atom({
  key: ATOMS.SHOW_MOBILE_VIEW_NOTES,
  default: false
});

const showNewChatDesktop = atom({
  key: ATOMS.SHOW_NEW_CHAT_DESKTOP,
  default: false
});

const unreadNotificationCount = atom({
  key: ATOMS.UNREAD_NOTIFICATION_COUNT,
  default: 0
});

const inboxRightSidepanelPreference = atom<Record<string, SidepanelPreference>>(
  {
    key: ATOMS.INBOX_RIGHT_SIDEPANEL_PREFERENCE,
    default: {}
  }
);

export {
  showAddMe,
  ticketList,
  showNewChat,
  inboxFilters,
  selectedTicket,
  isWarningClosed,
  showChatMessage,
  unreadNotification,
  showMobileViewInbox,
  ticketListLoaded,
  selectedChatAccordion,
  selectedSerchedMessage,
  unreadNotificationCount,
  showNewChatDesktop,
  selectedTicketActiveTreatement,
  showMobileViewAppointment,
  showMobileViewNotes,
  inboxRightSidepanelPreference
};
