import { FC } from 'react';

import classNames from 'classnames';

import Checkbox from 'components/CheckboxGroup/Checkbox/Checkbox';
import { Divider } from 'components/Divider/Divider';
import { If } from 'components/Generics';
import { LOWER_TOOTH_LIST, UPPER_TOOTH_LIST } from 'constants/ToothChart';
import { IprInfo } from 'types/TPVForm.types';
type Props = {
  toothData?: IprInfo[];
  selected?: string[];
  setSelected(selected?: string[]): void;
  missingTooth?: string[];
  disabled?: boolean;
};

export const ToothChart3: FC<Props> = ({
  toothData = [],
  selected = [],
  setSelected,
  missingTooth,
  disabled = false
}) => {
  const handleSelection = (name: string, checked: boolean) => {
    if (checked) setSelected([...selected, name]);
    else setSelected(selected?.filter((selectedData) => name !== selectedData));
  };

  const getPosition = (value: IprInfo) => {
    const position = value?.position.split('-').sort();
    if (
      (1.1 <= Number(position[0]) && Number(position[1]) <= 1.8) ||
      (4.1 <= Number(position[0]) && Number(position[1]) <= 4.8) ||
      (3.1 === Number(position[0]) && Number(position[1]) === 4.1)
    ) {
      return position[1];
    }
    return position[0];
  };

  return (
    <div
      id='tooth-chart'
      className='bg-BG_COLOR w-full flex overflow-x-scroll scrollbar-visible-mobile max-w-screen-xl'
    >
      <div className='space-y-8 p-6 flex flex-col'>
        <div className='flex flex-1 pt-[6.25rem]'>
          {UPPER_TOOTH_LIST?.map((teeth, index) => {
            const teethData = toothData?.find(
              (item) => getPosition(item) === teeth?.name
            );
            const fieldName = teethData?.position || teeth?.name;
            const isTeethMissing = missingTooth?.includes(teeth?.name);
            const showCheckBox =
              index < LOWER_TOOTH_LIST?.length - 1 &&
              !!teethData &&
              !isTeethMissing;
            return (
              <div className='flex items-end w-[6.25%]' key={teeth.name}>
                <div className='flex flex-col justify-end text-WHITE'>
                  <div
                    className={classNames({
                      'text-DISABLED': isTeethMissing
                    })}
                  >
                    {teeth?.image}
                  </div>
                  <p className='text-xs text-center text-DEFAULT_TEXT'>
                    {teeth.name}
                  </p>
                </div>
                <div
                  className={classNames('w-0.5 h-full relative mx-1 top-0', {
                    'bg-LINE_COLOR': showCheckBox
                  })}
                  style={
                    index % 2 === 0 ? { height: 'calc(100% + 2.25rem)' } : {}
                  }
                >
                  <If condition={showCheckBox}>
                    <div className='text-xs top-0 -translate-x-1/2 -translate-y-full absolute'>
                      <div className='z-2 border-b-1 w-full text-center bg-BG_COLOR space-y-1'>
                        <If condition={!disabled}>
                          <Checkbox
                            name={fieldName}
                            checked={selected?.includes(fieldName)}
                            onChange={(e) => {
                              handleSelection(fieldName, e.target.checked);
                            }}
                            className='h-5 w-5 m-0'
                          />
                        </If>
                        <p className='whitespace-nowrap'>{`IPR ${
                          teethData?.setNumber || ''
                        }`}</p>
                        <p>{teethData?.value}</p>
                      </div>
                    </div>
                  </If>
                </div>
              </div>
            );
          })}
        </div>
        <Divider />
        <div className='flex flex-1 pb-[6.25rem]'>
          {LOWER_TOOTH_LIST?.map((teeth, index) => {
            const teethData = toothData?.find(
              (item) => getPosition(item) === teeth?.name
            );
            const fieldName = teethData?.position || teeth?.name;
            const showCheckBox =
              index < LOWER_TOOTH_LIST?.length - 1 && !!teethData;
            return (
              <div
                className='flex items-stretch w-[6.25%]'
                key={'lower' + teeth.name}
              >
                <div className='flex flex-col  text-WHITE'>
                  <p className='text-xs text-center text-DEFAULT_TEXT'>
                    {teeth.name}
                  </p>
                  <div
                    className={classNames({
                      'text-DISABLED': missingTooth?.includes(teeth?.name)
                    })}
                  >
                    {teeth?.image}
                  </div>
                </div>
                <div
                  className={classNames(
                    'md:w-1px w-10px h-full relative bottom-0 mx-1',
                    {
                      'bg-LINE_COLOR': showCheckBox
                    }
                  )}
                  style={
                    index % 2 === 1 ? { height: 'calc(100% + 2.25rem)' } : {}
                  }
                >
                  <If condition={showCheckBox}>
                    <div className='text-xs absolute bottom-0 -translate-x-1/2 translate-y-full'>
                      <div className='z-2 border-t-1 w-full text-center bg-BG_COLOR space-y-1'>
                        <p className='whitespace-nowrap'>{`IPR ${
                          teethData?.setNumber || ''
                        }`}</p>
                        <p>{teethData?.value}</p>
                        <If condition={!disabled}>
                          <Checkbox
                            name={fieldName}
                            checked={selected?.includes(fieldName)}
                            onChange={(e) => {
                              handleSelection(fieldName, e.target.checked);
                            }}
                            className='h-5 w-5 m-0'
                          />
                        </If>
                      </div>
                    </div>
                  </If>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
