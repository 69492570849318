import { FC, useEffect } from 'react';

import cx from 'classnames';
import { useRecoilState } from 'recoil';

import { ErrorCrossIcon, SuccessIcon } from 'assets/icons';
import { successErrorAlert } from 'store/atoms';
import { customTwMerge as twMerge } from 'utils';

interface Props {
  className?: string;
}

const SuccessErrorAlert: FC<Props> = ({ className = '' }) => {
  const [{ success, message, customClass }, setAlert] =
    useRecoilState(successErrorAlert);

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        setAlert({
          success: false,
          message: ''
        });
      }, 5000);
    }
  }, [message, setAlert]);

  return (
    <>
      {message && (
        <section
          className={twMerge(
            cx(
              `absolute xxs:inset-6  md:inset-12 flex ${className} rounded-10px justify-start items-center border-1 z-50 p-5 h-fit`,
              {
                'text-ERROR bg-LIGHT_RED  border-LIGHT_BORDER_RED': !success,
                'text-SUCCESS bg-LIGHT_GREEN border-LIGHT_BORDER_GREEN': success
              }
            ),
            customClass
          )}
        >
          {success ? (
            <SuccessIcon
              className='w-6 h-6 xxs:w-8 xxs:h-8 xxs:mr-4 text-SUCCESS_ICON_DEFAULT'
              data-testid='successAlert'
            />
          ) : (
            <ErrorCrossIcon className='w-6 h-6 xxs:w-8 xxs:h-8 xxs:mx-4 text-ERROR' />
          )}
          <span className={cx('text-sm md:pt-1')}>{message}</span>
        </section>
      )}
    </>
  );
};

export default SuccessErrorAlert;
