import { FC, useState } from 'react';

import { Select } from 'components';
import Button from 'components/Button/Button';
import { Option } from 'types/select';
import { translate } from 'utils';

const REASON_OPTIONS = [
  {
    label: translate('snooze.reason.pending_zenyum'),
    value: 'PENDING_ZENYUM'
  },
  {
    label: translate('snooze.reason.pending_treating_doctor'),
    value: 'PENDING_TREATING_DOCTOR'
  },
  {
    label: translate('snooze.reason.pending_patient'),
    value: 'PENDING_PATIENT'
  }
];

interface SnoozeReasonProps {
  onNext: (option: Option) => void;
  onCancel: () => void;
}

const SnoozeReason: FC<SnoozeReasonProps> = ({ onNext, onCancel }) => {
  const [selectedOption, setSelectedOption] = useState<Option>();
  const handleSelect = (option: Option) => {
    setSelectedOption(option);
  };
  const handleNext = () => {
    if (selectedOption?.value) onNext(selectedOption);
  };

  return (
    <div className='w-[40rem]'>
      <p className='text-base mb-8'>{translate('snooze.reason.title')}</p>
      <Select
        controlShouldRenderValue={true}
        placeholder={translate('snooze.reason.placeholder')}
        isMulti={false}
        options={REASON_OPTIONS}
        name='snoozeReason'
        isFormSelect
        selectedValue={selectedOption}
        onChange={handleSelect}
      />
      <div className='mt-8 flex gap-x-5 text-sm'>
        <Button onClick={onCancel} className='flex-1 px-6 py-3 bg-LIGHT_GRAY'>
          {translate('cancel.cancel')}
        </Button>
        <Button
          onClick={handleNext}
          disabled={!selectedOption?.value}
          className='flex-1 px-6 py-3 bg-PRIMARY text-white'
        >
          {translate('next_button.next')}
        </Button>
      </div>
    </div>
  );
};

export default SnoozeReason;
