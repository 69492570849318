import { createContext, useContext } from 'react';

// Create a context with an initial state
const FilterContext = createContext<any>({});

const useFilterContext = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyProvider');
  }
  return context;
};

export { FilterContext, useFilterContext };
