import { FC, ReactNode } from 'react';

import cx from 'classnames';
interface Props {
  title: string;
  value: string | number;
  isDisabled: boolean;
  icon?: ReactNode;
  className?: string;
}
const LabelValueCard: FC<Props> = ({
  title,
  value,
  icon,
  className = '',
  isDisabled
}) => {
  return (
    <div>
      <h3 className='text-GRAY3 mb-1 mt-6px text-sm font-normal'>{title}</h3>
      <div
        className={cx('flex flex-row gap-x-2', {
          'text-DISABLED_2': isDisabled
        })}
      >
        {icon}
        <p className={`text-17px ${className}`}>{value}</p>
      </div>
    </div>
  );
};

export default LabelValueCard;
