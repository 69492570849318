import { FC, useEffect } from 'react';

import ReactJoyride, {
  ACTIONS,
  CallBackProps,
  Step,
  Styles
} from 'react-joyride';

import { FEATURE_WALKTHROUGH } from 'constants/common';
import { useUpdateUserPreference } from 'services/hooks/TemplateSettings.hooks';

import GuideDetails from './GuideDetails/GuideDetails';

type GuideProps = {
  steps: Step[];
  run: boolean;
};

const CustomStyles: Styles = {
  overlay: {
    backgroundColor: 'transparent',
    mixBlendMode: 'normal',
    backgroundBlendMode: 'normal',
    zIndex: 3
  },
  spotlight: {
    display: 'none'
  }
};
const Guide: FC<GuideProps> = ({ steps, run }) => {
  const [updateUserPreference] = useUpdateUserPreference();
  //for the tooltip not moving while navbar open & close
  useEffect(() => {
    const modalContainer = document.getElementById('modal-container');

    const observer = new ResizeObserver(() => {
      //trigger resize event for toggle to reposition
      window.dispatchEvent(new Event('resize'));
    });
    observer.observe(modalContainer as HTMLElement, { box: 'border-box' });
    return () => {
      observer.disconnect();
    };
  }, []);

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, step, lifecycle } = data;

    if (
      action === ACTIONS.NEXT &&
      step.target === '#SIDE_TAB_TOGGLE_BUTTON' &&
      lifecycle === 'complete'
    ) {
      updateUserPreference({
        variables: {
          name: FEATURE_WALKTHROUGH,
          preference: { featureWalkThroughShown: true }
        }
      });
    }
  };

  return (
    <ReactJoyride
      disableOverlayClose={true}
      callback={handleJoyrideCallback}
      steps={steps}
      run={run}
      continuous
      showSkipButton={false}
      hideBackButton
      disableScrolling
      tooltipComponent={GuideDetails}
      disableScrollParentFix
      styles={CustomStyles}
      floaterProps={{ disableAnimation: true }}
    />
  );
};

export default Guide;
