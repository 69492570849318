import { FC } from 'react';

import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import { FormLabel } from 'components/FormLabel/FormLabel';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import {
  ARCHES_TO_TREAT_OPTIONS,
  ARCHES_TO_TREAT_OPTIONS_EXTERNAL
} from 'constants/TPVFormOptions';
import { getSelectedOption } from 'utils/tpvUtils';

interface InputProps {
  disabled?: boolean;
  inputName: string;
  label: string;
  isNewTemplatizedPrescription?: boolean;
}
const ArchesToTreatInput: FC<InputProps> = ({
  inputName,
  disabled,
  label,
  isNewTemplatizedPrescription = false
}) => {
  const {
    control,
    watch,
    formState: { errors }
  } = useFormContext();
  const archesValue = watch(inputName);

  return (
    <div className='relative'>
      <Controller
        control={control}
        name={inputName}
        render={({ field }) => (
          <>
            <FormLabel
              label={label}
              error={!!_.get(errors, inputName)}
              name={inputName}
            />
            <div className='md:w-325px mt-4'>
              <RhfSelect
                name={inputName}
                options={
                  isNewTemplatizedPrescription
                    ? ARCHES_TO_TREAT_OPTIONS_EXTERNAL
                    : ARCHES_TO_TREAT_OPTIONS
                }
                onChange={(data) => {
                  field.onChange(data?.value);
                }}
                isDisabled={disabled}
                inputProps={{
                  controlShouldRenderValue: true,
                  selectedValue: getSelectedOption(
                    archesValue,
                    isNewTemplatizedPrescription
                      ? ARCHES_TO_TREAT_OPTIONS_EXTERNAL
                      : ARCHES_TO_TREAT_OPTIONS
                  )
                }}
              />
            </div>
          </>
        )}
      />
    </div>
  );
};

export default ArchesToTreatInput;
