import { FC } from 'react';

import { TooltipRenderProps } from 'react-joyride';

import { Button } from 'components';
import { translate } from 'utils';

const GuideDetails: FC<TooltipRenderProps> = ({
  tooltipProps,
  skipProps,
  primaryProps,
  index,
  size,
  step
}) => {
  const isLastStep = index === size - 1;
  return (
    <div
      {...tooltipProps}
      className='bg-white p-4 md:w-80 w-auto md:mx-0 mx-6 rounded-lg'
    >
      <header>
        <h2 className='text-DEFAULT_TEXT text-base leading-5'>
          {translate(step.title as string)}
        </h2>
      </header>
      <article className='mt-2'>
        <p className='text-DEFAULT_TEXT text-sm leading-5'>
          {translate(step.content as string)}
        </p>
      </article>
      <footer className='flex md:flex-row text-sm leading-6 align-middle mt-4'>
        <div className='flex items-center flex-1 space-x-2'>
          {[...Array(size)].map((_, position) => (
            <div
              className={`rounded-full ${
                position === index
                  ? 'h-2 w-2 bg-PRIMARY'
                  : 'h-1.5 w-1.5 bg-LIGHT_BLUE_3'
              }`}
              key={position}
            ></div>
          ))}
        </div>
        <div className='flex justify-end text-sm leading-5'>
          {!isLastStep && (
            <Button
              className='text-DEFAULT_TEXT text-center'
              onClick={skipProps.onClick}
            >
              {translate('skip_button.skip')}
            </Button>
          )}
          <Button
            className='bg-PRIMARY text-white text-center py-2 px-4 md:w-auto w-full ml-6'
            onClick={primaryProps.onClick}
          >
            {isLastStep
              ? translate('COMMON.BUTTON.DONE')
              : translate('next_button.next')}
          </Button>
        </div>
      </footer>
    </div>
  );
};

export default GuideDetails;
