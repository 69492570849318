import { FC, useEffect, useRef, useState } from 'react';

// eslint-disable-next-line import/no-unresolved
import { GetPresignedUrlOutput } from '@chat/chat-sdk/dist/types';
import cx from 'classnames';

import { ErrorIcon, UploadSmallIcon } from 'assets/icons';
import chatClient from 'chatSDK';
import FilesUpload from 'components/FilesUpload/FilesUpload';
import { If } from 'components/Generics';
import PatientFileUpload from 'components/PatientFileUpload/PatientFileUpload';
import ZoomPhotoPopup from 'components/ZoomPhotoPopup/ZoomPhotoPopup';
import { REQUEST_METHODS } from 'constants/index';
import { callApi } from 'services/api/request';
import { FilesData } from 'types/Files.types';
import { isDataExist, translate } from 'utils';
import { showToast } from 'utils/toast/toast.util';

type ReferralFileUploaderProps = {
  value?: FilesData[];
  onChange: (filels: FilesData[]) => void;
  allowedFileMimeType?: string;
  fileDropAreaTitle?: string;
  title?: string;
  className?: string;
  errorMessage?: string;
  sequenceId: number | undefined;
  isUploading?: (value: boolean) => void;
  isFromIntreatment?: boolean;
  uploadButtonName?: string;
};

const NewChatFileUpload: FC<ReferralFileUploaderProps> = ({
  title = '',
  onChange,
  value = [],
  allowedFileMimeType = '',
  fileDropAreaTitle = '',
  uploadButtonName = '',
  className = '',
  errorMessage = '',
  isFromIntreatment = false,
  sequenceId,
  isUploading
}) => {
  const [zoomPhoto, toggleZoomPhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<string | number>(0);
  const [files, setFiles] = useState<FilesData[]>(value);
  const [errorFileState] = useState<FilesData | undefined>(undefined);
  const [pendingFilesToUploadCount, setPendingFilesToUploadCount] = useState(0);
  const isFirstRender = useRef(true);
  const chat = chatClient.getChatClient();
  const [uploadingFilesCount, setUploadingFilesCount] = useState<number>(0); // Number of files being uploaded.
  useEffect(() => {
    pendingFilesToUploadCount === 0 && isUploading?.(false);
  }, [pendingFilesToUploadCount]);

  const uploadFiles = (files: FilesData[]) => {
    const apiInput = files.map((file) => ({
      fileName: file.file?.name,
      entityId: `${sequenceId}`,
      entityType: 'TICKET'
    }));
    try {
      chat.getPresignedUploadUrl(
        apiInput,
        (data: GetPresignedUrlOutput[]) => {
          const uploadingFiles = data.map(async (uploadCreds, index) => {
            try {
              await uploadFileToS3(uploadCreds, files[index]);
              updateUploadedFileDetails(uploadCreds, files[index]);
              setPendingFilesToUploadCount((count) => count - 1);
            } catch {
              setPendingFilesToUploadCount((count) => count - 1);
            }
          });
          Promise.all(uploadingFiles)
            .then(() => {
              showToast(
                translate('success.multiplePhotosUploaded', {
                  x: files.length
                }),
                true
              );
              setUploadingFilesCount(0);
            })
            .catch(() => {
              showToast(translate('uploadingStatus.failedToast'), false);
              setUploadingFilesCount(0);
            });
        },
        (error: any) => {
          console.log('error', error);
          setPendingFilesToUploadCount(0);
        }
      );
    } catch (error) {
      console.log(error);
      isUploading?.(false);
    }
  };
  /**
   * to remove errored file from file state
   */
  useEffect(() => {
    if (errorFileState)
      setFiles((curretFiles) => {
        return curretFiles.filter(({ id }) => id !== errorFileState.id);
      });
  }, [errorFileState]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      onChange(files);
    }
  }, [files, onChange]);

  const updateUploadedFileDetails = (
    uploadedFile: GetPresignedUrlOutput,
    file: FilesData
  ) => {
    setFiles((files) => {
      const updatedFile = files.find(({ id }) => id === file.id);
      if (updatedFile) {
        updatedFile.fileName = uploadedFile.fileName;
        updatedFile.filePath = uploadedFile.filePath;
        updatedFile.fileId = updatedFile.fileName;
        return [...files];
      }
      return files;
    });
  };

  const onFilesChange = (files: FilesData[]) => {
    isUploading?.(true);
    setPendingFilesToUploadCount((count) => count + files.length);
    setFiles((currentFiles) => currentFiles.concat(files));
    const numberOfFiles = files?.length;
    setUploadingFilesCount(numberOfFiles);
    uploadFiles(files);
  };

  const uploadFileToS3 = async (
    { uploadUrl }: GetPresignedUrlOutput,
    file: FilesData
  ) => {
    const onUploadProgress = (progressEvent: ProgressEvent) => {
      const progress = (progressEvent.loaded * 100) / progressEvent.total;
      setFiles((files) => {
        const updatedFile = files.find(({ id }) => id === file.id);
        if (updatedFile) {
          updatedFile.uploadProgress = progress;
          return [...files];
        }
        file.uploadProgress = progress;
        return files.concat(file);
      });
    };
    const imgBody = new Blob([file.file as File], { type: file.file?.type });
    return callApi({
      payload: { method: REQUEST_METHODS.PUT, url: uploadUrl, data: imgBody },
      headers: { 'Content-Type': `${file.file?.type}` },
      onUploadProgress
    });
  };

  const onDelete = (
    selectedId: string | number,
    localId: string | number | undefined
  ) => {
    if (localId) {
      const deletedFile = files.find((file) => file.id === localId);
      if (deletedFile) {
        const { filePath } = deletedFile;
        chat.deleteFile({ filePath });
      }
      setFiles((files) => {
        return files.filter(({ id }) => id !== localId);
      });
    }
  };

  return (
    <>
      <If condition={isFromIntreatment}>
        <PatientFileUpload
          title={title}
          zoomPhoto={zoomPhoto}
          toggleZoomPhoto={toggleZoomPhoto}
          selectedPhoto={selectedPhoto as string}
          setSelectedPhoto={setSelectedPhoto}
          uploadingFilesCount={uploadingFilesCount}
          files={files}
          value={files}
          errorMessage={errorMessage}
          onFilesChange={onFilesChange}
          onDelete={onDelete}
          allowedFileMimeType={allowedFileMimeType}
          fileDropAreaTitle={fileDropAreaTitle}
          className={className}
          uploadButtonName={uploadButtonName}
          dropContainerClassName={'bg-GRAY5 rounded-xl p-4 md:p-0'}
        />
      </If>
      <If condition={!isFromIntreatment}>
        <p className='text-sm text-DISABLED_2'>
          {uploadingFilesCount
            ? translate('uploadingStatus.uploading', {
                x: uploadingFilesCount
              })
            : files?.length > 0 &&
              translate('uploadingStatus.uploaded', { x: files?.length })}
        </p>
        <div className={cx('w-full h-full', { 'mt-4': isDataExist(files) })}>
          <div className='mt-2'>
            <FilesUpload
              onFilesChange={onFilesChange}
              files={files}
              onDelete={onDelete}
              allowedFileMimeType={allowedFileMimeType}
              fileDropAreaTitle={fileDropAreaTitle}
              className={className}
              toggleZoomPhoto={toggleZoomPhoto}
              setSelectedPhoto={setSelectedPhoto}
            >
              <div className='flex text-center  mt-6 md:mt-4 items-center text-PRIMARY text-sm cursor-pointer'>
                <UploadSmallIcon />
                <p className='ml-3'>{translate('messageBox.attachment')}</p>
              </div>
            </FilesUpload>
            {errorMessage && (
              <p className='text-ERROR text-xs mt-2 flex '>
                <ErrorIcon />
                <span className='ml-2'>{errorMessage}</span>
              </p>
            )}
          </div>
          <ZoomPhotoPopup
            allFiles={files}
            zoomPhoto={zoomPhoto}
            toggleZoomPhoto={toggleZoomPhoto}
            selectedPhoto={selectedPhoto as string}
            setSelectedPhoto={setSelectedPhoto}
          />
        </div>
      </If>
    </>
  );
};

export default NewChatFileUpload;
