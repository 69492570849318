import { FC } from 'react';

import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { LogoIcon, MenuIcon } from 'assets/icons';
import Avatar from 'components/Avatar/Avatar';
import { If } from 'components/Generics';
import { pagePaths } from 'config/pages';
import { GlobalClinicSelector } from 'containers/GlobalClinicSelector/GlobalClinicSelector';
import { loginedUserDetails } from 'store/atoms';
import { globalSelectedClinic } from 'store/atoms/authAtom';
import { checkIsExternal, getInitials } from 'utils/common';

interface AppBarProps {
  handleMenuClick?: () => void;
}

export const AppBar: FC<AppBarProps> = ({ handleMenuClick }) => {
  const userDetails = useRecoilValue(loginedUserDetails);
  const selectedClinic = useRecoilValue(globalSelectedClinic);
  return (
    <div className='fixed z-30 md:z-41 top-0 w-full shadow-appBar h-14 md:px-12 flex items-center justify-between bg-white xxs:px-6 border-b-1 border-CORE_GRAY'>
      <div className='w-1/3'>
        <button
          className='hover:bg-gray-100 transition-colors'
          data-testid='menu-button'
          onClick={handleMenuClick}
        >
          <MenuIcon />
        </button>
      </div>
      <div className='w-1/3 flex justify-center items-center'>
        <Link to={pagePaths.dashboard}>
          <LogoIcon id='nav-logo' className='md:mr-4 h-6 w-34 text-PRIMARY' />
        </Link>
      </div>
      <div className='flex items-center space-x-6 w-1/3 justify-end'>
        <If
          condition={
            !!userDetails?.id &&
            !!selectedClinic &&
            checkIsExternal(userDetails?.groups) &&
            userDetails?.headClinics?.length > 1
          }
        >
          <div className='xxs:hidden md:block w-264px'>
            <GlobalClinicSelector />
          </div>
        </If>

        <Link to={pagePaths?.profileSettings} className='w-8 h-8'>
          <Avatar
            image={userDetails?.profilePicture}
            name={getInitials({
              firstName: userDetails?.firstName?.[0],
              lastName: userDetails?.lastName?.[0]
            })}
          />
        </Link>
      </div>
    </div>
  );
};
