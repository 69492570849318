import { FC } from 'react';

import { translate, customTwMerge as twMerge } from 'utils';

type PrescriptionDetailsProp = {
  label?: string;
  value?: string;
  valueClassName?: string;
};

const PrescriptionDetails: FC<PrescriptionDetailsProp> = ({
  label = '-',
  value = '',
  valueClassName
}) => {
  return (
    <div className='font-normal'>
      <p className='text-GRAY3 text-sm mb-2'>{label}</p>
      <p
        className={twMerge(
          'md:text-17px text-base text-DEFAULT_TEXT',
          valueClassName
        )}
      >
        {translate(value) || '-'}
      </p>
    </div>
  );
};

export default PrescriptionDetails;
