import { FC } from 'react';

import classNames from 'classnames';

import AnimateLabel from 'components/AnimateLabel/AnimateLabel';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { customTwMerge as twMerge } from 'utils';

type Props = {
  error?: boolean;
  disabled?: boolean;
  label: string;
  className?: string;
  name?: string;
};

export const FormLabel: FC<Props> = ({
  error = false,
  disabled = false,
  label,
  className,
  name
}) => {
  const { animatedField, callback } = useFieldRefContext();
  return (
    <div
      ref={callback?.(name as string)}
      className={twMerge(
        classNames(' font-normal relative overflow-hidden', {
          'text-ERROR': error,
          'text-DEFAULT_TEXT': !error && !disabled,
          'text-DISABLED': disabled
        }),
        className
      )}
    >
      {label}
      <AnimateLabel
        className='text-ERROR md:text-17px text-base font-normal pb-1'
        animate={animatedField === name}
      >
        {label}
      </AnimateLabel>
    </div>
  );
};
