import { FC } from 'react';

import classNames from 'classnames';

interface PreviewImageProps {
  url: string;
  isSelected: boolean;
  onClick(): void;
}

const PreviewImage: FC<PreviewImageProps> = ({ url, isSelected, onClick }) => {
  return (
    <div className='preview-image'>
      <button className='w-20 h-20 shrink-0 ml-4' onClick={onClick}>
        <img
          alt='thumbnail'
          src={url}
          className={classNames('h-20 w-20 object-cover rounded-2xl', {
            'border-2 border-PRIMARY': isSelected
          })}
        />
      </button>
    </div>
  );
};

export default PreviewImage;
