import { FC } from 'react';

import { useRecoilState } from 'recoil';

import { HeadClinicSelector } from 'containers/HeadClinicSelector/HeadClinicSelector';
import { globalSelectedClinic } from 'store/atoms/authAtom';
import { useChangeClinic } from 'utils/customHooks/useChangeClinic';

export const GlobalClinicSelector: FC = () => {
  const [selectedClinic, setSelectedClinic] =
    useRecoilState(globalSelectedClinic);
  const [selectClinic] = useChangeClinic();

  return (
    <HeadClinicSelector
      selectedClinic={selectedClinic}
      onChange={(selected) => {
        setSelectedClinic(selected);
        selectClinic({
          variables: {
            clinicId: selected?.value
          }
        });
      }}
      styles={{
        control: (base: any) => ({
          ...base,
          height: '3rem',
          backgroundColor: 'FFFFFF',
          border: '1px solid #EEEEEE',
          padding: '0.625rem 0.5rem',
          boxShadow: 'none',
          cursor: 'pointer',
          borderRadius: '0.25rem',
          minHeight: '3rem',
          fontSize: '0.875rem',
          flexWrap: 'nowrap',
          width: '100%',
          '&:hover': {
            border: '1px solid #EEEEEE'
          },
          '@media only screen and (min-width: 768px)': {
            height: '2.5rem',
            minHeight: '2.5rem'
          }
        })
      }}
    />
  );
};
