import * as yup from 'yup';

export const InboxAdvanceSearch = yup.object().shape({
  email: yup
    .string()
    .required('required.email')
    .matches(
      /^\s*(([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+)*\s*$/,
      'error.emailInvalid'
    ),
  setIsOpen: yup.boolean()
});
