import {
  useBulkAssignTickets,
  useGetTickets
} from 'services/hooks/Ticket.hooks';
import { BULK_ASSIGN_TICKETS } from 'services/query/inbox';
import {
  BulkAssignAction,
  BulkAssignActionKind,
  OwnerInfo
} from 'types/Inbox.types';
import { showToast } from 'utils/toast/toast.util';

interface Props {
  dispatch?: React.Dispatch<BulkAssignAction>;
}

const useBulkAssignHooks = ({ dispatch }: Props) => {
  const [bulkAssignTicket, { loading: bulkAssignChatsLoading }] =
    useBulkAssignTickets({
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        showToast(
          `We have successfully reassigned ${data.bulkReassignTicketOwner?.length} chats`,
          true
        );
      },
      refetchQueries: [BULK_ASSIGN_TICKETS]
    });
  const [getTicketDetails] = useGetTickets({
    fetchPolicy: 'network-only'
  });
  const bulkAssignChats = (extractedTicketIds?: number[], userId?: string) => {
    bulkAssignTicket({
      variables: {
        ticketIds: extractedTicketIds,
        userId: userId
      }
    });
  };
  const getTicketsCount = (ownerFrom: OwnerInfo, ownerTo: OwnerInfo) => {
    getTicketDetails({
      variables: {
        input: {
          paginationInfo: {
            offset: 0,
            limit: 50
          },
          advancedSearch: {
            includeParticipantId: ownerFrom?.userId,
            isIncludedParticipantOwner: true
          }
        }
      },
      onCompleted: (data) => {
        const { getTickets } = data;
        dispatch?.({
          type: BulkAssignActionKind.SET_OWNER_DETAILS,
          payload: {
            ownerFrom,
            ownerTo,
            ticketCount: getTickets.totalCount,
            ticketIds: getTickets.tickets,
            isCountLoading: false
          }
        });
      }
    });
  };
  return { bulkAssignChats, getTicketsCount, bulkAssignChatsLoading };
};

export { useBulkAssignHooks };
