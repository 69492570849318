import { selector } from 'recoil';

import { USER_ROLES } from 'constants/common';
import { loginedUserDetails } from 'store/atoms';
import { isDataExist } from 'utils';
import { checkIsExternal } from 'utils/common';

import { SELECTORS } from './constants';

export const useIsExternalUser = selector({
  key: SELECTORS.IS_EXTERNAL_USER,
  get: ({ get }) => {
    const { groups } = get(loginedUserDetails);
    return checkIsExternal(groups);
  }
});

export const getLoginedUserRoles = selector({
  key: SELECTORS.GET_USER_CHAT_ROLES,
  get: ({ get }) => {
    const { groups } = get(loginedUserDetails);
    const roles: string[] = [];
    if (isDataExist(groups)) {
      groups.forEach((group) => {
        switch (group) {
          case USER_ROLES.TREATMENT_PLANNING_LEAD:
            roles.push(USER_ROLES.TREATMENT_PLANNING_DOCTOR);
            break;
          case USER_ROLES.EXTERNAL_DOCTOR:
            roles.push(USER_ROLES.EXTERNAL_DOCTOR);
            break;
          case USER_ROLES.PRE_ASSESSMENT_LEAD:
            roles.push(USER_ROLES.PRE_ASSESSMENT_DOCTOR);
            break;
          case USER_ROLES.ADMIN:
            // TODO: Admin needs to be handeld
            break;
          case USER_ROLES.CHAT_OWNER:
            // TODO: Admin needs to be handeld
            break;
          case USER_ROLES.PARTNERSHIP_MANAGER:
            // TODO: Admin needs to be handeld
            break;
          default:
            roles.push(group);
            break;
        }
      });
    }
    return roles;
  }
});

export const getLoginedUserClinics = selector({
  key: SELECTORS.GET_LOGINED_USER_CLINICS,
  get: ({ get }) => {
    const { clinic } = get(loginedUserDetails);
    let clinics: string[] = [];
    if (clinic && isDataExist(clinic)) {
      clinics = clinic.map((clinicDetails) => clinicDetails.id);
    }
    return clinics;
  }
});

export const getCurrentBranches = selector({
  key: SELECTORS.GET_CURRENT_BRANCHES,
  get: ({ get }) => {
    const { clinic = [], headClinicId = '' } = get(loginedUserDetails);
    return clinic.filter((clinic) => clinic.headClinicId === headClinicId);
  }
});
