import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import CollapsibleMenu from 'components/CollapsibleMenu/CollapsibleMenu';
import { translate } from 'utils';

import PriorTreatmentNeeded from '../PriorTreatmentNeeded/PriorTreatmentNeeded';

type PriorTreatmentNeededCollapsibleProps = {
  name?: string;
  className?: string;
  type?: 'REFERRALS' | 'PENDING_PRESCRIPTION';
  patientId?: string;
  isExpanded: boolean;
  toggleExpand: (value: Record<string, boolean>) => void;
  isReferral?: boolean;
  isDisabled?: boolean;
  status?: string;
  priorTreatmentClassName?: string;
};

const PriorTreatmentNeededCollapsible: FC<
  PriorTreatmentNeededCollapsibleProps
> = ({
  name = 'priorTreatment',
  className = '',
  type,
  patientId,
  isExpanded = false,
  toggleExpand,
  isReferral = false,
  isDisabled = false,
  status,
  priorTreatmentClassName
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <CollapsibleMenu
          status={status}
          isPrescription={true}
          title={translate('prescriptions.1._prior_treatment_needed')}
          className={className}
          showErrorTitle={!!error}
          toggleExpand={(isExpanded) => toggleExpand({ [name]: isExpanded })}
          isExpanded={isExpanded}
          animateError={true}
          titleFont={`${
            !isReferral ? 'md:text-collapsible text-base' : 'text-17px'
          }`}
        >
          <PriorTreatmentNeeded
            isDisabled={isDisabled}
            onChange={field.onChange}
            value={field.value}
            error={error}
            type={type}
            patientId={patientId}
            className={priorTreatmentClassName}
          />
        </CollapsibleMenu>
      )}
    />
  );
};

export default PriorTreatmentNeededCollapsible;
