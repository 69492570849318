import { FC, useRef } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import { Button, Modal, UserList } from 'components';
import { INBOX_GROUP_NAME } from 'constants/inbox';
import { CHAT_TRACK_EVENTS } from 'constants/segment';
import TeamMemberAutoSuggest from 'containers/generics/TeamMemberAutoSuggest/TeamMemberAutoSuggest';
import { useAddUserToTicket, useGetGroups } from 'services/hooks/Inbox.hooks';
import {
  useSegmentTrackChat,
  useTrackData
} from 'services/hooks/Segment.hooks';
import { useGetTicketById } from 'services/hooks/Ticket.hooks';
import { loginedUserDetails, selectedTicket } from 'store/atoms';
import { Participant, Staff } from 'types/Inbox.types';
import { translate } from 'utils';
import {
  checkIsExternal,
  getFullName,
  getParticipantCount
} from 'utils/common';
import { useSetError } from 'utils/customHooks/useSetError';
import { getSegmentUserDetails } from 'utils/inbox';

interface Props {
  onClose: () => void;
  participantsList: Participant[];
  isAddParticipantPopupOpen: boolean;
  isFromTicketDetails?: boolean;
  setParticipantsList?: (participants: Participant[]) => void;
}

const AddTeamMember: FC<Props> = ({
  onClose,
  participantsList,
  isAddParticipantPopupOpen,
  isFromTicketDetails = false,
  setParticipantsList
}) => {
  const loginedUser = useRecoilValue(loginedUserDetails);
  const [selectedTicketDetails, setSelectedTicketDetails] =
    useRecoilState(selectedTicket);
  const selectedUser = useRef<Staff>();
  const handleSegmentTrack = useSegmentTrackChat();
  const { trackData, trackContextData, trackPatientData } = useTrackData();

  const { setError } = useSetError();
  const { data: groupDetails } = useGetGroups();

  const [getSelectedDetailsByID] = useGetTicketById({
    onCompleted: (data) => {
      const zenchatUpdateUserBqData = {
        zenchat_ID: trackData.zenchat_ID,
        opportunity_name: trackPatientData.opportunity_name,
        patient_name: trackPatientData.patient_name,
        patient_country: trackPatientData.patient_country,
        zenchat_status: trackData.zenchat_status,
        zenchat_urgent: trackData.zenchat_urgent,
        zenchat_topic: trackData.zenchat_topic,
        chat_created_ts: trackData.chat_created_ts,
        no_of_participants: getParticipantCount(data.getTicketById.ticketUsers),
        ...getSegmentUserDetails(data.getTicketById.ticketUsers)
      };
      handleSegmentTrack(
        CHAT_TRACK_EVENTS.ZENCHAT_USER_UPDATE_BQ,
        zenchatUpdateUserBqData
      );
      setSelectedTicketDetails(data.getTicketById);
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  const [addUserToTicket] = useAddUserToTicket({
    onCompleted: () => {
      getSelectedDetailsByID({
        variables: {
          ticketId: selectedTicketDetails.id,
          loginedUserId: loginedUser.id
        }
      });
      const { pathname } = window.location;
      const { zenchat_ID, zenchat_topic, user_permission } = trackData;
      const {
        treatment_plan_id,
        treatment_plan_version_id,
        treatment_plan_version,
        treatment_plan_type
      } = trackContextData;
      handleSegmentTrack(CHAT_TRACK_EVENTS.PARTICIPANT_ADDED, {
        zenchat_ID,
        zenchat_topic,
        user_permission,
        treatment_plan_id,
        treatment_plan_version_id,
        treatment_plan_version,
        treatment_plan_type,
        is_participant: 'yes',
        is_chat_created: pathname.endsWith('newChat') ? 'no' : 'yes',
        no_of_participants: getParticipantCount(participantsList) + 1
      });

      const message = translate('INBOX.ADDED_TO', {
        X: getFullName({
          firstName: selectedUser.current?.firstName,
          lastName: selectedUser.current?.lastName,
          salutation: ''
        })
      });

      setError(message, true, 'z-1000');
    }
  });

  const getGroupTeamId = (groupName = INBOX_GROUP_NAME.CHAT_MEMBER) =>
    groupDetails?.getGroups.find((group) => group.name === groupName)?.id;

  const handleAddTeamMember = (userDetails: Staff | undefined) => {
    selectedUser.current = userDetails;
    if (isFromTicketDetails) {
      const param = {
        ticketId: selectedTicketDetails.id,
        input: {
          groupId: getGroupTeamId(),
          userId: userDetails?.id,
          isExternal: checkIsExternal(userDetails?.groups || [])
        }
      };
      addUserToTicket({ variables: { ...param } });
    } else {
      const checkNewParticipantExist = participantsList.findIndex(
        (partticipant) => partticipant.userId === userDetails?.id
      );
      if (checkNewParticipantExist === -1) {
        const newParticipantInfo: Participant = {
          userId: userDetails?.id || '',
          userTeamCode: userDetails?.groups[0],
          groupId: getGroupTeamId() || '',
          groupName: INBOX_GROUP_NAME.CHAT_MEMBER,
          isExternal: checkIsExternal(userDetails?.groups || []),
          userFirstName: userDetails?.firstName || '',
          userLastName: userDetails?.lastName || ''
        };
        setParticipantsList?.([...participantsList, newParticipantInfo]);
        const { zenchat_ID, zenchat_topic, user_permission } = trackData;
        const {
          treatment_plan_id,
          treatment_plan_version_id,
          treatment_plan_version,
          treatment_plan_type
        } = trackContextData;
        handleSegmentTrack(CHAT_TRACK_EVENTS.PARTICIPANT_ADDED, {
          zenchat_ID,
          zenchat_topic,
          user_permission,
          treatment_plan_id,
          treatment_plan_version_id,
          treatment_plan_version,
          treatment_plan_type,
          is_participant: 'yes',
          is_chat_created: 'no',
          no_of_participants: getParticipantCount(participantsList) + 1
        });
      } else {
        setError(
          translate('INBOX.ALREADY_EXIST', {
            X: getFullName({
              firstName: userDetails?.firstName,
              lastName: userDetails?.lastName,
              salutation: ''
            })
          })
        );
      }
    }
  };
  return (
    <Modal open={isAddParticipantPopupOpen} onClose={onClose}>
      <div className='px-4 pt-10 pb-4 max-w-90vw md:pb-8 md:pt-14 md:px-8'>
        <section className='max-w-50vw md:w-100 h-50vh md:min-h-35vh flex flex-col justify-between'>
          <div>
            <TeamMemberAutoSuggest
              onSuggestionClick={handleAddTeamMember}
              participantsList={participantsList}
              searchListWrapperClassName='max-h-72 md:!mt-0 !rounded-sm'
            />
            <div className='max-h-60  overflow-y-scroll'>
              <UserList
                participantsList={participantsList}
                isFromTicketDetails={isFromTicketDetails}
                setParticipantsList={setParticipantsList}
              />
            </div>
          </div>
          <div className='flex w-full justify-end mt-2 '>
            <Button
              className='bg-PRIMARY px-8 text-WHITE'
              type='button'
              onClick={onClose}
            >
              {translate('COMMON.BUTTON.DONE')}
            </Button>
          </div>
        </section>
      </div>
    </Modal>
  );
};

export default AddTeamMember;
