import { FC, ReactNode, useState } from 'react';

import cx from 'classnames';

import { DownArrowIcon } from 'assets/icons';
import { LeaveStatus } from 'components';
import Avatar from 'components/Avatar/Avatar';

interface Props {
  title: string;
  icon?: string;
  roles?: string;
  showProfilePic?: boolean;
  isAvailable?: boolean;
  children?: ReactNode;
}

const CustomCollapsibleMenu: FC<Props> = ({
  title,
  roles,
  icon,
  isAvailable = true,
  showProfilePic = true,
  children
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => {
    setIsOpen((oldState) => !oldState);
  };

  return (
    <section className='bg-GRAY_6 mb-1 p-3 rounded-lg'>
      <button onClick={toggleIsOpen} className='w-full' type='button'>
        <div className='flex justify-between items-center bg-GRAY_6  rounded-lg '>
          <div className='flex justify-center items-center text-center align-middle'>
            {showProfilePic && (
              <div className='w-4 h-4'>
                <Avatar image={icon} className='' />
              </div>
            )}
            <div className='ml-5 text-sm'>{title}</div>
          </div>
          <div className='flex'>
            <div className='text-sm text-PRIMARY mr-5'>{roles}</div>
            <LeaveStatus isAvailable={isAvailable} />
            <div className='ml-5 flex items-center'>
              <DownArrowIcon
                className={cx(
                  'text-DEFAULT_TEXT',
                  {
                    'transform duration-500  rotate-180': isOpen
                  },
                  {
                    'transform duration-500  rotate-0': !isOpen
                  }
                )}
              />
            </div>
          </div>
        </div>
      </button>

      <div
        className={cx({
          'max-h-[200vh]': isOpen,
          'max-h-0': !isOpen
        })}
      >
        {isOpen && children}
      </div>
    </section>
  );
};

export default CustomCollapsibleMenu;
