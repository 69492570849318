import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import PreviewFiles from 'components/PreviewFiles/PreviewFiles';
import { FilesData, ReferralDocumentType } from 'types/Files.types';
import { translate } from 'utils';

import ReferralFileUploader from '../ReferralFileUploader';
type RhfReferralFileUploaderProps = {
  referralId: string;
  name: string;
  referralDocumentType: ReferralDocumentType;
  allowedFileMimeType?: string;
  fileDropAreaTitle: string;
  title?: string;
  className?: string;
  isMandatory?: boolean;
  showUploadImagesButton?: boolean;
  onFileuploadSuccess?: (data: FilesData[]) => void;
  allFiles?: FilesData[];
  containerStyle?: string;
  onUploadButtonClick?: () => void;
  uploadButtonHandler?: () => void;
  disableEdit?: boolean;
};

const RhfReferralFileUploader: FC<RhfReferralFileUploaderProps> = ({
  name,
  referralId,
  referralDocumentType,
  onFileuploadSuccess,
  fileDropAreaTitle,
  title = '',
  className = '',
  isMandatory = false,
  showUploadImagesButton,
  allowedFileMimeType,
  uploadButtonHandler,
  allFiles,
  containerStyle,
  onUploadButtonClick,
  disableEdit
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const showFilesUpload = !(disableEdit && field?.value?.length > 0);
        return showFilesUpload ? (
          <ReferralFileUploader
            name={name}
            onChange={field.onChange}
            value={field.value}
            referralId={referralId}
            fileDropAreaTitle={fileDropAreaTitle}
            onFileuploadSuccess={onFileuploadSuccess}
            title={title}
            referralDocumentType={referralDocumentType}
            className={className}
            uploadButtonHandler={uploadButtonHandler}
            isMandatory={isMandatory}
            errorMessage={translate(error?.message || '')}
            showUploadImagesButton={showUploadImagesButton}
            allowedFileMimeType={allowedFileMimeType}
            allFiles={allFiles}
            containerStyle={containerStyle}
            onUploadButtonClick={onUploadButtonClick}
            disabled={disableEdit}
          />
        ) : (
          <PreviewFiles
            files={field?.value}
            allFiles={allFiles}
            title={title}
            titleClassName={className}
          />
        );
      }}
    />
  );
};

export default RhfReferralFileUploader;
