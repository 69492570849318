import { FC, useRef, useState } from 'react';

import {
  ChatGallery as ChatGalleryPhotos,
  GetChatGalleryDownloadOutput
  // eslint-disable-next-line import/no-unresolved
} from '@chat/chat-sdk/dist/types';
import { useRecoilValue } from 'recoil';

import {
  AddIcon,
  ChatImageIcon,
  ChatImagesICon,
  GalleryArrow,
  PhotoLibraryIcon
} from 'assets/icons';
import chatClient from 'chatSDK';
import { If } from 'components/Generics';
import HorizontalInfiniteScroll from 'components/InfiniteScroll/HorizontalInfiniteScroll';
import { Loader } from 'components/Loader/Loader';
import { loginedUserDetails } from 'store/atoms';
import { translate } from 'utils';
import { showToast } from 'utils/toast/toast.util';

import GalleryContainer from './GalleryContainer';
import PreviewImage from './PreviewImage';

interface Props {
  chatId: number;
  onClose(): void;
  photos: ChatGalleryPhotos[];
  totalCount: number;
  isLoading: boolean;
  next: () => void;
}

const getPreviewWidth = (
  previewContainerRef: React.RefObject<HTMLDivElement>
) => {
  const children = previewContainerRef.current?.children || [];
  for (let index = 0; index < children.length; ++index) {
    const child = children[index];
    const isPreview = child.classList.contains('preview-image');
    const width = child.clientWidth;
    if (isPreview) return width;
  }
  return 30;
};

const ChatGallery: FC<Props> = ({
  chatId,
  onClose,
  photos,
  isLoading,
  totalCount,
  next
}) => {
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const previewContainerRef = useRef<HTMLDivElement>(null);
  const [downloadingImagesLoading, setDownloadImagesLoading] = useState(false);
  const { email } = useRecoilValue(loginedUserDetails);

  const isInitialLoading = isLoading && photos.length === 0;
  if (isInitialLoading) {
    return (
      <GalleryContainer onClick={onClose}>
        <Loader containerClassName='h-full' />
      </GalleryContainer>
    );
  }

  const noPhotos = photos.length === 0;
  if (noPhotos) {
    return (
      <GalleryContainer onClick={onClose} className='justify-center'>
        <div className='h-[50vh] p-4 m-4 text-center md:h-[39.75rem] md:w-[43.3125rem] bg-CHINESE_WHITE rounded-2xl flex justify-center items-center'>
          <div className='flex flex-col justify-center items-center'>
            <PhotoLibraryIcon className='mb-4 h-[5.625rem] w-[5.625rem] text-DARK_GRAY' />
            <p className='text-DARK_GRAY'>
              {translate('inbox.gallery.no_image')}
            </p>
          </div>
        </div>
      </GalleryContainer>
    );
  }

  const photoUrl =
    photos.length > 0 ? photos[selectedPhotoIndex].preSignedUrl : '';
  const preventClosing = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  const showLeftArrow = selectedPhotoIndex !== 0;
  const showRightArrow = selectedPhotoIndex !== totalCount - 1;
  const isLastOfflineImage = selectedPhotoIndex === photos.length - 1;

  const handleLeftArrowClick = () => {
    setSelectedPhotoIndex((prev) => prev - 1);
    if (previewContainerRef.current) {
      previewContainerRef.current.scrollLeft -=
        getPreviewWidth(previewContainerRef);
    }
  };

  const handleRightArrowClick = () => {
    if (previewContainerRef.current) {
      previewContainerRef.current.scrollLeft +=
        getPreviewWidth(previewContainerRef);
    }
    setSelectedPhotoIndex((prev) => prev + 1);
  };

  const chat = chatClient.getChatClient();

  const handleSingleImageDownload = () => {
    const { name, preSignedUrl } = photos[selectedPhotoIndex];
    const params = {
      url: preSignedUrl,
      fileName: name
    };
    chat.downloadFile(params);
  };

  const handleDownloadAll = () => {
    const variables = {
      email,
      entity_id: chatId.toString(),
      entity_type: 'TICKET',
      media_types: 'IMAGE'
    };

    const onSuccess = ({ message, status }: GetChatGalleryDownloadOutput) => {
      const isSuccess = status === 'SUCCESS';
      if (isSuccess) {
        showToast(translate('inbox.gallery.download'), isSuccess);
      } else {
        showToast(message, isSuccess);
      }
      setDownloadImagesLoading(false);
    };

    const onError = () => {
      showToast(translate('ERROR.DEFAULT_ERROR'), false);
      setDownloadImagesLoading(false);
    };
    setDownloadImagesLoading(true);
    chat.getChatGalleryDownload(variables, onSuccess, onError);
  };

  return (
    <GalleryContainer onClick={onClose}>
      <div className='h-[3.75rem] flex md:block'>
        <div
          className='flex gap-x-4'
          role='presentation'
          onClick={preventClosing}
        >
          <button
            onClick={handleSingleImageDownload}
            className='bg-PRIMARY h-[1.75rem] flex items-center px-2.5 rounded-md gap-x-1.5'
          >
            <ChatImageIcon className='w-5' />
            <p className='text-xs text-white pt-1'>
              {translate('inbox.gallery.save_photos')}
            </p>
          </button>
          <button
            onClick={handleDownloadAll}
            disabled={downloadingImagesLoading}
            className='h-[1.75rem] flex items-center px-2.5 bg-DISABLED_2 rounded-md gap-x-1'
          >
            <ChatImagesICon className='w-5' />
            <p className='text-xs text-white pt-1'>
              {translate('inbox.gallery.save_all')}
            </p>
          </button>
        </div>
        <div className='md:hidden'>
          <AddIcon className='h-5 w-5 rotate-45 ml-6 mt-1' />
        </div>
      </div>
      <div className='h-0 flex-1 mt-5 mb-6'>
        <div className='h-full flex items-center justify-center px-8 md:px-0'>
          <img
            role='presentation'
            src={photoUrl}
            alt='chat gallery'
            className='max-h-full rounded-2xl'
            onClick={preventClosing}
          />
        </div>
      </div>
      <div
        role='presentation'
        className='overflow-y-scroll flex max-w-full'
        onClick={preventClosing}
        ref={previewContainerRef}
      >
        <If condition={showLeftArrow}>
          <button
            className='absolute top-1/2 -translate-y-1/2 left-[0.3125rem] md:left-[3.75rem]'
            onClick={handleLeftArrowClick}
          >
            <GalleryArrow className='w-5 h-5 md:w-10 md:h-10' />
          </button>
        </If>
        <HorizontalInfiniteScroll
          hasMore={photos.length < totalCount}
          dataLength={photos.length}
          next={next}
          scrollableTarget={previewContainerRef}
        >
          {photos.map(({ thumbnailUrl, preSignedUrl }, index: number) => (
            <PreviewImage
              key={preSignedUrl}
              onClick={() => setSelectedPhotoIndex(index)}
              url={thumbnailUrl || preSignedUrl}
              isSelected={index === selectedPhotoIndex}
            />
          ))}
        </HorizontalInfiniteScroll>
        <If condition={showRightArrow}>
          <button
            className='absolute right-[0.3125rem] md:right-[3.75rem] top-1/2 -translate-y-1/2'
            onClick={handleRightArrowClick}
            disabled={isLastOfflineImage}
          >
            <GalleryArrow className='w-5 h-5 md:w-10 md:h-10 rotate-180' />
          </button>
        </If>
      </div>
    </GalleryContainer>
  );
};

export default ChatGallery;
