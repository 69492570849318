import { FC, useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { SubscriptionClient } from 'graphql-subscriptions-client';
import { debounce } from 'lodash';
import { useFormContext } from 'react-hook-form';

import { RHFTextArea } from 'components/RHFTextArea/RHFTextArea';
import { REACT_APP_WS_URL } from 'config';
import { PERMISSIONS } from 'constants/Permissions';
import { useGetTranslation } from 'services/hooks/Translation.hooks';
import { STREAM_TRANSLATION } from 'services/query/translation.queries';
import { TranslateTextOutput } from 'types/TPVForm.types';
import { translate } from 'utils';
import { useTranslateHook } from 'utils/customHooks/translate.hooks';
import { useHasAccess } from 'utils/hasAccess';
import { showToast } from 'utils/toast/toast.util';

interface Props {
  translatedText?: string;
  isTranslatedField?: boolean;
  placeholder?: string;
  InputFieldName?: string;
  translateFieldName?: string;
  isSubmitted?: boolean;
  showCustomHight?: boolean;
  isTranslateFieldDisabled?: boolean;
  setValue: (fieldName: string, result?: string) => void;
  isFormContext?: boolean;
  onInputChange?: (sourceText: string, isTranslatedField?: boolean) => void;
  isTranslating?: boolean;
  isTranslateFieldDefined?: boolean;
}
const TranslateTextArea: FC<Props> = ({
  translatedText,
  isTranslatedField = false,
  isTranslateFieldDisabled,
  isSubmitted,
  placeholder = translate('placeholder_text'),
  InputFieldName = '',
  translateFieldName = '',
  showCustomHight = true,
  setValue,
  isFormContext = true,
  onInputChange,
  isTranslating,
  isTranslateFieldDefined
}) => {
  const translationRef = useRef('');
  const streamId = localStorage.getItem('streamId')
    ? `${localStorage.getItem('streamId')}_${InputFieldName}`
    : InputFieldName;
  const hasStreamAccess = useHasAccess(PERMISSIONS.STREAM_TRANSLATION);
  const [isSubscriptionFailed, setisSubscriptionFailed] = useState(false);

  const { watch } = useFormContext();

  const translatedTextValue = watch(translateFieldName);

  const { getTranslatedText } = useTranslateHook();

  const [getTranslation] = useGetTranslation({
    fetchPolicy: 'no-cache'
  });

  /**
   * @description
   * create instance for streaming
   */
  const client = new SubscriptionClient(REACT_APP_WS_URL, {
    reconnect: true,
    lazy: true,
    connectionCallback: () => {
      setisSubscriptionFailed(true);
    }
  });

  /**
   * @description
   * Open subscription channel for receive messages
   */
  useEffect(() => {
    let subscription: any;
    if (!isTranslatedField) {
      subscription = client
        .request({
          query: STREAM_TRANSLATION,
          variables: {
            id: streamId
          }
        })
        .subscribe({
          next(response: any) {
            const translatedText = response.data.streamTranslation;
            if (translatedText) {
              if (translatedText !== streamId) {
                translationRef.current =
                  translationRef.current + translatedText;
                setValue(translateFieldName, translationRef.current);
              } else {
                translationRef.current = '';
              }
            }
          }
        });
    }
    return () => {
      if (subscription !== undefined) {
        subscription?.unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const textAreaElement = document.getElementById(
      `${translateFieldName}textArea`
    );
    if (textAreaElement) {
      textAreaElement.scrollTop = textAreaElement.scrollHeight;
    }
  }, [translatedText, translatedTextValue, translateFieldName]);

  const debouncedTranslateText = useCallback(
    debounce((sourceText: string) => {
      setValue(translateFieldName, '');
      if (sourceText !== '') {
        if (!isSubscriptionFailed && hasStreamAccess && !isTranslatedField) {
          getTranslation({
            variables: {
              input: {
                sourceText,
                language: 'English',
                context: 'in dental context',
                id: streamId
              }
            }
          });
        } else {
          getTranslatedText(
            sourceText,
            'English',
            (response: TranslateTextOutput) => {
              setValue(translateFieldName, response?.result);
            },
            (error: Error) => {
              showToast(error.message, false);
            }
          );
        }
      }
    }, 500),
    []
  );
  return (
    <div
      className={classNames({
        'bg-WHITE': !isSubmitted,
        'bg-LIGHT_GRAY': isSubmitted
      })}
    >
      {isTranslatedField && (
        <h4 className='text-sm text-PRIMARY mb-5px'>
          {translate('translation.title')}
        </h4>
      )}
      <div>
        {isFormContext ? (
          <RHFTextArea
            inputProps={{
              readOnly:
                isSubmitted ||
                (isTranslateFieldDisabled && !isTranslateFieldDefined),
              placeholder: isTranslating
                ? translate('translation.translating')
                : placeholder,
              onChange: (e) => {
                if (isTranslatedField) {
                  onInputChange?.(e.target.value.trim(), true);
                  setValue(translateFieldName, e.target.value);
                } else {
                  onInputChange?.(e.target.value.trim());
                  setValue(InputFieldName, e.target.value);
                  debouncedTranslateText(e.target.value.trim());
                }
              },
              id: isTranslatedField ? `${translateFieldName}textArea` : ''
            }}
            name={isTranslatedField ? translateFieldName : InputFieldName}
            className={classNames('scroll-smooth', {
              'h-32': !isTranslatedField && showCustomHight,
              'bg-WHITE': !isSubmitted,
              'bg-LIGHT_GRAY': isSubmitted
            })}
            showError={false}
          />
        ) : (
          <textarea
            rows={1}
            readOnly={isTranslateFieldDisabled}
            value={translatedText}
            className={classNames('w-full rounded-8px p-4 scroll-smooth', {
              'h-32': !isTranslatedField && showCustomHight
            })}
            placeholder={
              isTranslating ? translate('translation.translating') : placeholder
            }
            id={isTranslatedField ? `${translateFieldName}textArea` : ''}
            onChange={(e) => {
              if (isTranslatedField) {
                onInputChange?.(e.target.value.trim(), true);
                setValue(translateFieldName, e.target.value);
              } else {
                onInputChange?.(e.target.value.trim());
                setValue(InputFieldName, e.target.value);
                debouncedTranslateText(e.target.value.trim());
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TranslateTextArea;
