import { FC, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Visibility, VisibilityOff, Logo } from 'assets/icons';
import Button from 'components/Button/Button';
import RhfInput from 'components/Generics/Rhf/RhfInput';
import { pagePaths } from 'config/pages';
import { TITLE, TRACK_EVENTS } from 'constants/segment';
import { ForgotPassword, VerifyToken } from 'services/hooks/Auth.hooks';
import { useSegmentPage } from 'services/hooks/Segment.hooks';
import { ResetPasswordFormInput } from 'types/Login.types';
import { translate } from 'utils/locale';
import { showToast } from 'utils/toast/toast.util';
import { ResetPasswordSchema } from 'validations/LoginValidation';

interface Props {
  token: string | (string | null)[];
  title?: string;
}

const ResetPassword: FC<Props> = ({
  token,
  title = translate('login_screen_-_forget_password.forgot_your_password?')
}) => {
  const navigate = useNavigate();
  const [isPasswordVisible, togglePasswordVisible] = useState(false);
  const [isNewPasswordVisible, toggleNewPasswordVisible] = useState(false);
  useSegmentPage({
    path: pagePaths.forgotPassword,
    title: TITLE.RESET_PASSWORD
  });

  const formMethods = useForm<ResetPasswordFormInput>({
    mode: 'onChange',
    delayError: 5000,
    resolver: yupResolver(ResetPasswordSchema)
  });
  const { handleSubmit, watch, setError } = formMethods;

  const [password, passwordConfirmation] = watch([
    'password',
    'passwordConfirmation'
  ]);

  /**
   * @description
   * GraphQl quey for verifying reset token is valid or not if it is invalid redirect to forgot passwordpage
   */
  VerifyToken({
    variables: { token },
    onError: (error) => {
      if (error.message) {
        showToast(translate('reset.oops_reset_link_expired'), false);
        navigate(pagePaths.forgotPassword);
      }
    }
  });

  /**
   * @description
   * configuration for redirecting to the loginpage after success full resetting
   */
  const config = {
    onCompleted: () => {
      showToast(translate('success.password_updated'), true);
      window?.analytics.track(TRACK_EVENTS.RESET_PASSWORD_SUCCESS);
      navigate(pagePaths.login, { state: { hasLogginButtonPressed: true } });
    },
    onError: ({ graphQLErrors }: ApolloError) => {
      window?.analytics.track(TRACK_EVENTS.RESET_PASSWORD_FAILED, {
        error: graphQLErrors?.[0]?.message
      });
      setError('passwordConfirmation', {
        type: 'API error',
        message: graphQLErrors?.[0]?.message
      });
    }
  };
  const [resetPasswordHandler] = ForgotPassword(config);

  /**
   * @description
   * function for handling show and hide password
   */
  const handlePassword = () => {
    togglePasswordVisible(!isPasswordVisible);
  };

  /**
   * @description
   * function for handling show and hide confirm
   */
  const handleNewPassword = () => {
    toggleNewPasswordVisible(!isNewPasswordVisible);
  };

  const onSubmitHandler = (data: ResetPasswordFormInput) => {
    const param = {
      variables: {
        token: token,
        password: data.password
      }
    };
    resetPasswordHandler(param);
  };

  const checkFormFields = (): boolean => !!password && !!passwordConfirmation;

  const onFormError = (errors: FieldErrors) => {
    const { password, passwordConfirmation } = errors;
    const passwordError = password?.message;
    const passwordConfirmationError = passwordConfirmation?.message;
    if (passwordError || passwordConfirmationError)
      window?.analytics.track(TRACK_EVENTS.RESET_PASSWORD_FAILED, {
        error: passwordError || passwordConfirmationError
      });
  };

  return (
    <section className='md:w-520 xxs:w-full xxs:mt-6 flex flex-col'>
      <div className='md:block xss:hidden'>
        <Logo className='text-PRIMARY md:w-52px md:h-8 w-39px h-6' />
      </div>
      <h3 className='md:text-40px font-bold text-DEFAULT_TEXT mt-4 md:mt-6 mb-6 xxs:text-3xl '>
        {title}
      </h3>
      <div className='xxs:text-sm xxs:mb-8 whitespace-pre-wrap text-DARK_GRAY'>
        <span>
          {translate(
            'login_screen_-_forget_password_-_reset_password.please_enter_and_confirm_your_new_password._your_password_should_contain:_8_characters_with_at_least_1_letter__1_number_and_1_special_character.'
          )}
        </span>
      </div>
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(onSubmitHandler, onFormError)}
          className='flex flex-col'
        >
          <div className='relative w-full mb-6'>
            <RhfInput
              label={translate('new_password.new_password')}
              name='password'
              showAnimated={false}
              className='w-full md:h-12 h-12 bg-white px-4 py-3 pr-12'
              inputProps={{
                type: isPasswordVisible ? 'text' : 'password',
                placeholder: 'Enter your new password',
                endIcon: !isPasswordVisible ? (
                  <Visibility onClick={handlePassword} />
                ) : (
                  <VisibilityOff onClick={handlePassword} />
                ),
                showBorderError: true,
                showErrorTitle: true
              }}
            />
          </div>

          <div className='relative w-full'>
            <RhfInput
              label={translate('re-enter_new_password.re-enter_new_password')}
              name='passwordConfirmation'
              showAnimated={false}
              className='w-full md:h-12 h-12 bg-white px-4 py-3 pr-12'
              inputProps={{
                type: isNewPasswordVisible ? 'text' : 'password',
                placeholder: 'Re-enter your new password',
                endIcon: !isNewPasswordVisible ? (
                  <Visibility onClick={handleNewPassword} />
                ) : (
                  <VisibilityOff onClick={handleNewPassword} />
                ),
                showBorderError: true,
                showErrorTitle: true
              }}
            />
          </div>
          <Button
            type='submit'
            disabled={!checkFormFields()}
            className='bg-PRIMARY text-WHITE w-full h-12  mt-10 md:mt-12'
          >
            {translate('submit_button_rounded_(short).button')}
          </Button>
          <p className='text-center mt-7 xxs:text-sm text-DARK_GRAY md:text-DEFAULT_TEXT px-16'>
            {translate('create_new_account.any_problems')}
          </p>
        </form>
      </FormProvider>
    </section>
  );
};

export default ResetPassword;
