import chatClient from 'chatSDK';
import { TranslateTextOutput } from 'types/TPVForm.types';
const useTranslateHook = () => {
  const getTranslatedText = (
    sourceText: string,
    language: string,
    response: (response: TranslateTextOutput) => void,
    onError: (error: Error) => void
  ) => {
    chatClient.getChatClient().getTranslatedText(
      {
        context: 'dental health care',
        sourceText: sourceText,
        language: language
      },
      response,
      onError
    );
  };
  return {
    getTranslatedText
  };
};
export { useTranslateHook };
