import { FC } from 'react';

import { Guide } from 'components';
import { useViewport } from 'utils';

import { mobSteps, webSidePanelSteps, webSteps } from './MenuTour.config';

type MenuProps = {
  run: boolean;
  showFeatureWalkthrough?: boolean;
};

const MenuTour: FC<MenuProps> = ({ run, showFeatureWalkthrough }) => {
  const getWebSteps = () => {
    if (showFeatureWalkthrough && !run) return webSidePanelSteps;
    return webSteps;
  };
  const { width } = useViewport();
  const isMobile = width < 768;
  return (
    <Guide
      steps={isMobile ? mobSteps : getWebSteps()}
      run={run || !!showFeatureWalkthrough}
    />
  );
};

export default MenuTour;
