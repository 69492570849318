import { FC, useReducer } from 'react';

import moment from 'moment';

import CustomModal from 'components/CustomModal/CustomModal';
import { Option } from 'types/select';

import SnoozeCustomPicker from './SnoozeCustomPicker';
import SnoozeReason from './SnoozeReason';
import SnoozeSearch from './SnoozeSearch';

interface Props {
  isOpen: boolean;
  onConfirm: (time: moment.Moment, snoozeReason: string) => void;
  onClose: () => void;
}

const reducer = (state: any, action: any) => {
  if (action.type === 'SNOOZE_REASON_CONFIRM') {
    return {
      ...state,
      view: 'search',
      snoozeReason: action.value
    };
  }
  if (action.type === 'CUSTOM_SNOOZE_CLICK') {
    return {
      ...state,
      view: 'custom'
    };
  }
};

const SnoozeTicketPopup: FC<Props> = ({ isOpen, onClose, onConfirm }) => {
  const [state, dispatch] = useReducer(reducer, {
    view: 'reason'
  });

  const handleConfirm = (time: moment.Moment) => {
    const { snoozeReason } = state;
    onConfirm(time, snoozeReason);
  };

  const handleClosePopup = () => {
    onClose();
  };

  const handleSnoozeReasonConfirm = (option: Option) => {
    dispatch({
      type: 'SNOOZE_REASON_CONFIRM',
      value: option.value
    });
  };

  const handleCustomClick = () => {
    dispatch({
      type: 'CUSTOM_SNOOZE_CLICK'
    });
  };

  const getView = () => {
    switch (state.view) {
      case 'reason':
        return (
          <SnoozeReason
            onCancel={handleClosePopup}
            onNext={handleSnoozeReasonConfirm}
          />
        );
      case 'search':
        return (
          <SnoozeSearch
            onCustomClick={handleCustomClick}
            onTimeConfirm={handleConfirm}
          />
        );
      case 'custom':
        return (
          <SnoozeCustomPicker
            onCancel={handleClosePopup}
            onConfirm={handleConfirm}
          />
        );
    }
  };

  return (
    <CustomModal
      open={isOpen}
      onClose={handleClosePopup}
      closeIconClassName='hidden'
    >
      <div className='p-8'>{getView()}</div>
    </CustomModal>
  );
};

export default SnoozeTicketPopup;
