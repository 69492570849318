import { FC } from 'react';

import { ErrorIcon } from 'assets/icons';
import { translate } from 'utils';

interface Props {
  errorMessage?: string;
}

export const FormError: FC<Props> = ({ errorMessage }) => {
  return errorMessage ? (
    <p className='text-ERROR text-xs mt-2 flex '>
      <ErrorIcon title='error' />
      <span className='ml-2'>{translate(errorMessage)}</span>
    </p>
  ) : null;
};
