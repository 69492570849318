import { FC, useEffect } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import Checkbox from 'components/CheckboxGroup/Checkbox/Checkbox';
import RhfCheckboxGroup from 'components/CheckboxGroup/RhfCheckboxGroup/RhfCheckboxGroup';
import { FormLabel } from 'components/FormLabel/FormLabel';
import If from 'components/Generics/If/If';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import { RHFToothChart2 } from 'components/ToothChart2/RHFToothChart2';
import RhfTeethToExtract from 'components/TreatmentPlanningInstructions/TreatmentSpecifications/TeethToExtract/RhfTeethToExtract/RhfTeethToExtract';
import {
  ATTACHMENTS_OPTIONS,
  REFINEMENT_GOALS,
  REFINEMENT_GOALS_OPTIONS,
  RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS
} from 'constants/TPVFormOptions';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { TPVFormTypes } from 'types/TPVForm.types';
import { customTwMerge, translate } from 'utils';
import { scrollToToothChart } from 'utils/common';

type props = {
  formType: TPVFormTypes;
  name?: string;
  disabled?: boolean;
  toothChartCloseResidualRef: React.RefObject<HTMLDivElement>;
  toothChartResolvePosteriorRef: React.RefObject<HTMLDivElement>;
  source?: string;
};
export const RefinementGoals: FC<props> = ({
  formType,
  name,
  disabled,
  toothChartCloseResidualRef,
  toothChartResolvePosteriorRef,
  source
}) => {
  const { watch, control, trigger, formState, setValue } = useFormContext();
  const { callback } = useFieldRefContext();
  const FieldName = name ? `${name}.` : '';
  const [
    refinementGoals,
    watchAttachmentsSelect,
    watchAttachmentsOnly,
    watchAddButtonsForElastics
  ] = watch([
    `${FieldName}refinementGoals`,
    `${FieldName}attachmentsSelect`,
    `${FieldName}attachmentsOnlyCheck`,
    `${FieldName}addButtonsForElasticsCheck`
  ]);

  useEffect(() => {
    if (
      formState.isSubmitted &&
      (!refinementGoals?.some(
        (obj: { label: string; value: string }) =>
          obj.value === REFINEMENT_GOALS.RESOLVE_POSTERIOR_OPEN_BITE
      ) ||
        watchAttachmentsSelect?.value ===
          RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.SEE_RESULTING_PLAN)
    ) {
      trigger([
        `${FieldName}attachmentsOnlyCheck`,
        `${FieldName}attachmentsOnly`,
        `${FieldName}refinementGoals`
      ]);
    }
    if (
      !refinementGoals?.some(
        (obj: { label: string; value: string }) =>
          obj.value === REFINEMENT_GOALS.CLOSE_RESIDUAL_SPACES
      )
    ) {
      trigger(`${FieldName}toothChart2`);
    }
  }, [refinementGoals, watchAttachmentsSelect, watchAttachmentsOnly]);
  const isPrescriptionSummary = source === 'PrescriptionSummary';

  return (
    <div>
      <Controller
        control={control}
        name={`${FieldName}Refinement_Goals`}
        render={({ formState: { errors } }) => {
          const fieldError =
            _.get(errors, `${FieldName}refinementGoals`) ||
            _.get(errors, `${FieldName}attachmentsOnly`) ||
            _.get(errors, `${FieldName}toothChart2`) ||
            _.get(errors, `${FieldName}attachmentsOnlyCheck`) ||
            _.get(errors, `${FieldName}addButtonsForElasticsCheck`) ||
            _.get(errors, `${FieldName}buttonsForElastics`);

          return (
            <div ref={callback?.(`${FieldName}Refinement_Goals`)}>
              <FormLabel
                label={translate('4.Refinement.Goals')}
                className='mb-2 text-sm'
                error={!!fieldError}
                name={`${FieldName}Refinement_Goals`}
              />
              <RhfCheckboxGroup
                name={`${FieldName}refinementGoals`}
                options={REFINEMENT_GOALS_OPTIONS}
                checkBoxContainerClassName='grid grid-cols-1 md:grid-cols-2'
                disabled={disabled}
                onChange={(selectedValue, name, e) => {
                  setValue(`${FieldName}refinementGoals`, selectedValue);
                  scrollToToothChart(
                    e?.target?.checked || false,
                    toothChartCloseResidualRef,
                    toothChartResolvePosteriorRef,
                    e?.target?.value || ''
                  );
                }}
              />
              <If
                condition={
                  !!refinementGoals?.some(
                    (obj: { label: string; value: string }) =>
                      obj.value === REFINEMENT_GOALS.CLOSE_RESIDUAL_SPACES
                  )
                }
              >
                <div className='flex flex-col mt-4'>
                  <div className='text-BLUE_4 text-sm'>
                    {translate('Diagram.1')}
                  </div>
                  <RHFToothChart2
                    name={`${FieldName}toothChart2`}
                    disabled={disabled}
                    toothChartRef={toothChartCloseResidualRef}
                  />
                </div>
              </If>
              <If
                condition={
                  !!refinementGoals?.some(
                    (obj: { label: string; value: string }) =>
                      obj.value === REFINEMENT_GOALS.RESOLVE_POSTERIOR_OPEN_BITE
                  )
                }
              >
                <div className='md:ml-7 mt-7'>
                  <div className='text-BLUE_4 text-sm md:ml-10'>
                    {translate('Diagram.2')}
                  </div>
                  <div
                    className=' space-y-2'
                    ref={toothChartResolvePosteriorRef}
                  >
                    <div className='space-y-6'>
                      <div
                        className={classNames(
                          'md:flex justify-between grid grid-cols-1 md:ml-10',
                          {
                            'md:flex-wrap': isPrescriptionSummary
                          }
                        )}
                      >
                        <Controller
                          control={control}
                          name={`${FieldName}attachmentsOnlyCheck`}
                          render={({ field }) => (
                            <label className='py-2 flex'>
                              <Checkbox
                                checked={watchAttachmentsOnly}
                                className='h-4 w-4'
                                {...field}
                              />
                              <span className='pt-0.5 text-sm'>
                                {translate('Attachments.only')}
                              </span>
                            </label>
                          )}
                        />

                        {watchAttachmentsOnly && (
                          <>
                            <div
                              className={customTwMerge(
                                'md:w-327px w-full',
                                classNames({
                                  'md:w-48': isPrescriptionSummary
                                })
                              )}
                            >
                              <RhfSelect
                                inputProps={{
                                  controlShouldRenderValue: true,
                                  placeholder: translate('Attachments.only'),
                                  showErrorTitle: true,
                                  placeHolderStyle: {
                                    fontSize: '0.75rem',
                                    fontColor: '#B8B8B5'
                                  },
                                  selectHeight: '3rem',
                                  isDisabled: disabled
                                }}
                                options={ATTACHMENTS_OPTIONS}
                                name={`${FieldName}attachmentsSelect`}
                                showError={false}
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <If
                        condition={
                          watchAttachmentsOnly &&
                          watchAttachmentsSelect?.value ===
                            ATTACHMENTS_OPTIONS[1]?.value
                        }
                      >
                        <RhfTeethToExtract
                          source={source}
                          name={`${FieldName}attachmentsOnly`}
                          disabled={disabled}
                        />
                      </If>
                    </div>
                    {formType === TPVFormTypes.ZENPLUS && (
                      <div>
                        <Controller
                          control={control}
                          name={`${FieldName}addButtonsForElasticsCheck`}
                          render={({ field }) => (
                            <label className='py-2 flex ml-10'>
                              <Checkbox
                                checked={watchAddButtonsForElastics}
                                className='h-4 w-4'
                                {...field}
                              />
                              <span className='pt-0.5 text-sm'>
                                {translate('Add.buttons.for.elastics')}
                              </span>
                            </label>
                          )}
                        />
                        <If condition={watchAddButtonsForElastics}>
                          <div className='mt-4'>
                            <RhfTeethToExtract
                              source={source}
                              name={`${FieldName}buttonsForElastics`}
                              disabled={disabled}
                            />
                          </div>
                        </If>
                      </div>
                    )}
                  </div>
                </div>
              </If>
            </div>
          );
        }}
      />
    </div>
  );
};
