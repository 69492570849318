import { FC } from 'react';

import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';

import If from 'components/Generics/If/If';
import { InfoCardView } from 'components/InfoCardView/InfoCardView';
import RhfRadioGroup from 'components/RadioGroup/RhfRadioGroup/RhfRadioGroup';
import { TREATMENTPLANTYPEOPTIONS } from 'constants/options';
import { ZENCLEAR, ZENPLUS } from 'types/TPVForm.types';
import { customTwMerge, translate } from 'utils';

import TreatmentSpecificationsExternalZenclear from './TreatmentSpecificationsExternalZenclear';
import TreatmentSpecificationsExternalZenplus from './TreatmentSpecificationsExternalZenPlus';

type Props = {
  isDisabled?: boolean;
  isReferral?: boolean;
  className?: string;
  onRadioButtonClick?: () => void;
  source?: string;
};
const TreatmentSpecificationsExternal: FC<Props> = ({
  isDisabled,
  isReferral,
  className = '',
  onRadioButtonClick,
  source
}) => {
  const { watch, setValue } = useFormContext();
  const watchTreatmentPlanType = watch('treatmentSpecifications.treatmentType');
  const setSpecialNotes = (fieldName: string, result?: string) => {
    setValue(fieldName, result);
  };
  return (
    <div className={customTwMerge('flex flex-col space-y-5', className)}>
      <If condition={!!watchTreatmentPlanType && !isReferral}>
        <InfoCardView
          message={translate('treatmentSpecification.external.info.message')}
          classname='py-3.5 px-4 md:items-center'
          isExternal={false}
          iconClassname='text-YELLOW_4 mr-2'
        >
          <ul className='list-disc'>
            <li>{translate('aligner.info.prescription')}</li>
            <li>{translate('teeth.position.warning.info')}</li>
          </ul>
        </InfoCardView>
      </If>
      <div
        className={classNames('flex flex-col p-18px rounded-md space-y-8', {
          'bg-GRAY5': !isReferral
        })}
      >
        <RhfRadioGroup
          options={TREATMENTPLANTYPEOPTIONS}
          title={translate('prescriptions.treatment_plan_type')}
          className='mt-3 flex flex-col md:flex-row md:space-x-19'
          name='treatmentSpecifications.treatmentType'
          radioClassName='border-RADIO_BORDER'
          radioItemFont='md:text-17px text-sm'
          inputProps={{ disabled: isDisabled }}
          onClick={onRadioButtonClick}
        />

        <If condition={!!watchTreatmentPlanType}>
          <If condition={watchTreatmentPlanType === ZENCLEAR}>
            <TreatmentSpecificationsExternalZenclear
              setSpecialNotes={setSpecialNotes}
              formType={'ZenClear'}
              isDisabled={isDisabled}
              isReferral={isReferral}
            />
          </If>
          <If condition={watchTreatmentPlanType === ZENPLUS}>
            <TreatmentSpecificationsExternalZenplus
              setSpecialNotes={setSpecialNotes}
              formType={'ZenPlus'}
              isDisabled={isDisabled}
              isReferral={isReferral}
              source={source}
            />
          </If>
        </If>
      </div>
    </div>
  );
};

export default TreatmentSpecificationsExternal;
