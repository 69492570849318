import { Option } from 'types/select';

const TREATMENT_PLAN_TYPE: Option[] = [
  {
    label: 'prescriptions.zenyumclear',
    value: 'ZenClear'
  },
  {
    label: 'prescriptions.zenyumclear_plus',
    value: 'ZenPlus'
  },
  {
    label: 'refinement.zenyumclear',
    value: 'ZenClearRefinement'
  },
  {
    label: 'refinement.zenyumclear_plus',
    value: 'ZenPlusRefinement'
  }
];
export const TREATMENT_PLAN_TYPE_MAP = {
  ZenClear: {
    label: 'prescriptions.zenyumclear',
    value: 'ZenClear'
  },
  ZenPlus: {
    label: 'prescriptions.zenyumclear_plus',
    value: 'ZenPlus'
  },
  ZenClearRefinement: {
    label: 'refinement.zenyumclear',
    value: 'ZenClearRefinement'
  },
  ZenPlusRefinement: {
    label: 'refinement.zenyumclear_plus',
    value: 'ZenPlusRefinement'
  }
};
export const preferenceTypes = ['ZenClear', 'ZenPlus', 'Refinement'];
const IPR_OPTIONS: Option[] = [
  {
    label: '0.1',
    value: '0.1'
  },
  {
    label: '0.2',
    value: '0.2'
  },
  {
    label: '0.3',
    value: '0.3'
  },
  {
    label: '0.4',
    value: '0.4'
  },
  {
    label: '0.5',
    value: '0.5'
  },
  {
    label: 'ipr.locations.no.preferences',
    value: 'no Preferences'
  }
];

const IPR_LOCATION: Option[] = [
  {
    label: 'ipr.locations.Incisors-canines',
    value: 'Incisors-canines'
  },
  {
    label: 'ipr.locations.premolars',
    value: 'Premolars'
  },
  {
    label: 'ipr.locations.molars',
    value: 'Molars (mesial of #6)'
  },
  {
    label: 'ipr.locations.no.preferences',
    value: 'no Preferences'
  }
];
const BITE_RAMP: Option[] = [
  {
    label: 'biteRamp.when.possible',
    value: 'When possible'
  },
  {
    label: 'biteRamp.never',
    value: 'Never'
  }
];
const PREMOLAR_CROSSBITE: Option[] = [
  {
    label: 'premolar.crossbite.attachment',
    value: 'Attachment only'
  },
  {
    label: 'premolar.crossbite.elastics',
    value: 'Elastics only'
  },
  {
    label: 'premolar.crossbite.attachment.elastics',
    value: 'Attachment & elastics'
  },
  {
    label: 'premolar.crossbite.no.correction',
    value: 'No correction'
  }
];

export const preferenceTypesName: Record<string, string> = {
  ZenClear: 'prescriptions.zenyumclear',
  ZenPlus: 'prescriptions.zenyumclear_plus',
  ZenClearRefinement: 'refinement.zenyumclear',
  ZenPlusRefinement: 'refinement.zenyumclear_plus'
};
export {
  TREATMENT_PLAN_TYPE,
  IPR_OPTIONS,
  IPR_LOCATION,
  BITE_RAMP,
  PREMOLAR_CROSSBITE
};
