import { FC, HTMLProps, useReducer, useState } from 'react';

import cx from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';

import { DownArrowIcon, SearchIcon } from 'assets/icons';
import Checkbox from 'components/CheckboxGroup/Checkbox/Checkbox';
import { If } from 'components/Generics';
import { useFilterContext } from 'components/InboxLeftPanel/filterContext';
import { Loader } from 'components/Loader/Loader';
import { INBOX_DROPDOWN_KEY } from 'constants/inbox';
import { InfiniteScrollProps } from 'types/Inbox.types';
import { Option } from 'types/select';
import { translate } from 'utils';
import { useToggle } from 'utils/customHooks/useToggle';

interface FilterControllerProps {
  title: string;
  isExpanded: boolean;
  selectedCount: number;
  onClick(): void;
}

const FilterController: FC<FilterControllerProps> = ({
  title,
  onClick,
  isExpanded,
  selectedCount
}) => {
  const isOptionSelected = selectedCount > 0;

  return (
    <button
      onClick={onClick}
      className={cx(
        'w-full px-4 py-2 text-sm flex justify-between items-center',
        {
          'bg-LIGHT_BLUE_900 text-white rounded-md':
            isExpanded || isOptionSelected
        }
      )}
    >
      <p>{title}</p>
      <div>
        <If condition={isOptionSelected}>
          <p className='text-white text-center bg-PRIMARY h-[20px] w-[30px] rounded-full inline-block mr-2'>
            +{selectedCount}
          </p>
        </If>
        <DownArrowIcon
          className={cx(
            'text-DEFAULT_TEXT inline-block',
            {
              'transform duration-500  rotate-180 text-white': isExpanded
            },
            {
              'transform duration-500  rotate-0': !isExpanded
            },
            {
              'text-white': isOptionSelected
            }
          )}
        />
      </div>
    </button>
  );
};

type OnChange = (isChecked: boolean) => void;

interface FilterOptionProps {
  label: string;
  value: string;
  checked: boolean;
  onChange: OnChange;
}

const FilterOption: FC<FilterOptionProps> = ({
  label,
  value,
  checked,
  onChange
}) => {
  return (
    <label className='py-3 pr-5 pl-4 flex cursor-pointer' role='presentation'>
      <Checkbox
        id={value}
        name={value}
        checked={checked}
        onChange={(event) => {
          const isChecked = event.target.checked;
          onChange(isChecked);
        }}
        value={value}
      />
      <span className='ml-1 text-sm'>{translate(label)}</span>
    </label>
  );
};

interface FilterOptionContainerProps {
  isExpanded: boolean;
  children: React.ReactNode;
}

const FilterOptionContainer: FC<FilterOptionContainerProps> = ({
  isExpanded,
  children
}) => {
  return isExpanded ? <div>{children}</div> : null;
};

const FilterSearch: FC<HTMLProps<HTMLInputElement>> = ({
  onChange,
  ...props
}) => {
  const [value, setValue] = useState('');
  const handleChange: any = (event: any) => {
    setValue(event.target.value);
    onChange?.(event);
  };

  return (
    <div className='px-6 py-1 flex items-center'>
      <input
        {...props}
        value={value}
        onChange={handleChange}
        placeholder={`${translate('search.main')}...`}
        className='h-[28px] flex-1 placeholder:text-xs'
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={true}
      />
      <SearchIcon className='h-4 w-4 shrink-0' />
    </div>
  );
};

const reducer = (state: any, action: any) => {
  const { type } = action;
  if (type === 'TOGGLE_MENU') {
    return { ...state, isExpanded: !state?.isExpanded };
  }
  return state;
};

const DEFAULT_STATE = {
  isExpanded: false
};

interface MenuSubtitleProps {
  children: React.ReactNode;
}

const MenuSubtitle: FC<MenuSubtitleProps> = ({ children }) => {
  return (
    <p className='text-xs text-[#999] bg-GRAY_6 w-full py-0.5 px-3 mt-2'>
      {children}
    </p>
  );
};

interface ExpandableMenuSubtitleProps {
  children: React.ReactNode;
  isExpanded: boolean;
  onClick: () => void;
}
const ExpandableMenuSubtitle: FC<ExpandableMenuSubtitleProps> = ({
  children,
  isExpanded,
  onClick
}) => {
  return (
    <button
      onClick={onClick}
      className='text-xs text-[#999] bg-GRAY_6 w-full py-0.5 px-3 mt-2'
    >
      <div className='text-left'>
        <span className='mr-1'>{isExpanded ? '-' : '+'}</span>
        {children}
      </div>
    </button>
  );
};

interface OptionGroupProps {
  title: string;
  options: Option[];
  checked: boolean;
  className?: string;
  infiniteScrollProps?: InfiniteScrollProps;
  noOptionMessage?: string;
  onChange(option: Option): void;
}

const OptionGroup: FC<OptionGroupProps> = ({
  title,
  options,
  checked,
  className = '',
  infiniteScrollProps,
  noOptionMessage,
  onChange
}) => {
  const hasInfiniteScroll = !!infiniteScrollProps;

  const getOptions = () => {
    return options.map((option) => {
      const { label = '', value } = option;
      return (
        <FilterOption
          key={value}
          label={label}
          value={value}
          checked={checked}
          onChange={() => onChange(option)}
        />
      );
    });
  };

  if (options.length === 0 && !noOptionMessage) return null;
  if (options.length === 0 && noOptionMessage)
    return (
      <div>
        <MenuSubtitle>{translate(title)}</MenuSubtitle>
        <p className='py-3 pr-5 pl-4 text-center'>
          {translate(noOptionMessage)}
        </p>
      </div>
    );

  return (
    <div>
      <If condition={!!title}>
        <MenuSubtitle>{translate(title)}</MenuSubtitle>
      </If>
      {hasInfiniteScroll ? (
        <div className={className} id='option-container'>
          <InfiniteScroll
            dataLength={options.length}
            hasMore={infiniteScrollProps.hasMore}
            loader={<Loader />}
            next={infiniteScrollProps.next}
            scrollableTarget='option-container'
            scrollThreshold={0.75}
          >
            {getOptions()}
          </InfiniteScroll>
        </div>
      ) : (
        <div className={className}>{getOptions()}</div>
      )}
    </div>
  );
};

interface Props {
  title: string;
  optionKey: string;
  enableSearch: boolean;
  options: Option[];
  otherOptionInfiniteScrollProps?: InfiniteScrollProps;
  isOptionLoading?: boolean;
  isCustomSearch: boolean;
  customSearch?: (value: string) => void;
}

const InboxFilter: FC<Props> = ({
  title,
  enableSearch,
  options,
  optionKey,
  isCustomSearch,
  isOptionLoading = false,
  customSearch,
  otherOptionInfiniteScrollProps
}) => {
  const [state, dispatch] = useReducer(reducer, DEFAULT_STATE);
  const [searchValue, setSearchValue] = useState('');
  const [isTopicExpanded, toggleTopicExpanded] = useToggle();
  const { isExpanded } = state;
  const toggleMenu = () => {
    dispatch({ type: 'TOGGLE_MENU' });
    if (isExpanded) handleSearch('');
  };

  const { selectedFilters, toggleFilterOption, resetFilterByKey } =
    useFilterContext();

  const selectedOptions = selectedFilters[optionKey] || [];
  const disableClearFilter = selectedOptions.length === 0;
  const unselectedOptions = options.filter(
    (option) =>
      !selectedOptions.find(
        (selected: Option) => selected.value === option.value
      )
  );
  const getSearchResult = () => {
    return unselectedOptions.filter((option) =>
      option.label?.toLowerCase?.()?.includes(searchValue.toLowerCase())
    );
  };
  const otherOptions = searchValue ? getSearchResult() : unselectedOptions;

  const getOtherOptionTitle = () => {
    const isOptionSelected = selectedOptions.length > 0;
    return isOptionSelected ? 'filters.other_options' : 'filters.all_options';
  };

  const handleToggleOption = (option: Option) => {
    toggleFilterOption(optionKey, option);
  };

  const getNoOptionMessage = () => {
    return isOptionLoading ? '' : 'inbox.no_option_msg';
  };

  const clearFilter = () => {
    resetFilterByKey(optionKey);
  };

  const getOtherOptionGroup = () => {
    if (optionKey === INBOX_DROPDOWN_KEY.Topic) {
      const newTopics = otherOptions.filter((option) => !option.isOld);
      const oldTopics = otherOptions.filter((option) => !!option.isOld);
      return (
        <>
          <If condition={newTopics.length > 0}>
            <OptionGroup
              title={'inbox.new_topics'}
              options={newTopics}
              checked={false}
              onChange={handleToggleOption}
              className='max-h-[18rem] overflow-scroll'
              noOptionMessage={getNoOptionMessage()}
            />
          </If>
          <If condition={oldTopics.length > 0}>
            <div
              className={cx('', {
                'mb-2': !isTopicExpanded
              })}
            >
              <ExpandableMenuSubtitle
                isExpanded={isTopicExpanded}
                onClick={() => toggleTopicExpanded()}
              >
                {translate('inbox.old_topics')}
              </ExpandableMenuSubtitle>
            </div>

            <If condition={isTopicExpanded}>
              <OptionGroup
                title={''}
                options={oldTopics}
                checked={false}
                onChange={handleToggleOption}
                className='max-h-[18rem] overflow-scroll'
                noOptionMessage={getNoOptionMessage()}
              />
            </If>
          </If>
        </>
      );
    }

    const showOtherOption =
      otherOptions.length > 0 || !!searchValue || !!isCustomSearch;

    return (
      <If condition={showOtherOption}>
        <OptionGroup
          title={getOtherOptionTitle()}
          options={otherOptions}
          checked={false}
          onChange={handleToggleOption}
          className='max-h-[18rem] overflow-scroll'
          infiniteScrollProps={otherOptionInfiniteScrollProps}
          noOptionMessage={getNoOptionMessage()}
        />
      </If>
    );
  };

  const handleSearch = (text: string) => {
    const searchHandler = customSearch ?? setSearchValue;
    searchHandler(text);
  };

  return (
    <div className='border-b-1 border-LINE_COLOR'>
      <FilterController
        title={title}
        isExpanded={isExpanded}
        selectedCount={selectedOptions.length}
        onClick={toggleMenu}
      />
      <FilterOptionContainer isExpanded={isExpanded}>
        <If condition={enableSearch}>
          <FilterSearch
            onChange={(e: any) => {
              handleSearch(e.target.value);
            }}
          />
        </If>
        <OptionGroup
          title='filters.selected_options'
          options={selectedOptions}
          checked={true}
          onChange={handleToggleOption}
          className='max-h-[18rem] overflow-scroll'
        />
        {getOtherOptionGroup()}
        <If condition={isOptionLoading}>
          <Loader className='w-14 h-14' />
        </If>
        <If condition={selectedOptions.length}>
          <button
            disabled={disableClearFilter}
            className='text-sm text-[#999] bg-GRAY_6 w-full py-0.5'
            onClick={clearFilter}
          >
            {translate('select.clear_all_selection')}
          </button>
        </If>
      </FilterOptionContainer>
    </div>
  );
};

export default InboxFilter;
