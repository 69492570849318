import { FC } from 'react';

import { Modal } from 'components';
import { FilesData } from 'types/Files.types';

import FileUploadModalBody from './FileUploadModalBody/FileUploadModalBody';
import FileUploadModalFooter from './FileUploadModalFooter/FileUploadModalFooter';
import FileUploadModalHeader from './FileUploadModalHeader/FileUploadModalHeader';

type FileUploadProps = {
  title: string;
  onCancelClicked: () => void;
  onUploadClicked: (files: FilesData[]) => void;
  isOpen: boolean;
  files?: FilesData[];
  onLocalDelete: (index: number) => void;
  openFileDialog: () => void;
};

const FilesUploadModal: FC<FileUploadProps> = ({
  title,
  files = [],
  onCancelClicked,
  onUploadClicked,
  isOpen,
  onLocalDelete,
  openFileDialog
}) => {
  return (
    <Modal open={isOpen} onClose={onCancelClicked}>
      <div className='flex flex-col h-full p-6 rounded-xl w-164 z-50'>
        <header className='flex justify-between items-center'>
          <FileUploadModalHeader title={title} />
        </header>
        <main>
          <FileUploadModalBody data={files} onLocalDelete={onLocalDelete} />
        </main>
        <footer className='flex justify-between mt-auto pt-6'>
          <FileUploadModalFooter
            onCancel={onCancelClicked}
            onUpload={() => onUploadClicked(files)}
            openFileDialog={openFileDialog}
          />
        </footer>
      </div>
    </Modal>
  );
};

export default FilesUploadModal;
