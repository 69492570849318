import { SettingsIcon } from 'assets/icons';
import { pagePaths } from 'config/pages';
import { PERMISSIONS } from 'constants/Permissions';

export const settingsNav = {
  title: 'settings_button.settings',
  icon: SettingsIcon,
  path: pagePaths.settings,
  showSpacer: true,
  permission: PERMISSIONS?.SETTINGS
};
