import { useRef, useLayoutEffect, useEffect } from 'react';

export function useClickAway(cb: () => void) {
  const ref = useRef<HTMLDivElement>(null);
  const refCb = useRef(cb);

  useLayoutEffect(() => {
    refCb.current = cb;
  }, [cb]);

  useEffect(() => {
    const handler = (e: any) => {
      const element = ref.current;
      if (!element) return;
      if (element && !element.contains(e.target)) {
        refCb.current();
      }
    };

    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler);
    };
  }, []);

  return ref;
}
