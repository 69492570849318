import React from 'react';

import { ClockIcon } from 'assets/icons';

interface Props {
  time?: string;
}

const AppointmentTime: React.FC<Props> = ({ time }) => {
  return (
    <div className='flex flex-row content-center text-17px'>
      <ClockIcon className='h-4 w-4' />
      <div className='ml-10px'>{time}</div>
    </div>
  );
};

export default AppointmentTime;
