import React from 'react';

import { CalendarIcon } from 'assets/icons';

interface Props {
  date?: string;
}

const CalendarDate: React.FC<Props> = ({ date }) => {
  return (
    <div>
      <div className='flex flex-row mr-2 content-center text-17px'>
        <CalendarIcon className='h-5 w-4 mr-10px' />
        <div className='w-120px'>{date}</div>
      </div>
    </div>
  );
};

export default CalendarDate;
