import { FC, useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { useFormContext } from 'react-hook-form';

import { ErrorIcon } from 'assets/icons';
import { Button, Modal, WarningContainer } from 'components';
import { If } from 'components/Generics';
import { TreatmentSourceType } from 'components/TreatmentProgressMenu/TreatmentMenuConfig';
import ZoomPhotoPopup from 'components/ZoomPhotoPopup/ZoomPhotoPopup';
import {
  PRIMARY_REQUIRED_PHOTOS,
  PRIMARY_REQUIRED_XRAY,
  REFINEMENT_REQUIRED_PHOTOS,
  REFINEMENT_REQUIRED_XRAY,
  TREATMENT_DOCUMENT_FILE_DROP_TYPE_MAP
} from 'constants/treatmentFiles';
import { GET_PATIENT_DATA } from 'services/query/patientslist';
import { GET_REFERRAL_BY_ID } from 'services/query/referralForm';
import { FilesData, Classification } from 'types/Files.types';
import {
  TreatmentDocumentInput,
  TreatmentDocumentType
} from 'types/TreatmentPlanning.types';
import { isDataExist, translate } from 'utils';
import useFileUpload from 'utils/customHooks/fileUpload.hooks';
import { classifyFiles } from 'utils/fileUpload';
import { showToast } from 'utils/toast/toast.util';

import OtherPhotoCard from './Components/OtherPhotoCard/OtherPhotoCard';
import PhotosFileCard from './Components/PhotosFileCard/PhotosFileCard';
import ScanPhotoModal from './Components/ScanPhotoModal/ScanPhotoModal';

interface Props {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  treatmentId?: string;
  referralId?: string;
  selectedDocumentType?: TreatmentDocumentType;
  isReferral?: boolean;
  fileDetails: {
    files: any;
    formName: string;
    classification: Array<any>;
  };
  isPrimary?: boolean;
}

const getRequiredClassification = (
  isPhoto: boolean,
  isXray: boolean,
  isPrimary: boolean
) => {
  if (isPhoto) {
    return isPrimary ? PRIMARY_REQUIRED_PHOTOS : REFINEMENT_REQUIRED_PHOTOS;
  }
  if (isXray) {
    return isPrimary ? PRIMARY_REQUIRED_XRAY : REFINEMENT_REQUIRED_XRAY;
  }
  return [];
};

const PhotoUploadModal: FC<Props> = ({
  isOpen,
  setIsOpen,
  treatmentId = '',
  selectedDocumentType = TreatmentDocumentType.PHOTO,
  fileDetails,
  isReferral = false,
  referralId,
  isPrimary = false
}) => {
  const refetchQueries = isReferral ? [GET_REFERRAL_BY_ID] : [GET_PATIENT_DATA];

  const [files, setFiles] = useState<FilesData[]>([]);
  const [showScanPopup, setShowScanPopup] = useState(false);
  const [zoomPhoto, toggleZoomPhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<string | number>(0);
  const [errors, setErrors] = useState<Classification[]>([]);

  const { trigger } = useFormContext();

  const isXray = selectedDocumentType === TreatmentDocumentType.X_RAY;
  const isPhoto = selectedDocumentType === TreatmentDocumentType.PHOTO;
  const documentTypeLabel = isPhoto
    ? translate('photos.photos')
    : translate('x-rays.x-rays');

  const { uploadFile, updateTreatmentFile, refetchQuery } = useFileUpload({
    referralId,
    treatmentId,
    setFiles,
    refetchQueries
  });

  const classifiedFileMap = useMemo(() => {
    return classifyFiles(files, fileDetails.classification);
  }, [files]);

  useEffect(() => {
    setFiles(fileDetails.files);
    return () => {
      refetchQuery();
    };
  }, [fileDetails.files]);

  const handleUploadFile = (
    fileUploadInput: TreatmentDocumentInput,
    file: FilesData
  ) => {
    uploadFile(fileUploadInput, file);
  };

  const handleClose = () => {
    trigger(fileDetails.formName);
    setIsOpen(false);
  };

  const handleSave = () => {
    const requiredClassifications = getRequiredClassification(
      isPhoto,
      isXray,
      isPrimary
    );
    const errorFields = requiredClassifications.reduce(
      (accumulator: Classification[], classification) => {
        const index = fileDetails.files.findIndex(
          (file: any) => classification === file.classification
        );
        const notAvailable = index == -1;
        if (notAvailable) {
          accumulator.push(classification);
        }
        return accumulator;
      },
      []
    );
    setErrors(errorFields);
    if (errorFields.length == 0) {
      showToast(translate('successfully.saved'), true);
      handleClose();
    }
  };

  return (
    <>
      <Modal open={isOpen} onClose={handleClose} showCloseIcon={false}>
        <section className='w-full h-full'>
          <div className='flex justify-between  items-center px-6 py-4'>
            <div className='text-xl  font-semibold  text-DEFAULT_TEXT '>
              {documentTypeLabel}
            </div>
            <div className='flex flex-row h-max'>
              <Button
                onClick={handleClose}
                className='text-14px leading-6 py-3 px-4 xxs:hidden md:block'
                type='button'
              >
                {translate('button_rounded_(short).save')}
              </Button>
              <Button
                className='px-6 py-1  text-white bg-PRIMARY rounded-32px'
                onClick={handleSave}
                type='button'
              >
                {translate('btn.finish')}
              </Button>
            </div>
          </div>
          <div className='px-6 w-full'>
            <div className='flex flex-row'>
              <div className='flex flex-col'>
                <div className='inline-flex items-center'>
                  <div className='text-base font-semibold'>
                    {translate('categories')}
                  </div>
                  <If condition={!(!isPrimary && isXray)}>
                    {isDataExist(errors) && (
                      <div className='text-ERROR px-3 py-1 rounded-4xl bg-LIGHT_ERROR_RED ml-4 items-center flex justify-center'>
                        <ErrorIcon />
                        <span className='ml-2  break-before-all text-sm max-w-[11.25rem]'>
                          {translate('description.required')}
                        </span>
                      </div>
                    )}
                  </If>
                </div>
                <div className='mt-4 mb-6 text-base'>
                  {translate('drag.upload', {
                    X: documentTypeLabel.toLocaleLowerCase()
                  })}
                </div>
                <div className='mb-6 md:max-w-xs text-sm '>
                  <WarningContainer
                    text={translate('upload.high.resolution.image')}
                  />
                </div>
              </div>
              <div className='flex-1'>
                <div className='bg-white grid grid-cols-5 gap-4 items-center mx-0 pl-6'>
                  {fileDetails.classification.map((item) => (
                    <div
                      key={item.key}
                      className='md:w-[12.5rem] md:h-[12.313rem] overflow-hidden'
                    >
                      <PhotosFileCard
                        toggleZoomPhoto={toggleZoomPhoto}
                        setSelectedPhoto={setSelectedPhoto}
                        label={item.label}
                        icon={item.icon}
                        setFiles={setFiles}
                        handleUploadFile={handleUploadFile}
                        classification={item.key}
                        setShowScanPopup={setShowScanPopup}
                        selectedDocumentType={selectedDocumentType}
                        fileData={files.find(
                          ({ classification }) => classification === item.key
                        )}
                        files={files}
                        updateTreatmentFile={updateTreatmentFile}
                        isError={errors.includes(item.key)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='bg-white flex flex-row w-full my-6 py-8  border-t-1 border-bg-GRAY5'>
              <div className='flex flex-col md:max-w-xs'>
                <div className='text-base font-semibold text-DEFAULT_TEXT'>
                  {translate('upload.upload')} {documentTypeLabel}
                </div>
                <div className='mt-4 text-base'>
                  {translate('drag.drop.photo', {
                    X: documentTypeLabel.toLocaleLowerCase()
                  })}
                </div>
                <div className='text-DISABLED_2 text-base flex flex-1 items-end justify-end'>
                  <div>
                    {translate('Uncategorised.photo.delete', {
                      X: documentTypeLabel.toLocaleLowerCase()
                    })}
                  </div>
                </div>
              </div>
              <div className='ml-6 flex flex-1 md:max-w-[67rem] '>
                <OtherPhotoCard
                  isReferral={isReferral}
                  referralId={referralId}
                  treatmentId={treatmentId}
                  fileDropAreaTitle={
                    TREATMENT_DOCUMENT_FILE_DROP_TYPE_MAP[selectedDocumentType]
                  }
                  documentType={selectedDocumentType}
                  className={cx('overflow-x-scroll', {
                    'bg-GRAY5': !classifiedFileMap?.['other']?.length
                  })}
                  sourceType={TreatmentSourceType.PRESCRIPTIONS}
                  files={classifiedFileMap?.['other'] || []}
                  refetchQueries={refetchQueries}
                  thumbnailHeight='h-28.5 md:h-40'
                  name={''}
                />
              </div>
            </div>
          </div>
        </section>
        <If condition={zoomPhoto}>
          <ZoomPhotoPopup
            allFiles={Object.values(classifiedFileMap || {})?.flat() || []}
            zoomPhoto={zoomPhoto}
            toggleZoomPhoto={toggleZoomPhoto}
            selectedPhoto={selectedPhoto as string}
            setSelectedPhoto={setSelectedPhoto}
          />
        </If>
      </Modal>
      <ScanPhotoModal
        isOpen={showScanPopup}
        handleClose={() => setShowScanPopup(false)}
      />
    </>
  );
};

export default PhotoUploadModal;
