import React, { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Modal, RhfRadioGroup } from 'components';
import RhfInput from 'components/Generics/Rhf/RhfInput';
import { AdvanceSearchForm, InboxFilters } from 'types/Tickets.types';
import { translate } from 'utils';
import { InboxAdvanceSearch } from 'validations/AdvanceSearch';
interface Props {
  isOpen: boolean;
  setIsOpen: (status: boolean) => void;
  handleAdvanceSearch: (formValues: AdvanceSearchForm) => void;
  handleAdvanceSearchClear: () => void;
  selectedFilters: InboxFilters;
}

const AdvanceSearchModal: FC<Props> = ({
  isOpen,
  selectedFilters,
  setIsOpen,
  handleAdvanceSearch,
  handleAdvanceSearchClear
}) => {
  const formMethods = useForm<AdvanceSearchForm>({
    mode: 'onChange',
    resolver: yupResolver(InboxAdvanceSearch),
    defaultValues: {
      includeMe:
        selectedFilters.viewTicketsWithoutMe === true ||
        selectedFilters.viewTicketsWithoutMe === undefined
          ? 'false'
          : 'true',
      email: selectedFilters.advancedSearch?.includeParticipantEmail
    }
  });
  const {
    handleSubmit,
    reset,
    formState: { isValid },
    watch
  } = formMethods;
  const resetForm = () => {
    reset();
    handleAdvanceSearchClear();
  };

  const OptionList = [
    {
      value: 'false',
      label: translate('chat.excluding.self')
    },
    {
      value: 'true',
      label: translate('chat.including_self')
    }
  ];

  const isEmailSearchedOrEntered =
    selectedFilters?.advancedSearch?.includeParticipantEmail || watch('email');

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)}>
      <div className='md:min-w-524px w-full'>
        <p className='text-DEFAULT_TEXT text-base px-6 pt-6'>
          {translate('advance.search')}
        </p>
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit(handleAdvanceSearch)}
            className='px-6 py-4'
          >
            <label htmlFor='email' className='text-sm text-DISABLED mb-2'>
              {translate('chat.with_participant')}
            </label>
            <RhfInput
              inputProps={{
                type: 'email',
                placeholder: translate('input.email')
              }}
              className='placeholder-DISABLED'
              name='email'
              showAnimated={false}
            />
            <RhfRadioGroup
              options={OptionList}
              className='mt-4 '
              name='includeMe'
              radioClassName='border-RADIO_BORDER'
              radioItemFont='text-sm font-normal text-DEFAULT_TEXT '
            />
            <div className='flex justify-end mt-10 md:mt-5'>
              <Button
                onClick={resetForm}
                className={cx(
                  'border  px-30px py-4 text-14px md:text-basemd:px-8 md:py-3 xxs:w-full md:w-max',
                  {
                    'border-PRIMARY text-PRIMARY': isEmailSearchedOrEntered
                  }
                )}
                disabled={!isEmailSearchedOrEntered}
              >
                {translate('Page_1.Black_ghost_button')}
              </Button>
              <Button
                type='submit'
                disabled={!isValid}
                className={
                  'bg-PRIMARY text-WHITE px-30px py-4 text-14px md:text-basemd:px-8 md:py-3 xxs:w-full md:w-max  ml-4'
                }
              >
                {translate('confirmNoShow.buttons.confirm')}
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </Modal>
  );
};

export default AdvanceSearchModal;
