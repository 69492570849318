export const LOGIN_ERRORS = {
  ENTER_PASSWORD: 'Password Required Exception',
  INVALID_VERSION: 'Invalid Clinic Cloud Version Exception',
  USER_NOT_FOUND: 'Not Found',
  PASSWORD_NOT_FOUND: 'Password Not Found Exception',
  UNAUTHORIZED_ACCESS: 'Unauthorized access to chat service'
};

export const CHAT_AUTH_RESPONSE = {
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};
