import { FC } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  direction?: 'horizontal' | 'vertical';
  className?: string;
}

export const Divider: FC<Props> = ({
  direction = 'horizontal',
  className = ''
}) => {
  return (
    <>
      {direction === 'horizontal' ? (
        <div
          data-testid='divider'
          className={twMerge('bg-CORE_GRAY h-1px w-full', className)}
        />
      ) : (
        <div
          data-testid='divider'
          className={twMerge('bg-CORE_GRAY w-1px h-full', className)}
        />
      )}
    </>
  );
};
