import { FC } from 'react';

import parse from 'html-react-parser';

import { translate } from 'utils';

interface Props {
  handleBtnClick: () => void;
}
const BulkAssignInstruction: FC<Props> = ({ handleBtnClick }) => {
  return (
    <div className='p-8 w-703px'>
      <div className='text-3xl font-semibold pb-8 text-PRIMARY'>
        {translate('chat.bulk_assign_heading')}
      </div>
      <div className='md:text-base xxs:text-sm text-DEFAULT_TEXT'>
        <ol>
          <li className='flex mb-3'>
            <p className='border-1 border-black mr-1.5 rounded-xl text-center w-7 h-6'>
              1
            </p>
            <p>{parse(translate('chat.bulk_assign_instruction_step_one'))}</p>
          </li>
          <li className='flex justify-center mb-6'>
            <button className='border-1 text-PRIMARY border-PRIMARY p-3 text-base rounded-xl mr-5 cursor-auto'>
              {translate('chat.original_user')}
            </button>
            <div className='relative flex items-center mr-5'>
              <div className='w-4 z-1 h-0.5 bg-PRIMARY'></div>
              <div className='absolute ml-2 w-2 h-2 bg-white border-t-2 border-r-2 border-PRIMARY transform rotate-45 '></div>
            </div>
            <button className='border-1 text-white bg-PRIMARY text-base p-3 rounded-xl mr-5 cursor-auto'>
              {translate('chat.new_user')}
            </button>
          </li>
          <li className='flex mb-6'>
            <p className='border-1 border-black mr-1.5 rounded-xl text-center w-6 h-6'>
              2
            </p>
            {translate('chat.bulk_assign_instruction_step_two')}
          </li>
          <li className='flex mb-6'>
            <p className='border-1 border-black mr-1.5 rounded-xl text-center w-6 h-6'>
              3
            </p>
            {translate('chat.bulk_assign_instruction_step_three')}
          </li>
          <li className='flex mb-4'>
            <p className='border-1 border-black mr-1.5 rounded-xl text-center w-33px h-6'>
              4
            </p>
            {translate('chat.bulk_assign_instruction_step_four')}
          </li>
        </ol>
        <button
          onClick={handleBtnClick}
          className='mt-22px px-6 py-3 w-full bg-PRIMARY text-white rounded-48px'
        >
          {translate('consent_form.start_now')}
        </button>
      </div>
    </div>
  );
};

export default BulkAssignInstruction;
