import { FC, MouseEventHandler } from 'react';

import classNames from 'classnames';

import { CloseIcon } from 'assets/icons';
import { If } from 'components/Generics';
import { translate, customTwMerge as twMerge } from 'utils';

interface Props {
  handleClose?: () => void;
  label: string;
  className?: string;
  disabled?: boolean;
  error?: boolean;
}

export const FilterChip: FC<Props> = ({
  label,
  handleClose,
  className = '',
  disabled = false,
  error = false
}) => {
  const onClickCloseButton: MouseEventHandler<HTMLButtonElement> = (e) => {
    e?.stopPropagation();
    handleClose?.();
  };
  return (
    <div
      className={twMerge(
        'bg-GRAY rounded px-4 py-1 flex items-center w-max whitespace-nowrap max-w-full',
        className,
        classNames({
          'bg-CHINESE_WHITE text-DISABLED_2': disabled,
          'bg-transparent text-ERROR border-1 border-ERROR': error
        })
      )}
    >
      <span className='text-sm overflow-hidden whitespace-nowrap overflow-ellipsis'>
        {translate(label)}
      </span>
      <If condition={!disabled}>
        <button type='button' onClick={onClickCloseButton}>
          <CloseIcon title='close' className='w-2 h-2 ml-2' />
        </button>
      </If>
    </div>
  );
};
