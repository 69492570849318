import { FC, ReactNode, useEffect, useRef } from 'react';

import cx from 'classnames';

import { ChipLabel } from 'components';

type TreatmentProgressChipsProps = {
  id: string;
  onClick: () => void;
  state: 'ACTIVE' | 'DISABLED' | '';
  preventAutoScroll?: boolean;
  children: ReactNode;
};

const TreatmentProgressChips: FC<TreatmentProgressChipsProps> = ({
  id,
  children,
  onClick,
  state,
  preventAutoScroll = false
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (state === 'ACTIVE' && !preventAutoScroll)
      setTimeout(() =>
        buttonRef?.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        })
      );
  }, [state, preventAutoScroll]);

  return (
    <button
      onClick={onClick}
      disabled={state === 'DISABLED'}
      className={`rounded-32px opacity-100 ${cx({
        'text-white bg-PRIMARY': state === 'ACTIVE',
        'bg-PRIMARY_MEDIUM text-PRIMARY_DARK': !state,
        'bg-CHINESE_WHITE text-DISABLED_2 cursor-not-allowed':
          state === 'DISABLED'
      })}`}
      id={id}
      ref={buttonRef}
      type='button'
    >
      <ChipLabel className='py-1 px-3 md:py-1 md:px-4 h-auto md:h-8 text-sm'>
        {children}
      </ChipLabel>
    </button>
  );
};

export default TreatmentProgressChips;
