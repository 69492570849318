import { FC } from 'react';

import classNames from 'classnames';

import { InfoIcon, SuccessIcon, ThreeDotsIcon } from 'assets/icons';
import Tooltip from 'components/Tooltip/Tooltip';
import { DELIVERY_INFO_STATUSES_LABEL } from 'constants/common';
import { DELIVERY_INFO_STATUS } from 'constants/options';
import { customTwMerge, translate } from 'utils';

import { DeliveryProgressBar } from './DeliveryProgressBar';

interface DeliverInfoProps {
  currentStage?: string;
  className?: string;
  popOverClassName?: string;
  isAutoScrollEnabled?: boolean;
  autoScrollRef?: React.RefObject<HTMLDivElement>;
  scrollTo?: ScrollLogicalPosition;
}
export const DeliveryInfo: FC<DeliverInfoProps> = ({
  currentStage,
  className,
  popOverClassName,
  isAutoScrollEnabled,
  autoScrollRef,
  scrollTo
}) => {
  const getLeadingIcon = () => {
    if (currentStage === DELIVERY_INFO_STATUS.RECEIVED)
      return <SuccessIcon className='text-SUCCESS_TEXT w-4 h-4 mr-2' />;
    else return <ThreeDotsIcon className='text-YELLOW_2 w-4 h-4 mr-2' />;
  };

  return currentStage ? (
    <div
      className={customTwMerge(
        'md:items-center md:justify-between md:flex-wrap',
        className
      )}
    >
      <div className='flex items-center'>
        <p className='mr-1 text-DISABLED_2 md:text-sm xxs:text-xs'>
          {translate('DeliveryInfo.Status')}
        </p>
        <Tooltip
          scrollTo={scrollTo}
          autoScrollRef={autoScrollRef}
          positions={['top']}
          content={<DeliveryProgressBar currentStage={currentStage} />}
          messageText={''}
          containerClassName='left-20 mt-1 md:mt-0'
          arrowColor='#000000'
          popOverClassName={popOverClassName}
          isAutoScrollEnabled={isAutoScrollEnabled}
        >
          <InfoIcon className='block w-4 h-4' />
        </Tooltip>
      </div>
      <div className='flex items-center'>
        {getLeadingIcon()}
        <p
          className={customTwMerge(
            'text-YELLOW_2 xxs:text-sm md:text-17px',
            classNames({
              'text-SUCCESS_TEXT':
                currentStage === DELIVERY_INFO_STATUS.RECEIVED
            })
          )}
        >
          {translate(DELIVERY_INFO_STATUSES_LABEL[currentStage])}
        </p>
      </div>
    </div>
  ) : (
    <div className='hidden'></div>
  );
};
export default DeliveryInfo;
