import { FC, ReactNode } from 'react';

import { customTwMerge as twMerge } from 'utils';

interface CollapsibleProps {
  isExpanded: boolean;
  className: string;
  isExpandedStyle: string;
  notExpandedStyle: string;
  children: ReactNode;
}

const CollapsibleDrawer: FC<CollapsibleProps> = ({
  isExpanded,
  isExpandedStyle,
  notExpandedStyle,
  children,
  className
}) => {
  return (
    <div
      className={twMerge(
        className,
        isExpanded ? isExpandedStyle : notExpandedStyle
      )}
    >
      {children}
    </div>
  );
};

export default CollapsibleDrawer;
