import { gql } from '@apollo/client';

export const GET_TREATING_PATIENTS = gql`
  query (
    $searchText: String
    $paginationInfo: PaginationInfo
    $types: [String]
  ) {
    getTreatingPatients(
      params: $searchText
      paginationInfo: $paginationInfo
      types: $types
    ) {
      edges {
        id
        firstName
        lastName
        email
        mobile
        phone
        salesforceId
        profilePicture
        details {
          salesforceId
        }
      }
    }
  }
`;

export const GET_RESUBMISSION_FILES = gql`
  query getResubmissionFiles($id: ID!) {
    getTreatmentById(id: $id) {
      id
      treatmentPlan {
        id
        status
        resubmissionFiles
      }
      documents {
        documentId
        documentName
        classification
        documentType
        sourceType
        createdAt
        presignedUrl
        thumbnail
        extension
        origin
        resubmissionStatus
      }
    }
  }
`;

export const SAVE_RETAINER = gql`
  mutation SaveRetainerPrescription($id: ID!, $input: PrescriptionInput!) {
    saveRetainerPrescription(treatmentId: $id, input: $input) {
      id
      updatedAt
    }
  }
`;

export const SUBMIT_RETAINER = gql`
  mutation SubmitRetainerPrescription($id: ID!, $input: PrescriptionInput!) {
    submitRetainerPrescription(treatmentId: $id, input: $input) {
      id
      updatedAt
    }
  }
`;

export const SAVE_WHITENING = gql`
  mutation SaveWhiteningPrescription($id: ID!, $input: PrescriptionInput!) {
    saveWhiteningPrescription(treatmentId: $id, input: $input) {
      id
      updatedAt
    }
  }
`;

export const SUBMIT_WHITENING = gql`
  mutation SubmitWhiteningPrescription($id: ID!, $input: PrescriptionInput!) {
    submitWhiteningPrescription(treatmentId: $id, input: $input) {
      id
    }
  }
`;

export const SUBMIT_PICKUP_OUTCOME = gql`
  mutation submitPickUpOutcome(
    $treatmentId: String!
    $outcome: PickUpOutcome!
  ) {
    submitPickUpOutcome(treatmentId: $treatmentId, outcome: $outcome) {
      id
    }
  }
`;

export const GET_TREATMENT_PAYMENT_DETAILS = gql`
  query getTreatmentPaymentDetails(
    $opportunityId: String!
    $type: TreatmentType!
  ) @api(name: treatmentMonitor) {
    getTreatmentPaymentDetails(opportunityId: $opportunityId, type: $type) {
      opportunityId
      status
      paymentDate
    }
  }
`;

export const GET_UNREAD_MESSAGES_COUNT = gql`
  query getUnReadMessageCount($input: UnReadMessageCountInput) {
    getUnReadMessageCount(input: $input)
  }
`;
export const GET_PATIENT_DATA = gql`
  query getTreatmentById($id: ID!) {
    getTreatmentById(id: $id) {
      id
      predecessorId
      patientId
      stage
      predecessorId
      updatedAt
      clinic {
        id
        name
        scannerType
        headClinicId
        sfClinicId
        country
        isRetainerEnabled
        staffs(roles: [PARTNERSHIP_MANAGER]) {
          id
          firstName
          lastName
        }
      }
      details {
        consentStatus
        offboardingLink
        fittingAppointmentOutcome
        resubmissionRequestedAt
      }
      alignerProductionInfo {
        deliveryObject {
          status
          statusUpdatedAt
          statusLabel
        }
        productionRecord {
          status
          statusUpdatedAt
          statusLabel
        }
      }
      concern
      issues
      patient {
        id
        firstName
        lastName
        primaryCountry
        phone
        email
        profilePicture
        salutation
        details {
          ageCategory
          age
        }
        guardian {
          id
          email
          phone
          lastName
          firstName
        }
      }
      status
      zenyumCouncilPatientConcern
      zenyumCouncilExclusion
      employee {
        firstName
        lastName
      }
      documents {
        documentId
        documentName
        classification
        documentType
        sourceType
        createdAt
        presignedUrl
        thumbnail
        extension
        origin
        submittedBy
      }
      medicalStaff {
        doctors {
          primary
          role
          details {
            id
            salesforceId
            firstName
            lastName
            email
            primaryCountry
            phone
            details {
              maxComplexity
            }
          }
        }
      }
      treatmentSpecifications {
        teethToExtract
        iprLocation
        treatmentPlanInstructions
        zenplusInstructions
        treatmentPlanTypes
        crowdingResolvement
        attachmentRequired
        useToothImpressions
        version
        tpv {
          archesToTreat
          toothMovementRestrictions
          prosthesis
          prosthesisOptions
          prosthesisIprToAvoid
          prosthesisAttachmentToAvoid
          teethToExtract
          specialNotes
          translatedSpecialNotes
          overbite
          midlines
          midlinesShifts
          treatmentGoals {
            name
            procedures
          }
          designId
          submissionReason
          alignerInfo
          refinementGoals
          attachments
          iprInfo
          iprInfoText
          totalAlignerSet
          attachmentsOnlyCheck
          addButtonsForElasticsCheck
          clinicalPreferences {
            iprSize
            iprLocation
            biteRamps
            premolarCrossbite
            extrusionElastics
          }
        }
      }
      priorTreatment {
        completed
        treatments
        canPlanningProceed
      }
      family {
        id
        type
        hasRetainer
        opportunityId
        cpTreatmentId
        hasCaseOpsIssue
        status
        stage
      }
      treatmentConsent {
        patientConsent {
          signedOn
        }
        guardianConsent {
          signedOn
        }
      }
      details {
        metadata
        internalDoctorReview
        externalDoctorReview
        opportunityId
        segmentInfo {
          prescriptionLastFilled {
            fieldName
            updatedAt
          }
          prescriptionFirstFilled {
            fieldName
            updatedAt
          }
        }
      }
      successorId
      retainerPrintedUpperLowerSets
      numberOfRetainerSets
      offboardingUpperLowerSets
      patientOffboardingComments
      internalDoctorOffboardingComments
      externalDoctorOffboardingComments
      type
      treatmentPlan {
        id
        metadata
        treatmentSpecifications {
          cadence {
            wearTimePrescribed
            hours
            days
            schedule
            additionalInstructions
          }
          retainerSetInfo {
            upper
            lower
            type
          }
          whiteningInfo {
            isWhiteningEligible
            isValidForRepurchase
            validFor
          }
        }
        status
        versions {
          id
          treatmentPlanId
          acceptanceStatus
          rejectionInfo {
            rejectedBy
          }
        }
        documents {
          treatmentPlanId
          documentId
          sourceType
          documentType
          presignedUrl
          thumbnail
          documentName
          extension
        }
        alignerProductionInfo {
          deliveryObject {
            status
            statusLabel
          }
          productionRecord {
            status
            statusLabel
            actualDate
            expectedDate {
              from
              to
            }
          }
        }
        pickUpDetails {
          outcome
          actualDate
          expectedDate {
            from
            to
          }
        }
      }
      keyLinks {
        salesforceLink
        adminPortalLink
        retainerSalesforceLink
      }
      treatmentAppointments(
        input: { appointmentType: "Consultation", limit: 1 }
      ) {
        id
        appointmentType
        patientTreatmentInfo {
          patientId
          caseComplexity
          patient {
            firstName
            lastName
          }
        }
        patientPreferredSlots {
          createdAt
        }
        createdAt
        statusInfo {
          statusCode
          actionCode
        }
      }
      terminated
      relatedTreatment {
        id
        type
        opportunityId
        hasCaseOpsIssue
        status
        stage
        treatmentPlan {
          id
          pickUpDetails {
            outcome
          }
          status
          treatmentSpecifications {
            cadence {
              wearTimePrescribed
              hours
              days
              schedule
              additionalInstructions
            }
            retainerSetInfo {
              upper
              lower
              type
            }
            whiteningInfo {
              isWhiteningEligible
              isValidForRepurchase
              validFor
            }
          }
        }
      }
    }
  }
`;

export const GET_FAMILIES_OF_PATIENT = gql`
  query getTreatmentFamiliesOfPatient($patientId: String!) {
    getTreatmentFamiliesOfPatient(patientId: $patientId) {
      aligner {
        id
        type
        opportunityId
        hasCaseOpsIssue
        status
        stage
      }
      retainer {
        id
        type
        opportunityId
        hasCaseOpsIssue
        status
        stage
        scanPaymentRequired
        purchaseInfo {
          paymentStatus
        }
      }
      whitening {
        id
        type
        opportunityId
        hasCaseOpsIssue
        status
        stage
        purchaseInfo {
          paymentStatus
        }
      }
    }
  }
`;
export const GET_PATIENT_DOCUMENTS = gql`
  query getPatientDocuments($input: DocumentByTreatmentIdsInput!) {
    getTreatmentDocumentByTreatmentIds(input: $input) {
      key
      documentId
      documentName
      documentType
      sourceType
      presignedUrl
      extension
      resubmissionStatus
    }
  }
`;
export const GET_PATIENT_DOCUMENT = gql`
  query getPatientDocument($id: ID!) {
    getTreatmentDocumentByTreatmentId(id: $id) {
      key
      documentId
      documentName
      documentType
      sourceType
      presignedUrl
      thumbnail
      classification
      extension
      resubmissionStatus
      createdAt
    }
  }
`;

export const UPDATE_PATIENT_DETAILS = gql`
  mutation ($id: ID!, $input: UpdateTreatmentInput!) {
    updateTreatment(id: $id, input: $input) {
      id
      clinic
    }
  }
`;
export const RESUBMISSION_FILES = gql`
  mutation ($treatmentId: ID!) {
    submitResubmissionFiles(treatmentId: $treatmentId)
  }
`;

export const UPDATE_TREATMENT_STATUS = gql`
  mutation ($id: ID!, $status: String!, $treatmentAppointmentId: ID) {
    updateAppointmentStatusByTreatmentId(
      treatmentId: $id
      status: $status
      treatmentAppointmentId: $treatmentAppointmentId
    ) {
      id
      status
    }
  }
`;

export const POLL_FOR_REFINEMENT = gql`
  query getTreatmentById($id: ID!) {
    getTreatmentById(id: $id) {
      id
      type
      status
    }
  }
`;
export const GET_TREATMENT_PLAN_VERSION_BY_ID = gql`
  query getTreatmentPlanVersionById($id: String!) {
    getTreatmentPlanVersionById(id: $id) {
      id
      treatmentPlanId
    }
  }
`;

export const EDIT_PATIENT = gql`
  mutation ($id: ID!, $details: PatientUpdateInput!, $clinicId: String) {
    updatePatient(patientId: $id, details: $details, clinicId: $clinicId)
  }
`;
