import { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { DownArrowIcon } from 'assets/icons';

interface Props {
  isOpen: boolean;
  arrowStyle?: string;
  testId?: string;
}

const DropdownArrow: FC<Props> = ({ isOpen, arrowStyle, testId }) => {
  return isOpen ? (
    <DownArrowIcon
      className={twMerge('w-3 h-3 transform rotate-180', arrowStyle)}
      data-testid={testId}
    />
  ) : (
    <DownArrowIcon
      className={twMerge('w-3 h-3', arrowStyle)}
      data-testid={testId}
    />
  );
};

export default DropdownArrow;
