import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { IprInfo } from 'types/TPVForm.types';

import { ToothChart3 } from './ToothChart3';

type Props = {
  name: string;
  toothData?: IprInfo[];
  missingTooth?: string[];
  disabled?: boolean;
};

export const RHFToothChart3: FC<Props> = ({
  name,
  toothData,
  missingTooth,
  disabled = false
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <ToothChart3
          toothData={toothData}
          selected={field?.value}
          setSelected={field?.onChange}
          missingTooth={missingTooth}
          disabled={disabled}
        />
      )}
    />
  );
};
