import { FC, useMemo } from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import { CloseIcon } from 'assets/icons';
import { useGetAllAppointments } from 'services/hooks/calendarHooks';
import { Event } from 'types/Calendar.types';
import { formatAllAppointments } from 'utils/calendarUtil';
import { formatDate, formatTimeRange } from 'utils/dateUtils';

import CalendarPopoverCardSkeleton from './CalendarPopoverCardSkeleton';
import CalendarPopoverEventCard from './CalendarPopoverEventCard';

type CalendarPopoverProps = {
  x: number;
  y: number;
  date: Date;
  onClose: () => void;
  onEventSelect?: (event: Event) => void;
};

const CalendarPopover: FC<CalendarPopoverProps> = ({
  x,
  y,
  date,
  onClose,
  onEventSelect
}) => {
  const POPOVER_MARGIN = 50;
  const TOP_OFFSET = 100;
  const POPOVER_WIDTH = '37rem';
  const POPOVER_HEIGHT = '18.75rem';
  const maxLeft = window.innerWidth - POPOVER_MARGIN;
  const maxTop = window.innerHeight - POPOVER_MARGIN;

  const startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);
  const endDate = new Date(date);
  endDate.setHours(23, 59, 59, 999);

  const { data: { getAllAppointments: appointments = [] } = {}, loading } =
    useGetAllAppointments({
      variables: {
        filter: {
          start_date: startDate,
          end_date: endDate
        }
      },
      fetchPolicy: 'network-only'
    });

  const formattedAppt = useMemo(
    () => formatAllAppointments(appointments),
    [appointments]
  );

  const dateTitle = formatDate(date, 'MMMM DD, YYYY');

  const getTimeText = (start?: Date, end?: Date) => {
    return `${formatTimeRange(start)} - ${formatTimeRange(end)}`;
  };

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <div
        className={`fixed z-[100] bg-white md:w-[37rem] md:max-h-[18.75rem] flex flex-col shadow-calendar-popover`}
        style={{
          top: `min(calc(${maxTop}px - ${POPOVER_HEIGHT}), ${
            y - TOP_OFFSET
          }px)`,
          left: `min(calc(${maxLeft}px - ${POPOVER_WIDTH}), ${x}px)`
        }}
      >
        <div className='p-1 flex justify-between bg-[#d0d0d04d]'>
          <h3>{dateTitle}</h3>
          <button className='p-1' onClick={onClose}>
            <CloseIcon className='h-3 w-3' />
          </button>
        </div>
        <div className='p-2.5 bg-white flex-1 h-0 overflow-y-scroll'>
          <div className=''>
            {loading ? (
              <>
                <CalendarPopoverCardSkeleton />
                <CalendarPopoverCardSkeleton />
                <CalendarPopoverCardSkeleton />
              </>
            ) : (
              formattedAppt.map((appointment: any) => (
                <CalendarPopoverEventCard
                  key={appointment?.id}
                  title={appointment?.title}
                  timeText={getTimeText(appointment?.start, appointment?.end)}
                  color={appointment?.color}
                  onClick={() => onEventSelect?.(appointment)}
                />
              ))
            )}
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default CalendarPopover;
