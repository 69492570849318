import { FC } from 'react';

import { Add } from 'assets/icons';

type AddFileButtonProps = {
  onClick?: () => void;
  height?: string;
  width?: string;
  className?: string;
};
const AddFileButton: FC<AddFileButtonProps> = ({
  onClick,
  height = 'h-28.5 md:min-h-[11.5rem] md:h-full',
  width = 'w-28.5 md:w-44.5',
  className = 'flex w-full h-full cursor-default'
}) => {
  return (
    <div className={className}>
      <button
        title='add more'
        type='button'
        onClick={onClick}
        className={`${height} ${width} flex justify-center mt-6px items-center bg-CHINESE_WHITE border-solid rounded-xl`}
      >
        <Add className='fill-current text-SECONDARY_TEXT h-8 w-8' />
      </button>
    </div>
  );
};

export default AddFileButton;
