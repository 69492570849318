export enum ERROR_CODE {
  APPOINTMENT_BOOKED_NO_MODIFICATION_ALLOWED = 'Appointment already booked - No Further changes allowed',
  TREATMENT_APPOINTMENT_NOT_FOUND = 'Treatment Appointment Not Found',
  MIN_VALID_SLOTS_REQUIRED = 'Min Valid slots Required',
  MIN_SLOT_DURATION_REQUIRED = 'Min slot Duration Required',
  CLINIC_PREFERRED_SLOT_NOT_FOUND = 'Clinic preferred slots not found',
  CALENDAR_SERVICE_CONNECTION_ERROR = 'Error Connecting to Calendar Service',
  PATIENT_PREFERRED_SLOT_NOT_FOUND = 'Patient Preferred Slot Not Found',
  APPOINTMENT_NOT_FOUND = 'Appointment Not Found',
  APPOINTMENT_CLOSED_NO_MODIFICATION_ALLOWED = 'Appointment is Closed - No Further Modification Allowed',
  PATIENT_PREFERRED_SLOTS_ALREADY_SUBMITTED = 'Patient Preferred Slots already submitted',
  CLINIC_PREFERRED_SLOTS_ALREADY_SUBMITTED = 'Clinic Preferred Slots already submitted',
  DATA_MODIFIED_IN_BACKGROUND = 'error.data_modified_in_background_please_reload',
  START_TIME_AFTER_END_TIME = 'Start time after end time',
  SELECT_BETWEEN_DAYS = 'Select between 1 to 90 days',
  LEAVE_NOT_FOUND = 'LEAVE_NOT_FOUND'
}

/** TODO: translation to be added */
export const STATUS_CODE_MESSAGE: Record<number, string> = {
  403: 'Please reload the page.'
};

export const START_TIME_ERROR_TYPES = [
  'startTimeAfterEndTime',
  'startTimeBeforeCurrentTime'
];
