import { FC, ReactNode } from 'react';

import { customTwMerge } from 'utils';

interface TableComponentProps {
  style?: React.CSSProperties;
  props: {
    children?: ReactNode;
    context?: any;
  };
  className?: string;
}

const TableComponent: FC<TableComponentProps> = ({
  style,
  className,
  props
}) => {
  return (
    <table
      {...props}
      className={customTwMerge(
        `w-full h-10 relative overflow-visible`,
        className
      )}
      data-testid='table'
      style={{
        ...style,
        width: '100%',
        tableLayout: 'fixed',
        borderCollapse: 'collapse',
        borderSpacing: 0
      }}
    />
  );
};

export default TableComponent;
