import { FC, useEffect } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import { FormLabel } from 'components/FormLabel/FormLabel';
import { If } from 'components/Generics';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import InfoToolTip from 'components/InfoToolTip';
import RhfTeethToExtract from 'components/TreatmentPlanningInstructions/TreatmentSpecifications/TeethToExtract/RhfTeethToExtract/RhfTeethToExtract';
import { TEETH_SELECT_OPTIONS } from 'constants/TPVFormOptions';
import { Option } from 'types/select';
import { translate } from 'utils';

type Props = {
  title: string;
  radioGroupName: string;
  radioGroupOptions: Option[];
  teethSelectionName: string;
  message?: string;
  disabled?: boolean;
  animateLabel?: string;
};

export const TeethSelectRadioInput: FC<Props> = ({
  title,
  radioGroupName,
  radioGroupOptions,
  teethSelectionName,
  message,
  disabled = false,
  animateLabel
}) => {
  const {
    control,
    watch,
    formState: { isSubmitted, errors },
    trigger
  } = useFormContext();
  const [toothMovementRestrictions, teethSelected] = watch([
    radioGroupName,
    teethSelectionName
  ]);

  useEffect(() => {
    if (isSubmitted) trigger([radioGroupName, teethSelectionName]);
  }, [
    toothMovementRestrictions,
    teethSelected,
    isSubmitted,
    trigger,
    radioGroupName,
    teethSelectionName
  ]);

  const getSelectedOption = () => {
    return radioGroupOptions.find(
      ({ value }) => value == toothMovementRestrictions
    );
  };

  return (
    <div>
      <Controller
        control={control}
        name={radioGroupName}
        render={({ field }) => (
          <>
            <div className={classNames('flex flex-wrap space-x-2 ')}>
              <FormLabel
                label={translate(title)}
                className='mb-4'
                error={
                  !!_.get(errors, teethSelectionName) ||
                  !!_.get(errors, radioGroupName)
                }
                name={radioGroupName}
              />
              <If condition={!!message}>
                <InfoToolTip
                  message={message}
                  className='w-5 h-5 text-PRIMARY'
                  toolTipStyle='w-45 md:w-100'
                />
              </If>
            </div>

            <div className='md:w-325px'>
              <RhfSelect
                name={radioGroupName}
                options={radioGroupOptions}
                onChange={(data) => {
                  field.onChange(data?.value);
                }}
                isDisabled={disabled}
                inputProps={{
                  controlShouldRenderValue: true,
                  selectedValue: getSelectedOption()
                }}
              />
            </div>
            <If
              condition={
                toothMovementRestrictions === TEETH_SELECT_OPTIONS.SELECT_TEETH
              }
            >
              <div className='mt-6'>
                <RhfTeethToExtract
                  showError={false}
                  name={teethSelectionName}
                  disabled={disabled}
                  animateLabel={animateLabel}
                />
              </div>
            </If>
          </>
        )}
      />
    </div>
  );
};
