import { FC, useCallback } from 'react';

import { throttle } from 'lodash';

import { BinIcon, ErrorIcon } from 'assets/icons';
import { PRESCRIPTION_PHOTO_FORMAT } from 'constants/settings';
import {
  CLASSIFICATION_LABEL,
  photoClassificationCategories,
  xRayClassificationCategories
} from 'constants/treatmentFiles';
import { Classification, FilesData } from 'types/Files.types';
import { Option } from 'types/select';
import { TreatmentDocumentType } from 'types/TreatmentPlanning.types';
import { formatDate, translate } from 'utils';

import { If, Thumbnail } from './Generics';
import Select from './Select/Select';

interface Props {
  fileType?: TreatmentDocumentType;
  fileId: string;
  files: FilesData[];
  fileData?: FilesData;
  onDelete: (files: FilesData[], id?: string) => void;
  onChange?: (option: Option) => void;
  isDuplicateClassificationSelected?: boolean | null;
  toggleZoomPhoto?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPhoto?: React.Dispatch<React.SetStateAction<string | number>>;
  missingFilesList?: Option[];
}

const getClassificationList = (
  missingFilesList: Option[] = [],
  fileType?: TreatmentDocumentType
) => {
  if (missingFilesList?.length > 0) return missingFilesList;
  else {
    return fileType === TreatmentDocumentType.PHOTO
      ? photoClassificationCategories
      : xRayClassificationCategories;
  }
};

const MobilePhotoCard: FC<Props> = ({
  fileId,
  files,
  fileType,
  fileData,
  onDelete,
  toggleZoomPhoto,
  isDuplicateClassificationSelected,
  onChange,
  setSelectedPhoto,
  missingFilesList = []
}) => {
  const throttledDelete = useCallback(
    throttle(onDelete, 3000, {
      trailing: false
    }),
    []
  );

  return (
    <div className='border-b-2 pb-4'>
      <div className='flex flex-col items-center'>
        <button
          onClick={() => {
            if (
              fileData?.fileType === 'img' &&
              fileData?.createdAt &&
              (fileData?.uploadProgress
                ? fileData?.uploadProgress === 100
                : true)
            ) {
              toggleZoomPhoto?.(true);
              setSelectedPhoto?.(fileData?.id || fileData?.fileId || '');
            }
          }}
          className='w-93px h-93px box-content border-12px rounded-xl mb-4'
        >
          <Thumbnail
            height='h-93px'
            file={fileData?.fileSrc || ''}
            type={fileData?.fileType}
          />
        </button>

        <div className='mb-2 text-xl text-center break-all'>
          {fileData?.fileName}
        </div>
        <div className='text-DISABLED_2 text-sm text-center'>
          {fileData?.createdAt
            ? formatDate(fileData?.createdAt, PRESCRIPTION_PHOTO_FORMAT)
            : 'Uploading...'}
        </div>
        <div className='flex flex-col'>
          <div className='flex space-x-4 mt-7 '>
            <div className='w-52'>
              <Select
                onChange={onChange}
                isDisabled={!fileData?.id}
                className='rounded border-1 border-LIGHT_GRAY_2 w-full text-LIGHT_GRAY_4 p-2 text-sm'
                placeHolderStyle={{ fontSize: '1rem', fontColor: '#B8B8B5' }}
                selectedValue={
                  fileData?.classification && {
                    label: CLASSIFICATION_LABEL[fileData?.classification || ''],
                    value:
                      fileData?.classification === Classification.OTHER
                        ? null
                        : fileData?.classification
                  }
                }
                controlShouldRenderValue={true}
                placeholder={translate('Rejection.Dropdown_menu')}
                menuPlacement='auto'
                hideSelectedOptions={true}
                menuListMaxHeight='285px'
                options={getClassificationList(missingFilesList, fileType)}
              />
            </div>
            <button
              onClick={() => throttledDelete(files, fileId)}
              type='button'
              title='delete-icon'
              disabled={!fileData?.id}
            >
              <BinIcon title='delete' className=' h-5 w-5' />
            </button>
          </div>
          <If condition={!!isDuplicateClassificationSelected}>
            <p className='text-ERROR text-xs mt-4 flex '>
              <ErrorIcon />
              <span className='ml-2'>
                {translate('classification.selected')}
              </span>
            </p>
          </If>
        </div>
      </div>
    </div>
  );
};

export default MobilePhotoCard;
