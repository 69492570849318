import { gql } from '@apollo/client';

export const CHECK_DATA_VALIDITY = gql`
  query CheckIfReferralValid(
    $phone: String!
    $clinicId: String!
    $email: String
    $id: String
  ) {
    checkIfReferralValid(
      input: { phone: $phone, email: $email, id: $id, clinicId: $clinicId }
    ) {
      message
      status
    }
  }
`;
export const GET_AGE_DATA = gql`
  query GetUserAgeGroups($countryCode: CountryCode) {
    getUserAgeGroups(countryCode: $countryCode) {
      ageGroups {
        key
        value
      }
      minAge
    }
  }
`;

export const GET_FORM_DATA = gql`
  query {
    getAllDoctors {
      firstName
      lastName
      id
      details {
        maxComplexity
      }
    }
    getPatientIssueList
    getPatientConcernList
  }
`;

export const GET_LIST_DATA = gql`
  query ($input: FilterReferralInput) {
    getReferrals(input: $input) {
      firstName
      lastName
      phone
      consentStatus
      id
      clinic {
        name
      }
    }
  }
`;

export const GET_TAB_DATA = gql`
  query {
    getReferralStatusInfo {
      key
      name
      count
    }
  }
`;

export const SAVE_DATA = gql`
  mutation ($input: ReferralInput) {
    saveReferral(input: $input) {
      id
      updatedAt
    }
  }
`;

export const SUBMIT_DATA = gql`
  mutation ($input: ReferralInput) {
    submitReferral(input: $input) {
      id
      treatmentSpecifications {
        treatmentPlanInstructions
        zenplusInstructions
      }
      updatedAt
    }
  }
`;

export const GET_REFERRAL_BY_ID = gql`
  query getReferralById($id: String!, $name: UserPreferenceName!) {
    getUserPreference(name: $name) {
      name
      preference
    }

    getReferralById(id: $id) {
      stage
      firstName
      lastName
      email
      phone
      ageCategory
      concerns
      issues
      status
      age
      patientId
      consentStatus
      referringDoctorId
      referringDoctor {
        firstName
        lastName
        details {
          maxComplexity
        }
      }
      documents {
        id
        key
        name
        classification
        url
        type
      }
      treatmentSpecifications {
        teethToExtract
        iprLocation
        treatmentPlanInstructions
        zenplusInstructions
        treatmentPlanTypes
        crowdingResolvement
        attachmentRequired
        useToothImpressions
        tpv {
          archesToTreat
          toothMovementRestrictions
          prosthesis
          prosthesisOptions
          prosthesisIprToAvoid
          prosthesisAttachmentToAvoid
          teethToExtract
          specialNotes
          translatedSpecialNotes
          overbite
          midlines
          midlinesShifts
          treatmentGoals {
            name
            procedures
          }
          clinicalPreferences {
            iprSize
            iprLocation
            biteRamps
            premolarCrossbite
            extrusionElastics
          }
        }
      }
      priorTreatment {
        completed
        treatments
        canPlanningProceed
      }
      documents {
        id
        url
        name
        type
        createdAt
        extension
        thumbnail
      }
      clinic {
        value: id
        label: name
        scannerType
        country
      }
      guardian {
        id
        email
        phone
        lastName
        firstName
      }
      updatedAt
    }
  }
`;
export const GET_CONSENT_FORM_STATUS = gql`
  query getConsentStatusById($id: String!) {
    getReferralById(id: $id) {
      consentStatus
    }
  }
`;

export const GET_ALL_CLINICS = gql`
  query GetAllClinics($filter: ClinicFilter) {
    getAllClinics(filter: $filter) {
      value: id
      label: name
      country
    }
  }
`;

export const DELETE_REFERRAL = gql`
  mutation DeleteReferral($id: ID!) {
    deleteReferral(id: $id)
  }
`;
