import { FC, useRef } from 'react';

import cx from 'classnames';

import { SELECT_SEARCH_WIDTH } from 'constants/options';
import twMerge from 'utils/twMerge';

import DropdownArrow from './DropDownArrow';

interface DropdownProps {
  isOpen: boolean;
  onClose: () => void;
  onToggle: () => void;
  children: React.ReactNode;
  placeholder: React.ReactNode;
  className?: string;
  arrowStyle?: string;
  testId?: string;
  isFilter?: boolean;
  isDeactivated?: boolean;
  searchStyle?: string;
}

const Dropdown: FC<DropdownProps> = ({
  isOpen,
  onClose,
  onToggle,
  children,
  placeholder,
  className,
  arrowStyle,
  isFilter,
  isDeactivated,
  testId = 'select-component',
  searchStyle
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { left: containerLeft = 0 } =
    containerRef.current?.getBoundingClientRect() || {};
  const isOptionOverflowing =
    containerLeft + SELECT_SEARCH_WIDTH > window.innerWidth;

  return (
    <div
      className={cx('relative', { 'w-full': !isFilter })}
      data-testid='select-search'
      ref={containerRef}
    >
      {isFilter ? (
        <button
          data-testId={testId}
          onClick={onToggle}
          className={twMerge(
            'flex items-center gap-4 bg-GRAY px-4 py-1.5',
            className
          )}
        >
          {placeholder}
          <DropdownArrow
            testId={`${testId}-select-arrow`}
            isOpen={isOpen}
            arrowStyle={arrowStyle}
          />
        </button>
      ) : (
        <button
          type='button'
          onClick={onToggle}
          className={twMerge(
            cx(
              'flex items-center px-4 py-18px w-full justify-between border-LIGHT_GRAY_2 border-1 rounded-lg text-base',
              searchStyle,
              {
                'text-LIGHT_GRAY_4': isDeactivated
              }
            )
          )}
        >
          {placeholder}
          <DropdownArrow isOpen={isOpen} arrowStyle={arrowStyle} />
        </button>
      )}
      {isOpen ? (
        <div
          style={{ width: 'inherit' }}
          className={cx('mt-2 absolute z-20 bg-white', {
            'right-0': isOptionOverflowing
          })}
        >
          {children}
        </div>
      ) : null}
      {isOpen ? (
        <div
          className='fixed inset-0 z-1'
          role='button'
          tabIndex={0}
          onKeyDown={onClose}
          onClick={onClose}
        />
      ) : null}
    </div>
  );
};

export default Dropdown;
