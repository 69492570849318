import { FC } from 'react';

import cx from 'classnames';

import If from 'components/Generics/If/If';
import SearchItem from 'components/SearchItem/SearchItem';
import { AutoSuggestions, SearchListProps } from 'types/autoSuggest.types';

type Props = {
  suggestions: AutoSuggestions[];
  onSuggestionClick: (userDetails: AutoSuggestions) => void;
  searchedText?: string;
  showTypedText?: boolean;
};

const SearchList: FC<Props & SearchListProps> = ({
  onSuggestionClick,
  suggestions,
  searchedText,
  noDataMsg,
  showTypedText,
  minSearchTextlength,
  className = '',
  searchItemClassName = '',
  searchListMaxHeight = 'md:max-h-60vh',
  showDetails
}) => (
  <div
    className={`${searchListMaxHeight} text-14px md:text-base`} /* md:overflow-scroll */
    data-testid='typed-word'
  >
    {showTypedText && (
      <div
        className={cx('pb-4', {
          'border-b border-BORDER_COLOR pb-4':
            Number(searchedText?.length) >= Number(minSearchTextlength) &&
            suggestions.length
        })}
      >
        {`“${searchedText}”`}
      </div>
    )}
    <If condition={Number(searchedText?.length) >= Number(minSearchTextlength)}>
      {suggestions.map((details) => {
        return (
          <div
            className={`py-3 border-b border-BORDER_COLOR cursor-pointer ${className}`}
            key={details.id}
            data-testid={details.id}
            onClick={() => onSuggestionClick(details)}
            role='presentation'
          >
            <SearchItem
              showDetails={showDetails}
              {...details}
              className={searchItemClassName}
            />
          </div>
        );
      })}
      <If condition={!suggestions?.length}>
        <div className='py-4 text-14px md:text-base' data-testid='no-data-msg'>
          {noDataMsg}
        </div>
      </If>
    </If>
  </div>
);

export default SearchList;
