import { FC } from 'react';

import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { CompletedIcon, InfoIcon } from 'assets/icons';
import Tooltip from 'components/Tooltip/Tooltip';
import { translate } from 'utils';

type InfoMessageProps = {
  message: string;
  status?: string;
  isDisabled?: boolean;
  link: string;
  linkTitle?: string;
  isLinkVisible?: boolean;
};

export const InfoMessage: FC<InfoMessageProps> = ({
  message,
  status,
  isDisabled = false,
  link,
  linkTitle,
  isLinkVisible
}) => {
  return (
    <div className='flex space-x-3 items-center'>
      {!!status && (
        <>
          <CompletedIcon className='block md:hidden' />
          <div className='bg-LIGHT_GREEN  text-SUCCESS rounded-100px px-5 py-10px hidden md:block'>
            {translate('treatment.completed')}
          </div>
        </>
      )}
      {isLinkVisible && (
        <Link
          to={link}
          className={classNames('underline  text-xs', {
            'text-slate-300': isDisabled,
            'text-black': !isDisabled,
            'cursor-not-allowed': isDisabled,
            'pointer-events-none': isDisabled
          })}
        >
          {translate(linkTitle || '')}
        </Link>
      )}
      {!isDisabled && message && !status && (
        <div className='relative'>
          <div className='bg-LIGHT_BLUE text-DARK_BLUE px-4 py-1 hidden md:block rounded-full  text-xs'>
            {message}
          </div>
          <Tooltip
            messageText={message}
            containerClassName='left-20'
            tooltipStyle='bg-LIGHT_BLUE text-DARK_BLUE px-3 py-2 text-10px rounded-8px'
          >
            <InfoIcon className='block md:hidden' />
          </Tooltip>
        </div>
      )}
    </div>
  );
};
