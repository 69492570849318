import { createPlugin } from '@fullcalendar/react';

import { ScheduleView } from './ScheduleView';

const ScheduleViewConfig = {
  component: ScheduleView,
  type: 'dayGridMonth'
};

export default createPlugin({
  views: {
    scheduleView: ScheduleViewConfig
  }
});
