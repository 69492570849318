import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import fetch from 'cross-fetch';

import {
  GRAPHQL_ENDPOINT,
  TREATMENT_MONITOR_GRAPHQL_ENDPOINT,
  CHAT_API_ENDPOINT
} from 'config';
import { pagePaths } from 'config/pages';
import { ERROR_CODE_MAP } from 'constants/translationMaps';
import { showToast } from 'utils/toast/toast.util';

import { STATUS_CODE_MESSAGE } from './../constants/errorCode';
import { isSearchingPatient } from './cache';

const ignoredErrors = [
  'passwordLogin',
  'getTickets',
  'getLoggedInUser',
  'getRecentTickets',
  'ChangePassword',
  'getGroups',
  'verifyPasswordResetLink',
  'getTreatmentById',
  'getReferralById',
  'generatePasswordResetLink',
  'getTreatmentAppointmentById',
  'SubmitPrescription',
  'SavePrescription',
  'setPassword',
  'getTreatmentConsentSignatureUploadUrl',
  'verifyLink',
  'getTreatmentConsentInfo',
  'verifyEncryption',
  'getTreatmentConsentFormInfo',
  'giveTreatmentConsent',
  'getTreatmentByCpIdForCalendar',
  'getTreatmentByIdForCalendar',
  'SaveTPV',
  'SubmitTPV',
  'verifyConsentEncryption',
  'UpdateConsentLanguage',
  'cancelLeave'
];

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers
      }
    };
  });
  return forward(operation);
});

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getAllTreatmentAppointments: {
          keyArgs: ['filter'],
          merge: (existing, incoming) => {
            const isFirstUpdate = incoming?.paginationInfo?.offset === 0;
            if (existing?.treatmentAppointments && !isFirstUpdate) {
              return {
                treatmentAppointments: [
                  ...existing.treatmentAppointments,
                  ...incoming.treatmentAppointments
                ],
                totalCount: incoming.totalCount,
                paginationInfo: incoming.paginationInfo
              };
            } else {
              return incoming;
            }
          },
          read: (existing) => existing
        },
        getTreatmentsByView: {
          read: (existing) => {
            return { ...existing, isSearching: isSearchingPatient() };
          }
        },
        getAllStaff: {
          keyArgs: ['filter'],
          merge: (existing, incoming) => {
            const { offset = 0 } = incoming?.paginationInfo || {};
            const isFirstUpdate = offset === 0;
            if (existing?.staff && !isFirstUpdate) {
              return {
                staff: [...existing.staff, ...incoming.staff],
                totalCount: incoming.totalCount,
                paginationInfo: incoming.paginationInfo
              };
            } else {
              return incoming;
            }
          },
          read: (existing) => existing
        }
      }
    }
  }
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (!ignoredErrors?.includes(operation.operationName)) {
    // Ignore login errors
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, extensions }) => {
        const code = extensions?.code as string;
        const statusCode = extensions?.statusCode as number;
        let errorMessage = '';
        if (statusCode && statusCode in STATUS_CODE_MESSAGE)
          errorMessage = STATUS_CODE_MESSAGE[statusCode];
        else
          errorMessage =
            code && code in ERROR_CODE_MAP ? ERROR_CODE_MAP[code] : message;
        showToast(errorMessage, false);
      });
    }
  }
  if (networkError) showToast(`[Network error]: ${networkError}`, false);
  graphQLErrors?.forEach((error) => {
    if (
      error.extensions?.statusCode === 401 &&
      !window.location.pathname.includes(pagePaths.login) &&
      !window.location.pathname.includes(pagePaths.consentForm) &&
      !window.location.pathname.includes(pagePaths.webLink)
    ) {
      window.location.href = pagePaths.logout;
    }
  });
});
const multiHttpLink = new MultiAPILink({
  endpoints: {
    clinicCloud: GRAPHQL_ENDPOINT || '',
    treatmentMonitor: TREATMENT_MONITOR_GRAPHQL_ENDPOINT || '',
    chatter: CHAT_API_ENDPOINT || ''
  },
  httpSuffix: '',
  defaultEndpoint: 'clinicCloud',
  createHttpLink: () =>
    new HttpLink({
      credentials: 'include',
      fetch
    })
});

export const useAppApolloClient = () => {
  return new ApolloClient({
    link: from([errorLink, authMiddleware, multiHttpLink]),
    cache
  });
};
