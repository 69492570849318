import React, { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { ToothChart2 } from './ToothChart2';

type Props = {
  name: string;
  disabled?: boolean;
  toothChartRef: React.RefObject<HTMLDivElement>;
};

export const RHFToothChart2: FC<Props> = ({
  name,
  disabled = false,
  toothChartRef
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <ToothChart2
          toothData={field?.value}
          setToothData={field?.onChange}
          disabled={disabled}
          toothChartRef={toothChartRef}
        />
      )}
    />
  );
};
