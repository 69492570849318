import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';

import { debounce } from 'lodash';

import { CloseIcon, Search } from 'assets/icons';
import If from 'components/Generics/If/If';
import { SearchInputProps } from 'types/autoSuggest.types';

type Props = {
  placeholder: string;
  onSearchChange: (debouncedText: string) => void;
  debounceTime?: number;
  inputText?: string;
  onFocusInput?: () => void;
};

const SearchInput: FC<Props & SearchInputProps> = ({
  placeholder,
  onSearchChange,
  debounceTime = 600,
  inputText = '',
  className = '',
  inputStyle = 'border-none flex-grow xxs:mx-2 md:mx-3 placeholder:debouncedText-sm placeholder:leading-6 md:debouncedText-lg py-3',
  containerStyle = 'flex bg-GRAY rounded-4xl items-center justify-between px-4 xxs:text-14px xxs:h-8 text-DEFAULT_TEXT md:h-12 md:text-17px',
  inputRef = null,
  hideCloseIcon = false,
  hideSearchIcon = false,
  searchIconClassName = 'text-DISABLED',
  onFocusInput,
  testId
}) => {
  const [text, setText] = useState('');

  useEffect(() => {
    inputText !== text && setText(inputText);
  }, [inputText]);

  const debouncedSearch = useCallback(
    debounce((text: string) => {
      onSearchChange(text);
    }, debounceTime),
    []
  );

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setText(value);
    debouncedSearch(value);
  };
  const clearText = () => {
    setText('');
    onSearchChange('');
  };

  useImperativeHandle(inputRef, () => ({
    clearInput() {
      setText('');
      onSearchChange('');
    },
    setInput(text: string) {
      setText(text);
    }
  }));

  return (
    <div className={`${containerStyle}`}>
      <If condition={!hideSearchIcon}>
        <Search className={searchIconClassName} />
      </If>
      <input
        id={testId}
        type='text'
        placeholder={placeholder}
        className={`${inputStyle} ${className} `}
        onChange={onChangeText}
        value={text}
        data-testid='search-bar'
        onFocus={onFocusInput}
      />
      <If condition={!!text?.length && !hideCloseIcon}>
        <CloseIcon
          onClick={clearText}
          className='cursor-pointer xxs:w-2 xxs:h-2 md:h-2.5 md:w-2.5'
          data-testid='close-icon'
        />
      </If>
    </div>
  );
};

export default SearchInput;
