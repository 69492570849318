import { FC, useEffect, useState, useCallback } from 'react';

import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil';

import { ProfilePicIcon } from 'assets/icons';
import {
  Button,
  ParticipantsList,
  TpvInfoCard,
  CloseTicketPopup,
  ReassignOwner,
  AddTeamMember
} from 'components';
import SidepanelCollapsible from 'components/SidePanelCollapsible/SidePanelCollapsible';
import {
  INBOX_CONTEXT_TYPE,
  INBOX_GROUP_NAME,
  TICKET_STATUS
} from 'constants/inbox';
import { TREATMENT_INFO_STATUS } from 'constants/options';
import {
  CHAT_TRACK_EVENTS,
  EXTERNAL_LINKS_TRACK_EVENT
} from 'constants/segment';
import { useGetActiveTreatement } from 'services/hooks/Inbox.hooks';
import {
  useSegmentTrackChat,
  useTrackData
} from 'services/hooks/Segment.hooks';
import { useGetTicketById } from 'services/hooks/Ticket.hooks';
import {
  loginedUserDetails,
  selectedTicket,
  selectedTicketActiveTreatement
} from 'store/atoms';
import { useIsExternalUser } from 'store/selectors/auth';
import { isDataExist, translate } from 'utils';
import {
  filterOutRoles,
  getParticipantCount,
  segmentTrackEvent
} from 'utils/common';
import { useSuperUserAccess } from 'utils/customHooks/useAccess';

const ParticipantDetailSection: FC = () => {
  const isExternalUser = useRecoilValue(useIsExternalUser);
  const loginedUser = useRecoilValue(loginedUserDetails);
  const { isSuperUser } = useSuperUserAccess();
  const [selectedTicketDetails, setSelectedTicketDetails] =
    useRecoilState(selectedTicket);
  const setSelectedActiveTreatement = useSetRecoilState(
    selectedTicketActiveTreatement
  );
  const resetActiveTreatment = useResetRecoilState(
    selectedTicketActiveTreatement
  );
  /**
   * @description
   * Function to get the text for the close reopen button
   */
  const [isOpen, setIsOpen] = useState(false);
  const [showReassignModel, setShowReassignModel] = useState(false);
  const handleSegmentTrack = useSegmentTrackChat();
  const { trackData, trackContextData, trackPatientData } = useTrackData();
  const [isAddParticipantPopupOpen, setIsAddParticipantPopupOpen] =
    useState(false);

  const getOwnerId = (): string => {
    const ownerIndex = selectedTicketDetails.ticketUsers.findIndex(
      (user) => user.groupName === INBOX_GROUP_NAME.CHAT_OWNER
    );
    return selectedTicketDetails.ticketUsers[ownerIndex]?.userId;
  };

  const [getSelectedDetailsByID] = useGetTicketById({
    onCompleted: (data) => {
      setSelectedTicketDetails(data.getTicketById);
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  const [getActiveTreatement, { data: activeTreatmentData }] =
    useGetActiveTreatement({
      fetchPolicy: 'no-cache',
      nextFetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true
    });

  const getSelectedTicketDetails = useCallback(() => {
    if (selectedTicketDetails.id) {
      getSelectedDetailsByID({
        variables: {
          ticketId: selectedTicketDetails.id,
          loginedUserId: loginedUser.id,
          viewTicketsWithoutMe: !selectedTicketDetails.isUserPartOfTicket
        }
      });
      const patientId = selectedTicketDetails.ticketTopicContextInfo?.find(
        (context) => context.contextType === INBOX_CONTEXT_TYPE.PATIENT
      )?.contextValueId;
      if (patientId) {
        getActiveTreatement({
          variables: {
            patientId
          }
        });
      }
    }
  }, [selectedTicketDetails.id]);

  useEffect(() => {
    if (selectedTicketDetails.id) {
      getSelectedTicketDetails();
    }
  }, [selectedTicketDetails.id]);

  useEffect(() => {
    if (
      activeTreatmentData &&
      isDataExist(activeTreatmentData?.getActiveTreatmentsForPatient)
    ) {
      setSelectedActiveTreatement(
        activeTreatmentData.getActiveTreatmentsForPatient
      );
    } else {
      resetActiveTreatment();
    }
  }, [activeTreatmentData]);

  const participantsList = selectedTicketDetails.ticketUsers || [];

  const handleAddClick = (event: any) => {
    event.stopPropagation();
    const isFromTicketDetails = true;
    setIsAddParticipantPopupOpen(true);
    const {
      zenchat_ID,
      zenchat_topic,
      zenchat_status,
      chat_created_ts,
      user_permission
    } = trackData;
    const {
      treatment_plan_id,
      treatment_plan_version_id,
      treatment_plan_version,
      treatment_plan_type
    } = trackContextData;
    handleSegmentTrack(CHAT_TRACK_EVENTS.PARTICIPANT_ADD_INITIATED, {
      zenchat_ID,
      zenchat_topic,
      zenchat_status,
      chat_created_ts,
      user_permission,
      treatment_plan_id,
      treatment_plan_version_id,
      treatment_plan_version,
      treatment_plan_type,
      ...trackPatientData,
      no_of_participants: getParticipantCount(participantsList),
      is_chat_created: isFromTicketDetails ? 'yes' : 'no'
    });
  };

  const ownerId = getOwnerId();

  const handleSegmentEvent = (linkType: string, viewType: string) => {
    const linkClicked = {
      user_id: loginedUser?.id,
      user_role: filterOutRoles(loginedUser?.groups),
      zenchat_ID: selectedTicketDetails?.id,
      zenchat_topic: selectedTicketDetails?.topicInfo?.topicName,
      link_type: linkType,
      view_type: viewType
    };
    segmentTrackEvent(
      EXTERNAL_LINKS_TRACK_EVENT.EXTERNAL_LINK_OPENED,
      linkClicked
    );
  };

  const handleLinkClick = () => {
    let SFlinkToOpen = selectedTicketDetails?.keyLinks?.salesforceLink;
    if (
      selectedTicketDetails?.treatmentInfo?.status ===
        TREATMENT_INFO_STATUS.TREATMENT_COMPLETE ||
      selectedTicketDetails?.treatmentInfo?.status ===
        TREATMENT_INFO_STATUS.TREATMENT_LOST
    ) {
      SFlinkToOpen =
        selectedTicketDetails?.keyLinks?.retainerSalesforceLink ||
        selectedTicketDetails?.keyLinks?.salesforceLink;
    }
    handleSegmentEvent(
      EXTERNAL_LINKS_TRACK_EVENT.SALESFORCE_CLICK,
      EXTERNAL_LINKS_TRACK_EVENT.INBOX
    );
    window.open(SFlinkToOpen);
  };
  // Commented as part of ZCC-2022
  // useEffect(() => {
  //   const tempTicketList = [...ticketsList];
  //   const updatedTicketIndex = tempTicketList.findIndex(
  //     (ticket) => ticket.id === Number(ticketDetails?.getTicketById.id)
  //   );
  //   if (
  //     updatedTicketIndex >= 0 &&
  //     ticketDetails?.getTicketById &&
  //     !tempTicketList[updatedTicketIndex]?.ticketChatSearchMessage?.[0].text
  //   ) {
  //     tempTicketList[updatedTicketIndex] = ticketDetails.getTicketById;
  //     setTicketsList(tempTicketList);
  //   }
  // }, [ticketDetails]);
  const showAddButton = selectedTicketDetails.isUserPartOfTicket;

  return (
    <section className='flex flex-col'>
      {!isExternalUser && (
        <>
          <SidepanelCollapsible
            title={translate('descriptionText.participants')}
            handleAddClick={handleAddClick}
            participantsList={participantsList}
            showAddButton={showAddButton}
          >
            <ParticipantsList
              hideHeader={true}
              participantsList={selectedTicketDetails.ticketUsers || []}
              isFromTicketDetails={true}
              showAddButton={showAddButton}
              setIsAddParticipantPopupOpen={setIsAddParticipantPopupOpen}
              isAddParticipantPopupOpen={isAddParticipantPopupOpen}
            />
            {selectedTicketDetails.isUserPartOfTicket && (
              <div className='flex w-full mt-2 px-4 gap-4'>
                {(ownerId === loginedUser.id || isSuperUser) &&
                  selectedTicketDetails.status !== TICKET_STATUS.CLOSE && (
                    <Button
                      className='bg-PRIMARY text-WHITE text-sm px-6 mt-2 w-full flex'
                      onClick={() => {
                        setShowReassignModel(true);
                      }}
                    >
                      <ProfilePicIcon className='w-6 h-6 mr-2' />
                      {translate('inbox.reassign_owner')}
                    </Button>
                  )}
              </div>
            )}
            <div>
              <TpvInfoCard
                keyLinks={selectedTicketDetails?.keyLinks}
                handleLinkClick={handleLinkClick}
                handleSegmentEvent={handleSegmentEvent}
              />
            </div>
          </SidepanelCollapsible>
        </>
      )}
      <CloseTicketPopup isOpen={isOpen} setIsOpen={setIsOpen} />
      <ReassignOwner
        isOpen={showReassignModel}
        setIsOpen={setShowReassignModel}
        ticketId={selectedTicketDetails.id}
        participants={selectedTicketDetails.ticketUsers}
      />
      {isAddParticipantPopupOpen && (
        <AddTeamMember
          participantsList={participantsList}
          isAddParticipantPopupOpen={isAddParticipantPopupOpen}
          isFromTicketDetails={true}
          onClose={() => {
            setIsAddParticipantPopupOpen(false);
          }}
        />
      )}
    </section>
  );
};

export default ParticipantDetailSection;
