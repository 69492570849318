import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

import { isEqual } from 'lodash';

/**
 * @description
 * Should use only with Object as dependency
 * @param effect
 * @param deps
 * @param depsEqual
 */
const useCustomCompareEffect = <TDeps extends DependencyList>(
  effect: EffectCallback,
  deps: TDeps
) => {
  const ref = useRef<TDeps | undefined>(undefined);

  if (!ref.current || !isEqual(deps, ref.current)) {
    ref.current = deps;
  }

  useEffect(effect, ref.current);
};

export default useCustomCompareEffect;
