import { useCookies } from 'react-cookie';

const LOGGED_IN_KEY = 'isLoggedIn';
const CHAT_TOKEN_NAME = 'chatToken';
const CHAT_AUTH = 'isChatAuthenticated';

export const useIsLoggedIn = () => {
  const [cookies, setCookie, removeCookie] = useCookies([LOGGED_IN_KEY]);

  const setIsLoggedIn = (isLoggedIn: boolean) =>
    setCookie(LOGGED_IN_KEY, isLoggedIn, { path: '/' });

  const removeIsLoggedIn = () => {
    removeCookie(LOGGED_IN_KEY, { path: '/' });
  };

  return [cookies[LOGGED_IN_KEY], setIsLoggedIn, removeIsLoggedIn];
};

export const chatAccessToken = () => {
  const getChatAccessToken = (): string | null => {
    return localStorage.getItem(CHAT_TOKEN_NAME);
  };

  return { getChatAccessToken };
};

export const useIsChatAuthenticated = () => {
  const [cookies, setCookie, removeCookie] = useCookies([CHAT_AUTH]);

  const setIsChatAuthenticated = (isAuthenticated: boolean) =>
    setCookie(CHAT_AUTH, isAuthenticated, { path: '/' });

  const removeIsChatAuthenticated = () => {
    removeCookie(CHAT_AUTH, { path: '/' });
  };

  return {
    isChatAuthenticated: cookies[CHAT_AUTH],
    setIsChatAuthenticated,
    removeIsChatAuthenticated
  };
};
