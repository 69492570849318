import { FC, ReactNode } from 'react';

import { ErrorIcon } from 'assets/icons';
import Button from 'components/Button/Button';
import ResponsiveModal from 'components/Modals/ResponsiveModal/ResponsiveModal';
import { translate } from 'utils';

export type ConfirmationModalProps = {
  title?: string;
  message?: string;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
  disableConfirmButton?: boolean;
  children: ReactNode;
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  title,
  message,
  onCancel,
  onConfirm,
  open,
  disableConfirmButton = false,
  children
}) => {
  return (
    <ResponsiveModal isOpen={open} onClose={onCancel} title={title} showHeader>
      <div>
        <div className='mb-6 text-sm'>
          <div className='flex'>
            <div>
              <ErrorIcon className='fill-current text-ERROR w-4 h-4 mr-2 mt-1' />
            </div>
            <p className='text-ONYX'>{message}</p>
          </div>
          {children}
        </div>
        <div className='flex justify-end md:flex-row flex-col-reverse text-sm leading-6 mt-4'>
          <Button
            className='text-DEFAULT_TEXT text-center md:mr-6 mr-0'
            type='button'
            onClick={onCancel}
          >
            {translate('cancel.cancel')}
          </Button>
          <Button
            className='bg-PRIMARY text-white text-center py-3 px-4 md:w-auto w-full md:mb-0  text-sm mb-2'
            type='button'
            onClick={onConfirm}
            disabled={disableConfirmButton}
          >
            {translate('confirm.confirm')}
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );
};

export default ConfirmationModal;
