import { TREATMENT_LIST_SORT_OPTIONS } from 'constants/options';
import { TPV_TYPE_OPTIONS, TYPES } from 'constants/TPVFormOptions';
import {
  AfterSalesAppointmentDetails,
  TreatmentByCpIdDetails
} from 'types/AfterSalesAppointment.types';
import { MapToObj, Treatment } from 'types/Inbox.types';
import { TreatmentPlanType, VersionDetails } from 'types/PatientList.types';
import { SelectedSearchedTicket } from 'types/select';
import { Ticket } from 'types/Tickets.types';

export enum ATOMS {
  ZENCHAT_VISIBILITY = 'ZENCHAT_VISIBILITY',
  HIDE_LIVE_TRANSLATION = 'HIDE_LIVE_TRANSLATION',
  REFINEMENT_TEMPLATIZED_PRESCRIPTION = 'REFINEMENT_TEMPLATIZED_PRESCRIPTION',
  TEMPLATIZED_PRESCRIPTION = 'TEMPLATIZED_PRESCRIPTION',
  SELECTED_TICKET = 'SELECTED_TICKET',
  PATIENT_LIST_SORT = 'PATIENT_LIST_SORT',
  TICKET_LIST_FILTER_ATOM = 'TICKET_LIST_FILTER_ATOM',
  INBOX_FILTER_ATOM = 'INBOX_FILTER_ATOM',
  INBOX_TICKET_LIST_LOADED = 'INBOX_TICKET_LIST_LOADED',
  PATIENT_LIST_FILTER_ATOM = 'PATIENT_LIST_FILTER_ATOM',
  GIVE_INSTRUCTIONS_LIST_FILTER_ATOM = 'GIVE_INSTRUCTIONS_LIST_FILTER_ATOM',
  SUCCESS_ERROR_ALERT_ATOM = 'SUCCESS_ERROR_ALERT_ATOM',
  CLINIC_SELECTOR_ATOM = 'CLINIC_SELECTOR_ATOM',
  PATIENT_LIST_SELECTED_TAB = 'PATIENT_LIST_SELECTED_TAB',
  SELECTED_SEARCHED_MESSAGE = 'SELECTED_SEARCHED_MESSAGE',
  IS_WARNING_CLOSED = 'IS_WARNING_CLOSED',
  SELECTED_TICKET_ACTIVE_TREATMENT = 'SELECTED_TICKET_ACTIVE_TREATMENT',
  LOGINED_USER_DETAILS_ATOM = 'LOGINED_USER_DETAILS_ATOM',
  UNREAD_NOTIFICATION = 'UNREAD_NOTIFICATION',
  PATIENT_LIST_SEARH_TEXT_ATOM = 'PATIENT_LIST_SEARH_TEXT_ATOM',
  GIVE_INSTRUCTIONS_LIST_SEARCH_TEXT_ATOM = 'GIVE_INSTRUCTIONS_LIST_SEARCH_TEXT_ATOM',
  SELECTED_CHAT_ACCORDION = 'SELECTED_CHAT_ACCORDION',
  CHECK_FIREBASE_CONNECTION = 'CHECK_FIREBASE_CONNECTION',
  UNAUTHORIZED_ACCESS_TO_CHATSERVICE = 'UNAUTHORIZED_ACCESS_TO_CHATSERVICE',
  TICKET_LIST = 'TICKET_LIST',
  SHOW_CHAT = 'SHOW_CHAT',
  SHOW_NEW_CHAT = 'SHOW_NEW_CHAT',
  SHOW_MOBILE_VIEW_INBOX = 'SHOW_MOBILE_VIEW_INBOX',
  UNREAD_NOTIFICATION_COUNT = 'UNREAD_NOTIFICATION_COUNT',
  APPOINTMENT_LIST_FILTER_ATOM = 'APPOINTMENT_LIST_FILTER_ATOM',
  APPOINTMENT_LIST_SEARCH_TEXT_ATOM = 'APPOINTMENT_LIST_SEARCH_TEXT_ATOM',
  TREATMENT_APPOINTMENT = 'TREATMENT_APPOINTMENT',
  AUTO_SAVE_STATUS = 'AUTO_SAVE_STATUS',
  SELECTED_CLINIC = 'SELECTED_CLINIC',
  SELECTED_DOCTORS_CLINIC_ID = 'SELECTED_DOCTORS_CLINIC_ID',
  SHOW_CLINIC_SELECTOR = 'SHOW_CLINIC_SELECTOR',
  SHOW_ADD_ME = 'SHOW_ADD_ME',
  SHOW_NEW_CHAT_DESKTOP = 'SHOW_NEW_CHAT_DESKTOP',
  AFTER_SALE_APPOINTMENT_LIST_FILTER = 'AFTER_SALE_APPOINTMENT_LIST_FILTER',
  AFTER_SALE_APPOINTMENT_LIST_SEARCH_TEXT = 'AFTER_SALE_APPOINTMENT_LIST_SEARCH_TEXT',
  IS_EDIT_AFTERSALES_APPOINTMENT_OPEN = 'IS_EDIT_AFTERSALES_APPOINTMENT_OPEN',
  SHOW_MOBILE_VIEW_APPOINTMENTS = 'SHOW_MOBILE_VIEW_APPOINTMENTS',
  SHOW_MOBILE_VIEW_NOTES = 'SHOW_MOBILE_VIEW_NOTES',
  PATIENT_SIDEPANEL_PREFERENCE = 'SIDEPANEL_PREFERENCE',
  PATIENT_SIDEDRAWER_PREFERENCE = 'SIDEDRAWER_PREFERENCE',
  INBOX_RIGHT_SIDEPANEL_PREFERENCE = 'INBOX_RIGHT_SIDEPANEL_PREFERENCE',
  AFTER_SALES_APPOINTMENT_SIDEPANEL_FILTERS = 'AFTER_SALES_APPOINTMENT_SIDEPANEL_FILTERS',
  SELECTED_AFTER_SALES_APPOINTMENT = 'SELECTED_AFTER_SALES_APPOINTMENT',
  CACHED_ROUTE = 'INITIAL_ROUTE',
  SELECTED_AFTER_TREATMENT_BY_CP_ID = 'SELECTED_AFTER_TREATMENT_BY_CP_ID',
  APPOINTMENT_CLINICS = 'APPOINTMENT_CLINICS',
  TRANSLATION_ATOM = 'TRANSLATION_ATOM'
}

export const defaultFilterValues = {
  type: [],
  referral: [],
  status: [],
  retainerStatus: [],
  whiteningStatus: [],
  branches: [],
  doctor: [],
  treatmentType: [],
  country: [],
  council: undefined,
  sort: TREATMENT_LIST_SORT_OPTIONS[0]
};

export const defaultPatientListFilters = {
  ...defaultFilterValues
};

export const patientListSearchDefaultValue = undefined;

export const defaultGiveIntructionsListFilters = {
  type: [],
  status: [],
  branch: [],
  doctor: [],
  treatmentType: [],
  country: [],
  council: undefined
};
const ALL_PATIENTS_TAB_OPTION = {
  id: 'ALL_ALIGNERS'
};

export const defaultAppointmentListFilters = {
  status: [],
  actionRequired: [],
  clinics: [],
  callWithCS: []
};

export const defaultPatientListSelectedTab = ALL_PATIENTS_TAB_OPTION?.id;

export const defaultPatientListSort = { sortBy: '', sortOrder: '' };

export const defaultAlertValue = { success: false, message: '' };

export const defaultTicketListFilters = [
  {
    value: '',
    label: 'All'
  }
];
export const defaultInboxFilters = {
  createdByUsers: [],
  ownedBy: [],
  urgency: [],
  readStatus: [],
  status: [],
  hasMentionedMe: [],
  country: [],
  snoozeType: [],
  searchTerm: '',
  clinicId: [],
  doctorId: [],
  topicId: [],
  isReferral: []
};
export const defaultSelectedSearchedTicket: SelectedSearchedTicket = {
  messageId: '',
  ticketId: 0,
  text: ''
};
export const defaultSelectedTicketTreatment: Treatment[] = [
  {
    id: '',
    stage: '',
    type: '',
    patientId: '',
    status: '',
    treatmentPlan: [],
    details: {
      opportunityId: '',
      hasRetainer: false
    },
    clinic: {
      country: '',
      id: ''
    },
    patient: {
      country: ''
    }
  }
];

export const defaultUnreadNotification: MapToObj = {};

export const defaultSelectedTicket: Ticket = {
  id: 0,
  isUrgent: false,
  isRead: false,
  topicInfo: {
    topicId: '',
    topicName: '',
    topicCode: ''
  },
  status: 'OPEN',
  unReadMessageCount: 0,
  createdAt: '',
  isMentionedMessageRead: false,
  mentionedMessageId: null,
  updatedAt: '',
  ticketChatSearchMessage: [],
  ticketUsers: [],
  ticketTopicContextInfo: [],
  keyLinks: {
    salesforceLink: '',
    adminPortalLink: '',
    retainerSalesforceLink: '',
    isTreatmentActive: false
  },
  treatmentInfo: {
    status: '',
    opportunityId: '',
    type: '',
    patientId: '',
    treatmentPlan: [],
    clinic: {
      name: ''
    },
    currentTreatmentKey: '',
    medicalStaff: {
      doctors: [
        {
          role: '',
          primary: false,
          details: {
            firstName: '',
            lastName: ''
          }
        }
      ]
    }
  }
};

export const defaultLoginedDetails = {
  id: '',
  email: '',
  phone: '',
  mobile: '',
  groups: [],
  language: '',
  lastName: '',
  username: '',
  firstName: '',
  salutation: '',
  headClinicId: '',
  permissions: [],
  profilePicture: '',
  country: '',
  headClinics: [],
  details: {
    maxComplexity: null
  },
  available: false
};

export const defaultTreatmentAppointment = {
  id: '',
  clinicId: '',
  treatmentId: '',
  appointmentId: '',
  statusInfo: { statusCode: '' },
  patientTreatmentInfo: undefined,
  bookingStartTime: '',
  bookingEndTime: '',
  callWithCSComplete: false,
  patientPreferredSlots: [],
  internalNotes: undefined,
  clinicPreferredSlots: []
};

const defaultTimings = {
  start: { label: '9:00 AM', value: '9:00' },
  end: { label: '6:00 PM', value: '18:00' }
};

export const ClinicAvailabilityDefaultValues = {
  SUNDAY: {
    hours: [defaultTimings],
    closed: false
  },
  MONDAY: {
    hours: [defaultTimings],
    closed: false
  },
  TUESDAY: {
    hours: [defaultTimings],
    closed: false
  },
  WEDNESDAY: {
    hours: [defaultTimings],
    closed: false
  },
  THURSDAY: {
    hours: [defaultTimings],
    closed: false
  },
  FRIDAY: {
    hours: [defaultTimings],
    closed: false
  },
  SATURDAY: {
    hours: [defaultTimings],
    closed: false
  }
};

export const WorkingHoursDefaultValues = {
  SUNDAY: {
    hours: [defaultTimings],
    workingFullTime: false,
    notWorking: false
  },
  MONDAY: {
    hours: [defaultTimings],
    workingFullTime: false,
    notWorking: false
  },
  TUESDAY: {
    hours: [defaultTimings],
    workingFullTime: false,
    notWorking: false
  },
  WEDNESDAY: {
    hours: [defaultTimings],
    workingFullTime: false,
    notWorking: false
  },
  THURSDAY: {
    hours: [defaultTimings],
    workingFullTime: false,
    notWorking: false
  },
  FRIDAY: {
    hours: [defaultTimings],
    workingFullTime: false,
    notWorking: false
  },
  SATURDAY: {
    hours: [defaultTimings],
    workingFullTime: false,
    notWorking: false
  }
};

export const defaultAppointmentSettingsFilters = {
  clinic: { value: '', label: '' },
  country: { value: '', label: '' },
  doctor: { value: '', label: '' }
};

export const defaultAfterSaleAppointmentListFilters = {
  country: [],
  status: [
    {
      value: 'TO_BE_SCHEDULED',
      label: 'after_sales_appointment_status.to_be_scheduled'
    },
    { value: 'BOOKED', label: 'calendar.booked' },
    {
      value: 'CONFIRMED',
      label: 'after_sales_appointment_status.confirmed'
    },
    { value: 'RESCHEDULED', label: 'calendar.rescheduled' },
    {
      value: 'SHOW_UP',
      label: 'after_sales_appointment_status.show_up'
    },
    { value: 'NO_SHOW', label: 'calendar.no_show' },
    { value: 'CANCELLED', label: 'calendar.cancelled' }
  ],
  doctors: [],
  appointmentType: [],
  appointmentState: [
    { value: 'enable', label: 'aftersales_appointment.enabled' }
  ],
  actionRequired: [
    {
      value: 'SCHEDULE_APPOINTMENT',
      label: 'aftersales_appointment.schedule_appointment'
    },
    {
      value: 'SCHEDULE_APPOINTMENT_SUBMITTED',
      label: 'after_sales_appointment_actions.schedule_appointment_availability'
    },
    {
      value: 'RESCHEDULE_APPOINTMENT',
      label: 'after_sales_appointment_actions.reschedule_appointment'
    },
    {
      value: 'REMIND_PATIENT_AND_CONFIRM_APPOINTMENT',
      label: 'after_sales_appointment_actions.remind_patient'
    },
    {
      value: 'CHECK_APPOINTMENT_ATTENDANCE',
      label: 'aftersales_appointment.check_appointment'
    }
  ],
  clinics: []
};

export const defaultAfterSalesAppointmentSidePanelFilters = {
  timePeriod: [{ label: 'filters.all', value: '' }],
  treatment: [{ label: 'filters.all', value: '' }]
};
export const defaultClinicBranchListFilters = {
  country: []
};
export const defaultSelectedAfterSalesAppointment: AfterSalesAppointmentDetails =
  {
    country: '',
    patientName: '',
    appointmentId: '',
    appointmentType: '',
    appointmentStatus: '',
    doctorClinic: '',
    doctorName: '',
    actualAppointmentDate: '',
    appointmentStatusLabel: '',
    startTime: '',
    treatmentId: '',
    endTime: '',
    enabled: false,
    createdReason: '',
    defaultDuration: -1,
    advanceNoticePeriod: 0,
    expectedAppointmentDate: '',
    comments: [],
    doctorSfId: '',
    appointmentAction: '',
    clinicSfId: '',
    patient_preferred_slots_date_time: [],
    appointmentOrder: '',
    alignerSetNo: 0,
    opportunityId: '',
    opportunityType: '',
    rescheduledReason: '',
    appointment_outcome: '',
    treatmentType: ''
  };
export const defaultSelectedTreatmentByCpId: TreatmentByCpIdDetails = {
  id: '',
  patientId: '',
  patient: { email: '', phone: '', primaryCountry: '' },
  doctorTimings: [{ availableHours: [{ startTime: '', endTime: '' }], day: '' }]
};

export const createTpvPopupDefaultValues = (
  resim: boolean,
  selectedTreatment?: TreatmentPlanType,
  selectedVersion?: VersionDetails
) => {
  return {
    treatmentType: resim
      ? {
          label: TYPES?.[selectedVersion?.type as string],
          value: selectedVersion?.type
        }
      : TPV_TYPE_OPTIONS[0],
    zenClearVersion: resim
      ? { label: selectedVersion?.label, value: selectedVersion?.id }
      : { label: '', value: '' },
    isResim: resim,
    resimInstructions: ''
  };
};
