import { FC, InputHTMLAttributes } from 'react';

import './style.css';

const ToggleCheckbox: FC<InputHTMLAttributes<HTMLInputElement>> = ({
  ...props
}) => {
  return (
    <input {...props} className='w-8 h-4 toggle-checkbox' type='checkbox' />
  );
};

export default ToggleCheckbox;
