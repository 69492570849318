import { Day, FilterIcon, Month, Schedule, Week } from 'assets/icons';
import { AppointmentOrder } from 'types/AfterSalesAppointment.types';
import { CalendarStatus } from 'types/Calendar.types';
import { Option } from 'types/select';
import { ZENCLEAR, ZENPLUS } from 'types/TPVForm.types';
const TREATMENT_LIST_SORT_OPTIONS = [
  {
    label: 'a_-_z.a_-_z',
    value: 'ASC'
  },
  { label: 'z_-_a.z_-_a', value: 'DESC' }
];

export const SEARCH_ENABLE_MIN_COUNT = 7;

const VIEWS = {
  FILTERS: 'filter',
  SEARCH: 'search',
  EVENT_DETAILS: 'event',
  EVENT_CREATE: 'create'
};
export const TREATMENT_PLAN_DETAILS_VIEW = [
  {
    id: 'TREATMENT_PLAN_DETAILS',
    label: 'treatment_plan_details_view.treatment_plan_details'
  },
  {
    id: 'PRESCRIPTION_SUMMARY',
    label: 'treatment_plan_details_view.prescription_summary'
  }
];

const CALENDAR_VIEWS = {
  RESOURCE_DAY: 'resourceTimeGridDay',
  DAY: 'timeGridDay',
  WEEK: 'timeGridWeek',
  MONTH: 'dayGridMonth',
  MONTH_MOBILE: 'monthViewMobile',
  SCHEDULE: 'scheduleView'
};

const CALENDAR_VIEW_OPTIONS = [
  {
    id: CALENDAR_VIEWS.DAY,
    label: 'day.day'
  },
  {
    id: CALENDAR_VIEWS.WEEK,
    label: 'week.week'
  },
  {
    id: CALENDAR_VIEWS.MONTH,
    label: 'month.month'
  }
];

const CALENDAR_VIEW_OPTIONS_APPOINTMENT = [
  {
    id: CALENDAR_VIEWS.RESOURCE_DAY,
    label: 'day.day'
  },
  {
    id: CALENDAR_VIEWS.WEEK,
    label: 'week.week'
  }
];

const CALENDAR_VIEW_OPTIONS_MOBILE = [
  {
    value: CALENDAR_VIEWS.SCHEDULE,
    label: 'schedule.schedule',
    icon: <Schedule />,
    divider: true
  },
  {
    value: CALENDAR_VIEWS.DAY,
    label: 'day.day',
    icon: <Day />,
    divider: true
  },
  {
    value: CALENDAR_VIEWS.WEEK,
    label: 'week.week',
    icon: <Week />,
    divider: true
  },
  {
    value: CALENDAR_VIEWS.MONTH_MOBILE,
    label: 'month.month',
    icon: <Month />,
    divider: true
  },
  {
    value: VIEWS.FILTERS,
    label: 'filters.filters',
    icon: <FilterIcon />
  }
];

const RESCHEDULE_REASON = [
  {
    label: 'rescheduled_reason.unconfirmed',
    value: 'UNCONFIRMED'
  },
  {
    label: 'rescheduled_reason.no_show',
    value: 'NO_SHOW'
  },
  {
    label: 'rescheduled_reason.not_applicable',
    value: 'NA'
  },
  {
    label: 'rescheduled_reason.doctor_leave',
    value: 'DOCTOR_ON_LEAVE'
  }
];

const APPOINTMENT_TIME_OPTIONS = [...new Array(24)].map((item, i) => {
  const value = (i + 1) * 5;
  return { label: `${value} mins`, value: value.toString() };
});

const APPT_ORDER_BEFORE = {
  name: 'before',
  label: 'sidepanel_appointmentlist.before',
  value: AppointmentOrder.BEFORE_ALIGNER
};
const APPT_ORDER_AFTER = {
  name: 'after',
  label: 'sidepanel_appointmentlist.after',
  value: AppointmentOrder.AFTER_ALIGNER
};
const EVENT_TIME_OPTIONS = [APPT_ORDER_BEFORE, APPT_ORDER_AFTER];

enum EVENT_DETAILS_ACTIONS {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  SHOWED_UP = 'SHOW_UP',
  NO_SHOW = 'NO_SHOW',
  RESCHEDULE = 'RESCHEDULED',
  SCHEDULED = 'BOOKED',
  CANCELLED = 'CANCELLED',
  CLOSED_LOST = 'CLOSED_LOST',
  TO_BE_SCHEDULED = 'TO_BE_SCHEDULED',
  CONFIRMED = 'CONFIRMED'
}

enum TREATMENT_PLAN_STATUSES {
  REJECTED = 'REJECTED',
  CUSTOMER_ACCEPTED = 'CUSTOMER_ACCEPTED'
}

enum TREATMENT_PLAN_VERSION_ACTIONS {
  APPROVE_EXT_DOC = 'PENDING_PATIENT_REVIEW',
  APPROVE_INT_DOC = 'PENDING_DOCTOR_REVIEW',
  SUBMIT_ENQUIRY = 'DISCUSSION_REQUIRED',
  REUEST_NEW_VERSION = 'REQUEST_NEW_VERSION',
  REJECT_TREATMENT_PLAN = 'REJECT_TREATMENT_PLAN',
  REJECTED = 'REJECTED',
  PENDING_DOCTOR_REVIEW = 'PENDING_DOCTOR_REVIEW',
  CASE_WITH_INT_DOC = 'CASE_WITH_INT_DOC'
}

enum TREATMENT_PLAN_VERSION_STATUS {
  PENDING_DOCTOR_REVIEW = 'PENDING_DOCTOR_REVIEW',
  PENDING_PATIENT_REVIEW = 'PENDING_PATIENT_REVIEW',
  PATIENT_ACCEPTED = 'PATIENT_ACCEPTED',
  PENDING_ZENYUM_COUNCIL_REVIEW = 'PENDING_ZENYUM_COUNCIL_REVIEW',
  DISCUSSION_REQUIRED = 'DISCUSSION_REQUIRED',
  IN_PLANNING = 'IN_PLANNING',
  DORMANT = 'DORMANT',
  REJECTED = 'REJECTED',
  CASE_IN_PLANNING = 'CASE_IN_PLANNING',
  CASE_WITH_ZENYUM = 'CASE_WITH_ZENYUM',
  CASE_OPS_TPV_CHECK = 'CASE_OPS_TPV_CHECK',
  CASE_WITH_INT_DOC = 'CASE_WITH_INT_DOC',
  INT_DOC_TPV_CHECK = 'INT_DOC_TPV_CHECK',
  DRAFT = 'DRAFT'
}

enum TREATMENT_INFO_STATUS {
  TREATMENT_COMPLETE = 'status.treatment_complete',
  TREATMENT_LOST = 'status.treatment_lost'
}
const UPLOADIMAGE_SOURCE = {
  EXISTING: 'existingPhoto',
  COMPUTER: 'fromComputer'
};

const CALENDAR_ACTION_BTNS_STATUS: CalendarStatus[] = [
  EVENT_DETAILS_ACTIONS.CONFIRMED,
  EVENT_DETAILS_ACTIONS.SCHEDULED
];

export {
  CALENDAR_VIEW_OPTIONS,
  CALENDAR_VIEW_OPTIONS_APPOINTMENT,
  CALENDAR_VIEW_OPTIONS_MOBILE,
  CALENDAR_VIEWS,
  APPT_ORDER_AFTER,
  EVENT_DETAILS_ACTIONS,
  RESCHEDULE_REASON,
  VIEWS,
  TREATMENT_PLAN_STATUSES,
  TREATMENT_PLAN_VERSION_ACTIONS,
  TREATMENT_PLAN_VERSION_STATUS,
  TREATMENT_LIST_SORT_OPTIONS,
  TREATMENT_INFO_STATUS,
  UPLOADIMAGE_SOURCE,
  APPOINTMENT_TIME_OPTIONS,
  EVENT_TIME_OPTIONS,
  CALENDAR_ACTION_BTNS_STATUS
};

export const ZENSTAGE_OPTIONS: Option[] = [
  {
    label: '1',
    value: '1'
  },
  {
    label: '2',
    value: '2'
  }
];
export const INT_DOC_REJECTION_REASON: Option[] = [
  {
    label: 'intDocRejectionReason.incorrect_gap_design',
    value: 'Lab Error - Incorrect gap design'
  },
  {
    label: 'intDocRejectionReason.incorrect_IPR_design',
    value: 'Lab Error - Incorrect IPR design'
  },
  {
    label: 'intDocRejectionReason.no_sequential_retraction',
    value: 'Lab Error - No sequential retraction'
  },
  {
    label: 'intDocRejectionReason.unacceptable_movements_unacceptable_outcomes',
    value: 'Lab Error - Unacceptable movements/unacceptable outcomes'
  },
  {
    label: 'intDocRejectionReason.instructions_not_followed',
    value: 'Lab Error - Instructions (from int doc) not followed'
  },
  {
    label: 'intDocRejectionReason.wrong_tooth_selection',
    value: 'Int Doc Instruction Error - wrong tooth selection'
  },
  {
    label: 'intDocRejectionReason.wrong_attachment_selection',
    value: 'Int Doc Instruction Error - wrong attachment selection'
  },
  {
    label: 'intDocRejectionReason.wrong_type_of_plan_selection',
    value:
      'Int Doc Instruction Error - wrong type of plan selection (alignment VS retraction)'
  }
];
export const TREATMENTPLANTYPEOPTIONS = [
  {
    label: 'prescriptions.zenyumclear',
    value: ZENCLEAR
  },
  {
    label: 'prescriptions.zenyumclear_plus',
    value: ZENPLUS
  }
];
export const extractionOptions: Option[] = [
  {
    label: 'approvalpopup.no.teeth.extraction',
    value: 'none'
  },
  {
    label: 'approvalpopup.teeth.extraction',
    value: 'required'
  }
];

export const CASEOPSISSUEOPTIONS = {
  NO_INSTRUCTIONS: 'No Instructions',
  NO_PHOTOS_X_RAYS: 'No Photos/X-rays',
  TRANSLATION_OF_INSTRUCTIONS: 'Translation of Instructions',
  SMARTEE_TPV_LINK_NOT_PRESENT: 'Smartee TPV Link not present'
};

export const TEETH_EXTRACTION_INSTRUCTIONS: Option[] = [
  {
    value: 'NO_TEETH_TO_EXTRACT',
    label: 'prescriptions.no_teeth_to_extract'
  },
  {
    value: 'TEETH_TO_EXTRACT',
    label: 'prescriptions.teeth_to_extract'
  }
];

export const DESIGN_LAB_OPTIONS: Option[] = [
  {
    value: 'EON',
    label: 'EON'
  },
  {
    value: 'Smartee',
    label: 'Smartee'
  }
];

export const SELECT_SEARCH_WIDTH = 240;

export const PRODUCTION_RECORD_STATUS = {
  IN_PRODUCTION: 'IN_PRODUCTION',
  TRIMMING: 'TRIMMING',
  READY_FOR_SHIPMENT: 'READY_FOR_SHIPMENT',
  SHIPPED: 'SHIPPED',
  RECEIVED: 'RECEIVED'
};

export const DELIVERY_RECORD_STATUS = {
  MM_ORDERING_SHIPMENT: 'MM_ORDERING_SHIPMENT',
  MM_PICKED_UP: 'MM_PICKED_UP',
  MM_RECEIVED: 'MM_RECEIVED',
  LM_ORDERING_SHIPMENT: 'LM_ORDERING_SHIPMENT',
  LM_PICKUP: 'LM_PICKUP',
  LM_RECEIVED: 'LM_RECEIVED'
};
export const DELIVERY_INFO_STATUS = {
  PRODUCTION: 'Production',
  WAREHOUSE: 'Warehouse',
  DISPATCHED: 'Dispatched',
  RECEIVED: 'Received'
};
