import {
  CSSProperties,
  ChangeEvent,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';

import { MenuPlacement } from 'react-select';
import { useRecoilValue } from 'recoil';

import { Select } from 'components';
import { FormError } from 'components/FormError/FormError';
import OutlinedInput from 'components/OutlinedInput/OutlinedInput';
import { loginedUserDetails } from 'store/atoms';
import { customTwMerge as twMerge } from 'utils';

export interface CountrySelectOption {
  value: string;
  name: string;
  label: string;
  code: string;
  icon: ReactNode;
}

export const CountrySelectList: CountrySelectOption[] = [
  {
    value: 'sg',
    name: 'Singapore',
    label: '65',
    code: '+65',
    icon: (
      <img width={24} src='https://www.geonames.org/flags/x/sg.gif' alt='sg' />
    )
  },
  {
    value: 'hk',
    name: 'Hong Kong (香港)',
    label: '852',
    code: '+852',
    icon: (
      <img width={24} src='https://www.geonames.org/flags/x/hk.gif' alt='sg' />
    )
  },
  {
    value: 'mo',
    name: 'Macao',
    label: '853',
    code: '+853',
    icon: (
      <img width={24} src='https://www.geonames.org/flags/x/mo.gif' alt='mo' />
    )
  },
  {
    value: 'my',
    name: 'Malaysia',
    label: '60',
    code: '+60',
    icon: (
      <img width={24} src='https://www.geonames.org/flags/x/my.gif' alt='my' />
    )
  },
  {
    value: 'id',
    name: 'Indonesia',
    label: '62',
    code: '+62',
    icon: (
      <img width={24} src='https://www.geonames.org/flags/x/id.gif' alt='id' />
    )
  },
  {
    value: 'jp',
    name: 'Japan',
    label: '81',
    code: '+81',
    icon: (
      <img width={24} src='https://www.geonames.org/flags/x/jp.gif' alt='jp' />
    )
  },
  {
    value: 'kr',
    name: 'Korea, Republic of South Korea',
    label: '82',
    code: '+82',
    icon: (
      <img width={24} src='https://www.geonames.org/flags/x/kr.gif' alt='kr' />
    )
  },
  {
    value: 'tw',
    name: 'Taiwan (台灣)',
    label: '886',
    code: '+886',
    icon: (
      <img width={24} src='https://www.geonames.org/flags/x/tw.gif' alt='tw' />
    )
  },
  {
    value: 'th',
    name: 'Thailand (ไทย)',
    label: '66',
    code: '+66',
    icon: (
      <img width={24} src='https://www.geonames.org/flags/x/th.gif' alt='th' />
    )
  },
  {
    value: 'vn',
    name: 'Vietnam (Việt Nam)',
    label: '84',
    code: '+84',
    icon: (
      <img width={24} src='https://www.geonames.org/flags/x/vn.gif' alt='vn' />
    )
  }
];

interface Props {
  value?: string;
  onChange: (number: string) => void;
  placeHolder?: string;
  disabled?: boolean;
  errorMessage?: string;
  defaultCountryCode?: string;
  containerClassName?: string;
  labelClassName?: string;
  textInputclassName?: string;
  selectheight?: string;
  selectControlStyles?: CSSProperties;
  selectMenuPlacement?: MenuPlacement;
  menuListMaxHeight?: string;
}

export const PhoneNumberInput: FC<Props> = ({
  onChange,
  value,
  placeHolder,
  disabled = false,
  errorMessage,
  defaultCountryCode,
  containerClassName,
  labelClassName,
  textInputclassName = '',
  selectheight = '',
  selectControlStyles,
  selectMenuPlacement,
  menuListMaxHeight
}) => {
  const userDetails = useRecoilValue(loginedUserDetails);
  const [phoneCountryCode = '', defaultNumber = ''] = value?.split(' ') || [
    '',
    ''
  ];

  const codeIndex = useMemo(() => {
    const code = defaultCountryCode
      ? defaultCountryCode?.toLowerCase()
      : userDetails?.country?.toLowerCase();
    const index = CountrySelectList?.findIndex(
      (countryCode: CountrySelectOption) => countryCode?.value === code
    );
    if (index < 0) return 0;
    return index;
  }, []);

  const [selectedOption, setSelectedOption] = useState<CountrySelectOption>(
    CountrySelectList[codeIndex]
  );
  const [number, setNumber] = useState(defaultNumber || '');

  useEffect(() => {
    setNumber(defaultNumber);
    setSelectedOption(
      CountrySelectList.find(({ code }) => code === phoneCountryCode) ||
        CountrySelectList[codeIndex]
    );
  }, [defaultNumber, setNumber, setSelectedOption, phoneCountryCode]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setNumber(e.target.value);
      onChange(`${selectedOption?.code} ${e.target.value}`);
    },
    [onChange, selectedOption?.code]
  );

  const onSelectCode = (selected: CountrySelectOption) => {
    setSelectedOption(selected);
    onChange(`${selected?.code} ${number}`);
  };

  return (
    <div className='w-full  items-center '>
      <div className='flex space-x-4'>
        <Select
          isDisabled={disabled}
          options={CountrySelectList}
          placeholder={
            <div
              className={twMerge(
                'flex items-center h-full pr-1 xxs:w-20',
                containerClassName
              )}
            >
              <div className='w-6 h-6 mr-2 flex items-center'>
                {selectedOption?.icon}
              </div>
              <div
                className={twMerge(
                  `w-6 h-6 flex items-center ${
                    disabled ? 'text-DISABLED' : 'text-DEFAULT_TEXT'
                  }`,
                  labelClassName
                )}
              >
                {selectedOption?.label}
              </div>
            </div>
          }
          onChange={onSelectCode}
          isMulti={false}
          isFormSelect={true}
          selectHeight={selectheight}
          controlStyles={selectControlStyles}
          menuPlacement={selectMenuPlacement}
          menuListMaxHeight={menuListMaxHeight}
        />
        <OutlinedInput
          value={number}
          onChange={handleChange}
          type='tel'
          disabled={disabled}
          placeholder={placeHolder}
          className={textInputclassName}
        />
      </div>
      <FormError errorMessage={errorMessage} />
    </div>
  );
};
