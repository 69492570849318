import { FC } from 'react';

import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import { AlignerInput } from 'components/AlignerInput';
import { FormLabel } from 'components/FormLabel/FormLabel';
import { translate } from 'utils';

type props = {
  name?: string;
  maxLimit?: number;
  minLimit?: number;
  disabled?: boolean;
  showZenstage: boolean;
  source?: string;
};
export const RhfAlignerInput: FC<props> = ({
  name,
  maxLimit,
  minLimit,
  disabled,
  showZenstage,
  source
}) => {
  const { control } = useFormContext();
  const FieldName = name ? `${name}.` : '';
  return (
    <div>
      <Controller
        control={control}
        name={`${FieldName}AlignerInput`}
        render={({ formState: { errors } }) => {
          const fieldError =
            _.get(errors, `${FieldName}radioUpper`) ||
            _.get(errors, `${FieldName}alignerInputUpperZenstage`) ||
            _.get(errors, `${FieldName}alignerInputUpperSet`) ||
            _.get(errors, `${FieldName}radioLower`) ||
            _.get(errors, `${FieldName}alignerInputLowerSet`) ||
            _.get(errors, `${FieldName}alignerInputLowerZenstage`);

          return (
            <>
              <FormLabel
                label={translate('2.What.aligner')}
                className='mb-10px text-sm'
                error={!!fieldError}
                name={`${FieldName}AlignerInput`}
              />
              <div>
                <AlignerInput
                  source={source}
                  showZenstage={showZenstage}
                  radioName={`${FieldName}radioUpper`}
                  zenStageInputName={`${FieldName}alignerInputUpperZenstage`}
                  setInputName={`${FieldName}alignerInputUpperSet`}
                  label={translate('Upper')}
                  className='pb-10px'
                  maxLimit={maxLimit}
                  minLimit={minLimit}
                  disabled={disabled}
                />
                <AlignerInput
                  source={source}
                  showZenstage={showZenstage}
                  radioName={`${FieldName}radioLower`}
                  setInputName={`${FieldName}alignerInputLowerSet`}
                  zenStageInputName={`${FieldName}alignerInputLowerZenstage`}
                  label={translate('refinementForm.Lower')}
                  maxLimit={maxLimit}
                  minLimit={minLimit}
                  disabled={disabled}
                />
              </div>
            </>
          );
        }}
      />
    </div>
  );
};
