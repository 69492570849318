import { FC, ReactNode, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { DateRangePicker } from 'components';
import { FormError } from 'components/FormError/FormError';
import { If } from 'components/Generics';
import { DateRange } from 'types/Appointment.types';
import { translate } from 'utils';
import { getDateRangeLabel } from 'utils/dateUtils';

interface RHFDateRangePickerProps {
  name: string;
  hideErrorMessage?: boolean;
  label?: string;
  inputProps?: {
    placeholder?: string | ReactNode;
    onChange?: (range: DateRange) => void;
    onClose?: () => void;
    onClear?: () => void;
    onSave?: () => void;
    onOpen?: () => void;
    showDropdownArrow?: boolean;
    buttonClass?: string;
    minDate?: Date;
    showYearSelection?: boolean;
  };
}

export const RHFDateRangePicker: FC<RHFDateRangePickerProps> = ({
  name,
  label,
  inputProps
}) => {
  const defaultDateRange: DateRange = {
    startDate: new Date(),
    endDate: new Date()
  };
  const [selectedDate, setSelectedDate] = useState<DateRange>(defaultDateRange);
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className='relative'>
            <If condition={!!label}>
              <span className='mb-2 text-xs md:text-sm'>{label}</span>
            </If>
            <DateRangePicker
              placeholder={
                getDateRangeLabel(field.value) ||
                translate('date_picker.select_date')
              }
              range={selectedDate}
              onChange={(dateRange: DateRange) => {
                setSelectedDate(dateRange);
              }}
              onClear={() => {
                setSelectedDate(defaultDateRange);
              }}
              onSave={() => field.onChange(selectedDate)}
              {...inputProps}
            />
            <FormError errorMessage={error?.message} />
          </div>
        );
      }}
      control={control}
    />
  );
};
