import { useRef } from 'react';

function useScrollToElement(options?: ScrollIntoViewOptions) {
  const ref = useRef<HTMLDivElement>(null);

  const scrollIntoView = () => {
    ref?.current?.scrollIntoView(options);
  };

  return { ref, scrollIntoView };
}

export default useScrollToElement;
