import { gql } from '@apollo/client';

export const GET_QUOTE_BY_CLINIC_ID = gql`
  query getQuoteByClinicId($clinicId: String, $endOfValidity: String) {
    getQuoteByClinicId(clinicId: $clinicId, endOfValidity: $endOfValidity) {
      id
      status
      summary
      statementUrl
      invoiceUrl
      invoiceKey
      country
    }
  }
`;
export const GET_SOA_ENABLED_CLINICS = gql`
  query {
    getSOAEnabledClinics {
      value: id
      label: name
      country
    }
  }
`;

export const GET_QUOTE_LINES_BY_QUOTE_ID = gql`
  query getQuoteLinesByQuoteId($quoteId: String, $filter: GetQuoteLineFilter) {
    getQuoteLinesByQuoteId(quoteId: $quoteId, filter: $filter) {
      edges {
        id
        quoteId
        patientId
        doctorId
        type
        eventDate
        commission
        data {
          eventId
          eventType
          alignerCount
          productType
        }
        patient {
          id
          email
          phone
          lastName
          firstName
        }
        doctor {
          lastName
          firstName
        }
      }
      hasNext
    }
  }
`;

export const UPDATE_QUOTE = gql`
  mutation updateQuote($id: String, $input: UpdateQuoteObjectInput) {
    updateQuote(id: $id, input: $input) {
      id
    }
  }
`;
export const REVERT_QUOTE = gql`
  mutation revertQuote($id: String) {
    revertQuote(id: $id) {
      id
    }
  }
`;

export const UPDATE_QUOTE_LINE = gql`
  mutation ($input: QuoteLineProposalInput) {
    createQuoteLineProposal(input: $input) {
      id
      quoteId
    }
  }
`;

export const FINANCE_FILE_UPLOAD = gql`
  mutation getInvoiceUploadUrl($id: String, $name: String) {
    getInvoiceUploadUrl(id: $id, name: $name) {
      presignedUrl
      key
    }
  }
`;

export const GET_CLINIC_COMMISSION = gql`
  query getClinicCommission($input: GetClinicCommissionInput) {
    getClinicCommission(input: $input) {
      productType
      commission
    }
  }
`;
