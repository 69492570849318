import { FC } from 'react';

interface Props {
  children: React.ReactNode;
}

const FilterPanelPopup: FC<Props> = ({ children }) => {
  return (
    <section className='fixed md:absolute top-[3.5rem] md:top-0 right-0 md:translate-x-full h-full w-[16.25rem] md:w-[16.25rem] rounded-l-lg md:rounded-r-2xl md:shadow-calendar overflow-y-scroll z-5 bg-white'>
      {children}
    </section>
  );
};

export default FilterPanelPopup;
