import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { FormError } from 'components/FormError/FormError';
import { If } from 'components/Generics';
import { TimeRangePicker } from 'components/TimePicker/TimeRangePicker/TimeRangePicker';

type RHFTimeRangePickerProps = {
  name: string;
  placeholderText?: string;
  textToBeDisaplyedWithValue?: string;
  hideErrorMessage?: boolean;
};

const RHFTimeRangePicker: FC<RHFTimeRangePickerProps> = ({
  name,
  placeholderText,
  textToBeDisaplyedWithValue,
  hideErrorMessage = false
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <div className='relative'>
            <TimeRangePicker
              value={field.value}
              onChange={field.onChange}
              placeholderText={placeholderText}
              textToBeDisaplyedWithValue={textToBeDisaplyedWithValue}
            />
            <If condition={!hideErrorMessage}>
              <div>
                <FormError
                  errorMessage={(error as any)?.['startTime']?.message}
                />
              </div>
            </If>
          </div>
        );
      }}
    />
  );
};

export default RHFTimeRangePicker;
