import { useLazyQuery } from '@apollo/client';

import {
  CHAT_BULK_TICKET,
  GET_TICKET,
  GET_TICKET_BY_ID,
  GET_TICKET_COUNT,
  GET_TICKET_LIST,
  GET_TICKETS,
  BULK_ASSIGN_TICKETS,
  GET_TPV_DETAILS,
  SNOOZE_TICKET,
  UNSNOOZE_TICKET,
  UPDATE_TICKET_READ_STATUS,
  UPDATE_TICKET_STATUS,
  UPDATE_TICKET_URGENCY,
  GENERATE_CHAT_SUMMARY,
  SAVE_CHAT_SUMMARY,
  GET_TICKET_SEARCH_ZENCHAT_LINK,
  LINK_TICKETS,
  UNLINK_TICKETS
} from 'services/query/inbox';
import {
  GetInboxTicketList,
  GetTicketById,
  GetTicketCountByStatus,
  GetTicketsList,
  GetTratementPlanVersion,
  GetTreatmentPlanVersions,
  SnoozeTicket,
  TicketById,
  UnsnoozeTicket,
  TicketData,
  UpdateTicketStatus,
  UpdateTicketUnreadStatus,
  UpdateTicketUrgency,
  GenerateChatSummary,
  ChatSummary,
  SaveChatSummary,
  GetSearchTicketListZenchatLinks,
  LinkZenchatTickets,
  UnlinkZenchatTickets
} from 'types/Tickets.types';

import { UseGraphQlMutation, UseGraphQlQuery } from './hooks';

const useGetTicketList: GetTicketsList = (config) =>
  UseGraphQlQuery(GET_TICKET_LIST, {
    ...config
  });
const useGetTicketCount: GetTicketCountByStatus = (config) =>
  UseGraphQlQuery(GET_TICKET_COUNT, {
    ...config
  });
const useGetInboxTicketList: GetInboxTicketList = (config) =>
  useLazyQuery(GET_TICKET_LIST, {
    ...config
  });

const useGetSearchTicketList: GetSearchTicketListZenchatLinks = (config) =>
  useLazyQuery(GET_TICKET_SEARCH_ZENCHAT_LINK, config);

const useGetTicketById: GetTicketById = (config) =>
  useLazyQuery<TicketById>(GET_TICKET_BY_ID, {
    ...config
  });

const useGenerateChatSummary: GenerateChatSummary = (config) =>
  useLazyQuery<ChatSummary>(GENERATE_CHAT_SUMMARY, {
    ...config
  });

const useGetTicket: GetTicketById = (config) =>
  useLazyQuery<TicketById>(GET_TICKET, {
    ...config
  });
const useGetTickets: GetInboxTicketList = (config) =>
  useLazyQuery<TicketData>(GET_TICKETS, {
    ...config
  });

const useGetTpvInfo: GetTratementPlanVersion = (config) =>
  useLazyQuery<GetTreatmentPlanVersions>(GET_TPV_DETAILS, config);

const useLinkTickets: LinkZenchatTickets = (config) =>
  UseGraphQlMutation(LINK_TICKETS, { ...config });

const useUnlinkTickets: UnlinkZenchatTickets = (config) =>
  UseGraphQlMutation(UNLINK_TICKETS, { ...config });

const useUpdateTicketStatus: UpdateTicketStatus = (config) =>
  UseGraphQlMutation(UPDATE_TICKET_STATUS, { ...config });

const useSaveChatSummary: SaveChatSummary = (config) =>
  UseGraphQlMutation(SAVE_CHAT_SUMMARY, config);

const useUpdateTicketUrgency: UpdateTicketUrgency = (config) =>
  UseGraphQlMutation(UPDATE_TICKET_URGENCY, config);

const useUpdateUnreadStatus: UpdateTicketUnreadStatus = (config) =>
  UseGraphQlMutation(UPDATE_TICKET_READ_STATUS, config);

const useSnoozeTicket: SnoozeTicket = (config) =>
  UseGraphQlMutation(SNOOZE_TICKET, {
    ...config
  });

const useUnsnoozeTicket: UnsnoozeTicket = (config) =>
  UseGraphQlMutation(UNSNOOZE_TICKET, {
    ...config
  });

const useChatBulkAdd: UpdateTicketStatus = (config) =>
  UseGraphQlMutation(CHAT_BULK_TICKET, {
    ...config
  });
const useBulkAssignTickets: UpdateTicketStatus = (config) =>
  UseGraphQlMutation(BULK_ASSIGN_TICKETS, {
    ...config
  });

export {
  useGetTicket,
  useGetTickets,
  useGetTpvInfo,
  useSnoozeTicket,
  useUnsnoozeTicket,
  useSaveChatSummary,
  useChatBulkAdd,
  useBulkAssignTickets,
  useGetTicketById,
  useGenerateChatSummary,
  useGetTicketList,
  useGetInboxTicketList,
  useGetSearchTicketList,
  useLinkTickets,
  useUnlinkTickets,
  useUpdateTicketStatus,
  useUpdateUnreadStatus,
  useUpdateTicketUrgency,
  useGetTicketCount
};
