import { FC, useState } from 'react';

import Button from 'components/Button/Button';
import TeamMemberAutoSuggest from 'containers/generics/TeamMemberAutoSuggest/TeamMemberAutoSuggest';
import { OWNER_TYPE, OwnerInfo, Staff } from 'types/Inbox.types';
import { translate } from 'utils';
import { getTeamName } from 'utils/common';

interface Props {
  onClose: () => void;
  handleOwnerReassign: (ownerFrom: OwnerInfo, ownerTo: OwnerInfo) => void;
  ownerFrom?: OwnerInfo;
  ownerTo?: OwnerInfo;
}
const BulkAssignDetailsModal: FC<Props> = ({
  onClose,
  handleOwnerReassign,
  ownerFrom: prevOwnerFrom,
  ownerTo: prevOwnerTo
}) => {
  const [ownerFrom, setOwnerFrom] = useState<OwnerInfo | undefined>(
    prevOwnerFrom
  );
  const [ownerTo, setOwnerTo] = useState<OwnerInfo | undefined>(prevOwnerTo);
  const ownerDisplayValue = (owner?: OwnerInfo) => {
    return `${owner?.userFirstName} ${owner?.userLastName} (${getTeamName(
      owner?.groups?.[0] || ''
    )})`;
  };

  const handleOwnerSearch = (userDetails: Staff | undefined, type: string) => {
    const newOwnerInfo: OwnerInfo = {
      userId: userDetails?.id || '',
      emailId: userDetails?.email || '',
      userFirstName: userDetails?.firstName || '',
      userLastName: userDetails?.lastName || '',
      groups: userDetails?.groups || []
    };
    if (type === OWNER_TYPE.OWNER_FROM) {
      setOwnerFrom(newOwnerInfo);
    } else if (type === OWNER_TYPE.OWNER_TO) {
      setOwnerTo(newOwnerInfo);
    }
  };

  const handleNextClick = () => {
    if (ownerFrom?.userId && ownerTo?.userId) {
      handleOwnerReassign(ownerFrom, ownerTo);
    }
  };
  return (
    <div className='p-8 w-703px'>
      <div className='text-'>{translate('chat.owned_by')}</div>
      <div className='pt-4 pb-8'>
        <TeamMemberAutoSuggest
          onSuggestionClick={(userDetails) =>
            handleOwnerSearch(userDetails, OWNER_TYPE.OWNER_FROM)
          }
          searchListWrapperClassName='max-h-72'
          containerStyle='rounded border-1 border-LIGHT_GRAY_2 bg-white p-4'
          hideSearchIcon={true}
          inputStyle='w-full placeholder:text-base placeholder:text-LIGHT_GRAY_4'
          placeholder={translate('chat.add_participant_here')}
          clearOnSelect={false}
          isBulkAssign={true}
          searchText={
            !ownerDisplayValue(ownerFrom).includes('undefined')
              ? ownerDisplayValue(ownerFrom)
              : ''
          }
        />
      </div>
      {translate('chat.reassign_to')}
      <div className='pt-4 pb-8 h-auto'>
        <TeamMemberAutoSuggest
          onSuggestionClick={(userDetails) =>
            handleOwnerSearch(userDetails, OWNER_TYPE.OWNER_TO)
          }
          searchListWrapperClassName='max-h-72'
          containerStyle='rounded border-1 border-LIGHT_GRAY_2 bg-white p-4'
          hideSearchIcon={true}
          inputStyle='w-full placeholder:text-base placeholder:text-LIGHT_GRAY_4'
          placeholder={translate('chat.add_participant_here')}
          clearOnSelect={false}
          isBulkAssign={true}
          searchText={
            !ownerDisplayValue(ownerTo).includes('undefined')
              ? ownerDisplayValue(ownerTo)
              : ''
          }
        />
      </div>
      <div className='flex justify-center'>
        <Button
          onClick={onClose}
          className='w-full mr-5 text-DEFAULT_TEXT bg-LIGHT_GRAY px-6 py-3 rounded-4xl'
        >
          {translate('cancel.cancel')}
        </Button>
        <Button
          disabled={
            ownerFrom?.userId === ownerTo?.userId ||
            !ownerFrom?.userId ||
            !ownerTo?.userId
          }
          className='w-full text-white bg-PRIMARY px-6 py-3 rounded-4xl'
          onClick={handleNextClick}
        >
          {translate('next_button.next')}
        </Button>
      </div>
    </div>
  );
};
export default BulkAssignDetailsModal;
