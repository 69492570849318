import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { useRecoilCallback, useSetRecoilState } from 'recoil';

import chatClient from 'chatSDK';
import { useAppApolloClient } from 'services';
import { useIsChatAuthenticated, useIsLoggedIn } from 'services/auth/authToken';
import { logOut } from 'services/hooks/Auth.hooks';
import { patientListFilters, patientListSearchText } from 'store/atoms';
import { globalSelectedClinic } from 'store/atoms/authAtom';
import { defaultFilterValues } from 'store/atoms/constants';
import { cachedRoute } from 'store/atoms/routeAtom';

/**
useAuthToken is in the same file
**/

export const useLogout = () => {
  const chat = chatClient.getChatClient();
  const [, , removeIsLoggedIn] = useIsLoggedIn();
  const { removeIsChatAuthenticated } = useIsChatAuthenticated();
  const apolloClient = useAppApolloClient();
  const [logOutApi] = logOut();
  const { shutdown } = useIntercom();
  const setCachedRoute = useSetRecoilState(cachedRoute);
  const navigate = useNavigate();

  const clearRecoilCache = useRecoilCallback(
    ({ reset, set }) =>
      () => {
        set(patientListFilters, defaultFilterValues);
        set(patientListSearchText, undefined);
        reset(globalSelectedClinic);
      },
    []
  );
  const onError = () => {
    removeIsLoggedIn();
  };

  const logout = async () => {
    setCachedRoute(undefined); // Clear cached route on logout
    clearRecoilCache();
    chat.disconnectFromSocket();
    await chat.logout();
    removeIsChatAuthenticated();
    navigate('/login');
    removeIsLoggedIn(); // clear login cookies
    await logOutApi({ onError: onError }); // calls logout api
    await apolloClient.clearStore(); // we remove all information in the store
    shutdown();
  };
  return logout;
};
