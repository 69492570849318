import { FC } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { AddMore } from 'assets/icons';
import { BulkAssignZenchat } from 'components/BulkAssignZenchat/BulkAssignZenchat';
import Button from 'components/Button/Button';
import { INBOX_ROLE } from 'constants/inbox';
import { PERMISSIONS } from 'constants/Permissions';
import { CHAT_TRACK_EVENTS, VIEW_TYPE } from 'constants/segment';
import { useSegmentTrackChat } from 'services/hooks/Segment.hooks';
import { loginedUserDetails } from 'store/atoms';
import { showNewChatDesktop } from 'store/atoms/inbox';
import { translate } from 'utils';
import { checkIsExternal, filterOutRoles } from 'utils/common';
import { useHasAccess } from 'utils/hasAccess';

const PanelHeader: FC = () => {
  const setShowNewChatDesktop = useSetRecoilState(showNewChatDesktop);
  const hasBulkReassignChatAccess = useHasAccess(PERMISSIONS.BULK_ASSIGN);
  const handleSegmentTrack = useSegmentTrackChat();
  const loginedUser = useRecoilValue(loginedUserDetails);

  const trackNewChat = () => {
    handleSegmentTrack(CHAT_TRACK_EVENTS.NEW_CHAT_INITIATED, {
      user_id: loginedUser?.id,
      user_type: checkIsExternal(loginedUser?.groups)
        ? INBOX_ROLE.EXTERNAL
        : INBOX_ROLE.INTERNAL,
      user_role: filterOutRoles(loginedUser?.groups),
      view_type: VIEW_TYPE.INBOX
    });
  };

  const openNewChat = () => {
    trackNewChat();
    setShowNewChatDesktop(true);
  };

  return (
    <section className='h-[4rem] flex justify-between items-center px-4 border-b-1 border-LINE_GRAY'>
      <h2 className='text-28px'> Inbox </h2>
      <div className='flex'>
        {hasBulkReassignChatAccess && (
          <Button className='py-2 px-3 bg-[#CCE8FF] mr-[0.375rem] text-10px'>
            <BulkAssignZenchat />
          </Button>
        )}
        <Button
          onClick={openNewChat}
          className='bg-PRIMARY text-white px-3 py-2 text-10px flex items-center'
        >
          <AddMore className='fill-current text-WHITE h-4 w-4 mr-1' />
          <p>{translate('actions.newChat2')}</p>
        </Button>
      </div>
    </section>
  );
};

export default PanelHeader;
