import { FC } from 'react';

import moment from 'moment';
import Calendar, { CalendarProps } from 'react-calendar';
import './ReactCalendar.css';

export const ReactCalendar: FC<CalendarProps> = (props) => {
  return (
    <Calendar
      {...props}
      calendarType='US'
      formatShortWeekday={(_, date) => moment(date).format('ddd')[0]}
    />
  );
};
