import { FC, ReactNode, useState } from 'react';

import cx from 'classnames';

import { AddIcon, CollapseIcon, ExpandIcon } from 'assets/icons';
import Button from 'components/Button/Button';
import { Participant } from 'types/Inbox.types';
import { isDataExist } from 'utils';

type Props = {
  title: string;
  children: ReactNode;
  showAddButton?: boolean;
  participantsList?: Participant[];
  handleAddClick?: (event: any) => void;
};

const SidepanelCollapsible: FC<Props> = ({
  title,
  children,
  showAddButton,
  participantsList,
  handleAddClick
}) => {
  const [showContent, setShowContent] = useState(true);

  const handleViewToggle = () => {
    setShowContent((prev) => !prev);
  };

  return (
    <>
      <button
        className={cx(
          'px-4 pt-4 w-full flex justify-between items-center rounded-lg md:bg-white md:rounded-none md:border-LINE-COLOR',
          {
            'pb-4 md:border-b-1': !showContent,
            'md:border-b-0': showContent
          }
        )}
        onClick={handleViewToggle}
      >
        <h3
          className={cx('text-sm', {
            'text-PRIMARY font-semibold': showContent
          })}
        >
          {title}
        </h3>
        <div className='flex items-center'>
          {isDataExist(participantsList) && showAddButton && (
            <Button
              className='bg-PRIMARY w-5 h-5 mr-4'
              onClick={handleAddClick}
            >
              <p className='flex justify-center items-center'>
                <AddIcon className='text-WHITE' />
              </p>
            </Button>
          )}
          {showContent ? (
            <CollapseIcon className={'w-4 h-4 text-PRIMARY'} />
          ) : (
            <ExpandIcon className={'w-4 h-4'} />
          )}
        </div>
      </button>
      {showContent && children}
    </>
  );
};

export default SidepanelCollapsible;
