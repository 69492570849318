import { FC, useEffect, useMemo, useState } from 'react';

import AutoSuggest from 'components/AutoSuggest/AutoSuggest';
import { useGetTreatingPatients } from 'services/hooks/UsersList.hooks';
import { AutoSuggestions, SearchInputProps } from 'types/autoSuggest.types';
import { translate } from 'utils';
import { getFullName } from 'utils/common';

const MIN_SEARCH_TEXT_LEN = 1;

type PatientsAutoSuggestProp = {
  onSuggestionClick: (userDetails: AutoSuggestions) => void;
  onSuggestionCleared?: () => void;
  inputClasseName?: string;
  inputStyle?: string;
  searchListClassName?: string;
  searchItemClassName?: string;
  containerStyle?: string;
  searchInput?: string;
  searchIconClassName?: string;
  placeholder?: string;
  searchInputProps?: SearchInputProps;
  searchListWrapperClassName?: string;
  filterTypes?: string[];
};

const PatientsAutoSuggest: FC<PatientsAutoSuggestProp> = ({
  onSuggestionClick,
  onSuggestionCleared,
  inputClasseName = '',
  searchListClassName = '',
  searchItemClassName = '',
  placeholder = translate(
    'search_patient_name_or_mobile_number.search_patient_name_or_mobile_number'
  ),
  inputStyle,
  containerStyle,
  searchInput = '',
  searchInputProps,
  searchIconClassName,
  searchListWrapperClassName = '',
  filterTypes
}) => {
  const [searchedItem, setSearchedItem] = useState('');
  const [isFetchingResults, setIsFetchingResults] = useState(false);
  const [getTreatingPatientsDetails, { data: TreatingPatientsDetails }] =
    useGetTreatingPatients({
      fetchPolicy: 'no-cache',
      onCompleted: () => {
        setIsFetchingResults(false);
      },
      onError: () => {
        setIsFetchingResults(false);
      }
    });
  const userData = useMemo(() => {
    return (
      TreatingPatientsDetails?.getTreatingPatients?.edges?.map((user) => ({
        name: getFullName({
          firstName: user?.firstName,
          lastName: user?.lastName
        }),
        ...user
      })) || []
    );
  }, [TreatingPatientsDetails?.getTreatingPatients]);

  const onSearchChange = (text: string) => {
    setSearchedItem(text);
    fetchSuggestions(text.trim());
  };

  const fetchSuggestions = (searchWord: string) => {
    setIsFetchingResults(true);
    getTreatingPatientsDetails?.({
      variables: {
        searchText: searchWord,
        paginationInfo: { sortBy: 'name', offset: 0, limit: 20 },
        types: filterTypes
      }
    });
  };

  useEffect(() => {
    if (searchInput) {
      fetchSuggestions(searchInput);
    }
  }, [searchInput]);

  return (
    <AutoSuggest
      suggestions={userData}
      onSearchChange={onSearchChange}
      onSuggestionClick={onSuggestionClick}
      minSearchTextlength={MIN_SEARCH_TEXT_LEN}
      onSearchCleared={onSuggestionCleared}
      searchText={searchInput}
      loading={isFetchingResults}
      placeholder={placeholder}
      debounceTime={600}
      searchInputProps={{
        ...searchInputProps,
        className: inputClasseName,
        inputStyle: inputStyle,
        containerStyle: containerStyle,
        searchIconClassName,
        testId: 'patients-search-bar'
      }}
      searchListProps={{
        searchItemClassName: searchItemClassName,
        className: searchListClassName,
        noDataMsg: translate(
          `search_panel.no_result_found_for_“X”_in_your_patient_list`,
          { X: searchedItem }
        ),
        minSearchTextlength: MIN_SEARCH_TEXT_LEN
      }}
      searchListWrapperClassName={searchListWrapperClassName}
    />
  );
};

export default PatientsAutoSuggest;
