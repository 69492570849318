import React, { FC } from 'react';

import pageNotFound from 'assets/images/img-notfound.png';
import { pagePaths } from 'config/pages';

const PageNotFound: FC = () => {
  return (
    <div className='flex items-center justify-center w-full h-full flex-col'>
      <img src={pageNotFound} alt='Not Found' className='w-1/3 ' />
      <h1 className='mt-10 text-3xl'>Four Oh-Oh!</h1>
      <p className='mt-6'>
        We seem to have misplaced the page you’re looking for, but fret not and
        keep smiling.
      </p>
      <a
        href={pagePaths.dashboard}
        className='mt-12 bg-PRIMARY text-WHITE py-4 px-10 rounded-4xl'
      >
        Take me to home
      </a>
    </div>
  );
};

export default PageNotFound;
