import { Option } from 'types/select';
import { TPVFormTypes } from 'types/TPVForm.types';

export enum TREATMENT_GOALS {
  ALIGNMENT = 'ALIGNMENT',
  RETRACTION = 'RETRACTION',
  SPACE_CLOSURE = 'SPACE_CLOSURE'
}

export enum TREATMENT_GOALS_PROCEDURES {
  IPR = 'IPR',
  EXPANSION = 'EXPANSION',
  DISTALIZATION = 'DISTALIZATION'
}

export enum ARCHES_TO_TREAT {
  BOTH = 'BOTH',
  UPPER = 'UPPER',
  LOWER = 'LOWER'
}
export enum ARCHES_TO_TREAT_EXTERNAL {
  BOTH = 'refinementForm.archesToTreat.Both',
  UPPER = 'refinementForm.archesToTreat.Upper',
  LOWER = 'refinementForm.archesToTreat.Lower'
}

export const RESCAN_OPTIONS: Option[] = [
  {
    label: 'yes.yes',
    value: 'YES'
  },
  {
    label: 'no.no',
    value: 'NO'
  }
];
export enum OVERBITES {
  SHOW_RESULTING = 'SHOW_RESULTING',
  CORRECT_DEEP_BITE = 'DEEP_BITE_INTRUDE',
  CORRECT_OPEN_BITE = 'OPEN_BITE_EXTRUDE'
}

export enum MIDLINES {
  SHOW_RESULTING = 'SHOW_RESULTING',
  MAINTAIN = 'MAINTAIN',
  IMPROVE = 'IMPROVE'
}

export enum MIDLINES_SHIFT_SUB {
  UPPER_LEFT = 'UPPER_LEFT',
  UPPER_RIGHT = 'UPPER_RIGHT',
  LOWER_LEFT = 'LOWER_LEFT',
  LOWER_RIGHT = 'LOWER_RIGHT'
}

export enum TRUTH_VALUE {
  YES = 'YES',
  NO = 'NO'
}

export const MIDLINES_UPPER_SHIFT_SUB_OPTIONS = [
  {
    label: 'midlines.right',
    value: MIDLINES_SHIFT_SUB.UPPER_RIGHT
  },
  {
    label: 'midlines.left',
    value: MIDLINES_SHIFT_SUB.UPPER_LEFT
  }
];

export const MIDLINES_LOWER_SHIFT_SUB_OPTIONS = [
  {
    label: 'midlines.right',
    value: MIDLINES_SHIFT_SUB.LOWER_RIGHT
  },
  {
    label: 'midlines.left',
    value: MIDLINES_SHIFT_SUB.LOWER_LEFT
  }
];

export const MIDLINES_OPTIONS = [
  {
    label: 'midlines.show.resulting',
    value: MIDLINES.SHOW_RESULTING
  },
  {
    label: 'midlines.maintain',
    value: MIDLINES.MAINTAIN,
    message: 'IPR may be necessary'
  },
  {
    label: 'midlines.improve',
    value: MIDLINES.IMPROVE,
    message: 'IPR may be necessary'
  }
];

export enum TEETH_SELECT_OPTIONS {
  NONE = 'None',
  SELECT_TEETH = 'SELECT_TEETH'
}

export const TREATMENT_GOALS_ALIGNMENT_PROCEDURES_OPTIONS = [
  {
    key: TREATMENT_GOALS_PROCEDURES.IPR,
    label: 'IPR',
    subOptions: []
  },
  {
    key: TREATMENT_GOALS_PROCEDURES.EXPANSION,
    label: 'prescription.expansion',
    subOptions: []
  }
];
export const TREATMENT_GOAL_PROCEDURES_DISTILIZATION_UPPER_VALUES = [
  { value: 'upto 1.5mm', label: 'Upto 1.5mm' },
  { value: 'upto 2.5mm', label: 'Upto 2.5mm' }
];
export const TREATMENT_GOAL_PROCEDURES_DISTILIZATION_LOWER_VALUES = [
  { value: 'upto 1.5mm', label: 'Upto 1.5mm' }
];
export const TREATMENT_GOALS_DISTALIZATION_VALUES = [
  {
    key: 'upper',
    label: 'Upper',
    subOptions: TREATMENT_GOAL_PROCEDURES_DISTILIZATION_UPPER_VALUES
  },
  {
    key: 'lower',
    label: 'refinementForm.Lower',
    subOptions: TREATMENT_GOAL_PROCEDURES_DISTILIZATION_LOWER_VALUES
  }
];

export const TREATMENT_GOALS_RETRACTION_PROCEDURES_OPTIONS = [
  {
    key: TREATMENT_GOALS_PROCEDURES.IPR,
    label: 'tpv.chips_label_(non_interactive).submitted',
    subOptions: []
  },
  {
    key: TREATMENT_GOALS_PROCEDURES.EXPANSION,
    label: 'prescription.expansion',
    subOptions: []
  },
  {
    key: TREATMENT_GOALS_PROCEDURES.DISTALIZATION,
    label: 'prescription.destilization',
    subOptions: TREATMENT_GOALS_DISTALIZATION_VALUES
  }
];

export const TREATMENT_GOALS_OPTIONS = [
  {
    label: 'refinementGoals.alignment',
    value: TREATMENT_GOALS.ALIGNMENT,
    subOptions: TREATMENT_GOALS_ALIGNMENT_PROCEDURES_OPTIONS
  },
  {
    label: 'prescription.retraction',
    value: TREATMENT_GOALS.RETRACTION,
    subOptions: TREATMENT_GOALS_RETRACTION_PROCEDURES_OPTIONS
  },
  {
    label: 'prescription.space.closure',
    value: TREATMENT_GOALS.SPACE_CLOSURE,
    subOptions: []
  }
];

export const ARCHES_TO_TREAT_OPTIONS = [
  {
    label: 'refinementForm.archesToTreat.Both',
    value: ARCHES_TO_TREAT.BOTH
  },
  {
    label: 'upper.image',
    value: ARCHES_TO_TREAT.UPPER
  },
  {
    label: 'lower.image',
    value: ARCHES_TO_TREAT.LOWER
  }
];
export const ARCHES_TO_TREAT_OPTIONS_EXTERNAL = [
  {
    label: ARCHES_TO_TREAT_EXTERNAL.BOTH,
    value: ARCHES_TO_TREAT.BOTH
  },
  {
    label: ARCHES_TO_TREAT_EXTERNAL.UPPER,
    value: ARCHES_TO_TREAT.UPPER
  },
  {
    label: ARCHES_TO_TREAT_EXTERNAL.LOWER,
    value: ARCHES_TO_TREAT.LOWER
  }
];

export const TOOTH_MOVEMENT_RESTRICTIONS_OPTIONS = [
  {
    label: 'prescription.none.move.all',
    value: TEETH_SELECT_OPTIONS.NONE,
    id: 'toothMovementRestrictions.none'
  },
  {
    label: 'these.teeth.not.moved',
    value: TEETH_SELECT_OPTIONS.SELECT_TEETH,
    id: 'toothMovementRestrictions.selectTeeth'
  }
];

export const TEETH_TO_EXTRACT_OPTIONS = [
  {
    label: 'prescriptions.none',
    value: TEETH_SELECT_OPTIONS.NONE,
    id: 'teethToExtract.none'
  },
  {
    label: 'yes.yes',
    value: TEETH_SELECT_OPTIONS.SELECT_TEETH,
    id: 'teethToExtract.yes'
  }
];

export const PROSTHESIS_OPTIONS = [
  {
    label: 'prescriptions.none',
    value: TRUTH_VALUE.NO,
    id: 'teethToExtract.none'
  },
  {
    label: 'yes.yes',
    value: TRUTH_VALUE.YES,
    id: 'teethToExtract.yes'
  }
];
export const IPR_OR_ATTACHMENT_OPTIONS: Option[] = [
  {
    label: 'ipr.avoid',
    value: 'IPR to avoid'
  },
  {
    label: 'attachment.avoid',
    value: 'Attachment to avoid'
  }
];

export const OVERBITE_OPTIONS = [
  {
    label: 'show.resulting',
    value: OVERBITES.SHOW_RESULTING
  },
  {
    label: 'correct.deep.bite',
    value: OVERBITES.CORRECT_DEEP_BITE
  },
  {
    label: 'correct.open.bite',
    value: OVERBITES.CORRECT_OPEN_BITE
  }
];

export const TPV_TYPE_OPTIONS = [
  {
    label: 'ZenClear',
    value: TPVFormTypes.ZENCLEAR
  },
  {
    label: 'ZenPlus',
    value: TPVFormTypes.ZENPLUS
  }
];

export const ZENCLEAR_TPV_REFINEMENT_FORM_REASON: Option[] = [
  {
    label: 'Needs.finishing.improvements.default',
    value: 'Needs finishing improvements'
  },
  {
    label: 'New.scans.due.to.restorations',
    value: 'New scans due to restorations/extractions/etc.'
  },
  {
    label: 'Tooth.movements.not.tracking',
    value: 'Tooth movements not tracking'
  }
];

export const ILL_FITTING_OPTION = {
  label: 'Ill.fitting.at.fitting',
  value: 'ILL_FITTING_AT_FITTING'
};

export const ZENCLEAR_TPV_REFINEMENT_FORM_REASON_BACKEND = {
  [ZENCLEAR_TPV_REFINEMENT_FORM_REASON[0].value]:
    'NEEDS_FINISHING_IMPROVEMENTS',
  [ZENCLEAR_TPV_REFINEMENT_FORM_REASON[1].value]:
    'NEW_SCANS_DUE_TO_RESTORATIONS_EXTRACTIONS',
  [ZENCLEAR_TPV_REFINEMENT_FORM_REASON[2].value]:
    'TOOTH_MOVEMENTS_NOT_TRACKING',
  [ILL_FITTING_OPTION.value]: 'ILL_FITTING_AT_FITTING'
};

export const ZENCLEAR_TPV_REFINEMENT_FORM_REASON_WITH_ILL_FITTING: Option[] = [
  ...ZENCLEAR_TPV_REFINEMENT_FORM_REASON,
  ILL_FITTING_OPTION
];

export const ZENCLEAR_TPV_REFINEMENT_FORM_ARCHES_TO_TREAT: Option[] = [
  {
    label: 'upper_passive_aligner',
    value: ARCHES_TO_TREAT.UPPER
  },
  {
    label: 'lower_passive_aligner',
    value: ARCHES_TO_TREAT.LOWER
  },
  {
    label: 'prescriptions.both',
    value: ARCHES_TO_TREAT.BOTH
  }
];

export const ZENCLEAR_TPV_REFINEMENT_FORM_ATTACHMENTS: Option[] = [
  {
    label: 'refinement.attachments.keepAll',
    value: 'Keep all previous attachments and change as required'
  },
  {
    label: 'refinement.attachments.removeAll',
    value: 'Remove all previous attachments and add as required'
  }
];

export enum RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS {
  ATTACHMENTS_ONLY = 'Add attachments on teeth',
  ADD_BUTTON_FOR_ELASTICS = 'Add buttons for elastics',
  SEE_RESULTING_PLAN = 'See resulting plan'
}

export enum REFINEMENT_GOALS {
  ALIGNMENT = 'Alignment',
  CLOSE_RESIDUAL_SPACES = 'Close Residual Space',
  RESOLVE_ANTERIOR_CONTACTS = 'Resolve Heavy Anterior Contacts',
  RESOLVE_POSTERIOR_OPEN_BITE = 'Resolve posterior open bite'
}

export enum REFINEMENT_IPR {
  AS_PRESCRIBED = 'IPR performed as prescribed',
  NOT_AS_PRESCRIBED = 'IPR not performed as prescribed'
}

export const IPR_REFINEMENT_FORM_OPTIONS: Option[] = [
  {
    label: 'refinement.ipr.asPrescribed',
    value: REFINEMENT_IPR.AS_PRESCRIBED
  },
  {
    label: 'refinement.ipr.notAsPrescribed',
    value: REFINEMENT_IPR.NOT_AS_PRESCRIBED
  }
];

export const REFINEMENT_GOALS_OPTIONS: Option[] = [
  {
    label: 'refinementGoals.alignment',
    value: REFINEMENT_GOALS.ALIGNMENT
  },
  {
    label: 'refinementGoals.closeResidualSpaces',
    value: REFINEMENT_GOALS.CLOSE_RESIDUAL_SPACES
  },
  {
    label: 'refinementGoals.openBite',
    value: REFINEMENT_GOALS.RESOLVE_POSTERIOR_OPEN_BITE
  },

  {
    label: 'refinementGoals.heavyAnterior',
    value: REFINEMENT_GOALS.RESOLVE_ANTERIOR_CONTACTS
  }
];

export const TYPES: Record<string, string> = {
  ZENCLEAR_PLUS: 'ZenPlus',
  ZENCLEAR: 'ZenClear'
};

export const ZENCLEAR_FORM_DEFAULT_VALUES = {
  archesToTreat: ARCHES_TO_TREAT.BOTH,
  toothMovementRestrictions: TEETH_SELECT_OPTIONS.NONE,
  prosthesis: TRUTH_VALUE.NO,
  extractTeeth: TEETH_SELECT_OPTIONS.NONE
};
export const ZENPLUS_FORM_DEFAULT_VALUES = {
  treatmentGoalsProcedures: {},
  proceduresInnerFields: {},
  upperLowerProcedures: { upper: undefined, lower: undefined },
  extraGoals: [],
  archesToTreat: ARCHES_TO_TREAT.BOTH,
  toothMovementRestrictions: TEETH_SELECT_OPTIONS.NONE,
  overbite: OVERBITES.SHOW_RESULTING,
  midlines: MIDLINES.SHOW_RESULTING,
  prosthesis: TRUTH_VALUE.NO,
  extractTeeth: TEETH_SELECT_OPTIONS.NONE,
  specialNotes: ''
};
export const ATTACHMENTS_OPTIONS: Option[] = [
  {
    label: 'refinement.attachments.resultingPlan',
    value: RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.SEE_RESULTING_PLAN
  },
  {
    label: 'refinement.attachments.addAttachments',
    value: RESOLVE_POSTERIOR_OPEN_BITE_OPTIONS.ATTACHMENTS_ONLY
  }
];

export enum VERSIONS {
  V0 = 'V0',
  V1 = 'V1',
  V2 = 'V2'
}
