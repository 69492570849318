import { lazy, ReactElement } from 'react';

import { PERMISSIONS } from 'constants/Permissions';

const PatientList = lazy(() => import('pages/PatientList/PatientList'));
const PatientPage = lazy(() => import('pages/PatientPage/PatientPage'));
const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'));
const Referrals = lazy(() => import('pages/Referrals/Referrals'));
const ReferPatient = lazy(() => import('pages/ReferPatient/ReferPatient'));
const Calendar = lazy(() => import('pages/Calendar/Calendar'));
const FinanceReport = lazy(() => import('pages/FinanceReport/FinanceReport'));
const CaseLibrary = lazy(() => import('pages/CaseLibrary/CaseLibrary'));
const Settings = lazy(() => import('pages/Settings/Settings'));
const PageNotFound = lazy(() => import('pages/PageNotFound'));
const Inbox = lazy(() => import('pages/Inbox/Inbox'));
const NewChat = lazy(() => import('pages/NewChat/NewChat'));
const Finance = lazy(() => import('pages/Finance/Finance'));
const GeneralSettings = lazy(
  () => import('pages/GeneralSettings/GeneralSettings')
);
const BranchSettings = lazy(
  () => import('pages/BranchSettings/BranchSettings')
);
const UserSettings = lazy(() => import('pages/UserSettings/UserSettings'));
const LoginAndSecuritySettings = lazy(
  () => import('pages/LoginAndSecuritySettings/LoginAndSecuritySettings')
);
const ProfileSettings = lazy(
  () => import('pages/ProfileSettings/ProfileSettings')
);
const AppointmentList = lazy(() => import('pages/Appointment/index'));
const AppointmentPage = lazy(
  () => import('pages/AppointmentPage/AppointmentPage')
);
const AppointmentSettings = lazy(
  () => import('pages/AppointmentSettings/AppointmentSettings')
);

const TPV = lazy(() => import('pages/TPV/TPV'));
const TreatmentPlanningPreference = lazy(
  () => import('pages/TreatmentPlanningPreference/TreatmentPlanningPreference')
);
const GiveInstructionsDetails = lazy(
  () => import('pages/GiveInstructionsDetails/GiveInstructionsDetails')
);

interface Page {
  title: string;
  path: string;
  Component: ReactElement;
  permission?: string;
  redirectTo?: string;
}

interface PagePaths {
  [path: string]: string;
}

export const pagePaths: PagePaths = {
  dashboard: '/dashboard',
  login: '/login',
  forgot: '/forgot',
  patientList: '/patient/list',
  patientPage: '/patient/list/:id',
  calendar: '/calendar',
  retainerPurchase: '/retainers-purchase',
  retainerThankyou: '/retainers-purchase/thankyou',
  financeReport: '/financeReport',
  caseLibrary: '/caseLibrary',
  settings: '/settings',
  general: '/settings/general',
  branches: '/settings/branches',
  users: '/settings/users',
  security: '/settings/security',
  treatmentPlanningPreference: '/settings/treatmentPlanningPreference',
  appointment: '/settings/appointment',
  help: '/help',
  inbox: '/inbox',
  newChat: '/inbox/newChat',
  referrals: '/referrals',
  referPatient: '/referrals/referPatient',
  forgotPassword: '/en/reset-password',
  setPassword: '/en/set-password',
  profileSettings: '/profile',
  appointmentList: '/appointment/list',
  logout: '/logout',
  appointmentPage: '/appointment/list/:id',
  tpv: '/tpv/:id',
  finance: '/Finance',
  createNewAccount: '/createNewAccount',
  consentForm: '/consent-form',
  giveInstructions: '/give-instructions',
  giveInstructionsDetails: '/give-instructions/:id',
  clinicPage: '/clinics/:id',
  webLink: '/offboarding-survey',
  defaultPath: '/'
};

export const pages: Array<Page> = [
  {
    title: 'Dashboard',
    path: pagePaths.dashboard,
    Component: <Dashboard />,
    permission: PERMISSIONS.DASHBOARD_PAGE,
    redirectTo: pagePaths.patientList
  },
  {
    title: 'PatientList',
    path: pagePaths.patientList,
    Component: <PatientList />,
    permission: PERMISSIONS.PATIENT_LIST
  },
  {
    title: 'GiveInstructionsDetails',
    path: pagePaths.giveInstructionsDetails,
    Component: <GiveInstructionsDetails /> //Todo :add permission
  },
  {
    title: 'PatientPage',
    path: pagePaths.patientPage,
    Component: <PatientPage />
  },
  {
    title: 'Referrals',
    path: pagePaths.referrals,
    Component: <Referrals />,
    permission: PERMISSIONS.REFERRAL
  },
  {
    title: 'ReferPatient',
    path: pagePaths.referPatient,
    Component: <ReferPatient />,
    permission: PERMISSIONS.CREATE_REFERRAL,
    redirectTo: pagePaths.referrals
  },
  {
    title: 'PageNotFound',
    path: '*',
    Component: <PageNotFound />
  },
  {
    title: 'Calendar',
    path: pagePaths.calendar,
    Component: <Calendar />,
    permission: PERMISSIONS.CALENDAR
  },
  {
    title: 'Finance Report',
    path: pagePaths.financeReport,
    Component: <FinanceReport />
  },
  {
    title: 'Case Library',
    path: pagePaths.caseLibrary,
    Component: <CaseLibrary />,
    permission: PERMISSIONS.CASE_LIBRARY
  },
  {
    title: 'Settings',
    path: pagePaths.settings,
    Component: <Settings />,
    permission: PERMISSIONS.SETTINGS
  },
  {
    title: 'General Settings',
    path: pagePaths.general,
    Component: <GeneralSettings />,
    permission: PERMISSIONS.GENERAL_SETTINGS
  },
  {
    title: 'Branch Settings',
    path: pagePaths.branches,
    Component: <BranchSettings />,
    permission: PERMISSIONS.BRANCH_SETTINGS
  },
  {
    title: 'Users Settings',
    path: pagePaths.users,
    Component: <UserSettings />,
    permission: PERMISSIONS.USER_SETTINGS
  },
  {
    title: 'Login and Security Settings',
    path: pagePaths.security,
    Component: <LoginAndSecuritySettings />
  },
  {
    title: 'Treatment Planning Preference',
    path: pagePaths.treatmentPlanningPreference,
    Component: <TreatmentPlanningPreference />,
    permission: PERMISSIONS.TREATMENT_PLANNING_PREFERENCE
  },
  {
    title: 'Appointment Preferences',
    path: pagePaths.appointment,
    Component: <AppointmentSettings />,
    permission: PERMISSIONS.USER_PREFERENCE_VIEW
  },
  {
    title: 'Dashboard',
    path: pagePaths.defaultPath,
    Component: <Dashboard />,
    permission: PERMISSIONS.DASHBOARD_PAGE,
    redirectTo: pagePaths.patientList
  },
  {
    title: 'New Chat',
    path: pagePaths.newChat,
    permission: PERMISSIONS.CREATE_TICKET,
    Component: <NewChat />
  },
  {
    title: 'Inbox',
    path: pagePaths.inbox,
    permission: PERMISSIONS.INBOX,
    Component: <Inbox />
  },
  {
    title: 'Profile',
    path: pagePaths.profileSettings,
    Component: <ProfileSettings />
  },
  {
    title: 'AppointmentList',
    path: pagePaths.appointmentList,
    Component: <AppointmentList />
  },
  {
    title: 'AppointmentPage',
    path: pagePaths.appointmentPage,
    Component: <AppointmentPage />
  },
  {
    title: 'Finance',
    path: pagePaths.finance,
    permission: PERMISSIONS.FINANCE_TAB_VIEW,
    Component: <Finance />
  },
  {
    title: 'tpvPage',
    path: pagePaths.tpv,
    Component: <TPV />
  }
];
