import React, { FC } from 'react';

import classNames from 'classnames';

import { InfoCardView } from 'components';
import AnimateLabel from 'components/AnimateLabel/AnimateLabel';
import { If } from 'components/Generics';
import InfoToolTip from 'components/InfoToolTip';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { InputProps } from 'types/RadioInput';
import { Option } from 'types/select';
import { translate, customTwMerge as twMerge } from 'utils';

import Radio from './Radio/Radio';

interface Props {
  className?: string;
  title?: string;
  name: string;
  onChangehandle(value: string): void;
  showErrorTitle?: boolean;
  selectedItem?: string;
  options?: Array<Option>;
  radioClassName?: string;
  inputProps?: InputProps;
  radioItemFont?: string;
  message?: string;
  titleClassName?: string;
  optionClassName?: string;
  onClick?: () => void;
  infoToolTipMessage?: string;
}

const RadioGroup: FC<Props> = ({
  title,
  name = '',
  options,
  onChangehandle,
  showErrorTitle = false,
  className = '',
  radioClassName = '',
  selectedItem = '',
  inputProps,
  radioItemFont = 'text-sm',
  message = '',
  titleClassName = '',
  optionClassName = '',
  onClick,
  infoToolTipMessage
}) => {
  const { animatedField, callback } = useFieldRefContext();
  return (
    <div
      data-testid='radio-group'
      ref={callback?.(name)}
      className='scroll-mt-500'
    >
      <div
        className={classNames(
          twMerge(
            'md:text-17px text-sm font-normal pb-1 relative overflow-hidden',
            titleClassName
          ),
          { 'text-ERROR': showErrorTitle }
        )}
      >
        <div className='flex flex-row space-x-2'>
          <span>{title}</span>
          {!!infoToolTipMessage && (
            <InfoToolTip
              message={infoToolTipMessage}
              className='w-5 h-5 text-PRIMARY'
              toolTipStyle='w-45 md:w-100'
            />
          )}
        </div>
        <AnimateLabel
          className='text-ERROR md:text-17px text-sm font-normal pb-1'
          animate={showErrorTitle && animatedField === name}
        >
          {title}
        </AnimateLabel>
      </div>
      <If condition={!!message}>
        <div className='mt-6 mb-6'>
          <InfoCardView message={message} />
        </div>
      </If>
      <div className={`${radioItemFont} ${className}`}>
        {options?.map((item) => (
          <label
            className={`py-2 flex ${optionClassName}`}
            role='presentation'
            key={item?.id || item.value}
          >
            <Radio
              data-testid='radio'
              id={item?.id || item.value}
              name={name}
              value={item.value}
              onChange={(e) => {
                onClick?.();
                onChangehandle(e.target.value);
              }}
              className={radioClassName}
              checked={selectedItem === item?.value}
              inputProps={inputProps}
            />
            {translate(item.label as string)}
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioGroup;
