import { FC } from 'react';

import { translate, customTwMerge as twMerge } from 'utils';
interface Props {
  message?: string;
  className?: string;
  msgStyle?: string;
  image?: JSX.Element;
}

const NoDataMessage: FC<Props> = ({
  message = translate('patient_list.no_data_found'),
  className,
  image,
  msgStyle
}) => {
  return (
    <div className={twMerge('flex justify-center w-full', className)}>
      {image && <div>{image}</div>}
      <h3 className={twMerge('md:text-lg xxs:text-sm', msgStyle)}>{message}</h3>
    </div>
  );
};

export default NoDataMessage;
