import { FC, useMemo } from 'react';

import '../styles.css';

export interface TimeRangePickerProps {
  value?: { startTime?: string | number; endTime?: string | number };
  onChange: (value: {
    startTime?: string | number;
    endTime?: string | number;
  }) => void;
  background?: string;
  textToBeDisaplyedWithValue?: string;
  placeholderText?: string;
}

export const TimeRangePicker: FC<TimeRangePickerProps> = ({
  value,
  onChange,
  background,
  textToBeDisaplyedWithValue = '',
  placeholderText
}) => {
  const placeholder = useMemo(() => {
    if (value?.endTime || value?.startTime) {
      return `${value?.startTime || '-- : --'} to ${
        value?.endTime || '-- : --'
      } ${textToBeDisaplyedWithValue}`;
    }
    return placeholderText;
  }, [
    value?.endTime,
    value?.startTime,
    placeholderText,
    textToBeDisaplyedWithValue
  ]);

  return (
    <div
      style={{ background }}
      className='flex items-center rounded relative cursor-pointer border-2 border-opacity-50 h-12 pl-4 md:text-base xxs:text-sm w-full rangePicker'
    >
      <input
        className={`absolute focus:outline-none ml-4 md:w-16 w-7`}
        data-testid='startTimepicker'
        id='startTimeRangepicker'
        name='startTime'
        value={value?.startTime}
        onChange={({ target }) =>
          onChange({ startTime: target.value, endTime: value?.endTime })
        }
        type='time'
      />
      <input
        className={`absolute focus:outline-none ml-20 md:w-16 w-7`}
        data-testid='endTimepicker'
        id='endTimeRangepicker'
        name='endTime'
        value={value?.endTime}
        onChange={({ target }) =>
          onChange({ endTime: target.value, startTime: value?.startTime })
        }
        type='time'
      />
      <span
        className={`text-base ${
          !(value?.endTime || value?.startTime) && 'text-DISABLED'
        }`}
      >
        {placeholder}
      </span>
    </div>
  );
};
