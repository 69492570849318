import { FC, ReactNode, useEffect, useRef, MutableRefObject } from 'react';

interface HasMore {
  top: boolean;
  bottom: boolean;
}

interface InfiniteScrollProps {
  children: ReactNode;
  next: (isBottomUpdate: boolean, previousScrollHeight: number) => void;
  hasMore: HasMore;
  scrollableTarget: MutableRefObject<HTMLDivElement | null>;
  loader: ReactNode;
  dataLength: number;
  threshold?: number;
}

const handleLoadMore = (
  event: Event,
  dataCount: MutableRefObject<{
    prevCount: number;
    currentCount: number;
  }>,
  hasMore: HasMore,
  threshold: number,
  next: (isBottomUpdate: boolean, previousScrollHeight: number) => void,
  dataLength: number
) => {
  const { scrollTop, scrollHeight, clientHeight } =
    event.currentTarget as HTMLElement;
  const { currentCount } = dataCount.current;

  if (hasMore.bottom && scrollTop + clientHeight + threshold >= scrollHeight) {
    next(true, scrollHeight);
    dataCount.current.prevCount = currentCount;
  } else if (hasMore.top && scrollTop <= 0) {
    next(false, scrollHeight);
    dataCount.current.prevCount = dataLength;
  }
};

const InfiniteScroll: FC<InfiniteScrollProps> = ({
  children,
  next,
  hasMore,
  loader,
  scrollableTarget,
  dataLength,
  threshold = 100
}) => {
  const dataCount = useRef({
    prevCount: 0,
    currentCount: 0
  });
  dataCount.current.currentCount = dataLength;
  const isListEmpty = dataLength === 0;
  if (isListEmpty) {
    dataCount.current.prevCount = 0;
  }

  useEffect(() => {
    const updateScrollPosition: EventListenerOrEventListenerObject = (
      event
    ) => {
      const { currentCount, prevCount } = dataCount.current;
      if (prevCount < currentCount) {
        handleLoadMore(event, dataCount, hasMore, threshold, next, dataLength);
      }
    };

    if (scrollableTarget && scrollableTarget.current) {
      scrollableTarget.current.addEventListener(
        'scroll',
        updateScrollPosition,
        false
      );
    }

    const scrollableEl = scrollableTarget.current;
    return () => {
      if (scrollableEl) {
        scrollableEl.removeEventListener('scroll', updateScrollPosition, false);
      }
    };
  });

  return (
    <>
      {hasMore.top && loader}
      {children}
      {hasMore.bottom && loader}
    </>
  );
};

export default InfiniteScroll;
