import { FC } from 'react';

import Avatar from 'components/Avatar/Avatar';

type SearchItemProps = {
  name: string;
  profilePicture: string;
  mobile: string | number;
  phone: string | number;
  email: string;
  className?: string;
  showDetails?: boolean;
};

const SearchItem: FC<SearchItemProps> = ({
  email,
  profilePicture,
  mobile,
  phone,
  name,
  className = '',
  showDetails = true
}) => (
  <div className={`flex ${className}`} data-testid='search-list-item'>
    {showDetails && (
      <div className='md:w-12 md:h-12 xxs:w-10 xxs:h-10 text-DEFAULT_TEXT mr-2 mt-1'>
        <Avatar image={profilePicture} />
      </div>
    )}
    <div className='flex flex-col'>
      <h3 className='text-sm md:text-lg leading-6 mb-0.5 text-DEFAULT_TEXT break-all'>
        {name}
      </h3>

      {showDetails && (
        <p className='text-xs md:text-sm leading-6 break-all'>
          {(phone || mobile) && <span>{phone || mobile},</span>}{' '}
          {email && <span>{email}</span>}
        </p>
      )}
    </div>
  </div>
);

export default SearchItem;
