import { FC } from 'react';

import { DocumentNode } from 'graphql';
import { Control, Controller } from 'react-hook-form';

import PreviewFiles from 'components/PreviewFiles/PreviewFiles';
import { TreatmentSourceType } from 'components/TreatmentProgressMenu/TreatmentMenuConfig';
import { FilesData, RenderFooter, RenderHeader } from 'types/Files.types';
import { TreatmentDocumentType } from 'types/TreatmentPlanning.types';

import TreatmentFileUploader from '../TreatmentFileUploader';

type RHFTreatmentFileUploaderProps = {
  treatmentId: string;
  name: string;
  control: Control<any, any> | undefined;
  documentType: TreatmentDocumentType;
  sourceType: TreatmentSourceType;
  allowedFileMimeType?: string;
  fileDropAreaTitle: string;
  title?: string;
  className?: string;
  isMandatory?: boolean;
  uploadButtonName?: string;
  refetchQueries?: Array<DocumentNode>;
  retainerFiles?: FilesData[];
  uploadContainerStyle?: string;
  supportedFilesText?: string;
  enableSlider?: boolean;
  onFileuploadSuccess?: (data: FilesData[]) => void;
  onUploadSuccess?: () => void;
  onUploadFail?: () => void;
  uploadButtonHandler?: () => void;
  onUploadClicked?: () => void;
  hideUploadModal?: boolean;
  showError?: boolean;
  dropContainerClassName?: string;
  allFiles?: FilesData[];
  titleClassName?: string;
  renderHeader?: RenderHeader;
  renderFooter?: RenderFooter;
  disableEdit?: boolean;
  isResubmission?: boolean;
  query?: {
    url: DocumentNode;
    urlKey: string;
    attach: DocumentNode;
    attachKey: string;
    deleteDocument?: (variables: void) => Promise<void>;
  };
};

const allowedFileMimeTypeValues = '';

const RHFTreatmentFileUploader: FC<RHFTreatmentFileUploaderProps> = ({
  control,
  name,
  treatmentId,
  hideUploadModal,
  documentType,
  sourceType,
  allowedFileMimeType = allowedFileMimeTypeValues,
  fileDropAreaTitle,
  title = '',
  className = '',
  isMandatory = false,
  uploadButtonName,
  refetchQueries,
  retainerFiles,
  uploadContainerStyle,
  supportedFilesText,
  uploadButtonHandler,
  enableSlider,
  onFileuploadSuccess,
  onUploadFail,
  onUploadSuccess,
  onUploadClicked,
  showError = true,
  dropContainerClassName = '',
  allFiles,
  titleClassName,
  renderHeader,
  renderFooter,
  disableEdit = false,
  isResubmission = false,
  query
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const showFilesUpload = !(disableEdit && field?.value?.length > 0);
        return showFilesUpload ? (
          <TreatmentFileUploader
            isResubmission={isResubmission}
            onChange={field.onChange}
            value={field.value}
            treatmentId={treatmentId}
            allowedFileMimeType={allowedFileMimeType}
            fileDropAreaTitle={fileDropAreaTitle}
            title={title}
            documentType={documentType}
            className={className}
            isMandatory={isMandatory}
            errorMessage={showError ? error?.message : ''}
            sourceType={sourceType}
            uploadButtonName={uploadButtonName}
            refetchQueries={refetchQueries}
            retainerFiles={retainerFiles}
            uploadButtonHandler={uploadButtonHandler}
            name={name}
            uploadContainerStyle={uploadContainerStyle}
            hideUploadModal={hideUploadModal}
            supportedFilesText={supportedFilesText}
            enableSlider={enableSlider}
            onFileuploadSuccess={onFileuploadSuccess}
            onUploadSuccess={onUploadSuccess}
            onUploadFail={onUploadFail}
            onUploadClicked={onUploadClicked}
            dropContainerClassName={dropContainerClassName}
            allFiles={allFiles as FilesData[]}
            titleClassName={titleClassName}
            renderHeader={renderHeader}
            renderFooter={renderFooter}
            disabled={disableEdit}
            query={query}
          />
        ) : (
          <PreviewFiles
            files={field?.value}
            allFiles={allFiles}
            title={title}
            titleClassName={titleClassName}
          />
        );
      }}
    />
  );
};

export default RHFTreatmentFileUploader;
