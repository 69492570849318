import { FC } from 'react';

import Skeleton from 'react-loading-skeleton';

const TicketCardSkeleton: FC = () => {
  return (
    <div className='w-full rounded-8px p-4 flex justify-between relative cursor-pointer mb-3  min-h-[6.5rem] bg-GHOST_WHITE'>
      <div>
        <Skeleton className='w-[10rem]' />
        <Skeleton className='w-[7rem] mt-1' />
        <Skeleton className='w-[7rem] mt-1' />
      </div>
      <div className='flex flex-col justify-between items-end'>
        <Skeleton className='w-[4rem]' />
        <Skeleton className='w-[5rem]' />
        <Skeleton className='w-[4rem]' />
      </div>
    </div>
  );
};

export default TicketCardSkeleton;
