import { FC } from 'react';

import { BinIcon } from 'assets/icons';
import Button from 'components/Button/Button';
import ThumbnailName from 'components/Generics/Thumbnail/ThumbnailName/ThumbnailName';
import { FilesData } from 'types/Files.types';

type FileUploadModalBodyProps = {
  data: FilesData[];
  onLocalDelete: (index: number) => void;
};

const FileUploadModalBody: FC<FileUploadModalBodyProps> = ({
  data,
  onLocalDelete
}) => {
  return (
    <ul className='flex flex-col'>
      {data.map(({ file, dateTime, fileType: type, id }, index) => (
        <li
          className='flex w-full text-base first:pt-10 py-4 border-b-2 border-solid border-LIGHT_GRAY_2 justify-between items-center'
          key={id}
          data-testid={`upload-modal-file-${id}`}
        >
          <ThumbnailName
            type={type}
            name={file?.name || ''}
            textClassName='text-base text-DEFAULT_TEXT break-all'
          />

          <div className='flex justify-contents-end items-center'>
            <p className='text-sm w-max leading-6 text-DISABLED pl-6 pt-2 flex-shrink-0'>
              {dateTime}
            </p>
            <Button
              className='pl-6 '
              onClick={() => {
                onLocalDelete(index);
              }}
            >
              <BinIcon title='bin-icon' />
            </Button>
          </div>
        </li>
      ))}
    </ul>
  );
};
export default FileUploadModalBody;
