import { FC, useState } from 'react';

import Files from 'components/Files/Files';
import { If } from 'components/Generics';
import ZoomPhotoPopup from 'components/ZoomPhotoPopup/ZoomPhotoPopup';
import { FilesData } from 'types/Files.types';
import { customTwMerge } from 'utils';

type PreviewFilesProps = {
  title: string;
  files: FilesData[];
  allFiles?: FilesData[];
  titleClassName?: string;
};

const PreviewFiles: FC<PreviewFilesProps> = ({
  files,
  title,
  allFiles,
  titleClassName
}) => {
  const [zoomPhoto, toggleZoomPhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<string | number>(0);

  const isFilesAvailable = files.length > 0;

  return (
    <div className='mb-2'>
      <h3
        className={customTwMerge(
          'text-17px leading-6 text-DEFAULT_TEXT relative mb-2',
          titleClassName
        )}
      >
        {title}
      </h3>
      <If condition={isFilesAvailable}>
        <Files
          files={files}
          className='flex-shrink-0 mb-4'
          enableSlider={true}
          setSelectedPhoto={setSelectedPhoto}
          toggleZoomPhoto={toggleZoomPhoto}
        />
      </If>

      <ZoomPhotoPopup
        allFiles={allFiles ?? files}
        selectedPhoto={selectedPhoto as string}
        zoomPhoto={zoomPhoto}
        toggleZoomPhoto={toggleZoomPhoto}
        setSelectedPhoto={setSelectedPhoto}
      />
    </div>
  );
};

export default PreviewFiles;
