import { FC } from 'react';

const LoginSidePanal: FC = () => {
  return (
    <div className='flex-1 xxs:hidden md:block pt-32 pl-120px'>
      <div className='md:bg-login-Img bg-contain bg-right-bottom h-full bg-no-repeat' />
    </div>
  );
};

export default LoginSidePanal;
