import { ChangeEvent, FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import { Controller, FieldValues, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import Checkbox from 'components/CheckboxGroup/Checkbox/Checkbox';
import { FormLabel } from 'components/FormLabel/FormLabel';
import { If } from 'components/Generics';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import { InfoCardView } from 'components/InfoCardView/InfoCardView';
import {
  TREATMENT_GOALS,
  TREATMENT_GOALS_ALIGNMENT_PROCEDURES_OPTIONS,
  TREATMENT_GOALS_OPTIONS,
  TREATMENT_GOALS_PROCEDURES
} from 'constants/TPVFormOptions';
import { Option } from 'types/select';
import { translate } from 'utils';
import { getSelectedOption, onchangeCheckboxFields } from 'utils/tpvUtils';

type Props = {
  disabled?: boolean;
  applyDisableStyles?: boolean;
  isZenplus?: boolean;
  classname?: string;
  fieldName?: string;
  source?: string;
};
type innermostProc = {
  value: string;
  label: string;
};
type ProcedureField = {
  key: string;
  label: string;
  subOptions: innermostProc[];
};
export type ProcedureOptions = {
  key: string;
  label: string;
  subOptions: ProcedureField[];
};

export const TreatmentGoalsInput: FC<Props> = ({
  disabled = false,
  applyDisableStyles = false,
  isZenplus = true,
  classname,
  fieldName,
  source
}) => {
  //To do add types
  const FieldName = fieldName ? `${fieldName}.` : '';
  const isPrescriptionSummary = source === 'PrescriptionSummary';
  const {
    control,
    setValue,
    formState: { isSubmitted },
    trigger,
    watch
  } = useFormContext();
  const [name] = useState(`${FieldName}treatmentGoals`);
  const upperprc = watch(`${FieldName}upperLowerProcedures.upper`);
  const lowerprc = watch(`${FieldName}upperLowerProcedures.lower`);
  const onChangeTreatmentGoal = (data: Option) => {
    let procedureValue = {};
    procedureValue =
      data?.value === TREATMENT_GOALS.SPACE_CLOSURE
        ? { [data?.value]: [] }
        : { [data?.value]: TREATMENT_GOALS_ALIGNMENT_PROCEDURES_OPTIONS };
    setValue(`${FieldName}treatmentGoalsProcedures`, procedureValue);
    setValue(`${FieldName}proceduresInnerFields`, undefined);
    setValue(`${FieldName}upperLowerProcedures`, undefined);
    if (isSubmitted) {
      trigger(`${FieldName}treatmentGoalsProcedures`);
      trigger(`${FieldName}proceduresInnerFields`);
      trigger(`${FieldName}upperLowerProcedures`);
    }
  };

  const isTreatmentGoalsProceduresChecked = (
    field: any,
    // ControllerRenderProps<
    //   FieldValues,
    //   | `${FeildName}treatmentGoalsProcedures.ALIGNMENT`
    //   | 'treatmentGoalsProcedures.RETRACTION'
    //   | 'treatmentGoalsProcedures.SPACE_CLOSURE'
    // >,
    option: string
  ) => {
    return !!field.value?.find((item: FieldValues) => option === item.key);
  };

  const onChangeTreatmentGoalsProcedures = (
    e: ChangeEvent<HTMLInputElement>,
    procedureOptions: ProcedureOptions,
    goal: string,
    field: any
    //  ControllerRenderProps<
    //   FieldValues,
    //   | 'treatmentGoalsProcedures.ALIGNMENT'
    //   | 'treatmentGoalsProcedures.RETRACTION'
    //   | 'treatmentGoalsProcedures.SPACE_CLOSURE'
    // >
  ) => {
    onchangeCheckboxFields(e, procedureOptions, field);
    setValue(name, [goal]);
    setValue(`${FieldName}proceduresInnerFields.${procedureOptions.key}`, []);
    if (
      procedureOptions.key === TREATMENT_GOALS_PROCEDURES.DISTALIZATION &&
      !e.target.checked
    ) {
      setValue(`${FieldName}upperLowerProcedures`, undefined);
    }
    if (isSubmitted) {
      trigger(`${FieldName}treatmentGoalsProcedures`);
      trigger(`${FieldName}proceduresInnerFields`);
      trigger(name);
      trigger(`${FieldName}upperLowerProcedures`);
    }
  };

  const onChangeProceduresInnerFields = (
    e: ChangeEvent<HTMLInputElement>,
    subFields: ProcedureField,
    innerfield: any,
    // ControllerRenderProps<
    //   FieldValues,
    //   `proceduresInnerFields.${string}`
    // >,
    fieldValue: FieldValues
  ) => {
    onchangeCheckboxFields(e, subFields, innerfield);
    setValue(name, fieldValue);

    setValue(`${FieldName}upperLowerProcedures.${subFields?.key}`, '');
    if (isSubmitted) {
      trigger(`${FieldName}upperLowerProcedures`);
      trigger(`${FieldName}proceduresInnerFields`);
    }
  };

  const showUpperLowerProcedures = (
    subfieldKey: string,
    innerfielvalue: ProcedureField[]
  ) => {
    return (
      !!subfieldKey &&
      innerfielvalue.find(
        (item: ProcedureField) =>
          subfieldKey === item.key && item?.subOptions?.length
      )
    );
  };

  const showProcedureInnerField = (
    prcedureOptions: ProcedureOptions,
    field: any
    // ControllerRenderProps<
    //   FieldValues,
    //   | 'treatmentGoalsProcedures.ALIGNMENT'
    //   | 'treatmentGoalsProcedures.RETRACTION'
    //   | 'treatmentGoalsProcedures.SPACE_CLOSURE'
    // >
  ) => {
    return (
      !!prcedureOptions?.key &&
      !!field.value.find(
        (item: FieldValues) =>
          item.key === prcedureOptions.key &&
          prcedureOptions?.subOptions?.length
      )
    );
  };

  useEffect(() => {
    if (isSubmitted) {
      trigger(`${FieldName}proceduresInnerFields`);
      trigger(`${FieldName}upperLowerProcedures`);
    }
  }, [upperprc, lowerprc, isSubmitted, trigger]);

  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field, formState: { errors } }) => {
          const fieldError =
            _.get(errors, `${FieldName}treatmentGoals`) ||
            _.get(errors, `${FieldName}treatmentGoalsProcedures`) ||
            _.get(errors, `${FieldName}proceduresInnerFields`) ||
            _.get(errors, `${FieldName}upperLowerProcedures`);
          const fieldValue = field.value;
          return (
            <>
              <FormLabel
                label={translate('1.Treatment.goals')}
                className='mb-1 md:text-17px'
                error={!!fieldError}
                disabled={applyDisableStyles}
                name={name}
              />
              <div className={twMerge('flex-col md:flex-row', classname)}>
                <div className='md:w-325px h-48px mt-4'>
                  <RhfSelect
                    name={name}
                    options={TREATMENT_GOALS_OPTIONS}
                    showError={false}
                    onChange={(data) => {
                      field?.onChange([data.value]);
                      if (isZenplus) onChangeTreatmentGoal(data);
                    }}
                    isDisabled={disabled}
                    inputProps={{
                      controlShouldRenderValue: true,
                      selectedValue: getSelectedOption(
                        fieldValue?.[0],
                        TREATMENT_GOALS_OPTIONS
                      )
                    }}
                  />
                </div>
                {TREATMENT_GOALS_OPTIONS?.map((option, index: number) => (
                  <If
                    condition={
                      isZenplus &&
                      fieldValue?.includes(option.value) &&
                      option.subOptions.length
                    }
                    key={option.value}
                  >
                    <div
                      className={twMerge(
                        classNames('pt-5 text-sm flex-row flex-wrap', {
                          'md:flex-nowrap': !isPrescriptionSummary
                        }),
                        classname
                      )}
                    >
                      <Controller
                        control={control}
                        name={`${FieldName}treatmentGoalsProcedures.${option.value}`}
                        key={`${option.value}_${index}`}
                        render={({ field }) => (
                          <>
                            <If condition={!!option.value}>
                              {option.subOptions.map(
                                (prcedureOptions: ProcedureOptions) => (
                                  <div key={prcedureOptions.key}>
                                    <div>
                                      <label
                                        className={classNames(
                                          'flex py-1.5 mr-5',
                                          {
                                            'md:mr-0': !isPrescriptionSummary
                                          }
                                        )}
                                        htmlFor={`${option.value}.${prcedureOptions.key}`}
                                        key={`${option.value}.${prcedureOptions.key}`}
                                      >
                                        <Checkbox
                                          disabled={disabled}
                                          name={`${FieldName}treatmentGoalsProcedures.${option.value}`}
                                          id={`${option.value}.${prcedureOptions.key}`}
                                          value={prcedureOptions?.key}
                                          checked={isTreatmentGoalsProceduresChecked(
                                            field,
                                            prcedureOptions.key
                                          )}
                                          onChange={(e) => {
                                            onChangeTreatmentGoalsProcedures(
                                              e,
                                              prcedureOptions,
                                              option.value,
                                              field
                                            );
                                          }}
                                        />
                                        <span className='pt-0.5 text-sm'>
                                          {translate(prcedureOptions.label)}
                                        </span>
                                      </label>
                                    </div>
                                    <If
                                      condition={
                                        !!showProcedureInnerField(
                                          prcedureOptions,
                                          field
                                        )
                                      }
                                    >
                                      <div className='flex flex-col w-full md:ml-4'>
                                        <InfoCardView
                                          classname={classNames('mt-4 mb-2', {
                                            'md:w-fit': isPrescriptionSummary
                                          })}
                                          message={translate('class2.class3')}
                                        />
                                        <div className=''>
                                          <Controller
                                            name={`${FieldName}proceduresInnerFields.${prcedureOptions.key}`}
                                            control={control}
                                            render={({ field: innerfield }) => {
                                              const innerFieldValue =
                                                innerfield.value;
                                              return (
                                                <>
                                                  {prcedureOptions?.subOptions?.map(
                                                    (
                                                      subFields: ProcedureField
                                                    ) => (
                                                      <div key={subFields.key}>
                                                        <label
                                                          className='flex py-1.5 md:ml-4'
                                                          htmlFor={`${prcedureOptions.key}.${subFields.key}`}
                                                        >
                                                          <Checkbox
                                                            disabled={disabled}
                                                            id={`${prcedureOptions.key}.${subFields.key}`}
                                                            checked={
                                                              !!showUpperLowerProcedures(
                                                                subFields.key,
                                                                innerFieldValue
                                                              )
                                                            }
                                                            name={`${FieldName}proceduresInnerFields.${prcedureOptions.key}`}
                                                            onChange={(e) => {
                                                              onChangeProceduresInnerFields(
                                                                e,
                                                                subFields,
                                                                innerfield,
                                                                fieldValue
                                                              );
                                                            }}
                                                            value={
                                                              subFields.key
                                                            }
                                                          />
                                                          <span className='pt-0.5 text-sm'>
                                                            {translate(
                                                              subFields.label
                                                            )}
                                                          </span>
                                                        </label>
                                                        <If
                                                          condition={
                                                            !!showUpperLowerProcedures(
                                                              subFields.key,
                                                              innerFieldValue
                                                            )
                                                          }
                                                        >
                                                          <Controller
                                                            name={`${FieldName}upperLowerProcedures.${subFields.key}`}
                                                            control={control}
                                                            render={({
                                                              field:
                                                                upperLowerProceduresField
                                                            }) => (
                                                              <div className='ml-5 md:ml-7 md:w-335px'>
                                                                <RhfSelect
                                                                  name={`${FieldName}upperLowerProcedures.${subFields.key}`}
                                                                  options={
                                                                    subFields.subOptions
                                                                  }
                                                                  onChange={(
                                                                    data
                                                                  ) => {
                                                                    upperLowerProceduresField.onChange(
                                                                      data.value
                                                                    );
                                                                  }}
                                                                  isDisabled={
                                                                    disabled
                                                                  }
                                                                  inputProps={{
                                                                    controlShouldRenderValue:
                                                                      true,
                                                                    selectedValue:
                                                                      getSelectedOption(
                                                                        upperLowerProceduresField?.value,
                                                                        subFields.subOptions
                                                                      )
                                                                  }}
                                                                />
                                                              </div>
                                                            )}
                                                          />
                                                        </If>
                                                      </div>
                                                    )
                                                  )}
                                                </>
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </If>
                                  </div>
                                )
                              )}
                            </If>
                          </>
                        )}
                      />
                    </div>
                  </If>
                ))}
              </div>
            </>
          );
        }}
      />
    </div>
  );
};
