const controlStyles = {
  width: '15rem',
  boxShadow: '2px 4px 8px rgba(142, 153, 168, 0.25)'
};

const menuStyles = {
  width: '100%'
};

const RhfControlStyle = {
  boxShadow: '2px 4px 8px rgba(142, 153, 168, 0.25)'
};

export { controlStyles, menuStyles, RhfControlStyle };
