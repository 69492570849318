import { TREATMENT_TYPE } from 'constants/patientList';
import { PERMISSIONS } from 'constants/Permissions';

export type AllStages = keyof typeof AllTreatmentStages;

export type ProgressMenuOptions = {
  menuKey: AllStages;
  menuName: string;
  permission?: PERMISSIONS;
};

const permissionMapper: Record<string, string> = {
  PRE_ASSESSMENT: PERMISSIONS.STAGE_PRE_ASSESSMENT_VIEW,
  PENDING_PRESCRIPTION: PERMISSIONS.STAGE_PRESCRIPTION_VIEW,
  TREATMENT_PLAN_REVIEW: PERMISSIONS.STAGE_TP_REVIEW_VIEW,
  IN_TREATMENT: PERMISSIONS.STAGE_IN_TREATMENT_VIEW,
  END_OF_TREATMENT_REVIEW: PERMISSIONS.STAGE_END_OF_TREATMENT_VIEW
};

export const getProgressMenuOptions = (
  activeStage: AllStages | undefined,
  treatmentType: string
): ProgressMenuOptions[] => {
  let treatmentStages;
  if (treatmentType === TREATMENT_TYPE.ZENYUM_RETAINER) {
    treatmentStages = RetainerStage;
  } else if (treatmentType === TREATMENT_TYPE.WHITENING) {
    treatmentStages = WhiteningStage;
  } else {
    const indexOfCurrentStage = Object.keys(TreatmentStages).findIndex(
      (menuKey) => menuKey === activeStage
    );
    treatmentStages =
      indexOfCurrentStage > 1
        ? TreatmentStagesAfterPrescription
        : TreatmentStages;
  }

  return Object.entries(treatmentStages).map(([menuKey, menuName]) => {
    return {
      menuKey: menuKey as AllStages,
      menuName,
      permission: permissionMapper[menuKey] as PERMISSIONS
    };
  });
};

export enum TreatmentStages {
  PRE_ASSESSMENT = 'chips_label.pre-assessment',
  PENDING_PRESCRIPTION = 'chips_label.pending_prescription',
  TREATMENT_PLAN_REVIEW = 'chips_label.treatment_plan_review',
  IN_TREATMENT = 'status.in_treatment',
  END_OF_TREATMENT_REVIEW = 'chips_label.end-of-treatment_review'
}

enum AllTreatmentStages {
  PRE_ASSESSMENT = 'chips_label.pre-assessment',
  PENDING_PRESCRIPTION = 'chips_label.pending_prescription',
  TREATMENT_PLAN_REVIEW = 'chips_label.treatment_plan_review',
  IN_TREATMENT = 'status.in_treatment',
  END_OF_TREATMENT_REVIEW = 'chips_label.end-of-treatment_review',
  PRESCRIPTIONS = 'chips_label.prescriptions',
  PRESCRIPTION = 'Prescription',
  PURCHASE = 'Purchase',
  PRODUCTION_AND_DELIVERY = 'Production and Delivery',
  TREATMENT = 'Treatment'
}

enum TreatmentStagesAfterPrescription {
  PRE_ASSESSMENT = 'chips_label.pre-assessment',
  PENDING_PRESCRIPTION = 'chips_label.prescriptions',
  TREATMENT_PLAN_REVIEW = 'chips_label.treatment_plan_review',
  IN_TREATMENT = 'status.in_treatment',
  END_OF_TREATMENT_REVIEW = 'chips_label.end-of-treatment_review'
}

enum RetainerStage {
  PRESCRIPTION = 'Prescription',
  PURCHASE = 'Purchase',
  PRODUCTION_AND_DELIVERY = 'Production and Delivery',
  TREATMENT = 'Treatment'
}

enum WhiteningStage {
  PRESCRIPTION = 'Prescription',
  PURCHASE = 'Purchase',
  DELIVERY = 'Delivery'
}

export enum TreatmentSourceType {
  PRESCRIPTIONS = 'PRESCRIPTIONS',
  PRE_ASSESSMENT = 'PRE_ASSESSMENT',
  PATIENT_FILES = 'PATIENT_FILES',
  END_OF_TREATMENT = 'END_OF_TREATMENT',
  RETAINERS = 'RETAINERS',
  REFINEMENT = 'REFINEMENT'
}

export const SourceStageMap: Record<string, string> = {
  PRE_ASSESSMENT: TreatmentSourceType.PRE_ASSESSMENT,
  PENDING_PRESCRIPTION: TreatmentSourceType.PRESCRIPTIONS,
  END_OF_TREATMENT_REVIEW: TreatmentSourceType.END_OF_TREATMENT,
  RETAINERS: TreatmentSourceType.RETAINERS,
  PRESCRIPTIONS: TreatmentSourceType.PRESCRIPTIONS
};
