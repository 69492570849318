import React, { FC, useCallback } from 'react';

import classNames from 'classnames';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  MenuProps,
  OptionProps,
  StylesConfig,
  ThemeConfig
} from 'react-select';
import { twMerge } from 'tailwind-merge';

import { DownArrowIcon, Search } from 'assets/icons';
import { Divider } from 'components';
import Checkbox from 'components/CheckboxGroup/Checkbox/Checkbox';
import { If } from 'components/Generics';
import Radio from 'components/RadioGroup/Radio/Radio';
import { MOBILE_SCREEN_WIDTH } from 'constants/common';
import { Option, SelectProps } from 'types/select';
import { translate, useViewport } from 'utils';
import { useIsmobile } from 'utils/customHooks/useViewport';

const Select: FC<SelectProps> = ({
  name,
  testId = 'select-component',
  className,
  options,
  placeholder,
  onChange,
  selectedValue = [],
  controlShouldRenderValue = false,
  isMulti = false,
  showDropDownIcon = true,
  isFormSelect,
  showCheckBox = true,
  showRadio = false,
  menuMinWidth = '100%',
  menuMaxWidth,
  isDisabled,
  styles,
  defaultValue,
  formSelectStyles,
  isInputError = false,
  menuAlignment = 'left',
  menuPlacement,
  onMenuClose,
  onMenuOpen,
  menuListMaxHeight = '336px',
  menuShouldScrollIntoView = true,
  hideSelectedOptions = true,
  placeHolderStyle = {
    fontSize: '1.063rem',
    fontColor: '#999999'
  },
  selectHeight = '3.5rem',
  isSearchable = false,
  controlStyles = {},
  dropdownIconClassName = '',
  showResetSelectionButton = false,
  hoverColor,
  menuStyles,
  menuIsOpen,
  inputValue,
  onInputChange,
  autoFocus = false,
  showSearchIcon = false,
  filterOption,
  valueContainerStyles,
  searchBoxHeight = ''
}) => {
  const { width } = useViewport();
  const isMobile = useIsmobile();
  /**
   * @description
   * Function to change dropdown indicaor in the select component
   * @param props
   * @returns {node}
   */
  const DropdownIndicator = (props: DropdownIndicatorProps<Option>) => {
    const { selectProps } = props;
    if (showDropDownIcon)
      return (
        <components.DropdownIndicator {...props} data-testid='down-arrow'>
          <If condition={showSearchIcon}>
            <Search className='text-DISABLED' />
          </If>
          <If condition={!showSearchIcon}>
            {selectProps.menuIsOpen ? (
              <DownArrowIcon
                className={twMerge(
                  'w-3 h-3 transform rotate-180 text-DEFAULT_TEXT',
                  dropdownIconClassName
                )}
              />
            ) : (
              <DownArrowIcon
                className={twMerge(
                  'w-3 h-3 text-DEFAULT_TEXT',
                  dropdownIconClassName
                )}
              />
            )}
          </If>
        </components.DropdownIndicator>
      );
    return null;
  };

  /**
   * @description
   * To generate custom options to be rendered in the select component
   * @param props
   * @returns {node}
   */
  const CustomOption = (props: OptionProps<Option>) => {
    const { isSelected, label, data } = props;
    const { className } = data;

    return (
      <components.Option {...props}>
        <div
          data-testid={`${translate(label)}`}
          className={classNames('px-4 py-2 md:py-4 flex w-full items-start', {
            [className]: className
          })}
        >
          {isMulti && showCheckBox && (
            <Checkbox checked={isSelected} readOnly />
          )}
          <If condition={!isMulti && showRadio}>
            <Radio checked={isSelected} />
          </If>
          {data?.icon && <div className='mr-4'>{data?.icon}</div>}
          <label>
            {translate(label, {
              nsSeparator: '@!' // This is added to replace | in newchat TPV filter
            })}
          </label>
        </div>
        {data?.divider && <Divider />}
      </components.Option>
    );
  };

  const customStyle: StylesConfig<Option, boolean> = {
    option: (base, { isSelected, isFocused }) => {
      let backgroundColor = '';
      const isFocusedOnDesktop = isFocused && !isMobile;

      if (isSelected && !showCheckBox) backgroundColor = '#2CAAE2';
      if (isFocusedOnDesktop) backgroundColor = '#F1F8FF';

      return {
        ...base,
        color: isSelected && !showCheckBox ? '#FFFFFF' : '#282829',
        fontSize: '0.875rem',
        textAlign: 'left',
        padding: 0,
        backgroundColor,
        ':active': {
          ...base[':active'],
          backgroundColor: isSelected && !showCheckBox ? '#2CAAE2' : '',
          color: isSelected && !showCheckBox ? '#FFFFFF' : '#282829'
        },
        ':hover': {
          backgroundColor: isFocused ? '' : hoverColor || '#FAFBFC'
        }
      };
    },
    valueContainer: (base) => ({
      ...base,
      width: 'max-content',
      ...valueContainerStyles
    }),
    control: (base) => {
      if (isFormSelect) {
        return {
          ...base,
          height: width > MOBILE_SCREEN_WIDTH ? selectHeight : '3rem',
          backgroundColor: isDisabled ? '#EBEBEB' : '#FFFFFF',
          padding: width < 768 ? '0.625rem 0.5rem' : '0rem 0.5rem',
          border: isInputError ? '1px solid #D91E36' : '1px solid #EEEEEE',
          boxShadow: 'none',
          cursor: 'pointer',
          borderRadius: '0.5rem',
          minHeight: '3rem',
          fontSize: width < 768 ? '0.875rem' : '1rem',
          flexWrap: 'nowrap',
          ...formSelectStyles,
          '&:hover': {
            border: '1px solid #EEEEEE'
          },
          ...controlStyles
        };
      }
      return {
        ...base,
        cursor: 'pointer',
        border: 0,
        backgroundColor: width < 768 ? '#FFF' : '#FAFBFC',
        boxShadow: 'none',
        minHeight: '100%',
        height: searchBoxHeight,
        borderRadius: 4,
        ...controlStyles
      };
    },
    placeholder: (base) => {
      if (isFormSelect) {
        if (width >= 768)
          return {
            ...base,
            fontSize: placeHolderStyle.fontSize,
            color: placeHolderStyle.fontColor
          };
        return {
          ...base,
          fontSize: placeHolderStyle.MobileFontSize || '0.875rem',
          color: placeHolderStyle.MobileFontColor || '#999999'
        };
      }
      return {
        ...base,
        fontSize: '0.875rem',
        color: '#282829'
      };
    },
    indicatorSeparator: (base) => ({
      ...base,
      display: 'none'
    }),
    singleValue: (base) => {
      if (isFormSelect) {
        return {
          ...base,
          color: isInputError && '#D91E36'
        };
      }
      return {
        ...base
      };
    },
    menu: (base) => {
      if (isFormSelect) {
        return {
          ...base,
          marginTop: '1px',
          width: '100%',
          zIndex: 20,
          boxShadow: '4px 8px 16px rgba(142, 153, 168, 0.25)',
          borderRadius: '8px',
          background: '#FFFFFF',
          color: '#282829',
          ...menuStyles
        };
      }
      return {
        ...base,
        width: 'max-content',
        maxWidth: menuMaxWidth,
        minWidth: menuMinWidth,
        borderRadius: '8px',
        minHeight: '100%',
        zIndex: 20,
        background: '#FFFFFF',
        ...(menuAlignment === 'left' && { right: 0 }),
        lineHeight: '1.5rem',
        boxShadow: '2px 4px 8px rgba(142, 153, 168, 0.25)',
        ...menuStyles
      };
    },
    multiValue: (base) => {
      return {
        ...base,
        backgroundColor: '#FFFFFF'
      };
    },
    menuList: (base) => ({
      ...base,
      overflowY: 'overlay',
      maxHeight: menuListMaxHeight,
      '::-webkit-scrollbar-track': {
        marginTop: '10px',
        marginBottom: '10px'
      },
      padding: 0
    }),
    input: (base) => ({
      ...base,
      cursor: 'text'
    }),
    ...styles
  };

  const theme: ThemeConfig = (theme) => ({
    ...theme,
    borderRadius: 0,
    color: 'green',
    colors: {
      ...theme.colors,
      primary: '#FFFFFF',
      primary25: '#FAFBFC',
      primary50: '#c9cad0'
    }
  });

  const Menu = useCallback((props: MenuProps<any, any, any>) => {
    return (
      <components.Menu {...props}>
        {props.children}
        <If condition={showResetSelectionButton && isMulti && props?.hasValue}>
          <button
            className='px-4 bottom-0 text-center py-2 text-sm sticky w-full bg-GRAY_6 text-DISABLED'
            onClick={() =>
              onChange?.([], {
                name: 'reset-selection',
                action: 'clear',
                removedValues: props?.options
              })
            }
          >
            {translate('select.clear_all_selection')}
          </button>
        </If>
      </components.Menu>
    );
  }, []);

  const groupSelectedOptionsToTop = (options: any, selectedValue: any) => {
    const areOptionsEqual = (option1: any, option2: any) => {
      return option1.value == option2.value;
    };

    const unselectedOptions = options.filter((option: any) => {
      return !selectedValue.some((selected: any) =>
        areOptionsEqual(selected, option)
      );
    });
    return [
      { label: translate('filters.selected_options'), options: selectedValue },
      {
        label:
          selectedValue.length > 0
            ? translate('filters.other_options')
            : translate('filters.all_options'),
        options: unselectedOptions
      }
    ];
  };

  const preventSpaceSelection = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === ' ' && !inputValue) e.preventDefault();
  };
  const getOptionLabel = (option: Option) => {
    return translate(option?.label || '');
  };

  return (
    <div className={twMerge('h-full', className)} data-testid={testId}>
      <ReactSelect
        defaultValue={defaultValue}
        backspaceRemovesValue={false}
        name={name}
        className='h-full'
        styles={customStyle}
        options={
          isMulti ? groupSelectedOptionsToTop(options, selectedValue) : options
        }
        getOptionLabel={getOptionLabel}
        hideSelectedOptions={isMulti ? false : hideSelectedOptions}
        menuPlacement={menuPlacement}
        isMulti={isMulti}
        isSearchable={isSearchable}
        inputValue={inputValue}
        onInputChange={onInputChange}
        placeholder={placeholder}
        controlShouldRenderValue={controlShouldRenderValue}
        isDisabled={isDisabled}
        isClearable={false}
        closeMenuOnSelect={!isMulti}
        value={selectedValue}
        theme={theme}
        components={{
          Option: CustomOption,
          DropdownIndicator,
          Menu
        }}
        onChange={onChange}
        onMenuClose={onMenuClose}
        menuShouldScrollIntoView={menuShouldScrollIntoView}
        onMenuOpen={onMenuOpen}
        menuIsOpen={menuIsOpen}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        filterOption={filterOption}
        onKeyDown={preventSpaceSelection}
      />
    </div>
  );
};

export default Select;
