import { FC, MouseEvent, useState } from 'react';

import FileDelete from 'components/FileDelete/FileDelete';
import { ProgressBar, Thumbnail } from 'components/Generics';
import If from 'components/Generics/If/If';
import ThumbnailName from 'components/Generics/Thumbnail/ThumbnailName/ThumbnailName';
import { CLASSIFICATION_LABEL } from 'constants/treatmentFiles';
import { Classification } from 'types/Files.types';
import { translate } from 'utils';

type FileCardProps = {
  id: string | number;
  fileType: 'img' | 'file';
  fileSrc: string;
  thumbnail?: string;
  name: string;
  fileId?: string;
  uploadProgress?: number | number[];
  className?: string;
  onDelete?: (id: string | number, localId?: string | number) => void;
  deleting?: boolean;
  viewOnly?: boolean;
  height?: string;
  classification?: Classification | null;
  width?: string;
  noName?: boolean;
};

const FileCard: FC<FileCardProps> = ({
  id,
  fileSrc = '',
  thumbnail,
  classification,
  name = '',
  fileType = 'img',
  uploadProgress = undefined,
  className = '',
  fileId,
  viewOnly,
  onDelete,
  deleting = false,
  height,
  width,
  noName = false
}) => {
  const [onDeleteClicked, setOnDeleteClicked] = useState(false);
  const deleteDisabled =
    (uploadProgress != undefined && (uploadProgress as number) < 100) ||
    deleting ||
    onDeleteClicked ||
    (!fileId && !id);

  const handlePhotoDrag = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('id', `${id}`);
  };

  const getClassificationLabel = () => {
    if (
      classification &&
      CLASSIFICATION_LABEL[classification] &&
      classification !== Classification.OTHER
    ) {
      return translate(CLASSIFICATION_LABEL[classification]);
    }
    return '';
  };
  return (
    <div className={`${width} ${className}`}>
      <div
        className={`${
          viewOnly ? '' : 'relative'
        } inline-flex w-full md:mb-4 mb-2`}
      >
        <Thumbnail
          file={fileSrc}
          thumbnail={thumbnail}
          type={fileType}
          height={height}
          fileId={fileId}
          classification={getClassificationLabel()}
          name={name}
          onDragStart={handlePhotoDrag}
        />
        <If
          condition={
            uploadProgress != undefined && (uploadProgress as number) < 100
          }
        >
          <ProgressBar
            percentage={uploadProgress}
            className='absolute mx-1 md:mx-2 bottom-2'
          />
        </If>
        <If condition={!!onDelete}>
          <FileDelete
            className={`absolute md:right-2 md:top-2 right-1 top-1 ${
              deleteDisabled && 'cursor-wait'
            }`}
            disabled={deleteDisabled}
            onDeleteClicked={(e: MouseEvent<HTMLButtonElement>) => {
              onDelete?.(fileId || '', id);
              setOnDeleteClicked(true);
              e.stopPropagation();
            }}
          />
        </If>
      </div>
      <If condition={!noName}>
        <ThumbnailName
          name={name}
          type={fileType}
          textClassName={
            'text-DEFAULT_TEXT md:text-sm md:leading-6 text-xs leading-4 truncate'
          }
          className={`${fileType === 'file' ? 'flex' : 'md:flex'}`}
        />
      </If>
    </div>
  );
};

export default FileCard;
