import { FC, useRef, useState } from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import If from 'components/Generics/If/If';
import { Loader } from 'components/Loader/Loader';
import SearchInput from 'components/SearchInput/SearchInput';
import SearchList from 'components/SearchList/SearchList';
import {
  AutoSuggestions,
  SearchInputProps,
  SearchListProps
} from 'types/autoSuggest.types';
import { translate } from 'utils';

export type AutoSuggestProp = {
  suggestions: AutoSuggestions[];
  onSearchChange: (text: string) => void;
  onSuggestionClick: (userDetails: AutoSuggestions) => void;
  onSearchCleared?: () => void;
  searchText?: string;
  searchListWrapperClassName?: string;
  minSearchTextlength?: number;
  showTypedText?: boolean;
  placeholder: string;
  debounceTime?: number;
  loading?: boolean;
  clearOnSelect?: boolean;
  hideSearchIcon?: boolean;
  searchInputProps?: SearchInputProps;
  searchListProps?: SearchListProps;
};

const AutoSuggest: FC<AutoSuggestProp> = ({
  suggestions = [],
  onSearchChange,
  onSuggestionClick,
  onSearchCleared,
  hideSearchIcon,
  searchText = '',
  searchListWrapperClassName = '',
  minSearchTextlength = 1,
  showTypedText = true,
  placeholder,
  debounceTime = 600,
  loading = false,
  searchInputProps = {},
  clearOnSelect = false,
  searchListProps = {}
}) => {
  const [searchedText, setSearchedText] = useState(searchText);
  const [isSearchListClosed, setIsSearchListClosed] = useState(true);
  const autoSuggestRef = useRef<HTMLDivElement>(null);

  /**
   * uncomment this and the style and ref in search list wrapper if auto width rest is needed
   */
  /**const [resetWidth, setResetWidth] = useState('');
  const { width } = useViewport();
  const autoSuggestRef = useRef<HTMLDivElement>(null);
  const autoSuggestLeftWidth =
    (autoSuggestRef.current?.offsetParent as HTMLDivElement)?.offsetLeft || 0;
  const maxSearchListWidth = width - autoSuggestLeftWidth - 48;
  const autoSuggestWidth = autoSuggestRef.current?.offsetWidth || 0;
  const searchListRef = useRef<HTMLDivElement>(null);
  const searchListWidth = searchListRef.current?.offsetWidth || 0;
  // useEffect(() => {
    width > 768 &&
      setResetWidth(() => {
        searchListWidth >= maxSearchListWidth
          ? `${maxSearchListWidth}px`
          : '100%';
        if (searchListWidth >= maxSearchListWidth) {
          return `${maxSearchListWidth}px`;
        } else if (searchListWidth >= autoSuggestWidth) {
          return 'max-content';
        }
        return '100%';
      });
  }, [searchListWidth, maxSearchListWidth, width, autoSuggestWidth]);
  */

  const onInputChange = (text: string) => {
    setIsSearchListClosed((closed) => (closed ? !closed : closed));
    showTypedText && setSearchedText(text);
    text.length >= minSearchTextlength && onSearchChange?.(text);
    !text.length && onSearchCleared?.();
  };
  const onSearchListClick = (userDetails: AutoSuggestions) => {
    setIsSearchListClosed(true);
    onSuggestionClick(userDetails);
    setSearchedText(userDetails.name);
    if (clearOnSelect) {
      setSearchedText('');
    }
  };

  const closeSearchList = () => setIsSearchListClosed(true);

  const onFocusInput = () => {
    setIsSearchListClosed(false);
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        closeSearchList();
      }}
    >
      <div className='relative'>
        <div ref={autoSuggestRef}>
          <div>
            <SearchInput
              hideSearchIcon={hideSearchIcon}
              onSearchChange={onInputChange}
              inputText={searchedText}
              placeholder={placeholder}
              debounceTime={debounceTime}
              onFocusInput={onFocusInput}
              {...searchInputProps}
            />
          </div>
          <If
            condition={
              (!!showTypedText || !!suggestions.length) &&
              !!searchedText &&
              !isSearchListClosed
            }
          >
            <div
              className={`absolute z-50 bg-white shadow-suggestion rounded-lg py-6 px-6 mt-2 md:mt-4 w-full md:min-w-85 right-0  overflow-scroll ${searchListWrapperClassName}`}
              /** style={{ width: `${resetWidth}` }}
            ref={searchListRef} */
              data-testid='search-list'
            >
              {loading ? (
                <div className='w-full flex flex-col justify-center items-center xxs:h-screenHeight md:h-248px'>
                  <Loader className='h-72px w-72px' />
                  <p className='text-DISABLED text-17px mt-4'>
                    {translate('patient_list.loading_data')}
                  </p>
                </div>
              ) : (
                <SearchList
                  suggestions={suggestions}
                  onSuggestionClick={onSearchListClick}
                  searchedText={searchedText}
                  showTypedText={showTypedText}
                  minSearchTextlength={minSearchTextlength}
                  {...searchListProps}
                />
              )}
            </div>
          </If>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default AutoSuggest;
