import { FC } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Logo } from 'assets/icons';
import Button from 'components/Button/Button';
import RhfInput from 'components/Generics/Rhf/RhfInput';
import { pagePaths } from 'config/pages';
import { useVerifyIfExistingAccount } from 'services/hooks/Auth.hooks';
import { GenerateResetLink, VerifyEmailExists } from 'types/Login.types';
import { translate } from 'utils/locale';
import { showToast } from 'utils/toast/toast.util';
import { GenerateResetLinkSchema } from 'validations/LoginValidation';

const CreateNewAccount: FC = () => {
  const formMethods = useForm<GenerateResetLink>({
    mode: 'onChange',
    delayError: 5000,
    resolver: yupResolver(GenerateResetLinkSchema),
    defaultValues: { email: '' }
  });
  const {
    handleSubmit,
    formState: { isDirty }
  } = formMethods;
  const navigate = useNavigate();
  const config = {
    onCompleted: (data: VerifyEmailExists) => {
      if (data.setPassword)
        showToast(
          translate(
            'login_screen_-_forget_password_-_email_input.email_sent._look_out_for_an_email_from_smile@zenyum.com'
          ),
          true
        );
      navigate(pagePaths?.login, { state: { hasLogginButtonPressed: true } });
    }
  };
  const [createNewAccountHandler] = useVerifyIfExistingAccount(config);
  const onSubmitHandler = (data: GenerateResetLink) => {
    const param = {
      variables: {
        email: data.email
      }
    };
    createNewAccountHandler(param);
  };

  return (
    <section className='md:w-520 xxs:w-full flex flex-col'>
      <Logo className='text-PRIMARY md:w-52px md:h-8 w-39px h-6' />
      <h3 className='md:text-40px md:leading-56px font-bold text-DEFAULT_TEXT md:mt-6 mt-4 mb-6 md:mb-8 text-3xl'>
        {translate('create_new_account_screen_.welcome_to_zenyum_clinic_cloud')}
      </h3>
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          className='flex flex-col'
          data-testid='form'
        >
          <div className={'relative w-full'}>
            <RhfInput
              showAnimated={false}
              label={translate('referrals.email_address')}
              inputProps={{
                placeholder: translate('Enter your email address'),
                type: 'text',
                showBorderError: true,
                showErrorTitle: true
              }}
              name='email'
              className='w-full md:h-12 h-12 bg-white px-4 py-3'
            />
          </div>
          <Button
            type='submit'
            className='bg-PRIMARY text-WHITE w-full h-12 justify-center md:mt-12 mt-10 text-14px md:leading-22px leading-14px'
            disabled={!isDirty}
            onClick={handleSubmit(onSubmitHandler)}
          >
            {translate('submit_button_rounded_(short).button')}
          </Button>
          <p className='text-center px-16 mt-7 xxs:text-sm text-DARK_GRAY md:text-DEFAULT_TEXT'>
            {translate('create_new_account.any_problems')}
          </p>
        </form>
      </FormProvider>
    </section>
  );
};

export default CreateNewAccount;
