import { FC } from 'react';

import Tooltip from 'components/Tooltip/Tooltip';
import { translate } from 'utils';

interface Props {
  children: JSX.Element;
  content: string;
}

const InboxTooltip: FC<Props> = ({ children, content }) => {
  return (
    <Tooltip
      tooltipStyle='p-4 rounded-lg bg-DEFAULT_TEXT text-white text-xs max-w-[12.5rem] hidden md:block'
      arrowSize={9}
      arrowColor='#282829'
      messageText={translate(content)}
      containerClassName='flex'
      arrowClassName='hidden md:block'
    >
      {children}
    </Tooltip>
  );
};

export default InboxTooltip;
