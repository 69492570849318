import { gql } from '@apollo/client';

const STREAM_TRANSLATION = `
  subscription streamTranslation($id: String) {
    streamTranslation(id: $id)
  }
`;

const TRANSLATE_TEXT = gql`
  query translateText($input: TranslateInput!) @api(name: chatter) {
    translateText(input: $input)
  }
`;
export { STREAM_TRANSLATION, TRANSLATE_TEXT };
