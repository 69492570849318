import { FC } from 'react';

import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';

import { customTwMerge } from 'utils';

import If from './Generics/If/If';
import OutlinedInput from './OutlinedInput/OutlinedInput';
type props = {
  maxLimit?: number;
  minLimit?: number;
  showMaxLimit?: boolean;
  disabled?: boolean;
  name: string;
  isChecked?: boolean;
  className?: string;
};
export const NumberInput: FC<props> = ({
  maxLimit = 20,
  minLimit = 1,
  showMaxLimit = true,
  disabled = false,
  name,
  isChecked,
  className
}) => {
  const { control } = useFormContext();
  return (
    <div className='flex flex-row space-x-10px'>
      <div className='w-60px'>
        <Controller
          name={name}
          control={control}
          render={({ field }) => {
            return (
              <div className='flex flex-col'>
                <OutlinedInput
                  value={field?.value}
                  onChange={(e) => {
                    const regex = /^[0-9\b]+$/;
                    if (
                      e.target.value === '' ||
                      (Number(e.target.value) <= maxLimit &&
                        Number(e.target.value) >= minLimit &&
                        regex.test(e.target.value))
                    ) {
                      field.onChange({
                        target: { name: field.name, value: e.target.value }
                      });
                    }
                  }}
                  className={customTwMerge(
                    classNames('md:h-12', {
                      'border-PRIMARY': isChecked,
                      'border-none': disabled
                    }),
                    className
                  )}
                  name={name}
                  disabled={disabled}
                  placeholder={String(minLimit)}
                />
              </div>
            );
          }}
        />
      </div>
      <If condition={showMaxLimit}>
        <div className='pt-13px'>/{maxLimit}</div>
      </If>
    </div>
  );
};
