import { FC } from 'react';

import classNames from 'classnames';

import { SuccessIcon, ThreeDotsIcon } from 'assets/icons';
import {
  DELIVERY_INFO_MESSAGES,
  DELIVERY_INFO_STATUSES,
  DELIVERY_INFO_STATUSES_LABEL
} from 'constants/common';
import { DELIVERY_INFO_STATUS } from 'constants/options';
import { customTwMerge, translate } from 'utils';

interface DeliveryProgressBarProps {
  currentStage: string;
}

export const DeliveryProgressBar: FC<DeliveryProgressBarProps> = ({
  currentStage
}) => {
  const IndexOfCurrentStage = DELIVERY_INFO_STATUSES.findIndex(
    (item) => item === currentStage
  );

  return (
    <div className='flex flex-col bg-black p-6 rounded-lg '>
      <div className='bg-white p-6 mb-6  rounded-lg  flex md:flex-row flex-col justify-between '>
        {DELIVERY_INFO_STATUSES?.map((item, index) => {
          const isCompleted = index < IndexOfCurrentStage;
          return (
            <div
              key={item}
              className='flex md:flex-row flex-col md:items-center justify-center '
            >
              <div className='flex md:flex-col md:items-center flex-row'>
                <div className='flex flex-col items-center '>
                  {item !== currentStage ? (
                    <div className=' w-7 h-4 md:h-7 relative'>
                      <div
                        className={customTwMerge(
                          ' absolute w-4 h-4 rounded-2xl bg-PRIMARY top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
                          classNames({
                            'bg-LIGHT_BLUE_8':
                              !isCompleted && item !== currentStage
                          })
                        )}
                      ></div>
                    </div>
                  ) : (
                    <div className='w-7 h-7 rounded-2xl bg-sky-200 relative'>
                      <div className='absolute w-4 h-4 rounded-2xl  bg-PRIMARY top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 '></div>
                    </div>
                  )}
                  <div
                    className={customTwMerge(
                      'md:hidden h-10 bg-PRIMARY w-1',
                      classNames({
                        hidden: index === DELIVERY_INFO_STATUSES?.length - 1,
                        'bg-LIGHT_BLUE_8': !isCompleted
                      })
                    )}
                  ></div>
                </div>

                <p
                  className={customTwMerge(
                    'text-12px text-PRIMARY ml-3 md:mt-2',
                    classNames({
                      'mt-1': item === currentStage,
                      'text-LIGHT_BLUE_8': !isCompleted && item !== currentStage
                    })
                  )}
                >
                  {translate(DELIVERY_INFO_STATUSES_LABEL[item])}
                </p>
              </div>
              <div
                className={customTwMerge(
                  'md:block hidden border-t-1  h-0  border-PRIMARY w-[5.375rem] mx-2',
                  classNames({
                    'md:hidden': index === DELIVERY_INFO_STATUSES?.length - 1,
                    'border-LIGHT_BLUE_8': !isCompleted
                  })
                )}
              ></div>
            </div>
          );
        })}
      </div>

      <div className='flex flex-col space-y-2'>
        <div className='flex flex-row'>
          {currentStage === DELIVERY_INFO_STATUS.RECEIVED ? (
            <SuccessIcon className='text-SUCCESS_TEXT w-4 h-4 mr-1' />
          ) : (
            <ThreeDotsIcon className='text-YELLOW_2 w-4 h-4 mr-1' />
          )}
          ,
          <div
            className={customTwMerge(
              'text-YELLOW_2 text-17px',
              classNames({
                'text-SUCCESS_TEXT':
                  currentStage === DELIVERY_INFO_STATUS.RECEIVED
              })
            )}
          >
            {translate(DELIVERY_INFO_STATUSES_LABEL[currentStage])}
          </div>
        </div>
        <p className='text-white text-17px'>
          {translate(DELIVERY_INFO_MESSAGES[currentStage])}
        </p>
      </div>
    </div>
  );
};
