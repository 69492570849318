import { FC, ReactNode } from 'react';

import { CloseIcon } from 'assets/icons';
import { customTwMerge as twMerge } from 'utils';

type ModalHeaderProp = {
  title: ReactNode | string;
  onClose: () => void;
  titleClassName?: string;
};

const ModalHeader: FC<ModalHeaderProp> = ({
  title,
  onClose,
  titleClassName
}) => (
  <div className='flex justify-between items-center mb-6'>
    <h2
      className={twMerge(
        'text-xl text-DEFAULT_TEXT md:leading-6 leading-8 md:font-bold font-normal',
        titleClassName
      )}
    >
      {title}
    </h2>
    <button data-testid='close' onClick={onClose}>
      <CloseIcon className='h-3 w-3 ' />
    </button>
  </div>
);

export default ModalHeader;
