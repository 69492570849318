import { FC, useEffect, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { CloseIcon } from 'assets/icons';
import { Button, RhfRadioGroup, RHFTextArea } from 'components';
import { If } from 'components/Generics';
import { CHAT_WITH_ZENYUM, YES_NO } from 'constants/common';
import { TOPIC_CODE } from 'constants/InTreament';
import { TITLE, TRACK_EVENTS } from 'constants/segment';
import { useGetNextTicketSequenceId } from 'services/hooks/Inbox.hooks';
import {
  useCreateTicketFromTopicCode,
  useUpdateExternalDoctorReview
} from 'services/hooks/InTreatment.hooks';
import { GET_PATIENT_DATA } from 'services/query/patientslist';
import { loginedUserDetails, ticketList } from 'store/atoms';
import { ChatCustomData } from 'types/Inbox.types';
import {
  CreateTicketFromTopicCodeResponse,
  SuccessorType
} from 'types/InTreatmetn.types';
import { VersionDetails } from 'types/PatientList.types';
import { Option } from 'types/select';
import { segmentTrackEvent, translate } from 'utils';
import { filterOutRoles } from 'utils/common';
import { getDurationInMinutes } from 'utils/dateUtils';
import { showToast } from 'utils/toast/toast.util';
import { EOTValidationSchema } from 'validations/EOTValidations';

interface Props {
  treatmentId?: string;
  patientId?: string;
  onClose: () => void;
  clinicId: string;
  acceptedTreatmentPlanVersion?: VersionDetails;
  chatCustomData: ChatCustomData;
  opportunityId?: string;
  opportunityName?: string;
  onRefinement: () => void;
  treatmentPlanTypes: string[];
  additionalTreatment?: SuccessorType;
}

const REFINEMENT = 'REFINEMENT';

const scheduleAppointmentValueMapper: Record<string, boolean> = {
  [YES_NO.YES]: true,
  [YES_NO.NO]: false
};
type CloseEventType = 'SUBMITTED' | 'CANCELLED' | '';

type RejectionForm = {
  additionalTreatment: SuccessorType;
  scheduleAppointment: string;
  commentsForZenyum: string;
  treatmentPlanTypes: string[];
};

const RejectionFeedback: FC<Props> = ({
  treatmentId = '',
  patientId,
  onClose,
  clinicId,
  chatCustomData,
  acceptedTreatmentPlanVersion,
  opportunityId,
  opportunityName,
  onRefinement,
  treatmentPlanTypes = [],
  additionalTreatment
}) => {
  const setTicketsList = useSetRecoilState(ticketList);
  const formMethods = useForm<RejectionForm>({
    resolver: yupResolver(EOTValidationSchema),
    mode: 'onChange',
    defaultValues: { additionalTreatment, treatmentPlanTypes }
  });
  const { handleSubmit, getValues, watch, formState, reset } = formMethods;
  const watchAdditionalTreatment = watch('additionalTreatment');
  const watchScheduleAppoitment = watch('scheduleAppointment');
  const loggedInUserDetails = useRecoilValue(loginedUserDetails);
  const [closeEvent, setCloseEvent] = useState<CloseEventType>('');
  const componentMountTimeRef = useRef<moment.Moment | null>(null);

  const additionalTreatments: Option[] = [
    {
      label: translate('refinement.continue_with_refinement'),
      value: REFINEMENT
    },
    {
      label: translate('refinement.chat_with_zenyum'),
      value: CHAT_WITH_ZENYUM
    }
  ];
  const bookPatientForRescanOptions: Option[] = [
    {
      label: translate('yes.yes'),
      value: YES_NO.YES
    },
    {
      label: translate('no.no'),
      value: YES_NO.NO
    }
  ];

  useEffect(() => {
    watchAdditionalTreatment &&
      reset({
        additionalTreatment: watchAdditionalTreatment,
        commentsForZenyum: '',
        treatmentPlanTypes
      });
  }, [watchAdditionalTreatment, reset, treatmentPlanTypes]);

  const trackSegment = (event: string) => {
    const trackedData = {
      patient_id: patientId,
      opportunity_name: opportunityName,
      opportunity_id: opportunityId,
      role: filterOutRoles(loggedInUserDetails?.groups)
    };
    segmentTrackEvent(event, trackedData);
  };

  /** segment screen call */
  useEffect(() => {
    if (!componentMountTimeRef.current)
      componentMountTimeRef.current = moment();
    return () => {
      if (closeEvent) {
        const duration = getDurationInMinutes(
          componentMountTimeRef.current || moment()
        );
        const name =
          closeEvent === 'SUBMITTED'
            ? TITLE.EOT_CONTINUE_TREATMENT_POPUP_VIEWED_AND_SUBMITTED
            : TITLE.EOT_CONTINUE_TREATMENT_POPUP_VIEWED_AND_CANCELLED;
        window.analytics.page(name, { duration });
      }
    };
  }, [closeEvent]);

  const createZenChat = (isRefinement: boolean) => {
    const zenChatMsg = isRefinement
      ? 'Doctor at clinic requires patient to be booked in for an appointment to take refinement scans.'
      : 'Doctor at clinic has requested Chat with Zenyum.';
    createTicketFromTopicCode({
      variables: {
        input: {
          id: sequenceId?.getNextTicketSequenceId,
          patientId: patientId,
          topicCode: TOPIC_CODE.intreatmentDiscussion,
          sendMessageInput: {
            text: `${zenChatMsg} ${
              getValues('commentsForZenyum')
                ? getValues('commentsForZenyum')
                : ''
            }`,
            customAttributes: { isInternal: false }
          },
          treatmentId,
          tpvId: acceptedTreatmentPlanVersion?.id,
          tpId: acceptedTreatmentPlanVersion?.treatmentPlanId,
          clinicId,
          customData: chatCustomData
        },
        loginedUserId: loggedInUserDetails?.id
      }
    });
  };

  const config = {
    onCompleted: () => {
      setCloseEvent('SUBMITTED');
      const isRefinement = getValues('additionalTreatment') === REFINEMENT;
      const scheduleAppointment = getValues('scheduleAppointment');
      !isRefinement && createZenChat(isRefinement);
      scheduleAppointment === YES_NO.YES && createZenChat(isRefinement);
      scheduleAppointment === YES_NO.NO &&
        showToast(
          translate(
            'toast.patient_has_been_successfully_registered_for_refinement.'
          ),
          true
        );
      onClose();
      trackSegment(
        isRefinement
          ? TRACK_EVENTS.EOT_SEND_FOR_REFINEMENT
          : TRACK_EVENTS.EOT_CHECK_WITH_ZENYUM
      );
      trackSegment(
        scheduleAppointment === YES_NO.YES
          ? TRACK_EVENTS.EOT_REFINEMENT_RESCAN_APPOINTMENT_NEEDED
          : TRACK_EVENTS.EOT_REFINEMENT_RESCAN_APPOINTMENT_NOT_NEEDED
      );
      isRefinement && onRefinement();
    },
    refetchQueries: [GET_PATIENT_DATA]
  };

  const [updateExternalDoctorReview] = useUpdateExternalDoctorReview(config);
  const [getSequenceId, { data: sequenceId }] = useGetNextTicketSequenceId({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache'
  });
  const [createTicketFromTopicCode] = useCreateTicketFromTopicCode({
    onCompleted: (data: CreateTicketFromTopicCodeResponse) => {
      let message = '';
      if (getValues('additionalTreatment') === REFINEMENT)
        message = translate(
          'toast.patient_has_been_successfully_registered_for_refinement.'
        );
      if (getValues('additionalTreatment') === 'REFINEMENT_OC')
        message = translate(
          'toast.you_have_successfully_approved_a_new_prescription:_zenstages'
        );
      if (getValues('additionalTreatment') === CHAT_WITH_ZENYUM)
        message = translate('successMessage.ZenChatWasCreated');
      showToast(message, true);

      setTicketsList((oldTickets) => [
        data.createTicketFromTopicCode,
        ...oldTickets
      ]);
    }
  });

  const onSubmitHandler = () => {
    updateExternalDoctorReview({
      variables: {
        input: {
          treatmentId: treatmentId,
          type: getValues('additionalTreatment'),
          rescanAppointmentNeeded:
            scheduleAppointmentValueMapper[getValues('scheduleAppointment')],
          commentsForZenyum: getValues('commentsForZenyum') || undefined
        }
      }
    });
  };
  useEffect(() => {
    getSequenceId();
  }, [getSequenceId]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        className='pt-4 pb-6 px-6 md:p-6 md:w-785px xxs:w-343px'
      >
        <header className='flex md:mb-6 mb-4 items-center justify-between'>
          <h2 className='text-collapsible font-bold'>
            {translate('refinement.what_next_for_patient')}
          </h2>
          <button
            data-testid='close'
            onClick={() => {
              setCloseEvent('CANCELLED');
              onClose();
            }}
            type='button'
          >
            <CloseIcon className='h-3 w-3 ' />
          </button>
        </header>
        <section className='space-y-4'>
          <RhfRadioGroup
            title={translate(
              'do_you_wish_to_prescribe_an_additional_treatment_for_patient?.do_you_wish_to_prescribe_an_additional_treatment_for_patient?'
            )}
            options={additionalTreatments}
            name='additionalTreatment'
            radioClassName='border-RADIO_BORDER'
            className='mt-1'
            radioItemFont='text-17px'
            titleClassName='text-17px'
          />
          <If condition={watchAdditionalTreatment === REFINEMENT}>
            <RhfRadioGroup
              title={translate('refinement.book_appointment')}
              options={bookPatientForRescanOptions}
              name='scheduleAppointment'
              radioClassName='border-RADIO_BORDER'
              className='mt-1'
              radioItemFont='text-17px'
              titleClassName='text-17px'
            />
          </If>
          <If
            condition={
              watchAdditionalTreatment === CHAT_WITH_ZENYUM ||
              watchScheduleAppoitment === YES_NO.YES
            }
          >
            <div>
              <div className='mb-2 text-17px'>
                {`${
                  watchScheduleAppoitment === YES_NO.YES
                    ? translate('additional.Comments')
                    : translate('additional.Comments.Zenyum')
                }`}
              </div>
              <RHFTextArea
                autogrow={true}
                name='commentsForZenyum'
                inputProps={{
                  placeholder: translate('settings.please_type_here')
                }}
                className='h-14'
              />
            </div>
          </If>
        </section>
        <footer className='flex justify-end text-sm mt-[22px] md:mt-10 md:mb-0 mb-3'>
          <Button
            className='text-DEFAULT_TEXT text-center md:mr-8 mr-2 px-4 py-3'
            onClick={() => {
              setCloseEvent('CANCELLED');
              onClose();
            }}
          >
            {translate('cancel.cancel')}
          </Button>
          <Button
            className='bg-PRIMARY text-white text-center py-3 px-6 md:px-6 md:w-auto md:mb-0'
            type='submit'
            disabled={!formState?.isValid}
          >
            {translate('confirm.confirm')}
          </Button>
        </footer>
      </form>
    </FormProvider>
  );
};

export default RejectionFeedback;
