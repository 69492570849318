import { FC, ReactNode } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { ErrorIcon } from 'assets/icons';
import AnimateLabel from 'components/AnimateLabel/AnimateLabel';
import Select from 'components/Select/Select';
import SelectSearch from 'components/SelectSearch/SelectSearch';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { Option, SelectProps } from 'types/select';
import { translate } from 'utils';

import If from '../If/If';

interface RhfSelectProps {
  name: string;
  options: Option[];
  inputProps?: SelectProps;
  label?: string;
  selectedValue?: Option;
  isMandatory?: boolean;
  onChange?: (option: any) => void;
  isDisabled?: boolean;
  className?: {
    label?: string;
  };
  labelClassName?: string;
  menuMaxWidth?: string;
  placeholder?: ReactNode;
  isSearchable?: boolean;
  isFilter?: boolean;
  showError?: boolean;
  searchStyle?: string;
}

const RhfSelect: FC<RhfSelectProps> = ({
  name = '',
  options,
  label,
  inputProps,
  onChange,
  isDisabled,
  isFilter,
  isSearchable,
  className,
  menuMaxWidth,
  showError = true,
  searchStyle
}) => {
  const { animatedField, callback } = useFieldRefContext();
  const { control } = useFormContext();

  return (
    <div className='w-full' ref={callback?.(name)}>
      <section className='w-full flex flex-col'>
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState: { error } }) => {
            const isError = !!error?.message && !!inputProps?.showErrorTitle;
            return (
              <>
                {label && (
                  <label
                    htmlFor={name}
                    className={`md:text-sm xxs:text-xs xxs:mb-2 relative ${
                      isError ? 'text-ERROR' : className?.label
                    }`}
                  >
                    <>
                      <span>
                        {label}
                        {inputProps?.isMandatory && (
                          <span className='text-ERROR'>*</span>
                        )}
                      </span>
                      <AnimateLabel
                        className='text-ERROR md:text-17px text-base font-normal pb-1'
                        animate={isError && animatedField === name}
                      >
                        {label}
                      </AnimateLabel>
                    </>
                  </label>
                )}
                {isSearchable ? (
                  <SelectSearch
                    {...field}
                    searchStyle={searchStyle}
                    field={field}
                    isMulti={inputProps?.isMulti}
                    options={options}
                    selectedValue={field.value}
                    errorMessage={error?.message}
                    isFilter={isFilter}
                    isDisabled={isDisabled}
                    isDeactivated={!field.value}
                    menuMaxWidth={menuMaxWidth}
                    controlShouldRenderValue={
                      inputProps?.controlShouldRenderValue
                    }
                    onChange={onChange}
                    showResetSelectionButton={inputProps?.isMulti}
                    {...inputProps}
                  />
                ) : (
                  <Select
                    {...field}
                    options={options}
                    selectedValue={field.value}
                    errorMessage={error?.message}
                    onChange={
                      onChange ||
                      ((selected) => {
                        field.onChange({
                          target: { name: field.name, value: selected }
                        });
                      })
                    }
                    isFormSelect={true}
                    isDisabled={isDisabled}
                    menuMaxWidth={menuMaxWidth}
                    {...inputProps}
                  />
                )}

                <If condition={showError && !!error?.message}>
                  <p className='text-ERROR text-xs mt-2 flex '>
                    <ErrorIcon />
                    <span className='ml-2'>
                      {translate(error?.message || '')}
                    </span>
                  </p>
                </If>
              </>
            );
          }}
        />
      </section>
    </div>
  );
};

export default RhfSelect;
