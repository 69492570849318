import { FC, useEffect, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { BulkAddConfirmationBox, Button } from 'components';
import { If } from 'components/Generics';
import { INBOX_GROUP_NAME } from 'constants/inbox';
import { useGetGroups } from 'services/hooks/Inbox.hooks';
import { useChatBulkAdd } from 'services/hooks/Ticket.hooks';
import { loginedUserDetails, ticketList } from 'store/atoms';
import { inboxFilters, showAddMe } from 'store/atoms/inbox';
import { TicketPaginationInfo } from 'types/Inbox.types';
import { translate } from 'utils';
import { checkIsExternal, isDataExist } from 'utils/common';
import { useGetTickets } from 'utils/customHooks/inbox';
import { showToast } from 'utils/toast/toast.util';

interface Props {
  ticketPaginationInfo: React.MutableRefObject<TicketPaginationInfo>;
}

const ChatBulkAddButton: FC<Props> = ({ ticketPaginationInfo }) => {
  const ticketsList = useRecoilValue(ticketList);
  const inboxFilter = useRecoilValue(inboxFilters);
  const loginedUser = useRecoilValue(loginedUserDetails);
  const setShowAddMe = useSetRecoilState(showAddMe);

  const [showBulkAddConfirmation, setShowBulkAddConfirmation] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const { getTicketList } = useGetTickets();

  const [chatBulkAdd, { loading }] = useChatBulkAdd({
    onCompleted: () => {
      showToast(
        translate('inbox.bulk_add.success', { X: ticketsList.length }),
        true
      );
      setIsButtonDisabled(true);
      getTicketList().then((data) => {
        ticketPaginationInfo.current.totalCount =
          data.data?.getTickets.totalCount || 0;
      });
    },
    onError: () => {
      setIsButtonDisabled(false);
      setShowAddMe(true);
    }
  });
  const { data: groupDetails } = useGetGroups();

  const getGroupTeamId = (groupName = INBOX_GROUP_NAME.CHAT_MEMBER) =>
    groupDetails?.getGroups.find((group) => group.name === groupName)?.id;

  const handleBulkAdd = () => {
    //This will filter out all the ticket ids , logged user not part of.
    const notPartOfTicketsId = ticketsList.map((ticket) => ticket.id);
    const param = {
      ticketIds: notPartOfTicketsId,
      input: {
        groupId: getGroupTeamId(),
        userId: loginedUser?.id,
        teamId: getGroupTeamId(
          loginedUser?.groups.filter(
            (group) => group !== INBOX_GROUP_NAME.CHAT_OWNER
          )[0]
        ),
        isExternal: checkIsExternal(loginedUser?.groups || [])
      }
    };
    setShowAddMe(false);
    chatBulkAdd({ variables: { ...param } });
  };

  useEffect(() => {
    setIsButtonDisabled(false);
  }, [ticketsList]);

  return (
    <If
      condition={!!inboxFilter.viewTicketsWithoutMe && isDataExist(ticketsList)}
    >
      <div className='flex mb-4 px-2 sticky top-0 z-1'>
        <Button
          className='bg-DEFAULT_TEXT text-WHITE text-sm py-3 w-full '
          onClick={() => setShowBulkAddConfirmation(true)}
          disabled={loading || isButtonDisabled}
        >
          {translate('chat.join_all')}
        </Button>
      </div>
      <BulkAddConfirmationBox
        isOpen={showBulkAddConfirmation}
        setIsOpen={setShowBulkAddConfirmation}
        handleSubmit={handleBulkAdd}
      />
    </If>
  );
};

export default ChatBulkAddButton;
