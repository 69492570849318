import { FC } from 'react';

import { CloseIcon } from 'assets/icons';
import { translate } from 'utils';

interface Props {
  handleCloseButton?: () => void;
}

export const MobileFilterHeader: FC<Props> = ({ handleCloseButton }) => {
  return (
    <div className='text-20px flex justify-between bg-WHITE w-full'>
      <span>{translate('filters.filters')}</span>
      <button
        className='flex items-center justify-center'
        onClick={handleCloseButton}
        data-testid='close'
      >
        <CloseIcon />
      </button>
    </div>
  );
};
