import { gql } from '@apollo/client';

import { TREATMENT_STATUS_VALUES } from 'constants/common';
import { TREATMENT_STAGES } from 'constants/patientList';
import { PATIENT_LIST_LIMIT } from 'constants/settings';

export const GET_LONGEST_PENDING_TREATMENT = gql`
  query getLongestPendingTreatment {
    getTreatmentsByView(pagination: {
      offset: 0,
      sortOrder: DESC,
      sortBy: "pendingFor",
      limit: 2
    },
    view: "${TREATMENT_STAGES.TREATMENT_PLAN_REVIEW}",
    filter: {
      status: "${TREATMENT_STATUS_VALUES.PENDING_DOCTOR_ASSESSMENT}"
    
      }) {
      isSearching @client
      edges {
        id
        stage
        pendingFor
        patient {
          firstName
          lastName
          id
        }
        treatmentPlan {
          versions {
            id
            treatmentPlanId
            acceptanceStatus
          }
        }
      }
    }
  }
`;

export const GET_TREATMENT_LIST_FILTERS = gql`
  query getTreatmentListFilters(
    $input: FilterTreatmentPlanTypeInput
    $staffFilter: StaffFilter
    $type: [TreatmentType]
  ) {
    getAllTreatmentTypes(input: $input) {
      value: key
      label: value
    }
    getAllTreatmentStatuses(type: $type) {
      value: key
      label: value
    }
    getSupportedCountries {
      value: key
      label: value
    }
    getTreatmentActions(type: $type) {
      value: key
      label: value
    }
    getAllStaff(filter: $staffFilter) {
      staff {
        id
        firstName
        lastName
      }
    }
  }
`;
export const GET_ALL_TREATMENT_STATUSES = gql`
  query getAllTreatmentStatuses($type: [TreatmentType]) {
    getAllTreatmentStatuses(type: $type) {
      key
      value
    }
  }
`;

export const GET_DELIVERY_OBJECTS_COUNT = gql`
  query getDeliveryObjectsCount {
    getDeliveryObjectsCount {
      status
      count
    }
  }
`;
export const GET_DELIVERY_OBJECTS_LIST = gql`
  query getDeliveryObjectsList($status: String) {
    getDeliveryObjectsList(status: $status) {
      patientName
      treatmentId
    }
  }
`;

export const GET_TREATMENT_LIST_TABS = gql`
  query {
    getCountByStage {
      key
      value
      count
    }
  }
`;

export const GET_TREATMENT_PLAN_VERSIONS = gql`
  query getTreatmentPlanVersions($input: TreatmentPlanVersionQueryInput) {
    getTreatmentPlanVersions(input: $input) {
      treatmentPlanId
      id
    }
  }
`;

export const GET_COUNT_BY_VIEW = gql`
  query getCountByView($view: String!, $filter: TreatmentListFilterInput) {
    getCountByView(view: $view, filter: $filter) {
      value
      count
    }
  }
`;

export const GET_TREATMENT_LIST_VIEWS = gql`
  query getTreatmentListViews {
    getTreatmentListViews {
      aligner {
        key: name
        translationKey
        order
      }
      retainer {
        key: name
        translationKey
        order
      }
      whitening {
        key: name
        translationKey
        order
      }
    }
  }
`;
export const GET_TREATMENT_ACTIONS = gql`
  query getTreatmentActions {
    getTreatmentActions {
      key
      value
    }
  }
`;
const CORE_PATIENT_TREATMENT_DETAILS = gql`
  fragment CorePatientTreatmentDetails on Treatment {
    id
    stage
    medicalStaff {
      doctors {
        details {
          id
          firstName
          lastName
        }
        role
        primary
      }
    }
  }
`;
export const GET_TREATMENTS_BY_VIEW = gql`
${CORE_PATIENT_TREATMENT_DETAILS}
query getTreatmentsByView($offset: Int!, $sortOrder: SortOrder, $sortBy: String, $filter: TreatmentListFilterInput,$view:String!) {
  getTreatmentsByView(view:$view,pagination: {offset:$offset,sortBy: $sortBy, sortOrder: $sortOrder ,limit: ${PATIENT_LIST_LIMIT}}, filter: $filter) {
      isSearching @client
      edges {
       ...CorePatientTreatmentDetails
        actions {
          entityId
          entityName
          pendingFrom
          name
        }
        pendingFor
        patient {
          firstName
          lastName
          phone
          profilePicture
          id
        }
        clinic {
          id
          name
        }
        status
        type
        details {
          opportunityId
        }
      }
      pageInfo {
        hasNext
      }
    }
  }`;

export const IN_TREATMENT_GET_TREATMENTS = gql`
${CORE_PATIENT_TREATMENT_DETAILS}
query getTreatmentsByView($offset: Int!, $sortOrder: SortOrder, $sortBy: String, $filter: TreatmentListFilterInput,$view:String!) {
  getTreatmentsByView(view:$view,pagination: {offset:$offset,sortBy: $sortBy, sortOrder: $sortOrder ,limit: ${PATIENT_LIST_LIMIT}}, filter: $filter) {
      isSearching @client
      edges {
        ...CorePatientTreatmentDetails
        actions {
          entityId
          entityName
          pendingFrom
          name
        }
        patient {
          firstName
          lastName
          phone
          profilePicture
          id
          details{
            ageCategory
          }
        }
        treatmentSpecifications{
          treatmentPlanTypes
        }
        clinic {
          id
          name
          country
        }
        status
        type
        details {
          opportunityId
        }
      }
      pageInfo {
        hasNext
      }
    }
  }`;
export const TPV_REVIEW_GET_TREATMENTS = gql`
${CORE_PATIENT_TREATMENT_DETAILS}
query getTreatmentsByView($offset: Int!, $sortOrder: SortOrder, $sortBy: String, $filter: TreatmentListFilterInput,$view:String!) {
  getTreatmentsByView(view:$view,pagination: {offset:$offset,sortBy: $sortBy, sortOrder: $sortOrder ,limit: ${PATIENT_LIST_LIMIT}}, filter: $filter) {
      isSearching @client
      edges {
        ...CorePatientTreatmentDetails
        status
        treatmentPlan{
          statusUpdatedAt(status:"IN_PLANNING")
        }
        actions {
          entityId
          entityName
          pendingFrom
          name
        }
        pendingFor
        patient {
          firstName
          lastName
          phone
          profilePicture
          id
          details{
            ageCategory
          }
        }
        treatmentSpecifications{
          treatmentPlanTypes
        }
        clinic {
          id
          name
          country
        }
        type
        details {
          opportunityId
        }
      }
      pageInfo {
        hasNext
      }
    }
  }`;
export const INITIAL_TPV_GET_TREATMENTS = gql`
${CORE_PATIENT_TREATMENT_DETAILS}
query getTreatmentsByView($offset: Int!, $sortOrder: SortOrder, $sortBy: String, $filter: TreatmentListFilterInput,$view:String!) {
  getTreatmentsByView(view:$view,pagination: {offset:$offset,sortBy: $sortBy, sortOrder: $sortOrder ,limit: ${PATIENT_LIST_LIMIT}}, filter: $filter) {
      isSearching @client
      edges {
        ...CorePatientTreatmentDetails
        status
        patient {
          firstName
          lastName
          phone
          profilePicture
          id
        }
        actions {
          entityId
          entityName
          pendingFrom
          name
        }
        treatmentSpecifications{
          treatmentPlanTypes
        }
        clinic {
          id
          name
          country
        }
      }
      pageInfo {
        hasNext
      }
    }
  }`;
export const ALL_PATIENT_GET_TREATMENTS = gql`
${CORE_PATIENT_TREATMENT_DETAILS}
query getTreatmentsByView($offset: Int!, $sortOrder: SortOrder, $sortBy: String, $filter: TreatmentListFilterInput,$view:String!) {
  getTreatmentsByView(view:$view,pagination: {offset:$offset,sortBy: $sortBy, sortOrder: $sortOrder ,limit: ${PATIENT_LIST_LIMIT}}, filter: $filter) {
      isSearching @client
      edges {
        ...CorePatientTreatmentDetails
        isActiveTPVCategoryA
        actions {
          entityId
          entityName
          pendingFrom
          name
        }
        id
        pendingFor
        patient {
          firstName
          lastName
          phone
          profilePicture
          id
        }
        clinic {
          id
          name
        }
          treatmentAppointments(
          input: { appointmentType: "Consultation", limit: 1 }
        ) {
          id
          status
          bookingStartTime
          bookingEndTime
          appointmentType
          statusInfo {
            statusCode
          }
        }
        status
      }
      pageInfo {
        hasNext
      }
    }
  }`;

export const ALL_RETAINERS = gql`
${CORE_PATIENT_TREATMENT_DETAILS}
query getTreatmentsByView($offset: Int!, $sortOrder: SortOrder, $sortBy: String, $filter: TreatmentListFilterInput,$view:String!) {
  getTreatmentsByView(view:$view,pagination: {offset:$offset,sortBy: $sortBy, sortOrder: $sortOrder ,limit: ${PATIENT_LIST_LIMIT}}, filter: $filter) {
      isSearching @client
      edges {
        ...CorePatientTreatmentDetails
        patient {
          firstName
          lastName
          email
          phone
          profilePicture
          email
          id
        }
          actions {
          entityId
          entityName
          pendingFrom
          name
        }
        pendingFor
       treatmentPlan{
        alignerProductionInfo{
          productionRecord{
            expectedDate{
              from
              to
            }
          }
        }
        pickUpDetails{
          actualDate
        }
       }
        clinic {
          id
          name
        }
        status
      }
      pageInfo {
        hasNext
      }
    }
  }`;

export const getTreatMentByViewQuery = (view: string) => {
  switch (view) {
    case TREATMENT_STAGES.ALL_ALIGNERS:
    case TREATMENT_STAGES.PENDING_PRESCRIPTION:
    case TREATMENT_STAGES.TREATMENT_PLAN_REVIEW:
      return ALL_PATIENT_GET_TREATMENTS;
    case TREATMENT_STAGES.INITIAL_TPV:
    case TREATMENT_STAGES.RESIM_REQUESTS:
      return INITIAL_TPV_GET_TREATMENTS;
    case TREATMENT_STAGES.TPV_REVIEW:
      return TPV_REVIEW_GET_TREATMENTS;
    case TREATMENT_STAGES.IN_TREATMENT:
    case TREATMENT_STAGES.END_OF_TREATMENT_REVIEW:
      return IN_TREATMENT_GET_TREATMENTS;
    case TREATMENT_STAGES.ALL_WHITENING:
    case TREATMENT_STAGES.ALL_RETAINERS:
      return ALL_RETAINERS;
    default:
      return GET_TREATMENTS_BY_VIEW;
  }
};
