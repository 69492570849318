import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { LightBulbIcon } from 'assets/icons';
import { customTwMerge as twMerge } from 'utils';

type Props = {
  message?: string;
  classname?: string;
  isExternal?: boolean;
  iconClassname?: string;
  children?: ReactNode;
};

export const InfoCardView: FC<Props> = ({
  message = '',
  classname = '',
  isExternal,
  iconClassname = '',
  children
}) => {
  return (
    <div className={twMerge('p-6 rounded-xl bg-YELLOW_LIGHT flex', classname)}>
      {!isExternal && (
        <div>
          <LightBulbIcon className={twMerge('h-5 w-5', iconClassname)} />
        </div>
      )}
      <p
        className={classNames('md:text-sm text-xs leading-5', {
          'pl-2': !isExternal
        })}
      >
        {children ? children : message}
      </p>
    </div>
  );
};
