import { FC } from 'react';

import { AddMore, UploadSmallIcon } from 'assets/icons';
import Button from 'components/Button/Button';
import { translate } from 'utils';

type FileUploadModalFooterProps = {
  onUpload: () => void;
  onCancel: () => void;
  openFileDialog: () => void;
};

const FileUploadModalFooter: FC<FileUploadModalFooterProps> = ({
  onUpload,
  onCancel,
  openFileDialog
}) => (
  <>
    <Button onClick={openFileDialog}>
      <AddMore className='fill-current text-BORDER_GRAY h-6 w-6' />
    </Button>
    <div className='flex justify-end'>
      <Button onClick={onCancel} className='px-1'>
        <span className='text-sm leading-6 text-DEFAULT_TEXT'>
          {translate('cancel.cancel')}
        </span>
      </Button>
      <Button
        name='Upload'
        onClick={onUpload}
        className='md:flex bg-PRIMARY text-white text-center text-sm h-12 px-5 py-3 ml-6'
      >
        <>
          <UploadSmallIcon className='mt-1' />
          <span className='pt-0.5 ml-3 text-sm leading-6'>
            {translate('upload.upload')}
          </span>
        </>
      </Button>
    </div>
  </>
);

export default FileUploadModalFooter;
