import React, { useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import { DAYS_AND_TIMINGS } from 'constants/appointments';
import { selectedTreatmentByCpId } from 'store/atoms/afterSalesAppointmentAtom';
import { DoctorTimingsDetails } from 'types/AfterSalesAppointment.types';
import { timeRange } from 'utils/dateUtils';

const WorkingHoursDetails: React.FC = () => {
  const selectedActiveTreatmentByCpId = useRecoilValue(selectedTreatmentByCpId);

  const days = useMemo(() => {
    selectedActiveTreatmentByCpId?.doctorTimings?.forEach(
      (item: DoctorTimingsDetails) => {
        if (DAYS_AND_TIMINGS[item.day]) {
          DAYS_AND_TIMINGS[item.day].doctorTimings = item.availableHours || [];
        }
      }
    );
    return DAYS_AND_TIMINGS;
  }, [selectedActiveTreatmentByCpId]);

  return (
    <>
      {Object.values(days)?.map((day) => (
        <div className='flex flex-row mb-4 space-x-4' key={day.label}>
          <div className='min-w-91px mr-4 h-4 text-17px'>{day.label}</div>
          <div className='flex flex-wrap items-center text-base pt-1'>
            {day.doctorTimings.length > 0
              ? day.doctorTimings.map((divider, index) => (
                  <div
                    key={day.label}
                    className={`${
                      index % 2 === 0 ? 'border-r-2 last:border-0' : ''
                    } pt-1px  border-GRAY3 px-2 mb-2 leading-4`}
                  >
                    {timeRange(divider.startTime, divider.endTime)}
                  </div>
                ))
              : '-'}
          </div>
        </div>
      ))}
    </>
  );
};
export default WorkingHoursDetails;
