import { FC } from 'react';

import { Event } from 'types/Calendar.types';
import { formatTimeRange } from 'utils/dateUtils';

interface Props {
  event: Event;
}

export const EventComponent: FC<Props> = ({ event }) => {
  const { title, patient, start, end, color, textColor, id, onSelectEvent } =
    event;
  return (
    <button
      key={id}
      className='p-2 rounded w-full text-left'
      style={{
        background: color,
        color: textColor
      }}
      onClick={() => {
        onSelectEvent?.(event);
      }}
    >
      <div className='flex justify-between items-center mb-1 md:hidden'>
        <span className='text-sm font-bold'>{patient?.name}</span>
        <span className='text-10px'>{`${formatTimeRange(
          start
        )} - ${formatTimeRange(end)}`}</span>
      </div>
      <p className='text-10px md:hidden'>{title}</p>

      <div className='max-h-full max-w-full flex flex-col text-left overflow-hidden h-16 text-DEFAULT_TEXT p-1 xxs:hidden md:block'>
        <p className='text-xs'>{`${formatTimeRange(start)} - ${formatTimeRange(
          end
        )}`}</p>
        <p className='text-sm font-bold truncate'>{patient?.name}</p>
        <p className='text-xs truncate mt-1'>{title}</p>
      </div>
    </button>
  );
};
