import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { ErrorIcon } from 'assets/icons';

import UsersAutoSuggest from './PatientsAutoSuggest';

interface RhfUsersAutoSuggestProps {
  name: string;
  label?: string;
  inputStyle?: string;
  searchListClassName?: string;
  containerStyle?: string;
  placeholder?: string;
  defaultValue?: string;
  isHidden?: boolean;
  isMandatory?: boolean;
  searchListWrapperClassName?: string;
}

const RhfUsersAutoSuggest: FC<RhfUsersAutoSuggestProps> = ({
  name = '',
  label = '',
  defaultValue = '',
  isHidden = false,
  isMandatory = false,
  placeholder = '',
  searchListClassName = '',
  inputStyle,
  containerStyle,
  searchListWrapperClassName
}) => {
  const { control } = useFormContext();
  return (
    <div className='w-full'>
      <section className='w-full'>
        <label htmlFor={name}>
          {!isHidden && label}
          {isMandatory && <span className='text-ERROR pl-1'>*</span>}
        </label>
        <Controller
          name={name}
          render={({ field, fieldState: { error } }) => (
            <>
              <UsersAutoSuggest
                onSuggestionClick={(patient: any) => {
                  field.onChange({
                    target: { name: field.name, value: patient }
                  });
                }}
                placeholder={placeholder}
                onSuggestionCleared={() => {
                  field.onChange({
                    target: { name: field.name, value: null }
                  });
                }}
                inputStyle={inputStyle}
                searchListClassName={searchListClassName}
                containerStyle={`${containerStyle} ${
                  error?.message ? 'border-ERROR' : 'border-LIGHT_GRAY_2'
                }`}
                searchListWrapperClassName={searchListWrapperClassName}
              />
              {error?.message && (
                <p className='text-ERROR text-xs mt-2 flex '>
                  <ErrorIcon />
                  <span className='ml-2'>{error.message}</span>
                </p>
              )}
            </>
          )}
          control={control}
          defaultValue={defaultValue}
        />
      </section>
    </div>
  );
};

export default RhfUsersAutoSuggest;
