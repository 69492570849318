import { atom } from 'recoil';

import {
  AfterSalesAppointmentDetails,
  TreatmentByCpIdDetails
} from 'types/AfterSalesAppointment.types';
import {
  AfterSalesAppointmentFilterValues,
  AppointmentFilterValues
} from 'types/AppointmentList.types';
import { AutoSuggestions } from 'types/autoSuggest.types';
import { Option } from 'types/select';

import {
  ATOMS,
  defaultAfterSaleAppointmentListFilters,
  defaultAppointmentListFilters,
  defaultSelectedAfterSalesAppointment,
  defaultSelectedTreatmentByCpId
} from './constants';

export const appointmentListFilters = atom<AppointmentFilterValues>({
  key: ATOMS.APPOINTMENT_LIST_FILTER_ATOM,
  default: defaultAppointmentListFilters
});

export const afterSalesAppointmentListFilters =
  atom<AfterSalesAppointmentFilterValues>({
    key: ATOMS.AFTER_SALE_APPOINTMENT_LIST_FILTER,
    default: defaultAfterSaleAppointmentListFilters
  });

export const appointmentListSearchText = atom<AutoSuggestions | undefined>({
  key: ATOMS.APPOINTMENT_LIST_SEARCH_TEXT_ATOM,
  default: undefined
});

export const afterSaleAppointmentListSearchText = atom<
  AutoSuggestions | undefined
>({
  key: ATOMS.AFTER_SALE_APPOINTMENT_LIST_SEARCH_TEXT,
  default: undefined
});

export const isEditAfterSalesAppointmentOpen = atom<boolean>({
  key: ATOMS.IS_EDIT_AFTERSALES_APPOINTMENT_OPEN,
  default: false
});
export const selectedAfterSalesAppointment = atom<AfterSalesAppointmentDetails>(
  {
    key: ATOMS.SELECTED_AFTER_SALES_APPOINTMENT,
    default: defaultSelectedAfterSalesAppointment
  }
);
export const selectedTreatmentByCpId = atom<TreatmentByCpIdDetails>({
  key: ATOMS.SELECTED_AFTER_TREATMENT_BY_CP_ID,
  default: defaultSelectedTreatmentByCpId
});

export const appointmentClinic = atom<Option[]>({
  key: ATOMS.APPOINTMENT_CLINICS,
  default: []
});
