import { FC } from 'react';

import classNames from 'classnames';

import { DownArrowIcon } from 'assets/icons';

interface ActionFilterControllerProps {
  isExpanded: boolean;
  onClick(): void;
  label: string;
  count?: number;
}

const ActionFilterController: FC<ActionFilterControllerProps> = ({
  isExpanded,
  onClick,
  label,
  count
}) => {
  const getContent = () => {
    if (count === undefined) return label;
    return `${count}  |  ${label}`;
  };

  return (
    <button
      className={classNames(
        'text-14px py-1 px-2 rounded-md flex items-center',
        {
          'bg-DARK_BLUE_2 text-WHITE': isExpanded
        }
      )}
      onClick={onClick}
    >
      <div className='mr-2 min-w-[3rem]'>
        <p className='pre-wrap'> {getContent()} </p>
      </div>
      <DownArrowIcon
        className={classNames(
          'text-DEFAULT_TEXT',
          {
            'transform duration-500  rotate-180 text-white': isExpanded
          },
          {
            'transform duration-500  rotate-0': !isExpanded
          },
          {
            'text-white': isExpanded
          }
        )}
      />
    </button>
  );
};

export default ActionFilterController;
