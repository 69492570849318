import React, { FC } from 'react';

import cx from 'classnames';
import { Modal, ModalProps } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import CloseIcon from 'assets/icons/close.svg';
import './modalStyle.css';
import { MOBILE_SCREEN_WIDTH } from 'constants/common';
import { useViewport, customTwMerge as twMerge } from 'utils';

interface CustomModalProps {
  open: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  containerId?: string;
  noOverlay?: boolean;
  closeIconClassName?: string;
  disclaimerOverlay?: boolean;
  classNames?: Record<string, string>;
  center?: boolean;
  showCloseIcon?: boolean;
  closeOnOverlayClick?: boolean;
}

const CustomModal: FC<ModalProps & CustomModalProps> = ({
  open,
  onClose,
  children,
  containerId = 'modal-container',
  noOverlay = false,
  disclaimerOverlay = false,
  closeIconClassName = '',
  classNames,
  center = true,
  showCloseIcon = true,
  closeOnOverlayClick,
  ...rest
}) => {
  const { width } = useViewport();
  const isMobile = width < MOBILE_SCREEN_WIDTH;
  const containerViewId = isMobile ? 'mobile-modal-container' : containerId;
  const modalContainer = document.getElementById(containerViewId);
  return (
    <Modal
      open={open}
      onClose={onClose}
      showCloseIcon={showCloseIcon}
      center={center}
      container={modalContainer}
      closeOnOverlayClick={closeOnOverlayClick}
      closeIcon={
        <img
          src={CloseIcon}
          className={twMerge(closeIconClassName, 'h-3 w-3')}
          alt='close-icon'
        />
      }
      classNames={{
        ...classNames,
        root: twMerge(
          cx({
            disclaimerRoot: disclaimerOverlay,
            customRoot: !disclaimerOverlay
          }),
          classNames?.root
        ),
        modal: twMerge(
          cx({
            noOverlayModal: noOverlay,
            customModal: !noOverlay
          }),
          classNames?.modal
        ),
        overlay: twMerge(
          cx({
            noOverlay: noOverlay,
            disclaimerOverlay: disclaimerOverlay,
            customOverlay: !noOverlay && !disclaimerOverlay
          }),
          classNames?.overlay
        ),
        modalContainer: twMerge('containerModal', classNames?.modalContainer)
      }}
      {...rest}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
