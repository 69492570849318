import { FC, ReactNode } from 'react';

import { TAB_SCREEN_WIDTH } from 'constants/common';
import { customTwMerge, useViewport } from 'utils';

interface TabDrawerProps {
  children: ReactNode;
  outerContainerClassName?: string;
  innerContainerClassName?: string;
}

const TabDrawer: FC<TabDrawerProps> = ({
  children,
  outerContainerClassName,
  innerContainerClassName
}) => {
  const { width } = useViewport();
  const isMobile = width < TAB_SCREEN_WIDTH;
  return isMobile ? (
    <div
      className={customTwMerge(
        'fixed bg-WHITE inset-0 top-14 z-20 overflow-y-auto ',
        outerContainerClassName
      )}
    >
      <div className={customTwMerge(innerContainerClassName)}>{children}</div>
    </div>
  ) : (
    <></>
  );
};

export default TabDrawer;
