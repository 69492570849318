import * as yup from 'yup';

import { translate } from 'utils';

export const NewChatSchema = yup.object().shape({
  patient: yup.object().required(translate('description.required')).nullable(),
  topic: yup.object().required(translate('description.required')).nullable(),
  showTp: yup.bool(),
  tpv: yup
    .object()
    .nullable()
    .when('showTp', {
      is: (showTp: boolean) => {
        return showTp;
      },
      then: yup.object().required(translate('description.required')).nullable()
    }),
  markasurgent: yup.bool(),
  message: yup
    .object()
    .shape({
      text: yup.string().trim().required(translate('description.required'))
    })
    .nullable()
});

export const TreatmentChatSchema = yup.object().shape({
  feedback: yup.string().trim().required()
});
