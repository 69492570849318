export const API_END_POINT = process.env.REACT_APP_API_ENDPOINT;
export const GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT || '';
export const REACT_APP_WS_URL = process.env.REACT_APP_WS_URL || '';
export const TREATMENT_MONITOR_GRAPHQL_ENDPOINT =
  process.env.REACT_APP_TREATMENT_MONITOR_GRAPHQL_ENDPOINT;
export const FULL_CALENDAR_LICENSE_KEY =
  process.env.REACT_APP_FULL_CALENDAR_LICENSE_KEY;
export const CHAT_API_ENDPOINT = process.env.REACT_APP_CHAT_API_ENDPOINT;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
export const FIREBASE_PROJECT_ID = process.env.REACT_APP_FIREBASE_PROJECT_ID;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN;
export const REACT_APP_SALES_FORCE_LOGIN_REDIRECT =
  process.env.REACT_APP_SALES_FORCE_LOGIN_REDIRECT;
export const REACT_APP_CASE_LIBRARY_URL =
  process.env.REACT_APP_CASE_LIBRARY_URL;
export const REACT_APP_HELP_CENTER_URL = process.env.REACT_APP_HELP_CENTER_URL;
export const REACT_APP_HELP_CENTER_CLINIC_URL =
  process.env.REACT_APP_HELP_CENTER_URL_CLINIC;
export const REACT_APP_HELP_CENTER_URL_VI =
  process.env.REACT_APP_HELP_CENTER_URL_VI;
export const REACT_APP_IFRAME_HTTPS_PROXY =
  process.env?.REACT_APP_IFRAME_HTTPS_PROXY;
export const CLINIC_CLOUD_ENDPOINT =
  process.env?.REACT_APP_CLINIC_CLOUD_ENDPOINT;
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID || '';
export const FLIGHT_PATH_BASE_URL =
  process.env.REACT_APP_FLIGHT_PATH_BASE_URL || '';
