import { FC, useEffect } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import RhfCheckbox from 'components/CheckboxGroup/Checkbox/RhfCheckbox';
import { FormLabel } from 'components/FormLabel/FormLabel';
import { If } from 'components/Generics';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import { InfoCardView } from 'components/InfoCardView/InfoCardView';
import InfoToolTip from 'components/InfoToolTip';
import {
  MIDLINES,
  MIDLINES_LOWER_SHIFT_SUB_OPTIONS,
  MIDLINES_OPTIONS,
  MIDLINES_UPPER_SHIFT_SUB_OPTIONS
} from 'constants/TPVFormOptions';
import { translate } from 'utils';
import { getSelectedOption } from 'utils/tpvUtils';

type Props = {
  disabled?: boolean;
  name: string;
  applyDisableStyles?: boolean;
  isNewTemplatizedPrescription?: boolean;
  fieldObject?: {
    shiftUpper: string;
    shiftLower: string;
    shiftLowerPosition: string;
    shiftUpperPosition: string;
  };
};
export const MidlinesInput: FC<Props> = ({
  disabled = false,
  name = 'midlines',
  applyDisableStyles = false,
  isNewTemplatizedPrescription = false,
  fieldObject = {
    shiftUpper: 'shiftUpper',
    shiftLower: 'shiftLower',
    shiftLowerPosition: 'shiftLowerPosition',
    shiftUpperPosition: 'shiftUpperPosition'
  }
}) => {
  const {
    control,
    watch,
    setValue,
    trigger,
    formState: { isSubmitted }
  } = useFormContext();
  const watchedFields = [
    name,
    fieldObject.shiftUpper,
    fieldObject.shiftLower,
    fieldObject.shiftLowerPosition,
    fieldObject.shiftUpperPosition
  ];
  const [
    selectedMidline,
    midlineShiftUpper,
    midlineShftLower,
    shiftLowerPosition,
    shiftUpperPosition
  ] = watch(watchedFields);

  useEffect(() => {
    setValue('midlinesShifts', []);
  }, [selectedMidline, setValue]);

  useEffect(() => {
    if (isSubmitted) trigger(watchedFields);
  }, [
    selectedMidline,
    midlineShiftUpper,
    midlineShftLower,
    shiftLowerPosition,
    shiftUpperPosition,
    trigger,
    isSubmitted
  ]);

  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field, formState: { errors } }) => {
          const fieldError = !!(
            _.get(errors, name) ||
            _.get(errors, fieldObject.shiftUpper) ||
            _.get(errors, fieldObject.shiftLower) ||
            _.get(errors, fieldObject.shiftUpperPosition) ||
            _.get(errors, fieldObject.shiftLowerPosition)
          );
          return (
            <>
              <div className={classNames('flex space-x-4 mb-2')}>
                <FormLabel
                  label={translate('5.Midlines')}
                  className='mb-1 md:text-17px text-sm'
                  error={fieldError}
                  disabled={applyDisableStyles}
                  name={name}
                />
                <InfoToolTip
                  message={translate('midlines.message')}
                  className='w-5 h-5 text-PRIMARY'
                  toolTipStyle='w-45 md:w-full'
                />
              </div>

              <div
                className={classNames('md:w-325px h-48px', {
                  'md:mb-4': isNewTemplatizedPrescription
                })}
              >
                <RhfSelect
                  name={name}
                  options={MIDLINES_OPTIONS}
                  onChange={(data) => {
                    field?.onChange(data.value);
                  }}
                  isDisabled={disabled}
                  inputProps={{
                    controlShouldRenderValue: true,
                    selectedValue: getSelectedOption(
                      field?.value,
                      MIDLINES_OPTIONS
                    )
                  }}
                />
              </div>
              <If
                condition={
                  !isNewTemplatizedPrescription &&
                  !!getSelectedOption(field?.value, MIDLINES_OPTIONS)?.message
                }
              >
                <InfoCardView
                  classname='mt-4 mb-2'
                  message={
                    getSelectedOption(field?.value, MIDLINES_OPTIONS)?.message
                  }
                />
              </If>
              <If condition={selectedMidline === MIDLINES.IMPROVE}>
                <div className='text-sm'>
                  <div className='flex flex-col md:flex-row md:items-center'>
                    <RhfCheckbox
                      name={fieldObject.shiftUpper}
                      label={translate('Shift.Upper')}
                      disabled={disabled}
                    />
                    <If condition={midlineShiftUpper}>
                      <Controller
                        control={control}
                        name={fieldObject.shiftUpperPosition}
                        render={({ field: innerUpperField }) => (
                          <div className='md:w-325px ml-5'>
                            <RhfSelect
                              name={fieldObject.shiftUpperPosition}
                              options={MIDLINES_UPPER_SHIFT_SUB_OPTIONS}
                              onChange={(data) => {
                                innerUpperField?.onChange(data.value);
                              }}
                              isDisabled={disabled}
                              inputProps={{
                                controlShouldRenderValue: true,
                                selectedValue: getSelectedOption(
                                  innerUpperField?.value,
                                  MIDLINES_UPPER_SHIFT_SUB_OPTIONS
                                )
                              }}
                            />
                          </div>
                        )}
                      />
                    </If>
                  </div>
                  <div className='flex flex-col md:flex-row md:mt-5 md:items-center'>
                    <RhfCheckbox
                      name={fieldObject.shiftLower}
                      label={translate('Shift.Lower')}
                      disabled={disabled}
                    />
                    <If condition={midlineShftLower}>
                      <Controller
                        control={control}
                        name={fieldObject.shiftLowerPosition}
                        render={({ field: innerLowerField }) => (
                          <div className='md:w-325px ml-5'>
                            <RhfSelect
                              name={fieldObject.shiftLowerPosition}
                              options={MIDLINES_LOWER_SHIFT_SUB_OPTIONS}
                              onChange={(data) => {
                                innerLowerField?.onChange(data.value);
                              }}
                              isDisabled={disabled}
                              inputProps={{
                                controlShouldRenderValue: true,
                                selectedValue: getSelectedOption(
                                  innerLowerField?.value,
                                  MIDLINES_LOWER_SHIFT_SUB_OPTIONS
                                )
                              }}
                            />
                          </div>
                        )}
                      />
                    </If>
                  </div>
                </div>
              </If>
            </>
          );
        }}
      />
    </div>
  );
};
