import { FC, useEffect, useState } from 'react';

import { CameraIcon, ProfilePicIcon } from 'assets/icons';
import ProfileImagePlaceHolder from 'assets/images/Profile.png';
import { If } from 'components/Generics';

type AvataProp = {
  image?: string;
  className?: string;
  name?: string;
  onClick?: () => void;
};

const Avatar: FC<AvataProp> = ({ image, className, name, onClick }) => {
  const [error, setError] = useState(false);
  const [isHoveringImage, toggleHover] = useState(false);

  useEffect(() => {
    // Reset error on updating image
    setError(false);
  }, [image]);

  const getPlaceHolder = () => {
    if (name) {
      return (
        <div className='w-full h-full rounded-full bg-LIGHT_GRAY_2 flex items-center justify-center overflow-hidden p-1 text-sm leading-4'>
          {name}
        </div>
      );
    }

    return !onClick ? (
      <ProfilePicIcon className={`w-full h-full ${className} `} />
    ) : (
      <img src={ProfileImagePlaceHolder} alt='profile Pic' />
    );
  };

  const avatar =
    !!image && !error ? (
      <div
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
        className={`relative rounded-full overflow-hidden h-full w-full ${className}`}
      >
        <img
          loading='lazy'
          src={image}
          alt=''
          className='object-cover rounded-full w-full h-full'
          onError={() => {
            setError(true); // Set error if the image fails to load
          }}
        />
        <If condition={!!onClick && isHoveringImage}>
          <div className='bg-LOADER_BG absolute bottom-0 w-full h-1/3 flex items-center justify-center'>
            <CameraIcon className='w-3 h-3 md:h-6 md:w-6' />
          </div>
        </If>
      </div>
    ) : (
      getPlaceHolder()
    );
  return !onClick ? (
    avatar
  ) : (
    <button className={`w-full h-full ${className}`} onClick={onClick}>
      {avatar}
    </button>
  );
};

export default Avatar;
