import { gql } from '@apollo/client';

const GET_RECENT_TICKET_LIST = gql`
  query getRecentTickets($ticketLimit: Int, $messagesPerTicket: Int) {
    getRecentTickets(
      ticketLimit: $ticketLimit
      messagesPerTicket: $messagesPerTicket
    ) {
      unReadMessageCount
      tickets {
        id
        ticketMessages {
          id
          text
          created_at
          sender_details {
            first_name
            last_name
            designation
          }
          rich_text_info {
            blocks {
              rich_text_location_infos {
                offset
                length
                key
              }
              text
            }
            rich_text_details {
              type
              details {
                id
                name
                team
              }
            }
          }
        }
        patientDetails {
          contextValue
        }
        topicDetails {
          code
        }
      }
    }
  }
`;

export { GET_RECENT_TICKET_LIST };
