import { FC, useEffect, useMemo, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';

import { WarningIcon } from 'assets/icons';
import { FormLabel } from 'components';
import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import { DESIGN_LAB_OPTIONS } from 'constants/options';
import {
  useGetDesignLabs,
  useGetSupportedDesignLabs
} from 'services/hooks/TPVForm.hooks';
import { AlignerIPRInfo, TPVFormTypes } from 'types/TPVForm.types';
import { isDataExist, translate } from 'utils';

interface InputProps {
  tpLab?: string;
  manufacturerName?: string;
  disabled?: boolean;
  tplLabel: string;
  manufacturerLabel: string;
  country?: string;
  treatmentType: string;
  treatmentPlanType: string;
  treatmentId: string;
  externalDoctor: string;
  newFormType?: TPVFormTypes;
  formType?: string;
  isRefinement?: boolean;
  predecssorTPVData?: AlignerIPRInfo;
}

const DesignLabSelectInput: FC<InputProps> = ({
  tpLab,
  disabled,
  tplLabel,
  manufacturerLabel,
  country = '',
  treatmentType,
  treatmentPlanType,
  externalDoctor,
  newFormType,
  manufacturerName,
  formType,
  treatmentId,
  isRefinement = false,
  predecssorTPVData
}) => {
  const { data: designLabs } = useGetDesignLabs({
    variables: {
      input: {
        country,
        treatmentType,
        externalDoctor,
        treatmentPlanType
      }
    },
    skip: treatmentPlanType !== formType,
    fetchPolicy: 'no-cache'
  });

  const { data: designLabsForRefinement } = useGetSupportedDesignLabs({
    variables: {
      input: {
        country,
        treatmentType,
        externalDoctor,
        treatmentPlanType,
        treatmentId,
        predecessorTPV: predecssorTPVData?.id
      }
    },
    skip: !isRefinement,
    fetchPolicy: 'no-cache'
  });

  const {
    control,
    setValue,
    watch,
    formState: { errors }
  } = useFormContext();
  const watchTreatmentPlaningLab = watch('treatmentPlanningLab');
  const watchManufacturer = watch('manufacturer');
  const [isConfigInControlPage, setIsConfigInControlPage] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (designLabs?.getDesignLabs && isDataExist(designLabs?.getDesignLabs)) {
      let checked = false;
      setIsConfigInControlPage(false);
      designLabs?.getDesignLabs.map(
        ({ manufacturer, treatmentPlanningLab, isDefault }) => {
          if (
            isDefault &&
            isFirstRender &&
            !isRefinement &&
            treatmentPlanType === formType &&
            (newFormType || (!newFormType && !tpLab && !manufacturerName))
          ) {
            setValue('treatmentPlanningLab', {
              value: treatmentPlanningLab,
              label: treatmentPlanningLab
            });
            setValue('manufacturer', {
              value: manufacturer,
              label: manufacturer
            });
            setIsFirstRender(false);
          }
          if (
            watchTreatmentPlaningLab?.value === treatmentPlanningLab &&
            watchManufacturer?.value === manufacturer &&
            !checked
          ) {
            checked = true;
            setIsConfigInControlPage(true);
          }
        }
      );
    }
  }, [designLabs?.getDesignLabs, watchManufacturer, watchTreatmentPlaningLab]);

  const DESIGN_LAB_OPTION_LIST = useMemo(() => {
    if (isRefinement) {
      const designLabOption = DESIGN_LAB_OPTIONS.filter((option) =>
        designLabsForRefinement?.getSupportedDesignLabs?.designLabs?.includes(
          option.value
        )
      );
      if (
        (newFormType || (!newFormType && !tpLab && !manufacturerName)) &&
        predecssorTPVData?.treatmentPlanningLab &&
        isDataExist(designLabsForRefinement?.getSupportedDesignLabs?.designLabs)
      ) {
        setValue('treatmentPlanningLab', {
          value: designLabsForRefinement?.getSupportedDesignLabs?.designLabs[0],
          label: designLabsForRefinement?.getSupportedDesignLabs?.designLabs[0]
        });
      }
      if (
        (newFormType || (!newFormType && !tpLab && !manufacturerName)) &&
        predecssorTPVData?.manufacturer &&
        isDataExist(
          designLabsForRefinement?.getSupportedDesignLabs?.manufacturers
        )
      ) {
        setValue('manufacturer', {
          value:
            designLabsForRefinement?.getSupportedDesignLabs?.manufacturers[0],
          label:
            designLabsForRefinement?.getSupportedDesignLabs?.manufacturers[0]
        });
      }
      return designLabOption;
    }
    return DESIGN_LAB_OPTIONS;
  }, [isRefinement, designLabsForRefinement]);

  return (
    <div className='relative space-y-5'>
      <Controller
        control={control}
        name={'treatmentPlanningLab'}
        render={({ field }) => (
          <>
            <FormLabel
              label={tplLabel}
              error={!!errors?.['treatmentPlanningLab']}
              name={'treatmentPlanningLab'}
            />
            <div className='w-325px pb-4 mt-5 space-y-7'>
              <RhfSelect
                name={'treatmentPlanningLab'}
                options={DESIGN_LAB_OPTION_LIST}
                onChange={(data) => {
                  field.onChange(data);
                }}
                isDisabled={disabled || DESIGN_LAB_OPTION_LIST.length === 1}
                inputProps={{
                  controlShouldRenderValue: true
                }}
              />
            </div>
          </>
        )}
      />
      <div className='flex flex-col space-y-2'>
        <Controller
          control={control}
          name={'manufacturer'}
          render={({ field }) => (
            <>
              <FormLabel
                label={manufacturerLabel}
                error={!!errors?.['manufacturer']}
                name={'manufacturer'}
              />
              <div className='w-325px pb-4 mt-5 space-y-7'>
                <RhfSelect
                  name={'manufacturer'}
                  options={DESIGN_LAB_OPTIONS}
                  onChange={(data) => {
                    field.onChange(data);
                  }}
                  isDisabled={disabled}
                  inputProps={{
                    controlShouldRenderValue: true
                  }}
                />
              </div>
            </>
          )}
        />
        {!!watchManufacturer &&
          !!watchTreatmentPlaningLab &&
          !isConfigInControlPage && (
            <div className={'p-6 rounded-xl bg-YELLOW_LIGHT flex mt-4 mb-2'}>
              <div>
                <WarningIcon className='h-5 w-5 text-DARK_YELLOW' />
              </div>
              <p className='md:text-sm text-xs leading-5 pl-2'>
                {translate(
                  "Manufacturer and treatment planning lab combination not found in this country's control page settings"
                )}
              </p>
            </div>
          )}
      </div>
    </div>
  );
};

export default DesignLabSelectInput;
