import { ChangeEvent, FC, useCallback, useState } from 'react';

import classNames from 'classnames';
import { debounce } from 'lodash';
import { useSetRecoilState } from 'recoil';

import { Search as SearchIcon } from 'assets/icons';
import ToggleCheckbox from 'components/ToggleCheckbox/ToggleCheckbox';
import { TICKET_SEARCH_TARGET } from 'constants/inbox';
import { inboxFilters } from 'store/atoms/inbox';
import { translate } from 'utils';
import { useClickAway } from 'utils/customHooks/useClickAway.customHooks';
import { useToggle } from 'utils/customHooks/useToggle';

import { InboxTooltip } from './index';

interface Props {
  isExpanded: boolean;
  disableCollapse?: boolean;
  toggleExpansion?: (value?: boolean) => void;
  allowAutoFocus?: boolean;
}

const DEBOUNCE_TIME = 1000;

const TicketSearch: FC<Props> = ({
  isExpanded,
  disableCollapse = false,
  toggleExpansion,
  allowAutoFocus: autoFocus = true
}) => {
  const [searchValue, setSearchValue] = useState('');
  const setInboxFilter = useSetRecoilState(inboxFilters);
  const [isMessageSearch, toggleMessageSearch] = useToggle(false);

  const setGlobalChatFilterSearch = (
    searchTerm: string,
    searchTarget: TICKET_SEARCH_TARGET
  ) => {
    setInboxFilter((prev) => ({
      ...prev,
      searchTerm: searchTerm.trim(),
      searchTarget
    }));
  };

  const debouncedSearch = useCallback(
    debounce(setGlobalChatFilterSearch, DEBOUNCE_TIME),
    []
  );

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    const searchText = event.target.value;
    setSearchValue(searchText);
    const searchTarget = isMessageSearch
      ? TICKET_SEARCH_TARGET.MESSAGE
      : TICKET_SEARCH_TARGET.TICKET;
    debouncedSearch(searchText, searchTarget);
  };

  const handleClickAway = () => {
    if (disableCollapse) return;

    toggleExpansion?.();
  };

  const containerRef = useClickAway(handleClickAway);
  const notExpanded = !isExpanded;
  if (notExpanded) {
    return (
      <InboxTooltip content='INBOX.SEARCH.PLACEHOLDER'>
        <button onClick={() => toggleExpansion?.()}>
          <SearchIcon
            className={classNames({
              'text-PRIMARY': !!searchValue
            })}
          />
        </button>
      </InboxTooltip>
    );
  }

  const getToggleTooltipMessage = () => {
    return isMessageSearch
      ? 'inbox.search.messageTooltip'
      : 'inbox.search.ticketTooltip';
  };

  const handleTargetToggle = () => {
    toggleMessageSearch();
    const isSearchValueAbsent = searchValue.length === 0;
    if (isSearchValueAbsent) return;
    const prevIsMessageSearch = isMessageSearch;
    const updatedMessageSearch = !prevIsMessageSearch;
    const searchTarget = updatedMessageSearch
      ? TICKET_SEARCH_TARGET.MESSAGE
      : TICKET_SEARCH_TARGET.TICKET;
    debouncedSearch(searchValue, searchTarget);
  };

  return (
    <div
      ref={containerRef}
      className='px-4 py-2 flex-1 rounded-full border-1 border-PRIMARY flex h-8 items-center'
    >
      <InboxTooltip content='INBOX.SEARCH.PLACEHOLDER'>
        <SearchIcon className='h-4 w-4 text-PRIMARY mr-4' />
      </InboxTooltip>
      <input
        type='text'
        maxLength={500}
        className='text-xs placeholder:text-CHINESE_WHITE flex-1 mr-1'
        placeholder={translate('search.main')}
        value={searchValue}
        onChange={handleSearchChange}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
      />
      <InboxTooltip content={getToggleTooltipMessage()}>
        <ToggleCheckbox
          checked={isMessageSearch}
          onChange={handleTargetToggle}
        />
      </InboxTooltip>
    </div>
  );
};

export default TicketSearch;
