import { FC } from 'react';

import { ApolloError } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { Logo } from 'assets/icons';
import Button from 'components/Button/Button';
import RhfInput from 'components/Generics/Rhf/RhfInput';
import { pagePaths } from 'config/pages';
import { TITLE, TRACK_EVENTS } from 'constants/segment';
import { generatePasswordResetLink } from 'services/hooks/Auth.hooks';
import { useSegmentPage } from 'services/hooks/Segment.hooks';
import {
  ForgotPasswordType,
  GeneratePasswordResetLink
} from 'types/Login.types';
import { getCurrentLanguage, translate } from 'utils/locale';
import { showToast } from 'utils/toast/toast.util';
import { GenerateResetLinkSchema } from 'validations/LoginValidation';

const ForgotPassword: FC = () => {
  const formMethods = useForm<ForgotPasswordType>({
    mode: 'onChange',
    delayError: 5000,
    defaultValues: { email: '' },
    resolver: yupResolver(GenerateResetLinkSchema)
  });
  const {
    handleSubmit,
    // control,
    formState: { isDirty }
  } = formMethods;

  useSegmentPage({
    path: pagePaths.forgotPassword,
    title: TITLE.FORGOT_PASSWORD
  });
  /**
   * @description
   * configration for handling onComplete and onError after requesting for new link
   */
  const config = {
    onCompleted: (data: GeneratePasswordResetLink) => {
      if (data.generatePasswordResetLink) {
        showToast(
          translate(
            'login_screen_-_forget_password_-_email_input.email_sent._look_out_for_an_email_from_smile@zenyum.com'
          ),
          true
        );
        window?.analytics.track(TRACK_EVENTS.RESET_PASSWORD_LINK_SENT);
      }
    },
    onError: (error: ApolloError) => {
      showToast(error.message, false);
      window?.analytics.track(TRACK_EVENTS.RESET_PASSWORD_LINK_SENT_ERROR, {
        error: error.message
      });
    }
  };

  /**
   * @description
   * Mutation query for handling reneration link
   */
  const [generatePasswordResetLinkHandler] = generatePasswordResetLink(config);

  /**
   * @description
   * Request for handling generate link submission
   * @param data email id for resetting password
   */
  const onSubmitHandler = (data: ForgotPasswordType) => {
    const param = {
      variables: {
        email: data.email,
        locale: getCurrentLanguage()
      }
    };
    generatePasswordResetLinkHandler(param);
  };

  return (
    <section className='md:w-520 xxs:w-full flex flex-col'>
      <Logo className='text-PRIMARY md:w-52px md:h-8 w-39px h-6' />
      <h3 className='md:text-40px md:leading-56px font-bold text-DEFAULT_TEXT md:mt-6 mt-4 mb-6 md:mb-8 text-3xl'>
        {translate('login_screen_-_forget_password.forgot_your_password?')}
      </h3>
      <FormProvider {...formMethods}>
        <form
          onSubmit={handleSubmit(onSubmitHandler)}
          className='flex flex-col'
          data-testid='form'
        >
          <div className='w-full'>
            <RhfInput
              showAnimated={false}
              label={translate('common.email')}
              inputProps={{
                placeholder: translate('Enter your email address'),
                type: 'text',
                showBorderError: true,
                showErrorTitle: true
              }}
              name='email'
              className='w-full md:h-12 h-12 bg-white px-4 py-3'
            />
          </div>
          <Button
            type='submit'
            className='bg-PRIMARY text-WHITE w-full h-12 justify-center md:mt-12 mt-10 text-14px md:leading-22px leading-14px'
            disabled={!isDirty}
          >
            {translate('button_sign_in.send_reset_link')}
          </Button>
          <p className='text-center px-16 mt-7 xxs:text-sm text-DARK_GRAY md:text-DEFAULT_TEXT'>
            {translate('create_new_account.any_problems')}
          </p>
        </form>
      </FormProvider>
    </section>
  );
};

export default ForgotPassword;
