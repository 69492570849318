import { FC, useState } from 'react';

import {
  AddLeave,
  Button,
  LeaveStatus,
  LeaveTable,
  Loader,
  UpdateLogin,
  UserInfoForm
} from 'components';
import { CanAccess } from 'hoc/can-access/CanAccess';
import { useGetStaffDetails } from 'services/hooks/UsersList.hooks';
import { UserPermissionList } from 'types/UsersList';
import { translate } from 'utils';
import { getFullName } from 'utils/common';
import useScrollToElement from 'utils/customHooks/useScrollToElement';
import { getCurrentDateIso } from 'utils/dateUtils';

interface Props {
  userId: string;
  isInternalUsersFiltered?: boolean;
  userRoleGroup: string;
}

const UserInfo: FC<Props> = ({
  userId,
  userRoleGroup,
  isInternalUsersFiltered
}) => {
  const [showAddLeave, setShowAddLeave] = useState(false);
  const { ref: addLeaveContainerRef, scrollIntoView: scrollLeaveToTop } =
    useScrollToElement({
      block: 'start',
      behavior: 'smooth'
    });

  const { data: { getStaff: userDetails } = {}, loading } = useGetStaffDetails({
    variables: {
      id: userId,
      date: getCurrentDateIso()
    }
  });

  const { firstName, lastName } = userDetails || {};

  const toggleAddLeave = () => {
    setShowAddLeave((prevState) => !prevState);
  };

  const title = getFullName({
    firstName,
    lastName
  });

  const handleCalendarOpen = () => {
    setTimeout(scrollLeaveToTop, 0);
  };

  const permissionList: UserPermissionList = isInternalUsersFiltered
    ? {
        leaveView: 'leave:view:internal:users',
        leaveManage: 'leave:manage:internal:users',
        userEdit: 'user:details:edit:internal:users',
        loginEdit: 'edit:login:internal:users',
        dailyCapacityEdit: 'user:details:dailycapacity:edit:internal:users'
      }
    : {
        leaveView: 'leave:view:external:users',
        leaveManage: 'leave:manage:external:users',
        userEdit: 'user:details:edit:external:users',
        loginEdit: 'edit:login:external:users',
        dailyCapacityEdit: 'user:details:dailycapacity:edit:external:users'
      };

  if (loading || !userDetails) {
    return <Loader />;
  }

  return (
    <section className='bg-LIGHT_BLUE_66 w-full h-full p-10 flex flex-col '>
      <h2 className='text-2xl text-DEFAULT_TEXT mb-7'>{title}</h2>
      <div className='border-b-1 border-WHITE pb-7'>
        <UserInfoForm
          userDetails={userDetails}
          permissionList={permissionList}
          userRoleGroup={userRoleGroup}
          isInternalUsersFiltered={isInternalUsersFiltered}
        />
      </div>
      {!showAddLeave && (
        <div className='flex justify-between my-6'>
          <CanAccess
            permission={permissionList.leaveView}
            yes={
              <div className='flex items-center'>
                <p className='text-BLACK text-sm mr-2.5 font-bold'>
                  {`${translate('status.status')} :`}
                </p>
                <LeaveStatus
                  isAvailable={userDetails.available}
                  showDate={true}
                  startDate={userDetails?.activeLeave?.startDate}
                  endDate={userDetails?.activeLeave?.endDate}
                  timeZone={userDetails?.activeLeave?.timezone}
                />
              </div>
            }
          />
          <CanAccess
            permission={permissionList.leaveManage}
            yes={
              <Button
                className='bg-PRIMARY text-WHITE px-4 py-2'
                onClick={toggleAddLeave}
              >
                {translate('leave.add_leaves')}
              </Button>
            }
          />
        </div>
      )}
      {showAddLeave && (
        <div className='pt-6' ref={addLeaveContainerRef}>
          <AddLeave
            userId={userDetails.id}
            toggleAddLeave={toggleAddLeave}
            onCalendarOpen={handleCalendarOpen}
          />
        </div>
      )}
      <CanAccess
        permission={permissionList.leaveView}
        yes={
          <div className='mt-4'>
            <LeaveTable
              userId={userDetails.id}
              leaveManagePermission={permissionList.leaveManage}
            />
          </div>
        }
      />
      <CanAccess
        permission={permissionList.loginEdit}
        yes={
          <div className='mt-7.5 pt-7 border-t-1 border-WHITE'>
            <UpdateLogin userDetails={userDetails} />
          </div>
        }
      />
    </section>
  );
};

export default UserInfo;
