import { useApolloClient } from '@apollo/client';
import { matchPath, useNavigate } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { pagePaths } from 'config/pages';
import { useSelectUserClinic } from 'services/hooks/Auth.hooks';
import {
  patientListFilters,
  patientListSearchText,
  selectedTicket
} from 'store/atoms';
import { defaultFilterValues } from 'store/atoms/constants';
import { syncTabs } from 'utils/common';
import { translate } from 'utils/locale';
import { showToast } from 'utils/toast/toast.util';

const invalidPagePaths = [
  { pathname: pagePaths.patientPage, redirectTo: pagePaths.patientList },
  { pathname: pagePaths.referPatient, redirectTo: pagePaths.referrals },
  { pathname: pagePaths.general, redirectTo: pagePaths.settings },
  { pathname: pagePaths.users, redirectTo: pagePaths.settings },
  { pathname: pagePaths.branches, redirectTo: pagePaths.settings },
  { pathname: pagePaths.security, redirectTo: pagePaths.settings }
];

export const useChangeClinic = () => {
  const navigate = useNavigate();
  const resetSelectedTicket = useResetRecoilState(selectedTicket);
  const setPatientFilters = useSetRecoilState(patientListFilters);
  const setPatientSearch = useSetRecoilState(patientListSearchText);
  const client = useApolloClient();
  return useSelectUserClinic({
    onCompleted: () => {
      syncTabs();
      resetSelectedTicket();
      setPatientFilters(defaultFilterValues);
      setPatientSearch(undefined);
      client?.refetchQueries({
        include: 'active',
        onQueryUpdated(observableQuery) {
          // when clinic changes, user won't be able to view
          // previously selected ticket
          if (observableQuery.queryName == 'getTicketById') {
            return false;
          }
          return true;
        }
      });
      const invalidPath = invalidPagePaths?.find((path) =>
        matchPath(path?.pathname, location?.pathname)
      );
      if (invalidPath) {
        navigate(invalidPath.redirectTo);
      }
    },
    onError: () => {
      showToast(translate('toast.error_switching_clinics'), false);
    }
  });
};
