import { FC, ReactElement, ReactNode } from 'react';

import { Button } from 'components';
import { If } from 'components/Generics';
import ResponsiveModal from 'components/Modals/ResponsiveModal/ResponsiveModal';
import { PERMISSIONS } from 'constants/Permissions';
import { CanAccess } from 'hoc/can-access/CanAccess';
import { translate, useViewport } from 'utils';

interface Props {
  label?: string;
  value?: string | ReactElement;
  input?: ReactNode;
  onClickSave?: () => void;
  onClickCancel?: () => void;
  onClickEdit?: () => void;
  hideEditButton?: boolean;
  disableEditButton?: boolean;
  editable?: boolean;
  editButtonLabel?: string;
  permissions?: PERMISSIONS;
  disableSaveButton?: boolean;
  editPopupTitle?: string;
}

export const DetailsInfoCard: FC<Props> = ({
  label = '',
  value,
  onClickEdit,
  input,
  onClickSave,
  onClickCancel,
  hideEditButton = false,
  disableEditButton = false,
  editable = false,
  editButtonLabel = translate('edit.edit'),
  permissions,
  disableSaveButton,
  editPopupTitle = `Edit ${label}`
}) => {
  const { width } = useViewport();
  const getValue = () => {
    if (!editable) return value || '-';
    const isDesktop = width > 768;
    return isDesktop ? input : value;
  };

  return (
    <>
      <div className='md:text-17px xxs:text-sm'>
        <div className='flex items-center justify-between md:my-4 xxs:my-2'>
          <p className='font-semibold'>{label}</p>
          <CanAccess
            permission={permissions}
            yes={
              <If condition={!hideEditButton}>
                {editable ? (
                  width > 768 && (
                    <Button
                      className='text-PRIMARY'
                      onClick={onClickCancel}
                      type='button'
                    >
                      {translate('cancel.cancel')}
                    </Button>
                  )
                ) : (
                  <button
                    disabled={disableEditButton}
                    className='text-PRIMARY disabled:text-DISABLED'
                    onClick={onClickEdit}
                    type='button'
                  >
                    {editButtonLabel}
                  </button>
                )}
              </If>
            }
          />
        </div>

        <div className='md:mb-6 xxs:mb-4'>{getValue()}</div>
        {width > 768 ? (
          <>
            <If condition={editable && !!onClickSave}>
              <Button
                onClick={onClickSave}
                type='submit'
                className='bg-PRIMARY text-WHITE px-6 py-3 text-sm md:mb-6 xxs:mb-4'
                disabled={disableSaveButton}
              >
                {translate('button_rounded_(short).save')}
              </Button>
            </If>
          </>
        ) : (
          <div>
            <ResponsiveModal
              title={editPopupTitle}
              isOpen={editable && width < 768}
              onClose={onClickCancel || (() => null)}
              className='leading-8'
            >
              <div className='mb-6'>{input}</div>
              <If condition={editable}>
                <Button
                  onClick={onClickSave}
                  className='bg-PRIMARY text-WHITE w-full px-6 py-3 text-sm'
                  disabled={disableSaveButton}
                >
                  {translate('button_rounded_(short).save')}
                </Button>
              </If>
            </ResponsiveModal>
          </div>
        )}
      </div>
    </>
  );
};
