import { FC, useCallback, useMemo, useState } from 'react';

import { debounce } from 'lodash';
import { useRecoilValue } from 'recoil';

import { If } from 'components/Generics';
import InboxFilter from 'components/InboxFilter/InboxFilter';
import {
  CHAT_TOPIC_INITIATOR,
  INBOX_DROPDOWN_KEY,
  INBOX_DROPDOWN_OPTIONS
} from 'constants/inbox';
import { USER_ROLE_GROUP } from 'constants/userDetails';
import { useGetAllDoctorsByClinics } from 'services/hooks/Branches.hooks';
import {
  useGetAllChatTopic,
  useGetAllStaffs
} from 'services/hooks/Inbox.hooks';
import { useGetAllClinics } from 'services/hooks/Referrals.hooks';
import { useGetCountries } from 'services/hooks/UsersList.hooks';
import { checkFireBaseConnection } from 'store/atoms/authAtom';
import { inboxFilters as inboxFiltersAtom } from 'store/atoms/inbox';
import { useIsExternalUser } from 'store/selectors/auth';
import { ChatTopics, InboxFilterOptions } from 'types/Inbox.types';
import { Option } from 'types/select';
import { translate } from 'utils';
import { getFullName, getTopicName } from 'utils/common';
import { getPlaceHolder } from 'utils/inbox';

import AdvanceSearchButton from './AdvanceSearchButton';
import FilterPanelPopup from './FilterPanelPopup';
import { useFilterContext } from '../filterContext';

interface FilterPanelProps {
  onClose(): void;
}

const DEBOUNCE_TIME = 500;

const FilterPanel: FC<FilterPanelProps> = ({ onClose }) => {
  const { selectedFilters, resetDropdownFilters } = useFilterContext();

  const isExternalUser = useRecoilValue(useIsExternalUser);
  const filterDropdown = ({ showOnlyInternal = false }: InboxFilterOptions) =>
    !(isExternalUser && showOnlyInternal);
  const dropdownOptions = INBOX_DROPDOWN_OPTIONS.filter(filterDropdown);

  const { data: countries } = useGetCountries();

  const inboxFilters = useRecoilValue(inboxFiltersAtom);
  const [searchKey, setSearchKey] = useState('');
  const {
    data: {
      getAllStaff: { staff = [], totalCount: staffTotalCount = 0 } = {}
    } = {},
    loading: staffLoading,
    fetchMore
  } = useGetAllStaffs({
    variables: {
      filter: {
        searchKey: searchKey,
        searchOnlyOnName: true,
        userType: USER_ROLE_GROUP.INTERNAL
      },
      paginationInfo: {
        limit: 50,
        offset: 0
      }
    },
    fetchPolicy: 'network-only',
    skip: !inboxFilters
  });
  const { data: topicList } = useGetAllChatTopic({
    variables: {
      userRole: [
        isExternalUser
          ? CHAT_TOPIC_INITIATOR.EXTERNAL_DOCTOR
          : CHAT_TOPIC_INITIATOR.INTERNAL_ZENYUM_STAKEHOLDER
      ],
      patientTreatmentStage: [],
      showDisabledTopics: true,
      listAllTopics: isExternalUser ? undefined : true
    }
  });
  const formatTopicList = (getAllChatTopics: ChatTopics[]) => {
    return getAllChatTopics.map((option) => {
      return {
        label: getTopicName(option.code),
        value: option.id,
        isOld: !option.isActive
      };
    });
  };

  const topicOptions = useMemo(() => {
    return formatTopicList(topicList?.getAllChatTopics || []);
  }, [topicList]);

  const staffOptions = useMemo(() => {
    return staff.map(({ firstName, lastName, id }) => ({
      label: getFullName({ firstName, lastName }),
      value: id
    }));
  }, [staff]);

  const getClinicIds = () => {
    const selectedClinics: Option[] =
      selectedFilters?.[INBOX_DROPDOWN_KEY.Clinic] || [];
    return selectedClinics.map((option) => option.value);
  };

  const getSelectedCountries = () => {
    const selectedCountries: Option[] = selectedFilters?.['country'] || [];
    return selectedCountries.map((country) => country.value);
  };

  const {
    data: doctorsList = { getAllDoctorsByClinics: [] },
    loading: isFetchingDoctors
  } = useGetAllDoctorsByClinics({
    variables: {
      filter: {
        clinicIds: getClinicIds()
      }
    },
    fetchPolicy: 'no-cache'
  });

  const doctorOptions = useMemo(
    () =>
      doctorsList.getAllDoctorsByClinics?.map((doctor: any) => ({
        value: doctor?.id,
        label: getFullName({
          firstName: doctor?.firstName,
          lastName: doctor?.lastName
        })
      })),
    [doctorsList]
  );

  const {
    data: clinicData = { getAllClinics: [] },
    loading: isFetchingClinics
  } = useGetAllClinics({
    variables: {
      filter: {
        excludeHeadClinic: true,
        countries: getSelectedCountries()
      }
    }
  });

  const getOptions = (key: string, options: Option[]) => {
    if (key === 'country') {
      return countries?.getSupportedCountries || [];
    }
    if (key === 'ownedBy') {
      return staffOptions;
    }
    if (key === INBOX_DROPDOWN_KEY.Doctor) {
      return doctorOptions;
    }
    if (key === INBOX_DROPDOWN_KEY.Clinic) {
      return clinicData?.getAllClinics || [];
    }
    if (key === INBOX_DROPDOWN_KEY.Topic) {
      return topicOptions;
    }
    return options;
  };

  const searchOwnedBy = (value: string) => {
    setSearchKey(value);
  };

  const debouncedSearchOwnedBy = useCallback(
    debounce(searchOwnedBy, DEBOUNCE_TIME),
    []
  );

  const getCustomSearch = (key: string) => {
    if (key === 'ownedBy') {
      return debouncedSearchOwnedBy;
    }
  };

  const getIsCustomSearch = (key: string) => {
    if (key === 'ownedBy') {
      return !!searchKey;
    }
    return false;
  };

  const fetchMoreStaff = () => {
    fetchMore({
      variables: {
        paginationInfo: {
          limit: 50,
          offset: staff.length || 0
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return Object.assign({}, prev, {
          getAllStaff: {
            staff: [
              ...(prev.getAllStaff.staff || []),
              ...(fetchMoreResult.getAllStaff.staff || [])
            ],
            totalCount: fetchMoreResult.getAllStaff.totalCount
          }
        });
      }
    });
  };

  const getInfiniteScrollProps = (key: string) => {
    if (key === 'ownedBy') {
      return {
        next: fetchMoreStaff,
        hasMore: staff.length < staffTotalCount,
        dataLength: staff.length
      };
    }
  };

  const getIsOptionLoading = (key: string) => {
    if (key === 'ownedBy') {
      return staffLoading;
    }
    if (key === INBOX_DROPDOWN_KEY.Doctor) {
      return isFetchingDoctors;
    }
    if (key === INBOX_DROPDOWN_KEY.Clinic) {
      return isFetchingClinics;
    }
  };

  const checkIsFirebaseConnected = useRecoilValue(checkFireBaseConnection);

  const getIsClearDisabled = () => {
    let setDisabled = true;
    for (const key in selectedFilters) {
      if (selectedFilters[key]?.length > 0) {
        setDisabled = false;
        break;
      }
    }
    return setDisabled || !checkIsFirebaseConnected;
  };

  return (
    <>
      <FilterPanelPopup>
        <div className='p-4 h-14 flex justify-between items-center border-b-1 border-LINE_COLOR'>
          <p>{translate('filters.filters')}</p>
          <button
            disabled={getIsClearDisabled()}
            className='text-14px text-PRIMARY disabled:text-DISABLED'
            onClick={resetDropdownFilters}
          >
            {translate('clear_all_filters_button.clear_all_filters')}
          </button>
        </div>
        {dropdownOptions.map(({ key, placeholder, options, enableSearch }) => (
          <InboxFilter
            key={key}
            optionKey={key}
            title={getPlaceHolder(placeholder)}
            options={getOptions(key, options)}
            enableSearch={enableSearch}
            customSearch={getCustomSearch(key)}
            isOptionLoading={getIsOptionLoading(key)}
            otherOptionInfiniteScrollProps={getInfiniteScrollProps(key)}
            isCustomSearch={getIsCustomSearch(key)}
          />
        ))}
        <If condition={!isExternalUser}>
          <AdvanceSearchButton />
        </If>
      </FilterPanelPopup>
      <div
        className='fixed inset-0 z-1 bg-LOADER_BG md:bg-transparent'
        role='button'
        tabIndex={0}
        onKeyDown={onClose}
        onClick={onClose}
        id='chat-filter-background'
      />
    </>
  );
};

export default FilterPanel;
