import React, { ReactNode } from 'react';

import cx from 'classnames';

import { ProfilePicIcon } from 'assets/icons';
import { customTwMerge, translate } from 'utils';
interface Props {
  className?: string;
  name: string | ReactNode;
  image?: string;
  isDeactivated?: boolean;
  labelClassName?: string;
}

const NameCard: React.FC<Props> = ({
  className = '',
  name,
  image = '',
  isDeactivated = false,
  labelClassName
}) => {
  return (
    <div className={`${className} flex `}>
      <div className='flex'>
        <div className='flex items-center'>
          {image ? (
            <img
              loading='lazy'
              src={image}
              alt=''
              className='w-7 h-7 align-middle object-cover rounded-full max-w-none'
            />
          ) : (
            <ProfilePicIcon />
          )}
        </div>
        <div
          className={customTwMerge(
            cx('text-DEFAULT_TEXT break-all', {
              'px-3 pt-3 pb-1px': isDeactivated,
              'p-3 ': !isDeactivated
            }),
            labelClassName
          )}
        >
          {name}
        </div>
      </div>
      {isDeactivated && (
        <span className='italic pl-10 text-DISABLED'>
          [{translate('user.deactivated')}]
        </span>
      )}
    </div>
  );
};

export default NameCard;
