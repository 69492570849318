export const FINANCE_EVENTS = {
  FITTING: 'FITTING',
  REIMBURSEMENTS: 'REIMBURSEMENT',
  REFERRALS: 'REFERRAL',
  RETAINERS: 'RETAINER',
  CONSULTATIONS: 'CONSULTATION'
};

export const FINANCE_EVENTS_LABELS: Record<string, string> = {
  fittings: 'event.fittings',
  reimbursements: 'event.reimbursements',
  referrals: 'event.referrals',
  retainers: 'event.retainers',
  consultations: 'event.consultations'
};

export const EventsTabsList = [
  {
    title: FINANCE_EVENTS_LABELS.fittings,
    type: FINANCE_EVENTS.FITTING
  },
  {
    title: FINANCE_EVENTS_LABELS.reimbursements,
    type: FINANCE_EVENTS.REIMBURSEMENTS
  },
  {
    title: FINANCE_EVENTS_LABELS.referrals,
    type: FINANCE_EVENTS.REFERRALS
  },
  {
    title: FINANCE_EVENTS_LABELS.retainers,
    type: FINANCE_EVENTS.RETAINERS
  },
  {
    title: FINANCE_EVENTS_LABELS.consultations,
    type: FINANCE_EVENTS.CONSULTATIONS
  }
];
export const DEFAULT_FINANCE_SUMMARY: Record<string, string> = {
  consultations: '0',
  fittings: '0',
  referrals: '0',
  retainers: '0',
  reimbursements: '0'
};
export const FINANCE_SUMMARY_STATUS = {
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  PENDING_CONFIRMATION: 'PENDING_CONFIRMATION',
  INVOICE_COMPLETED: 'INVOICE_COMPLETED',
  PENDING_PROPOSAL_SYNC: 'PENDING_PROPOSAL_SYNC',
  APPROVED: 'APPROVED'
};
export const ACTION_BUTTON_HIDDEN_STATUSES = [
  FINANCE_SUMMARY_STATUS.PENDING_CONFIRMATION,
  FINANCE_SUMMARY_STATUS.APPROVED,
  FINANCE_SUMMARY_STATUS.INVOICE_COMPLETED
];

export const PRODUCT_TYPES = {
  ZENYUM_CLEAR: 'ZenyumClear™',
  ZENYUM_CLEAR_PLUS: 'ZenyumClear™ Plus',
  ZENYUM_CLEAR_EXTRA: 'ZenyumClear™ Extra',
  REFINEMENT: 'Refinement',
  RETAINER: 'Retainer'
};

export const productTypeList = [
  {
    label: 'product_type.zenyum_clear',
    value: PRODUCT_TYPES.ZENYUM_CLEAR
  },
  {
    label: 'product_type.zenyum_clear_plus',
    value: PRODUCT_TYPES.ZENYUM_CLEAR_PLUS
  },
  {
    label: 'product_type.zenyum_extra',
    value: PRODUCT_TYPES.ZENYUM_CLEAR_EXTRA
  },
  {
    label: 'product_type.refinement',
    value: PRODUCT_TYPES.REFINEMENT
  },
  {
    label: 'product_type.retainer',
    value: PRODUCT_TYPES.RETAINER
  }
];

export const APPROVE_BUTTON_VISIBLE_STATUSES = [
  FINANCE_SUMMARY_STATUS.PENDING_APPROVAL,
  FINANCE_SUMMARY_STATUS.PENDING_CONFIRMATION
];

export const EDIT_BUTTON_VISIBLE_STATUSES = [
  FINANCE_SUMMARY_STATUS.PENDING_APPROVAL,
  FINANCE_SUMMARY_STATUS.PENDING_CONFIRMATION
];
