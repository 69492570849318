import { FC, MouseEvent } from 'react';

import { DeleteIcon } from 'assets/icons';

type FileDeleteProp = {
  className?: string;
  disabled?: boolean;
  onDeleteClicked: (event: MouseEvent<HTMLButtonElement>) => void | undefined;
};

const FileDelete: FC<FileDeleteProp> = ({
  className = '',
  disabled = false,
  onDeleteClicked
}) => (
  <button
    disabled={disabled}
    className={`flex justify-center items-center rounded-full h-5 w-5 md:h-6 md:w-6 bg-FILE_DELETE ${className}`}
    onClick={onDeleteClicked}
    type='button'
    title='delete-icon'
  >
    <DeleteIcon title='delete' />
  </button>
);

export default FileDelete;
