import { useMemo, useRef, useState } from 'react';

import { TreatmentSourceType } from 'components/TreatmentProgressMenu/TreatmentMenuConfig';
import { EXTENSIONS } from 'constants/common';
import {
  PATIENT_FILE_CLASSIFICATIONS,
  RESUBMISSION_PENDING
} from 'constants/patientCard';
import {
  CLASSIFICATION_LABEL,
  patientFileMobileCameraDefault,
  PatientFilesMobileCamera,
  VIDEO_CONSTRAINTS
} from 'constants/treatmentFiles';
import {
  submitResubmissionFiles,
  useGetPatientDocument,
  useGetResubmissionFiles
} from 'services/hooks/PatientList.hooks';
import { GET_PATIENT_DATA } from 'services/query/patientslist';
import { Classification, FilesData } from 'types/Files.types';
import { FileClassification } from 'types/PendingPrescription.types';
import { Option } from 'types/select';
import { TreatmentDocumentType } from 'types/TreatmentPlanning.types';
import {
  getDocumentsForSelectedStage,
  hasMinimumFilesWithExtension,
  isDataExist
} from 'utils/common';
import { classifyFiles } from 'utils/fileUpload';

interface Props {
  treatmentId?: string;
}
const useReuploadHook = ({ treatmentId }: Props) => {
  const [errors, setErrors] =
    useState<
      Record<
        | TreatmentDocumentType.PHOTO
        | TreatmentDocumentType.X_RAY
        | TreatmentDocumentType.SCAN,
        Classification[]
      >
    >();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const submissionCount = useRef<number>(0);
  const { data: resubmissionFiles, loading } = useGetResubmissionFiles({
    variables: {
      id: treatmentId
    },
    skip: !treatmentId
  });

  const { data: treatmentDocuments } = useGetPatientDocument({
    variables: {
      id: treatmentId
    },
    skip: !treatmentId,
    fetchPolicy: 'network-only'
  });

  const [resubmitFiles] = submitResubmissionFiles({
    refetchQueries: [GET_PATIENT_DATA]
  });
  const getMissingClassificationMobile = (
    treatmentDocumentType:
      | TreatmentDocumentType.PHOTO
      | TreatmentDocumentType.X_RAY
  ) => {
    let patientMissingFileMobileCameraDefault: PatientFilesMobileCamera = {
      currentStage: 0,
      isCameraReady: false,
      videoConstraints: VIDEO_CONSTRAINTS,
      files: []
    };

    if (treatmentDocumentType === TreatmentDocumentType.PHOTO) {
      const missingPhotosClassificationMobile =
        patientFileMobileCameraDefault.files?.filter((classificationItem) => {
          return resubmissionFiles?.getTreatmentById.treatmentPlan[0]?.resubmissionFiles?.includes(
            classificationItem?.classification
          );
        });
      patientMissingFileMobileCameraDefault = {
        currentStage: patientFileMobileCameraDefault?.currentStage,
        isCameraReady: patientFileMobileCameraDefault?.isCameraReady,
        videoConstraints: patientFileMobileCameraDefault?.videoConstraints,
        files: missingPhotosClassificationMobile
      };
    }

    return patientMissingFileMobileCameraDefault;
  };

  const getMissingClassification = (
    treatmentDocumentType:
      | TreatmentDocumentType.PHOTO
      | TreatmentDocumentType.X_RAY
  ) => {
    const classificationList: Classification[] = [];
    if (treatmentDocumentType) {
      const missingClassification = PATIENT_FILE_CLASSIFICATIONS[
        treatmentDocumentType
      ]?.filter((classification: any) => {
        if (
          resubmissionFiles?.getTreatmentById.treatmentPlan[0]?.resubmissionFiles?.includes(
            classification.key
          )
        ) {
          classificationList.push(classification.key);
          return true;
        }
        return false;
      });
      return {
        missingClassification,
        classificationList
      };
    }
    return {
      missingClassification: [],
      classificationList: []
    };
  };

  const getMissingUploadedFiles = (
    treatmentDocumentType:
      | TreatmentDocumentType.PHOTO
      | TreatmentDocumentType.X_RAY
      | TreatmentDocumentType.SCAN
  ) => {
    const files = getDocumentsForSelectedStage(
      treatmentDocuments?.getTreatmentDocumentByTreatmentId || [],
      TreatmentSourceType.PRESCRIPTIONS
    );
    return (
      files.filter(
        (file) =>
          file.key === treatmentDocumentType &&
          file.resubmissionStatus === RESUBMISSION_PENDING
      ) || []
    );
  };
  const getUpdatedMissingList = (
    treatmentDocumentType:
      | TreatmentDocumentType.PHOTO
      | TreatmentDocumentType.X_RAY
  ) => {
    const uploadedMissingFileList = getMissingUploadedFiles(
      treatmentDocumentType
    );
    if (uploadedMissingFileList?.length === 0)
      return getMissingClassification(treatmentDocumentType)
        ?.missingClassification;
    else
      return getMissingClassification(
        treatmentDocumentType
      )?.missingClassification.filter((classificationItem) =>
        uploadedMissingFileList.every(
          (missingFileData) =>
            missingFileData.classification !== classificationItem.key
        )
      );
  };
  const missingFilesInfo = useMemo(() => {
    const remainingPhotoList = getUpdatedMissingList(
      TreatmentDocumentType.PHOTO
    );
    const remainingXrayList = getUpdatedMissingList(
      TreatmentDocumentType.X_RAY
    );
    const missingUploadedScanList = getMissingUploadedFiles(
      TreatmentDocumentType.SCAN
    );
    const missingScanList =
      resubmissionFiles?.getTreatmentById.treatmentPlan[0]?.resubmissionFiles?.includes(
        Classification.STL
      )
        ? [Classification.STL]
        : [];

    const isPhotoUploadIncomplete = remainingPhotoList.length !== 0;
    const isXrayUploadIncomplete = remainingXrayList.length !== 0;
    const isScanUploadIncomplete =
      !!missingScanList?.length &&
      !hasMinimumFilesWithExtension(missingUploadedScanList, EXTENSIONS.STL, 2);
    setIsButtonDisabled(
      isPhotoUploadIncomplete ||
        isXrayUploadIncomplete ||
        isScanUploadIncomplete
    );

    return {
      [TreatmentDocumentType.PHOTO]: {
        files: getMissingUploadedFiles(TreatmentDocumentType.PHOTO),
        formName: 'treatmentFiles.photo',
        classification: getMissingClassification(TreatmentDocumentType.PHOTO)
          ?.missingClassification,
        remainingMissingList: remainingPhotoList
      },
      [TreatmentDocumentType.X_RAY]: {
        files: getMissingUploadedFiles(TreatmentDocumentType.X_RAY),
        formName: 'treatmentFiles.xray',
        classification: getMissingClassification(TreatmentDocumentType.X_RAY)
          ?.missingClassification,
        remainingMissingList: remainingXrayList
      },
      [TreatmentDocumentType.SCAN]: {
        files: missingUploadedScanList,
        formName: 'treatmentFiles.scan',
        classification: missingScanList
      }
    };
  }, [resubmissionFiles?.getTreatmentById, treatmentDocuments]);

  const getMissingFilesForFileType = (
    treatmentDocumentType: TreatmentDocumentType
  ) => {
    switch (treatmentDocumentType) {
      case TreatmentDocumentType.PHOTO:
        return missingFilesInfo[TreatmentDocumentType.PHOTO];
      case TreatmentDocumentType.X_RAY:
        return missingFilesInfo[TreatmentDocumentType.X_RAY];

      default:
        return {
          files: [],
          formName: '',
          classification: []
        };
    }
  };

  const finalResubmission = (successCallBack: () => void) => {
    resubmitFiles({
      variables: {
        treatmentId: treatmentId
      },
      onCompleted: () => {
        successCallBack();
      }
    });
  };

  const getMissingClassifications = (
    files: FilesData[],
    classifications: Classification[]
  ) => {
    return classifications.reduce(
      (accumulator: Classification[], classification) => {
        const index = files.findIndex(
          (file: any) => classification === file.classification
        );
        const notAvailable = index == -1;
        if (notAvailable) {
          accumulator.push(classification);
        }
        return accumulator;
      },
      []
    );
  };

  const validateFiles = () => {
    const errorFields: Record<
      | TreatmentDocumentType.PHOTO
      | TreatmentDocumentType.X_RAY
      | TreatmentDocumentType.SCAN,
      Classification[]
    > = {
      [TreatmentDocumentType.PHOTO]: [],
      [TreatmentDocumentType.X_RAY]: [],
      [TreatmentDocumentType.SCAN]: []
    };

    if (isDataExist(missingFilesInfo.PHOTO.classification)) {
      const requiredClassifications = missingFilesInfo.PHOTO.classification.map(
        (classificationDetails) => classificationDetails.key
      );
      errorFields[TreatmentDocumentType.PHOTO] = getMissingClassifications(
        missingFilesInfo.PHOTO.files,
        requiredClassifications
      );
    }
    if (isDataExist(missingFilesInfo.X_RAY.classification)) {
      const requiredClassifications = missingFilesInfo.X_RAY.classification.map(
        (classificationDetails) => classificationDetails.key
      );
      errorFields[TreatmentDocumentType.X_RAY] = getMissingClassifications(
        missingFilesInfo.X_RAY.files,
        requiredClassifications
      );
    }
    if (isDataExist(missingFilesInfo.SCAN.classification)) {
      if (
        !hasMinimumFilesWithExtension(
          missingFilesInfo.SCAN.files,
          EXTENSIONS.STL,
          2
        )
      ) {
        errorFields[TreatmentDocumentType.SCAN] = [Classification.STL];
      }
    }
    setErrors(errorFields);
    return errorFields;
  };

  const classifiedFileMap = useMemo(() => {
    if (submissionCount.current) {
      validateFiles();
    }
    return {
      [TreatmentDocumentType.PHOTO]: classifyFiles(
        missingFilesInfo?.PHOTO?.files,
        missingFilesInfo?.PHOTO?.classification
      ),
      [TreatmentDocumentType.X_RAY]: classifyFiles(
        missingFilesInfo?.X_RAY?.files,
        missingFilesInfo?.PHOTO?.classification
      ),
      [TreatmentDocumentType.SCAN]: classifyFiles(
        missingFilesInfo?.SCAN?.files,
        []
      )
    };
  }, [missingFilesInfo]);

  const getMissingFilesOption = (fileDetails: FileClassification) => {
    const optionList: Option[] = fileDetails?.classification?.map(
      (classification) => {
        return {
          label: CLASSIFICATION_LABEL[classification?.key],
          value: classification?.key
        };
      }
    );
    if (optionList.length === 0) return [];
    return [
      ...optionList,
      {
        label: CLASSIFICATION_LABEL[Classification.OTHER],
        value: Classification.OTHER
      }
    ];
  };

  return {
    classifiedFileMap,
    missingFilesInfo,
    getMissingFilesForFileType,
    finalResubmission,
    validateFiles,
    getMissingUploadedFiles,
    loading,
    errors,
    resubmissionFiles,
    getUpdatedMissingList,
    getMissingFilesOption,
    submissionCount,
    getMissingClassification,
    isButtonDisabled,
    getMissingClassificationMobile
  };
};

export default useReuploadHook;
