import { FC, ReactNode, useState } from 'react';

import cx from 'classnames';
import classNames from 'classnames';
import { addMonths, format, set as setDate } from 'date-fns';
import { CalendarProps, DateRange, RangeKeyDict } from 'react-date-range';
import OutsideClickHandler from 'react-outside-click-handler';

import {
  DownArrowIcon,
  CalendarDownArrowIcon,
  CalendarArrowIcon
} from 'assets/icons';
import { DEFAULT_YEAR_START, YEAR_OPTION_LIMIT } from 'constants/DatePicker';
import { DateRange as Range } from 'types/Appointment.types';
import { translate, customTwMerge as twMerge } from 'utils';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './style.css';

export interface DateRangePickerProps {
  placeholder: string | ReactNode;
  range: Range;
  onChange: (range: Range) => void;
  onClose?: () => void;
  onClear: () => void;
  onSave?: () => void;
  onOpen?: () => void;
  showDropdownArrow?: boolean;
  buttonClass?: string;
  minDate?: Date;
  showYearSelection?: boolean;
}

const handlePrevNavigation = (
  isYearSelectOpen: boolean,
  optionStartYear: number,
  setOptionStartYear: React.Dispatch<React.SetStateAction<number>>,
  currentFocusedDate: Date,
  changeShownDate: (value: Date | number | string) => void,
  minDate?: Date
) => {
  if (isYearSelectOpen) {
    const isInitialYearView = minDate?.getFullYear() === optionStartYear;
    if (isInitialYearView) return;
    setOptionStartYear((prev) => prev - YEAR_OPTION_LIMIT);
  } else {
    const prevMonth = addMonths(currentFocusedDate, -1);
    changeShownDate(prevMonth);
  }
};

const handleNextNavigation = (
  isYearSelectOpen: boolean,
  setOptionStartYear: React.Dispatch<React.SetStateAction<number>>,
  currentFocusedDate: Date,
  changeShownDate: (value: Date | number | string) => void
) => {
  if (isYearSelectOpen) {
    setOptionStartYear((prev) => prev + YEAR_OPTION_LIMIT);
  } else {
    const nextMonth = addMonths(currentFocusedDate, 1);
    changeShownDate(nextMonth);
  }
};

const DateRangePicker: FC<DateRangePickerProps> = ({
  range,
  placeholder,
  onChange,
  onClear,
  onClose,
  onOpen,
  minDate,
  onSave,
  showDropdownArrow = true,
  buttonClass = '',
  showYearSelection = true
}) => {
  const [isCalendarOpen, setCalendarOpen] = useState(false);
  const [isYearSelectOpen, setYearSelectOpen] = useState(false);
  const [optionStartYear, setOptionStartYear] = useState(
    minDate?.getFullYear() ?? DEFAULT_YEAR_START
  );

  const toggleCalendarOpen = () => {
    setCalendarOpen((prevState) => !prevState);
    onOpen?.();
  };

  const handleRangeChange = (ranges: RangeKeyDict) => {
    const { selection } = ranges;
    onChange({
      startDate: selection.startDate,
      endDate: selection.endDate
    });
  };

  const handleCalendarSave = () => {
    toggleCalendarOpen();
    if (onSave) onSave();
  };

  const navigatorRenderer: (
    currFocusedDate: Date,
    changeShownDate: (value: Date | number | string) => void,
    props: CalendarProps
  ) => JSX.Element = (currentFocusedDate, changeShownDate) => {
    const focusedYear = currentFocusedDate.getFullYear();

    const toggleYearSelectMenu = () => {
      setYearSelectOpen((prev) => !prev);
    };

    const handleYearSelectChange = (year: number) => {
      const updatedDate = setDate(currentFocusedDate, { year });
      // update year after toggling year menu
      setTimeout(() => {
        changeShownDate(updatedDate);
      }, 0);
      toggleYearSelectMenu();
    };

    const optionYears = [];
    for (let year = optionStartYear; year < optionStartYear + 25; year += 1)
      optionYears.push(year);

    return (
      <>
        <div className='flex items-center justify-between mb-2'>
          <div className='flex items-center'>
            {showYearSelection ? (
              <button
                onClick={toggleYearSelectMenu}
                className='flex items-center'
                type='button'
              >
                <p className='text-DEFAULT_TEXT text-17px mr-3'>
                  {format(currentFocusedDate, 'MMMM Y')}
                </p>
                <CalendarDownArrowIcon
                  className={classNames({
                    'w-2 h-2 transform rotate-180': isYearSelectOpen
                  })}
                />
              </button>
            ) : (
              <p className='text-DEFAULT_TEXT text-17px mr-3'>
                {format(currentFocusedDate, 'MMMM Y')}
              </p>
            )}
          </div>
          <div>
            <button
              className='px-3 py-1'
              onClick={() =>
                handlePrevNavigation(
                  isYearSelectOpen,
                  optionStartYear,
                  setOptionStartYear,
                  currentFocusedDate,
                  changeShownDate,
                  minDate
                )
              }
              type='button'
            >
              <CalendarArrowIcon />
            </button>
            <button
              className='px-3 py-1 mr-2'
              onClick={() =>
                handleNextNavigation(
                  isYearSelectOpen,
                  setOptionStartYear,
                  currentFocusedDate,
                  changeShownDate
                )
              }
              type='button'
            >
              <CalendarArrowIcon className='transform rotate-180' />
            </button>
          </div>
        </div>
        {isYearSelectOpen && (
          <div className='year-selection-preview flex flex-wrap gap-3 px-3 pb-2'>
            {optionYears.map((year) => (
              <button
                className={cx(
                  'text-xs leading-6 h-8 w-11 border border-DEFAULT_TEXT rounded-4xl',
                  {
                    'bg-DEFAULT_TEXT text-white': year === focusedYear,
                    'text-DEFAULT_TEXT': year !== focusedYear
                  }
                )}
                onClick={() => handleYearSelectChange(year)}
                key={year}
                type='button'
              >
                {year}
              </button>
            ))}
          </div>
        )}
      </>
    );
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setCalendarOpen(false);
        onClose?.();
      }}
    >
      <button
        type='button'
        onClick={toggleCalendarOpen}
        className={twMerge('px-4 py-1 rounded-lg bg-GRAY mr-2', buttonClass)}
      >
        <div className='flex items-center gap-3 text-DEFAULT_TEXT justify-between w-full'>
          <p> {placeholder} </p>
          {showDropdownArrow &&
            (isCalendarOpen ? (
              <DownArrowIcon className='w-2 h-2 transform rotate-180 text-DEFAULT_TEXT' />
            ) : (
              <DownArrowIcon className='w-2 h-2 text-DEFAULT_TEXT' />
            ))}
        </div>
      </button>
      {isCalendarOpen && (
        <div className='absolute z-99 p-4 bg-white rounded-xl h-auto shadow-suggestion mt-2 flex flex-col'>
          <div className='relative'>
            <DateRange
              weekdayDisplayFormat={'EEEEE'}
              minDate={minDate}
              className={`custom-date-selector ${
                isYearSelectOpen && 'year-selection'
              }`}
              navigatorRenderer={navigatorRenderer}
              ranges={[
                {
                  ...range,
                  key: 'selection'
                }
              ]}
              onChange={handleRangeChange}
              showDateDisplay={false}
              rangeColors={['#D4EDFC']}
            />
            {!isYearSelectOpen && (
              <div className='text-14px flex justify-end items-center gap-2'>
                <button
                  className='px-4 py-1 text-DEFAULT_TEXT text-xs'
                  onClick={onClear}
                  type='button'
                >
                  {translate('Page_1.Black_ghost_button')}
                </button>
                <button
                  className='px-4 py-1 text-white leading-6 bg-PRIMARY rounded-32px text-sm'
                  onClick={handleCalendarSave}
                  type='button'
                >
                  {translate('button_rounded_(short).save')}
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </OutsideClickHandler>
  );
};

export default DateRangePicker;
