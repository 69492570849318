import { FC } from 'react';

import { Skeleton } from 'components';
import { If } from 'components/Generics';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { translate } from 'utils';
export interface TabProps {
  selected: boolean;
  label: string;
  count?: string;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  tabClassName?: string;
  countColour?: string;
  padding?: string;
  testId?: string;
  endIcon?: JSX.Element;
  isCountLoading?: boolean;
  view?: string;
}

export const Tab: FC<TabProps> = ({
  selected,
  label,
  count,
  handleClick,
  tabClassName,
  countColour = 'bg-PRIMARY_LIGHT',
  padding = 'py-2',
  testId,
  endIcon,
  isCountLoading = false,
  view = ''
}) => {
  const { callback } = useFieldRefContext();

  return (
    <button
      id={testId}
      ref={callback?.(view)}
      type='button'
      data-testid='tab'
      onClick={handleClick}
      className={`${padding} ${
        !tabClassName ? 'hover:text-PRIMARY' : ''
      } xxs:text-sm md:text-17px md:leading-6 transition-colors flex items-center whitespace-nowrap box-border custom-tab overflow-y-auto ${tabClassName}
    ${
      !tabClassName
        ? `${
            selected
              ? 'border-b-2 border-PRIMARY border-solid text-PRIMARY'
              : 'text-DISABLED '
          }`
        : ''
    }`}
    >
      {translate(label)}
      <If condition={!!endIcon}>{endIcon}</If>
      <Skeleton
        loading={isCountLoading}
        width='2.5rem'
        height='1.5rem'
        className='ml-2 rounded-3xl'
      >
        {count && (
          <span
            className={`ml-2 rounded-3xl px-3 text-sm h-6
       ${selected ? ` ${countColour}` : 'bg-LIGHT_GRAY '}`}
          >
            {count}
          </span>
        )}
      </Skeleton>
    </button>
  );
};
