import { FC } from 'react';

import NoDataMessage from 'components/NoDataMessage/NoDataMessage';
import { customTwMerge } from 'utils';

interface EmptyPlaceholderComponent {
  colSpan: number;
  message?: string;
  className?: string;
}

const EmptyPlaceholderComponent: FC<EmptyPlaceholderComponent> = ({
  colSpan,
  message,
  className
}) => (
  <tr>
    <td colSpan={colSpan} className={customTwMerge('py-4 pt-40 ', className)}>
      <NoDataMessage message={message} />
    </td>
  </tr>
);

export default EmptyPlaceholderComponent;
