import { FC, useMemo } from 'react';

import DeliveryInfo from 'components/DeliveryInfo/DeliveryInfo';
import { TreatmentVersionCard } from 'components/TreatmentVersionCard/TreatmentVersionCard';
import { TREATMENT_PLAN_VERSION_STATUS } from 'constants/options';
import {
  AlignerProductionType,
  TreatmentPlanType,
  VersionDetails
} from 'types/PatientList.types';
import { getCurrentTpvStatus } from 'utils/common';
import { getCurrentDeliveryStage } from 'utils/TreatmentPlanningInstructions.utils';

interface Props {
  treatmentPlan?: TreatmentPlanType;
  handleClick?: (tp?: TreatmentPlanType, version?: VersionDetails) => void;
  patientId?: string;
  alignerProductionInfo?: AlignerProductionType;
  autoScrollRef?: React.RefObject<HTMLDivElement>;
  refetchTreatmentPlans: () => void;
  hideReOpenPlan?: boolean;
}
export const TreatmentPlanCard: FC<Props> = ({
  treatmentPlan,
  alignerProductionInfo,
  patientId,
  handleClick,
  autoScrollRef,
  refetchTreatmentPlans,
  hideReOpenPlan
}) => {
  const groupedVersions = useMemo(() => {
    const dormantVersions: VersionDetails[] = [];
    const allVersions: VersionDetails[] = [];
    treatmentPlan?.versions?.forEach?.((version) => {
      const currentTpvStatus = getCurrentTpvStatus(version);
      if (currentTpvStatus === TREATMENT_PLAN_VERSION_STATUS.DORMANT) {
        dormantVersions?.push(version);
      } else {
        allVersions?.push(version);
      }
    });
    return { dormantVersions, allVersions };
  }, [treatmentPlan]);

  const currentStage = getCurrentDeliveryStage(alignerProductionInfo);

  return (
    <div className='p-6 bg-GRAY5 xxs:rounded-lg flex flex-col md:flex-row w-full gap-4'>
      <div className='w-full md:w-3/12'>
        <h5 className='md:text-lg md:font-bold xxs:font-semibold xxs:text-base mb-1'>
          {treatmentPlan?.label}
        </h5>
        <DeliveryInfo
          currentStage={currentStage}
          className=' flex md:flex-row gap-x-3 md:justify-start mt-4'
          autoScrollRef={autoScrollRef}
          scrollTo='center'
          isAutoScrollEnabled={true}
          popOverClassName='pl-20 z-20'
        />
      </div>
      <div className='w-full md:w-9/12 flex flex-col gap-4'>
        {groupedVersions?.allVersions?.map((version) => (
          <TreatmentVersionCard
            handleClick={() => handleClick?.(treatmentPlan, version)}
            versionDetails={version}
            patientId={patientId}
            key={version?.id}
            treatmentPlan={treatmentPlan as TreatmentPlanType}
            refetchTreatmentPlans={refetchTreatmentPlans}
            hideReOpenPlan={hideReOpenPlan}
          />
        ))}
      </div>
    </div>
  );
};
