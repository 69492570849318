import { FC } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { Button, RhfCheckbox } from 'components';
import { TRACK_EVENTS } from 'constants/segment';
import { useUpdateExternalDoctorReview } from 'services/hooks/InTreatment.hooks';
import { GET_PATIENT_DATA } from 'services/query/patientslist';
import { loginedUserDetails } from 'store/atoms';
import { translate } from 'utils';
import { showToast } from 'utils/toast/toast.util';

interface Props {
  treatmentId?: string;
  onClose: () => void;
  patientId?: string;
  name?: string;
  opportunityName?: string;
  opportunityId?: string;
  onRetainer?: () => void;
}

const ApprovalFeedback: FC<Props> = ({
  treatmentId = '',
  onClose,
  patientId,
  name,
  opportunityId,
  opportunityName,
  onRetainer
}) => {
  const formMethods = useForm({
    defaultValues: { patientHappy: false }
  });
  const { handleSubmit, watch } = formMethods;
  const isPatientHappy = watch('patientHappy');
  const loggedInUserDetails = useRecoilValue(loginedUserDetails);

  const config = {
    onCompleted: () => {
      showToast(translate('toast.retainer_approve'), true);
      onClose();
      onRetainer?.();
    },
    onError: () => {
      showToast(
        'Your selection could not be processed at this time due to technical issues. Please try again later.',
        false
      );
    },
    refetchQueries: [GET_PATIENT_DATA]
  };

  const [updateExternalDoctorReview] = useUpdateExternalDoctorReview(config);

  const onSubmitHandler = () => {
    const type = 'RETAINER';
    updateExternalDoctorReview({
      variables: {
        input: {
          type,
          treatmentId: treatmentId
        }
      }
    });
    window?.analytics.track(TRACK_EVENTS?.END_OF_TREATMENT_RETAINERS_APPROVED, {
      patient_name: name,
      patient_Id: patientId,
      opportunity_id: opportunityId,
      opportunity_name: opportunityName,
      clinic:
        loggedInUserDetails?.clinic?.filter(
          ({ headClinicId }) => !headClinicId
        )?.[0]?.name || '',
      branch: loggedInUserDetails?.clinic
        ?.filter(({ headClinicId }) => headClinicId)
        ?.map(({ name: branchName }) => branchName)
        ?.join(', '),
      country: loggedInUserDetails?.country
    });
  };

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmitHandler)}
        className='md:p-6 p-4 md:w-785px xxs:w-343px'
      >
        <header className='flex md:mb-8 mb-4 items-center justify-between'>
          <h2 className='text-collapsible font-semibold'>
            {translate('refinement.what_next_for_patient')}
          </h2>
        </header>
        <section>
          <RhfCheckbox
            name='patientHappy'
            label={translate('off-boarding.confirmation')}
            isRequired={true}
          />
        </section>
        <footer className='flex justify-end md:flex-row flex-col-reverse text-sm mt-7 md:mb-0 mb-3'>
          <Button
            className='text-DEFAULT_TEXT text-center md:mr-8 mr-0'
            onClick={onClose}
          >
            {translate('cancel.cancel')}
          </Button>
          <Button
            className='bg-PRIMARY text-white text-center py-3 md:px-6 md:w-auto w-full'
            type='submit'
            disabled={!isPatientHappy}
          >
            {translate('confirm.confirm')}
          </Button>
        </footer>
      </form>
    </FormProvider>
  );
};

export default ApprovalFeedback;
