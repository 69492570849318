import { FC } from 'react';

import { QRCodeSVG } from 'qrcode.react';

import { Button, Modal } from 'components';
import { translate } from 'utils';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const ScanPhotoModal: FC<Props> = ({ isOpen, handleClose }) => {
  const onClickCancel = () => {
    handleClose();
  };
  return (
    <Modal open={isOpen} onClose={onClickCancel} showCloseIcon={false}>
      <div className='p-8 max-w-sm'>
        <p className='text-base text-DEFAULT_TEXT font-semibold text-center'>
          {translate('switch.photo')}
        </p>

        <div className='flex w-full justify-center'>
          <QRCodeSVG
            size={256}
            style={{ height: '288px', width: '160px' }}
            value={window.location.href}
          />
        </div>

        <p className='text-DISABLED_2 text-xs leading-6 text-center'>
          {translate('scan.assessment')}
        </p>
        <Button
          className='w-full px-6 py-2 border border-DEFAULT_TEXT mt-4'
          onClick={onClickCancel}
        >
          {translate('cancel.cancel')}
        </Button>
      </div>
    </Modal>
  );
};

export default ScanPhotoModal;
