import { pagePaths } from 'config/pages';

const PATIENT_LIST_LIMIT = 50;

const DATE_FORMAT = 'DD/MM/YYYY';
const DATE_FORMAT_HYPHEN = 'YYYY-MM-DD';
const YEAR_FIRST_DATE_FORMAT = 'YYYY/MM/DD';
const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm';
const DATE_TIME_FORMAT_A = 'DD/MM/YYYY HH:mm A';
const DATE_TIME_FORMAT_PENDING = 'DD/MM/YYYY, HH:mm A';
const DATE_FORMAT_DD_MM_YY = 'DD/MM/YY';
const DATE_FORMAT_CALENDAR = 'DD MMM YYYY, dddd';
const DATE_FORMAT_CALENDAR_MOBILE = 'DD MMM YYYY';
const LONG_DATE_FORMAT = 'DD MMM YYYY, dddd';
const DATE_FORMAT_DOCTOR_NOTES = 'D/M/YYYY, dddd';
const DATE_FORMAT_RECENT_MESSAGE = 'ddd DD MMM hh:mm A';
const TIME_FORMAT_12 = 'h:mm A';
const TIME_FORMAT_24 = 'HH:mm';
const SLOT_OPTION_PREFIX = 'HH:mm:ss';
const CLINIC_CLOSED = 'Closed';
const TIME_FORMAT = 'hh:mm A';
const TIME_FIRST_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const MESSAGE_DATE_FORMAT = 'Do MMMM YYYY. h:mm A';
const ALIGNER_DATE_FORMAT = 'Do MMMM YYYY.';
const SUBMISSION_DATE_FORMAT = 'DD MMMM YYYY, HH:mm';
const PRESCRIPTION_PHOTO_FORMAT = 'DD MMM yyyy, h:mm A';
const SETTINGS_BREADCRUMBS = [
  {
    label: 'dashboard_button.dashboard',
    pathname: pagePaths.dashboard
  },
  {
    label: 'settings_button.settings',
    pathname: pagePaths.settings
  }
];

export {
  SETTINGS_BREADCRUMBS,
  DATE_FORMAT,
  DATE_TIME_FORMAT_A,
  DATE_TIME_FORMAT_PENDING,
  DATE_FORMAT_CALENDAR,
  DATE_FORMAT_CALENDAR_MOBILE,
  DATE_FORMAT_DD_MM_YY,
  DATE_TIME_FORMAT,
  LONG_DATE_FORMAT,
  PATIENT_LIST_LIMIT,
  DATE_FORMAT_DOCTOR_NOTES,
  DATE_FORMAT_HYPHEN,
  DATE_FORMAT_RECENT_MESSAGE,
  TIME_FORMAT,
  TIME_FORMAT_12,
  TIME_FORMAT_24,
  CLINIC_CLOSED,
  ALIGNER_DATE_FORMAT,
  SLOT_OPTION_PREFIX,
  PRESCRIPTION_PHOTO_FORMAT,
  YEAR_FIRST_DATE_FORMAT,
  MESSAGE_DATE_FORMAT,
  TIME_FIRST_DATE_TIME_FORMAT,
  SUBMISSION_DATE_FORMAT
};
