import { FC, useState } from 'react';

import { DocumentNode } from 'graphql';
import { Control, FieldValues } from 'react-hook-form';

import Files from 'components/Files/Files';
import { TreatmentSourceType } from 'components/TreatmentProgressMenu/TreatmentMenuConfig';
import RHFTreatmentFileUploader from 'containers/TreatmentFileUploader/RHFTreatmentFileUploader/RHFTreatmentFileUploader';
import { FilesData } from 'types/Files.types';
import { TreatmentDocumentType } from 'types/TreatmentPlanning.types';

type TreatmentDetailsProp = {
  label: string;
  value?: string;
  files?: FilesData[];
  fileUpload?: boolean;
  fileUploadInput?: {
    control: Control<FieldValues>;
    treatmentId: string;
    documentType: TreatmentDocumentType;
    sourceType: TreatmentSourceType;
    allowedFileMimeType: string;
    name: string;
    fileDropAreaTitle: string;
  };
  refetchQueries?: Array<DocumentNode>;
};

const TreatmentDetails: FC<TreatmentDetailsProp> = ({
  label,
  value = '',
  files,
  fileUpload = false,
  fileUploadInput,
  refetchQueries
}) => {
  const [, toggleZoomPhoto] = useState(false);
  const [, setSelectedPhoto] = useState<string | number>(0);
  return (
    <div>
      <p className='md:text-sm md:leading-6 text-xs md:mb-4 mb-2 text-DEFAULT_TEXT'>
        {label}
      </p>
      {fileUpload && fileUploadInput ? (
        <RHFTreatmentFileUploader
          title={''}
          control={fileUploadInput.control}
          treatmentId={fileUploadInput.treatmentId || ''}
          name={fileUploadInput.name}
          fileDropAreaTitle={fileUploadInput.fileDropAreaTitle}
          documentType={fileUploadInput.documentType}
          sourceType={fileUploadInput.sourceType}
          allowedFileMimeType={fileUploadInput.allowedFileMimeType}
          className='mb-6 w-full'
          refetchQueries={refetchQueries}
          retainerFiles={files}
        />
      ) : files ? (
        <Files
          files={files || []}
          className='flex-shrink-0'
          width='md:w-44.5 w-155.5px'
          height='md:h-44.5 h-155.5px'
          viewOnly={true}
          setSelectedPhoto={setSelectedPhoto}
          toggleZoomPhoto={toggleZoomPhoto}
        />
      ) : (
        <p className='md:text-base text-sm leading-6 text-DEFAULT_TEXT'>
          {value || '-'}
        </p>
      )}
    </div>
  );
};

export default TreatmentDetails;
