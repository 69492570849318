import { FC } from 'react';

import { ErrorIcon } from 'assets/icons';
import AnimateLabel from 'components/AnimateLabel/AnimateLabel';
import ChipLabel from 'components/ChipLabel/ChipLabel';
import FilesUpload from 'components/FilesUpload/FilesUpload';
import { If } from 'components/Generics';
import ZoomPhotoPopup from 'components/ZoomPhotoPopup/ZoomPhotoPopup';
import { FieldRefCallback } from 'types/FieldRef.types';
import { FilesData, RenderFooter, RenderHeader } from 'types/Files.types';
import { customTwMerge, translate } from 'utils';

interface Props {
  zoomPhoto: boolean;
  toggleZoomPhoto: React.Dispatch<React.SetStateAction<boolean>>;
  selectedPhoto: string;
  setSelectedPhoto: React.Dispatch<React.SetStateAction<string | number>>;
  uploadingFilesCount: number;
  files: FilesData[];
  value?: FilesData[];
  callback?: FieldRefCallback | null;
  title?: string;
  errorMessage?: string;
  animatedField?: string;
  isMandatory?: boolean;
  hideUploadModal?: boolean;
  allFiles?: FilesData[];
  onFilesChange: (files: FilesData[]) => void;
  onDelete: (
    selectedId: string | number,
    localId: string | number | undefined
  ) => void;
  allowedFileMimeType?: string;
  fileDropAreaTitle?: string;
  className?: string;
  uploadButtonName?: string;
  retainerFiles?: FilesData[];
  uploadContainerStyle?: string;
  supportedFilesText?: string;
  enableSlider?: boolean;
  onUploadClicked?: () => void;
  uploadButtonHandler?: () => void;
  dropContainerClassName?: string;
  name?: string;
  titleClassName?: string;
  renderHeader?: RenderHeader;
  renderFooter?: RenderFooter;
  disabled?: boolean;
  warningText?: string;
  thumbnailHeight?: string;
}

const defaultRenderFooter: RenderFooter = ({ errorMessage }) => {
  return errorMessage ? (
    <p className='text-ERROR text-xs xxs:mb-4 md:my-2 flex '>
      <ErrorIcon />
      <span className='ml-2'>{errorMessage}</span>
    </p>
  ) : null;
};

const defaultRenderHeader: RenderHeader = ({
  titleClassName,
  errorMessage,
  title,
  animatedField,
  name,
  isMandatory,
  warningText,
  uploadedFilesCount,
  uploadingFilesCount,
  titleTextStyle
}) => {
  return (
    <div className='mb-2'>
      <div className='flex justify-between w-full'>
        <h3
          className={customTwMerge(
            'md:text-sm leading-6 text-DEFAULT_TEXT xxs:text-17px relative',
            titleClassName
          )}
        >
          <span className={`${titleTextStyle}`}>{title}</span>
          {errorMessage && (
            <AnimateLabel
              animate={animatedField === name}
              className={`${titleTextStyle}`}
            >
              {title}
            </AnimateLabel>
          )}
          <If condition={isMandatory}>
            <span className='text-ERROR'>*</span>
          </If>
        </h3>
        {warningText && (
          <ChipLabel className='bg-LIGHT_BLUE text-DARK_BLUE mb-6 px-4 h-6'>
            <div>{translate(warningText)}</div>
          </ChipLabel>
        )}
      </div>
      <p className='text-sm text-DISABLED_2'>
        {uploadingFilesCount
          ? translate('uploadingStatus.uploading', { x: uploadingFilesCount })
          : uploadedFilesCount > 0 &&
            translate('uploadingStatus.uploaded', { x: uploadedFilesCount })}
      </p>
    </div>
  );
};

const PatientFileUpload: FC<Props> = ({
  files,
  zoomPhoto,
  toggleZoomPhoto,
  selectedPhoto,
  setSelectedPhoto,
  uploadingFilesCount,
  uploadButtonHandler,
  allFiles,
  value = [],
  hideUploadModal,
  errorMessage = '',
  title = '',
  isMandatory = false,
  callback,
  animatedField,
  onFilesChange,
  allowedFileMimeType = '',
  onDelete,
  fileDropAreaTitle = '',
  className = '',
  uploadButtonName,
  retainerFiles,
  uploadContainerStyle = '',
  supportedFilesText,
  enableSlider,
  onUploadClicked,
  dropContainerClassName = '',
  name = '',
  titleClassName = '',
  renderHeader = defaultRenderHeader,
  renderFooter = defaultRenderFooter,
  disabled,
  warningText = '',
  thumbnailHeight
}) => {
  const titleTextStyle = errorMessage ? 'text-ERROR' : 'text-DEFAULT_TEXT';
  return (
    <div className='w-full h-full' ref={callback?.(name)}>
      {renderHeader({
        titleClassName,
        errorMessage,
        title,
        animatedField,
        name,
        isMandatory,
        uploadingFilesCount,
        titleTextStyle,
        uploadedFilesCount: value.length,
        warningText
      })}
      <FilesUpload
        onFilesChange={onFilesChange}
        files={files}
        onDelete={onDelete}
        allowedFileMimeType={allowedFileMimeType}
        fileDropAreaTitle={fileDropAreaTitle}
        className={`${!files?.length && 'md:h-66'} ${className}`}
        uploadButtonName={uploadButtonName}
        retainerFiles={retainerFiles}
        hideUploadModal={hideUploadModal}
        uploadContainerStyle={uploadContainerStyle}
        supportedFilesText={supportedFilesText}
        enableSlider={enableSlider}
        onUploadClicked={onUploadClicked}
        uploadButtonHandler={uploadButtonHandler}
        dropContainerClassName={dropContainerClassName}
        toggleZoomPhoto={toggleZoomPhoto}
        setSelectedPhoto={setSelectedPhoto}
        disabled={disabled}
        thumbnailHeight={thumbnailHeight}
      />
      {renderFooter({ errorMessage })}
      <ZoomPhotoPopup
        allFiles={allFiles ? allFiles || [] : files}
        zoomPhoto={zoomPhoto}
        toggleZoomPhoto={toggleZoomPhoto}
        selectedPhoto={selectedPhoto as string}
        setSelectedPhoto={setSelectedPhoto}
      />
    </div>
  );
};

export default PatientFileUpload;
