import { FC, ReactNode, useMemo } from 'react';

import { useRecoilValue } from 'recoil';

import {
  AppointmentIcon,
  CalendarIcon,
  CaseLibraryIcon,
  DashboadIcon,
  PatientListIcon,
  ReferralIcon
} from 'assets/icons';
import InboxNav from 'components/Navbar/InboxNav/InboxNav';
import { pagePaths } from 'config/pages';
import { PERMISSIONS } from 'constants/Permissions';
import { loginedUserDetails } from 'store/atoms';

const useNavBarConfig = () => {
  interface NavItem {
    title: string | ReactNode;
    icon?: FC<{ className?: string }>;
    path: string;
    customClass?: string;
    showSpacer?: boolean;
    permission?: string;
    testId?: string;
    hidden?: boolean;
  }

  const loginedUser = useRecoilValue(loginedUserDetails);

  const isSoaEnabled = useMemo(() => {
    return loginedUser?.clinic?.some((clinic) => clinic.isSOAEnabled);
  }, [loginedUser]);

  const getNavBarItems = () => {
    const navBarItems: Array<NavItem> = [
      {
        title: 'dashboard_button.dashboard',
        icon: DashboadIcon,
        path: pagePaths.dashboard,
        permission: PERMISSIONS?.DASHBOARD_PAGE,
        testId: 'sidebar-item-dashboard'
      },
      {
        title: 'patient_list_button.patient_list',
        icon: PatientListIcon,
        path: pagePaths.patientList,
        permission: PERMISSIONS?.PATIENT_LIST,
        testId: 'sidebar-item-patients-list'
      },
      {
        title: 'calendar_button.calendar',
        icon: CalendarIcon,
        path: pagePaths.calendar,
        permission: PERMISSIONS?.CALENDAR,
        testId: 'sidebar-item-calendar'
      },
      {
        title: <InboxNav />,
        path: pagePaths.inbox,
        permission: PERMISSIONS?.INBOX,
        testId: 'sidebar-item-inbox'
      },
      {
        title: 'button.appointments',
        icon: AppointmentIcon,
        path: pagePaths.appointmentList,
        permission: PERMISSIONS?.APPOINTMENTS,
        testId: 'sidebar-item-appointments'
      },
      {
        title: 'referrals.referrals',
        icon: ReferralIcon,
        path: pagePaths.referrals,
        permission: PERMISSIONS?.REFERRAL,
        testId: 'sidebar-item-referrals'
      },
      {
        title: 'case_library_button.case_library',
        icon: CaseLibraryIcon,
        path: pagePaths.caseLibrary,
        permission: PERMISSIONS?.CASE_LIBRARY,
        testId: 'sidebar-item-case-library'
      },
      {
        title: 'finance_report_button.finance_report',
        icon: CaseLibraryIcon,
        path: pagePaths.finance,
        permission: PERMISSIONS?.FINANCE_TAB_VIEW,
        testId: 'finance',
        hidden: !isSoaEnabled
      }
    ];
    return navBarItems;
  };
  return { getNavBarItems };
};
export default useNavBarConfig;
