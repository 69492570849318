import { ReactNode } from 'react';

import {
  ApolloCache,
  DefaultContext,
  LazyQueryHookOptions,
  MutationHookOptions,
  MutationTuple,
  QueryTuple
} from '@apollo/client';

import { SubmittedBy } from './PatientList.types';
import { QueryCallbackFunctions } from './request';
import { TreatmentDocumentType } from './TreatmentPlanning.types';

export interface FilesUploadlData extends File {
  id: string | number;
  name: string;
  fileType: 'img' | 'file';
  dateTime: string;
}

export const FileType = {
  IMAGE: 'img' as const,
  FILE: 'file' as const
};

export enum FileOrigin {
  SALESFORCE = 'SALESFORCE',
  CLINIC_CLOUD = 'CLINIC_CLOUD',
  ADMIN_PORTAL = 'ADMIN_PORTAL'
}
export enum Classification {
  FRONTAL = 'FRONTAL',
  FRONTAL_SMILING = 'FRONTAL_SMILING',
  INTRAORAL_LEFT = 'INTRAORAL_LEFT',
  INTRAORAL_RIGHT = 'INTRAORAL_RIGHT',
  UPPER = 'UPPER',
  LOWER = 'LOWER',
  SIDE_PROFILE = 'SIDE_PROFILE',
  INTRAORAL_FRONTAL = 'INTRAORAL_FRONTAL',
  PANORAMIC_RADIOGRAPHY = 'PANORAMIC_RADIOGRAPHY',
  LATERAL_CEPHALOGRAM = 'LATERAL_CEPHALOGRAM',
  OTHER = 'OTHER',
  STL = 'STL'
}

export interface FilesData {
  id: string | number;
  fileType: 'img' | 'file';
  fileSrc: string;
  fileName?: string;
  uploadProgress?: number | number[] | undefined;
  dateTime?: string;
  file?: File;
  fileId?: string;
  filePath?: string;
  key?: string;
  classification?: Classification | null;
  createdAt?: string;
  origin?: FileOrigin;
  submittedBy?: SubmittedBy | null;
  documentType?: TreatmentDocumentType;
  S3key?: string;
  resubmissionStatus?: string;
  thumbnail?: string;
}

export type ReferralDocumentType =
  | 'SCAN'
  | 'X_RAY'
  | 'CONSENT_FORM'
  | 'PRE_ASSESSMENT_PHOTO'
  | 'PHOTO';

export interface ReferralDocumentInput {
  key?: string;
  name: string;
  type?: ReferralDocumentType;
}

export interface ReferralDocumentListInput {
  referralId: string;
  documents: ReferralDocumentInput[];
  files?: FilesData[];
  resubmissionStatus?: string;
}

export interface ReferralDocumentUploadUrl {
  url: string;
  name: string;
  key: string;
  extension: string;
}

export interface ReferralDocumentUploadUrlResponse {
  getReferralDocumentsUploadUrl: ReferralDocumentUploadUrl[];
}

export interface FinanceFileUploadInput {
  id: string;
  name: string;
}

export interface PresignedUrl {
  presignedUrl: string;
  key: string;
}
export interface FinanceDocumentUploadUrlResponse {
  getInvoiceUploadUrl: PresignedUrl;
}
export interface GetReferralFileUploadUrl {
  (
    config?: LazyQueryHookOptions<
      ReferralDocumentUploadUrlResponse,
      ReferralDocumentListInput
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): QueryTuple<ReferralDocumentUploadUrlResponse, ReferralDocumentListInput>;
}

export interface SaveReferralDocument {
  (
    config?: MutationHookOptions<
      ReferralDocumentResponse,
      ReferralDocumentListInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    ReferralDocumentResponse,
    ReferralDocumentListInput,
    DefaultContext,
    ApolloCache<any>
  >;
}
export interface DeleteReferralDocument {
  (
    config?: MutationHookOptions<
      { deleteReferralDocument: boolean },
      ReferralDocumentDeleteInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    { deleteReferralDocument: boolean },
    ReferralDocumentDeleteInput,
    DefaultContext,
    ApolloCache<any>
  >;
}

export type ReferralDocumentDeleteInput = {
  id: string;
  referralId: string;
};

export type ReferralDocument = {
  id: string;
  url: string;
  name: string;
  type: string;
  extension: string;
};

export type ClassificationField = {
  documentId: string | number;
  classification: Classification;
};
export type ReferralDocumentResponse = {
  saveReferralDocuments: ReferralDocument[];
};

export type RenderFooter = ({
  errorMessage
}: {
  errorMessage: string;
}) => ReactNode;

export type RenderHeader = ({
  titleClassName,
  errorMessage,
  title,
  animatedField,
  name,
  isMandatory,
  uploadedFilesCount,
  uploadingFilesCount,
  titleTextStyle,
  warningText
}: {
  titleClassName?: string;
  errorMessage?: string;
  title?: string;
  animatedField?: any;
  name?: string;
  isMandatory: boolean;
  uploadingFilesCount: number;
  uploadedFilesCount: number;
  titleTextStyle?: string;
  warningText: string;
}) => ReactNode;

export type ReferralDocuments = {
  documentId: string | number;
  classification: string | null;
};
type ReferralDocumentsUpdateInput = {
  input: ReferralDocuments[];
};
export interface UpdateReferralDocument {
  (
    config?: MutationHookOptions<
      ReferralDocumentsUpdateInput,
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    ReferralDocumentsUpdateInput,
    DefaultContext,
    ApolloCache<any>
  >;
}
