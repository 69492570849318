import {
  FC,
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
  MutableRefObject
} from 'react';

import { useApolloClient, useLazyQuery } from '@apollo/client';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import {
  Notification,
  NotificationOutput
  // eslint-disable-next-line import/no-unresolved
} from '@chat/chat-sdk/dist/types';
import cx from 'classnames';
import { debounce } from 'lodash';
import { cloneDeep } from 'lodash';
import { useNavigate } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState
} from 'recoil';

import { CloseIcon } from 'assets/icons';
import chatClient from 'chatSDK';
import {
  ChatMessage,
  MobileDrawer,
  NoDataMessage,
  TicketCard,
  DesktopDrawer
} from 'components';
import If from 'components/Generics/If/If';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import { pagePaths } from 'config/pages';
import { LOGIN_ERRORS } from 'constants/auth';
import { CHAT_TRACK_EVENTS, VIEW_TYPE } from 'constants/segment';
import {
  useSegmentTrackChat,
  useTrackData
} from 'services/hooks/Segment.hooks';
import { useUpdateUnreadStatus } from 'services/hooks/Ticket.hooks';
import { GET_RECENT_TICKET_LIST } from 'services/query/Dashboard';
import { GET_TICKET_BY_ID, GET_TICKET_LIST } from 'services/query/inbox';
import { GET_UNREAD_MESSAGES_COUNT } from 'services/query/patientslist';
import {
  selectedTicket,
  selectedSerchedMessage,
  loginedUserDetails,
  unreadNotification,
  ticketList,
  showChatMessage,
  unreadNotificationCount,
  showUnauthChatError
} from 'store/atoms';
import {
  defaultSelectedTicket,
  defaultSelectedSearchedTicket,
  defaultInboxFilters
} from 'store/atoms/constants';
import { inboxFilters, ticketListLoaded } from 'store/atoms/inbox';
import { useIsExternalUser } from 'store/selectors/auth';
import { RecentTicketData } from 'types/Dashboard.types';
import {
  MapToObj,
  TicketByIdOptionalArg,
  TicketPaginationInfo
} from 'types/Inbox.types';
import { SelectedSearchedTicket } from 'types/select';
import {
  GetTicketByIdInput,
  GetTicketsListInput,
  InboxFilters,
  SearchedMessageDetails,
  Ticket,
  TicketById,
  TicketData,
  TicketDetailsCard
} from 'types/Tickets.types';
import { isDataExist, useViewport, translate } from 'utils';
import { formatMessage } from 'utils/common';
import {
  getTicketList,
  useUpdateSelectedTicket
} from 'utils/customHooks/inbox';
import { useQueryParams } from 'utils/customHooks/useQueryParams';
import { formatSegmentTimeDiff } from 'utils/dateUtils';
import { formatMentionedMessage, isForDifferentHeadClinic } from 'utils/inbox';
import { showToast } from 'utils/toast/toast.util';

import TicketCardListSkeleton from './Skelton/TicketCardListSkeleton';
import TicketCardSkeleton from './Skelton/TicketCardSkeleton';
import { handleGroupStatusListenerSuccess } from './TicketCardListService';

interface TicketCardListProps {
  isFromPatientDetails?: boolean;
  patientId?: string;
  ticketScrollRef: MutableRefObject<HTMLDivElement | null>;
  updateTicketListFlag?: () => void;
  ticketPaginationInfo: React.MutableRefObject<TicketPaginationInfo>;
  setUnReadMessageCount?: React.Dispatch<React.SetStateAction<number>>;
}

const TicketCardList: FC<TicketCardListProps> = ({
  isFromPatientDetails = false,
  ticketScrollRef,
  updateTicketListFlag,
  ticketPaginationInfo,
  patientId,
  setUnReadMessageCount
}) => {
  const navigate = useNavigate();
  const chat = chatClient.chatClient;

  const { updateSelectedTicketDetails } = useUpdateSelectedTicket();

  const [isListenerRegistered, setListenerRegistered] = useState(false);
  const query = useQueryParams();
  const { trackData, trackContextData } = useTrackData();
  const redirectionParams = {
    ticketId: Number(query.get('ticketId')),
    messageId: query.get('messageId')
  };
  const selectedTicketRef = useRef<Ticket>();
  const ticketListRef = useRef<Ticket[]>([]);
  const ticketFilterRef = useRef<InboxFilters>();
  const headClinicIdRef = useRef<string>();
  const searchedTicketIdRef = useRef<number>();
  const [isFirstUpdate, setFirstUpdate] = useState(false);
  const searchTrack = useRef({
    resultDisplayed: 0,
    scrollNo: 0
  });
  const ticketDuration = useRef({
    ticketId: -1,
    startTime: -1
  });
  const [showLoader, setShowLoader] = useState(true);
  const [liveUpdate, setLiveUpdate] = useState<Notification>();
  const resetSelectedTicket = useResetRecoilState(selectedTicket);
  const setTicketListLoaded = useSetRecoilState(ticketListLoaded);
  const resetTicketList = useResetRecoilState(ticketList);
  const setShowUnAuthChatModal = useSetRecoilState(showUnauthChatError);
  const resetSelectedSearchedMessage = useResetRecoilState(
    selectedSerchedMessage
  );

  const [inboxFilter, setInboxFilter] = useRecoilState(inboxFilters);
  const isExternalUser = useRecoilValue(useIsExternalUser);
  const [selectedTicketDetails, setSelectedTicket] =
    useRecoilState(selectedTicket);
  const setUnreadNotificationCount = useSetRecoilState(unreadNotificationCount);
  const [slectedMessageTicket, setSlectedMessageTicket] = useRecoilState(
    selectedSerchedMessage
  );
  const [showChat, setShowChat] = useRecoilState(showChatMessage);
  const [ticketsList, setTicketsList] = useRecoilState(ticketList);
  const userDetails = useRecoilValue(loginedUserDetails);
  const [notification, setNotification] =
    useRecoilState<MapToObj>(unreadNotification);
  const [getUnReadMessageCount] = useLazyQuery(GET_UNREAD_MESSAGES_COUNT, {
    fetchPolicy: 'network-only'
  });
  const { width } = useViewport();
  const handleSegmentTrack = useSegmentTrackChat();

  const isMobile = width < 768;
  const removeNotificationListener = useRef<any>(null);
  const removeGroupStatusListener = useRef<any>(null);

  const [updateTicketUnreadStatus] = useUpdateUnreadStatus();

  /**
   * @description
   * Recoil value can't be use inside callback so it is added a ref
   */
  selectedTicketRef.current = selectedTicketDetails;
  ticketListRef.current = ticketsList;
  ticketFilterRef.current = inboxFilter;
  headClinicIdRef.current = userDetails?.headClinicId;
  searchedTicketIdRef.current = slectedMessageTicket.ticketId;

  const client = useApolloClient();

  const checkChatAuth = (error: any) => {
    if (error.message === LOGIN_ERRORS.UNAUTHORIZED_ACCESS) {
      setShowUnAuthChatModal(true);
    }
  };
  const debouncedGetUnreadMessageCount = debounce(() => {
    getUnReadMessageCount?.({
      variables: { input: { patientId: patientId } },
      onCompleted: (data) => {
        setUnReadMessageCount?.(data?.getUnReadMessageCount);
      }
    });
  }, 2000);

  const getPaginationLimit = (): number => {
    const DEFAULT_LIMIT = 15;
    const BULK_ADD_LIMIT = 50;
    return inboxFilter.viewTicketsWithoutMe ? BULK_ADD_LIMIT : DEFAULT_LIMIT;
  };
  const getTicketsList = async (variables: GetTicketsListInput) => {
    return client.query<TicketData, GetTicketsListInput>({
      query: GET_TICKET_LIST,
      variables,
      fetchPolicy: 'no-cache'
    });
  };

  const getRecentMessages = async () => {
    return client.query<RecentTicketData, any>({
      query: GET_RECENT_TICKET_LIST,
      variables: {
        ticketLimit: 1,
        messagesPerTicket: 1
      },
      fetchPolicy: 'no-cache'
    });
  };

  const updateNotificationMap = (tickets: Ticket[]) => {
    setNotification((prevState) => {
      const updatedNotification: MapToObj = cloneDeep(prevState);
      if (isDataExist(tickets)) {
        tickets.forEach((ticketData) => {
          if (selectedTicketDetails.id !== ticketData.id) {
            updatedNotification[`${ticketData.id}`] =
              ticketData.unReadMessageCount || 0;
          }
        });
      }
      return updatedNotification;
    });
  };

  const scrollToTicket = (ticketId: number) => {
    setTimeout(() => {
      const ticketElement = document.getElementById(ticketId.toString());
      if (ticketElement) ticketElement.scrollIntoView({ block: 'center' });
    }, 0);
  };
  const getInitialFilters = () => {
    if (isFromPatientDetails) {
      return { ...defaultInboxFilters };
    }

    if (isExternalUser) {
      return { ...defaultInboxFilters, status: ['OPEN'] };
    }

    return { ...defaultInboxFilters, status: ['OPEN'], snoozeType: ['NONE'] };
  };

  useEffect(() => {
    const filters = getInitialFilters();
    setInboxFilter(filters);
    setShowLoader(true);
    const variables: GetTicketsListInput = {
      params: {
        ...filters,
        ticketId: redirectionParams.ticketId,
        patientId,
        paginationInfo: { limit: getPaginationLimit(), offset: 0 }
      },
      loginedUserId: userDetails.id
    };

    getTicketsList(variables)
      .then(({ data }) => {
        setShowLoader(false);
        updateTicketListFlag?.();
        setTicketListLoaded(true);
        if (isDataExist(data.getTickets.tickets)) {
          setTicketsList([...data.getTickets.tickets]);
          ticketPaginationInfo.current.totalCount = data.getTickets.totalCount;

          const pagInfo = data.getTickets.paginationInfo;
          if (redirectionParams.ticketId) {
            // storing initial pagination data
            ticketPaginationInfo.current.offset = pagInfo.offset;
            // select ticket and message
            const ticketDetails = data.getTickets.tickets.find(
              (ticket) => ticket.id === redirectionParams.ticketId
            );
            if (ticketDetails) {
              setSelectedTicket(ticketDetails);
              if (redirectionParams.messageId) {
                setSlectedMessageTicket({
                  messageId: redirectionParams.messageId || '',
                  ticketId: redirectionParams.ticketId,
                  text: ''
                });
              }
            }
            const redirectFrom = query.get('redirectFrom');
            if (redirectFrom && redirectFrom === 'slack') {
              handleSegmentTrack(CHAT_TRACK_EVENTS.SLACK_REDIRECTION, {
                zenchat_ID: redirectionParams.ticketId,
                zenchat_topic: ticketDetails?.topicInfo?.topicName,
                zenchat_urgent: ticketDetails?.isUrgent ? 'Yes' : 'No',
                zenchat_message_id: redirectionParams.messageId ? 'Yes' : 'No'
              });
            }
            scrollToTicket(redirectionParams.ticketId);
            !isFromPatientDetails && navigate(pagePaths.inbox);
          }
          updateNotificationMap(data.getTickets.tickets);
        } else {
          setNotification({});
          setTicketsList([]);
        }
      })
      .catch((error) => {
        checkChatAuth(error);
        showToast(translate('ERROR.DEFAULT_ERROR'), false);
      });
  }, []);

  const getTicketById = (id: number, optionalArg?: TicketByIdOptionalArg) => {
    return client.query<TicketById, GetTicketByIdInput>({
      query: GET_TICKET_BY_ID,
      variables: {
        ticketId: id,
        loginedUserId: userDetails.id,
        ...optionalArg
      },
      fetchPolicy: 'no-cache'
    });
  };

  const filterDetails = {
    params: {
      ...inboxFilter,
      patientId,
      paginationInfo: {
        limit: 1,
        offset: 0
      },
      ticketPosition: 'TOP'
    },
    loginedUserId: userDetails.id
  };
  const filterRef = useRef<GetTicketsListInput>(filterDetails);
  filterRef.current = filterDetails;

  const getNotifiedTicketIndex = (
    tickets: Ticket[],
    notifiedTicketId: number
  ) => {
    const updatedTicketIndex = tickets.findIndex(
      (ticket) => ticket.id === notifiedTicketId
    );
    return updatedTicketIndex;
  };

  const updateExistingTicket = (
    tickets: Ticket[],
    updatedTicketIndex: number
  ) => {
    const updateTicket = tickets.splice(updatedTicketIndex, 1);
    updateTicket[0].updatedAt = new Date().toISOString();
    setTicketsList([...updateTicket, ...tickets]);
    ticketListRef.current = [...updateTicket, ...tickets];
  };

  const updateTicketList = useCallback(
    (notifiedTicketId: number) => {
      if (notifiedTicketId) {
        const tickets = cloneDeep(ticketListRef.current);
        const updatedTicketIndex = getNotifiedTicketIndex(
          tickets,
          notifiedTicketId
        );
        if (updatedTicketIndex !== -1) {
          updateExistingTicket(tickets, updatedTicketIndex);
        } else {
          // NOTE: for filtering based on applied filter getTicketsList
          // is used instead of getTicketById
          // different ticket will be returned if the no match is found
          getTicketsList(filterRef.current).then(({ data }) => {
            const ticket = data.getTickets.tickets?.[0];
            const isCurrentNotifiedTicket = ticket?.id === notifiedTicketId;

            if (!isCurrentNotifiedTicket) return;

            const updatedTicketIndex = getNotifiedTicketIndex(
              ticketListRef.current,
              notifiedTicketId
            );
            if (updatedTicketIndex !== -1) {
              const tickets = cloneDeep(ticketListRef.current);
              updateExistingTicket(tickets, updatedTicketIndex);
              return;
            }

            const ticketContextPatientId = ticket.ticketTopicContextInfo.filter(
              (context) => context.contextType === 'PATIENT'
            )[0]?.contextValueId;

            if (
              !isFromPatientDetails ||
              (isFromPatientDetails && ticketContextPatientId === patientId)
            ) {
              setTicketsList((oldList) => {
                const updatedList = [ticket, ...oldList];
                ticketListRef.current = updatedList;
                return updatedList;
              });
              setNotification((prevState) => {
                const newState = { ...prevState };
                newState[ticket.id] = ticket.unReadMessageCount;
                return newState;
              });
            }
          });
        }
      }
    },
    [ticketsList]
  );

  const removeRealtimeListener = () => {
    //NOTE: This setTimeout is added to avoid conflict
    // between the get tickets message and reltime nitification changes
    if (removeNotificationListener.current) {
      removeNotificationListener.current();
    }
    if (removeGroupStatusListener.current) {
      removeGroupStatusListener.current();
    }
  };

  useEffect(() => {
    return () => {
      removeRealtimeListener();
    };
  }, []);

  useEffect(() => {
    if (!isMobile) {
      setShowChat(false);
    }
  }, [isMobile]);

  useEffect(() => {
    const ticketId = selectedTicketDetails.id;
    if (notification[`${ticketId}`]) {
      setUnreadNotificationCount(
        (prevCount) => prevCount - notification[`${ticketId}`]
      );
    }
    if (selectedTicketDetails.id) {
      if (
        selectedTicketDetails.mentionedMessageId &&
        selectedTicketDetails.isMentionedMessageRead
      ) {
        const tickets = cloneDeep(ticketListRef.current);
        const updatedTicketIndex = tickets.findIndex(
          (ticket) => ticket.id === selectedTicketDetails.id
        );
        if (updatedTicketIndex !== -1) {
          tickets[updatedTicketIndex].mentionedMessageId = null;
          setTicketsList(tickets);
        }
      }

      if (
        selectedTicketDetails.isUserPartOfTicket &&
        !selectedTicketDetails.isMentionedMessageRead
      ) {
        const tickets = cloneDeep(ticketListRef.current);
        const updatedTicketIndex = tickets.findIndex(
          (ticket) => ticket.id === selectedTicketDetails.id
        );
        if (updatedTicketIndex !== -1) {
          tickets[updatedTicketIndex].isMentionedMessageRead = true;
          setTicketsList(tickets);
        }
      }
    }
  }, [selectedTicketDetails, setNotification]);

  /**
   * @description funtion to updated mentioned ticket if there is a notifiction
   * @param mentionedMessageId mention message id
   * @param entityId mentioned ticket id
   * @return {void}
   */
  const updateMentionedTicket = (
    mentionedMessageId: string,
    entityId: string
  ) => {
    const tickets = [...ticketListRef.current];
    const indexOfUpdatedTicket = tickets.findIndex(
      (ticket) => ticket.id === Number(entityId)
    );
    if (
      (indexOfUpdatedTicket !== -1 &&
        tickets[indexOfUpdatedTicket]?.isMentionedMessageRead) ||
      !tickets[indexOfUpdatedTicket]?.mentionedMessageId
    ) {
      const updatedTicket = { ...tickets[indexOfUpdatedTicket] };
      updatedTicket.mentionedMessageId = mentionedMessageId;
      updatedTicket.isMentionedMessageRead = false;
      tickets[indexOfUpdatedTicket] = updatedTicket;
      setTicketsList(tickets);
    }
  };

  useEffect(() => {
    if (liveUpdate?.entityId && isFirstUpdate) {
      const entityId = liveUpdate.entityId;
      if (ticketPaginationInfo.current.offset === 0)
        updateTicketList(Number(liveUpdate.entityId));
      if (`${selectedTicketDetails.id}` !== entityId) {
        handleUnread(entityId);
        const updatedNotification: MapToObj = { ...notification };
        const messageCount = liveUpdate.messageIds.length;
        /* if notification for ticket not in state,
         * count will not be updated.
         * when ticket details are fetched,
         * count will be updated for those*/
        if (updatedNotification[entityId] !== undefined) {
          updatedNotification[entityId] += messageCount;
        }
        if (liveUpdate?.mentionedMessageId) {
          updateMentionedTicket(liveUpdate?.mentionedMessageId, entityId);
        }
        setNotification(updatedNotification);
      }
    }
  }, [liveUpdate, isFirstUpdate]);

  const checkNoFilterApplied = () => {
    if (ticketFilterRef.current) {
      return (
        ticketFilterRef.current.createdByUsers.length === 0 &&
        ticketFilterRef.current.ownedBy.length === 0 &&
        ticketFilterRef.current.urgency.length === 0 &&
        ticketFilterRef.current.readStatus.length === 0 &&
        ticketFilterRef.current.status.length === 0 &&
        ticketFilterRef.current.searchTerm === '' &&
        !ticketFilterRef.current.viewTicketsWithoutMe &&
        !ticketFilterRef.current.advancedSearch?.includeParticipantEmail
      );
    }
    return false;
  };

  const updateRecentMessage = () => {
    getRecentMessages()
      .then((data) => {
        setUnreadNotificationCount(
          data.data.getRecentTickets.unReadMessageCount
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleNotificationDelete = (updates: Notification) => {
    setNotification((prevState) => {
      const updatedNotification: MapToObj = cloneDeep(prevState);
      updatedNotification[`${updates.entityId}`] =
        updatedNotification[`${updates.entityId}`] > 0
          ? updatedNotification[`${updates.entityId}`] - 1
          : 0;
      return { ...updatedNotification };
    });
  };
  const handleNotificationSuccess = (
    updates: NotificationOutput | Notification
  ) => {
    if (!isListenerRegistered) {
      setListenerRegistered(true);
    }
    const isMessageInCurrentOpenChat =
      Object.values(updates)?.[0]?.entityId ===
      selectedTicketRef.current?.id.toString();

    const isMessageForDifferentHeadClinic = isForDifferentHeadClinic(
      Object.values(updates)?.[0]?.customData,
      headClinicIdRef.current
    );

    if (
      Object.values(updates)[0]?.messageCategory === 'NEW_MESSAGE' &&
      !isMessageInCurrentOpenChat &&
      !isMessageForDifferentHeadClinic &&
      isFromPatientDetails
    )
      debouncedGetUnreadMessageCount();

    if (updates.messageCategory === 'DELETE_MESSAGE') {
      {
        isFromPatientDetails && debouncedGetUnreadMessageCount();
      }

      const tickets = cloneDeep(ticketListRef.current);

      tickets.forEach((ticket, index) => {
        if (ticket.id === Number(updates.entityId)) {
          tickets[index] = {
            ...tickets[index],
            isMentionedMessageRead: false,
            mentionedMessageId: null
          };
          setTicketsList(tickets);
        }
      });

      handleNotificationDelete(updates as Notification);
    }
    Object.values(updates).forEach((update: Notification) => {
      const { customData } = update;
      if (isForDifferentHeadClinic(customData, headClinicIdRef.current)) {
        return;
      }

      if (update.entityId) {
        setLiveUpdate(update);
        chat.setMessageReceivedStatus({
          userId: userDetails.id,
          messageIds: update.messageIds
        });
      }
    });
  };

  // temporary fix for applying filter
  // once getTicketById supports filter remove this
  const getTicketByFilter = (
    ticketId: number,
    onCompleted: (ticket: Ticket) => void
  ) => {
    const { loginedUserId, params } = filterRef.current;
    const updatedFilter = {
      params: {
        ...params,
        ticketId
      },
      loginedUserId
    };

    getTicketsList(updatedFilter).then(({ data }) => {
      const ticket = data.getTickets.tickets?.[0];
      const isCurrentNotifiedTicket = ticket?.id === ticketId;

      if (!isCurrentNotifiedTicket) return;
      const ticketDetails = data.getTickets.tickets[0];
      onCompleted(ticketDetails);
    });
  };
  const handleGroupStatusListener = (updates: any) => {
    handleGroupStatusListenerSuccess(
      updates,
      headClinicIdRef,
      ticketListRef,
      selectedTicketRef,
      ticketFilterRef,
      userDetails,
      searchedTicketIdRef,
      ticketPaginationInfo,
      isFromPatientDetails,
      defaultSelectedTicket,
      patientId,
      setSelectedTicket,
      setTicketsList,
      getTicketById,
      resetSelectedSearchedMessage,
      setShowChat,
      updateSelectedTicketDetails,
      checkNoFilterApplied,
      setNotification,
      updateRecentMessage,
      getTicketByFilter,
      setUnReadMessageCount,
      getUnReadMessageCount
    );
  };

  const addRealtimeListener = () => {
    // remove listener before assigning
    removeRealtimeListener();

    removeNotificationListener.current = chat.addNotificationListener(
      handleNotificationSuccess
    );
    removeGroupStatusListener.current = chat.addGroupStatusListener(
      handleGroupStatusListener
    );
  };

  useEffect(() => {
    removeRealtimeListener();
    //Note: 2s delay added for removing conflict tickets from get tickets and firstore listener
    setTimeout(() => {
      addRealtimeListener();
    }, 2000);
  }, [chat, userDetails]);

  const handleUnread = (entityId: string) => {
    const param = {
      variables: {
        ticketId: Number(entityId),
        isRead: false
      }
    };

    updateTicketUnreadStatus(param);
  };

  useEffect(() => {
    return () => {
      resetTicketList();
      resetSelectedTicket();
      resetSelectedSearchedMessage();
    };
  }, []);

  const handleSearchTrack = (data: TicketData) => {
    let currentResultNo = 0;
    data.getTickets.tickets.forEach((ticket) => {
      const matchCount = ticket.ticketChatSearchMessage?.length;
      currentResultNo += matchCount ? matchCount : 1;
    });
    searchTrack.current.resultDisplayed += currentResultNo;
    handleSegmentTrack(CHAT_TRACK_EVENTS.ZENCHAT_SEARCHED, {
      search_results_no: searchTrack.current.resultDisplayed,
      scroll_no: searchTrack.current.scrollNo,
      view_type: isFromPatientDetails ? VIEW_TYPE.SIDE_PANEL : VIEW_TYPE.INBOX
    });
  };

  useEffect(() => {
    if (isFirstUpdate) {
      setShowLoader(true);
      resetTicketList();
      const variables: GetTicketsListInput = {
        params: {
          ...inboxFilter,
          patientId: patientId,
          paginationInfo: { limit: getPaginationLimit(), offset: 0 }
        },
        loginedUserId: userDetails.id
      };
      getTicketsList(variables)
        .then((data) => {
          updateRecentMessage();
          setShowLoader(false);
          if (inboxFilter.searchTerm.length > 0) {
            searchTrack.current.resultDisplayed = 0;
            searchTrack.current.scrollNo = 0;
            handleSearchTrack(data.data);
          }
          setTicketsList([...data.data.getTickets.tickets]);
          ticketPaginationInfo.current.offset =
            data.data.getTickets.paginationInfo.offset;
          ticketPaginationInfo.current.totalCount =
            data.data.getTickets.totalCount;
          updateNotificationMap(data.data.getTickets.tickets);
        })
        .catch((error) => {
          checkChatAuth(error);
          showToast(translate('ERROR.DEFAULT_ERROR'), false);
        });
    } else {
      setFirstUpdate(true);
    }
  }, [inboxFilter]);

  useEffect(() => {
    if (
      isDataExist(ticketsList) &&
      !selectedTicketDetails.id &&
      !isMobile &&
      !isFromPatientDetails &&
      !redirectionParams.ticketId
    ) {
      setSelectedTicket(ticketsList[0]);
    }
  }, [ticketsList]);

  const ticketData = useMemo(() => {
    if (isDataExist(ticketsList)) {
      return getTicketList(ticketsList);
    }
  }, [ticketsList]);

  const listlength = ticketData?.length || 0;
  /**
   * @description
   * Hooks for opening message in mobile and patient side panel
   */
  useEffect(() => {
    if (selectedTicketDetails.id !== defaultSelectedTicket.id) {
      handleSegmentTrack(CHAT_TRACK_EVENTS.CHAT_OPEN, {
        ...trackData,
        ...trackContextData,
        is_participant: 'Yes'
      });
      if (ticketDuration.current.ticketId === -1) {
        // entering first ticket
        ticketDuration.current.ticketId = selectedTicketDetails.id;
        ticketDuration.current.startTime = Date.now();
      } else {
        // leaving a ticket
        handleSegmentTrack(
          CHAT_TRACK_EVENTS.ZENCHAT_INBOX_CHAT,
          {
            duration: formatSegmentTimeDiff(
              Date.now() - ticketDuration.current.startTime
            ),
            zenchat_ID: ticketDuration.current.ticketId
          },
          true
        );
        ticketDuration.current.startTime = Date.now();
        ticketDuration.current.ticketId = selectedTicketDetails.id;
      }
    }
    if ((isMobile || isFromPatientDetails) && selectedTicketDetails.id) {
      setShowChat(true);
    }
  }, [selectedTicketDetails?.id]);

  const ticketDurationRef = ticketDuration.current;
  useEffect(() => {
    return () => {
      handleSegmentTrack(CHAT_TRACK_EVENTS.ZENCHAT_INBOX_CHAT, {
        duration: formatSegmentTimeDiff(
          Date.now() - ticketDurationRef.startTime
        ),
        zenchat_ID: ticketDurationRef.ticketId
      });
    };
  }, []);

  const handleTicketSelect = (ticketDetails: TicketDetailsCard) => {
    if (selectedTicketDetails.id !== ticketDetails.id) {
      setSelectedTicket(ticketDetails.ticketDetails);
    }
    if (ticketDetails.metaData.searchedMessageDetails.message) {
      const searchedTicketDetails: SelectedSearchedTicket = {
        messageId:
          ticketDetails.metaData.searchedMessageDetails.messageId || '',
        ticketId: ticketDetails.id,
        text: ticketDetails.metaData.searchedMessageDetails.message
      };
      setSlectedMessageTicket(searchedTicketDetails);
    } else if (
      ticketDetails.metaData?.mentionedMessageId &&
      !ticketDetails.metaData.isMentionedMessageRead &&
      notification[`${ticketDetails.id}`]
    ) {
      const searchedTicketDetails: SelectedSearchedTicket = {
        messageId: ticketDetails.metaData?.mentionedMessageId || '',
        ticketId: ticketDetails.id
      };
      setSlectedMessageTicket(searchedTicketDetails);
    } else {
      if (slectedMessageTicket.messageId) {
        setSlectedMessageTicket(defaultSelectedSearchedTicket);
      }
    }
  };

  const update = (isBottomUpdate: boolean, previousScrollHeight: number) => {
    ticketPaginationInfo.current.previousFetchDirection = isBottomUpdate
      ? 'bottom'
      : 'top';
    ticketPaginationInfo.current.previousScrollHeight = previousScrollHeight;

    let offset = ticketPaginationInfo.current.offset;
    let limit = getPaginationLimit();
    if (isBottomUpdate) {
      offset += listlength;
    } else {
      // scroll to top
      if (offset < limit) {
        limit = offset;
        offset = 0;
      } else {
        offset -= limit;
      }
      ticketPaginationInfo.current.offset = offset;
    }
    // don't pass ticketId in update
    const variables: GetTicketsListInput = {
      params: {
        ...inboxFilter,
        patientId: patientId,
        paginationInfo: {
          offset,
          limit
        }
      },
      loginedUserId: userDetails.id
    };
    getTicketsList(variables)
      .then(({ data }) => {
        if (isDataExist(data.getTickets.tickets)) {
          if (
            ticketPaginationInfo.current.offset <
            data.getTickets.paginationInfo.offset
          ) {
            // scroll to bottom
            setTicketsList((prevList) => [
              ...prevList,
              ...data.getTickets.tickets
            ]);
          } else {
            // scroll to top
            setTicketsList((prevList) => [
              ...data.getTickets.tickets,
              ...prevList
            ]);
            ticketPaginationInfo.current.offset =
              data.getTickets.paginationInfo.offset;
          }
          if (inboxFilter.searchTerm.length > 0) {
            searchTrack.current.scrollNo += 1;
            handleSearchTrack(data);
          }
          updateNotificationMap(data.getTickets.tickets);
        }
        if (ticketPaginationInfo.current.previousFetchDirection === 'top') {
          setTimeout(() => {
            if (ticketScrollRef && ticketScrollRef.current) {
              ticketScrollRef.current.scrollTop =
                ticketScrollRef.current.scrollHeight -
                ticketPaginationInfo.current.previousScrollHeight;
            }
          }, 0);
        }
      })
      .catch((error) => {
        checkChatAuth(error);
        showToast(translate('ERROR.DEFAULT_ERROR'), false);
      });
  };

  const hasMore = {
    top: ticketPaginationInfo.current.offset > 0,
    bottom:
      ticketPaginationInfo.current.offset + listlength <
      ticketPaginationInfo.current.totalCount
  };

  const getFormattedSearchMessage = (
    searchedMessageDetails: SearchedMessageDetails
  ) => {
    let searchedMessage = searchedMessageDetails.message;
    if (
      searchedMessageDetails.rich_text_info &&
      isDataExist(searchedMessageDetails.rich_text_info?.rich_text_details)
    ) {
      searchedMessage = formatMentionedMessage(
        searchedMessageDetails.rich_text_info,
        isExternalUser
      );
    }
    return formatMessage(searchedMessage, inboxFilter.searchTerm);
  };

  const handleChatClose = () => {
    setShowChat(false);
    resetSelectedTicket();
  };

  const getChatLoaderView = () => {
    const ticketLength = ticketData?.length || 0;
    const isTicketListEmpty = ticketLength === 0;
    if (isTicketListEmpty) return null;

    return (
      <div className='md:mr-2 md:ml-4'>
        <TicketCardSkeleton />
      </div>
    );
  };

  return (
    <InfiniteScroll
      dataLength={ticketData?.length || 0}
      next={update}
      hasMore={hasMore}
      scrollableTarget={ticketScrollRef}
      loader={getChatLoaderView()}
    >
      <div
        className={cx('w-full py-2 ', {
          'md:pl-4 md:pr-2': !isFromPatientDetails
        })}
      >
        <TicketCardListSkeleton loading={showLoader}>
          {isDataExist(ticketData) ? (
            ticketData?.map((ticketDetails, index) => {
              return (
                <div key={`${ticketDetails.id}_${index}`} className='w-full'>
                  <TicketCard
                    chatId={ticketDetails.id}
                    data={ticketDetails.metaData}
                    updatedAt={ticketDetails.metaData?.updatedAt}
                    isSnoozed={ticketDetails.metaData.isSnoozed}
                    notificationCount={notification[`${ticketDetails.id}`] || 0}
                    unread={
                      ticketDetails.unread ||
                      !ticketDetails.ticketDetails.isUserPartOfTicket
                    }
                    selected={selectedTicketDetails?.id === ticketDetails?.id}
                    status={ticketDetails.status}
                    showMentionIcon={
                      !!ticketDetails.metaData?.mentionedMessageId &&
                      !ticketDetails.metaData.isMentionedMessageRead &&
                      !!notification[`${ticketDetails.id}`]
                    }
                    onSelect={() => handleTicketSelect(ticketDetails)}
                    isExternalUser={isExternalUser}
                    message={getFormattedSearchMessage(
                      ticketDetails.metaData.searchedMessageDetails
                    )}
                    isFromPatientDetails={isFromPatientDetails}
                  />
                </div>
              );
            })
          ) : (
            <div className='md:w-85 mb-4 break-all'>
              <NoDataMessage
                message={
                  inboxFilter.searchTerm
                    ? translate('INBOX.NO_RESULTS_FOUND', {
                        X: inboxFilter.searchTerm
                      })
                    : translate('Messages.noPending')
                }
              />
            </div>
          )}
        </TicketCardListSkeleton>
        <If condition={ticketData?.length === 0}></If>
      </div>
      <If condition={showChat && !!selectedTicketDetails.id && !showLoader}>
        <If condition={isMobile}>
          <MobileDrawer className='md:hidden xxs:block z-30'>
            <ChatMessage
              setUnReadMessageCount={setUnReadMessageCount}
              handleChatClose={handleChatClose}
              isFromPatientDetails={isFromPatientDetails}
              setShowSidePanel={() => false}
            />
          </MobileDrawer>
        </If>
        <If condition={!isMobile && isFromPatientDetails}>
          <DesktopDrawer className='md:block xxs:hidden'>
            <div className='relative patient-details-inbox h-full'>
              <button
                className='absolute cursor-pointer pr-1.5 right-1 top-1'
                onClick={handleChatClose}
              >
                <CloseIcon className='h-3 w-3' />
              </button>
              <ChatMessage
                setUnReadMessageCount={setUnReadMessageCount}
                handleChatClose={handleChatClose}
                isFromPatientDetails={isFromPatientDetails}
                setShowSidePanel={() => false}
              />
            </div>
          </DesktopDrawer>
        </If>
      </If>
    </InfiniteScroll>
  );
};

export default TicketCardList;
