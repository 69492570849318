import { FC } from 'react';

import { RHFTimeRangePicker } from 'components';
import { translate } from 'utils';

const AvailabilityPicker: FC = () => {
  return (
    /** to be replaced */
    <div className='flex flex-col'>
      <span className='mb-2'>{translate('settings.availability')}</span>
      <div className='w-full mb-4'>
        <RHFTimeRangePicker
          name='availability.weekday'
          placeholderText={translate('availability.weekday')}
          textToBeDisaplyedWithValue={`(${translate('weekdays.weekdays')})`}
          hideErrorMessage={false}
        />
      </div>
      <div className='w-full'>
        <RHFTimeRangePicker
          name='availability.weekend'
          placeholderText={translate('availability.weekend')}
          textToBeDisaplyedWithValue={`(${translate('weekend.weekend')})`}
          hideErrorMessage={false}
        />
      </div>
    </div>
  );
};

export default AvailabilityPicker;
