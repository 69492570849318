import { FC } from 'react';

import classNames from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { ZENSTAGE_OPTIONS } from 'constants/options';
import { ALIGNER_INFO_TYPE } from 'types/TPVForm.types';
import { translate } from 'utils';

import RhfSelect from './Generics/Rhf/RhfSelect';
import { NumberInput } from './NumberInput';
import Radio from './RadioGroup/Radio/Radio';
type props = {
  setInputName: string;
  zenStageInputName: string;
  label: string;
  className?: string;
  radioName: string;
  maxLimit?: number;
  minLimit?: number;
  disabled?: boolean;
  showZenstage: boolean;
  source?: string;
};
export const AlignerInput: FC<props> = ({
  label,
  setInputName,
  className,
  radioName,
  maxLimit,
  minLimit,
  zenStageInputName,
  disabled = false,
  showZenstage = false,
  source
}) => {
  const isPrescriptionSummary = source === 'PrescriptionSummary';
  const { watch, control } = useFormContext();
  const currentAligner = watch(radioName);
  return (
    <div className={twMerge('flex flex-col', className)}>
      <div className='text-sm text-black'>{label}</div>
      <div className='mt-2.5'>
        <div
          className={classNames('grid grid-cols-1', {
            'md:flex md:space-x-60.5': !isPrescriptionSummary,
            'md:flex-wrap md:space-y-5': isPrescriptionSummary
          })}
        >
          <div className='flex space-x-10px'>
            <div className='pt-3 text-black '>
              <Controller
                name={radioName}
                control={control}
                render={({ field }) => {
                  return (
                    <div>
                      <Radio
                        label={translate('refinementForm.aligner.set')}
                        name={radioName}
                        value={ALIGNER_INFO_TYPE.SET}
                        checked={field?.value === ALIGNER_INFO_TYPE.SET}
                        onChange={field?.onChange}
                        inputProps={{
                          disabled
                        }}
                      />
                    </div>
                  );
                }}
              />
            </div>
            <NumberInput
              name={setInputName}
              disabled={disabled || currentAligner != ALIGNER_INFO_TYPE.SET}
              maxLimit={maxLimit}
              minLimit={minLimit}
              isChecked={currentAligner === ALIGNER_INFO_TYPE.SET}
              className='bg-white'
            />
          </div>
          {showZenstage && (
            <div className='md:flex md:space-x-10px grid grid-cols-1'>
              <div className=' pt-3 text-black pb-2'>
                <Controller
                  name={radioName}
                  control={control}
                  render={({ field }) => (
                    <Radio
                      label={translate('refinementForm.aligner.zenstage')}
                      name={radioName}
                      value={ALIGNER_INFO_TYPE.ZEN_STAGE}
                      checked={field?.value === ALIGNER_INFO_TYPE.ZEN_STAGE}
                      onChange={field?.onChange}
                      inputProps={{
                        disabled
                      }}
                    />
                  )}
                />
              </div>
              <div className='md:w-230px w-full'>
                <RhfSelect
                  isDisabled={
                    disabled || currentAligner != ALIGNER_INFO_TYPE.ZEN_STAGE
                  }
                  name={zenStageInputName}
                  options={ZENSTAGE_OPTIONS}
                  inputProps={{
                    showErrorTitle: false,
                    controlShouldRenderValue: true,
                    placeholder: translate('Select'),
                    selectHeight: '3rem'
                  }}
                  showError={false}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
