import { FC, ReactNode } from 'react';

import { useHasAccess } from 'utils/hasAccess';

interface Props {
  label: string;
  isEditable?: boolean;
  children?: ReactNode;
  value?: string;
  className?: string;
  permission?: string;
}
const UserInfoInput: FC<Props> = ({
  isEditable = false,
  children,
  value,
  className = '',
  label,
  permission = ''
}) => {
  const hasPermission = useHasAccess(permission);

  return (
    <div className={className}>
      <p className='font-bold'>{label}</p>
      <div className='mt-2'>
        {isEditable && hasPermission ? (
          children
        ) : (
          <p className='break-words'>{value}</p>
        )}
      </div>
    </div>
  );
};

export default UserInfoInput;
