import { FC } from 'react';

import cx from 'classnames';
import { HeaderGroup } from 'react-table';

import SortArrow from './SortArrow';

interface TableHeaderComponentProps {
  headerGroups: HeaderGroup<object>[];
  isCustom?: boolean;
  className?: string;
  stickyHeader?: boolean;
}

const TableHeaderComponent: FC<TableHeaderComponentProps> = ({
  headerGroups,
  isCustom,
  className,
  stickyHeader
}) => {
  return (
    <>
      {headerGroups.map((headerGroup, index: number) => (
        <tr {...headerGroup.getHeaderGroupProps()} key={`head-${index}`}>
          {headerGroup.headers.map((column, index: number) =>
            !isCustom ? (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={`head-cell-${index}`}
                id={`${column.render('Header')}`}
                className={cx(
                  `${column.className} ${className}  bg-GRAY py-4 h-14 text-sm text-left px-2 z-1 `,
                  { 'sticky top-0': stickyHeader }
                )}
                style={{
                  width: column.minWidth ? `${column.minWidth}rem` : undefined,
                  maxWidth: column.maxWidth ? `${column.maxWidth}rem` : ''
                }}
              >
                <div className='flex gap-x-2'>
                  {column.render('Header')}
                  {column.canSort && (
                    <SortArrow
                      isSorted={column.isSorted}
                      isSortedDesc={column.isSortedDesc}
                    />
                  )}
                </div>
              </th>
            ) : (
              <th
                {...column.getHeaderProps()}
                key={`head-cell-${index}`}
                className={`bg-LIGHT_BLUE_5 font-normal pt-2 h-6 text-sm text-left   sticky top-0 `}
                style={{
                  width: `${column.width}px`
                }}
              >
                {column.render('Header')}
              </th>
            )
          )}
        </tr>
      ))}
    </>
  );
};

export default TableHeaderComponent;
