import { FC, useState } from 'react';

import { useSetRecoilState } from 'recoil';

import { Modal } from 'components';
import Button from 'components/Button/Button';
import { MobileFullScreenView } from 'components/MobileFullScreenView/MobileFullScreenView';
import { MOBILE_SCREEN_WIDTH } from 'constants/common';
import { HeadClinicSelector } from 'containers/HeadClinicSelector/HeadClinicSelector';
import { useSelectUserClinic } from 'services/hooks/Auth.hooks';
import { GET_LOGINED_USER_DETAILS } from 'services/query/auth';
import { globalSelectedClinic } from 'store/atoms/authAtom';
import { Option } from 'types/select';
import { translate, useViewport } from 'utils';
import { syncTabs } from 'utils/common';

interface Props {
  open: boolean;
  onClose(): void;
}

export const ClinicSelectorPopup: FC<Props> = ({ open, onClose }) => {
  const [selectedClinic, setSelectedClinic] = useState<Option>();
  const { width } = useViewport();
  const isMobile = width < MOBILE_SCREEN_WIDTH;
  const [selectClinic] = useSelectUserClinic({
    refetchQueries: [GET_LOGINED_USER_DETAILS],
    onCompleted: () => {
      setClinic(selectedClinic);
      syncTabs();
      onClose();
    }
  });
  const setClinic = useSetRecoilState(globalSelectedClinic);

  const onClickConfirm = () => {
    selectClinic({
      variables: {
        clinicId: selectedClinic?.value
      }
    });
  };

  const modalContent = (
    <div className='md:p-6 md:w-656px xxs:w-full h-full'>
      <p className='text-xl xxs:hidden md:block'>
        {translate('multiclinic.title')}
      </p>
      <p className='mb-2 text-sm mt-6'>
        {translate('multiclinic.description')}
      </p>
      <HeadClinicSelector
        selectedClinic={selectedClinic}
        onChange={(selected) => setSelectedClinic(selected)}
      />
      <div className='flex items-center w-full justify-end md:mt-6'>
        <Button
          type='button'
          className='text-14px leading-6 py-3 px-5 bg-PRIMARY text-white w-full md:w-auto xxs:mt-10 md:mt-0'
          onClick={onClickConfirm}
          disabled={!selectedClinic}
        >
          {isMobile
            ? translate('submit_button_rounded_(short).button')
            : translate('confirm.confirm')}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        open={open && !isMobile}
        onClose={() => null}
        showCloseIcon={false}
      >
        {modalContent}
      </Modal>
      {open && isMobile && (
        <div className='md:hidden'>
          <MobileFullScreenView
            header={
              <p className='text-20px'>{translate('multiclinic.title')}</p>
            }
          >
            {modalContent}
          </MobileFullScreenView>
        </div>
      )}
    </>
  );
};
