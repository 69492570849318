import { useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  INBOX_DROPDOWN_KEY,
  INBOX_ROLE,
  SEGMENT_FILTER_KEY
} from 'constants/inbox';
import { CHAT_TRACK_EVENTS } from 'constants/segment';
import { useSegmentTrackChat } from 'services/hooks/Segment.hooks';
import { loginedUserDetails } from 'store/atoms';
import { defaultInboxFilters } from 'store/atoms/constants';
import { inboxFilters } from 'store/atoms/inbox';
import { Option } from 'types/select';
import { TICKET_STATUS } from 'types/TreatmentPlanReview.types';
import { checkIsExternal, filterOutRoles } from 'utils/common';

const removeLocalOption = (
  prev: SelectedFilters,
  option: Option,
  key: string
) => {
  return {
    ...prev,
    [key]: prev[key].filter(
      (selectedOption: Option) => selectedOption.value !== option.value
    )
  };
};

const removeGlobalOption = (prev: any, option: Option, key: string) => {
  return {
    ...prev,
    [key]: prev[key]?.filter((value: string) => value !== option.value)
  };
};

const clearOptions = (prev: any, key: string) => {
  return {
    ...prev,
    [key]: []
  };
};

const addLocalOption = (
  prevSelectedOptions: SelectedFilters,
  option: Option,
  key: string
) => {
  return {
    ...prevSelectedOptions,
    [key]: [...(prevSelectedOptions[key] || []), option]
  };
};

const addGlobalOption = (prev: any, option: Option, key: string) => {
  const updated = {
    ...prev,
    [key]: [...(prev[key] || []), option.value]
  };
  const isCloseSelected = option.value === TICKET_STATUS.CLOSE;
  if (isCloseSelected) {
    updated.snoozeType = [];
  }
  return updated;
};

type SelectedFilters = Record<string, Option[]>;

const getCurrentOptions = (
  selectedOptions: Option[],
  currentChange: Option
) => {
  const isPrevSelected = selectedOptions.includes(currentChange);
  let latestOptions = [];
  if (isPrevSelected) {
    latestOptions = selectedOptions.filter(
      (option) => option.value !== currentChange.value
    );
  } else {
    latestOptions = [...selectedOptions, currentChange];
  }
  return latestOptions;
};

const useOptionSelector = () => {
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({});
  const handleSegmentTrack = useSegmentTrackChat();
  const loginedUser = useRecoilValue(loginedUserDetails);

  const setSelectedFilterOptions = useSetRecoilState(inboxFilters);

  const resetFilterByKey = (key: string) => {
    setSelectedFilters((prev) => clearOptions(prev, key));
    setSelectedFilterOptions((prev) => clearOptions(prev, key));
  };

  const handleDependedFilters = (key: string, option: Option) => {
    const selectedOptions = selectedFilters[key] || [];
    const currentOptions = getCurrentOptions(selectedOptions, option);
    const isFiltered = currentOptions.length > 0;
    if (key === 'country' && isFiltered) {
      resetFilterByKey(INBOX_DROPDOWN_KEY.Clinic);
      resetFilterByKey(INBOX_DROPDOWN_KEY.Doctor);
    }

    if (key === INBOX_DROPDOWN_KEY.Clinic && isFiltered) {
      resetFilterByKey(INBOX_DROPDOWN_KEY.Doctor);
    }
  };

  const toggleFilterOption = (key: string, option: Option) => {
    const selectedOptions = selectedFilters[key] || [];

    handleDependedFilters(key, option);

    if (selectedOptions.includes(option)) {
      setSelectedFilters((prev) => removeLocalOption(prev, option, key));
      setSelectedFilterOptions((prev) => removeGlobalOption(prev, option, key));
    } else {
      const segmentDetails = {
        user_id: loginedUser?.id,
        user_type: checkIsExternal(loginedUser?.groups)
          ? INBOX_ROLE.EXTERNAL
          : INBOX_ROLE.INTERNAL,
        user_role: filterOutRoles(loginedUser?.groups),
        filter_type: SEGMENT_FILTER_KEY[key]
      };
      handleSegmentTrack(
        CHAT_TRACK_EVENTS.ZENCHAT_FILTER_SELECTED,
        segmentDetails
      );
      setSelectedFilters((prev) => addLocalOption(prev, option, key));
      setSelectedFilterOptions((prev) => addGlobalOption(prev, option, key));
    }
  };

  const resetDropdownFilters = () => {
    setSelectedFilters({});
    setSelectedFilterOptions((prevFilter) => {
      return {
        ...prevFilter,
        ...defaultInboxFilters,
        searchTerm: prevFilter.searchTerm,
        status: prevFilter.status
      };
    });
  };

  return {
    selectedFilters,
    toggleFilterOption,
    resetDropdownFilters,
    resetFilterByKey
  };
};

export default useOptionSelector;
