import { FC } from 'react';

import { InfoCardView } from 'components/InfoCardView/InfoCardView';
import { AlignerIPRInfo } from 'types/TPVForm.types';
import { translate } from 'utils';

import TreatmentSpecificationsRefinementForm from './TreatmentSpecificationsRefinementForm';

type Props = {
  isDisabled?: boolean;
  predecessorTPVData?: AlignerIPRInfo;
  formType?: string;
  source?: string;
};
const TreatmentSpecificationsRefinement: FC<Props> = ({
  isDisabled,
  predecessorTPVData,
  formType,
  source
}) => {
  return (
    <div className='flex flex-col space-y-5'>
      <InfoCardView
        message={translate('treatmentSpecification.external.info.message')}
        classname='py-2 px-2 md:items-center'
        isExternal={false}
        iconClassname='text-YELLOW_4'
      />

      <div className=' bg-GRAY5 p-4 rounded-md'>
        <TreatmentSpecificationsRefinementForm
          formType={formType}
          predecessorTPVData={predecessorTPVData}
          isDisabled={isDisabled}
          source={source}
        />
      </div>
    </div>
  );
};

export default TreatmentSpecificationsRefinement;
