import { FC, ImgHTMLAttributes, useEffect, useRef, useState } from 'react';

type ProgressiveImageProps = {
  src?: string;
  placeholder?: string;
} & ImgHTMLAttributes<HTMLImageElement>;

const ProgressiveImage: FC<ProgressiveImageProps> = ({
  src,
  placeholder,
  alt,
  ...props
}) => {
  const [image, setImage] = useState(placeholder);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const loadImage = (src: string) => {
    const img = new Image();
    img.src = src;
    img.onload = () => setImage(src);
  };

  useEffect(() => {
    if (!placeholder) return;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && src) {
          loadImage(src);
          observer.disconnect();
        }
      });
    });

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [src]);

  const getSrc = () => {
    return image || src;
  };

  return <img ref={imgRef} src={getSrc()} alt={alt} {...props} />;
};

export default ProgressiveImage;
