import { useLayoutEffect, useState, useRef } from 'react';

enum RESIZE_STATE {
  INITIATED = 1,
  COMPLETED
}

// https://stackoverflow.com/a/25621277
// 1. set height to 0px and render
// 2. re-calculate height and re-render
export function useTextAreaHeight(
  value: string | ReadonlyArray<string> | number | undefined,
  initialHeight: string,
  placeholder?: string
) {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [textAreaHeight, setTextAreaHeight] = useState(initialHeight);
  const [resizeState, setResizeState] = useState(RESIZE_STATE.COMPLETED);

  useLayoutEffect(() => {
    if (resizeState === RESIZE_STATE.INITIATED) {
      setResizeState(RESIZE_STATE.COMPLETED);
      setTextAreaHeight(`${textAreaRef.current?.scrollHeight}px`);
    }
  }, [resizeState]);

  useLayoutEffect(() => {
    if (value) {
      setTextAreaHeight('0px');
      setResizeState(RESIZE_STATE.INITIATED);
    } else if (placeholder) {
      setTextAreaHeight(initialHeight);
      setResizeState(RESIZE_STATE.INITIATED);
    } else {
      setTextAreaHeight(initialHeight);
    }
  }, [value]);

  return { textAreaRef, textAreaHeight };
}
