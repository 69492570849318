import { FC } from 'react';

import cx from 'classnames';
import moment from 'moment';

import { SnoozeIcon } from 'assets/icons';
import { TIME_FORMAT_12 } from 'constants/settings';

interface CustomTimePickerProps {
  startDate: Date;
  onSelect: (date: moment.Moment) => void;
  selected?: moment.Moment;
}

const checkIsCurrentDate = (date: Date) => {
  const currentDate = new Date();
  return (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() === currentDate.getDate()
  );
};

const setTimeTo9AM = (dateTime: moment.Moment) => {
  return dateTime.set({ hour: 9 }).startOf('hour');
};

const getStart = (startDate: Date) => {
  if (checkIsCurrentDate(startDate)) {
    return moment(startDate).add(1, 'hour').startOf('hour');
  }
  return setTimeTo9AM(moment(startDate));
};

const getTimeOptions = (startDate: Date) => {
  const start = getStart(startDate);
  const end = moment(start).set({ hour: 23 }).startOf('hour');
  const timeOptions = [];
  for (
    let currentTime = start;
    currentTime.isSameOrBefore(end);
    currentTime.add(1, 'hour')
  ) {
    timeOptions.push(moment(currentTime));
  }
  return timeOptions;
};

const CustomTimePicker: FC<CustomTimePickerProps> = ({
  startDate,
  onSelect,
  selected
}) => {
  const timeOptions = getTimeOptions(startDate);

  return (
    <div className='w-[15.625rem] h-[17.25rem] overflow-y-scroll flex flex-col gap-y-4'>
      {timeOptions.map((time) => (
        <button
          key={time.format()}
          onClick={() => onSelect(time)}
          className={cx(
            'text-base px-4 py-3 rounded-lg flex gap-x-4 hover:bg-PRIMARY hover:text-white',
            {
              'bg-PRIMARY text-white':
                !!selected && time.format() === selected.format()
            }
          )}
        >
          <SnoozeIcon />
          <p>{time.format(TIME_FORMAT_12)}</p>
        </button>
      ))}
    </div>
  );
};

export default CustomTimePicker;
