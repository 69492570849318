import { FC } from 'react';

import { ChipLabel } from 'components';
import { If } from 'components/Generics';

type PrescriptionHeaderProp = {
  label: string;
  status?: string;
};

const PrescriptionHeader: FC<PrescriptionHeaderProp> = ({
  label = '',
  status = ''
}) => {
  return (
    <div className='flex flex-col'>
      <If condition={!!status}>
        <ChipLabel className='bg-LIGHT_GREEN text-SUCCESS text-sm leading-22px mb-4'>
          {status}
        </ChipLabel>
      </If>
      <h3 className='md:text-17px text-base text-DEFAULT_TEXT mr-4 font-normal'>
        {label}
      </h3>
    </div>
  );
};

export default PrescriptionHeader;
