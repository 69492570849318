import { FC } from 'react';

import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import ArchesToTreatInput from 'components/ArchesToTreatInput/ArchesToTreatInput';
import { MidlinesInput } from 'components/MidlinesInput/MidlinesInput';
import { OverbiteInput } from 'components/OverbiteInput/OverbiteInput';
import { ProsthesisRestorationInput } from 'components/ProsthesisRestorationInput/ProsthesisRestorationInput';
import { RHFTextArea } from 'components/RHFTextArea/RHFTextArea';
import SpecialNoteTranslation from 'components/SpecialNoteTranslation/SpecialNoteTranslation';
import { TeethSelectRadioInput } from 'components/TeethSelectRadioInput/TeethSelectRadioInput';
import { TreatmentGoalsInput } from 'components/TreatmentGoalsInput/TreatmentGoalsInput';
import {
  TEETH_TO_EXTRACT_OPTIONS,
  TOOTH_MOVEMENT_RESTRICTIONS_OPTIONS
} from 'constants/TPVFormOptions';
import { hideLiveTranslation } from 'store/atoms/authAtom';
import { translate } from 'utils';
type TreatmentSpecificationsExternalZenplusProps = {
  formType: string;
  isDisabled?: boolean;
  isReferral?: boolean;
  source?: string;
  setSpecialNotes: (fieldName: string, result?: string) => void;
};
const TreatmentSpecificationsExternalZenplus: FC<
  TreatmentSpecificationsExternalZenplusProps
> = ({ formType, isDisabled, isReferral, source, setSpecialNotes }) => {
  const isPrescriptionSummary = source === 'PrescriptionSummary';
  const { watch } = useFormContext();
  const watchTranslatedNotes = watch(
    `treatmentSpecifications.${formType}.translatedSpecialNotes`
  );
  const hideLiveTranslationBox = useRecoilValue(hideLiveTranslation);
  return (
    <div
      className={classNames('flex flex-col rounded-md gap-y-10', {
        'bg-GRAY5': !isReferral
      })}
    >
      <TreatmentGoalsInput
        disabled={isDisabled}
        applyDisableStyles={false}
        isZenplus={true}
        classname={classNames('flex', {
          'md:space-x-4': !isPrescriptionSummary,
          'md:flex-col': isPrescriptionSummary
        })}
        fieldName={`treatmentSpecifications.${formType}.treatmentGoals`}
        source={source}
      />
      <ArchesToTreatInput
        isNewTemplatizedPrescription={true}
        disabled={isDisabled}
        inputName={`treatmentSpecifications.${formType}.archesToTreat`}
        label={translate('2.Arches.to.treat')}
      />
      <TeethSelectRadioInput
        title={translate('3.Tooth.movement.restrictions')}
        radioGroupName={`treatmentSpecifications.${formType}.toothMovementRestrictions.toothMovementRestrictions`}
        radioGroupOptions={TOOTH_MOVEMENT_RESTRICTIONS_OPTIONS}
        teethSelectionName={`treatmentSpecifications.${formType}.toothMovementRestrictions.teethNotToMove`}
        disabled={isDisabled}
        animateLabel={`treatmentSpecifications.${formType}.toothMovementRestrictions.teethNotToMove`}
      />
      <OverbiteInput
        source={source}
        name={`treatmentSpecifications.${formType}.overbite`}
        disabled={isDisabled}
        applyDisableStyles={false}
        isNewTemplatizedPrescription={true}
      />
      <MidlinesInput
        disabled={isDisabled}
        name={`treatmentSpecifications.${formType}.midlines.midlines`}
        applyDisableStyles={false}
        isNewTemplatizedPrescription={true}
        fieldObject={{
          shiftUpper: `treatmentSpecifications.${formType}.midlines.shiftUpper`,
          shiftLower: `treatmentSpecifications.${formType}.midlines.shiftLower`,
          shiftLowerPosition: `treatmentSpecifications.${formType}.midlines.shiftLowerPosition`,
          shiftUpperPosition: `treatmentSpecifications.${formType}.midlines.shiftUpperPosition`
        }}
      />
      <TeethSelectRadioInput
        title='6. Teeth Extractions'
        radioGroupName={`treatmentSpecifications.${formType}.extractTeeth.extractTeeth`}
        radioGroupOptions={TEETH_TO_EXTRACT_OPTIONS}
        teethSelectionName={`treatmentSpecifications.${formType}.extractTeeth.teethToExtract`}
        message={translate('teethExtraction.message.zenplus')}
        disabled={isDisabled}
        animateLabel={`treatmentSpecifications.${formType}.extractTeeth.teethToExtract`}
      />
      <ProsthesisRestorationInput
        isNewTemplatizedPrescription={true}
        title={translate('7.Prosthesis.Restorations')}
        disabled={isDisabled}
        fieldName={`treatmentSpecifications.${formType}.prosthesis`}
      />
      {hideLiveTranslationBox ? (
        <RHFTextArea
          inputProps={{
            placeholder: translate('settings.please_type_here'),
            disabled: isDisabled
          }}
          title={translate('8.Special Notes')}
          name={`treatmentSpecifications.${formType}.specialNotes`}
          autogrow
        />
      ) : (
        <SpecialNoteTranslation
          isTranslateFieldDefined={!!watchTranslatedNotes}
          labelClassName='text-17px'
          setValue={setSpecialNotes}
          isDisabled={isDisabled}
          title={translate('8.Special Notes')}
          InputTextFieldName={`treatmentSpecifications.${formType}.specialNotes`}
          translatedTextFieldName={`treatmentSpecifications.${formType}.translatedSpecialNotes`}
        />
      )}
    </div>
  );
};

export default TreatmentSpecificationsExternalZenplus;
