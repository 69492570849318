import { FC, useReducer } from 'react';

import CustomModal from 'components/CustomModal/CustomModal';
import { If } from 'components/Generics';
import {
  BulkAssign,
  BulkAssignAction,
  BulkAssignActionKind,
  OwnerInfo
} from 'types/Inbox.types';
import { translate } from 'utils';
import { useBulkAssignHooks } from 'utils/customHooks/bulkAssignHooks';

import BulkAssignConfirmation from './BulkAssignConfirmation';
import BulkAssignDetailsModal from './BulkAssignDetailsModal';
import BulkAssignInstruction from './BulkAssignInstruction';

export const BulkAssignZenchat: FC = () => {
  const initialState = {
    currentStep: 1,
    showBulkAssignPopup: false
  };

  const reducer = (state: BulkAssign, action: BulkAssignAction) => {
    switch (action.type) {
      case BulkAssignActionKind.TOGGLE_BULK_ADD:
        if (state.showBulkAssignPopup) {
          return { ...state, showBulkAssignPopup: false };
        }
        return { currentStep: 1, showBulkAssignPopup: true };
      case BulkAssignActionKind.UPDATE_BULK_NEXT_STEP:
        return { ...state, currentStep: state.currentStep + 1 };
      case BulkAssignActionKind.UPDATE_BULK_PREV_STEP:
        return { ...state, currentStep: state.currentStep - 1 };
      case BulkAssignActionKind.SET_OWNER_DETAILS: {
        const { ownerFrom, ownerTo, ticketCount, ticketIds, isCountLoading } =
          action?.payload || {};
        return {
          ...state,
          ownerFrom,
          ownerTo,
          ticketCount,
          ticketIds,
          isCountLoading
        };
      }
      default:
        return state;
    }
  };

  const [bulkAssign, dispatch] = useReducer(reducer, initialState);

  const { bulkAssignChats, bulkAssignChatsLoading } = useBulkAssignHooks({});
  const { getTicketsCount } = useBulkAssignHooks({ dispatch });

  const handleToggleBulkAssign = () => {
    dispatch({
      type: BulkAssignActionKind.TOGGLE_BULK_ADD
    });
  };

  const handleOwnerReassign = (ownerFrom: OwnerInfo, ownerTo: OwnerInfo) => {
    dispatch({
      type: BulkAssignActionKind.SET_OWNER_DETAILS,
      payload: {
        ownerFrom,
        ownerTo,
        ticketCount: 0,
        ticketIds: [],
        isCountLoading: true
      }
    });
    dispatch({
      type: BulkAssignActionKind.UPDATE_BULK_NEXT_STEP
    });
    getTicketsCount(ownerFrom, ownerTo);
  };
  const handleConfirmClick = () => {
    const extractedTicketIds = bulkAssign.ticketIds?.map((ticket) => ticket.id);
    const userId = bulkAssign.ownerTo?.userId;
    bulkAssignChats(extractedTicketIds, userId);
    handleToggleBulkAssign();
  };

  return (
    <div>
      <button
        onClick={() => handleToggleBulkAssign()}
        className='text-PRIMARY text-10px'
      >
        {translate('chat.bulk_assign')}
      </button>
      <CustomModal
        onClose={handleToggleBulkAssign}
        open={bulkAssign.showBulkAssignPopup}
        closeIconClassName='m-3'
      >
        <If condition={bulkAssign.currentStep === 1}>
          <BulkAssignInstruction
            handleBtnClick={() =>
              dispatch({
                type: BulkAssignActionKind.UPDATE_BULK_NEXT_STEP
              })
            }
          />
        </If>
        <If condition={bulkAssign.currentStep === 2}>
          <BulkAssignDetailsModal
            ownerFrom={bulkAssign.ownerFrom || undefined}
            ownerTo={bulkAssign.ownerTo || undefined}
            onClose={handleToggleBulkAssign}
            handleOwnerReassign={handleOwnerReassign}
          />
        </If>
        <If condition={bulkAssign.currentStep === 3}>
          <BulkAssignConfirmation
            ownerFrom={bulkAssign.ownerFrom || undefined}
            ownerTo={bulkAssign.ownerTo || undefined}
            ticketCount={bulkAssign.ticketCount || 0}
            isLoading={bulkAssign.isCountLoading}
            disableConfirm={bulkAssignChatsLoading}
            handleBackButtonClick={() =>
              dispatch({
                type: BulkAssignActionKind.UPDATE_BULK_PREV_STEP
              })
            }
            handleConfirmBtn={handleConfirmClick}
          />
        </If>
      </CustomModal>
    </div>
  );
};
