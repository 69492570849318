import { FC } from 'react';

import cx from 'classnames';

import { If } from 'components/Generics';
import { Tab } from 'components/Tab/Tab';
import { TABLIST } from 'constants/inbox';
import { translate } from 'utils';

interface ChatInputProps {
  selectedTab: string;
  children: React.ReactNode;
  onChange: (id: string) => void;
  className?: string;
  isEdit?: boolean;
  isInternalStyle: boolean;
}

const ChatInput: FC<ChatInputProps> = ({
  children,
  selectedTab,
  onChange,
  className = 'border-LIGHT_GRAY',
  isEdit = false,
  isInternalStyle
}) => {
  return (
    <div
      className={cx(
        `w-full px-13px py-1.5 rounded-lg border shadow-calendar ${className}`,
        {
          'bg-YELLOW': isInternalStyle,
          'bg-LIGHT_GRAY_DISCLAIMER': !isInternalStyle
        }
      )}
    >
      <If condition={!isEdit}>
        <div className='flex space-x-30px mb-3 '>
          {TABLIST?.map((tab) => (
            <Tab
              key={tab?.id}
              selected={selectedTab === tab?.id}
              label={translate(tab?.label)}
              handleClick={() => onChange(tab?.id)}
            />
          ))}
        </div>
      </If>
      {children}
    </div>
  );
};

export default ChatInput;
