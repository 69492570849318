import React, { ChangeEvent, FC, useCallback } from 'react';

import Checkbox from 'components/CheckboxGroup/Checkbox/Checkbox';
import { If } from 'components/Generics';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { Option } from 'types/select';
import { translate } from 'utils';

interface Props {
  title?: string;
  name?: string;
  onChangehandle(
    selected: Array<Option>,
    name: string,
    event?: ChangeEvent<HTMLInputElement>
  ): void;
  selectedItems?: Array<Option>;
  options?: Array<Option>;
  showErrorTitle?: boolean;
  className?: string;
  checkBoxClassName?: string;
  labelClassName?: string;
  checkBoxContainerClassName?: string;
  checkBoxInnerContainerClassName?: string;
  checkBoxItemFont?: string;
  disabled?: boolean;
  gridTemplateColumnsWidth?: string;
}

const Input: FC<Props> = ({
  title,
  name = '',
  options,
  onChangehandle,
  selectedItems = [],
  showErrorTitle = false,
  className = '',
  checkBoxClassName = '',
  checkBoxContainerClassName = '',
  checkBoxInnerContainerClassName = '',
  labelClassName = '',
  checkBoxItemFont = 'text-sm',
  disabled = false,
  gridTemplateColumnsWidth
}) => {
  const { callback } = useFieldRefContext();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;
    let selectedValue: Option[] = [];
    if (checked) {
      const currentSelection = options?.find(
        (option) => option?.value === value
      );
      if (currentSelection) {
        selectedValue = [...selectedItems, currentSelection];
      }
    } else {
      selectedValue = selectedItems.filter((option) => option?.value !== value);
    }
    onChangehandle(selectedValue, name, e);
  };

  const isChecked = useCallback(
    (value: string) => {
      return selectedItems?.findIndex((item) => item.value === value) > -1;
    },
    [selectedItems]
  );

  return (
    <div
      data-testid='checkbox-group'
      className={className}
      ref={callback?.(name)}
    >
      <If condition={!!title}>
        <div className={`text-base mb-4 ${showErrorTitle && 'text-ERROR'}`}>
          {title}
        </div>
      </If>
      <div
        className={`${checkBoxItemFont} ${checkBoxContainerClassName}`}
        style={{ gridTemplateColumns: gridTemplateColumnsWidth }}
      >
        {options?.map((item) => (
          <div key={item.value} className={`flex`}>
            <label
              className={`py-2 flex  ${labelClassName}`}
              role='presentation'
            >
              <Checkbox
                data-testid='checkbox'
                id={item.label}
                name={name}
                value={item.value}
                onChange={handleChange}
                checked={isChecked(item?.value)}
                className={checkBoxClassName}
                containerClassName={checkBoxInnerContainerClassName}
                disabled={disabled}
              />
              <span className='pt-0.5'>{translate(item.label as string)}</span>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Input;
