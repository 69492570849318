import { FC, ReactNode, useRef } from 'react';

import Draggable, { DraggableEventHandler } from 'react-draggable';
import { useIntercom } from 'react-use-intercom';
import { useRecoilValue } from 'recoil';

import { Logo } from 'assets/icons';
import { If } from 'components/Generics';
import { INTERCOM_APP_ID } from 'config';
import { loginedUserDetails } from 'store/atoms';

interface Props {
  children: ReactNode;
}

const IntercomProvider: FC<Props> = ({ children }) => {
  const loggedUserInfo = useRecoilValue(loginedUserDetails);
  const isAppIdAvailable = !!INTERCOM_APP_ID;
  const { boot, show, hide, isOpen } = useIntercom();
  if (loggedUserInfo.id) {
    boot({
      name: `${loggedUserInfo.firstName} ${loggedUserInfo.lastName}`,
      email: loggedUserInfo.email,
      phone: loggedUserInfo.phone,
      customAttributes: {
        clinic_name: loggedUserInfo.clinic,
        hide_default_launcher: true
      }
    });
  }

  const touchPositionRef = useRef({ x: 0, y: 0 });

  const onStart: DraggableEventHandler = (_, data) => {
    const { x, y } = data;
    touchPositionRef.current.x = x;
    touchPositionRef.current.y = y;
  };

  const togglePopup = () => {
    if (isOpen) {
      hide();
    } else {
      show();
    }
  };

  const eventHandler: DraggableEventHandler = (_, data) => {
    const MOVE_THRESHOLD = 5;
    const { x, y } = data;
    const deltaX = touchPositionRef.current.x - x;
    const deltaY = touchPositionRef.current.y - y;
    const hasDragged =
      Math.abs(deltaX) > MOVE_THRESHOLD || Math.abs(deltaY) > MOVE_THRESHOLD;
    if (hasDragged) {
      return;
    }
    togglePopup();
  };

  return (
    <>
      <If condition={isAppIdAvailable}>
        <Draggable bounds='parent' onStart={onStart} onStop={eventHandler}>
          <div className='fixed right-3 bottom-20 md:bottom-5 md:right-5 z-1000'>
            <a
              id='intercom_btn'
              className='bg-PRIMARY rounded-full w-12 h-12 flex items-center justify-center transition duration-300 ease-in-out hover:scale-110 cursor-pointer '
            >
              <Logo className='w-8 h-8 text-white' />
            </a>
          </div>
        </Draggable>
      </If>
      {children}
    </>
  );
};

export default IntercomProvider;
