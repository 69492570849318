import React from 'react';

import { customTwMerge as twMerge } from 'utils';
interface Props {
  className?: string;
  children?: React.ReactNode;
}

const ChipLabel: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <div
      className={twMerge(
        'font-normal text-sm w-max rounded-full flex items-center h-6 px-3 truncate leading-unset',
        className
      )}
    >
      {children}
    </div>
  );
};

export default ChipLabel;
