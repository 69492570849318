import { FC, useMemo, useState } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';

import {
  EXTERNAL_LABELS,
  INTERNAL_LABELS,
  SNOOZE_INBOX_FILTER
} from 'constants/inbox';
import { useGetTicketCount } from 'services/hooks/Ticket.hooks';
import { inboxFilters } from 'store/atoms/inbox';
import { useIsExternalUser } from 'store/selectors/auth';
import { GetTicketCount, TicketCount } from 'types/Tickets.types';
import { translate } from 'utils';
import { useIsmobile } from 'utils/customHooks/useViewport';

import {
  ActionOption,
  ActionOptionContainer,
  ActionFilterController
} from './index';

interface Props {
  hide: boolean;
}

const ActionFilter: FC<Props> = ({ hide }) => {
  const isExternalUser = useRecoilValue(useIsExternalUser);
  const isMobile = useIsmobile();
  const isMinimalOptions = isExternalUser || isMobile;

  const getDefaultButtonValue = () => (isMinimalOptions ? 'ALL' : 'TODO');
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedButton, setSelectedButton] = useState<string | null>(
    getDefaultButtonValue
  );
  const [inboxFilter, setInboxFilter] = useRecoilState(inboxFilters);
  const LABELS = isMinimalOptions ? EXTERNAL_LABELS : INTERNAL_LABELS;

  const getFiltersWithoutSnooze = () => {
    const filters = { ...inboxFilter };
    delete filters.snoozeType;
    filters.status = [];
    return filters;
  };

  const { data } = useGetTicketCount({
    variables: {
      params: {
        ...getFiltersWithoutSnooze()
      }
    },
    fetchPolicy: 'no-cache'
  });

  const formatSnoozeCount = (ticketCount?: TicketCount) => {
    const counts: { [key: string]: number } = {};
    const { getTicketCountByStatus = [] } = ticketCount || {};
    getTicketCountByStatus.forEach((item: GetTicketCount) => {
      counts[item.snoozeType] = item.count;
    });
    return counts;
  };

  const snoozeCounts = useMemo(() => formatSnoozeCount(data), [data]);

  const toggleExpansion = () => {
    setIsExpanded((prev) => !prev);
  };

  const getControllerLabel = () => {
    if (!selectedButton) return { label: '' };

    const count = snoozeCounts[selectedButton] ?? '';
    const labelKey = LABELS[selectedButton]?.label;
    return { count, label: translate(labelKey) };
  };

  const handleOptionSelect = (selectedOption: string) => {
    toggleExpansion();
    if (selectedOption === selectedButton) return;

    const { status, snoozeType } = SNOOZE_INBOX_FILTER[selectedOption];
    setSelectedButton(selectedOption);
    setInboxFilter((prev) => ({ ...prev, status, snoozeType }));
  };

  const controllerLabel = getControllerLabel();

  if (hide) return null;

  return (
    <div className='relative'>
      <ActionFilterController
        isExpanded={isExpanded}
        onClick={toggleExpansion}
        label={controllerLabel.label}
        count={controllerLabel?.count}
      />
      <ActionOptionContainer
        isExpanded={isExpanded}
        toggleExpansion={toggleExpansion}
      >
        {Object.values(LABELS).map(({ label, toolTipMsg, snoozeType }) => (
          <ActionOption
            key={label}
            title={label}
            subtitle={toolTipMsg}
            count={snoozeCounts[snoozeType]}
            onClick={() => handleOptionSelect(snoozeType)}
          />
        ))}
      </ActionOptionContainer>
    </div>
  );
};

export default ActionFilter;
