import { gql } from '@apollo/client';

export const GET_USER_PREFERENCE = gql`
  query getUserPreference($name: UserPreferenceName!) {
    getUserPreference(name: $name) {
      name
      preference
    }
  }
`;
export const GET_USER_PREFERENCE_BY_ID = gql`
  query getUserPreferenceByUserId(
    $name: UserPreferenceName!
    $userId: String!
  ) {
    getUserPreferenceByUserId(name: $name, userId: $userId) {
      name
      preference
    }
  }
`;
export const UPDATE_USER_PREFERENCE = gql`
  mutation UpdateUserPreference(
    $name: UserPreferenceName!
    $preference: JSON!
  ) {
    updateUserPreference(name: $name, preference: $preference) {
      name
      preference
    }
  }
`;
