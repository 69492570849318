import * as yup from 'yup';

import { EXTENSIONS } from 'constants/common';
import { REFINEMENT_REQUIRED_PHOTOS } from 'constants/treatmentFiles';
import { hasMinimumFilesWithExtension } from 'utils/common';
import { planTypeCode } from 'utils/TreatmentPlanningInstructions.utils';

const minTwoStlFileReq = yup
  .array()
  .test('scan test', 'Minimum 2 stl files are required', (value) =>
    hasMinimumFilesWithExtension(value, EXTENSIONS.STL, 2)
  )
  .nullable(false);

export const EOTValidationSchema = yup.object().shape({
  additionalTreatment: yup.string().required(),
  scheduleAppointment: yup.string().when('additionalTreatment', {
    is: (additionalTreatment: string) => additionalTreatment === 'REFINEMENT',
    then: yup.string().required()
  })
});

export const RefinementFormValidation = yup.object().shape({
  treatmentPlanInstructions: yup
    .string()
    .when(['treatmentPlanTypes'], {
      is: (treatmentPlanTypes: string[] | undefined) => {
        return treatmentPlanTypes?.includes(planTypeCode.ZENCLEAR);
      },
      then: yup.string().trim().required().defined()
    })
    .nullable(),
  zenplusInstructions: yup
    .string()
    .when(['treatmentPlanTypes'], {
      is: (treatmentPlanTypes: string[] | undefined) =>
        treatmentPlanTypes?.includes(planTypeCode.ZEN_PLUS),
      then: yup.string().trim().required().defined()
    })
    .nullable(),
  photo: yup
    .array()
    .test(
      'photos test',
      'Required minimum of 5 photos and ensure each of them is classified',
      function (value) {
        const requiredClassifications = REFINEMENT_REQUIRED_PHOTOS;
        const missingClassification = requiredClassifications.find(
          (classification) => {
            const index = value?.findIndex(
              (file) => classification === file.classification
            );
            return index === -1;
          }
        );
        return !missingClassification;
      }
    ),
  xray: yup.array().nullable(),
  scan: minTwoStlFileReq
});
