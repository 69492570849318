import { useState, useEffect } from 'react';

import { MOBILE_SCREEN_WIDTH, TAB_SCREEN_WIDTH } from 'constants/common';

interface viewPort {
  width: number;
}

export const useViewport = (): viewPort => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  // Return the width so we can use it in our components
  return { width };
};

export const useIsmobile = (): boolean => {
  const { width } = useViewport();
  return width < MOBILE_SCREEN_WIDTH;
};

export const useIsTabView = (): boolean => {
  const { width } = useViewport();
  return width < TAB_SCREEN_WIDTH;
};
