import {
  DELETE_TREATMENT_DOCUMENTS,
  DELETE_TREATMENT_PLAN_DOCUMENTS,
  GET_PRIOR_TREATMENT_OPTIONS,
  GET_TREATMENT_PLANS_OPTIONS,
  GET_TREATMENT_SPECIFICATION_OPTIONS,
  UPDATE_TREATMENT_DOCUMENTS
} from 'services/query/treatmentPlanning';
import {
  DeleteTreatmentDocument,
  DeleteTreatmentPlanDocument,
  UpdateTreatmentDocument,
  UseGetPriorTreatmentOptions,
  UseGetTreatmentPlanOptions,
  UseGetTreatmentSpecificationOptions
} from 'types/TreatmentPlanning.types';

import { UseGraphQlMutationWithTpes, UseGraphQlQuery } from './hooks';

export const useGetPriorTreatmentOptions: UseGetPriorTreatmentOptions = (
  config
) => {
  return UseGraphQlQuery(GET_PRIOR_TREATMENT_OPTIONS, config);
};

export const useGetTreatmentSpecficationOptions: UseGetTreatmentSpecificationOptions =
  (config) => {
    return UseGraphQlQuery(GET_TREATMENT_SPECIFICATION_OPTIONS, config);
  };

export const deleteTreatmentDocument: DeleteTreatmentDocument = (config) => {
  return UseGraphQlMutationWithTpes(DELETE_TREATMENT_DOCUMENTS, config);
};

export const deleteTreatmentPlanDocument: DeleteTreatmentPlanDocument = (
  config
) => {
  return UseGraphQlMutationWithTpes(DELETE_TREATMENT_PLAN_DOCUMENTS, config);
};

export const useUpdateTreatmentDocument: UpdateTreatmentDocument = (config) => {
  return UseGraphQlMutationWithTpes(UPDATE_TREATMENT_DOCUMENTS, config);
};

export const useGetTreatmentPlanTypes: UseGetTreatmentPlanOptions = (config) =>
  UseGraphQlQuery(GET_TREATMENT_PLANS_OPTIONS, config);
