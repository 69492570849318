import { FC } from 'react';

import CollapsibleMenu from 'components/CollapsibleMenu/CollapsibleMenu';
import If from 'components/Generics/If/If';
import TreatmentSpecificationsRefinement from 'components/TreatmentSpecificationsRefinement';
import { AlignerIPRInfo } from 'types/TPVForm.types';
import { translate } from 'utils';

import TreatmentSpecifications from '../TreatmentSpecifications/TreatmentSpecifications';
import TreatmentSpecificationsExternal from '../TreatmentSpecificationsExternal';

type TreatmentSpecificationCollapsibleProps = {
  refinementFormType?: string;
  name?: string;
  className?: string;
  preventExpand?: boolean;
  showTitleError?: boolean;
  doctorComplexity?: number;
  isReferral?: boolean;
  isExpanded: boolean;
  toggleExpand: (value: Record<string, boolean>) => void;
  save?: () => void;
  isDisabled?: boolean;
  showDetails?: boolean;
  isNewTemplatizedPrescription?: boolean;
  status?: string;
  innerContainerClassName?: string;
  isRefinement?: boolean;
  predecessorTPVData?: AlignerIPRInfo;
  title?: string;
  source?: string;
};

const TreatmentSpecificationCollapsible: FC<
  TreatmentSpecificationCollapsibleProps
> = ({
  refinementFormType,
  className = '',
  preventExpand,
  showTitleError = false,
  doctorComplexity,
  isReferral,
  isExpanded = false,
  toggleExpand,
  name = '',
  save,
  isDisabled = false,
  isNewTemplatizedPrescription = false,
  showDetails = false,
  status,
  innerContainerClassName,
  isRefinement = false,
  predecessorTPVData,
  title = '',
  source
}) => {
  return (
    <CollapsibleMenu
      status={status}
      title={title}
      isPrescription={true}
      className={className}
      preventExpand={preventExpand}
      showErrorTitle={showTitleError}
      toggleExpand={(isExpanded) => toggleExpand({ [name]: isExpanded })}
      isExpanded={isExpanded}
      titleFont={`${
        !isReferral ? 'md:text-collapsible text-base' : 'text-17px'
      }`}
      isDisabled={isDisabled}
      infoText={isRefinement ? translate('please.note.zenplusrefinement') : ''}
    >
      <If condition={isNewTemplatizedPrescription}>
        {isRefinement ? (
          <TreatmentSpecificationsRefinement
            source={source}
            formType={refinementFormType}
            isDisabled={showDetails}
            predecessorTPVData={predecessorTPVData}
          />
        ) : (
          <TreatmentSpecificationsExternal
            isDisabled={showDetails}
            isReferral={isReferral}
            className={innerContainerClassName}
            source={source}
          />
        )}
      </If>

      <If condition={!isNewTemplatizedPrescription}>
        <TreatmentSpecifications
          doctorComplexity={doctorComplexity}
          isReferral={isReferral}
          save={save}
          className={innerContainerClassName}
        />
      </If>
    </CollapsibleMenu>
  );
};
export default TreatmentSpecificationCollapsible;
