import { FC, ReactNode } from 'react';

import Skeleton from 'react-loading-skeleton';

interface Props {
  loading: boolean;
  width: number | string;
  children: ReactNode;
  count?: number;
  height?: number | string;
  className?: string;
}

const SkeletonWrapper: FC<Props> = ({
  loading,
  width,
  count,
  height,
  children,
  className
}) => {
  return (
    <>
      {loading ? (
        <Skeleton
          width={width}
          count={count}
          height={height}
          className={className}
        />
      ) : (
        children
      )}
    </>
  );
};

export default SkeletonWrapper;
