import { DocumentNode, useApolloClient } from '@apollo/client';

import { TreatmentSourceType } from 'components/TreatmentProgressMenu/TreatmentMenuConfig';
import { REQUEST_METHODS } from 'constants/index';
import { RESUBMISSION_PENDING } from 'constants/patientCard';
import { TRACK_EVENTS } from 'constants/segment';
import { callApi } from 'services/api/request';
import {
  ATTACH_TREATMENT_DOCUMENTS,
  GET_TREATMENT_FILE_UPLOAD_URL
} from 'services/query/treatmentPlanning';
import { FilesData } from 'types/Files.types';
import {
  TreatmentDocumentInput,
  TreatmentDocumentRes,
  TreatmentDocumentType,
  TreatmentDocumentUploadInput,
  TreatmentDocumentUploadUrlRes
} from 'types/TreatmentPlanning.types';
import { translate } from 'utils';
import { showToast } from 'utils/toast/toast.util';

interface Props {
  treatmentId: string;
  documentType: TreatmentDocumentType;
  isResubmission?: boolean;
  sourceType: TreatmentSourceType;
  refetchQueries?: Array<DocumentNode>;
  onUploadSuccess?: () => void;
  onUploadFail?: () => void;
  setFiles: React.Dispatch<React.SetStateAction<FilesData[]>>;
  setUploadingFilesCount: React.Dispatch<React.SetStateAction<number>>;
  query?: {
    url: DocumentNode;
    urlKey: string;
    attach: DocumentNode;
    attachKey?: string;
  };
}
const useFileUpload = ({
  treatmentId,
  documentType,
  sourceType,
  onUploadSuccess,
  onUploadFail,
  refetchQueries,
  setUploadingFilesCount,
  isResubmission = false,
  setFiles,
  query
}: Props) => {
  const client = useApolloClient();
  const getFileUploadUrl = async (
    fileUploadInput: TreatmentDocumentInput[]
  ) => {
    return client.query<
      TreatmentDocumentUploadUrlRes,
      TreatmentDocumentUploadInput
    >({
      query: query?.url ?? GET_TREATMENT_FILE_UPLOAD_URL,
      variables: {
        documents: fileUploadInput,
        treatmentId
      },
      fetchPolicy: 'network-only'
    });
  };

  const uploadFileToS3 = async (url: string, key: string, file: FilesData) => {
    const onUploadProgress = (progressEvent: ProgressEvent) => {
      const progress = (progressEvent.loaded * 100) / progressEvent.total;
      setFiles((files) => {
        const updatedFile = files.find(({ id }) => id === file.id);
        if (updatedFile) {
          updatedFile.uploadProgress = progress;
          updatedFile.key = key;
          return [...files];
        }
        file.uploadProgress = progress;
        return files.concat(file);
      });
    };
    const imgBody = new Blob([file.file as File], { type: documentType });
    return callApi({
      payload: { method: REQUEST_METHODS.PUT, url, data: imgBody },
      headers: { 'Content-Type': documentType },
      onUploadProgress
    });
  };

  const saveUploadedFile = async (
    fileUploadInput: TreatmentDocumentInput[]
  ) => {
    return client.mutate<TreatmentDocumentRes, TreatmentDocumentUploadInput>({
      mutation: query?.attach ?? ATTACH_TREATMENT_DOCUMENTS,
      variables: {
        documents: fileUploadInput,
        treatmentId
      },
      refetchQueries,
      fetchPolicy: 'network-only'
    });
  };

  const updateIdOfUploadedFile = (
    uploadedFile: TreatmentDocumentRes,
    filesInfo: FilesData[]
  ) => {
    setFiles((oldFiles) => {
      const updatedFiles = oldFiles.map((file) => {
        const updatedFile = filesInfo.find(({ id }) => id === file.id);
        if (updatedFile) {
          const attachKey = query?.attachKey ?? 'attachTreatmentDocuments';
          updatedFile.fileId = uploadedFile[attachKey][0].id;
          return updatedFile;
        }
        return file;
      });
      return updatedFiles;
    });
    window?.analytics.track(TRACK_EVENTS.PATIENT_PHOTOS_UPLOADED);
  };

  const uploadFile = async (files: FilesData[]) => {
    const fileInfo = [...files];
    const fileInputToGetS3Url: TreatmentDocumentInput[] = files?.map((file) => {
      const date = new Date();
      const fileUploadInput: TreatmentDocumentInput = {
        name: `${date.getTime()} ${file.file?.name}` as string, // For handling apollo client issue for multiple GET_TREATMENT_FILE_UPLOAD_URL query with same files.
        sourceType,
        documentType
      };
      if (isResubmission) {
        fileUploadInput.resubmissionStatus = RESUBMISSION_PENDING;
      }
      return fileUploadInput;
    });
    try {
      const { data: documentUrl } = await getFileUploadUrl(fileInputToGetS3Url);
      const queryKey = query?.urlKey ?? 'getTreatmentDocumentsUploadUrl';
      const uploadFilePromise = documentUrl?.[queryKey].map(
        async (documentUploadUrl, index) => {
          const { url, key } = documentUploadUrl;
          fileInputToGetS3Url[index].key = key;
          return await uploadFileToS3(url, key, fileInfo[index]);
        }
      );
      Promise.all(uploadFilePromise)
        .then(async () => {
          const { data } = await saveUploadedFile(fileInputToGetS3Url);
          if (data) {
            onUploadSuccess?.();
            setUploadingFilesCount(0);
            updateIdOfUploadedFile(data, files);
          }
          showToast(
            translate('success.multiplePhotosUploaded', { x: files.length }),
            true
          );
          onUploadSuccess?.();
        })
        .catch(() => {
          showToast(translate('uploadingStatus.failedToast'), false);
        });
      return uploadFilePromise;
    } catch (error) {
      onUploadFail?.();
      throw error;
    }
  };
  return { getFileUploadUrl, uploadFile };
};

export default useFileUpload;
