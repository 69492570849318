import { FC } from 'react';

import PageNotFound from 'pages/PageNotFound';

export const ErrorFallback: FC = () => {
  return (
    <div className='w-full h-screen'>
      <PageNotFound />
    </div>
  );
};
