import { FC, ReactNode } from 'react';

import { MOBILE_SCREEN_WIDTH } from 'constants/common';
import { useViewport } from 'utils';

interface MobileDrawerProps {
  className?: string;
  children: ReactNode;
}

const MobileDrawer: FC<MobileDrawerProps> = ({ children, className }) => {
  const { width } = useViewport();
  const isMobile = width < MOBILE_SCREEN_WIDTH;
  return isMobile ? (
    <div
      className={`fixed bg-WHITE inset-0 top-14 z-20 overflow-y-auto ${className}`}
    >
      {children}
    </div>
  ) : (
    <></>
  );
};

export default MobileDrawer;
