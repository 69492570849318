import {
  GET_USER_PREFERENCE,
  GET_USER_PREFERENCE_BY_ID,
  UPDATE_USER_PREFERENCE
} from 'services/query/TemplateSettings.queries';
import {
  GetUserPreferenceInput,
  GetUserPreferenceResponse,
  UpdateUserPreference,
  UseGetUserPreference,
  UseGetUserPreferenceById
} from 'types/Settings.types';

import {
  UseGraphQlMutationWithTpes,
  UseGraphQlQuery,
  UseGraphQlQueryWithTypes
} from './hooks';

export const useGetUserPreference: UseGetUserPreference = (config) => {
  return UseGraphQlQueryWithTypes<
    GetUserPreferenceResponse,
    GetUserPreferenceInput
  >(GET_USER_PREFERENCE, config);
};
export const useGetUserPreferenceById: UseGetUserPreferenceById = (config) => {
  return UseGraphQlQuery(GET_USER_PREFERENCE_BY_ID, config);
};
export const useUpdateUserPreference: UpdateUserPreference = (config) => {
  return UseGraphQlMutationWithTpes(UPDATE_USER_PREFERENCE, config);
};
