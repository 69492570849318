import { FC, useMemo, useState } from 'react';

import { CellProps } from 'react-table';

import { BinIcon } from 'assets/icons';
import { CancelLeave } from 'components';
import { If } from 'components/Generics';
import Table from 'components/Table/Table';
import { LEAVE_TYPE_LABEL } from 'constants/userDetails';
import { LeaveInfo } from 'types/leave';
import { isDataExist, translate } from 'utils';
import { useGetAllLeaves } from 'utils/customHooks/leave.customHooks';
import { formatDateWithTimeZone } from 'utils/dateUtils';
import { useHasAccess } from 'utils/hasAccess';

interface Props {
  userId: string;
  leaveManagePermission: string;
  isLoginedUser?: boolean;
}

const LeaveTable: FC<Props> = ({
  userId,
  leaveManagePermission,
  isLoginedUser
}) => {
  const [selectedLeave, setSelectedLeave] = useState<LeaveInfo | null>(null);
  const { leaveData } = useGetAllLeaves(userId);

  const formattedTableData: LeaveInfo[] = useMemo(() => {
    const { viewLeaves: userLeaveData } = leaveData || {};
    if (userLeaveData && isDataExist(userLeaveData)) {
      return userLeaveData.map(
        ({ id, type, startDate, endDate, description, timezone }) => ({
          id,
          type: LEAVE_TYPE_LABEL[type]
            ? translate(LEAVE_TYPE_LABEL[type])
            : type,
          startDate: formatDateWithTimeZone(startDate, timezone),
          endDate: formatDateWithTimeZone(endDate, timezone),
          description: description,
          timezone
        })
      );
    }
    return [];
  }, [leaveData]);

  const LEAVE_TABLE_COLUMNS = [
    {
      Header: translate('leave.type'),
      accessor: 'type',
      disableSortBy: true
    },
    {
      Header: translate('leave.from'),
      accessor: 'startDate',
      disableSortBy: true
    },
    {
      Header: translate('leave.to'),
      accessor: 'endDate',
      disableSortBy: true
    },
    {
      Header: translate('leave.descriptions'),
      accessor: 'description',
      disableSortBy: true
    },
    {
      accessor: 'action',
      className: 'w-4',
      Header: () => <div>{translate('leave.actions')}</div>,
      Cell: (props: CellProps<any, boolean>) => {
        return (
          <button
            className='w-full flex justify-center items-center cursor-pointer'
            onClick={() => setSelectedLeave(props?.row?.original)}
          >
            <BinIcon className='w-5 h-5 text-red-600' />
          </button>
        );
      },
      disableSortBy: true
    }
  ];

  const hasLeaveManagementPermission = useHasAccess(leaveManagePermission);
  const getHiddenColumn = () => {
    if (!hasLeaveManagementPermission) {
      return ['action'];
    }
    return [];
  };

  return (
    <div>
      <If condition={isDataExist(formattedTableData)}>
        <Table
          columns={LEAVE_TABLE_COLUMNS}
          data={formattedTableData}
          headerClassName='bg-LIGHT_BLUE_HEADER border-2 border-PRIMARY z-0'
          tableDataClassName='border-2 border-PRIMARY px-3 py-4 bg-WHITE'
          tableClassName='rounded-2xl'
          stickyHeader={false}
          showRowPointer={false}
          hiddenColumns={getHiddenColumn()}
        />
        <CancelLeave
          isLoginedUser={isLoginedUser}
          onClose={() => setSelectedLeave(null)}
          isOpen={!!selectedLeave?.id}
          leaveInfo={selectedLeave}
          setSelectedLeave={setSelectedLeave}
        ></CancelLeave>
      </If>
    </div>
  );
};

export default LeaveTable;
