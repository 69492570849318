import { gql } from '@apollo/client';

const GET_ACTIVE_TREATEMENT = gql`
  query ($patientId: ID!) {
    getActiveTreatmentsForPatient(patientId: $patientId) {
      id
      stage
      status
      type
      patientId
      patient {
        country: primaryCountry
      }
      treatmentPlan {
        id
        salesforceInfo {
          salesforceLink
        }
        versions {
          id
          salesforceInfo {
            salesforceLink
          }
        }
      }
      details {
        opportunityId
        hasRetainer
      }
      clinic {
        country
        id
        headClinicId
      }
    }
  }
`;

const GET_ALL_TOPICS = gql`
  query (
    $userRole: [CHAT_TOPIC_INITIATOR]!
    $patientTreatmentStage: [PATIENT_TREATMENT_STAGE]!
    $listAllTopics: Boolean
    $showDisabledTopics: Boolean
  ) {
    getAllChatTopics(
      input: {
        userRole: $userRole
        patientTreatmentStage: $patientTreatmentStage
        listAllTopics: $listAllTopics
        showDisabledTopics: $showDisabledTopics
      }
    ) {
      id
      name
      code
      treatmentStageCode
      treatmentStageName
      chatInitiator
      isActive
    }
  }
`;

const GET_TREATEMENT_PLANS = gql`
  query (
    $params: FilterTreatmentPlanInput
    $paginationInfo: PaginationInfo!
    $rejectedBy: [TPVRejectedBy!]
  ) {
    getTreatmentPlans(params: $params, paginationInfo: $paginationInfo) {
      edges {
        id
        name
        versions(input: { filter: { rejectedBy: $rejectedBy } }) {
          type
          id
          name
          acceptanceStatus
          treatmentPlanningLab
        }
      }
    }
  }
`;
const GET_TOPIC_CONTEXT_TYPE = gql`
  query ($topicId: String!) {
    getTopicContextType(topicId: $topicId) {
      id
      contextType
      contextDisplayOrder
    }
  }
`;
const GET_NEXT_SEQUENCE_ID = gql`
  query {
    getNextTicketSequenceId
  }
`;

const GET_TICKET_COUNT = gql`
  query getTicketCountByStatus($params: ListTicketFilter) {
    getTicketCountByStatus(input: $params) {
      snoozeType
      count
    }
  }
`;

const GET_TICKET_LIST = gql`
  query getTickets($params: ListTicketFilter!, $loginedUserId: ID!) {
    getTickets(input: $params) {
      totalCount
      tickets {
        id
        isUrgent
        isRead
        updatedAt
        topicInfo {
          topicId
          topicName
          topicCode
        }
        ticketChatSearchMessage {
          id
          text
          send_group_id
          rich_text_info {
            blocks {
              rich_text_location_infos {
                offset
                length
                key
              }
              text
            }
            rich_text_details {
              type
              details {
                id
                name
                team
              }
            }
          }
        }
        status
        ticketUsers {
          username
          groupName
          userId
          userFirstName
          userLastName
          email
          userTeamName
          isExternal
          userTeamCode
          deactivatedAt
          userProfilePicture
        }
        treatmentInfo {
          opportunityId
          type
          status
          patient {
            country: primaryCountry
          }
          treatmentPlan {
            versions {
              id
              name
              type
            }
          }
        }
        isMentionedMessageRead(userId: $loginedUserId)
        mentionedMessageId(userId: $loginedUserId)
        isUserPartOfTicket(userId: $loginedUserId)
        ticketTopicContextInfo {
          contextType
          contextValue
          contextValueId
        }
        createdAt
        unReadMessageCount
        keyLinks {
          isTreatmentActive
        }
        snoozeInfo {
          id
          snoozeUntil
          snoozeType
        }
      }
      paginationInfo {
        limit
        offset
      }
    }
  }
`;

const GET_TICKET_SEARCH_ZENCHAT_LINK = gql`
  query getTicketsZenchatLink($params: ListTicketFilter!) {
    getTickets(input: $params) {
      tickets {
        id
        topicInfo {
          topicName
        }
        ticketTopicContextInfo {
          contextType
          contextValue
        }
        linkedTickets {
          ticketId
          url
        }
      }
    }
  }
`;

const GET_TPV_DETAILS = gql`
  query ($params: TreatmentPlanVersionQueryInput) {
    getTreatmentPlanVersions(input: $params) {
      id
      name
      urlSmileSummary
      ipr
      attachments
      treatmentPlan {
        id
        name
        type
        treatment {
          zenyumCouncilExclusion
        }
        treatmentSpecifications {
          iprLocation
          attachmentRequired
        }
      }
      numberOfSets
      numberOfZenStages
      teethExtractionZenplus
      teethToExtract
      riskAssessmentForAppt
    }
  }
`;

const GET_TOPIC_PARTICIPANTS = gql`
  query (
    $topicId: String!
    $patientId: String!
    $clinicCountryCode: String
    $initiator: CHAT_TOPIC_INITIATOR
  ) {
    getTopicParticipants(
      topicId: $topicId
      initiator: $initiator
      patientId: $patientId
      clinicCountryCode: $clinicCountryCode
    ) {
      userId
      groupId
      groupName
      userTeamId
      userLastName
      userTeamCode
      userTeamName
      userFirstName
      userEmail
      isExternal
    }
  }
`;

const GET_GROUPS = gql`
  query {
    getGroups {
      id
      name
    }
  }
`;

const REMOVE_USER_FROM_TICKET = gql`
  mutation ($userId: ID!, $ticketId: Int!) {
    removeUserFromTicket(userId: $userId, ticketId: $ticketId) {
      userId
    }
  }
`;
const UPDATE_TICKET_OWNER = gql`
  mutation ($userId: ID!, $ticketId: Int!) {
    updateTicketOwnership(userId: $userId, ticketId: $ticketId) {
      username
      groupName
      userId
      userFirstName
      userLastName
      email
      userTeamName
      isExternal
      userTeamCode
    }
  }
`;

const ADD_USER_TO_TICKET = gql`
  mutation ($input: TicketUserInput!, $ticketId: Int!) {
    addUserToTicket(input: $input, ticketId: $ticketId) {
      username
      groupName
      userId
      userFirstName
      userLastName
      email
      userTeamName
      isExternal
      userTeamCode
    }
  }
`;

const LINK_TICKETS = gql`
  mutation ($ticketId: Int!, $linkedTicketId: Int!) {
    linkTickets(ticketId: $ticketId, linkedTicketId: $linkedTicketId) {
      linkedTickets {
        ticketId
        url
      }
    }
  }
`;

const UNLINK_TICKETS = gql`
  mutation ($ticketId: Int!, $unlinkedTicketId: Int!) {
    unlinkTickets(ticketId: $ticketId, unlinkedTicketId: $unlinkedTicketId) {
      linkedTickets {
        ticketId
        url
      }
    }
  }
`;

const GET_TICKET_USERS = gql`
  query ($input: GetTicketParticipantsInput!) {
    getTicketParticipants(input: $input) {
      username
      groupName
      userId
      userFirstName
      userLastName
      email
      userTeamName
      isExternal
      userTeamCode
    }
  }
`;

const GET_TICKETS = gql`
  query ($input: ListTicketFilter!) {
    getTickets(input: $input) {
      totalCount
      tickets {
        id
      }
      paginationInfo {
        limit
        offset
      }
    }
  }
`;
const BULK_ASSIGN_TICKETS = gql`
  mutation ($ticketIds: [Int]!, $userId: String) {
    bulkReassignTicketOwner(ticketIds: $ticketIds, userId: $userId) {
      username
      userId
      email
      ticketId
    }
  }
`;
const ADD_USER_TO_TICKET_SELF = gql`
  mutation ($input: TicketUserInput!, $ticketId: Int!) {
    addUserToTicketByMyself(input: $input, ticketId: $ticketId) {
      username
      groupName
      userId
      userFirstName
      userLastName
      email
      userTeamName
      isExternal
      userTeamCode
    }
  }
`;
const GET_ALL_STAFF_LIST = gql`
  query ($filter: StaffFilter!, $paginationInfo: PaginationInput) {
    getAllStaff(filter: $filter, paginationInfo: $paginationInfo) {
      staff {
        id
        firstName
        lastName
        email
        mobile
        phone
        groups
        profilePicture
      }
      totalCount
    }
  }
`;
const CORE_TICKET_FIELDS = gql`
  fragment CoreTicketFields on TicketResponse {
    id
    isUrgent
    createdAt
    isRead
    unReadMessageCount
    updatedAt
    topicInfo {
      topicId
      topicName
      topicCode
    }
    ticketChatSearchMessage {
      id
      text
      send_group_id
    }
    status
    ticketUsers {
      username
      groupName
      userId
      email
      userFirstName
      userLastName
      userTeamName
      userProfilePicture
      isExternal
      userTeamCode
      deactivatedAt
    }
    isMentionedMessageRead(userId: $loginedUserId)
    mentionedMessageId(userId: $loginedUserId)
    ticketTopicContextInfo {
      contextType
      contextValue
      contextValueId
    }
    keyLinks {
      salesforceLink
      adminPortalLink
      retainerSalesforceLink
      isTreatmentActive
    }
    snoozeInfo {
      id
      snoozeUntil
      snoozeType
    }
    treatmentInfo {
      treatmentPlan {
        versions {
          id
          name
          type
        }
      }
      status
      patientId
      patient {
        email
        phone
        country: primaryCountry
      }
      type
      opportunityId
      retainerOpportunityId
      clinic {
        name
      }
      treatmentStage: stage
      currentTreatmentKey: type
      medicalStaff {
        doctors {
          role
          primary
          details {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const GET_TICKET = gql`
  ${CORE_TICKET_FIELDS}
  query (
    $ticketId: Int!
    $isExternalUserView: Boolean
    $viewTicketsWithoutMe: Boolean
    $patientId: ID
    $loginedUserId: ID!
  ) {
    getTicketById(
      ticketId: $ticketId
      isExternalUserView: $isExternalUserView
      viewTicketsWithoutMe: $viewTicketsWithoutMe
      patientId: $patientId
    ) {
      ...CoreTicketFields
      isUserPartOfTicket(userId: $loginedUserId)
      treatmentId
      linkedTickets {
        ticketId
        url
      }
    }
  }
`;

const GET_TICKET_BY_ID = gql`
  ${CORE_TICKET_FIELDS}
  query getTicketById(
    $ticketId: Int!
    $isExternalUserView: Boolean
    $viewTicketsWithoutMe: Boolean
    $patientId: ID
    $loginedUserId: ID!
  ) {
    getTicketById(
      ticketId: $ticketId
      isExternalUserView: $isExternalUserView
      viewTicketsWithoutMe: $viewTicketsWithoutMe
      patientId: $patientId
    ) {
      ...CoreTicketFields
      treatmentId
      linkedTickets {
        ticketId
        url
      }
      isUserPartOfTicket(userId: $loginedUserId)
      crossChatSummary {
        createdAt
        relatedTickets
        summary {
          blocks {
            rich_text_location_infos {
              offset
              length
              key
            }
            text
          }
          rich_text_details {
            type
            details {
              id
              name
              team
              list_view_depth
            }
          }
        }
      }
    }
  }
`;

const GENERATE_CHAT_SUMMARY = gql`
  query generateChatSummary($ticketId: Int!) {
    generateTicketCrossChatSummary(ticketId: $ticketId) {
      ticketId
      summary
      topic {
        name
      }
    }
  }
`;

const CREATE_TICKET = gql`
  ${CORE_TICKET_FIELDS}
  mutation ($param: CreateTicketInput!, $loginedUserId: ID!) {
    createTicket(input: $param) {
      ...CoreTicketFields
      isUserPartOfTicket(userId: $loginedUserId)
    }
  }
`;

const UPDATE_TICKET_STATUS = gql`
  mutation ($ticketId: Int!, $status: TICKET_STATUS!) {
    updateTicketStatus(ticketId: $ticketId, status: $status) {
      id
      status
      lastClosedDate
    }
  }
`;

const SAVE_CHAT_SUMMARY = gql`
  mutation saveChatSummary(
    $ticketId: Int!
    $relatedTickets: [Int]!
    $summary: RichTextInfoInput!
    $createdAt: Date
  ) {
    saveTicketCrossChatSummary(
      ticketId: $ticketId
      relatedTickets: $relatedTickets
      summary: $summary
      createdAt: $createdAt
    ) {
      ticketId
      relatedTickets
      createdAt
      summary {
        blocks {
          rich_text_location_infos {
            offset
            length
            key
          }
          text
        }
        rich_text_details {
          type
          details {
            id
            name
            team
            list_view_depth
          }
        }
      }
    }
  }
`;

const CHAT_BULK_TICKET = gql`
  mutation ($ticketIds: [Int]!, $input: TicketUserInput!) {
    bulkAddUserToTickets(ticketIds: $ticketIds, input: $input) {
      id
    }
  }
`;

const UPDATE_TICKET_URGENCY = gql`
  mutation ($ticketId: Int!, $isUrgent: Boolean!) {
    updateTicketUrgency(ticketId: $ticketId, isUrgent: $isUrgent) {
      id
      isUrgent
    }
  }
`;
const UPDATE_TICKET_READ_STATUS = gql`
  mutation ($ticketId: Int!, $isRead: Boolean!) {
    updateTicketReadStatus(ticketId: $ticketId, isRead: $isRead) {
      id
      isRead
    }
  }
`;

const SNOOZE_TICKET = gql`
  mutation snoozeTicket($ticketId: Int!, $input: SnoozeTicketInput!) {
    snoozeTicket(ticketId: $ticketId, input: $input) {
      id
      ticketId
      snoozeUntil
    }
  }
`;

const UNSNOOZE_TICKET = gql`
  mutation unsnoozeTicket($ticketId: Int!) {
    unsnoozeTicket(ticketId: $ticketId) {
      ticketId
    }
  }
`;

const GET_SIDEPANEL_TREATMENT_DETAILS = gql`
  query getSidepanelTreatmentDetails($id: ID!) {
    getTreatmentById(id: $id) {
      limitation: zenyumCouncilPatientConcern
      exclusion: zenyumCouncilExclusion
      treatmentStage: stage
      clinic {
        name
      }
      currentTreatmentKey: type
      medicalStaff {
        doctors {
          role
          primary
          details {
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const GET_TREATMENT_BY_ID = gql`
  query getTreatmentById($id: ID!) {
    getTreatmentById(id: $id) {
      id
      stage
      type
      patient {
        country: primaryCountry
      }
      treatmentPlan {
        id
        salesforceInfo {
          salesforceLink
        }
        versions {
          id
          salesforceInfo {
            salesforceLink
          }
        }
      }
      details {
        opportunityId
        hasRetainer
      }
      clinic {
        country
        id
        headClinicId
      }
    }
  }
`;

export {
  GET_GROUPS,
  GET_TICKET,
  CREATE_TICKET,
  GET_ALL_TOPICS,
  GET_TPV_DETAILS,
  GET_TICKET_LIST,
  GENERATE_CHAT_SUMMARY,
  GET_TICKET_SEARCH_ZENCHAT_LINK,
  GET_TICKET_USERS,
  GET_TICKET_BY_ID,
  SNOOZE_TICKET,
  UNSNOOZE_TICKET,
  CHAT_BULK_TICKET,
  ADD_USER_TO_TICKET,
  GET_ALL_STAFF_LIST,
  GET_TREATMENT_BY_ID,
  UPDATE_TICKET_OWNER,
  SAVE_CHAT_SUMMARY,
  GET_TREATEMENT_PLANS,
  GET_NEXT_SEQUENCE_ID,
  GET_SIDEPANEL_TREATMENT_DETAILS,
  UPDATE_TICKET_STATUS,
  UPDATE_TICKET_URGENCY,
  GET_ACTIVE_TREATEMENT,
  GET_TOPIC_CONTEXT_TYPE,
  GET_TOPIC_PARTICIPANTS,
  LINK_TICKETS,
  UNLINK_TICKETS,
  ADD_USER_TO_TICKET_SELF,
  REMOVE_USER_FROM_TICKET,
  UPDATE_TICKET_READ_STATUS,
  GET_TICKET_COUNT,
  GET_TICKETS,
  BULK_ASSIGN_TICKETS
};
