import { useRef } from 'react';

import { cloneDeep } from 'lodash';
import { useRecoilState, useRecoilValue } from 'recoil';

import { TICKET_DETAILS, TICKET_STATUS } from 'constants/inbox';
import { TYPE_MAPPER } from 'constants/translationMaps';
import {
  useGetInboxTicketList,
  useGetTicketById
} from 'services/hooks/Ticket.hooks';
import { loginedUserDetails } from 'store/atoms/authAtom';
import {
  inboxFilters,
  selectedTicket,
  selectedTicketActiveTreatement,
  ticketList
} from 'store/atoms/inbox';
import { TicketByIdOptionalArg } from 'types/Inbox.types';
import { TREATMENT_PLAN_TYPE } from 'types/PatientList.types';
import {
  GetTicketsListInput,
  Ticket,
  TicketDetailsCard,
  TicketMetaData,
  TicketTopicContextInfo
} from 'types/Tickets.types';
import { isDataExist, translate } from 'utils';
import { getFullName, getTopicName } from 'utils/common';

const getContextValue = (ticketDetail: Ticket, key: string) => {
  return ticketDetail?.ticketTopicContextInfo?.find(
    (context) => context.contextType === key
  );
};
const getChatOwner = (ticketDetail: Ticket) => {
  const owner = ticketDetail?.ticketUsers?.find(
    (user) => user.groupName === TICKET_DETAILS.CHAT_OWNER
  );
  if (owner?.userFirstName || owner?.userLastName) {
    return getFullName({
      firstName: owner?.userFirstName,
      lastName: owner?.userLastName,
      salutation: ''
    });
  }
  return '-';
};

const getTicketSubtitle = (ticket: Ticket, tpvType?: string): string => {
  const tp: TicketTopicContextInfo | undefined = getContextValue(
    ticket,
    TICKET_DETAILS.TP
  );
  const tpv: TicketTopicContextInfo | undefined = getContextValue(
    ticket,
    TICKET_DETAILS.TPV
  );
  const versionType = tpvType ? translate(TYPE_MAPPER[tpvType]) : '';
  if (tp?.contextValue && tpv?.contextValue) {
    if (versionType !== '') return `${versionType} | ${tpv?.contextValue}`;
    else return `${tp.contextValue} | ${tpv.contextValue}`;
  } else if (tp?.contextValue || tpv?.contextValue) {
    if (tpv?.contextValue && versionType !== '') {
      return `${versionType}|${tpv?.contextValue}`;
    } else return tp?.contextValue || tpv?.contextValue || '';
  }
  return '';
};

const getTicketList = (data: Ticket[]): TicketDetailsCard[] => {
  const ticketList: TicketDetailsCard[] = [];
  data?.forEach((ticketDetails: Ticket) => {
    const tp: TicketTopicContextInfo | undefined = getContextValue(
      ticketDetails,
      TICKET_DETAILS.TP
    );
    const tpv: TicketTopicContextInfo | undefined = getContextValue(
      ticketDetails,
      TICKET_DETAILS.TPV
    );
    const tpvType: TREATMENT_PLAN_TYPE | undefined =
      ticketDetails?.treatmentInfo?.treatmentPlan?.[0]?.versions?.find(
        (version) => version.id === tpv?.contextValueId
      )?.type;
    const patientName = getContextValue(ticketDetails, TICKET_DETAILS.PATIENT);
    const subtitle = getTicketSubtitle(ticketDetails, tpvType);
    const metaData: TicketMetaData = {
      name: patientName?.contextValue || '',
      patientId: patientName?.contextValueId || '',
      title: getTopicName(ticketDetails.topicInfo.topicCode),
      subtitle: subtitle || '',
      owner: getChatOwner(ticketDetails) || '',
      tpDetails: tp,
      tpvDetails: tpv,
      tpvType: translate(`${tpvType ? TYPE_MAPPER[tpvType] : ''}`),
      searchedMessageDetails: {
        message: '',
        messageId: ''
      },
      ticketUsers: ticketDetails.ticketUsers,
      updatedAt: ticketDetails.updatedAt,
      mentionedMessageId: ticketDetails.mentionedMessageId,
      isMentionedMessageRead: ticketDetails.isMentionedMessageRead,
      isSnoozed: !!ticketDetails.snoozeInfo
    };
    let ticketStatus: string;
    if (ticketDetails?.status === TICKET_STATUS.CLOSE) {
      ticketStatus = TICKET_STATUS.CLOSE;
    } else {
      ticketStatus = ticketDetails?.isUrgent
        ? TICKET_STATUS.URGENT
        : ticketDetails?.status;
    }
    const ticket = {
      metaData: metaData,
      id: ticketDetails.id,
      unread: ticketDetails.isRead,
      status: ticketStatus,
      ticketDetails: ticketDetails
    };
    /**
     * @description
     * Multiple same message in the ticket list
     */
    if (isDataExist(ticketDetails.ticketChatSearchMessage)) {
      ticketDetails.ticketChatSearchMessage.forEach((message) => {
        const ticketData = cloneDeep(ticket);
        ticketData.metaData.searchedMessageDetails.message = message.text;
        ticketData.metaData.searchedMessageDetails.messageId = message.id;
        ticketData.metaData.searchedMessageDetails.rich_text_info =
          message.rich_text_info;
        ticketList.push(ticketData);
      });
    } else {
      ticketList.push(ticket);
    }
  });
  return ticketList;
};

const useGetTickets = () => {
  const inboxFilter = useRecoilValue(inboxFilters);
  const userDetails = useRecoilValue(loginedUserDetails);
  const [, setTicketsList] = useRecoilState(ticketList);

  const [getTicketsList] = useGetInboxTicketList({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });

  /**
   * @description
   * Function to get the ticketList
   */
  const getTicketList = (patientId?: string, ticketId?: number) => {
    const variables: GetTicketsListInput = {
      params: {
        ...inboxFilter,
        ticketId,
        patientId,
        paginationInfo: { limit: 50, offset: 0 }
      },
      loginedUserId: userDetails.id
    };
    return getTicketsList({
      variables,
      onCompleted: (data) => {
        setTicketsList([...data.getTickets.tickets]);
      }
    });
  };
  return { getTicketList };
};

/**
 * @description function for getting link to the patient details section
 *
 */
const useGetPatientUrl = () => {
  const selectedActiveTreatement = useRecoilValue(
    selectedTicketActiveTreatement
  );
  if (selectedActiveTreatement?.[0].id) {
    return `patient/list/${selectedActiveTreatement?.[0].id}`;
  }
  return '';
};

const useUpdateSelectedTicket = () => {
  const userDetails = useRecoilValue(loginedUserDetails);
  const [selectedTicketDetails, setSelectedTicketDetails] =
    useRecoilState(selectedTicket);

  const selectedTicketRef = useRef<Ticket>();

  selectedTicketRef.current = selectedTicketDetails;

  const [getSelectedDetailsByID] = useGetTicketById({
    onCompleted: (data) => {
      setSelectedTicketDetails(data.getTicketById);
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });
  const updateSelectedTicketDetails = (
    optionalConfig?: TicketByIdOptionalArg
  ) => {
    if (selectedTicketRef.current?.id) {
      getSelectedDetailsByID({
        variables: {
          ticketId: selectedTicketRef.current?.id,
          loginedUserId: userDetails.id,
          ...optionalConfig
        }
      });
    }
  };
  return { updateSelectedTicketDetails };
};

export {
  useGetPatientUrl,
  useGetTickets,
  getTicketSubtitle,
  getTicketList,
  useUpdateSelectedTicket
};
