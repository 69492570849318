import { FC, useRef, useState } from 'react';

import cx from 'classnames';
import { useRecoilValue } from 'recoil';

import { TicketCardGroup } from 'components';
import { If } from 'components/Generics';
import useOptionSelector from 'components/InboxFilter/InboxFilter.hooks';
import ChatBulkAddButton from 'containers/ChatBulkAddButton/ChatBulkAddButton';
import { ticketList as ticketListAtom } from 'store/atoms';
import { inboxFilters } from 'store/atoms/inbox';
import { useIsExternalUser } from 'store/selectors/auth';
import { TicketPaginationInfo } from 'types/Inbox.types';
import { isDataExist } from 'utils';

import { FilterPanel, PanelHeader, TicketFilters } from './components';
import { FilterContext } from './filterContext';

const Tickets: FC = () => {
  const ticketsList = useRecoilValue(ticketListAtom);
  const inboxFilter = useRecoilValue(inboxFilters);
  const isExternalUser = useRecoilValue(useIsExternalUser);

  const [isChatListLoaded, setIsChatListLoaded] = useState(false);

  const ticketPaginationInfo = useRef<TicketPaginationInfo>({
    offset: 0,
    previousFetchDirection: '',
    previousScrollHeight: 0,
    totalCount: 0
  });

  const ticketScrollRef = useRef<HTMLDivElement>(null);
  const showBulkAddButton =
    !!inboxFilter.viewTicketsWithoutMe && isDataExist(ticketsList);

  const updateTicketListFlag = (isTicketLoaded = true) => {
    setIsChatListLoaded(isTicketLoaded);
  };

  return (
    <section className='flex-1'>
      <section
        className={cx('xxs:w-full chat-content-container', {
          'ticket-card-container-external': isExternalUser,
          'chat-content-container-internal': !isExternalUser
        })}
      >
        {isChatListLoaded && (
          <ChatBulkAddButton ticketPaginationInfo={ticketPaginationInfo} />
        )}
        <div
          className={cx('xxs:w-full  overflow-y-scroll ticket-list-scroll', {
            'bulk-add-ticket-list': showBulkAddButton,
            'ticket-card-container-external':
              !showBulkAddButton && isExternalUser,
            'chat-content-container-internal':
              !showBulkAddButton && !isExternalUser
          })}
          id='ticket-card-group'
          ref={ticketScrollRef}
        >
          <TicketCardGroup
            ticketScrollRef={ticketScrollRef}
            updateTicketListFlag={updateTicketListFlag}
            ticketPaginationInfo={ticketPaginationInfo}
          />
        </div>
      </section>
    </section>
  );
};

const InboxLeftPanel: FC = () => {
  const inboxFilter = useRecoilValue(inboxFilters);

  const [isFilterOpen, setFilterOpen] = useState(false);

  const toggleFilter = () => {
    setFilterOpen((prev) => !prev);
  };

  const handleFilterClose = () => {
    setFilterOpen(false);
  };

  const contextValue = useOptionSelector();
  const { selectedFilters } = contextValue;

  const getIsFilterSelected = () => {
    const isAdvanceSearchApplied = !!inboxFilter.advancedSearch;
    if (isAdvanceSearchApplied) return true;

    for (const key in selectedFilters) {
      if (selectedFilters[key]?.length > 0) {
        return true;
      }
    }
    return false;
  };

  return (
    <section className='flex flex-col h-full relative'>
      <PanelHeader />
      <TicketFilters
        isFilterSelected={getIsFilterSelected()}
        isFilterOpen={isFilterOpen}
        onFilterClick={toggleFilter}
      />
      <Tickets />
      <FilterContext.Provider value={contextValue}>
        <If condition={isFilterOpen}>
          <div>
            <FilterPanel onClose={handleFilterClose} />
          </div>
        </If>
      </FilterContext.Provider>
    </section>
  );
};

export default InboxLeftPanel;
