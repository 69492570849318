import { FC, ReactNode } from 'react';

import { MOBILE_SCREEN_WIDTH } from 'constants/common';
import { useViewport } from 'utils';

interface DesktopDrawerProps {
  className?: string;
  children: ReactNode;
}

const DesktopDrawer: FC<DesktopDrawerProps> = ({ children, className }) => {
  const { width } = useViewport();
  const isDesktop = width >= MOBILE_SCREEN_WIDTH;
  return isDesktop ? (
    <div
      className={`absolute bg-WHITE inset-0 max-h-screen z-3 overflow-y-auto ${className}`}
    >
      {children}
    </div>
  ) : (
    <></>
  );
};

export default DesktopDrawer;
