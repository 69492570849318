import {
  DELETE_REFERRAL_DOCUMENTS,
  SAVE_REFERRAL_DOCUMENTS,
  UPDATE_REFERRAL_DOCUMENTS
} from 'services/query/referralFileUpload';
import {
  CHECK_DATA_VALIDITY,
  DELETE_REFERRAL,
  GET_AGE_DATA,
  GET_ALL_CLINICS,
  GET_FORM_DATA,
  GET_LIST_DATA,
  GET_REFERRAL_BY_ID,
  GET_TAB_DATA,
  SAVE_DATA,
  SUBMIT_DATA
} from 'services/query/referralForm';
import {
  DeleteReferralDocument,
  SaveReferralDocument,
  UpdateReferralDocument
} from 'types/Files.types';
import {
  GetReferralList,
  GetReferralListTabs,
  GetReferralUserAgeGroups,
  AuthMutation,
  GetIfReferralValid,
  GetReferralFormFilters,
  GetReferralById,
  GetAllClinics,
  DeleteReferral
} from 'types/Referrals.types';

import {
  UseGraphQlMutationWithTpes,
  UseGraphQlQuery,
  UsegraphQlLazyQuery,
  UseGraphQlMutation
} from './hooks';

/**
 * Function to get sample data
 * @param {QueryHookOptions=} config query options
 * @returns {QueryResult}
 */
const defaultConfig = {};

export const deleteReferralDocument: DeleteReferralDocument = (config) => {
  return UseGraphQlMutationWithTpes(DELETE_REFERRAL_DOCUMENTS, config);
};
export const SaveReferrals: AuthMutation = (config = defaultConfig) => {
  return UseGraphQlMutation(SAVE_DATA, config);
};

export const SubmitReferrals: AuthMutation = (config = defaultConfig) => {
  return UseGraphQlMutation(SUBMIT_DATA, config);
};
export const useCheckIfReferralValid: GetIfReferralValid = (config) => {
  return UsegraphQlLazyQuery(CHECK_DATA_VALIDITY, config);
};

export const useGetReferralFormFilters: GetReferralFormFilters = (config) => {
  return UseGraphQlQuery(GET_FORM_DATA, config);
};

export const useGetReferralList: GetReferralList = (config) => {
  return UseGraphQlQuery(GET_LIST_DATA, config);
};

export const useGetReferralListTabs: GetReferralListTabs = (config) => {
  return UseGraphQlQuery(GET_TAB_DATA, config);
};

export const useGetUserAgeGroups: GetReferralUserAgeGroups = (config) => {
  return UseGraphQlQuery(GET_AGE_DATA, config);
};

export const useGetAllClinics: GetAllClinics = (config) => {
  return UseGraphQlQuery(GET_ALL_CLINICS, config);
};

export const useSaveReferralDocuments: SaveReferralDocument = (config) => {
  return UseGraphQlMutationWithTpes(SAVE_REFERRAL_DOCUMENTS, config);
};
export const useUpdateReferralDocument: UpdateReferralDocument = (config) => {
  return UseGraphQlMutationWithTpes(UPDATE_REFERRAL_DOCUMENTS, config);
};

export const useGetReferralById: GetReferralById = (config) => {
  return UseGraphQlQuery(GET_REFERRAL_BY_ID, config);
};
export const useDeleteReferral: DeleteReferral = (config) =>
  UseGraphQlMutationWithTpes(DELETE_REFERRAL, config);
