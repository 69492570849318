import { Step } from 'react-joyride';

const baseSteps = [
  {
    target: '#PRE_ASSESSMENT',
    title: 'chips_label.pre-assessment',
    placement: 'bottom-start' as const,
    disableBeacon: true
  },
  {
    target: '#PENDING_PRESCRIPTION',
    title: 'chips_label.prescriptions',
    disableBeacon: true
  },
  {
    target: '#SIDE_TAB_TOGGLE_BUTTON',
    title: '',
    disableBeacon: true
  }
];

export const STAGE_TO_SHOW_GUID = 'TREATMENT_PLAN_REVIEW';

export const webSteps: Step[] = [
  {
    content: 'tooltip.review_a_patient_card',
    ...baseSteps[0]
  },
  {
    content: `prescriptions.click_here_to_review_your_prior_prescription_and_patient's_files.`,
    ...baseSteps[1]
  },
  {
    content: 'sideTab.info',
    ...baseSteps[2]
  }
];
export const webSidePanelSteps: Step[] = [
  {
    content: 'sideTab.info',
    ...baseSteps[2]
  }
];

export const mobSteps: Step[] = [
  {
    content: 'tooltip.review_a_patient_card',
    ...baseSteps[0]
  },
  {
    content: `prescriptions.click_here_to_review_your_prior_prescription_and_patient's_files.`,
    ...baseSteps[1]
  }
];
