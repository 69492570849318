import { Classification } from 'types/Files.types';

import { isDataExist } from './common';

export const regexToFindKey: (fileName: string) => RegExp = (fileName) =>
  new RegExp(`(?<=amazonaws.com/)(.*)${encodeURI(fileName as string)}(?=\\?)`);

export const classifyFiles = (files: any, classification: Array<any>) => {
  const fileMap: Record<string, Array<any>> = {};
  if (isDataExist(classification)) {
    classification.forEach((classification) => {
      fileMap[classification?.key] = [];
    });
  }
  if (isDataExist(files)) {
    files.forEach((file: any) => {
      const isOther =
        file.classification === Classification.OTHER ||
        file.classification === null;
      if (!isOther) {
        fileMap[file.classification] = fileMap[file.classification]?.length
          ? [...fileMap[file.classification], file]
          : [file];
      } else {
        fileMap['other'] = fileMap['other']?.length
          ? [...fileMap['other'], file]
          : [file];
      }
    });
    return fileMap;
  }
};
