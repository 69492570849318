import { FC, MouseEvent, ReactNode } from 'react';

import classNames from 'classnames';

import { customTwMerge as twMerge } from 'utils';

interface ButtonProps {
  onClick?: (e: MouseEvent<HTMLElement>) => void;
  background?: string;
  className?: string;
  disabled?: boolean;
  children?: ReactNode;
  type?: 'submit' | 'reset' | 'button';
  formId?: string;
  disabledBgColor?: string;
  name?: string;
  testId?: string;
}

const Button: FC<ButtonProps> = ({
  onClick,
  background = '',
  disabled = false,
  children,
  className = '',
  type = 'button',
  formId,
  disabledBgColor = 'disabled:bg-CHINESE_WHITE',
  name = '',
  testId
}) => {
  return (
    <button
      id={testId}
      type={type}
      name={name}
      data-testid='button'
      onClick={onClick}
      className={classNames(
        twMerge(
          `py-2 ${disabledBgColor} disabled:text-DISABLED_2 font-normal md:hover:bg-opacity-80 transform transition-all flex items-center justify-center rounded-full`,
          className
        ),
        {
          ripple: background
        }
      )}
      disabled={disabled}
      form={formId}
    >
      {children}
    </button>
  );
};

export default Button;
