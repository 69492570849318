// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ChatSdk from '@chat/chat-sdk/dist';

import {
  CHAT_API_ENDPOINT,
  FIREBASE_API_KEY,
  FIREBASE_PROJECT_ID,
  FIREBASE_AUTH_DOMAIN
} from 'config';

class ChatSDK {
  static _instance: any;
  chatClient: any;
  constructor() {
    if (ChatSDK._instance) {
      return ChatSDK._instance;
    }
    ChatSDK._instance = this;
    this.chatClient = new ChatSdk({
      apiEndpoint: CHAT_API_ENDPOINT,
      firebaseConfig: {
        apiKey: FIREBASE_API_KEY,
        projectId: FIREBASE_PROJECT_ID,
        authDomain: FIREBASE_AUTH_DOMAIN
      }
    });
    // ... Your rest of the constructor code goes after this
  }
  getChatClient = () => this.chatClient;
}

export default new ChatSDK();
