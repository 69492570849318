import { gql } from '@apollo/client';

const ADD_PATIENT_PREFERRED_SLOTS = gql`
  mutation ($input: PatientPreferredSlotsInput!, $treatmentAppointmentId: ID!) {
    addPatientPreferredSlots(
      input: $input
      treatmentAppointmentId: $treatmentAppointmentId
    ) {
      id
      statusInfo {
        statusCode
        actionCode
      }
      patientPreferredSlots {
        id
        preferredStartTime
        preferredEndTime
      }
      clinicInfo {
        id
        name
      }
    }
  }
`;

const UPDATE_APPOINTMENT_INTERNAL_NOTE = gql`
  mutation (
    $treatmentAppointmentId: ID!
    $internalNote: String!
    $isCallWithCSCompleted: Boolean!
  ) {
    updateAppointmentInternalNote(
      treatmentAppointmentId: $treatmentAppointmentId
      internalNote: $internalNote
      isCallWithCSCompleted: $isCallWithCSCompleted
    ) {
      id
      callWithCSComplete
      internalNotes {
        content
      }
    }
  }
`;

const ACCEPT_CLINIC_PREFERRED_SLOT = gql`
  mutation ($clinicPreferredSlotId: ID!, $treatmentAppointmentId: ID!) {
    acceptClinicPreferredSlot(
      clinicPreferredSlotId: $clinicPreferredSlotId
      treatmentAppointmentId: $treatmentAppointmentId
    ) {
      id
      statusInfo {
        statusCode
        actionCode
      }
      bookingStartTime
      bookingEndTime
      doctorInfo {
        id
        firstName
        lastName
      }
      clinicInfo {
        id
        name
      }
      clinicPreferredSlots {
        id
        status
        preferredStartTime
        preferredEndTime
        expireAt
      }
    }
  }
`;

const REJECT_CLINIC_PREFERRED_SLOTS = gql`
  mutation ($treatmentAppointmentId: ID!) {
    rejectClinicPreferredSlots(
      treatmentAppointmentId: $treatmentAppointmentId
    ) {
      id
      statusInfo {
        statusCode
        actionCode
      }
      bookingStartTime
      bookingEndTime
      doctorInfo {
        id
        firstName
        lastName
      }
      clinicInfo {
        id
        name
      }
      clinicPreferredSlots {
        id
        status
        preferredStartTime
        preferredEndTime
        expireAt
      }
    }
  }
`;

const GET_TREATMENT_APPOINTMENT_BY_ID = gql`
  query getTreatmentAppointmentById($treatmentAppointmentId: ID!) {
    getTreatmentAppointmentById(
      treatmentAppointmentId: $treatmentAppointmentId
    ) {
      id
      createdAt
      clinicId
      treatmentId
      appointmentId
      statusInfo {
        statusCode
        actionCode
      }
      patientTreatmentInfo {
        id
        patientId
        patient {
          id
          firstName
          lastName
          email
          phone
        }
        zenyumCouncilPatientConcern
        zenyumCouncilExclusion
        caseComplexity
      }
      appointmentType
      bookingStartTime
      bookingEndTime
      callWithCSComplete
      patientPreferredSlots {
        id
        createdAt
        preferredStartTime
        preferredEndTime
        clinicId
        createdAt
      }
      internalNotes {
        content
      }
      clinicPreferredSlots {
        id
        status
        preferredStartTime
        preferredEndTime
        expireAt
        createdAt
      }
      doctorInfo {
        id
        firstName
        lastName
        maxComplexity
      }
      clinicInfo {
        id
        name
      }
      isClosed
      rescheduleCount
    }
  }
`;

const GET_CLINICS = gql`
  query ($filter: ClinicFilter) {
    getClinics(filter: $filter) {
      value: id
      label: name
      sfClinicId
    }
  }
`;

const GET_ALL_DOCTORS_BY_CLINICS = gql`
  query ($filter: GetDoctorFilter) {
    getAllDoctorsByClinics(input: $filter) {
      id
      firstName
      lastName
      country: primaryCountry
      details {
        salesforceId
      }
      phone
      email
      clinic {
        id
      }
    }
  }
`;

const GET_DOCTOR_TIMING_FOR_CALENDAR = gql`
  query ($filter: DoctorTimingsInputFilter) {
    getDoctorTimingsForCalendar(input: $filter) {
      date
      day
      doctorHours {
        clinicId
        doctorId
        hours {
          workingHours {
            startTime
            endTime
          }
          zenyumHours {
            startTime
            endTime
          }
        }
      }
    }
  }
`;

const ACCEPT_PATIENT_PREFERRED_SLOT = gql`
  mutation AcceptPatientPreferredSlot(
    $treatmentAppointmentId: ID!
    $input: AcceptPatientPreferredSlotInput!
  ) {
    acceptPatientPreferredSlot(
      input: $input
      treatmentAppointmentId: $treatmentAppointmentId
    ) {
      id
    }
  }
`;

const UPDATE_CLINIC_PREFERRED_SLOT = gql`
  mutation UpdateClinicPreferredSlots(
    $input: ClinicPreferredSlotsInput!
    $treatmentAppointmentId: ID!
  ) {
    updateClinicPreferredSlots(
      input: $input
      treatmentAppointmentId: $treatmentAppointmentId
    ) {
      id
    }
  }
`;

export {
  ADD_PATIENT_PREFERRED_SLOTS,
  UPDATE_APPOINTMENT_INTERNAL_NOTE,
  ACCEPT_CLINIC_PREFERRED_SLOT,
  REJECT_CLINIC_PREFERRED_SLOTS,
  GET_TREATMENT_APPOINTMENT_BY_ID,
  GET_CLINICS,
  GET_ALL_DOCTORS_BY_CLINICS,
  GET_DOCTOR_TIMING_FOR_CALENDAR,
  ACCEPT_PATIENT_PREFERRED_SLOT,
  UPDATE_CLINIC_PREFERRED_SLOT
};
