import { FC, ReactNode } from 'react';

import { Button, Modal } from 'components';
import { translate } from 'utils';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
  name: ReactNode;
  isLoginedUser?: boolean;
}

const RemoveTeamMember: FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  isLoginedUser = false,
  name
}) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      classNames={{
        modal: 'mx-4'
      }}
    >
      <section className='p-6 md:w-656px'>
        <h4 className='text-DEFAULT_TEXT text-xl font-semibold'>
          {translate('INBOX.REMOVE_PARTICIPANTS.TITLE')}
        </h4>
        <p className='mt-8 text-sm text-ONYX'>
          {isLoginedUser
            ? 'Please confirm if you would like to remove'
            : translate('INBOX.REMOVE_PARTICIPANTS.PLEASE_CONFIRM')}
          <b className='font-bold'> {name} </b>
          {isLoginedUser
            ? 'from the conversation. You will no longer be able to view the conversation once removed.'
            : translate('INBOX.REMOVE_PARTICIPANTS.CONFIRM_CONTINUE')}
        </p>
        <div className='flex justify-end mt-6'>
          <Button className='py-3 px-4 text-DEFAULT_TEXT' onClick={onClose}>
            {translate('cancel.cancel')}
          </Button>
          <Button
            className='bg-PRIMARY text-WHITE px-5 py-3 ml-2'
            onClick={onSubmit}
          >
            {translate('confirm.confirm')}
          </Button>
        </div>
      </section>
    </Modal>
  );
};

export default RemoveTeamMember;
