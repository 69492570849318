import { useSetRecoilState } from 'recoil';

import { successErrorAlert } from 'store/atoms';
import { showToast } from 'utils/toast/toast.util';

export const useSetError = () => {
  const setAlert = useSetRecoilState(successErrorAlert);

  const setError = (errorMessage = '', success = false, customClass = '') => {
    if (success) {
      setAlert({ success, message: errorMessage, customClass });
    } else {
      showToast(errorMessage, false);
    }
  };

  return { setError };
};
