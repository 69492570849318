import React, { TextareaHTMLAttributes, FC } from 'react';

import { customTwMerge as twMerge } from 'utils';

import { useTextAreaHeight } from './AutoTextarea.hooks';

type AutoTextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  fontClass?: string;
  initialHeight?: string;
  maxgrowHeight?: number;
};

const AutoTextArea: FC<AutoTextAreaProps> = ({
  fontClass = 'md:text-17px xxs:text-14px',
  initialHeight = 'auto',
  maxgrowHeight = 150,
  ...props
}) => {
  const { value, placeholder } = props;
  const { textAreaRef, textAreaHeight } = useTextAreaHeight(
    value,
    initialHeight,
    placeholder
  );

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <textarea
      {...props}
      ref={textAreaRef}
      rows={1}
      style={{
        resize: 'none',
        minHeight: initialHeight,
        height: textAreaHeight,
        maxHeight: `${maxgrowHeight}px`,
        width: '100%',
        borderRadius: '0.5rem',
        border: '1px solid ',
        borderColor: '#EBEBEB',
        overflowY:
          textAreaHeight === 'auto' ||
          parseFloat(textAreaHeight) > maxgrowHeight
            ? 'auto'
            : 'hidden',
        outline: 'none'
      }}
      onChange={onChangeHandler}
      className={twMerge('placeholder-LIGHT_GRAY_4 px-4 py-3 ', fontClass)}
    />
  );
};

export default AutoTextArea;
