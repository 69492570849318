/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useRef, useState } from 'react';

import { DocumentNode } from '@apollo/client';
import { isEqual } from 'lodash';

import PatientFileUpload from 'components/PatientFileUpload/PatientFileUpload';
import { TreatmentSourceType } from 'components/TreatmentProgressMenu/TreatmentMenuConfig';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { deleteTreatmentDocument } from 'services/hooks/TreatmentPlanning.hooks';
import { FilesData, RenderFooter, RenderHeader } from 'types/Files.types';
import { TreatmentDocumentType } from 'types/TreatmentPlanning.types';
import useCustomCompareEffect from 'utils/customHooks/useCustomCompareEffect';

import useFileUpload from './fileUpload.hooks';

type TreatmentFileUploaderProps = {
  treatmentId: string;
  value?: FilesData[];
  onChange?: (files: FilesData[]) => void;
  documentType: TreatmentDocumentType;
  sourceType: TreatmentSourceType;
  allowedFileMimeType?: string;
  fileDropAreaTitle?: string;
  title?: string;
  className?: string;
  hideUploadModal?: boolean;
  isMandatory?: boolean;
  errorMessage?: string;
  uploadButtonName?: string;
  refetchQueries?: Array<DocumentNode>;
  retainerFiles?: FilesData[];
  name: string;
  uploadContainerStyle?: string;
  supportedFilesText?: string;
  enableSlider?: boolean;
  isResubmission?: boolean;
  onFileuploadSuccess?: (data: FilesData[]) => void;
  onUploadSuccess?: () => void;
  onUploadFail?: () => void;
  uploadButtonHandler?: () => void;
  onUploadClicked?: () => void;
  dropContainerClassName?: string;
  allFiles: FilesData[];
  titleClassName?: string;
  warningText?: string;
  renderHeader?: RenderHeader;
  renderFooter?: RenderFooter;
  disabled?: boolean;
  thumbnailHeight?: string;
  query?: {
    url: DocumentNode;
    urlKey: string;
    attach: DocumentNode;
    attachKey: string;
    deleteDocument?: (variables: any) => Promise<void>;
  };
};

const TreatmentFileUploader: FC<TreatmentFileUploaderProps> = ({
  treatmentId,
  //TODO : Commented as part of ticket ZCCAS-4320
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onChange,
  value = [],
  allowedFileMimeType = '',
  documentType,
  fileDropAreaTitle = '',
  title = '',
  className = '',
  isMandatory = false,
  errorMessage = '',
  sourceType,
  uploadButtonName,
  refetchQueries = [],
  retainerFiles = [],
  name,
  uploadContainerStyle,
  supportedFilesText,
  hideUploadModal,
  enableSlider,
  onFileuploadSuccess,
  onUploadFail,
  onUploadSuccess,
  onUploadClicked,
  uploadButtonHandler,
  dropContainerClassName = '',
  allFiles,
  isResubmission,
  titleClassName,
  renderHeader,
  renderFooter,
  disabled,
  warningText,
  thumbnailHeight,
  query
}) => {
  const { animatedField, callback } = useFieldRefContext();
  const [files, setFiles] = useState<FilesData[]>(value);
  const [uploadingFilesCount, setUploadingFilesCount] = useState<number>(0); // Number of files being uploaded.
  const { uploadFile } = useFileUpload({
    treatmentId,
    documentType,
    sourceType,
    isResubmission,
    setFiles,
    onUploadSuccess,
    onUploadFail,
    setUploadingFilesCount,
    refetchQueries,
    query
  });

  const [deleteDocument] = deleteTreatmentDocument({ refetchQueries });
  const isFirstRender = useRef(true);
  const [zoomPhoto, toggleZoomPhoto] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState<string | number>(0);

  useCustomCompareEffect(() => {
    if (!isEqual(files, value)) {
      setFiles(value);
    }
  }, [value]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      const uploadedFiles = files.filter(({ fileId }) => fileId);
      if (
        !isEqual(value, uploadedFiles) &&
        files.length === uploadedFiles.length
      ) {
        onChange?.(uploadedFiles);
      }
    }
  }, [files]);

  useEffect(() => {
    return () => {
      isFirstRender.current = true;
    };
  }, []);

  const onFilesChange = (files: FilesData[]) => {
    onFileuploadSuccess?.(files);
    setFiles((currentFiles) => currentFiles.concat(files));
    const numberOfFiles = files?.length;
    setUploadingFilesCount(numberOfFiles);
    uploadFile(files);
  };

  const onDelete = (id: string | number, localId?: string | number) => {
    if (id) {
      const documentId = String(id);
      const deleteAPI = query?.deleteDocument ?? deleteDocument;
      deleteAPI({
        variables: {
          treatmentId,
          documentId
        }
      })
        .then(() => {
          setFiles((files) => {
            return files.filter(({ fileId }) => fileId !== id);
          });
          toggleZoomPhoto?.(false);
        })
        .catch(console.log);
    } else {
      setFiles((files) => {
        return files.filter(({ id }) => id !== localId);
      });
    }
  };

  return (
    <PatientFileUpload
      errorMessage={errorMessage}
      callback={callback}
      name={name}
      title={title}
      hideUploadModal={hideUploadModal}
      animatedField={animatedField}
      isMandatory={isMandatory}
      uploadingFilesCount={uploadingFilesCount}
      value={value}
      onFilesChange={onFilesChange}
      files={files}
      onDelete={onDelete}
      allowedFileMimeType={allowedFileMimeType}
      uploadButtonHandler={uploadButtonHandler}
      fileDropAreaTitle={fileDropAreaTitle}
      className={`${
        !files?.length && 'md:h-66 rounded-lg bg-GRAY_6 md:bg-white'
      } ${className}`}
      uploadButtonName={uploadButtonName}
      retainerFiles={retainerFiles}
      uploadContainerStyle={uploadContainerStyle}
      supportedFilesText={supportedFilesText}
      enableSlider={enableSlider}
      onUploadClicked={onUploadClicked}
      dropContainerClassName={dropContainerClassName}
      toggleZoomPhoto={toggleZoomPhoto}
      setSelectedPhoto={setSelectedPhoto}
      allFiles={allFiles ? allFiles || [] : files}
      zoomPhoto={zoomPhoto}
      selectedPhoto={selectedPhoto as string}
      titleClassName={titleClassName}
      renderHeader={renderHeader}
      warningText={warningText}
      renderFooter={renderFooter}
      disabled={disabled}
      thumbnailHeight={thumbnailHeight}
    />
  );
};

export default TreatmentFileUploader;
