import { FC, ReactNode } from 'react';

import { WarningIcon } from 'assets/icons';
import { If } from 'components/Generics';
import { customTwMerge } from 'utils';

interface Props {
  text: string;
  backgroundColor?: string;
  iconColor?: string;
  containerClassName?: string;
  height?: string;
  children?: ReactNode;
  hideIcon?: boolean;
}
const WarningContainer: FC<Props> = ({
  text,
  backgroundColor = 'bg-YELLOW_WARNING',
  iconColor = 'text-DARK_YELLOW',
  containerClassName,
  hideIcon = false,
  height = 'h-16',
  children
}) => {
  return (
    <div
      className={customTwMerge(
        `${backgroundColor} ${height} flex flex-row justify-center gap-4 w-full  items-center rounded-lg p-3`,
        containerClassName
      )}
    >
      <If condition={!hideIcon}>
        <div className='place-self-start mt-1'>
          <WarningIcon className={`h-5 w-5 ${iconColor}`} title='' />
        </div>
      </If>
      {text && <p className={`text-sm text-DEFAULT_TEXT w-full`}>{text}</p>}
      {children}
    </div>
  );
};

export default WarningContainer;
