import { gql } from '@apollo/client';

export const GET_BRANCHES_BY_CLINIC = gql`
  query GetBranchesOfHeadClinic(
    $headClinicId: String
    $searchKey: String
    $countries: [String]
    $sortBy: String
  ) {
    getBranchesOfHeadClinic(
      headClinicId: $headClinicId
      searchKey: $searchKey
      countries: $countries
      sortBy: $sortBy
    ) {
      id
      name
      street
      city
      postalCode
      state
      country
      availability {
        weekday {
          startTime
          endTime
        }
        weekend {
          startTime
          endTime
        }
      }
      isAppointmentEnabled
    }
  }
`;

export const GET_CLININC_BY_ID = gql`
  query GetClinicById($id: ID!) {
    getClinicById(id: $id) {
      id
      name
      street
      city
      postalCode
      state
      country
    }
  }
`;

export const CREATE_BRANCH = gql`
  mutation CreateClinic($input: CreateClinicInput!) {
    createClinic(input: $input) {
      id
    }
  }
`;

export const UPDATE_BRANCH = gql`
  mutation UpdateClinic($id: ID!, $input: UpdateClinicInput!) {
    updateClinic(id: $id, input: $input) {
      id
      name
      street
      city
      postalCode
      state
      country
      availability {
        weekday {
          startTime
          endTime
        }
        weekend {
          startTime
          endTime
        }
      }
    }
  }
`;

export const GET_CLINIC_WORKING_HOURS = gql`
  query GetClinicWorkingHours($input: WorkingHoursInput) {
    getEntityWorkingHours(input: $input) {
      entityType
      entityId
      timings {
        day
        isWorking
        isWorkingFullTime
        availableHours {
          startTime
          endTime
        }
      }
    }
  }
`;

export const GET_DOCTOR_WORKING_HOURS = gql`
  query GetDoctorWorkingHours($input: WorkingHoursInput) {
    getEntityWorkingHours(input: $input) {
      entityType
      entityId
      timings {
        day
        isWorking
        isWorkingFullTime
        availableHours {
          startTime
          endTime
        }
      }
    }
  }
`;

export const UPDATE_WORKDAY_TIMING = gql`
  mutation UpdateWorkDayTiming($input: [WorkDayInput]!) {
    updateWorkDayTiming(input: $input) {
      id
      entity
      entityId
    }
  }
`;
