import { FC } from 'react';

type CalendarPopoverEventCardProps = {
  color: string;
  title: string;
  timeText: string;
  onClick: () => void;
};

const CalendarPopoverEventCard: FC<CalendarPopoverEventCardProps> = ({
  color,
  title,
  timeText,
  onClick
}) => {
  return (
    <button
      onClick={onClick}
      className='w-full flex  justify-between items-center  text-DEFAULT_TEXT text-left hover:bg-[rgba(0,0,0,0.1)]'
    >
      <div className='rounded-full h-2 w-2' style={{ background: color }} />
      <div className='mx-1 flex-1 h-full truncate text-sm'>{title}</div>
      <div className='h-full text-sm'>{timeText}</div>
    </button>
  );
};

export default CalendarPopoverEventCard;
