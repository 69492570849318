/* eslint-disable react/no-unused-prop-types */
import { FC, useMemo, useState } from 'react';

import { ViewProps, Duration } from '@fullcalendar/common';
import { sliceEvents } from '@fullcalendar/react';
import moment from 'moment';
import { CalendarTileProperties } from 'react-calendar';

import { EventNotifier } from 'assets/icons';
import { ReactCalendar, EventComponent } from 'components';
import { DATE_FORMAT } from 'constants/settings';
import { formatEventRenderRangeArray } from 'utils/common';

export const MonthViewMobile: FC<ViewProps & { nextDayThreshold: Duration }> = (
  props
) => {
  const [date, setDate] = useState(new Date());
  const events = useMemo(() => sliceEvents(props, true), [props]);

  const formattedEvents = useMemo(() => {
    return formatEventRenderRangeArray(events);
  }, [events]);

  const selectedDayEvents = useMemo(
    () =>
      formattedEvents?.filter(
        (event) =>
          moment(event?.start).format(DATE_FORMAT) ===
          moment(date)?.format(DATE_FORMAT)
      ),
    [date, formattedEvents]
  );

  const renderDate: (props: CalendarTileProperties) => JSX.Element | null = ({
    date: cellDate
  }) => {
    const hasEvent = events?.find(
      (event) =>
        moment(event?.def?.extendedProps?.appointmentStart).format(
          DATE_FORMAT
        ) === moment(cellDate).format(DATE_FORMAT)
    );
    return (
      <div className='flex flex-col items-center justify-center mt-2'>
        {hasEvent && <EventNotifier />}
      </div>
    );
  };

  return (
    <div className='h-full relative'>
      <div className='sticky top-0 border-b border-LIGHT_GRAY_2 pb-4 mb-4 bg-WHITE'>
        <ReactCalendar
          activeStartDate={props?.dateProfile?.currentRange?.start}
          value={date}
          showNavigation={false}
          showNeighboringMonth={false}
          onChange={(newDate: Date) => {
            setDate(newDate);
          }}
          tileContent={renderDate}
        />
      </div>
      <div className='overflow-auto space-y-2'>
        {selectedDayEvents?.map((event) => {
          return <EventComponent key={event?.id} event={event} />;
        })}
      </div>
    </div>
  );
};
