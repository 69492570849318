import React from 'react';

import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const domNode = document.getElementById('root');
if (domNode) {
  const root = createRoot(domNode);
  root.render(
    <React.StrictMode>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </React.StrictMode>
  );
}

console.log(
  `%c 
8888888888P                                                  
      d88P                                                   
     d88P                                                    
    d88P    .d88b.  88888b.  888  888 888  888 88888b.d88b.  
   d88P    d8P  Y8b 888 "88b 888  888 888  888 888 "888 "88b 
  d88P     88888888 888  888 888  888 888  888 888  888  888 
 d88P      Y8b.     888  888 Y88b 888 Y88b 888 888  888  888 
d8888888888 "Y8888  888  888  "Y88888  "Y88888 888  888  888 
                                  888                        
                             Y8b d88P                        
                              "Y88P"                         `,
  'color: #2CAAE2;'
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
