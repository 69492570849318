import { ReactText } from 'react';

import { toast } from 'react-toastify';

import { SuccessIcon, ErrorCrossIcon } from 'assets/icons';

import 'react-toastify/dist/ReactToastify.css';
import './styles.css';

const POSITION = 'top-center';
const AUTO_CLOSE_DELAY = 5000;
const style = {
  background: '#F7D2D7',
  border: '1px solid #E60225'
};
const successStyle = {
  background: '#CDEDE5',
  border: '1px solid #A3D9CC',
  color: '#136753'
};

const uniqueErrorMessages = new Set();
let clearUniqueErrorMessagesTimeout: ReturnType<typeof setTimeout> | undefined;

export const showToast = (
  message: string,
  success: boolean
): ReactText | undefined => {
  if (!message) {
    return;
  }
  if (success) {
    return toast.success(message, {
      position: POSITION,
      autoClose: AUTO_CLOSE_DELAY,
      hideProgressBar: true,
      closeButton: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: successStyle,
      icon: (
        <SuccessIcon
          className='text-SUCCESS_ICON_DEFAULT'
          data-testid='successAlert'
        />
      )
    });
  } else {
    if (uniqueErrorMessages.has(message)) {
      return 0;
    }
    uniqueErrorMessages.add(message);

    if (clearUniqueErrorMessagesTimeout) {
      clearTimeout(clearUniqueErrorMessagesTimeout);
    }
    clearUniqueErrorMessagesTimeout = setTimeout(() => {
      uniqueErrorMessages.clear();
    }, AUTO_CLOSE_DELAY);

    return toast.error(message, {
      position: POSITION,
      autoClose: AUTO_CLOSE_DELAY,
      hideProgressBar: true,
      closeButton: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: style,
      icon: <ErrorCrossIcon className='text-ERROR justify-center' />
    });
  }
};
