import { useRecoilValue } from 'recoil';

import { loginedUserDetails } from 'store/atoms';

export const useHasAccess = (permission: string | string[]): boolean => {
  const { permissions = [] } = useRecoilValue(loginedUserDetails);
  if (!permission) return true;
  return Array.isArray(permission)
    ? permission.every((item) => permissions.includes(item))
    : permissions?.includes(permission);
};
