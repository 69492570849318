import { useLazyQuery } from '@apollo/client';

import {
  ADD_USER_TO_TICKET,
  ADD_USER_TO_TICKET_SELF,
  CREATE_TICKET,
  GET_ACTIVE_TREATEMENT,
  GET_ALL_STAFF_LIST,
  GET_ALL_TOPICS,
  GET_GROUPS,
  GET_NEXT_SEQUENCE_ID,
  GET_TOPIC_CONTEXT_TYPE,
  GET_TOPIC_PARTICIPANTS,
  GET_TREATEMENT_PLANS,
  GET_TREATMENT_BY_ID,
  REMOVE_USER_FROM_TICKET,
  UPDATE_TICKET_OWNER,
  GET_TICKET_USERS,
  GET_SIDEPANEL_TREATMENT_DETAILS
} from 'services/query/inbox';
import {
  ActiveTreatements,
  AddUserToTicket,
  CreateNewTicket,
  GetActiveTreatement,
  GetAllChatTopics,
  GetAllChatTopicsList,
  GetAllContextType,
  GetAllStaff,
  GetAllStaffList,
  GetAllTPVs,
  GetAllTreatementPlan,
  GetContextType,
  GetGroups,
  GetNextTicketSequenceId,
  GetTopicParticipantsList,
  UseGetTreatmentById,
  RemoveUserFromTicket,
  TicketSequenceId,
  TopicParticipantsList,
  UpdateTicketOwner,
  GetTreatmentById,
  UseGetTicketUsers,
  GetTicketUsers,
  GetAllStaffs,
  GetAllChatTopic,
  GetSidepanelTreatmentDetails
} from 'types/Inbox.types';

import { UseGraphQlMutation, UseGraphQlQuery } from './hooks';

const useGetActiveTreatement: GetActiveTreatement = (config) =>
  useLazyQuery<ActiveTreatements>(GET_ACTIVE_TREATEMENT, config);

/**
 * Function to get all the chat topics
 * @param {QueryHookOptions=} config query options
 * @returns {QueryResult}
 */
const useGetAllChatTopics: GetAllChatTopics = (config) =>
  useLazyQuery<GetAllChatTopicsList>(GET_ALL_TOPICS, config);

/**
 * Hook to get all the TPV details
 * @param {QueryHookOptions=} config query options
 * @returns {QueryResult}
 */
const useGetTreatmentPlans: GetAllTPVs = (config) =>
  useLazyQuery<GetAllTreatementPlan>(GET_TREATEMENT_PLANS, config);

/**
 * Hook to get all context for the ticket
 * @param {QueryHookOptions=} config query options
 * @returns {QueryResult}
 */
const useGetTopicContextType: GetContextType = (config) =>
  useLazyQuery<GetAllContextType>(GET_TOPIC_CONTEXT_TYPE, config);

/**
 * Hook to get sequence uniquw id
 * @param {QueryHookOptions=} config query options
 * @returns {QueryResult}
 */
const useGetNextTicketSequenceId: GetNextTicketSequenceId = (config) =>
  useLazyQuery<TicketSequenceId>(GET_NEXT_SEQUENCE_ID, config);

/**
 * mutation to create a new ticket
 * @param {CreateNewTicket} config query options
 * @returns {QueryResult}
 */
const useCreateTicket: CreateNewTicket = (config) =>
  UseGraphQlMutation(CREATE_TICKET, { ...config });

/**
 * Hook to get sequence uniquw id
 * @param {QueryHookOptions=} config query options
 * @returns {QueryResult}
 */
const useGetTopicParticipantsList: GetTopicParticipantsList = (config) =>
  useLazyQuery<TopicParticipantsList>(GET_TOPIC_PARTICIPANTS, config);

const useGetGroups: GetGroups = (config) => {
  return UseGraphQlQuery(GET_GROUPS, config);
};

const useGetAllChatTopic: GetAllChatTopic = (config) => {
  return UseGraphQlQuery(GET_ALL_TOPICS, config);
};

const useSidepanelTreatmentDetails: GetSidepanelTreatmentDetails = (config) => {
  return UseGraphQlQuery(GET_SIDEPANEL_TREATMENT_DETAILS, config);
};
/**
 * mutation to remove user from ticket
 */
const useRemoveUserFromTicket: RemoveUserFromTicket = (config) =>
  UseGraphQlMutation(REMOVE_USER_FROM_TICKET, {
    ...config
  });
/**
 * mutation to remove user from ticket
 */
const useUpdateTicketOwner: UpdateTicketOwner = (config) =>
  UseGraphQlMutation(UPDATE_TICKET_OWNER, {
    ...config
  });
/**
 * mutation to add user to ticket
 */
const useAddUserToTicket: AddUserToTicket = (config) =>
  UseGraphQlMutation(ADD_USER_TO_TICKET, { ...config });

/**
 * lazy query to ticket
 */
const useGetTicketUserDetails: UseGetTicketUsers = (config) =>
  useLazyQuery<GetTicketUsers>(GET_TICKET_USERS, {
    ...config
  });

/**
 * Hook to get staffList
 * @param {QueryHookOptions=} config query options
 * @returns {QueryResult}
 */
const useGetAllStaffList: GetAllStaffList = (config) =>
  useLazyQuery<GetAllStaff>(GET_ALL_STAFF_LIST, config);

// TODO: optimize the query
const useGetAllStaffs: GetAllStaffs = (config) => {
  return UseGraphQlQuery(GET_ALL_STAFF_LIST, config);
};
/**
 * Hook to get staffList
 * @param {QueryHookOptions=} config query options
 * @returns {QueryResult}
 */
const useAddUserToTicketSelf: AddUserToTicket = (config) =>
  UseGraphQlMutation(ADD_USER_TO_TICKET_SELF, {
    ...config
  });

export const useGetTreatmentById: UseGetTreatmentById = (config) => {
  return useLazyQuery<GetTreatmentById>(GET_TREATMENT_BY_ID, config);
};

export {
  useGetGroups,
  useCreateTicket,
  useAddUserToTicket,
  useGetAllStaffList,
  useGetAllChatTopic,
  useGetAllChatTopics,
  useUpdateTicketOwner,
  useGetAllStaffs,
  useGetTreatmentPlans,
  useAddUserToTicketSelf,
  useGetActiveTreatement,
  useSidepanelTreatmentDetails,
  useGetTopicContextType,
  useRemoveUserFromTicket,
  useGetTicketUserDetails,
  useGetNextTicketSequenceId,
  useGetTopicParticipantsList
};
