import { FC, Fragment } from 'react';

import cx from 'classnames';
import { Row } from 'react-table';

interface TableRowComponent {
  props: any;
  rows: Row<object>[];
  prepareRow: (row: Row<object>) => void;
  bodyClassName?: string;
  showRowPointer?: boolean;
  onTableRowClick?: (data: any, isExpanded: boolean) => void;
  tableDataClassName?: string;
  isCustom?: boolean;
}

const TableRowComponent: FC<TableRowComponent> = ({
  props,
  rows,
  prepareRow,
  bodyClassName,
  showRowPointer,
  onTableRowClick,
  tableDataClassName,
  isCustom
}) => {
  const index = props['data-index'];
  const row = rows[index];
  prepareRow(row);

  return (
    <tr
      {...props}
      {...row.getRowProps()}
      key={`data-${index}`}
      data-testid='patient-list-rows'
      className={`${
        !bodyClassName
          ? cx('h-12 hover:bg-HOVER_BLUE', {
              'bg-GRAY': index % 2 !== 0,
              'bg-LIGHT_BLUE_5': row.isExpanded,
              'cursor-pointer': showRowPointer
            })
          : bodyClassName
      }`}
      onClick={() => {
        onTableRowClick?.(row.original, !row.isExpanded);
      }}
    >
      {row.cells.map((cell: any, index: number) => {
        return (
          <Fragment key={`data-cell-${index}`}>
            <td
              {...cell.getCellProps()}
              className={
                tableDataClassName
                  ? tableDataClassName
                  : `${!isCustom && 'box-borders px-2'} text-sm`
              }
            >
              {cell.render('Cell')}
            </td>
          </Fragment>
        );
      })}
    </tr>
  );
};

export default TableRowComponent;
