import { FC } from 'react';

import { ImageIcon, PdfIcon } from 'assets/icons';

type ThumbnailNameProp = {
  name: string;
  type: 'img' | 'file';
  className?: string;
  textClassName?: string;
};

const ThumbnailName: FC<ThumbnailNameProp> = ({
  name = '',
  type = 'img',
  className = '',
  textClassName = ''
}) => (
  <div className={`flex ${className}`}>
    <div className='flex-shrink-0'>
      {type === 'img' ? (
        <ImageIcon title='image-icon' className='mt-0.75 ' />
      ) : (
        <PdfIcon title='pdf-icon' className='w-4 h-4 md:mt-0.1rem mt-0.75' />
      )}
    </div>
    <div className='min-w-0 ml-1.5 flex items-center'>
      <p className={`${textClassName}`}>{name}</p>
    </div>
  </div>
);

export default ThumbnailName;
