import { FC } from 'react';

import { translate } from 'utils';

interface ReopenTicketModalProps {
  patientName: string;
  topicName: string;
}
const ReopenTicketModal: FC<ReopenTicketModalProps> = ({
  patientName,
  topicName
}) => {
  return (
    <>
      <h2 className='font-semibold text-20px leading-6 mb-6 text-DEFAULT_TEXT'>
        {translate('INBOX.REOPEN_POPUP.REOPEN_CONVERSATION')}
      </h2>
      <div className='text-14px mt-2 pr-3'>
        <p>{translate('INBOX.REOPEN_POPUP.CONFIRM_REOPEN')}</p>
        <p className='font-semibold'>
          {translate('INBOX.CLOSE_POPUP.FOR', {
            X: topicName,
            patient: patientName
          })}
        </p>
        <p>{translate('INBOX.REOPEN_POPUP.REOPEN_WARNING')}</p>
      </div>
    </>
  );
};

export default ReopenTicketModal;
