import { FC } from 'react';

import CollapsibleMenu from 'components/CollapsibleMenu/CollapsibleMenu';
import { translate } from 'utils';

import PatientFiles from '../PatientFiles/PatientFiles';

type PatientFilesCollapsibleProps = {
  name?: string;
  referralId: string;
  className?: string;
  preventExpand?: boolean;
  isExpanded: boolean;
  toggleExpand: (value: Record<string, boolean>) => void;
  status?: string;
  isPrescription?: boolean;
  innerContainerClassName?: string;
  disableEdit?: boolean;
};

const PatientFilesCollapsible: FC<PatientFilesCollapsibleProps> = ({
  className = '',
  referralId,
  preventExpand,
  name = '',
  toggleExpand,
  isExpanded,
  status,
  isPrescription,
  innerContainerClassName,
  disableEdit
}) => {
  return (
    <CollapsibleMenu
      title={translate('patient.files')}
      status={status}
      className={className}
      preventExpand={preventExpand}
      toggleExpand={(isExpanded) => toggleExpand({ [name]: isExpanded })}
      isExpanded={isExpanded}
      titleFont='text-17px'
      isPrescription={isPrescription}
    >
      <PatientFiles
        referralId={referralId}
        className={innerContainerClassName}
        disableEdit={disableEdit}
      />
    </CollapsibleMenu>
  );
};

export default PatientFilesCollapsible;
