import { GET_ALL_DOCTORS_BY_CLINICS } from 'services/query/appointment';
import {
  CREATE_BRANCH,
  GET_BRANCHES_BY_CLINIC,
  GET_CLINIC_WORKING_HOURS,
  GET_CLININC_BY_ID,
  GET_DOCTOR_WORKING_HOURS,
  UPDATE_BRANCH,
  UPDATE_WORKDAY_TIMING
} from 'services/query/Branches.queries';
import { Doctors, DoctorsInput } from 'types/Appointment.types';
import {
  CreateBranch,
  GetAllDoctorsByClinics,
  GetBranchesByClinicId,
  GetBranchesByClinicIdResponse,
  GetBranchesByClinicInput,
  GetClinicById,
  GetClinicByIdInput,
  GetClinicByIdResponse,
  GetEntityWorkingHours,
  GetEntityWorkingHoursInput,
  GetEntityWorkingHoursResponse,
  UpdateBranch,
  UpdateWorkDayTiming
} from 'types/Branches.types';

import { UseGraphQlMutationWithTpes, UseGraphQlQueryWithTypes } from './hooks';

export const useGetBranchesByClinicId: GetBranchesByClinicId = (config) => {
  return UseGraphQlQueryWithTypes<
    GetBranchesByClinicIdResponse,
    GetBranchesByClinicInput
  >(GET_BRANCHES_BY_CLINIC, config);
};

export const useCreateBranch: CreateBranch = (config) => {
  return UseGraphQlMutationWithTpes(CREATE_BRANCH, config);
};

export const useUpdateBranch: UpdateBranch = (config) => {
  return UseGraphQlMutationWithTpes(UPDATE_BRANCH, config);
};

export const useGetClinicId: GetClinicById = (config) => {
  return UseGraphQlQueryWithTypes<GetClinicByIdResponse, GetClinicByIdInput>(
    GET_CLININC_BY_ID,
    config
  );
};

export const useGetAllDoctorsByClinics: GetAllDoctorsByClinics = (config) => {
  return UseGraphQlQueryWithTypes<Doctors, DoctorsInput>(
    GET_ALL_DOCTORS_BY_CLINICS,
    config
  );
};
export const useGetClinicWorkingHours: GetEntityWorkingHours = (config) => {
  return UseGraphQlQueryWithTypes<
    GetEntityWorkingHoursResponse,
    GetEntityWorkingHoursInput
  >(GET_CLINIC_WORKING_HOURS, config);
};

export const useGetDoctorWorkingHours: GetEntityWorkingHours = (config) => {
  return UseGraphQlQueryWithTypes<
    GetEntityWorkingHoursResponse,
    GetEntityWorkingHoursInput
  >(GET_DOCTOR_WORKING_HOURS, config);
};

export const useUpdateWorkDayTiming: UpdateWorkDayTiming = (config) => {
  return UseGraphQlMutationWithTpes(UPDATE_WORKDAY_TIMING, config);
};
