import * as yup from 'yup';

export const LoginSchema = yup.object().shape({
  email: yup
    .string()
    .required('required.email')
    .matches(
      /^\s*(([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+)*\s*$/,
      'error.emailInvalid'
    ),
  password: yup
    .string()
    .typeError('required.password')
    .required('required.password')
});

export const GenerateResetLinkSchema = yup.object().shape({
  email: yup
    .string()
    .matches(
      /^\s*(([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+([;.](([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5}){1,25})+)*\s*$/,
      'error.emailInvalid'
    )
    .required('required.email')
});
// to do => missing translations
const RESET_PASSWORD_VALIDATION = {
  password: yup
    .string()
    .min(8, 'error.passwordLength')
    .max(32)
    .required('description.required')
    .matches(
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]*$/,
      'error.passwordChar'
    ),
  passwordConfirmation: yup
    .string()
    .required('description.required')
    .oneOf([yup.ref('password'), null], 'error_message.passwords_do_not_match')
};

export const ResetPasswordSchema = yup
  .object()
  .shape(RESET_PASSWORD_VALIDATION);
//to do missing translations=>LOGIN.VALIDATION.IS_SAME=> Current password and new password should be different
export const ResetPasswordSettingsSchema = yup.object().shape({
  ...RESET_PASSWORD_VALIDATION,
  currentPassword: yup
    .string()
    .required('description.required')
    .notOneOf([yup.ref('password')], 'error.passwordSame')
});
