import { FC } from 'react';

import {
  ApolloCache,
  DefaultContext,
  LazyQueryHookOptions,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  QueryTuple
} from '@apollo/client';

import { Classification } from './Files.types';
import { TreatmentSpecExternalType } from './PendingPrescription.types';
import { QueryCallbackFunctions } from './request';
import {
  TreatmentPLanPreferenceFormType,
  UserPreference
} from './Settings.types';
import {
  PriorTreatment,
  PriorTreatmentNeededType,
  TreatmentSpecifications,
  TreatmentSpecType
} from './TreatmentPlanning.types';

export interface ReferralInfoCardItem {
  title: string;
  icon: FC;
  message: string;
}

export interface AuthMutation {
  (config?: MutationHookOptions): MutationTuple<
    any,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}

export interface ReferralSaveInfo {
  saveReferral: {
    id: string;
    updatedAt: string;
  };
}
export interface ReferralSave {
  (config?: MutationHookOptions): MutationTuple<
    ReferralSaveInfo,
    OperationVariables,
    DefaultContext,
    ApolloCache<any>
  >;
}

export interface FilterReferralInput {
  status: string;
  patientName: string;
}

export interface GetReferrals {
  firstName: string;
  lastName: string;
  phone: string;
  id: string;
  consentStatus?: string;
  clinic: {
    name: string;
  };
}
export interface ReferralData {
  getReferrals: GetReferrals[];
}
export interface ReferralTabFilter {
  key: string;
  name: string;
  count: number;
}
export interface ReferralAgeGroup {
  key: string;
  value: string;
}

export interface ReferralResponse {
  firstName: string;
  stage: string;
  lastName: string;
  email: string;
  phone: string;
  age: number;
  ageCategory: string;
  concerns: string[];
  issues: string[];
  status: string;
  patientId: string;
  referringDoctorId: string;
  consentStatus: string;
  referringDoctor: {
    lastName: string;
    firstName: string;
    details: {
      maxComplexity: number;
    };
  };
  clinic: {
    label: string;
    value: string;
    scannerType?: string;
    country: string;
  };
  documents: {
    id: string;
    key?: string;
    name: string;
    url: string;
    type: string;
    extension: string;
    thumbnail?: string;
    createdAt?: string;
    classification: Classification | null;
  }[];
  priorTreatment: PriorTreatment;
  treatmentSpecifications: TreatmentSpecifications;
  guardian: {
    id: string;
    email: string;
    phone: string;
    lastName: string;
    firstName: string;
  };
  updatedAt: string;
}
export interface ReferralDoctors {
  firstName: string;
  lastName: string;
  id: string;
  details: {
    maxComplexity: number;
  };
}

export interface IssueList {
  issue: string;
}
export interface ValidReferralCheckInput {
  id: string;
  email: string;
  phone: string;
  clinicId: string;
}

export interface AgeGroups {
  ageGroups: ReferralAgeGroup[];
  minAge: number;
}

export interface UserAgeGroups {
  getUserAgeGroups: AgeGroups;
}

export interface AllClinics {
  getAllClinics: Option[];
}

export interface ReferralById {
  getReferralById: ReferralResponse;
  getUserPreference: UserPreference;
}

export interface ReferralFilters {
  getAllDoctors: ReferralDoctors[];
  getPatientIssueList: string[];
  getPatientConcernList: string[];
}

export interface IfReferralValid {
  checkIfReferralValid: ValidReferralCheckInput[];
}

export interface ReferralListTabData {
  getReferralStatusInfo: ReferralTabFilter[];
}

export interface GetReferralList {
  (config?: QueryHookOptions): QueryResult<ReferralData>;
}

export interface GetReferralListTabs {
  (config?: QueryHookOptions): QueryResult<ReferralListTabData>;
}
export interface GetIfReferralValid {
  (config?: LazyQueryHookOptions): QueryTuple<any, any>;
}

export interface GetReferralUserAgeGroups {
  (config?: QueryHookOptions): QueryResult<UserAgeGroups>;
}

export type GetClinicsVariables = {
  filter?: {
    excludeHeadClinic?: boolean;
    countries: string[];
  };
};

export interface GetAllClinics {
  (
    config?: QueryHookOptions<AllClinics, GetClinicsVariables>
  ): QueryResult<AllClinics>;
}

export interface GetReferralFormFilters {
  (config?: QueryHookOptions): QueryResult<ReferralFilters>;
}
export interface GetReferralById {
  (config?: QueryHookOptions): QueryResult<ReferralById>;
}

export interface Option {
  label: string;
  value: string | number | boolean;
  country?: string;
}

export interface ReferralForm {
  firstName: string;
  lastName: string;
  clinic: Option;
  activeState: string;
  phone: string;
  referralId?: string;
  email?: string;
  age?: Option;
  id?: string;
  issues?: string[];
  concerns?: string[];
  patientIssue?: Option;
  patientConcerns?: Option;
  other?: string;
  referringDoctor?: { id: string; maxComplexity: number };
  priorTreatment?: PriorTreatmentNeededType;
  treatmentSpecifications?: TreatmentSpecType | TreatmentSpecExternalType;
  clinicalPreferences?: TreatmentPLanPreferenceFormType;
  preAssessmentPhoto?: any;
  metadata: {
    metadataZenclear?: string;
    metadataZenplus?: string;
    clinicalPreference?: string;
  };
  isSubmission?: string;
  xray?: any;
  scan?: any;
  consentForm?: any;
  image?: any;
  takeImpressions?: boolean;
  scannerType: string;
  guardianFirstName?: string;
  guardianLastName?: string;
  guardianPhone?: string;
  guardianEmail?: string;
  ageCategory: string;
  clinicCountry: string;
}

export interface DeleteReferral {
  (
    config?: MutationHookOptions<
      { deleteReferral: boolean },
      { id: string },
      DefaultContext,
      ApolloCache<any>
    >,
    queryCallbacks?: QueryCallbackFunctions
  ): MutationTuple<
    { deleteReferral: boolean },
    { id: string },
    DefaultContext,
    ApolloCache<any>
  >;
}

export enum REFERRALS_STATUS {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  ERROR = 'ERROR',
  COMPLETED = 'COMPLETED',
  COMPLETED_DISABLED = 'COMPLETED_DISABLED',
  DEFAULT_STATUS = '',
  ACTIVE_DISABLED = 'ACTIVE_DISABLED'
}

export interface ReferralSteps {
  label: string;
  state: REFERRALS_STATUS;
  key: string;
}

export interface ReferralButton {
  type: 'button' | 'submit';
  label: string;
}
