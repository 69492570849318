import { FC, useEffect, useMemo, useRef } from 'react';

import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import {
  ILL_FITTING_OPTION,
  ZENCLEAR_TPV_REFINEMENT_FORM_ARCHES_TO_TREAT,
  ZENCLEAR_TPV_REFINEMENT_FORM_ATTACHMENTS
} from 'constants/TPVFormOptions';
import { IprPerformedDropDown } from 'containers/IprPerformedDropDown';
import { RefinementGoals } from 'containers/RefinementGoals';
import { RhfAlignerInput } from 'containers/RhfAlignerInput';
import { useGetAllSubmissionReason } from 'services/hooks/Prescription.hooks';
import { hideLiveTranslation } from 'store/atoms/authAtom';
import { AlignerIPRInfo, TPVFormTypes } from 'types/TPVForm.types';
import { translate } from 'utils';
import { getSetAndZenStageDefaultValues } from 'utils/tpvUtils';

import RhfSelect from './Generics/Rhf/RhfSelect';
import { RHFTextArea } from './RHFTextArea/RHFTextArea';
import SpecialNoteTranslation from './SpecialNoteTranslation/SpecialNoteTranslation';

type TreatmentSpecificationsRefinementProps = {
  formType?: string;
  isDisabled?: boolean;
  predecessorTPVData?: AlignerIPRInfo;
  source?: string;
};

const TreatmentSpecificationsRefinementForm: FC<
  TreatmentSpecificationsRefinementProps
> = ({
  isDisabled: disabled = false,
  predecessorTPVData,
  formType,
  source
}) => {
  const {
    watch,
    setValue,
    formState: { defaultValues }
  } = useFormContext();
  const hideLiveTranslationBox = useRecoilValue(hideLiveTranslation);
  const toothChartCloseResidualRef = useRef<HTMLDivElement>(null);
  const toothChartResolvePosteriorRef = useRef<HTMLDivElement>(null);

  const watchTranslatedNotes = watch(
    `treatmentSpecifications.${formType}.translatedSpecialNotes`
  );
  const isPrescriptionSummary = source === 'PrescriptionSummary';

  const { data: submissionReason } = useGetAllSubmissionReason();

  const submissionReasonOptions = useMemo(() => {
    if (submissionReason?.getRefinementSubmissionReasons) {
      return submissionReason?.getRefinementSubmissionReasons
        ?.filter((reason) => reason.key !== 'ILL_FITTING_AT_FITTING')
        ?.map((reason) => {
          return {
            value: reason.key,
            label: reason.value
          };
        });
    }
    return [];
  }, [submissionReason]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      setValue(
        `treatmentSpecifications.${formType}.iprInfo`,
        predecessorTPVData?.iprInfo
      );
      isFirstRender.current = false;
    }
  }, [setValue, predecessorTPVData]);

  const IsIllfitting = !!(
    formType &&
    defaultValues?.treatmentSpecifications[formType].reasonForSubmission
      ?.value === ILL_FITTING_OPTION.value
  );

  const setSpecialNotes = (fieldName: string, result?: string) => {
    setValue(fieldName, result);
  };
  return (
    <div className='flex flex-col bg-GRAY5 md:p-4 rounded-md space-y-10'>
      <div
        className={classNames({
          'w-full md:w-max': !isPrescriptionSummary
        })}
      >
        <RhfSelect
          inputProps={{
            showErrorTitle: true,
            controlShouldRenderValue: true,
            placeholder: translate('Need.finishing.improvement'),
            placeHolderStyle: {
              fontSize: '0.75rem',
              fontColor: '#B8B8B5'
            },
            selectHeight: '3rem',
            isDisabled: disabled || IsIllfitting
          }}
          options={submissionReasonOptions}
          name={`treatmentSpecifications.${formType}.reasonForSubmission`}
          label={translate('1.Reason.for.submission*')}
          className={{ label: 'text-black text-sm md:mb-4 ' }}
          showError={false}
        />
      </div>
      <div>
        <RhfAlignerInput
          source={source}
          showZenstage={
            !!getSetAndZenStageDefaultValues(
              predecessorTPVData?.alignerInfo?.currentAligner,
              predecessorTPVData?.alignerInfo?.totalSetAligner
            ).zenstage
          }
          name={`treatmentSpecifications.${formType}`}
          maxLimit={predecessorTPVData?.alignerInfo?.setAlignerCount}
          disabled={disabled || IsIllfitting}
        />
      </div>

      <div
        className={classNames({
          'md:min-w-[330px] md:w-max w-full': !isPrescriptionSummary
        })}
      >
        <RhfSelect
          inputProps={{
            controlShouldRenderValue: true,
            placeholder: translate('Upper'),
            showErrorTitle: true,
            placeHolderStyle: {
              fontSize: '0.75rem',
              fontColor: '#B8B8B5'
            },
            selectHeight: '3rem',
            isDisabled: disabled
          }}
          options={ZENCLEAR_TPV_REFINEMENT_FORM_ARCHES_TO_TREAT}
          name={`treatmentSpecifications.${formType}.archesToTreat`}
          label={translate('3.Arches.to.treat')}
          className={{ label: 'text-black text-sm' }}
          showError={false}
        />
      </div>
      <RefinementGoals
        source={source}
        toothChartResolvePosteriorRef={toothChartResolvePosteriorRef}
        toothChartCloseResidualRef={toothChartCloseResidualRef}
        name={`treatmentSpecifications.${formType}`}
        formType={
          formType === 'ZenClear' ? TPVFormTypes.ZENCLEAR : TPVFormTypes.ZENPLUS
        }
        disabled={disabled}
      />

      <div
        className={classNames({
          'md:min-w-[25.25rem] md:w-max w-full': !isPrescriptionSummary
        })}
      >
        <RhfSelect
          inputProps={{
            showErrorTitle: true,
            controlShouldRenderValue: true,
            placeholder: translate('keep.all.previous.attatchments'),
            placeHolderStyle: {
              fontSize: '0.75rem',
              fontColor: '#B8B8B5'
            },
            selectHeight: '3rem',
            isDisabled: disabled
          }}
          options={ZENCLEAR_TPV_REFINEMENT_FORM_ATTACHMENTS}
          name={`treatmentSpecifications.${formType}.attachments`}
          label={translate('5. Attachments')}
          className={{ label: 'text-black text-sm mb-4' }}
          showError={false}
        />
      </div>
      <IprPerformedDropDown
        source={source}
        name={`treatmentSpecifications.${formType}`}
        iprInfo={predecessorTPVData?.iprInfo}
        missingTooth={predecessorTPVData?.missingTooth}
        disabled={disabled}
        smileSummaryUrl={predecessorTPVData?.smileSummaryUrl}
        showSmileSummary={!predecessorTPVData?.iprInfo}
      />
      {hideLiveTranslationBox ? (
        <RHFTextArea
          inputProps={{
            placeholder: translate('settings.please_type_here'),
            disabled: disabled
          }}
          labelclassName='text-sm mb-4'
          title={translate('7. Special Notes')}
          name={`treatmentSpecifications.${formType}.specialNotes`}
          autogrow
        />
      ) : (
        <SpecialNoteTranslation
          isTranslateFieldDefined={!!watchTranslatedNotes}
          setValue={setSpecialNotes}
          isDisabled={disabled}
          title={translate('7. Special Notes')}
          InputTextFieldName={`treatmentSpecifications.${formType}.specialNotes`}
          translatedTextFieldName={`treatmentSpecifications.${formType}.translatedSpecialNotes`}
        />
      )}
    </div>
  );
};

export default TreatmentSpecificationsRefinementForm;
