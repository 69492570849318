import { FC, ReactNode, useEffect, useMemo } from 'react';

import moment from 'moment';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { twMerge } from 'tailwind-merge';

import { Button, Loader } from 'components';
import { If } from 'components/Generics';
import { VersionStatusComponent } from 'components/VersionStatusComponent/VersionStatusComponent';
import {
  INTERNAL_DOCTOR,
  IN_PLANNING_STATUSES,
  KEEP_PLAN_OPENED,
  MOBILE_SCREEN_WIDTH,
  PATIENT,
  STAGES,
  TREATMENT_STATUSES
} from 'constants/common';
import { PATIENT_INBOX_KEY } from 'constants/inbox';
import { TREATMENT_PLAN_VERSION_STATUS } from 'constants/options';
import { PATIENT_CARD_SIDE_PANEL_CONFIG } from 'constants/patientCard';
import { PERMISSIONS } from 'constants/Permissions';
import { DATE_FORMAT } from 'constants/settings';
import { ReOpenPLan } from 'containers/ReOpenPlan/ReOpenPlan';
import { CanAccess } from 'hoc/can-access/CanAccess';
import { useGetTicketId } from 'services/hooks/TreatmentPlan.hooks';
import {
  loginedUserDetails,
  selectedTicket,
  selectedChatAccordion
} from 'store/atoms';
import {
  isWarningClosed,
  showChatMessage,
  showMobileViewInbox,
  ticketList
} from 'store/atoms/inbox';
import { sideDrawerPreference } from 'store/atoms/patientListAtom';
import { TreatmentPlanType, VersionDetails } from 'types/PatientList.types';
import { Ticket } from 'types/Tickets.types';
import {
  SortOrder,
  TOPIC_CODE,
  TOPIC_CONTEXT_TYPE,
  TREATMENT_PLAN_DISCUSSION
} from 'types/TreatmentPlanReview.types';
import { translate, useViewport } from 'utils';
import {
  checkIsExternal,
  getAttatchmentIPRValues,
  getCurrentTpvStatus
} from 'utils/common';

interface Props {
  versionDetails?: VersionDetails;
  handleClick?: () => void;
  patientId?: string;
  treatmentPlan?: TreatmentPlanType;
  refetchTreatmentPlans?: () => void;
  hideReOpenPlan?: boolean;
}

interface LabelInfoProps {
  label: ReactNode;
  value: ReactNode;
  className?: string;
  onClick?: () => void;
}

const LabelInfo: FC<LabelInfoProps> = ({
  label,
  value,
  className = '',
  onClick
}) => (
  <div className='flex items-center w-full mt-3'>
    <div className='font-normal text-GRAY3 md:text-sm xxs:text-xs md:h-6 w-1/3 md:w-3/12 mr-1'>
      {label}
    </div>
    <div
      className={twMerge('md:text-17px xxs:text-sm', className)}
      onClick={onClick}
      role='presentation'
    >
      {value || '-'}
    </div>
  </div>
);

export const TreatmentVersionCard: FC<Props> = ({
  versionDetails,
  handleClick,
  patientId,
  treatmentPlan,
  refetchTreatmentPlans,
  hideReOpenPlan
}) => {
  const setSelectedTicket = useSetRecoilState(selectedTicket);
  const setShowMobileViewInbox = useSetRecoilState(showMobileViewInbox);
  const setSelectedAccordion = useSetRecoilState(selectedChatAccordion);
  const ticketsList = useRecoilValue(ticketList);
  const userDetails = useRecoilValue(loginedUserDetails);
  const setSidepanelPreference = useSetRecoilState(sideDrawerPreference);
  const setWarningClosed = useSetRecoilState(isWarningClosed);
  const setShowChat = useSetRecoilState(showChatMessage);
  const { width } = useViewport();
  const isMobile = width < MOBILE_SCREEN_WIDTH;
  const currentTpvStatus = useMemo(() => {
    return getCurrentTpvStatus(versionDetails);
  }, [versionDetails]);

  const {
    data: tickets,
    loading,
    refetch
  } = useGetTicketId({
    variables: {
      params: {
        patientId,
        paginationInfo: {
          limit: 1,
          offset: 0,
          sortBy: 'createdAt',
          sortOrder: SortOrder.DESC
        },
        topicCode: [
          TOPIC_CODE.DISCUSSION_REQUIRED,
          TOPIC_CODE.REQUEST_NEW_VERSION,
          TOPIC_CODE.CASE_WITH_INT_DOC,
          TOPIC_CODE.CASE_OPS_ISSUE
        ],
        contextType: TOPIC_CONTEXT_TYPE.TPV,
        contextValueId: versionDetails?.id
      },
      loginedUserId: userDetails?.id
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (ticketsList?.[0]?.topicInfo?.topicCode === TREATMENT_PLAN_DISCUSSION) {
      refetch();
      refetchTreatmentPlans?.();
    }
  }, [ticketsList?.length]);

  const updateSidepanelPreference = (view: string, isOpen: boolean) => {
    if (patientId) {
      setSidepanelPreference({
        view,
        isOpen
      });
    }
  };

  const openChat = (ticketDetails: Ticket) => {
    setShowChat(true);
    if (!isMobile) {
      setWarningClosed(true);
      updateSidepanelPreference(PATIENT_CARD_SIDE_PANEL_CONFIG.INBOX.key, true);
    }
    const selectedTicketIndex = ticketsList.findIndex(
      (ticket) => ticket.id === ticketDetails.id
    );
    selectedTicketIndex !== -1
      ? setSelectedTicket(ticketsList[selectedTicketIndex])
      : setSelectedTicket(ticketDetails);
    setSelectedAccordion([PATIENT_INBOX_KEY.MY_CHAT]);
    isMobile && setShowMobileViewInbox(true);
  };
  const labelInfoConfig = [
    {
      key: 1,
      label: translate('date_created.date_created'),
      value: moment(versionDetails?.createdAt)?.format(DATE_FORMAT)
    },
    {
      key: 4,
      label: translate('remarks.remarks'),
      value: tickets?.getTickets?.tickets?.[0]?.id
        ? `Conversation #${tickets?.getTickets?.tickets?.[0]?.id}`
        : '',
      className: tickets?.getTickets?.tickets?.[0]?.id
        ? 'underline text-PRIMARY cursor-pointer'
        : '',
      onClick: () =>
        tickets?.getTickets?.tickets?.[0]?.id
          ? openChat(tickets.getTickets.tickets[0])
          : null
    },
    {
      key: 6,
      label: translate('status.status'),
      value: versionDetails?.acceptanceStatus && (
        <>
          <VersionStatusComponent status={versionDetails?.acceptanceStatus} />
          <If
            condition={
              !!versionDetails?.doctor3dViewerUrl &&
              !hideReOpenPlan &&
              currentTpvStatus === TREATMENT_PLAN_VERSION_STATUS.REJECTED &&
              treatmentPlan?.treatment?.stage ===
                STAGES.TREATMENT_PLAN_REVIEW &&
              versionDetails?.rejectionInfo?.rejectedBy !== INTERNAL_DOCTOR &&
              versionDetails?.rejectionInfo?.rejectedBy !== PATIENT &&
              versionDetails?.rejectionInfo?.rejectedBy !== null &&
              (treatmentPlan?.treatment?.status !==
                TREATMENT_STATUSES.PATIENT_ACCEPTED_PENDING_FITTING_APPOINTMENT ||
                treatmentPlan?.treatment?.status !==
                  TREATMENT_STATUSES.FITTING_APPOINTMENT_BOOKED)
            }
          >
            <div className='flex flex-col md:block xxs:hidden'>
              <CanAccess
                permission={PERMISSIONS.REOPEN_TPV}
                yes={
                  <ReOpenPLan
                    buttonType={KEEP_PLAN_OPENED}
                    planVersionId={versionDetails?.id}
                    planVersionName={versionDetails.name}
                    treatmentPlanName={treatmentPlan?.label}
                    treatmentPlanId={treatmentPlan?.id}
                    patientId={patientId || ''}
                    disabled={
                      treatmentPlan?.treatment?.stage ===
                      'status.treatment_lost'
                    }
                  />
                }
              />
            </div>
          </If>
        </>
      )
    }
  ];
  const isOpenPlanVisible = (status?: TREATMENT_PLAN_VERSION_STATUS) => {
    return (
      (status !== TREATMENT_PLAN_VERSION_STATUS.INT_DOC_TPV_CHECK &&
        !IN_PLANNING_STATUSES.includes(
          status as TREATMENT_PLAN_VERSION_STATUS
        )) ||
      (!checkIsExternal(userDetails.groups) &&
        status === TREATMENT_PLAN_VERSION_STATUS.CASE_WITH_INT_DOC)
    );
  };
  const renderOpenPlan = () => {
    return (
      <If condition={isOpenPlanVisible(currentTpvStatus)}>
        <div className='flex justify-end w-full'>
          <Button
            onClick={handleClick}
            className='text-sm rounded-32px border border-DEFAULT_TEXT py-1 px-4 md:mr-2'
          >
            {translate('chips_label.open_plan')}
          </Button>
        </div>
      </If>
    );
  };
  if (loading) return <Loader />;
  return (
    <div className='md:flex md:space-y-0  xxs:space-y-4 md:space-x-1 justify-between p-4 bg-WHITE rounded-lg flex flex-col'>
      <div className='w-full flex justify-between items-center pb-3 border-b-1 border-solid border-DEFAULT_TEXT'>
        <div className=' flex flex-col md:flex-row w-full'>
          <span className='block mr-2'>{`${versionDetails?.name}: `} </span>
          <span>
            {getAttatchmentIPRValues(
              versionDetails?.ipr,
              versionDetails?.attachments
            )}
          </span>
        </div>
        {renderOpenPlan()}
      </div>
      <div>
        {labelInfoConfig.map((tpvCardItem) => {
          if (
            tpvCardItem.label !== translate('remarks.remarks') ||
            tpvCardItem.value !== ''
          )
            return (
              <LabelInfo
                key={tpvCardItem.key}
                label={tpvCardItem.label}
                value={tpvCardItem.value}
                className={tpvCardItem.className}
                onClick={tpvCardItem.onClick}
              />
            );
        })}
      </div>
    </div>
  );
};
