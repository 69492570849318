import { useLazyQuery } from '@apollo/client';

import { TRANSLATE_TEXT } from 'services/query/translation.queries';
import { GetTranslation } from 'types/translation.types';

const useGetTranslation: GetTranslation = (config) => {
  return useLazyQuery(TRANSLATE_TEXT, {
    ...config
  });
};

export { useGetTranslation };
