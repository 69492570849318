import { useLazyQuery } from '@apollo/client';

import { GET_TREATING_PATIENTS } from 'services/query/patientslist';
import {
  ADD_USER,
  DELETE_USER,
  GET_COUNTRIES,
  GET_ROLES,
  GET_HEAD_CLINIC_BRANCHES,
  GET_LANGUAGES,
  GET_STAFFS,
  UPDATE_USER,
  UPDATE_USER_CLINICS,
  UPDATE_USER_GROUPS,
  GET_STAFF_BASIC,
  GET_STAFF_DETAILS
} from 'services/query/userslist';
import {
  GetPatientsListInput,
  GetTreatingPatientsList,
  TreatingPatientsList
} from 'types/autoSuggest.types';
import {
  GetUsersList,
  GetUserInputType,
  GetUsersReponse,
  UpdateUserStatus,
  AddUser,
  GetBranches,
  UpdateUser,
  UpdateUserGroups,
  UpdateUserClinics,
  GetCountries,
  GetLanguages,
  GetUsersListLazy,
  GetRoles,
  GetBasicStaffInfoResponse,
  GetBasicStaffInfoInput,
  GetBasicStaffInfo,
  GetStaffDetailsInfo,
  GetStaffDetailsInfoInput,
  GetStaffDetailsInfoResponse
} from 'types/UsersList';

import {
  UsegraphQlLazyQuery,
  UseGraphQlMutation,
  UseGraphQlMutationWithTpes,
  UseGraphQlQuery,
  UseGraphQlQueryWithTypes
} from './hooks';

export const useGetUsersList: GetUsersList = (config) => {
  return UseGraphQlQueryWithTypes<GetUsersReponse, GetUserInputType>(
    GET_STAFFS,
    config
  );
};

export const useBasicStaffInfo: GetBasicStaffInfo = (config) => {
  return UseGraphQlQueryWithTypes<
    GetBasicStaffInfoResponse,
    GetBasicStaffInfoInput
  >(GET_STAFF_BASIC, config);
};

export const useGetStaffDetails: GetStaffDetailsInfo = (config) => {
  return UseGraphQlQueryWithTypes<
    GetStaffDetailsInfoResponse,
    GetStaffDetailsInfoInput
  >(GET_STAFF_DETAILS, config);
};

export const useGetUsersListLazy: GetUsersListLazy = (config) => {
  return UsegraphQlLazyQuery<GetUsersReponse, GetUserInputType>(
    GET_STAFFS,
    config
  );
};

export const useUpdateUserStatus: UpdateUserStatus = (config) => {
  return UseGraphQlMutationWithTpes(DELETE_USER, config);
};

export const useAddUser: AddUser = (config) => {
  return UseGraphQlMutationWithTpes(ADD_USER, config);
};

export const useGetBranches: GetBranches = (config) => {
  return UseGraphQlQuery(GET_HEAD_CLINIC_BRANCHES, config);
};

export const useUpdateUser: UpdateUser = (config) => {
  return UseGraphQlMutation(UPDATE_USER, config);
};

export const useUpdateUserGroups: UpdateUserGroups = (config) => {
  return UseGraphQlMutation(UPDATE_USER_GROUPS, config);
};

export const useUpdateUserClinics: UpdateUserClinics = (config) => {
  return UseGraphQlMutation(UPDATE_USER_CLINICS, config);
};
export const useGetTreatingPatients: GetTreatingPatientsList = (config) => {
  return useLazyQuery<TreatingPatientsList, GetPatientsListInput>(
    GET_TREATING_PATIENTS,
    config
  );
};

export const useGetCountries: GetCountries = (config) => {
  return UseGraphQlQuery(GET_COUNTRIES, config);
};

export const useGetRoles: GetRoles = (config) => {
  return UseGraphQlQuery(GET_ROLES, config);
};

export const useGetLanguages: GetLanguages = (config) => {
  return UseGraphQlQuery(GET_LANGUAGES, config);
};
