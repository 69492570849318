import { FC, useEffect, useState } from 'react';

import OutsideClickHandler from 'react-outside-click-handler';
import { ArrowContainer, Popover, PopoverPosition } from 'react-tiny-popover';

import { customTwMerge } from 'utils';
import { useIsmobile } from 'utils/customHooks/useViewport';

export interface TooltipProps {
  messageText: string;
  children: JSX.Element;
  tooltipStyle?: string;
  disabled?: boolean;
  arrowColor?: string;
  arrowSize?: number;
  positions?: PopoverPosition[];
  arrowClassName?: string;
  containerClassName?: string;
  content?: JSX.Element;
  popOverClassName?: string;
  isAutoScrollEnabled?: boolean;
  autoScrollRef?: React.RefObject<HTMLDivElement>;
  scrollTo?: ScrollLogicalPosition;
}

const Tooltip: FC<TooltipProps> = ({
  messageText,
  children,
  tooltipStyle = '',
  disabled = false,
  arrowColor = '#ebebeb',
  arrowSize = 6,
  positions = ['bottom'],
  arrowClassName,
  containerClassName = '',
  content,
  popOverClassName,
  isAutoScrollEnabled,
  autoScrollRef,
  scrollTo
}) => {
  const [showToolTip, toggleTooltip] = useState<boolean>(false);
  const isMobile = useIsmobile();

  useEffect(() => {
    const handleScroll = () => {
      toggleTooltip(false);
    };
    if (!isAutoScrollEnabled && showToolTip)
      document.addEventListener('scroll', handleScroll, true);
    return () => {
      document.removeEventListener('scroll', handleScroll, true);
    };
  }, [showToolTip]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        toggleTooltip(false);
      }}
    >
      <Popover
        containerClassName={customTwMerge(
          'z-50 pl-10 md:pl-0',
          popOverClassName
        )}
        isOpen={!disabled && showToolTip}
        align='center'
        content={({ position, childRect, popoverRect }) => {
          const { width } = popoverRect || {};
          const isPopoverCreated = !!width;

          return (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={isPopoverCreated ? arrowColor : 'transparent'}
              arrowSize={arrowSize}
              arrowClassName={arrowClassName}
            >
              {!content ? (
                <div
                  className={
                    tooltipStyle ||
                    'px-3 py-2 text-10px rounded-8px bg-LIGHT_GRAY text-DISABLED z-50'
                  }
                >
                  {messageText}
                </div>
              ) : (
                <>{content}</>
              )}
            </ArrowContainer>
          );
        }}
        positions={positions}
        boundaryInset={40}
      >
        {isMobile ? (
          <button
            type='button'
            onClick={() => {
              toggleTooltip(!showToolTip);
              isAutoScrollEnabled &&
                autoScrollRef?.current?.scrollIntoView({
                  behavior: 'smooth',
                  block: scrollTo
                });
            }}
            className={containerClassName}
          >
            {children}
          </button>
        ) : (
          <div
            onPointerEnter={() => toggleTooltip(true)}
            onPointerLeave={() => toggleTooltip(false)}
            className={containerClassName}
          >
            {children}
          </div>
        )}
      </Popover>
    </OutsideClickHandler>
  );
};

export default Tooltip;
