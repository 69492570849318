import { createPlugin } from '@fullcalendar/react';

import { MonthViewMobile } from './MonthViewMobile';

const MonthViewMobileConfig = {
  component: MonthViewMobile,
  type: 'dayGridMonth'
};

export default createPlugin({
  views: {
    monthViewMobile: MonthViewMobileConfig
  }
});
