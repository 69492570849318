import { translate } from 'utils';

const LEAVE_TYPE = {
  ANNUAL: 'ANNUAL',
  PATERNITY: 'PATERNITY',
  MATERNITY: 'MATERNITY',
  SICK: 'SICK',
  EMERGENCY: 'EMERGENCY'
};

export const LEAVE_TYPE_LABEL: Record<string, string> = {
  ANNUAL: 'leave.annual_leave',
  PATERNITY: 'leave.paternity_leave',
  MATERNITY: 'leave.maternity_leave',
  SICK: 'leave.sick',
  EMERGENCY: 'leave.emergency'
};
const LEAVE_TYPE_OPTIONS = [
  {
    label: LEAVE_TYPE_LABEL.MATERNITY,
    value: LEAVE_TYPE.MATERNITY
  },
  {
    label: LEAVE_TYPE_LABEL.PATERNITY,
    value: LEAVE_TYPE.PATERNITY
  },
  {
    label: LEAVE_TYPE_LABEL.ANNUAL,
    value: LEAVE_TYPE.ANNUAL
  },
  {
    label: LEAVE_TYPE_LABEL.SICK,
    value: LEAVE_TYPE.SICK
  },
  {
    label: LEAVE_TYPE_LABEL.EMERGENCY,
    value: LEAVE_TYPE.EMERGENCY
  }
];

const USER_ROLE_GROUP = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL'
};

const INT_EXT_FILTER_OPTIONS = [
  {
    label: translate('settings_user.internal_users'),
    id: USER_ROLE_GROUP.INTERNAL
  },
  {
    label: translate('settings_user.external_users'),
    id: USER_ROLE_GROUP.EXTERNAL
  }
];

export { LEAVE_TYPE_OPTIONS, INT_EXT_FILTER_OPTIONS, USER_ROLE_GROUP };
