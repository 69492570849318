import {
  FC,
  useEffect,
  useState,
  useMemo,
  useRef,
  MutableRefObject,
  useCallback
} from 'react';

import { useApolloClient } from '@apollo/client';
import cx from 'classnames';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { CloseIcon } from 'assets/icons';
import chatClient from 'chatSDK';
import {
  AddMeToChat,
  ChatMessage,
  Loader,
  NoDataMessage,
  TicketCard,
  InboxDrawer
} from 'components';
import { If } from 'components/Generics';
import InfiniteScroll from 'components/InfiniteScroll/InfiniteScroll';
import TicketCardListSkeleton from 'components/TicketCardList/Skelton/TicketCardListSkeleton';
import { INBOX_CONTEXT_TYPE, INBOX_NOTIFICATION } from 'constants/inbox';
import { CHAT_TRACK_EVENTS } from 'constants/segment';
import {
  useSegmentTrackChat,
  useTrackData
} from 'services/hooks/Segment.hooks';
import { GET_TICKET_BY_ID, GET_TICKET_LIST } from 'services/query/inbox';
import {
  loginedUserDetails,
  selectedTicket,
  showChatMessage,
  showAddMe as showAddMeState,
  ticketList
} from 'store/atoms';
import { defaultSelectedTicket } from 'store/atoms/constants';
import { inboxFilters } from 'store/atoms/inbox';
import { useIsExternalUser } from 'store/selectors/auth';
import { GroupStatusNotification } from 'types/Inbox.types';
import {
  GetTicketByIdInput,
  GetTicketsListInput,
  Ticket,
  TicketById,
  TicketData,
  TicketDetailsCard,
  TicketTopicContextInfo
} from 'types/Tickets.types';
import { isDataExist, translate } from 'utils';
import { formatMessage } from 'utils/common';
import { getTicketList } from 'utils/customHooks/inbox';
import { showToast } from 'utils/toast/toast.util';

interface TicketListViewProps {
  patientId?: string;
  ticketScrollRef: MutableRefObject<HTMLDivElement | null>;
}

const TicketListView: FC<TicketListViewProps> = ({
  ticketScrollRef,
  patientId
}) => {
  const chat = chatClient.chatClient;
  const client = useApolloClient();

  /**
   * component Local state
   */
  const [showLoader, setShowLoader] = useState(false);
  /**
   * Component local reference
   */
  const removeGroupStatusListener = useRef<any>(null);
  const ticketListRef = useRef<Ticket[]>([]);
  const selectedTicketRef = useRef<Ticket>();
  const showAddMeRef = useRef<boolean>();
  const showChatRef = useRef<boolean>();
  const ticketPaginationInfo = useRef({
    offset: 0,
    previousFetchDirection: '',
    previousScrollHeight: 0,
    totalCount: 0
  });
  /**
   * Recoil state
   */
  const inboxFilter = useRecoilValue(inboxFilters);
  const isExternalUser = useRecoilValue(useIsExternalUser);
  const [showChat, setShowChat] = useRecoilState(showChatMessage);
  const [showAddMe, setShowAddMe] = useRecoilState(showAddMeState);
  const [selectedTicketInfo, setSelectedTicket] =
    useRecoilState(selectedTicket);
  const [ticketsList, setTicketsList] = useRecoilState(ticketList);
  const userDetails = useRecoilValue(loginedUserDetails);
  /**
   * @description
   * reset recoil state
   */
  const resetSelectedTicket = useResetRecoilState(selectedTicket);
  const resetTicketList = useResetRecoilState(ticketList);
  const handleSegmentTrack = useSegmentTrackChat();
  const { trackData, trackContextData } = useTrackData();

  /**
   * @description
   * Recoil value can't be use inside callback so it is added a ref
   */
  ticketListRef.current = ticketsList;
  selectedTicketRef.current = selectedTicketInfo;
  showAddMeRef.current = showAddMe;
  showChatRef.current = showChat;

  const getTicketsList = async (variables: GetTicketsListInput) => {
    if (isExternalUser) {
      variables.params.isExternalUserView = true;
    }
    return client.query<TicketData, GetTicketsListInput>({
      query: GET_TICKET_LIST,
      variables,
      fetchPolicy: 'no-cache'
    });
  };

  const getTicketById = (id: number) => {
    const variables: GetTicketByIdInput = {
      ticketId: id,
      viewTicketsWithoutMe: true,
      patientId,
      loginedUserId: userDetails.id
    };
    if (isExternalUser) {
      variables.isExternalUserView = true;
    }
    return client.query<TicketById, GetTicketByIdInput>({
      query: GET_TICKET_BY_ID,
      variables,
      fetchPolicy: 'no-cache'
    });
  };

  /**
   * @description
   * Hook to remove notification listener
   */
  useEffect(() => {
    return () => {
      if (removeGroupStatusListener.current) {
        removeGroupStatusListener.current();
      }
      resetTicketList();
    };
  }, []);

  useEffect(() => {
    removeGroupStatusListener.current = chat.addGroupStatusListener(
      handleGroupStatusListenerSuccess
    );
  }, [chat, userDetails]);

  const updateSelectedTicketDetails = (id: number) => {
    getTicketById(id).then(({ data }) => {
      if (data.getTicketById.id) {
        setSelectedTicket(data.getTicketById);
      }
    });
  };
  const getTopicPatientId = (contextInfo: TicketTopicContextInfo[]) => {
    return contextInfo.find(
      (context) => context.contextType === INBOX_CONTEXT_TYPE.PATIENT
    )?.contextValueId;
  };
  useEffect(() => {
    if (selectedTicketInfo.id !== defaultSelectedTicket.id) {
      handleSegmentTrack(CHAT_TRACK_EVENTS.CHAT_OPEN, {
        ...trackData,
        ...trackContextData,
        is_participant: showChat ? 'Yes' : 'No'
      });
    }
  }, [selectedTicketInfo?.id]);

  const handleGroupStatusListenerSuccess = (updates: any) => {
    if (isDataExist(updates)) {
      updates.forEach((notification: GroupStatusNotification) => {
        const tickets = [...ticketListRef.current];
        switch (notification.message_category) {
          case INBOX_NOTIFICATION.GROUP_USERS_REMOVED: {
            if (userDetails.id === notification?.user_list?.[0]) {
              getTicketById(Number(notification.group_details.entity_id)).then(
                ({ data }) => {
                  if (
                    data.getTicketById.id &&
                    patientId ===
                      getTopicPatientId(
                        data.getTicketById.ticketTopicContextInfo
                      )
                  ) {
                    setTicketsList((oldList) => [
                      data.getTicketById,
                      ...oldList
                    ]);
                  }
                }
              );
              if (
                selectedTicketRef.current?.id ===
                  Number(notification.group_details.entity_id) &&
                showChatRef.current
              ) {
                resetSelectedTicket();
                setShowChat(false);
              }
            }
            break;
          }
          case INBOX_NOTIFICATION.GROUP_USERS_ADDED: {
            const updatedTicketIndex = tickets.findIndex(
              (ticket) =>
                ticket.id === Number(notification.group_details.entity_id)
            );

            if (updatedTicketIndex !== -1) {
              tickets.splice(updatedTicketIndex, 1);
            }
            setTicketsList(tickets);
            if (
              selectedTicketRef.current?.id ===
                Number(notification.group_details.entity_id) &&
              showAddMeRef.current
            ) {
              resetSelectedTicket();
              setShowAddMe(false);
            }
            break;
          }
        }
      });
    }
  };

  const getTickets = useCallback(() => {
    setShowLoader(true);
    const variables: GetTicketsListInput = {
      params: {
        ...inboxFilter,
        patientId,
        viewTicketsWithoutMe: true,
        paginationInfo: { limit: 10, offset: 0 }
      },
      loginedUserId: userDetails.id
    };
    getTicketsList(variables)
      .then((data) => {
        setShowLoader(false);
        setTicketsList([...data.data.getTickets.tickets]);
        ticketPaginationInfo.current.offset =
          data.data.getTickets.paginationInfo.offset;
        ticketPaginationInfo.current.totalCount =
          data.data.getTickets.totalCount;
      })
      .catch(() => {
        showToast(translate('ERROR.DEFAULT_ERROR'), false);
      });
  }, [inboxFilter]);

  useEffect(() => {
    getTickets();
  }, [inboxFilter]);

  const ticketData = useMemo(() => {
    if (isDataExist(ticketsList)) {
      return getTicketList(ticketsList);
    }
  }, [ticketsList]);

  const handleTicketSelect = (ticketDetails: TicketDetailsCard) => {
    setSelectedTicket(ticketDetails.ticketDetails);
    setShowChat(false);
    setShowAddMe(true);
  };

  const update = (isBottomUpdate: boolean, previousScrollHeight: number) => {
    ticketPaginationInfo.current.previousFetchDirection = isBottomUpdate
      ? 'bottom'
      : 'top';
    ticketPaginationInfo.current.previousScrollHeight = previousScrollHeight;

    let offset = ticketPaginationInfo.current.offset;
    let limit = 10;
    if (isBottomUpdate) {
      offset += ticketsList.length;
    } else {
      // scroll to top
      if (offset < limit) {
        limit = offset;
        offset = 0;
      } else {
        offset -= limit;
      }
      ticketPaginationInfo.current.offset = offset;
    }
    // don't pass ticketId in update
    const variables: GetTicketsListInput = {
      params: {
        ...inboxFilter,
        viewTicketsWithoutMe: true,
        patientId,
        paginationInfo: {
          offset,
          limit
        }
      },
      loginedUserId: userDetails.id
    };
    getTicketsList(variables)
      .then(({ data }) => {
        if (isDataExist(data.getTickets.tickets)) {
          setTicketsList((prevList) => [
            ...prevList,
            ...data.getTickets.tickets
          ]);
        }
      })
      .catch(() => {
        showToast(translate('ERROR.DEFAULT_ERROR'), false);
      });
  };

  const hasMore = {
    top: ticketPaginationInfo.current.offset > 0,
    bottom:
      ticketPaginationInfo.current.offset + ticketsList.length <
      ticketPaginationInfo.current.totalCount
  };

  return (
    <InfiniteScroll
      dataLength={ticketData?.length || 0}
      next={update}
      hasMore={hasMore}
      scrollableTarget={ticketScrollRef}
      loader={<Loader />}
    >
      <TicketCardListSkeleton loading={showLoader}>
        <div className={cx('w-full py-2 ')}>
          {isDataExist(ticketData) ? (
            ticketData?.map((ticketDetails, index) => {
              return (
                <div key={`${ticketDetails.id}_${index}`} className='w-full'>
                  <TicketCard
                    chatId={ticketDetails.id}
                    data={ticketDetails.metaData}
                    showMentionIcon={
                      !!ticketDetails.metaData.mentionedMessageId &&
                      !ticketDetails.metaData.isMentionedMessageRead
                    }
                    updatedAt={ticketDetails.metaData?.updatedAt}
                    notificationCount={0}
                    unread={true}
                    selected={false}
                    status={ticketDetails.status}
                    onSelect={() => handleTicketSelect(ticketDetails)}
                    message={formatMessage(
                      ticketDetails.metaData.searchedMessageDetails.message,
                      inboxFilter.searchTerm
                    )}
                    isFromPatientDetails={true}
                  ></TicketCard>
                </div>
              );
            })
          ) : (
            <div className='md:w-85 mb-4'>
              <NoDataMessage
                message={
                  inboxFilter.searchTerm
                    ? translate('referrals.no_result_found_for_“X”', {
                        X: inboxFilter.searchTerm
                      })
                    : translate('Messages.noPending')
                }
              />
            </div>
          )}
        </div>
      </TicketCardListSkeleton>
      <If condition={!!selectedTicketInfo.id && showAddMe}>
        <InboxDrawer>
          <AddMeToChat
            selectedTicketDetails={selectedTicketInfo}
            onClose={() => setShowAddMe(false)}
            reFetchTicketList={getTickets}
            updateSelectedTicketDetails={updateSelectedTicketDetails}
          />
        </InboxDrawer>
      </If>
      <If condition={showChat && !!selectedTicketInfo.id}>
        <InboxDrawer>
          <div className='relative patient-details-inbox h-full'>
            <button
              className='absolute cursor-pointer pr-1.5 right-1 top-1'
              onClick={() => {
                setShowChat(false);
              }}
            >
              <CloseIcon className='h-3 w-3' />
            </button>
            <ChatMessage
              handleChatClose={() => {
                setShowChat(false);
              }}
              isFromPatientDetails={true}
              setShowSidePanel={() => false}
            />
          </div>
        </InboxDrawer>
      </If>
    </InfiniteScroll>
  );
};

export default TicketListView;
