import { FC } from 'react';

import Files from 'components/Files/Files';
import { If } from 'components/Generics';
import { FilesData } from 'types/Files.types';

type PrescriptionFilesProps = {
  label: string;
  files: FilesData[] | undefined;
  setSelectedPhoto?: React.Dispatch<React.SetStateAction<string | number>>;
  toggleZoomPhoto?: React.Dispatch<React.SetStateAction<boolean>>;
};

const PrescriptionFiles: FC<PrescriptionFilesProps> = ({
  label,
  files = [],
  setSelectedPhoto,
  toggleZoomPhoto
}) => {
  return (
    <If condition={!!files.length}>
      <div>
        <h5 className='text-GRAY3 md:text-sm mb-2 font-normal'>{label}</h5>
        <div>
          <Files
            files={files}
            className='flex-shrink-0'
            viewOnly={true}
            setSelectedPhoto={setSelectedPhoto}
            toggleZoomPhoto={toggleZoomPhoto}
            width='w-40 md:w-44.5'
          />
        </div>
      </div>
    </If>
  );
};
export default PrescriptionFiles;
