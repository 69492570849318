import { FC, ReactNode } from 'react';

import cx from 'classnames';
import classNames from 'classnames';

import { CollapseIcon, ExpandIcon } from 'assets/icons';
import AnimateLabel from 'components/AnimateLabel/AnimateLabel';
import Avatar from 'components/Avatar/Avatar';
import If from 'components/Generics/If/If';
import { InfoMessage } from 'components/InfoMessage/InfoMessage';
import { useFieldRefContext } from 'contexts/filesRefs.context';
import { customTwMerge as twMerge } from 'utils';

type CollapsibleMenuProps = {
  showLink?: boolean;
  title: JSX.Element | string;
  className?: string;
  icon?: string;
  preventExpand?: boolean;
  showErrorTitle?: boolean;
  classNameTitle?: string;
  classNameToggleIcon?: string;
  showProfilePic?: boolean;
  toggleExpand: (val: boolean) => void;
  isExpanded?: boolean;
  animateError?: boolean;
  titleFont?: string;
  isDisabled?: boolean;
  children: ReactNode;
  isPrescription?: boolean;
  status?: string;
  infoText?: string;
  linkTitle?: string;
  link?: string;
  leadingIcon?: ReactNode;
  leadingIconClassName?: string;
  buttonClassName?: string;
  childrenClassName?: string;
  renderInfoMessage?: ReactNode;
};

const CollapsibleMenu: FC<CollapsibleMenuProps> = ({
  showLink = false,
  title,
  children,
  className = '',
  icon,
  classNameToggleIcon,
  preventExpand = false,
  showErrorTitle = false,
  classNameTitle = '',
  showProfilePic = false,
  toggleExpand,
  isExpanded = false,
  animateError = false,
  titleFont = 'text-base',
  isDisabled = false,
  isPrescription = false,
  infoText = '',
  link,
  linkTitle,
  status,
  leadingIcon,
  leadingIconClassName,
  buttonClassName,
  childrenClassName = '',
  renderInfoMessage
}) => {
  const { animatedField, callback } = useFieldRefContext();
  const isLinkVisible = !!link && showLink && status !== 'Completed';
  const getLeadingElement = () => {
    if (leadingIcon)
      return <div className={leadingIconClassName}>{leadingIcon}</div>;
    else if (showProfilePic)
      return (
        <div className='w-9 h-9  xxs:p-1 '>
          <Avatar image={icon} className='' />
        </div>
      );
  };
  return (
    <div
      className={twMerge(
        classNames('pb-6 w-full', {
          'border-b-1 border-solid border-LIGHT_GRAY_2': !isPrescription
        }),
        className
      )}
      ref={animateError ? callback?.('priorTreatment.treatments') : () => null}
    >
      <button
        type='button'
        disabled={isDisabled}
        className={twMerge(
          classNames('flex justify-between items-center w-full', {
            'cursor-pointer': !preventExpand,
            'cursor-not-allowed': preventExpand,
            'bg-GRAY_6 p-5 rounded-lg': isPrescription
          }),
          buttonClassName
        )}
        onClick={() => !preventExpand && toggleExpand(!isExpanded)}
      >
        <div
          className={classNames('flex', {
            'items-center space-x-4': leadingIcon
          })}
        >
          {getLeadingElement()}
          <div className='text-start'>
            <h3
              className={twMerge(
                `${
                  showErrorTitle ? 'text-ERROR' : 'text-DEFAULT_TEXT'
                } ${titleFont} ${icon ? 'pt-1' : ''} relative`,
                classNameTitle
              )}
            >
              <span className={isDisabled ? 'text-CHINESE_WHITE' : ''}>
                {title}
              </span>
              <AnimateLabel
                className='text-ERROR text-collapsible font-normal'
                animate={
                  showErrorTitle &&
                  animateError &&
                  animatedField === 'priorTreatment.treatments'
                }
              >
                {title}
              </AnimateLabel>
            </h3>
          </div>
        </div>

        <div className='flex space-x-4 items-center'>
          {renderInfoMessage ? (
            renderInfoMessage
          ) : (
            <InfoMessage
              message={infoText}
              status={status}
              isDisabled={isDisabled}
              link={link || ''}
              linkTitle={linkTitle}
              isLinkVisible={isLinkVisible}
            />
          )}

          <div>
            {!isExpanded ? (
              <ExpandIcon
                data-testid='expandIcon'
                className={twMerge(
                  cx(
                    'xxs:w-3 xxs:h-3 md:h-5 md:w-5',
                    {
                      'text-CHINESE_WHITE': isDisabled
                    },
                    { 'text-DEFAULT_TEXT': !isDisabled }
                  ),
                  classNameToggleIcon
                )}
              />
            ) : (
              <CollapseIcon
                data-testid='collapseIcon'
                className={twMerge(
                  cx(
                    'xxs:w-3 xxs:h-3 md:h-5 md:w-5',
                    {
                      'text-CHINESE_WHITE': isDisabled
                    },
                    { 'text-DEFAULT_TEXT': !isDisabled }
                  ),
                  classNameToggleIcon
                )}
              />
            )}
          </div>
        </div>
      </button>
      <If condition={isExpanded && !isDisabled}>
        <div className={twMerge('md:mt-7 mt-6', childrenClassName)}>
          {children}
        </div>
      </If>
    </div>
  );
};
export default CollapsibleMenu;
