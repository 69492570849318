/* eslint-disable react/no-unused-prop-types */
import { FC, useMemo } from 'react';

import { ViewProps, Duration } from '@fullcalendar/common';
import { sliceEvents } from '@fullcalendar/react';
import { groupBy } from 'lodash';
import moment from 'moment';

import { EventGroup } from 'components/EventGroup/EventGroup';
import { DATE_FORMAT } from 'constants/settings';
import { formatEventRenderRangeArray } from 'utils/common';

export const ScheduleView: FC<ViewProps & { nextDayThreshold: Duration }> = (
  props
) => {
  const events = useMemo(
    () =>
      sliceEvents(
        {
          ...props,
          dateProfile: {
            ...props?.dateProfile,
            activeRange: { ...props?.dateProfile?.currentRange }
          }
        },
        true
      ),
    [props]
  );

  const formatEvents = useMemo(() => {
    return formatEventRenderRangeArray(events);
  }, [events]);

  const formatAppointments = useMemo(() => {
    const formattedEvents = formatEvents;
    return groupBy(formattedEvents, (event) =>
      moment(event?.start).format(DATE_FORMAT)
    );
  }, [formatEvents]);

  const formattedAppointments = formatAppointments;

  return (
    <div className='h-full space-y-4 pt-4'>
      {Object.keys(formattedAppointments)?.map((date) => (
        <EventGroup
          key={date}
          showNowIndicator={true}
          events={formattedAppointments[date]}
        />
      ))}
    </div>
  );
};
