import { FC, Fragment, useEffect } from 'react';

import cx from 'classnames';
import {
  useTable,
  Column,
  useSortBy,
  SortingRule,
  useExpanded
} from 'react-table';
import { twMerge } from 'tailwind-merge';

import { NoDataMessage } from 'components';
import { Divider } from 'components/Divider/Divider';
import { isDataExist } from 'utils';

import { SortArrow } from './components';

interface Props {
  columns: Array<Column>;
  data: Array<any>;
  onSort?: (sortOptions: Array<SortingRule<Column>>) => void;
  noDataMessage?: string;
  onTableRowClick?: (data: any, isExpanded: boolean) => void;
  tableClassName?: string;
  bodyClassName?: string;
  tableDataClassName?: string;
  isCustom?: boolean;
  renderRowSubComponent?: any;
  headerClassName?: string;
  stickyHeader?: boolean;
  hiddenColumns?: string[];
  showRowPointer?: boolean;
  noDataStyle?: string;
  sortByColumns?: SortingRule<object>[];
}

const getTableDataStyle = (tableDataClassName?: string, isCustom?: boolean) => {
  return tableDataClassName
    ? tableDataClassName
    : `${!isCustom && 'box-borders px-2'} text-sm`;
};

const Table: FC<Props> = ({
  data,
  onSort,
  columns,
  isCustom,
  noDataMessage,
  bodyClassName,
  tableClassName,
  onTableRowClick,
  tableDataClassName,
  renderRowSubComponent,
  headerClassName = 'font-bold',
  stickyHeader = true,
  hiddenColumns = [],
  showRowPointer = true,
  noDataStyle = '',
  sortByColumns = []
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
    visibleColumns
  } = useTable(
    {
      columns,
      data,
      manualSortBy: true,
      initialState: {
        hiddenColumns,
        sortBy: sortByColumns
      }
    },
    useSortBy,
    useExpanded
  );
  /**
   * @description
   * Hook to impliment sort functinality when data changes it will call function for sort
   */
  useEffect(() => {
    if (onSort) {
      onSort(sortBy);
    }
  }, [onSort, sortBy]);

  return (
    <table
      {...getTableProps()}
      className={`w-full h-10 relative overflow-visible ${tableClassName}`}
      data-testid='table'
    >
      <thead data-testid='thead'>
        {headerGroups.map((headerGroup, index: number) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={`head-${index}`}>
            {headerGroup.headers.map((column, index: number) =>
              !isCustom ? (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={`head-cell-${index}`}
                  id={`${column.render('Header')}`}
                  className={cx(
                    `${column.className} ${headerClassName}  bg-GRAY py-4 h-14 text-sm text-left px-2 z-1 `,
                    { 'sticky top-0': stickyHeader }
                  )}
                >
                  <div className='flex gap-x-2'>
                    {column.render('Header')}
                    {column.canSort && (
                      <SortArrow
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                      />
                    )}
                  </div>
                </th>
              ) : (
                <th
                  {...column.getHeaderProps()}
                  key={`head-cell-${index}`}
                  className={`bg-LIGHT_BLUE_5 font-normal pt-2 h-6 text-sm text-left   sticky top-0 `}
                >
                  {column.render('Header')}
                </th>
              )
            )}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {isDataExist(rows) ? (
          rows.map((row, index: number) => {
            prepareRow(row);
            return (
              <Fragment key={row.id}>
                <tr
                  {...row.getRowProps()}
                  key={`data-${index}`}
                  data-testid='patient-list-rows'
                  className={`${
                    !bodyClassName
                      ? cx('h-12 hover:bg-HOVER_BLUE', {
                          'bg-GRAY': index % 2 !== 0,
                          'bg-LIGHT_BLUE_5': row.isExpanded,
                          'cursor-pointer': showRowPointer
                        })
                      : bodyClassName
                  }`}
                  onClick={() => {
                    onTableRowClick?.(row.original, !row.isExpanded);
                    renderRowSubComponent && row.toggleRowExpanded();
                  }}
                >
                  {row.cells.map((cell, index: number) => {
                    return (
                      <Fragment key={`data-cell-${index}`}>
                        <td
                          {...cell.getCellProps()}
                          className={getTableDataStyle(
                            tableDataClassName,
                            isCustom
                          )}
                        >
                          {cell.render('Cell')}
                        </td>
                      </Fragment>
                    );
                  })}
                </tr>
                {row.isExpanded && (
                  <tr className='bg-LIGHT_BLUE_5'>
                    <td colSpan={visibleColumns.length}>
                      <div className='pl-12 pr-4'>
                        <Divider />
                      </div>
                      {renderRowSubComponent(row.original)}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })
        ) : (
          <tr>
            <td
              colSpan={columns?.length}
              className={twMerge('py-4 pt-40 ', noDataStyle)}
            >
              <NoDataMessage message={noDataMessage} />
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default Table;
