import { FC, useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import { Button, Modal } from 'components';
import { pagePaths } from 'config/pages';
import { showUnauthChatError } from 'store/atoms';
import { translate, useLogout } from 'utils';

const UnauthorizedError: FC = () => {
  const [isOpen, setIsOpen] = useRecoilState(showUnauthChatError);
  const logout = useLogout();
  const location = useLocation();

  const onClose = () => {
    setIsOpen(false);
  };

  const handleLogout = () => {
    onClose();
    logout();
  };

  useEffect(() => {
    if (location.pathname.includes(pagePaths.logout)) {
      logout();
    }
  }, [location.pathname]);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className='w-full h-full'>
        <p className='m-8'>
          Something went wrong, please log out and try again.
        </p>
        <div className='flex  justify-end m-4'>
          <Button
            className='bg-PRIMARY px-8 text-WHITE '
            type='button'
            onClick={handleLogout}
          >
            {translate('actions.logout')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UnauthorizedError;
