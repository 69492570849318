import { FC } from 'react';

import parse from 'html-react-parser';

import { TIME_FORMAT_12 } from 'constants/settings';
import { Block, RichTextInput } from 'types/Inbox.types';
import { getRoleName, translate } from 'utils';
import { formatDate } from 'utils/dateUtils';

type Message = {
  createdAt: Date;
};

interface Props {
  richText: RichTextInput;
  isExternal: boolean;
  message: Message;
}
const GroupStatusUpdateMessageBlock: FC<Props> = ({
  richText,
  isExternal,
  message
}) => {
  const formatMessage = (block: Block, key: number) => {
    const name = isExternal
      ? getRoleName(richText.rich_text_details[0].details.team)
      : richText.rich_text_details[0].details.name;

    const text = translate(block.text, {
      X: name
    });

    return parse(
      `<p key=${key} className="table-cell text-center">${text} <span className="text-DISABLED_2">&nbsp;&nbsp;${formatDate(
        message?.createdAt,
        TIME_FORMAT_12
      )}<span></p>`
    );
  };

  return (
    <div className='flex items-center justify-center text-xs md:text-sm break-words bg-GRAY5 text-DISABLED_2 rounded-md px-3 py-1 mx-10 md:mx-24'>
      {richText.blocks.map((block, index) => formatMessage(block, index))}
    </div>
  );
};

export default GroupStatusUpdateMessageBlock;
