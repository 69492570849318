import { gql } from '@apollo/client';

export const GENERATE_RESET_LINK = gql`
  mutation generatePasswordResetLink($email: String!, $locale: String) {
    generatePasswordResetLink(input: { email: $email, locale: $locale })
  }
`;
export const CHECK_IF_EXISTING_ACCOUNT = gql`
  mutation setPassword($email: String!) {
    setPassword(email: $email)
  }
`;
