import { FC } from 'react';

import cx from 'classnames';
import moment from 'moment';

import { SnoozeIcon } from 'assets/icons';
import ChatHeaderTooltip from 'components/ChatHeader/ChatHeaderTooltip';
import { If } from 'components/Generics';
import { translate } from 'utils';

interface SnoozeButton {
  isSnoozed: boolean;
  onSnooze: () => void;
  onUnsnooze: () => void;
  snoozeUntill?: Date;
  snoozeType?: string;
  disabled?: boolean;
}

const REASON_OPTIONS: Record<string, string> = {
  PENDING_ZENYUM: 'snooze.reason.pending_zenyum',
  PENDING_TREATING_DOCTOR: 'snooze.reason.pending_treating_doctor',
  PENDING_PATIENT: 'snooze.reason.pending_patient'
};

const SnoozeButton: FC<SnoozeButton> = ({
  isSnoozed,
  onSnooze,
  onUnsnooze,
  snoozeUntill,
  snoozeType = '',
  disabled
}) => {
  const handleClick = isSnoozed ? onUnsnooze : onSnooze;
  const formattedExpiry = isSnoozed
    ? moment(snoozeUntill)?.format('DD MMM')
    : '';

  const getTooltipContent = () => {
    if (!isSnoozed) return translate('snooze.button.tooltip');

    let message = '';
    if (formattedExpiry) {
      message = message.concat(
        `${translate('snooze.snoozed_until')} ${formattedExpiry}`
      );
    }

    if (REASON_OPTIONS[snoozeType]) {
      message = message.concat(
        ' ',
        translate('snooze.button.reason', {
          reason: translate(REASON_OPTIONS[snoozeType])
        })
      );
    }
    return message;
  };

  return (
    <ChatHeaderTooltip messageText={getTooltipContent()}>
      <div className='flex items-center'>
        <If condition={isSnoozed}>
          <p className='text-COOL_GREY mr-2.5 text-sm'></p>
        </If>
        <button
          onClick={handleClick}
          disabled={disabled}
          data-testid='snooze-btn'
        >
          <SnoozeIcon
            className={cx('w-4 h-4', {
              'text-PRIMARY': isSnoozed
            })}
          />
        </button>
      </div>
    </ChatHeaderTooltip>
  );
};

export default SnoozeButton;
