import { useMemo } from 'react';

import { ROLES_TRANSLATION_MAPPER } from 'constants/translationMaps';
import { useGetRoles } from 'services/hooks/UsersList.hooks';
import { Option } from 'types/select';

import { translate } from './locale';

const filterOption = (options: Option[], values: string[] = []) => {
  return options.filter(({ value }) => values.some((group) => group === value));
};

const formatOptions = (option: Option[]) =>
  option.map(({ label }) => label).join(', ');

export const useRoleOption = (
  userRoleGroup: string,
  currentGroups?: string[]
) => {
  const { data } = useGetRoles({
    variables: {
      type: userRoleGroup
    }
  });

  const allRoleOption = useMemo(() => {
    return (
      data?.getSupportedRoles?.map(({ value }) => ({
        value,
        label: translate(ROLES_TRANSLATION_MAPPER[value])
      })) || []
    );
  }, [data]);

  const userRoleOptions = useMemo(
    () => filterOption(allRoleOption, currentGroups),
    [currentGroups, allRoleOption]
  );

  const formattedCurrentRole = formatOptions(userRoleOptions);

  const getFormattedRole = (currentGroups: string[]) => {
    const currentRoleOptions = filterOption(allRoleOption, currentGroups);
    return formatOptions(currentRoleOptions);
  };

  const getSelectedRoleOptions = (currentGroups: string[]) => {
    return filterOption(allRoleOption, currentGroups);
  };

  return {
    allRoleOption,
    userRoleOptions,
    getFormattedRole,
    getSelectedRoleOptions,
    formattedCurrentRole
  };
};
