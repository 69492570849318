import React, { FC, useState } from 'react';

import moment from 'moment';

import { SearchIcon, SnoozeIcon } from 'assets/icons';
import { TIME_FORMAT } from 'constants/settings';
import { translate } from 'utils';

import DropdownOption from './DropdownOption';

const formatOptionDate = (dateTime: moment.Moment) =>
  dateTime.format(TIME_FORMAT);

const setTimeTo9AM = (dateTime: moment.Moment) =>
  dateTime.set({ hour: 9, minute: 0, second: 0, millisecond: 0 });

const getDefaultOptions = () => {
  const laterToday = moment().add(3, 'hours').startOf('hour');
  const tomorrow = moment().add(1, 'day');
  const dayAfterTomorrow = moment().add(2, 'day');
  const week = moment().add(1, 'week');

  return [
    {
      label: translate('snooze.later_today'),
      timeText: formatOptionDate(laterToday),
      value: laterToday
    },
    {
      label: translate('snooze.tomorrow'),
      timeText: '09:00 AM',
      value: setTimeTo9AM(tomorrow)
    },
    {
      label: translate('snooze.day_after_tomorrow'),
      timeText: '09:00 AM',
      value: setTimeTo9AM(dayAfterTomorrow)
    },
    {
      label: translate('snooze.one_week'),
      timeText: '09:00 AM',
      value: setTimeTo9AM(week)
    }
  ];
};

interface SearchTimeOption {
  unit: moment.unitOfTime.DurationConstructor;
  format: string;
}

const SEARCH_TIME_OPTIONS: SearchTimeOption[] = [
  {
    unit: 'hour',
    format: TIME_FORMAT
  },
  {
    unit: 'day',
    format: 'ddd hh:mm A'
  },
  {
    unit: 'week',
    format: 'ddd, MMM D, hh:mm A'
  }
];

interface Props {
  onCustomClick: () => void;
  onTimeConfirm: (time: moment.Moment) => void;
}
const SnoozeSearch: FC<Props> = ({ onCustomClick, onTimeConfirm }) => {
  const [inputText, setInputText] = useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (Number(value) < 1000) setInputText(event.target.value);
  };

  const getUnitTranslation = (
    unit: moment.unitOfTime.DurationConstructor,
    isSingular: boolean
  ) => {
    const mapKey = isSingular ? unit : `${unit}s`;
    const translationMapper: Record<string, string> = {
      hour: 'common.hour',
      hours: 'common.hours',
      day: 'common.day',
      days: 'common.days',
      week: 'common.week',
      weeks: 'common.weeks'
    };
    return translate(translationMapper[mapKey]);
  };

  const getTimeOptionFromUnit = (
    unit: moment.unitOfTime.DurationConstructor,
    searchValue: number,
    format: string
  ) => {
    const isSingular = searchValue === 1;
    const unitTranslation = getUnitTranslation(unit, isSingular);
    const time = moment().add(searchValue, unit).startOf('hour');
    return {
      label: `for ${searchValue} ${unitTranslation}`,
      timeText: time.format(format),
      value: time
    };
  };

  const getTimeOptions = () => {
    if (!inputText) return getDefaultOptions();
    const searchValue = Number(inputText);
    if (searchValue === 0) return [];

    return SEARCH_TIME_OPTIONS.map(({ unit, format }) =>
      getTimeOptionFromUnit(unit, searchValue, format)
    );
  };

  const isShowingDefaultOption = !inputText;
  return (
    <div className='w-[40rem]'>
      <div className='flex w-full gap-x-4 items-center'>
        <SnoozeIcon />
        <div className='border-1 border-DISABLED_2 rounded-full px-6 py-1 flex-1 flex items-center'>
          <input
            className='h-8 flex-1 placeholder:text-DISABLED_2 text-xs'
            placeholder={translate('snooze.search_placeholder')}
            onChange={handleChange}
            type='number'
            value={inputText}
          />
          <SearchIcon className='w-4 h-4' />
        </div>
      </div>
      <div className='mt-8 flex flex-col gap-y-4'>
        {getTimeOptions().map(({ label, timeText, value }) => (
          <DropdownOption
            key={label}
            label={label}
            timeText={timeText}
            onClick={() => onTimeConfirm(value)}
          />
        ))}
        {isShowingDefaultOption && (
          <button
            onClick={onCustomClick}
            className='px-4 py-3 text-left text-base rounded-lg hover:bg-PRIMARY hover:text-white'
          >
            <p>{translate('snooze.custom')}</p>
          </button>
        )}
      </div>
    </div>
  );
};

export default SnoozeSearch;
