import { FC, useEffect } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Input } from '@chat/chat-uikit/dist';
import cx from 'classnames';
import { Controller, useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import { ErrorIcon } from 'assets/icons';
import chatClient from 'chatSDK';
import { ChatInput, InternalWarning, Loader } from 'components';
import If from 'components/Generics/If/If';
import { INBOX_ROLE } from 'constants/inbox';
import { isWarningClosed } from 'store/atoms';
import { useIsExternalUser } from 'store/selectors/auth';
import { UserMentionInfo } from 'types/Inbox.types';
import { translate } from 'utils';

interface Props {
  name: string;
  selectedRole: string;
  getChatUsers: (searchText?: string) => UserMentionInfo[];
  setSelectedRole: (role: string) => void;
}
const RhfChatInput: FC<Props> = ({
  name,
  selectedRole,
  setSelectedRole,
  getChatUsers
}) => {
  const isExternalUser = useRecoilValue(useIsExternalUser);
  const warningClosed = useRecoilValue(isWarningClosed);
  const isInternalStyle = selectedRole === INBOX_ROLE.INTERNAL;

  useEffect(() => {
    if (isExternalUser) {
      setSelectedRole(INBOX_ROLE.EXTERNAL);
    }
  }, [isExternalUser]);

  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        const handleChange = (value: string) => {
          field.onChange({
            target: { name: field.name, value }
          });
        };
        return (
          <>
            {!isExternalUser ? (
              <div
                className={cx('mt-4 internal-chat-input', {
                  internal: selectedRole === INBOX_ROLE.INTERNAL,
                  external: selectedRole === INBOX_ROLE.EXTERNAL
                })}
              >
                <If condition={!warningClosed}>
                  <InternalWarning
                    isInternal={selectedRole === INBOX_ROLE.INTERNAL}
                  />
                </If>
                <ChatInput
                  className={cx({
                    'border-ERROR': !!(error as any)?.text?.message,
                    'border-LIGHT_GRAY': !(error as any)?.text?.message
                  })}
                  selectedTab={selectedRole}
                  isInternalStyle={isInternalStyle}
                  onChange={(role) => {
                    setSelectedRole(role);
                  }}
                >
                  <Input
                    placeholder={translate('actions.send')}
                    getChatUsers={getChatUsers}
                    showAiAssistant={true}
                    chatClient={chatClient.getChatClient()}
                    isDisabled={!field.value?.text?.trim?.()}
                    loader={<Loader />}
                    hideAttachment={true}
                    onChange={handleChange}
                  />
                </ChatInput>
              </div>
            ) : (
              <div
                className={cx('mobile', {
                  error: (error as any)?.text?.message
                })}
              >
                <Input
                  hideAttachment={true}
                  showAiAssistant={false}
                  placeholder={translate('tell_us_more.tell_us_more')}
                  onChange={handleChange}
                />
              </div>
            )}
            {error && (
              <p className='text-ERROR text-xs mt-2 flex '>
                <ErrorIcon />
                <span className='ml-2'>{(error as any)?.text?.message}</span>
              </p>
            )}
          </>
        );
      }}
    />
  );
};

export default RhfChatInput;
