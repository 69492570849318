import { FC, useEffect, useRef } from 'react';

import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { InboxIcon } from 'assets/icons';
import chatClient from 'chatSDK';
import { pagePaths } from 'config/pages';
import { useRecentTicketList } from 'services/hooks/Dashboard.hook';
import {
  loginedUserDetails,
  selectedTicket,
  unreadNotificationCount
} from 'store/atoms';
import { checkFireBaseConnection } from 'store/atoms/authAtom';
import { Ticket } from 'types/Tickets.types';
import { translate } from 'utils';
import { checkIsExternal } from 'utils/common';
import { isForDifferentHeadClinic } from 'utils/inbox';

const InboxNav: FC = () => {
  const chat = chatClient.chatClient;
  const [unreadMessageCount, setUnreadMessageCount] = useRecoilState(
    unreadNotificationCount
  );

  const selectedTkt = useRecoilValue(selectedTicket);
  const removeNotificationListener = useRef<any>(null);
  const headClinicIdRef = useRef<string>('');
  const checkIsFirebaseConnected = useRecoilValue(checkFireBaseConnection);
  const logindUserDetails = useRecoilValue(loginedUserDetails);
  const resetUnreadCount = useResetRecoilState(unreadNotificationCount);
  const isFirebaseConfigured = useRecoilValue(checkFireBaseConnection);
  headClinicIdRef.current = logindUserDetails?.headClinicId;

  const selectedTicketRef = useRef<Ticket>();
  /**
   * @description
   * Recoil value can't be used inside callback so it is added as a ref
   */
  selectedTicketRef.current = selectedTkt;

  const noHeadClinicSelected =
    checkIsExternal(logindUserDetails?.groups) &&
    !logindUserDetails?.headClinicId &&
    logindUserDetails?.headClinics?.length > 1;

  const skipTicketListApi = () => !isFirebaseConfigured || noHeadClinicSelected;

  const { data, refetch } = useRecentTicketList({
    variables: {
      ticketLimit: 15,
      messagesPerTicket: 1
    },
    skip: skipTicketListApi(),
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });
  useEffect(() => {
    setUnreadMessageCount(data?.getRecentTickets.unReadMessageCount || 0);

    return () => {
      resetUnreadCount();
    };
  }, [data, logindUserDetails]);

  useEffect(() => {
    if (unreadMessageCount < 0) {
      refetch();
    }
  }, [unreadMessageCount]);

  const handleNotificationSuccess = (updates: NotificationOptions) => {
    Object.values(updates).forEach((update) => {
      const { customData } = update;
      if (isForDifferentHeadClinic(customData, headClinicIdRef.current)) {
        return;
      }
      if (
        !location.pathname.includes(pagePaths.dashboard) &&
        update?.entityId != selectedTicketRef.current?.id
      ) {
        setUnreadMessageCount((prevCount) => prevCount + 1);
      }
    });
  };

  useEffect(() => {
    if (checkIsFirebaseConnected) {
      removeNotificationListener.current = chat.addNotificationListener(
        handleNotificationSuccess
      );
    }
    return () => {
      if (removeNotificationListener.current) {
        removeNotificationListener.current();
      }
    };
  }, [chat, checkIsFirebaseConnected]);

  const showMessageCount = unreadMessageCount > 0;

  return (
    <div className='flex justify-between w-full items-center '>
      <div className='flex items-center'>
        <div className='mr-4 w-5 h-5'>
          <InboxIcon />
        </div>
        <span className='md:text-17px xxs:text-sm  whitespace-nowrap'>
          {translate('inbox_button.inbox')}
        </span>
      </div>
      {showMessageCount && (
        <div className=' items-center'>
          <span className='text-DISABLED xxs:text-sm md:text-base whitespace-nowrap'>
            {unreadMessageCount}
          </span>
        </div>
      )}
    </div>
  );
};

export default InboxNav;
