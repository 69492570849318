import { pagePaths } from 'config/pages';

export const ALL_APPOINTMENT_COLORS: { [index: string]: string } = {
  EXTRACTION: '#C4FDFD',
  FITTING: '#D5EAFF',
  IPR: '#C4FDFD',
  SCAN: '#C4FDFD',
  RE_SCAN: '#C4FDFD',
  RE_CHECK: '#C4FDFD',
  MID_REVIEW: '#C4FDFD',
  FINAL_REVIEW: '#C4FDFD',
  CONSULTATION: '#FFE3EF',
  FOLLOW_UP: '#C4FDFD',
  DEFAULT: '#2CAAE2',
  APPOINTMENT_TEXT_COLOR: '#282829',
  NO_SHOW_BG: '#FFEEF0',
  NO_SHOW_TEXT: '#D91E36',
  ADD_ATTACHMENT: '#C4FDFD',
  REMOVE_ATTACHMENT: '#C4FDFD',
  ADD_BUTTON: '#C4FDFD',
  REMOVE_BUTTON: '#C4FDFD'
};

export const APPOINTMENT_TYPES: Record<string, string> = {
  FOLLOW_UP: 'Follow Up',
  FITTING: 'Fitting',
  CONSULTATION: 'Consultation',
  EXTRACTION: 'Extraction',
  IPR: 'IPR',
  SCAN: 'Scan',
  RE_SCAN: 'Re-scan',
  RE_CHECK: 'Re-check',
  MID_REVIEW: 'Mid-treatment review',
  FINAL_REVIEW: 'End-of-treatment review',
  ADD_ATTACHMENT: 'Add attachment',
  REMOVE_ATTACHMENT: 'Remove attachment',
  ADD_BUTTON: 'Add button',
  REMOVE_BUTTON: 'Remove button'
};

export const EDIT_DISABLED_FIELDS = {
  patient: true,
  remarks: true,
  clinic: true
};

export const EVENT_STATUS: Record<string, string> = {
  SHOWED_UP: 'showed up',
  NO_SHOW: 'no show'
};

export const CHECK_FOR_APPT_TYPE: Record<string, string> = {
  CONSULTATION: 'CONSULTATION'
};

export const EDIT_EVENT_BREADCRUMBS = [
  {
    label: 'dashboard_button.dashboard',
    pathname: pagePaths?.dashboard
  },
  {
    label: 'calendar_button.calendar',
    pathname: pagePaths?.calendar
  },
  {
    label: 'calendar.reschedule'
  }
];

export const CREATE_EVENT_BREADCRUMBS = [
  {
    label: 'dashboard_button.dashboard',
    pathname: pagePaths?.dashboard
  },
  {
    label: 'calendar_button.calendar',
    pathname: pagePaths?.calendar
  },
  {
    label: 'chips_label.create_new'
  }
];

export enum OUTCOME_BACKEND {
  SUCCESS = 'SUCCESS',
  ILL_FITTING_AT_FITTING = 'ILL_FITTING_AT_FITTING',
  OTHER_ISSUES = 'OTHER_ISSUES'
}

export const OUTCOME: Record<string, string> = {
  [OUTCOME_BACKEND.SUCCESS]: 'Success',
  [OUTCOME_BACKEND.ILL_FITTING_AT_FITTING]: 'ill-Fitting',
  NOT_AVAILABLE: 'Not Available',
  [OUTCOME_BACKEND.OTHER_ISSUES]: 'Other Issues'
};
