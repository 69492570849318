import { useRef, FC, useState, useEffect } from 'react';

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value: string;
}

const Input: FC<CustomInputProps> = (props) => {
  const { onChange, value, ...otherProps } = props;

  const onComposition = useRef(false);

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  const _onChange = (event: any) => {
    setInputValue(event.target.value);

    if (event.type === 'compositionstart') {
      onComposition.current = true;
      return;
    }

    if (event.type === 'compositionend') {
      onComposition.current = false;
    }

    if (!onComposition.current) {
      onChange?.(event);
    }
  };

  return (
    <input
      {...otherProps}
      value={inputValue}
      onChange={_onChange}
      onCompositionEnd={_onChange}
      onCompositionStart={_onChange}
    />
  );
};

export default Input;
