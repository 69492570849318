import React, { FC, ReactNode, useState } from 'react';

import { CopyIcon, LinkIcon } from 'assets/icons';
import { If } from 'components/Generics';
import { customTwMerge, translate, customTwMerge as twMerge } from 'utils';
import { copyToClipboard } from 'utils/common';
import { showToast } from 'utils/toast/toast.util';

interface Props {
  displayText?: ReactNode | string;
  copyText: string;
  successToastMessage?: string;
  errorToastMessage?: string;
  showCopyIcon?: boolean;
  showLinkIcon?: boolean;
  changeTextOnCopy?: boolean;
  copyHandler?: () => void;
  textStyle?: string;
  linkClassName?: string;
}

export const CopyText: FC<Props> = ({
  displayText,
  copyText,
  successToastMessage,
  errorToastMessage,
  showCopyIcon = true,
  copyHandler,
  textStyle,
  changeTextOnCopy = false,
  showLinkIcon = false,
  linkClassName
}) => {
  const [hasCopied, setHasCopied] = useState(false);
  const onClickCopy = () => {
    copyToClipboard(copyText);
    if (changeTextOnCopy) {
      setHasCopied(true);
    }
    if (successToastMessage) {
      showToast(successToastMessage, true);
      copyHandler?.();
    } else if (errorToastMessage && !copyText) {
      showToast(errorToastMessage, false);
    }
  };
  return (
    <button
      data-testid='copy-button'
      onClick={onClickCopy}
      className='flex items-center w-max'
    >
      <If condition={showCopyIcon}>
        <CopyIcon className='H-6 W-6 mr-2' />
      </If>
      <If condition={showLinkIcon}>
        <LinkIcon
          className={customTwMerge(
            'w-4 h-4 text-DISABLED_2 mr-6px',
            linkClassName
          )}
        />
      </If>
      <p className={twMerge('underline', textStyle)}>
        {hasCopied ? translate('weblink.link_copied') : displayText}
      </p>
    </button>
  );
};
