import { FC } from 'react';

import cx from 'classnames';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { CloseIcon } from 'assets/icons';
import Button from 'components/Button/Button';
import { INBOX_GROUP_NAME } from 'constants/inbox';
import { CHAT_TRACK_EVENTS } from 'constants/segment';
import {
  useAddUserToTicketSelf,
  useGetGroups
} from 'services/hooks/Inbox.hooks';
import {
  useSegmentTrackChat,
  useTrackData
} from 'services/hooks/Segment.hooks';
import {
  loginedUserDetails,
  selectedTicket,
  showChatMessage,
  unreadNotification
} from 'store/atoms';
import { AddUserToTicketBySelf, MapToObj } from 'types/Inbox.types';
import { Ticket } from 'types/Tickets.types';
import { translate } from 'utils';
import {
  getParticipantCount,
  checkIsExternal,
  getTopicName
} from 'utils/common';
import { getTicketSubtitle } from 'utils/customHooks/inbox';
import { getSegmentUserDetails } from 'utils/inbox';

interface Props {
  selectedTicketDetails: Ticket;
  updateSelectedTicketDetails: (id: number) => void;
  onClose: () => void;
  reFetchTicketList: () => void;
}
const AddMeToChat: FC<Props> = ({
  selectedTicketDetails,
  updateSelectedTicketDetails,
  onClose,
  reFetchTicketList
}) => {
  const { data: groupDetails } = useGetGroups();
  const loginedUser = useRecoilValue(loginedUserDetails);
  const selectedTicketDetail = useRecoilValue(selectedTicket);
  const resetSelectedTicket = useResetRecoilState(selectedTicket);
  const setShowChat = useSetRecoilState(showChatMessage);
  const setNotification = useSetRecoilState<MapToObj>(unreadNotification);
  const getGroupTeamId = (groupName = INBOX_GROUP_NAME.CHAT_MEMBER) =>
    groupDetails?.getGroups.find((group) => group.name === groupName)?.id;
  const handleSegmentTrack = useSegmentTrackChat();
  const { trackData, trackContextData, trackPatientData } = useTrackData();
  const metadata = {
    title: getTopicName(selectedTicketDetails.topicInfo.topicCode),
    subtitle: getTicketSubtitle(selectedTicketDetails)
  };

  const [addUserToTicketSelf, { loading }] = useAddUserToTicketSelf({
    onCompleted: (data: AddUserToTicketBySelf) => {
      if (data.addUserToTicketByMyself.userId) {
        const participantCount = getParticipantCount(
          selectedTicketDetails.ticketUsers
        );
        const { zenchat_ID, zenchat_topic, user_permission } = trackData;
        const {
          treatment_plan_id,
          treatment_plan_version_id,
          treatment_plan_version,
          treatment_plan_type
        } = trackContextData;
        handleSegmentTrack(CHAT_TRACK_EVENTS.PARTICIPANT_ADDED, {
          zenchat_ID,
          zenchat_topic,
          user_permission,
          treatment_plan_id,
          treatment_plan_version_id,
          treatment_plan_version,
          treatment_plan_type,
          is_participant: 'no',
          is_chat_created: 'Yes',
          no_of_participants: participantCount + 1
        });
        const selectedTicketInfo = { ...selectedTicketDetails };
        selectedTicketInfo.isRead = true;
        updateSelectedTicketDetails(selectedTicketInfo.id);

        const zenchatUpdateBqData = {
          zenchat_ID: trackData.zenchat_ID,
          chat_created_ts: trackData.chat_created_ts,
          no_of_participants: participantCount + 1
        };
        handleSegmentTrack(
          CHAT_TRACK_EVENTS.ZENCHAT_UPDATE_BQ,
          zenchatUpdateBqData
        );
        const zenchatUpdateUserBqData = {
          opportunity_name: trackPatientData.opportunity_name,
          patient_name: trackPatientData.patient_name,
          patient_country: trackPatientData.patient_country,
          zenchat_status: trackData.zenchat_status,
          zenchat_urgent: trackData.zenchat_urgent,
          zenchat_topic: trackData.zenchat_topic,
          ...zenchatUpdateBqData,
          ...getSegmentUserDetails([
            ...selectedTicketDetails.ticketUsers,
            data.addUserToTicketByMyself
          ])
        };
        handleSegmentTrack(
          CHAT_TRACK_EVENTS.ZENCHAT_USER_UPDATE_BQ,
          zenchatUpdateUserBqData
        );
      }
      if (selectedTicketDetail.id) {
        setNotification((prevState) => {
          const newState = { ...prevState };
          newState[`${selectedTicketDetails.id}`] = 1; // Note: To reset message read status manually for self added tickets
          return newState;
        });
        setShowChat(true);
        onClose();
        reFetchTicketList();
      }
    }
  });

  const handleAddMe = () => {
    const param = {
      ticketId: selectedTicketDetails.id,
      input: {
        groupId: getGroupTeamId(),
        userId: loginedUser?.id,
        teamId: getGroupTeamId(
          loginedUser?.groups.filter(
            (group) => group !== INBOX_GROUP_NAME.CHAT_OWNER
          )[0]
        ),
        isExternal: checkIsExternal(loginedUser?.groups || [])
      }
    };
    addUserToTicketSelf({ variables: { ...param } });
  };

  const handleClose = () => {
    resetSelectedTicket();
    onClose();
  };

  return (
    <div>
      <div className='xxs:flex  mt-2 absolute top-0 right-7'>
        <button onClick={handleClose} className='mt-0.5'>
          <CloseIcon data-testid='close-icon' className='h-3 w-3' />
        </button>
      </div>
      <div className={cx('flex justify-between px-5 pt-6 md:pt-0 flex-col')}>
        <div className='text-DEFAULT_TEXT'>
          <h2 className='text-20px md:text-28px'>{metadata.title}</h2>
          <p className='font-bold md:font-semibold text-14px leading-6 mt-4'>
            {metadata.subtitle}
          </p>
          <p className='text-14px leading-6'>
            {translate('INBOX.CHAT_ID')} - #{selectedTicketDetails.id}
          </p>
        </div>
      </div>
      <div className='flex flex-col justify-center items-center w-full'>
        <p className='px-9 text-xs text-DEFAULT_TEXT text-center mt-4 leading-6'>
          {translate('inbox.addSelfDescription')}
        </p>
        <Button
          onClick={handleAddMe}
          disabled={loading}
          className='text-sm rounded-32px border border-DEFAULT_TEXT py-1 px-4 mt-3'
        >
          {translate('inbox.addMySelf')}
        </Button>
      </div>
    </div>
  );
};

export default AddMeToChat;
