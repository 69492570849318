import { FC, ReactNode } from 'react';

import { MobileDrawer, DesktopDrawer } from 'components';

interface InboxDrawerProps {
  children: ReactNode;
}

const InboxDrawer: FC<InboxDrawerProps> = ({ children }) => {
  return (
    <>
      <MobileDrawer className='md:hidden xxs:block'>{children}</MobileDrawer>
      <DesktopDrawer className='md:block xxs:hidden'>{children}</DesktopDrawer>
    </>
  );
};

export default InboxDrawer;
