import { useCallback, useState } from 'react';

export function useToggle(
  initialValue = false
): [boolean, (value?: boolean) => void] {
  const [on, setOn] = useState(initialValue);

  const handleToggle = useCallback((value?: boolean) => {
    if (typeof value === 'boolean') {
      return setOn(value);
    }

    return setOn((v) => !v);
  }, []);

  return [on, handleToggle];
}
