import { FC, ReactNode } from 'react';

import cx from 'classnames';

import { SnoozeIcon } from 'assets/icons';
import If from 'components/Generics/If/If';
import { TICKET_STATUS } from 'constants/inbox';
import { DATE_FORMAT_DD_MM_YY } from 'constants/settings';
import { TicketTopicContextInfo } from 'types/Tickets.types';
import { customTwMerge, translate } from 'utils';
import { useIsmobile } from 'utils/customHooks/useViewport';
import { formatDate } from 'utils/dateUtils';
import { getStatusLabel } from 'utils/inbox';

interface Data {
  name: string;
  title: string;
  subtitle: string;
  owner: string;
  tpDetails: TicketTopicContextInfo | undefined;
  tpvDetails: TicketTopicContextInfo | undefined;
  tpvType?: string;
}

interface TicketCardProps {
  showMentionIcon: boolean;
  chatId: number;
  selected?: boolean;
  data: Data;
  updatedAt: string;
  notificationCount?: number;
  unread?: boolean;
  isExternalUser?: boolean;
  isFromPatientDetails?: boolean;
  isSnoozed?: boolean;
  status?: string;
  message?: ReactNode;
  onSelect: () => void;
}

const TicketCard: FC<TicketCardProps> = ({
  chatId,
  selected = false,
  data,
  updatedAt,
  notificationCount = 0,
  message,
  isSnoozed = false,
  unread = false,
  isExternalUser = false,
  isFromPatientDetails = false,
  status = '',
  showMentionIcon = false,
  onSelect
}) => {
  const isUnread = !(notificationCount < 1 && unread);
  const showSnoozeIcon = isSnoozed && !isExternalUser;
  const isMobile = useIsmobile();

  const getTicketSubtitle = () => {
    const tp = data.tpDetails;
    const tpv = data.tpvDetails;

    if (tp?.contextValue && tpv?.contextValue) {
      return (
        <>
          <If condition={data.tpvType !== ''}>
            <span className='whitespace-nowrap'>{data.tpvType}</span>
          </If>
          <If condition={data.tpvType === ''}>
            <span className='whitespace-nowrap'>{tp?.contextValue}</span>
          </If>
          {' | '}
          <span className='whitespace-nowrap'>{tpv?.contextValue}</span>
        </>
      );
    }
    if (tp?.contextValue || tpv?.contextValue) {
      if (tpv?.contextValue && data.tpvType !== '') {
        return (
          <span className='whitespace-nowrap'>
            {`${data.tpvType} | ${tpv?.contextValue}`}
          </span>
        );
      } else
        return (
          <span className='whitespace-nowrap'>
            {tp?.contextValue || tpv?.contextValue}
          </span>
        );
    }
    return '';
  };

  const showNotificationDot = notificationCount > 0;
  return (
    <div
      className={customTwMerge(
        cx(
          'rounded-8px px-4 py-3 flex justify-between relative cursor-pointer mb-3 border-b-1 border-LINE_GRAY',
          {
            'bg-GRAY5': isUnread,
            'border border-PRIMARY bg-LIGHT_BLUE_66': selected,
            'mb-2 text-14px': isFromPatientDetails,
            'mr-2': isFromPatientDetails && showNotificationDot
          }
        )
      )}
      id={`${chatId}`}
      onClick={onSelect}
      role='presentation'
      data-testid='card'
    >
      <span
        className={cx(
          'bg-red-500 absolute h-6 w-6 top-0 right-0 -mt-2 -mr-2 rounded-full flex items-center justify-center',
          {
            hidden: !showNotificationDot
          }
        )}
        data-testid='notification-dot'
      >
        <p
          className={cx('text-white', {
            hidden: notificationCount < 1
          })}
        >
          {Math.min(notificationCount, 99)}
        </p>
      </span>

      <div>
        <If condition={!isFromPatientDetails}>
          <h4
            className={cx(
              'text-DEFAULT_TEXT text-base md:text-14px md:text-base mb-2.5 md:mb-1',
              {
                'font-semibold': isUnread
              }
            )}
          >
            {data.name}
          </h4>
        </If>
        <p
          className={customTwMerge(
            cx(
              { 'text-DISABLED text-xs mb-1': !isFromPatientDetails },
              {
                'text-DEFAULT_TEXT text-xs mb-1 font-semibold':
                  isUnread && !isFromPatientDetails
              },
              {
                'text-DEFAULT_TEXT text-14px mb-2': isFromPatientDetails
              },
              {
                'text-DEFAULT_TEXT text-sm mb-2 font-semibold':
                  isUnread && isFromPatientDetails
              }
            )
          )}
        >
          {data.title}
        </p>
        <If condition={isExternalUser && !isFromPatientDetails}>
          <p
            className={customTwMerge(
              cx('text-DISABLED text-xs mb-1', {
                'text-DEFAULT_TEXT font-semibold': isUnread
              })
            )}
          >
            {data.subtitle}
          </p>
        </If>
        <If condition={data.owner.length > 0 && !isExternalUser && !message}>
          <p
            className={customTwMerge(
              cx('text-DISABLED text-xs mb-1', {
                'text-black font-semibold': isUnread,
                'md:text-sm mb-2': isFromPatientDetails
              })
            )}
          >
            {translate('descriptive.owner')}: {data.owner}
          </p>
        </If>
        <If condition={isFromPatientDetails || isMobile}>
          <p
            className={customTwMerge(
              cx('text-DISABLED text-xs md:text-sm', {
                'text-DEFAULT_TEXT font-semibold': isUnread
              })
            )}
          >
            Chat ID: {chatId}
          </p>
        </If>

        <div className='text-xs text-DEFAULT_TEXT break-all'>{message}</div>
      </div>
      <div
        className={cx({
          'flex flex-col justify-between': isExternalUser
        })}
      >
        <div className='flex gap-x-4 items-center justify-end mb-1'>
          <If condition={showMentionIcon}>
            <p className='pr-2 text-PRIMARY'>@</p>
          </If>
          <If condition={showSnoozeIcon}>
            <SnoozeIcon className='w-4 h-4 text-DARK_BLUE_3' />
          </If>
          <p className='text-DISABLED text-xs md:text-14px py-1'>
            {formatDate(updatedAt, DATE_FORMAT_DD_MM_YY)}
          </p>
        </div>
        <If condition={!isExternalUser}>
          <p
            className={customTwMerge(
              cx('text-DISABLED text-xs mb-1 text-right', {
                'text-DEFAULT_TEXT font-semibold': isUnread,
                'my-2': isFromPatientDetails
              })
            )}
          >
            {getTicketSubtitle()}
          </p>
        </If>
        <div className='flex flex-col items-end'>
          <div className='flex items-center mb-1'>
            <p
              className={cx('px-3 py-[0.0625rem] text-14px rounded-full', {
                'text-UPSDELL_RED bg-LIGHT_RED':
                  status === TICKET_STATUS.URGENT,
                'text-SUCCESS bg-LIGHT_GREEN': status === TICKET_STATUS.CLOSE,
                'text-BROWN bg-YELLOW': status === TICKET_STATUS.OPEN
              })}
            >
              {getStatusLabel(status)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketCard;
