import { FC } from 'react';

type FileUploadModalHeaderProp = {
  title: string;
};

const FileUploadModalHeader: FC<FileUploadModalHeaderProp> = ({ title }) => (
  <h2 className='text-xl text-DEFAULT_TEXT leading-6 font-w'>{title}</h2>
);

export default FileUploadModalHeader;
