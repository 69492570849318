import { FC, useState, useRef, ReactNode } from 'react';

import cx from 'classnames';
import { FileRejection } from 'react-dropzone';

import Files from 'components/Files/Files';
import FilesUploadModal from 'components/Files/FilesUploadModal/FilesUploadModal';
import { If } from 'components/Generics';
import Slider from 'components/Slider/Slider';
import { FilesData } from 'types/Files.types';
import { translate, useViewport, customTwMerge as twMerge } from 'utils';
import { formatFile, removeItemAtIndex } from 'utils/common';
import { showToast } from 'utils/toast/toast.util';

import FileDropZone from './FilesDropZone/FileDropZone';

const FILE_TOO_SMALL = 'file-too-small';

type FileUploadProps = {
  files: FilesData[];
  onFilesChange: (files: FilesData[]) => void;
  fileDropAreaTitle: string;
  onDelete?: (id: string | number, localId?: string | number) => void;
  deleting?: boolean;
  allowedFileMimeType?: string | string[];
  className?: string;
  showUploadImagesButton?: boolean;
  children?: ReactNode;
  uploadButtonName?: string;
  retainerFiles?: FilesData[];
  enableSlider?: boolean;
  uploadContainerStyle?: string;
  supportedFilesText?: string;
  onUploadClicked?: () => void;
  uploadButtonHandler?: () => void;
  dropContainerClassName?: string;
  setSelectedPhoto?: React.Dispatch<React.SetStateAction<string | number>>;
  toggleZoomPhoto?: React.Dispatch<React.SetStateAction<boolean>>;
  isFromNotesUpload?: boolean;
  onUploadButtonClick?: () => void;
  disabled?: boolean;
  hideUploadModal?: boolean;
  thumbnailHeight?: string;
};

const FilesUpload: FC<FileUploadProps> = ({
  files = [],
  onFilesChange,
  onDelete,
  deleting,
  allowedFileMimeType = '',
  showUploadImagesButton,
  fileDropAreaTitle = 'image',
  className = '',
  children,
  uploadButtonName,
  retainerFiles = [],
  enableSlider = false,
  uploadContainerStyle,
  supportedFilesText,
  uploadButtonHandler,
  hideUploadModal = false,
  onUploadClicked: handleUploadClicked,
  dropContainerClassName = '',
  setSelectedPhoto,
  toggleZoomPhoto,
  isFromNotesUpload = false,
  onUploadButtonClick,
  disabled,
  thumbnailHeight
}) => {
  const [filesModalData, setFilesModalData] = useState<{
    data: FilesData[];
    isOpen: boolean;
  }>({ data: [], isOpen: false });
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const showOnlyAddButton = !!files.length;
  const { width } = useViewport();

  const onDropAccepted = (uploadedFiles: File[]) => {
    const filesData: FilesData[] = formatFile(uploadedFiles);
    width > 768 && !hideUploadModal
      ? setFilesModalData({
          data: filesModalData.data.concat(filesData),
          isOpen: true
        })
      : onFilesChange(filesData);
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.forEach(({ errors, file }) => {
      if (errors.find(({ code }) => code === FILE_TOO_SMALL))
        showToast(
          translate('error.0kbfile', {
            X: file.name
          }),
          false
        );
      else
        showToast(
          translate('error.validFileType', {
            X: file.name
          }),
          false
        );
    });
  };

  const onCancelClicked = () => setFilesModalData({ data: [], isOpen: false });
  const onUploadClicked = (filesData: FilesData[]) => {
    handleUploadClicked?.();
    setFilesModalData({ data: [], isOpen: false });
    onFilesChange(filesData);
  };

  const containerRef = useRef<HTMLDivElement>(null);

  const onLocalDelete = (index: number) => {
    const newList = removeItemAtIndex(filesModalData?.data, index);
    newList.length === 0
      ? onCancelClicked()
      : setFilesModalData({ data: newList, isOpen: true });
  };
  const openFileDialog = () => {
    setFileDialogOpen(true);
  };
  const onFileDialogClosed = () => {
    setFileDialogOpen(false);
  };
  return (
    <Slider childRef={containerRef} disable={!enableSlider} rightOffset={138}>
      <div
        className={twMerge(
          cx('flex overflow-x-auto', {
            'flex-col': !showOnlyAddButton,
            'flex-wrap': showOnlyAddButton && isFromNotesUpload,
            'flex-row': showOnlyAddButton && !isFromNotesUpload
          }),
          className
        )}
        ref={containerRef}
      >
        <If condition={!retainerFiles.length}>
          <FileDropZone
            onDropAccepted={onDropAccepted}
            onDropRejected={onDropRejected}
            allowedFileMimeType={allowedFileMimeType}
            fileTitleType={fileDropAreaTitle}
            showOnlyAddButton={showOnlyAddButton}
            showUploadImagesButton={showUploadImagesButton}
            maxFiles={0} //0 means there is no limit to the number of files that can be uploaded
            uploadButtonName={uploadButtonName}
            onUploadButtonClick={onUploadButtonClick}
            supportedFilesText={supportedFilesText}
            containerStyle={uploadContainerStyle}
            fileDialogOpen={fileDialogOpen}
            onFileDialogClosed={onFileDialogClosed}
            className={dropContainerClassName}
            isFromNotesUpload={isFromNotesUpload}
            disabled={disabled}
            uploadButtonHandler={uploadButtonHandler}
          >
            {children}
          </FileDropZone>
        </If>
        <Files
          files={!retainerFiles.length ? files : retainerFiles}
          onDelete={onDelete}
          deleting={deleting}
          className={isFromNotesUpload ? 'flex-wrap' : 'flex-shrink-0'}
          setSelectedPhoto={setSelectedPhoto}
          toggleZoomPhoto={toggleZoomPhoto}
          isFromNotesUpload={isFromNotesUpload}
          showOnlyAddButton={showOnlyAddButton}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          height={thumbnailHeight}
        />
        <FilesUploadModal
          title={translate('upload_images.upload_images')}
          onCancelClicked={onCancelClicked}
          onUploadClicked={onUploadClicked}
          files={filesModalData?.data || []}
          isOpen={filesModalData?.isOpen || false}
          onLocalDelete={onLocalDelete}
          openFileDialog={openFileDialog}
        />
      </div>
    </Slider>
  );
};

export default FilesUpload;
