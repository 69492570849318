import { FC, useMemo, useState, useEffect } from 'react';

import AutoSuggest from 'components/AutoSuggest/AutoSuggest';
import { EXTERNAL_ROLES, INTERNAL_ROLES } from 'constants/common';
import { useGetAllStaffList } from 'services/hooks/Inbox.hooks';
import { AutoSuggestions } from 'types/autoSuggest.types';
import { Participant, Staff } from 'types/Inbox.types';
import { translate } from 'utils';
import { getTeamName, isDataExist } from 'utils/common';

type UsersAutoSuggestProp = {
  onSuggestionClick: (userDetails: Staff | undefined) => void;
  onSuggestionCleared?: () => void;
  inputClasseName?: string;
  containerStyle?: string;
  searchListClassName?: string;
  participantsList?: Participant[];
  searchItemClassName?: string;
  searchListWrapperClassName?: string;
  hideSearchIcon?: boolean;
  inputStyle?: string;
  placeholder?: string;
  clearOnSelect?: boolean;
  isBulkAssign?: boolean;
  searchText?: string;
};

const TeamMemberAutoSuggest: FC<UsersAutoSuggestProp> = ({
  onSuggestionClick,
  onSuggestionCleared,
  inputClasseName = '',
  containerStyle,
  hideSearchIcon,
  searchListClassName = '',
  searchItemClassName = '',
  inputStyle,
  isBulkAssign = false,
  clearOnSelect = true,
  searchListWrapperClassName = '',
  participantsList = [],
  searchText = '',
  placeholder = translate('INBOX.ADD_PARTICIPANT_NAME')
}) => {
  const [searchedItem, setSearchedItem] = useState('');
  const [getAllStaffList, { data: staffDetails }] = useGetAllStaffList();
  const [selectedParticipantsIds, setselectedParticipantsIds] = useState<
    string[]
  >([]);

  useEffect(() => {
    if (isDataExist(participantsList)) {
      setselectedParticipantsIds(
        participantsList.map((participant) => participant.userId)
      );
    }
    return () => {
      setselectedParticipantsIds([]);
    };
  }, [participantsList]);

  const userData = useMemo(() => {
    const suggestionList: AutoSuggestions[] = [];
    staffDetails?.getAllStaff?.staff?.forEach(
      ({ firstName, lastName, groups, id, ...remaining }) => {
        if (!selectedParticipantsIds.includes(id)) {
          let name = `${firstName || ''} ${lastName || ''}`;
          const teamName = isDataExist(groups) ? getTeamName(groups[0]) : '';
          name = `${name} (${teamName})`;
          suggestionList.push({
            name,
            id,
            ...(remaining as any)
          });
        }
      }
    );
    return suggestionList;
  }, [staffDetails?.getAllStaff?.staff, selectedParticipantsIds]);

  const roles = [...EXTERNAL_ROLES, ...INTERNAL_ROLES];

  const onSearchChange = (text: string) => {
    setSearchedItem(text.trim());
    getAllStaffList?.({
      variables: {
        filter: {
          searchKey: text,
          roles: isBulkAssign ? INTERNAL_ROLES : roles
        },
        paginationInfo: {
          sortBy: 'name'
        }
      }
    });
  };
  const handleAutoSuggestionClick = (userDetails: AutoSuggestions) => {
    const selectedStaff = staffDetails?.getAllStaff?.staff?.find(
      (staff) => staff.id === userDetails.id
    );
    onSuggestionClick(selectedStaff);
  };

  return (
    <AutoSuggest
      suggestions={userData}
      searchText={searchText}
      onSearchChange={onSearchChange}
      searchListWrapperClassName={searchListWrapperClassName}
      onSuggestionClick={handleAutoSuggestionClick}
      onSearchCleared={onSuggestionCleared}
      placeholder={placeholder}
      debounceTime={600}
      clearOnSelect={clearOnSelect}
      hideSearchIcon={hideSearchIcon}
      searchInputProps={{
        hideCloseIcon: true,
        className: inputClasseName,
        containerStyle: containerStyle,
        inputStyle: inputStyle
      }}
      searchListProps={{
        searchItemClassName: searchItemClassName,
        className: searchListClassName,
        noDataMsg: translate('referrals.no_result_found_for_“X”', {
          X: searchedItem
        }),
        searchListMaxHeight: 'md:max-h-25vh',
        minSearchTextlength: 1
      }}
    />
  );
};

export default TeamMemberAutoSuggest;
