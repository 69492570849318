import { FC, useMemo, useRef, useCallback, useEffect } from 'react';

import Dots from 'react-carousel-dots';
import PrismaZoom from 'react-prismazoom';

import { ImageClose, NextArrow } from 'assets/icons';
import CustomModal from 'components/CustomModal/CustomModal';
import { If } from 'components/Generics';
import { Loader } from 'components/Loader/Loader';
import { FilesData } from 'types/Files.types';
import './style.css';

type Props = {
  allFiles?: FilesData[];
  zoomPhoto?: boolean;
  selectedPhoto: string;
  toggleZoomPhoto?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedPhoto?: React.Dispatch<React.SetStateAction<string | number>>;
};

const ZoomPhotoPopup: FC<Props> = ({
  allFiles = [],
  zoomPhoto = true,
  toggleZoomPhoto,
  selectedPhoto,
  setSelectedPhoto
}) => {
  const filterImages = useMemo(() => {
    return allFiles?.filter((img) => img.fileType === 'img');
  }, [allFiles]);

  const getNextSelectedPhoto = useCallback(
    (next: boolean) => {
      const currIndex = filterImages.findIndex(
        (image) => image?.id === selectedPhoto
      );
      if (next) return filterImages[currIndex + 1]?.id;
      else if (!next) return filterImages[currIndex - 1]?.id;
      else return filterImages[currIndex]?.id;
    },
    [filterImages, selectedPhoto]
  );

  const zoomref = useRef<any>(null);

  useEffect(() => {
    zoomref?.current?.reset();
  }, [selectedPhoto]);

  const selectedImage = useMemo(() => {
    return filterImages.find((img) => img.id === selectedPhoto);
  }, [filterImages, selectedPhoto]);

  const handleKeyPress = useCallback(
    (e: any) => {
      const { key } = e;
      if (
        key === 'ArrowRight' &&
        selectedPhoto !== filterImages[filterImages.length - 1]?.id
      ) {
        setSelectedPhoto?.(getNextSelectedPhoto(true));
      } else if (key === 'ArrowLeft' && selectedPhoto !== filterImages[0]?.id) {
        setSelectedPhoto?.(getNextSelectedPhoto(false));
      }
    },
    [filterImages, getNextSelectedPhoto, selectedPhoto, setSelectedPhoto]
  );

  useEffect(() => {
    if (zoomPhoto) document.body.addEventListener('keydown', handleKeyPress);

    return () => {
      document.body.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress, zoomPhoto]);

  return (
    <CustomModal
      open={zoomPhoto}
      onClose={() => toggleZoomPhoto?.(false)}
      showCloseIcon={false}
    >
      <>
        <If condition={!!selectedImage?.id}>
          <div className='chat-preview-popup__container overflow-hidden rounded-xl'>
            <If condition={selectedPhoto !== filterImages[0]?.id}>
              <button
                className='absolute top-47 left-6 z-2'
                onClick={() => setSelectedPhoto?.(getNextSelectedPhoto(false))}
                type='button'
              >
                <NextArrow className='transform rotate-180' />
              </button>
            </If>
            <button
              className='chat-preview-popup__close-button z-2'
              onClick={() => toggleZoomPhoto?.(false)}
              type='button'
            >
              <ImageClose className='h-7 w-7' />
            </button>
            <PrismaZoom ref={zoomref}>
              <img
                src={selectedImage?.fileSrc}
                alt=''
                className='chat-preview-popup__img  min-w-44'
              />
            </PrismaZoom>
            <div className='flex space-x-2 place-content-center absolute bottom-4 w-full rounded-xl'>
              <If condition={filterImages.length > 1}>
                <Dots
                  length={filterImages.length}
                  visible={4}
                  size={6}
                  active={filterImages.findIndex(
                    (img) => img.id === selectedPhoto
                  )}
                />
              </If>
            </div>

            <If
              condition={
                selectedPhoto !== filterImages[filterImages.length - 1]?.id
              }
            >
              <button
                className=' absolute top-47 right-6 z-2'
                onClick={() => setSelectedPhoto?.(getNextSelectedPhoto(true))}
                type='button'
              >
                <NextArrow />
              </button>
            </If>
          </div>
        </If>
        <If condition={!selectedImage?.id}>
          <Loader fullPage />
        </If>
      </>
    </CustomModal>
  );
};

export default ZoomPhotoPopup;
