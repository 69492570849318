import { FC, useState } from 'react';

import classNames from 'classnames';

import { customTwMerge } from 'utils';

import TranslateTextArea from './Components/TranslateTextArea/TranslateTextArea';

interface Props {
  title?: string;
  InputTextFieldName: string;
  translatedTextFieldName: string;
  isDisabled?: boolean;
  setValue: (fieldName: string, result?: string) => void;
  isFormContext?: boolean;
  showCustomHight?: boolean;
  translatedText?: string;
  placeholder?: string;
  translateFieldPlaceholder?: string;
  isTranslateFieldDefined?: boolean;
  labelClassName?: string;
}
const SpecialNoteTranslation: FC<Props> = ({
  title,
  InputTextFieldName: specialNotesFieldName,
  translatedTextFieldName: translatedSpecialNotesFieldName,
  isDisabled,
  setValue,
  showCustomHight,
  isFormContext = true,
  translatedText,
  placeholder,
  isTranslateFieldDefined,
  translateFieldPlaceholder,
  labelClassName
}) => {
  const [isTranslatedFieldDisabled, setTranslatedFieldDisabled] =
    useState(true);
  const [isTranslating, setIsTranslating] = useState(false);
  const onInputChange = (sourceText: string, isTranslatedField?: boolean) => {
    if (!isTranslatedField) {
      if (sourceText === '') {
        setIsTranslating(false);
        setTranslatedFieldDisabled(true);
      } else {
        setIsTranslating(true);
        setTranslatedFieldDisabled(false);
      }
    } else {
      setTranslatedFieldDisabled(false);
      if (sourceText === '') {
        setIsTranslating(false);
      } else {
        setIsTranslating(true);
      }
    }
  };

  return (
    <section>
      {isFormContext && (
        <h4 className={customTwMerge('text-sm font-normal', labelClassName)}>
          {title}
        </h4>
      )}
      <div
        className={classNames(
          'mt-4 px-4 py-3 border border-BORDER_COLOR rounded-lg',
          {
            'bg-WHITE': !isDisabled,
            'bg-LIGHT_GRAY': isDisabled
          }
        )}
      >
        <div className=''>
          <TranslateTextArea
            onInputChange={onInputChange}
            isTranslateFieldDisabled={false}
            placeholder={placeholder}
            setValue={setValue}
            isSubmitted={isDisabled}
            InputFieldName={specialNotesFieldName}
            translateFieldName={translatedSpecialNotesFieldName}
            isFormContext={isFormContext}
            showCustomHight={showCustomHight}
          />
        </div>
        <div className='w-full  mb-4 border border-LINE_GRAY flex-1 h-[1px] mr-1 box-border'></div>
        <div>
          <TranslateTextArea
            isTranslateFieldDefined={isTranslateFieldDefined}
            onInputChange={onInputChange}
            isTranslating={isTranslating}
            isTranslateFieldDisabled={isTranslatedFieldDisabled}
            placeholder={translateFieldPlaceholder || placeholder}
            translatedText={translatedText}
            setValue={setValue}
            isSubmitted={isDisabled}
            showCustomHight={showCustomHight}
            isTranslatedField={true}
            InputFieldName={specialNotesFieldName}
            translateFieldName={translatedSpecialNotesFieldName}
            isFormContext={isFormContext}
          />
        </div>
      </div>
    </section>
  );
};

export default SpecialNoteTranslation;
