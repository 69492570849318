import { Suspense, FC } from 'react';

import { ApolloProvider } from '@apollo/client';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import { IntercomProvider } from 'react-use-intercom';
import { RecoilRoot } from 'recoil';

import { INTERCOM_APP_ID } from 'config';
import RootLayout from 'routes/rootLayout';
import { useAppApolloClient } from 'services';
import useLogError from 'utils/customHooks/useLogError.hooks';

import { ErrorFallback, Loader, SuccessErrorAlert } from './components';
import 'moment/locale/vi';
import 'moment/locale/id';
import 'moment/locale/th';
import 'moment/locale/mn';
import 'moment/locale/ms';
import 'moment/locale/ko';
import 'moment/locale/ja';
import 'moment/locale/zh-tw';

import './App.css';

const App: FC = () => {
  const client = useAppApolloClient();
  const { logError } = useLogError();

  return (
    <RecoilRoot>
      <ApolloProvider client={client}>
        <ErrorBoundary fallback={<ErrorFallback />} onError={logError}>
          <Suspense fallback={<Loader fullPage />}>
            <ToastContainer />
            <SuccessErrorAlert />
            <IntercomProvider appId={INTERCOM_APP_ID}>
              <RootLayout />
            </IntercomProvider>
          </Suspense>
        </ErrorBoundary>
      </ApolloProvider>
    </RecoilRoot>
  );
};

export default App;
