import { FC } from 'react';

import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import 'react-loading-skeleton/dist/skeleton.css';

import { ProfilePicIcon } from 'assets/icons';
import { pagePaths } from 'config/pages';
import { RECENT_MESSAGE_MAX_LENGTH } from 'constants/inbox';
import { CHAT_TRACK_EVENTS } from 'constants/segment';
import { DATE_FORMAT_RECENT_MESSAGE } from 'constants/settings';
import { RecentMessageCardDetails } from 'types/Dashboard.types';
import { translate, formatDate, subStringText } from 'utils';
import { formatMentionedMessage } from 'utils/inbox';

interface Props {
  image?: string;
  isExternalUser: boolean;
  recentTicketData?: RecentMessageCardDetails;
  loading?: boolean;
}
const RecentMessageCard: FC<Props> = ({
  image,
  recentTicketData,
  isExternalUser,
  loading
}) => {
  const navigate = useNavigate();
  const getLink = () =>
    `${pagePaths.inbox}?ticketId=${recentTicketData?.id}&messageId=${recentTicketData?.messageId}`;

  const translationTopicNameMapper: Record<string, string> = {
    'Urgent enquiry': 'TopicList..2',
    'Trimming aligners': 'TopicList..84.12',
    'Ill fitting aligners': 'TopicList20',
    'End-of-treatment support': 'TopicList..52'
  };

  const formatMentionMessage = () => {
    if (recentTicketData?.rich_text_info) {
      const mentionedMessage = formatMentionedMessage(
        recentTicketData?.rich_text_info,
        isExternalUser
      );
      return subStringText(mentionedMessage, RECENT_MESSAGE_MAX_LENGTH);
    }
    return subStringText(recentTicketData?.message, RECENT_MESSAGE_MAX_LENGTH);
  };

  const onClickMessageCard = () => {
    window.analytics.track(CHAT_TRACK_EVENTS.ZENCHAT_CLICKED_DASHBOARD, {
      zenchat_ID: recentTicketData?.id,
      zenchat_topic: recentTicketData?.topicName
    });
    navigate(getLink());
  };

  return (
    <div className='md:border-b-1 md:border-CORE_GRAY py-4 xxs:bg-GRAY5 xxs:p-4 xxs:mb-2 xxs: recent-message-card md:first:pt-0'>
      <div
        className='cursor-pointer'
        onClick={onClickMessageCard}
        role='button'
        tabIndex={-1}
        onKeyDown={onClickMessageCard}
      >
        <div className='flex justify-between'>
          <p className='md:text-sm xxs:text-xs text-DEFAULT_TEXT'>
            {loading ? (
              <Skeleton width={200} />
            ) : (
              translate(
                translationTopicNameMapper[
                  recentTicketData?.topicName as string
                ]
              ) || recentTicketData?.topicName
            )}
          </p>
          <p className='text-DISABLED_2 text-sm xxs:hidden md:block'>
            {loading ? (
              <Skeleton width={100} />
            ) : (
              formatDate(
                recentTicketData?.createdDate || '',
                DATE_FORMAT_RECENT_MESSAGE
              )
            )}
          </p>
        </div>

        <div className='flex md:flex-row md:mt-4 xxs:mt-2 xxs:flex-col'>
          <div className='flex items-center mr-4 '>
            {loading ? (
              <Skeleton circle={true} height={30} width={30} className='mr-2' />
            ) : image ? (
              <img
                loading='lazy'
                src={image}
                alt=''
                className='md:w-7 md:h-7 xxs:w-5 xxs:h-5 align-middle object-cover rounded-full'
              />
            ) : (
              <ProfilePicIcon className='md:w-7 md:h-7 xxs:w-5 xxs:h-5 ' />
            )}
          </div>
          <div className=' text-sm text-DEFAULT_TEXT xxs:mt-2 '>
            <p className='xxs:font-semibold md:font-normal'>
              {loading ? (
                <Skeleton className='w-[12rem] md:w-[18rem]' />
              ) : (
                translate('INBOX.HAS_REPLIED', {
                  X: recentTicketData?.senderName || '',
                  'patient name': recentTicketData?.patientName || ''
                })
              )}
            </p>
            <p className='md:mt-1 xxs:mt-2 xxs:text-DISABLED md:text-DEFAULT_TEXT'>
              {loading ? (
                <Skeleton className='w-[20rem] md:w-[31rem]' />
              ) : (
                formatMentionMessage()
              )}
            </p>
            <div className='md:hidden flex justify-end'>
              <p className='text-DISABLED text-sm '>
                {formatDate(
                  recentTicketData?.createdDate || '',
                  DATE_FORMAT_RECENT_MESSAGE
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentMessageCard;
