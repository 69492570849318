import { FC } from 'react';

import RhfSelect from 'components/Generics/Rhf/RhfSelect';
import { useGetCountries } from 'services/hooks/UsersList.hooks';
import { translate } from 'utils';

export const CountrySelector: FC = () => {
  const { data: countries } = useGetCountries();

  return (
    <RhfSelect
      label={translate('country.country')}
      inputProps={{
        controlShouldRenderValue: true,
        placeholder: translate('country.country'),
        isMulti: false
      }}
      options={countries?.getSupportedCountries || []}
      name='country'
      isSearchable
    />
  );
};
