import { gql } from '@apollo/client';

export const GET_DOCTORS_NOTES = gql`
  query GetDoctorNotes($treatmentIds: [ID!]!) {
    getInTreatmentNotes(treatmentIds: $treatmentIds) {
      note {
        id
        content
        noteType
        createdAt
        updatedAt
        documents {
          presignedUrl
          thumbnail
          documentId
          extension
          name
        }
        title
        relatedId
      }
      appointment {
        id
        type {
          name
        }
        startTime
        treatment {
          id
        }
      }
    }
  }
`;

export const GET_APPOINTENT_TYPES = gql`
  query GetTreatmentAppointments($treatmentId: String!) {
    getTreatmentAppointments(treatmentId: $treatmentId) {
      id
      startTime
      title
      type {
        name
      }
    }
  }
`;

export const GET_ALL_APPOINTMENT_FOR_OPPORTUNITY = gql`
  query ($filter: AftersalesAppointmentsFilter) @api(name: treatmentMonitor) {
    getAllAppointmentForOpportunities(filter: $filter) {
      id: appointment_id
      startTime: start_time
      type: appointment_type_name
      salesType: appointment_sales_type
      patientName: patient_name
      doctorClinic: doctor_clinic
    }
  }
`;

export const CREATE_TREATMENT_NOTE = gql`
  mutation ($input: CreateTreatmentNoteInput!) {
    createTreatmentNote(input: $input) {
      note {
        id
      }
    }
  }
`;

export const GET_NOTE_DOCUMENT_UPLOAD_URL = gql`
  query GetInTreatmentNotesDocumentUploadUrl(
    $treatmentId: String!
    $documents: [String]!
  ) {
    getInTreatmentNotesDocumentUploadUrl(
      input: { treatmentId: $treatmentId, documents: $documents }
    ) {
      url
      name
      key
      extension
    }
  }
`;

export const DELETE_NOTE_DOCUMENT = gql`
  mutation RemoveNoteDocument($noteId: ID!, $documentId: ID!) {
    removeNoteDocument(noteId: $noteId, documentId: $documentId) {
      id
    }
  }
`;

export const DELETE_NOTE = gql`
  mutation DeleteTreatmentNote($noteId: ID!) {
    deleteTreatmentNote(noteId: $noteId) {
      note {
        id
      }
    }
  }
`;

export const UPDATE_TREATMENT_NOTE = gql`
  mutation ($noteId: ID!, $input: UpdateTreatmentNoteInput!) {
    updateTreatmentNote(noteId: $noteId, input: $input) {
      note {
        id
      }
    }
  }
`;

export const UPDATE_EXTERNAL_DOCTOR_REVIEW = gql`
  mutation ($input: ExternalDoctorReviewInput!) {
    updateExternalDoctorReview(input: $input) {
      id
      patientId
    }
  }
`;

export const SUBMIT_OUTCOME_FOR_APPOINTMENT = gql`
  mutation ($id: String!, $outcome: OUTCOME!) @api(name: treatmentMonitor) {
    submitOutcomeForAppointment(id: $id, outcome: $outcome) {
      patient_name
      appointment_id
      appointment_type
      appointment_status
      appointment_outcome
      successor_treatment_id
      comments {
        description
      }
    }
  }
`;

export const CORE_TICKET_FIELDS = gql`
  fragment CoreTicketFields on TicketResponse {
    id
    isUrgent
    isRead
    topicInfo {
      topicId
      topicName
      topicCode
    }
    createdAt
    updatedAt
    ticketChatSearchMessage {
      id
      text
      send_group_id
    }
    status
    ticketUsers {
      username
      groupName
      userId
      userFirstName
      userLastName
      email
      userTeamName
      isExternal
      userTeamCode
      deactivatedAt
    }
    ticketTopicContextInfo {
      contextType
      contextValue
      contextValueId
    }
  }
`;

export const CREATE_CHAT = gql`
  ${CORE_TICKET_FIELDS}
  mutation ($input: CreateTicketFromTopicCodeInput, $loginedUserId: ID!) {
    createTicketFromTopicCode(input: $input) {
      ...CoreTicketFields
      isUserPartOfTicket(userId: $loginedUserId)
    }
  }
`;

export const GET_PATIENT_PHOTOS = gql`
  query GetTreatmentInfo($opportunityId: ID!) @api(name: treatmentMonitor) {
    getTreatmentInfoByOppId(id: $opportunityId) {
      totalAligner: total_aligner
      alignerInfo: aligner_info
      startDate: start_date
      endDate: end_date
      currentAligner: current_aligner
      totalSetAligner: no_of_set_aligner
      totalZenstageAligner: no_of_zen_stage_aligner
      zenAlignerInfo: zen_aligner_info
      estimatedDeliveryDate: estimated_delivery_date {
        earliestDeliveryDate: from
        latestDeliveryDate: to
      }
      lastAlignerSwitch: last_aligner_date
      nextAlignerSwitch: next_aligner_date
      switchAligner: switch_aligner_every
      originalEndDate: original_end_date
      completionDate: end_date_without_zenstage
      setAlignerCount: set_aligner_count
      zenAlignerCount: zen_aligner_count
      patientUpdatedAligner: patient_updated_aligner
    }
  }
`;

export const GET_MIA_INFO = gql`
  query GetTreatmentInfo($opportunityId: ID!) @api(name: treatmentMonitor) {
    getTreatmentInfoByOppId(id: $opportunityId) {
      mia
    }
  }
`;

export const GET_TOTAL_ALIGNER_SET = gql`
  query GetTreatmentInfo($opportunityId: ID!) @api(name: treatmentMonitor) {
    getTreatmentInfoByOppId(id: $opportunityId) {
      totalAligner: no_of_set_aligner
    }
  }
`;
