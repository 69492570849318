import { FC, useState } from 'react';

import moment from 'moment';

import { SnoozeIcon } from 'assets/icons';
import { ReactCalendar, Button } from 'components';
import { translate } from 'utils';

import CustomTimePicker from './CustomTimePicker';

interface Props {
  onConfirm: (date: moment.Moment) => void;
  onCancel: () => void;
}

const SnoozeCustomPicker: FC<Props> = ({ onConfirm, onCancel }) => {
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [selectedDateTime, setSelectedDateTime] = useState<moment.Moment>();

  const handleOptionSelect = (dateTime: moment.Moment) => {
    setSelectedDateTime(dateTime);
  };

  const handleDateSelect = (date: Date) => {
    if (date.getTime() !== selectedDate.getTime()) {
      setSelectedDate(date);
      setSelectedDateTime(undefined);
    }
  };

  const handleConfirm = () => {
    if (selectedDateTime) onConfirm(selectedDateTime);
  };

  return (
    <section>
      <div className='flex pb-2 border-b-1 border-DISABLED_2'>
        <SnoozeIcon />
        <p className='ml-4'>{translate('snooze.custom')}</p>
      </div>
      <div className='my-8 py-2 px-8 w-[40.375rem] flex'>
        <div className='w-47% h-32vh px-8 py-2.5 shadow-calendar'>
          <ReactCalendar
            showNeighboringMonth={false}
            value={selectedDate}
            minDetail='month'
            minDate={new Date()}
            onChange={handleDateSelect}
            tileClassName='react-calendar__tile--snooze'
            formatMonthYear={(_, date) => moment(date).format('MMM Y')}
          />
        </div>
        <div className='border-r-1 mx-7.5 border-CHINESE_WHITE' />
        <CustomTimePicker
          startDate={selectedDate}
          onSelect={handleOptionSelect}
          selected={selectedDateTime}
        />
      </div>
      <div className='mt-8 flex gap-x-5 text-sm'>
        <Button onClick={onCancel} className='flex-1 px-6 py-3 bg-LIGHT_GRAY'>
          {translate('cancel.cancel')}
        </Button>
        <Button
          disabled={!selectedDateTime}
          onClick={handleConfirm}
          className='flex-1 px-6 py-3 bg-PRIMARY text-white'
        >
          {translate('confirm.confirm')}
        </Button>
      </div>
    </section>
  );
};

export default SnoozeCustomPicker;
