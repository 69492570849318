export const TITLE: Record<string, string> = {
  NEW_CHAT: 'ZenChat New Chat',
  INBOX: 'Zenchat Inbox',
  LOGIN: 'Log In',
  FORGOT_PASSWORD: 'Forget Password Login',
  RESET_PASSWORD: 'Reset Password Login',
  PATIENT_LIST: 'Patient List',
  PATIENT_PAGE: 'Patient page',
  REFERRALS: 'Referrals-Incomplete Referrals',
  REFER_PATIENT: 'Referrals Submission',
  SETTINGS: 'Settings',
  GENERAL_SETTINGS: 'Settings-General',
  USER_SETTINGS: 'Settings-Users',
  LOGIN_SECURITY_SETTINGS: 'Settings-Login and Security',
  BRANCH_SETTINGS: 'Settings-Branches',
  DASHBOARD: 'Dashboard',
  DASHBOARD_CALENDAR: 'Dashboard Calendar',
  CALENDAR: 'Calendar',
  CASE_LIBRARY: 'Case Library',
  CALENDAR_EDIT: 'Edit Appointment',
  PROFILE_SETTINGS: 'Profile',
  INCOMPLETE_REFERRAL: 'Referrals-Incomplete Referrals',
  REVIEW_PENDING_REFERRAL: 'Referrals-Pending Zenyum Review',
  REJECTED_REFERRAL: 'Referrals-Rejected Referrals',
  CLINIC_CONFIRM_APPOINTMENT_SLOT: 'Appointment Slot Confirmation Popup',
  CLINIC_ALTERNATE_APPOINTMENT_SLOT: 'Proposed Slots Confirmation Popup',
  EDIT_AVAILABILITY_POPUP: 'Edit Clinic Availability Popup',
  EDIT_USER_AVAILABILITY_POPUP: 'Edit User Availability Popup ',
  ADD_CLINIC_STAFF_POPUP: 'Add Clinic Staff Popup',
  CLINIC_STAFF_ADDED: 'Clinic Staff Added',
  APPOINTMENT: 'Settings-Appointments',
  PENDING_PRESCRIPTIONS: 'Pending prescription',
  EOT_CONTINUE_TREATMENT_POPUP_VIEWED_AND_CANCELLED:
    'EOT Continue Treatment Pop-Up Viewed and Cancelled',
  EOT_CONTINUE_TREATMENT_POPUP_VIEWED_AND_SUBMITTED:
    'EOT Continue Treatment Pop-Up Viewed and Submitted ',
  REFINEMENT_PRESCRIPTIONS_PAGE_VIEWED: 'Refinement Prescriptions Page Viewed',
  REFINEMENT_EMPTY_STATE_VIEWED: 'Refinement Empty State Viewed ',
  EOT_INCOMPLETE: 'End-of-treatment Review(Incomplete) Viewed',
  EOT_REVIEW: 'End-of-treatment Review Viewed',
  APPOINTMENTS: 'Appointments'
};

export const VIEW_TYPE = {
  INBOX: 'inbox',
  SIDE_PANEL: 'side panel',
  LOGIN: 'login'
};

export const TRACK_EVENTS = {
  USER_CLICKED_SWAPPING_POPUP:
    'Doctor Reminders -- Clinic Swapping Popup Clicked',
  TPV_SWAPPER_LEFT_TPV_VIEW_WITHOUT_APPROVING:
    'TPV Swapper - Left TPV View without approving',
  TPV_SWAPPER_USER_APPROVED_AFTER_TPV_AUTOMATICALLY_SWAPPED:
    'TPV Swapper - User approved TPV after TPV was automatically swapped',
  TPV_SWAPPER_CLICKED_ON_NEXT_TPV_BUTTON:
    'TPV Swapper - Clicked on “Next TPV” button',
  TPV_SWAPPER_TPV_AUTOMATICALLY_SWAPPED:
    'TPV Swapper - TPV was automatically swapped',
  TPV_SWAPPER_POPUP_CLOSED: 'TPV Swapper - Popup closed',
  TPV_SWAPPER_BACK_TO_PATIENT_LIST_CLICKED:
    'TPV Swapper - Clicked on “Back to patient list”',
  EMAIL_CLICK_THROUGH_RATE: 'TP Review Reminder Email click through rate',
  WHATSAPP_CLICK_THROUGH_RATE: 'TP Review Reminder WhatsApp click through rate',
  THREED_VIEWER_NEW_POPUP_OPENED: '3D Viewer Link Opened In New Tab',
  LOGIN: 'Log In',
  FORGOT_PASSWORD_CLICKED: 'Forgot Password Clicked',
  REMEMBER_ME_CHECKED: 'Login Remember Me Checked',
  LOGIN_SHOW_PASSWORD_CLICKED: 'Login Show Password Clicked',
  LOGIN_FAILED: 'Login Failed',
  SET_PASSWORD_LINK_SENT: 'Set Signup Password Email Sent',
  RESET_PASSWORD_LINK_SENT: 'Password Reset Email Sent',
  RESET_PASSWORD_LINK_SENT_ERROR: 'Password Reset Failed',
  RESET_PASSWORD_FAILED: 'Password Reset Failed',
  RESET_PASSWORD_SUCCESS: 'Password Reset confirmation',
  PATIENT_CARD_OPENED: 'Patient Card Opened',
  PATIENT_LIST_SEARCHED: 'Patient list searched',
  PATIENT_LIST_FILTERS_CLICKED: 'Patient list filters clicked',
  PATIENT_LIST_NTH_ROW_CLICKED: 'Patient List N-th Row Clicked',
  PENDING_PRESCRIPTION_FIRST_FILLED: 'Pending Prescription Field First Filled',
  PENDING_PRESCRIPTION_LAST_FILLED: 'Pending Prescription Field last Filled',
  PENDING_PRESCRIPTION_SAVE: 'Pending prescription incompleted',
  PENDING_PRESCRIPTION_COMPLETED: 'Pending prescription completed',
  PENDING_PRESCRIPTION_SUBMITTED: 'Prescription Submitted',
  PATIENT_PRIOR_TREATMENT_REQUIRED: 'Patient Prior Treatment Required',
  PATIENT_CARD_OPENED_FROM_TREATMENT_PLAN_REVIEW:
    'Patient Card Opened From Treatment Plan Review',
  TREATMENT_PLAN_VERSION_CLICKED: 'Treatment Plan Version Clicked',
  TREATMENT_PLAN_REQUEST_NEW_VERSION: 'Treatment Plan New Version Requested',
  TREATMENT_PLAN_VERSION_ENQUIRED: 'Treatment Plan Version Enquired',
  TREATMENT_PLAN_VERSION_APPROVED: 'Treatment Plan Version Approved',
  TPV_SWITCHED: `TPVs Switched`,
  PATIENT_LIST_VIEWED: 'Patient List Viewed',
  IN_NEW_TAB_3D_VIEWER_LINK: '3D Viewer Link Opened In New Tab',
  COPY_LINK_3D_VIEWER_LINK: '3D Viewer Link Copied',
  TREATMENT_PLAN_SMILE_SUMMARY_OPENED: 'Smile Summary Opened',
  TREATMENT_PLAN_3D_SIMULATION_LINK_CLICKED: '3D Simulation Clicked',
  TREATMENT_PLAN_REJECTED: 'Treatment Plan Rejected',
  PATIENT_CARD_OPENED_FROM_IN_TREATMENT:
    'Patient Card Opened From In Treatment',
  IN_TREATMENT_ADD_NEW_NOTE: 'Patient Note Added',
  IN_TREATMENT_EDIT_NOTE: 'Patient Note Edited',
  IN_TREATMENT_ILL_FITTING_ALLIGNERS: 'Ill-fitting Aligners Created',
  IN_TREATMENT_TRIMMING_ALLIGNERS: 'Trimming Created',
  IN_TREATMENT_REFINEMENT: 'Refinement Created',
  IN_TREATMENT_ZENSTAGES: 'ZenStages Created',
  PATIENT_CARD_OPENED_FROM_EOT_REVIEW:
    'Patient Card Opened From End-of-treatment Review',
  END_OF_TREATMENT_RETAINERS_APPROVED: 'End-of-Treatment Retainers Approved',
  REFERRAL_SUBMIT_SUCCESS: 'Referral Submitted',
  REFERRAL_SAVE_SUCCESS: 'Referral Incompleted',
  EXISTING_REFERRAL: 'Existing Referral',
  PASSWORD_UPDATED: 'Password Updated',
  BRANCH_ADDED: 'Branch Added',
  BRANCH_EDITED: 'Branch Edited',
  USER_ADDED: 'User Added',
  USER_EDITED: 'User Edited',
  USER_DELETED: 'User Deleted',
  USER_RESTORED: 'User Restored',
  GENERAL_EDITED: 'General Edited',
  REFERRAL_SEARCH_BAR_CLICKED: 'Referral Search Bar Clicked',
  TASK_CARD_CLICKED: 'Task Card Clicked',
  DASHBOARD_REFERRAL_CLICKED: 'Incomplete Referral Clicked (Dashboard)',
  DASHBOARD_CALENDAR_EVENT_CLICKED: 'Calendar Sidebar Clicked',
  CALENDAR_EVENT_CLICKED: 'Calendar Event Clicked',
  CALENDAR_EVENT_EDIT_CLICKED: 'Calendar Event Edited',
  CALENDAR_FILTER_CLICKED: 'Calendar Filter Clicked',
  CALENDAR_SEARCH_SELECTED: 'Calendar Search Bar Clicked',
  PROFILE_EDITED: 'Profile Edited',
  USER_PROFILE_PICTURE_UPDATED: 'Profile Picture Updated',
  PATIENT_PROFILE_PICTURE_DELETED: 'Profile Picture Deleted',
  PATIENT_PROFILE_PICTURE_ASSIGNED: 'Profile Picture Assigned',
  PATIENT_PHOTOS_UPLOADED: 'Patient Photos Uploaded',
  SETTINGS_CLICKED: 'Settings Clicked',
  APPOINTMENT_STATUS_UPDATE_CALENDAR: 'Calendar Event Status Updated',
  APPOINTMENT_STATUS_UPDATE_DASHBOARD:
    'Calendar Event Status Updated (Dashboard)',
  CALENDAR_VIEW_PATIENT_CARD: 'Patient Card Opened (Calendar)',
  BRANCH_ADDRESS_EDIT_CLICKED: 'Branch Address Edited Clicked',
  CLINIC_AVAILABILITY_EDIT_CLICKED: 'Clinic Availability Edited Clicked',
  ADD_CLINIC_STAFF_CLICKED: 'Add Clinic Staff Clicked',
  EDIT_STAFF_AVAILABILITY_CLICKED: 'Edit Staff Availability Clicked',
  CLINIC_AVAILABILITY_EDITED: 'Clinic Availability Edited',
  STAFF_AVAILABILITY_EDITED: 'Staff Availability Edited',
  BRANCH_EXPANDED: 'Branch Expanded',
  BRANCH_COLLAPSED: 'Branch Collapsed',
  EDIT_TIME_FAILED_CLINIC: 'Clinic Availability Edit Time Failed ',
  EDIT_TIME_FAILED_DOCTOR: 'Staff Availability Edit Time Failed ',
  ADD_STAFF_FAILED: 'Clinic Staff Added Failed',
  EDIT_DAY_SELECTED_CLINIC: 'Clinic Availability Edit Day Selected',
  EDIT_DAY_SELECTED_DOCTOR: 'Staff Availability Edit Day Selected',
  EDIT_DAY_SELECTED_NEW_USER: 'Add Staff Availability Selected ',
  CLINIC_STAFF_EXPANDED: 'Clinic Staff Expanded',
  CLINIC_STAFF_COLLAPSED: 'Clinic Staff Collapsed',
  CASE_LIBRARY_CLICKED: 'Case Library Tab Clicked',
  HELP_CENTER_CLICKED: 'Help Centre Tab Clicked',
  IMAGE_OVERLAY_OPENED: 'Image Overlay Opened',
  NO_SHOW_TOOLTIP_CLICKED: 'Quick No Show Tooltip Clicked',
  NO_SHOW_BUTTON_CLICKED: 'Quick No Show Button Clicked',
  FILLED_PRIOR_TREATMENT: 'Prior Treatment Filled',
  FILLED_TREATMENT_PLANNING_PROCEED: 'Treatment Planning Proceed Filled',
  FILLED_PRIOR_TREATMENT_COMPLETED: 'Prior Treatment Completed Filled',
  FILLED_ATTACHMENTS: 'Attachment Filled',
  FILLED_IPR_LOCATION: 'IPR Location Filled',
  FILLED_RESOLVE_CROWDING: 'Resolved Crowding Filled',
  FILLED_TEETH_EXTRACTION_INSTRUCTIONS: 'Teeth Extraction Instructions Filled',
  FILLED_TREATMENT_PLAN_TYPE: 'Treatment Plan Type Filled',
  FILLED_PHOTOS: 'Photos Filled',
  FILLED_CONSENT_FORM: 'Consent Form Filled',
  FILLED_XRAY: 'Xray Form Filled',
  FILLED_SCANS: 'Scans Filled',
  PRESCRIPTION_SAVE_CLICKED: 'Prescription Saved',
  QUICK_NO_SHOW_TOOLTIP_CLICKED: 'Quick No Show Tooltip Clicked',
  QUICK_NO_SHOW_BUTTON_CLICKED: 'Quick No Show Button Clicked',
  QUICK_NO_SHOW_CONFIRM_BUTTON_CLICKED:
    'Quick No Show Button Confirmation Clicked',
  QUICK_NO_SHOW_CANCEL_BUTTON_CLICKED: 'Quick No Show Button Cancel Clicked',
  PATIENT_CARD_OPENED_FROM_PENDING_PRESCRIPTIONS:
    'Patient Card Opened From Pending Prescriptions',
  PATIENT_CARD_OPENED_FROM_ALL_ALIGNERS_TAB:
    'Patient Card Opened From All aligners Tab',
  THREE_D_VIEWER_LINK_COPIED: '3D Viewer Link Copied from Popup',
  THREE_D_VIEWER_POPUP_CLOSED: '3D Viewer Popup Closed',
  TAKE_IMPRESSIONS_CLICKED: 'User Clicked “I Want To Take Impressions”',
  FIRST_TIME_SETUP_TPPT: 'TPPT First Time Setup',
  TPPT_EDITED: 'TPPT Edited',
  TPPT_CLEARED: 'TPPT Cleared',
  MANDATORY_FIELDS_NOT_FILLED:
    'Mandatory Fields Not Filled And Submitted Prescription',
  EOT_SEND_FOR_REFINEMENT: 'EOT Send For Refinement',
  EOT_CHECK_WITH_ZENYUM: 'EOT Check With Zenyum First',
  EOT_REFINEMENT_RESCAN_APPOINTMENT_NEEDED:
    'EOT Refinement Rescan Appointment Needed',
  EOT_REFINEMENT_RESCAN_APPOINTMENT_NOT_NEEDED:
    'EOT Refinement Rescan Appointment Not Needed',
  MOBILE_HANDOFF_QR_CODE_LINK_OPENED: 'Mobile Handoff QR Code Link Opened',
  PRESCRIPTION_SUMMARY_TAB_CLICKED: 'TPR Side Panel “Prescription” Tab Clicked',
  TPR_EXPAND_BUTTON_CLICKED: 'TPR Side Panel expand button clicked',
  SCROLLING_AND_CLICKING_IN_THE_SIDE_PANEL:
    'TPR Side Panel “Prescription“ information interacted',
  ACTION_REQUIRED_CHIP_CLICKED: '“Action Required” Chip Clicked'
};

export const SEGMENT_REFERRAL_TRACK = {
  PATIENT_FILE_UPLOAD: 'Referral Tab 3 Patient Files Photos - Upload Clicked',
  CONSENT_FORM_UPLOAD:
    'Referral Tab 3 Patient Files Consent Form - Upload Clicked',
  XRAY_UPLOAD: 'Referral Tab 3 Patient Files Xray - Upload Clicked',
  SCAN_UPLOAD: 'Referral Tab 3 Patient Files Scans - Upload Clicked',
  SUCCESS_TOAST: 'Referral Tab 4 Review Summary Success Toast',
  TAB_1_SUBMIT: 'Referral Tab 1 Personal Info Continue Clicked',
  TAB_2_SUBMIT: 'Referral Tab 2 Treatment Specifications Continue Clicked',
  TAB_3_SUBMIT: 'Referral Tab 3 Patient Files Submit Clicked',
  REFER_PATIENT: 'Referral Refer a Patient CTA Clicked',
  TO_PATIENT_LIST: 'Referral Tab 4 Continue to Patient List Clicked',
  TAB_CLICKED: 'Referral Tab Clicked',
  TAB_TOGGLED: 'Referral Breadcrumb Tab Toggled',
  PATIENT_EXIST: 'Referral Tab 1 Personal Info Patient Exists Card Clicked',
  INSTRUCTION_ACTION: 'Referral Tab 2 TP Instructions Action Clicked',
  TAB_1_DROP_OFF: 'Referral Tab 1 Personal Info Drop Off',
  TAB_2_DROP_OFF: 'Referral Tab 2 Treatment Specifications Drop Off',
  TAB_3_DROP_OFF: 'Referral Tab 3 Patient Files Drop Off'
};

export const SEGMENT_REFERRAL_PAGE = {
  PERSONAL_INFO: 'Referral Tab 1 Personal Info',
  TREATMENT_SPECIFICATIONS: 'Referral Tab 2 Treatment Specifications',
  PATIENT_FILE: 'Referral Tab 3 Patient Files',
  REVIEW_SUMMARY: 'Referral Tab 4 Review Summary',
  REFERRAL_TAB_CLICKED: 'Referral Home',
  TAB_1_ERROR: 'Referral Tab 1 Personal Info Invalid Error Shown',
  TAB_3_ERROR: 'Referral Tab 3 Patient Files Invalid Error Shown',
  TAB_1_PATIENT_EXISTS: 'Referral Tab 1 Personal Info Patient Exists Card Shown'
};

export const TREATMENT_STAGES_SEGMENT: { [key: string]: string } = {
  'All aligners': 'All aligners Tab clicked',
  'Pending prescription': 'Pending Prescriptions Tab Clicked',
  'Pending Resubmission': 'Pending Resubmission Tab Clicked',
  'Initial tpv': 'Initial tpv Tab Clicked',
  'Resim requests': 'Resim requests Tab Clicked',
  'TPV Review': 'TPV Review Tab Clicked',
  'Treatment Plan Review': 'Treatment Plan Review Tab Clicked',
  'In treatment': 'In Treatment Tab Clicked',
  'End of treatment': 'End-of-treatment Reviews Tab Clicked'
};

export const CHAT_TRACK_EVENTS = {
  NEW_CHAT_INITIATED: 'New Chat Initiated',
  NEW_CHAT_MORE_TOPICS: 'New Chat More Topics Selected',
  NEW_CHAT_CREATED: 'New ZenChat Created',
  ZENCHAT_FILTER_SELECTED: 'ZenChat Filter Selected',
  ZENCHAT_SEARCHED: 'ZenChat Searched',
  PATIENT_CARD_OPEN: 'Patient Card Opened (INBOX)',
  MARK_URGENT: 'Zenchat Marked Urgent',
  MARK_UNREAD: 'Zenchat Marked Unread',
  CHAT_OPEN: 'Zenchat opened',
  CHAT_CLOSE: 'Zenchat closed',
  CHAT_REOPEN: 'ZenChat Reopened',
  PARTICIPANT_ADD_INITIATED: 'Participant Added Initiated',
  PARTICIPANT_ADDED: 'Participant Added',
  PARTICIPANT_REMOVAL_INITIATED: 'Participant Removal Initiated',
  PARTICIPANT_REMOVED: 'Participant Removed',
  SLACK_REDIRECTION: 'Zenchat Message Entered',
  MESSAGE_SEND: 'ZenChat Message Sent',
  UPLOAD_ERROR: 'Zenchat Upload Error',
  MESSAGE_FAILED: 'Zenchat Message Failed',
  MESSAGE_RETRY: 'Zenchat Message Retry',
  MESSSAGE_IMAGE_VIEWED: 'Zenchat Image Clicked',
  OPEN_DOCUMENT: 'ZenChat Open File',
  ZENCHAT_INBOX_CHAT: 'Zenchat Inbox Chat',
  SIDE_PANEL_CHAT_OPEN: 'ZenChat Inbox Side Panel Opened',
  ZENCHAT_CLICKED_DASHBOARD: 'ZenChat Clicked (Dashboard)',
  ZENCHAT_UPDATE_BQ: 'ZenChat Update BQ',
  ZENCHAT_USER_UPDATE_BQ: 'ZenChat User Update BQ',
  ZENCHAT_DELETE: 'ZenChat Message Deleted',
  ZENCHAT_EDIT: 'ZenChat Message Edited',
  ZENCHAT_COPY_LINK: 'Copy ZenChat Link'
};

export const APPOINTMENT_PAGE_EVENTS = {
  APPOINTMENT_REQUEST_DETAILS: 'Appointments Request Details',
  UPDATE_APPOINTMENT_DETAILS_PAGE: 'Appointment Details Updated Popup',
  CLINIC_SLOT_REJECTED_PAGE: 'Clinic Slots Rejected Popup',
  CLINIC_SLOT_ACCEPTED_PAGE: 'Clinic Slots Accepted Popup',
  INCOMING_APPOINTMENTS_PAGE: 'Appointments: Incoming Request',
  PENDING_APPOINTMENT_PAGE: 'Appointments: Pending Patients',
  CONFIRMED_APPOINTMENT_PAGE: 'Appointments: Confirmed Appointments',
  PATIENT_SLOT_POPUP: 'Patient Slots Added Confirmation Popup',
  ZENBASE_APPOINTMENT: 'Appointments',
  CONFIRMED_APPOINTMENTS: 'Confirmed Appointments',
  PENDING_PATIENTS: 'Pending Patients'
};

export const END_OF_TREATMENT_EVENTS = {
  ADD_ALIGNER_SET: 'Aligner Set Number Added',
  SAVE_ALIGNER_SET: 'Aligner Set Number Saved',
  COMMENT_ADDED: `Patient's Comments Added`,
  COMMENT_SAVED: `Patient's Comments Saved`,
  START_RETAINERS: 'EOT Start Retainers',
  CONTINUE_TREATMENT: 'EOT Continue Treatment',
  PHOTO_COMPARED: 'EOT Photos Compared',
  UPLOAD_SUCCESS: 'EOT Photos Successfully Uploaded',
  UPLOAD_FAIL: 'EOT Photos Failed to upload',
  PHOTOS_UPLOADED: 'Photos Uploaded in EOT'
};

export const APPOINTMENT_TRACK_EVENTS = {
  VIEW_CLINIC_PATIENT_PREFERRED_SLOT:
    'Appointment Slot View on Calendar Clicked',
  ACCEPT_CLINIC_PATIENT_PREFERRED_SLOT: 'Appointment Slot Accepted',
  CLINIC_NONE_OF_THIS_CLICKED: 'Propose New Slots Initiated',
  CLINIC_SEE_ALL_CLICKED: 'See All Patient Slots',
  CLINIC_APPOINTMENT_CONFIRMED: 'Appointment Slot Confirmed',
  CLINIC_APPOINTMENT_REJECTED: 'Appointment Slots Rejected',
  PATIENT_CARD_CLICKED: 'Appointment Request Details: Patient Card Clicked',
  ADD_PATIENT_SLOT_INITIATED: 'Add Patient Slots Initiated',
  EDIT_INTERNAL_NOTES_CLICKED: 'Edit Internal Notes Clicked',
  EDIT_INTERNAL_NOTES_SAVED: 'Edit Internal Notes Saved',
  EDIT_APPOINTMENT_DETAILS_INITIATED: 'Edit Appointment Details Initiated',
  ADD_PATIENT_SLOTS_SUCCESS: 'Add Patient Slots Success',
  APPOINTMENT_SLOT_VIEW: 'Appointment Slot View on Calendar Clicked',
  CLINIC_SLOT_ACCEPTED: 'Clinic Slot Accepted',
  CLINIC_SLOT_REJECTED: 'Clinic Slot Rejected',
  CALENDAR_CHANGE_VIEW: 'Calendar: Change View',
  CALENDAR_CHANGE_WEEK: 'Calendar: Change Week',
  CALENDAR_APPT_SELECTED: 'Calendar: Appointment Selected',
  CALENDAR_DOCTOR_FILTER: 'Calendar: Doctor Filtered',
  APPOINTMENT_SEARCHED: 'Appointments Searched',
  CONFIRM_PATIENT_CARD_CLICKED: ' Confirmed Appointments: Patient Card Clicked',
  CALENDAR_CLINIC_FILTER: 'Calendar: Clinic Filtered',
  ACTION_BUTTON_CLICKED: 'Appointment Action Button Clicked',
  SEE_ALL_PATIENT_SLOTS_CLICKED: ' See All Patient Slots Clicked',
  APPOINTMENT_FILTERED: 'Appointments Filtered',
  APPOINTMENT_TAB_CLICKED: 'Appointments Tab Clicked',
  STATUS_CONFIRMED: 'Appointment Status Pop-Up Confirmed',
  SCHEDULE_CONFIRMED: 'Appointment Schedule Pop-Up Confirmed',
  ADD_APPT_CONFIRMED: 'Add Appointments Pop-Up Confirmed',
  DETAILS_CONFIRMED: 'Appointments Additional Details Pop-Up Confirmed',
  ATTENDANCE_MARKED: 'Appointment Attendance Marked',
  APPOINTMENT_REQUEST_CLICKED: 'Appointment Request Viewed',
  APPOINTMENT_FILTERED_VIEW: 'Appointments Filtered views',
  MANUAL_BOOKED: 'Manual Appointment Booked',
  APPOINTMENT_UPDATED: 'Appointment Status Updated',
  APPOINTMENT_RESCHEDULE: 'Appointment Rescheduled',
  APPOINTMENT_DETAILS_UPDATED: 'Appointment Details Updated',
  PRESALES_APPOINTMENTS_TAB_CLICKED: 'Presales Appointments Tab Clicked',
  AFTERSALES_APPOINTMENTS_TAB_CLICKED: 'Aftersales Appointments Tab Clicked',
  TASK_CARD_CLICKED_ZENBASE: 'Task Card Clicked (Zenbase)',
  APPOINTMENTS_FILTERS_CLEARED: 'Appointments Filters Cleared',
  APPOINTMENTS_ADDITIONAL_DETAILS_EDIT_BUTTON_CLICKED:
    'Appointments Additional Details Edit Button Clicked',
  ADD_APPOINTMENT_BUTTON_CLICKED: 'Add Appointment Button Clicked',
  APPOINTMENT_CONFIRMATION_ERROR: 'Appointment Confirmation Error',
  APPOINTMENT_FILTERS_SIDEPANEL: 'Patient Card Appointments Filtered',
  APPOINTMENT_SELECTED: 'Appointment Selected',
  ADD_APPOINTMENT_CONFIRMATION_ERROR:
    'Add Appointments Confirmation Pop-Up Error',
  AFTERSALES_APPOINTMENT_FILTERED: 'Appointments Filtered',
  AFTERSALES_APPOINTMENT_SEARCHED: 'Appointments Searched'
};

export const CLINIC_CLOUD_VIEW = 'Clinic Cloud';

export const EXTERNAL_LINKS_TRACK_EVENT = {
  EXTERNAL_LINK_OPENED: 'External link opened',
  SALESFORCE_CLICK: 'Salesforce',
  ADMIN_PORTAL_CLICK: 'Admin Portal',
  INBOX: 'inbox',
  PATIENT_CARD: 'patient card'
};

export const APPOINTMENT_SETTINGS_EVENTS = {
  PAGE_OPENED: 'Settings-Appointments',
  UPDATE_APPOINTMENT_PREFERENCE_INIT:
    'Update appointment preference duration initiated',
  UPDATE_APPOINTMENT_PREFERENCE_SAVED:
    'Update appointment preference duration saved',
  UPDATE_APPOINTMENT_REVIEW_INIT:
    'Update review appointment preference initiated',
  UPDATE_APPOINTMENT_REVIEW_SAVED: 'Update review appointment preference saved'
};

export const APPOINTMENT_SIDEPANEL_FILTERS = {
  APPOINTMENT: 'All appointments',
  TREATMENT: 'Treatment'
};

export const ERROR = {
  FE_404_ERROR: '404 Frontend Error'
};

export enum DEVICE_TYPE {
  MOBILE = 'mobile',
  DESKTOP = 'desktop'
}

export const COMPLETE_STATUS = {
  COMPLETE: 'Complete',
  INCOMPLETE: 'Incomplete'
};
