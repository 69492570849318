import { FC } from 'react';

import { translate } from 'utils';

interface ClosedTicketModalProps {
  patientName: string;
  topicName: string;
}
const ClosedTicketModal: FC<ClosedTicketModalProps> = ({
  patientName,
  topicName
}) => {
  return (
    <>
      <h2 className='font-semibold text-20px leading-6 mb-6 text-DEFAULT_TEXT'>
        {translate('INBOX.CLOSE_POPUP.CLOSE_CONVERSATION')}
      </h2>
      <div className='text-14px mt-2 pr-3'>
        <p>{translate('INBOX.CLOSE_POPUP.CONFIRM_CLOSE')}</p>
        <p className='font-semibold'>
          {translate('INBOX.CLOSE_POPUP.FOR', {
            X: topicName,
            patient: patientName
          })}
        </p>
        <p>{translate('INBOX.CLOSE_POPUP.CLOSE_WARNING')}</p>
      </div>
    </>
  );
};

export default ClosedTicketModal;
