import { FC, useEffect, useState } from 'react';

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ErrorIcon } from 'assets/icons';
import AutoTextArea from 'components/AutoTextarea/AutoTextarea';
import Button from 'components/Button/Button';
import { If } from 'components/Generics';
import ResponsiveModal from 'components/Modals/ResponsiveModal/ResponsiveModal';
import SpecialNoteTranslation from 'components/SpecialNoteTranslation/SpecialNoteTranslation';
import { TREATMENT_PLAN_VERSION_ACTIONS } from 'constants/options';
import {
  EVENTDOCTORCOMMENTMAPPER,
  EVENTPLANMAPPER
} from 'constants/translationMaps';
import { useGetNextTicketSequenceId } from 'services/hooks/Inbox.hooks';
import { useCreateTicketFromTopicCode } from 'services/hooks/InTreatment.hooks';
import { useUpdateTreatmentPlanVersion } from 'services/hooks/TreatmentPlan.hooks';
import { GET_TICKET_LIST } from 'services/query/inbox';
import { GET_TREATMENT_PLANS } from 'services/query/TreatmentPlanReview.queries';
import { loginedUserDetails, ticketList } from 'store/atoms';
import { hideLiveTranslation } from 'store/atoms/authAtom';
import { CreateTicketFromTopicCodeResponse } from 'types/InTreatmetn.types';
import { translate } from 'utils';
import { checkIsExternal, checkIsSuperAdmin } from 'utils/common';
import { showToast } from 'utils/toast/toast.util';

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  opportunityName?: string;
  opportunityId?: string;
  patientId: string;
  name?: string;
  treatmentPlanName?: string;
  planVersionId?: string;
  planVersionName?: string;
  treatmentPlanId?: string;
  treatmentPlanType?: string;
  onActionCompleted?: (msg: string) => void;
  title: string;
  mainText: string;
  textBoxTitle?: string;
  acceptanceStatus: TREATMENT_PLAN_VERSION_ACTIONS;
  isReopen?: boolean;
};

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen,
  onClose,
  opportunityId,
  opportunityName,
  patientId,
  name,
  treatmentPlanName,
  planVersionId,
  planVersionName,
  treatmentPlanId,
  treatmentPlanType,
  onActionCompleted,
  title,
  mainText,
  textBoxTitle,
  acceptanceStatus,
  isReopen = false
}) => {
  const hideLiveTranslationBox = useRecoilValue(hideLiveTranslation);
  const loggedInUserDetails = useRecoilValue(loginedUserDetails);
  const [comments, setComents] = useState('');
  const [translatedComments, setTranslatedComments] = useState('');
  const setTicketsList = useSetRecoilState(ticketList);
  const [getSequenceId, { data: sequenceId }] = useGetNextTicketSequenceId({
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true
  });
  const onClickCloseOrCancel = () => {
    setTranslatedComments('');
    setComents('');
    onClose();
  };
  useEffect(() => {
    if (isReopen) getSequenceId();
  }, [getSequenceId]);

  const [createTicketFromTopicCode] = useCreateTicketFromTopicCode({
    refetchQueries: [GET_TICKET_LIST],
    onCompleted: (data: CreateTicketFromTopicCodeResponse) => {
      setTicketsList((oldTickets) => [
        data.createTicketFromTopicCode,
        ...oldTickets
      ]);
    }
  });

  const [updateTreatmentPlanVersion] = useUpdateTreatmentPlanVersion({
    fetchPolicy: 'no-cache',
    refetchQueries: [GET_TREATMENT_PLANS]
  });
  const getMessageInput = (isReopen: boolean) => {
    if (isReopen) return translate('reopen_plan.default_text', { X: comments });
    else return comments;
  };

  const onSubmit = () => {
    onClose();
    updateTreatmentPlanVersion({
      variables: {
        id: planVersionId,
        input: {
          acceptanceStatus: acceptanceStatus,
          ...(!isReopen &&
            acceptanceStatus !==
              TREATMENT_PLAN_VERSION_ACTIONS.APPROVE_EXT_DOC &&
            !checkIsExternal(loggedInUserDetails.groups) && {
              zenyumCouncilComments: comments
            }),
          ...(!isReopen &&
            (checkIsExternal(loggedInUserDetails.groups) ||
              (checkIsSuperAdmin(loggedInUserDetails.groups) &&
                acceptanceStatus ===
                  TREATMENT_PLAN_VERSION_ACTIONS.APPROVE_EXT_DOC)) && {
              treatingDoctorComments: comments,
              translatedTreatingDoctorComments: hideLiveTranslationBox
                ? ''
                : translatedComments
            })
        }
      },
      onCompleted: () => {
        onActionCompleted?.('tpv.treatment_plan_approved');
        if (isReopen) {
          showToast('Rejected tpv re-opened successfully', true);
          createTicketFromTopicCode({
            variables: {
              input: {
                id: sequenceId?.getNextTicketSequenceId,
                patientId: patientId,
                topicCode: 'TREATMENT_PLAN_DISCUSSION',
                sendMessageInput: {
                  text: getMessageInput(isReopen),
                  customAttributes: { isInternal: false }
                },
                tpvId: planVersionId,
                tpId: treatmentPlanId
              },
              loginedUserId: loggedInUserDetails?.id
            }
          });
        }
        window?.analytics.track(EVENTPLANMAPPER['PENDING_PATIENT_REVIEW'], {
          treatment_plan_type: treatmentPlanType,
          treatment_plan_version: planVersionName,
          treatment_plan_id: treatmentPlanId,
          treatment_plan_version_id: planVersionId,
          patient_name: name,
          patient_id: patientId,
          [EVENTDOCTORCOMMENTMAPPER['PENDING_PATIENT_REVIEW']]: comments,
          opportunity_name: opportunityName,
          opportunity_id: opportunityId
        });
        setComents('');
      }
    });
  };
  const setFeedBack = (fieldName: string, result?: string) => {
    if (fieldName === 'specialNotes') {
      setComents(result || '');
    } else if (fieldName === 'translatedSpecialNotes') {
      setTranslatedComments(result || '');
    }
  };
  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClickCloseOrCancel}
      title={title}
      showHeader
    >
      <div>
        <div className='flex mb-6'>
          <ErrorIcon className='fill-current text-ERROR w-4 h-4 mr-2 mt-1' />
          <div className='flex flex-col'>
            <p className='text-sm text-ONYX'>{mainText}</p>
            <If condition={!!treatmentPlanName && !!planVersionName}>
              <p className='text-sm text-ONYX font-bold'>
                {treatmentPlanName} | {planVersionName}
              </p>
            </If>
          </div>
        </div>
        <span className='mb-2 text-sm text-DEFAULT_TEXT'>{textBoxTitle}</span>

        {!hideLiveTranslationBox &&
        (checkIsExternal(loggedInUserDetails.groups) ||
          (checkIsSuperAdmin(loggedInUserDetails.groups) &&
            acceptanceStatus ===
              TREATMENT_PLAN_VERSION_ACTIONS.APPROVE_EXT_DOC)) ? (
          <SpecialNoteTranslation
            translatedText={translatedComments}
            isFormContext={false}
            setValue={setFeedBack}
            isDisabled={false}
            title={translate('8. Special Notes')}
            InputTextFieldName='specialNotes'
            translatedTextFieldName='translatedSpecialNotes'
          />
        ) : (
          <AutoTextArea
            rows={1}
            className='w-full border border-LIGHT_GRAY rounded-8px p-4'
            value={comments}
            onChange={({ target }) => setComents(target.value)}
            initialHeight='56px'
          />
        )}
        <div className='flex justify-end md:flex-row flex-col-reverse text-sm leading-6 mt-4'>
          <Button
            className='text-DEFAULT_TEXT text-center md:mr-6 mr-0'
            type='button'
            onClick={onClickCloseOrCancel}
          >
            {translate('cancel.cancel')}
          </Button>
          <Button
            className='bg-PRIMARY text-white text-center py-3 px-4 md:w-auto w-full md:mb-0  text-sm mb-2'
            type='button'
            onClick={onSubmit}
          >
            {translate('confirm.confirm')}
          </Button>
        </div>
      </div>
    </ResponsiveModal>
  );
};

export default ConfirmationModal;
