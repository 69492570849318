import { ReactNode } from 'react';

import {
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult
} from '@apollo/client';

import { PaginationInfo, TreatmentPlanType } from './PatientList.types';
import { TicketData, Ticket } from './Tickets.types';
import { TreatmentSpecifications } from './TreatmentPlanning.types';

interface Document {
  presignedUrl: string;
  documentType: string;
}

export interface Version extends TreatmentSpecifications {
  id: string;
  name: string;
  url3DViewer: string;
  documents: Array<Document>;
  acceptanceStatus: string;
  numberOfZenStages: number;
  numberOfSets: number;
  teethToExtract: string;
  riskAssessmentForAppt: string;
  patientComments: string;
  zenyumCouncilComments: string;
  treatingDoctorComments: string;
  translatedTreatingDoctorComments: string;
  url3DSimulationVideo: string;
  urlSmileSummary: string;
  teethExtractionZenplus: string;
  accessToken3dViewer: string;
  ipr: boolean;
  attachments: boolean;
  createdAt: Date;
  rejectedBy: string;
  doctor3dViewerUrl: string;
  acceptanceStatusUpdatedAt: Date;
}

export interface ReviewTPV {
  reviewTreatmentPlanVersion?: {
    ticketResponse?: Ticket;
  };
}
export interface Reassign {
  reAssignCouncil: {
    details: {
      firstName: string;
      lastName: string;
    };
    role: string;
  };
}

interface Patient {
  firstName: string;
  lastName: string;
}
export interface TreatmentPlan {
  id: string;
  name: string;
  status: string;
  versions: Array<Version>;
  treatmentSpecifications: TreatmentSpecifications;
  type: string;
  patient: Patient;
  label?: string;
  treatment?: {
    status?: string;
  };
}
export interface TreatmentPlanDetailsType {
  hideFeild?: boolean;
  label: string;
  value?: ReactNode | string;
}

export interface TreatmentPlans {
  getTreatmentPlans: {
    edges: TreatmentPlanType[];
    pagination: PaginationInfo;
  };
}

export type TicketResponseMinimal = {
  getTicketByTopicAndContextInfo: { id: number; topicId: string }[];
};

export enum TOPIC_CONTEXT_TYPE {
  PATIENT = 'PATIENT',
  TP = 'TP',
  TPV = 'TPV'
}

export enum TICKET_STATUS {
  OPEN = 'OPEN',
  CLOSE = 'CLOSE'
}

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

type PaginationInput = {
  offset?: number;
  limit?: number;
  sortBy?: string;
  sortOrder?: SortOrder;
};

export type TicketByTopicAndContextInput = {
  params: {
    patientId?: string;
    topicCode: string[];
    contextType?: TOPIC_CONTEXT_TYPE;
    contextValueId?: string | number;
    status?: TICKET_STATUS;
    paginationInfo: PaginationInput;
    createdAfter?: Date;
  };
  loginedUserId: string;
};

export const TOPIC_CODE: Record<string, string> = {
  DISCUSSION_REQUIRED: 'TREATMENT_PLAN_DISCUSSION',
  REQUEST_NEW_VERSION: 'TREATMENT_PLAN_DISCUSSION',
  REJECTED: 'TREATMENT_PLAN_DISCUSSION',
  REJECTED_BY_INT_DOC: 'TREATMENT_PLAN_DISCUSSION',
  CASE_WITH_INT_DOC: 'TREATMENT_PLAN_DISCUSSION',
  CASE_OPS_ISSUE: 'CASE_OPS_ISSUE'
};
export const MAIN_TOPIC_CODE = {
  EXTERNAL_CHAT: 'TREATMENT_PLAN_DISCUSSION',
  INTERNAL_CHAT: 'TREATMENT_PLAN_DISCUSSION'
};
export const TREATMENT_PLAN_DISCUSSION = 'TREATMENT_PLAN_DISCUSSION';
export const DASHBOARD_BUTTON_GROUPS = {
  APPOINTMENT: 'APPOINTMENT',
  DELIVERY: 'DELIVERY'
};

export interface GetTreatmentPlan {
  (config?: QueryHookOptions): QueryResult<TreatmentPlans>;
}

export interface UpdateTreatmentPlan {
  (config?: MutationHookOptions): MutationTuple<TreatmentPlan, any>;
}
export interface UpdateTreatmentPlanVersion {
  (config?: MutationHookOptions): MutationTuple<Version, any>;
}
export interface raiseCaseOpsIssueType {
  (config?: MutationHookOptions): MutationTuple<TreatmentPlan, any>;
}

export interface RequestTPVUpdate {
  (config?: MutationHookOptions): MutationTuple<ReviewTPV, any>;
}

export interface ReassignCouncil {
  (config?: MutationHookOptions): MutationTuple<Reassign, any>;
}

export interface UseGetTicketId {
  (
    config?: QueryHookOptions<TicketData, TicketByTopicAndContextInput>
  ): QueryResult<TicketData, TicketByTopicAndContextInput>;
}
